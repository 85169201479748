<template>

  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-10">
      <div class="card card-body text-center">

        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <div class="row">
            <div v-if="reveal_word">
              <div class="p-3 mb-2 text-black">
                <h1>{{ room_meta_reactive.blackboard.value.ground_truth }}</h1>
              </div>
              <button class="btn btn-warning mt-2 w-25" @click="reveal_word = false">Hide</button>
            </div>
            <div v-else>
              <div class="p-3 mb-2 bg-info text-white">
                <h1>???</h1>
              </div>
              <button class="btn btn-warning mt-2 w-25" @click="reveal_word = true">Reveal to yourself</button>
            </div>
          </div>
          <hr/>
          <br/>
          <br/>
          <div class="row justify-content-center">
            <div class="col-10 text-start">
              <div class="mb-3">
                <div class="row">
                  <div class="col-md-8">
                    <label for="heard" class="form-label">Word/Sentence you hear:</label>
                    <input type="text" class="form-control" id="heard" v-model="heard_value">
                  </div>
                  <div class="col-md-4">
                    <label for="intelligibility" class="form-label">Intelligibility score</label>
                    <input type="text" class="form-control" id="intelligibility" v-model="intelligibility_score">
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="comments" class="form-label">Note</label>
                <textarea class="form-control" id="comments" rows="3" v-model="note"></textarea>
              </div>
            </div>
          </div>
          <div class="row">

          </div>

          <Loudness v-if="isRecording && received_stream.value" :loudness="received_stream.value['data']"></Loudness>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0 && room_meta_reactive.mode.value === 'exercise'">
          <button :class="['btn w-100', isRecording?'btn-success':'btn-danger']" @click="toggleRecording(!isRecording)" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">{{ isRecording ? 'Stop': 'Start' }} Recording</button>
          <hr/>
          <button v-if="room_meta_reactive.exercise_index.value + 1 <= room_meta_reactive.total_exercises.value" class="btn btn-info mt-2 w-100" @click="next" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">Next</button>
          <button v-if="room_meta_reactive.exercise_index.value > 1 && room_meta_reactive.mode.value === 'exercise'" class="btn btn-secondary mt-2 w-100" @click="back" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">Back</button>
          <hr/>
          <button class="btn btn-primary mt-2 w-100" @click="end">End session</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Results :room_meta="room_meta_reactive" @exit="exit" @save="save_results_edit"/>
  </div>
</template>
  
<script>
  
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';
import { reactive } from 'vue'
import Loudness from '../components/loudness.vue'
import Results from './results.vue'


export default {
  props: ['room_meta', 'received_stream'],
  components: {
    Results,
    Loudness,
  },
  setup(props, {emit}) {
    const room_meta_reactive = toRefs(props.room_meta)
    const received_stream = reactive(props.received_stream)
    const reveal_word = ref(false)
    const isRecording = ref(false)

    const heard_value = ref('')
    const intelligibility_score = ref('')
    const note = ref('')
    const session_slt_id = ref('')


    watch(() => room_meta_reactive.exercise_index.value, (new_exercise_index, old_exercise_index) => {
      if ('slt_note' in room_meta_reactive.blackboard.value) {

        heard_value.value = room_meta_reactive.blackboard.value.slt_note.heard_value
        intelligibility_score.value = room_meta_reactive.blackboard.value.slt_note.intelligibility_score
        note.value = room_meta_reactive.blackboard.value.slt_note.note
      } else {
        heard_value.value = ''
        intelligibility_score.value = ''
        note.value = ''
      }

    });

    const exit = () => {
      emit("exit")
    }

    const toggleRecording = (v) => {
      let command

      if (v) {
        command = "start_recording"
        isRecording.value = true
      } else {
        command = "stop_recording"
        isRecording.value = false
      }
      emit("command", {command: command, meta: {'uid': localStorage.getItem('client_uid') }})
    }

    const checkIfRecording = () => {
      if (room_meta_reactive.currently_recording_uid.value) {
        isRecording.value = true
      } else {
        isRecording.value = false
      }
    }

    const save = () => {
      if (heard_value.value != '' || intelligibility_score.value != '' || note.value != '') {
        emit("save", {result_type: "session_intelligibility", exercise_index: room_meta_reactive.exercise_index.value, payload: {heard_value: heard_value.value, intelligibility_score: intelligibility_score.value, note: note.value}})
        heard_value.value = '' 
        intelligibility_score.value = ''
        note.value = ''
      }
    }

    const save_results_edit = (v) => {
      emit('save', v)
    }

    const next = () => {
      reveal_word.value = false
      toggleRecording(false)
      save()
      emit("next")
    }

    const back = () => {
      reveal_word.value = false
      toggleRecording(false)
      save()
      emit("back")
    }

    const end = () => {
      toggleRecording(false)
      save()
      emit("end")
    }

    onMounted(() => {
      checkIfRecording()
    })
  
    return {
      session_slt_id,
      reveal_word,
      isRecording,
      room_meta_reactive,
      received_stream,
      heard_value,
      intelligibility_score,
      note,
      save,
      save_results_edit,
      next,
      back,
      end,
      exit,
      toggleRecording,
      checkIfRecording
    }
  }
}
</script>

<style scoped>

</style>
