<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='SinglePhonemeArticulation';openModal('speech_sound_modal')">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Speech_sound" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Articulation</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="speech_sound_modal" tabindex="-1" aria-labelledby="speech_sound_modal" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Articulation</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('speech_sound_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'SinglePhonemeArticulation'?'btn-primary':'btn-light']" type="button" @click="selected_module='SinglePhonemeArticulation'">
                  Phoneme articulation (with videos)
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'PhonemeArticulation'?'btn-primary':'btn-light']" type="button" @click="selected_module='PhonemeArticulation'">
                  Word articulation (with videos)
                </button>
              </div>
              <div class="col-8">
                <div v-show="selected_module == 'SinglePhonemeArticulation'">
                  <p class="lead">Practise phoneme articulation in isolation </p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose a sound</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration.phoneme">
                        <option v-for="(item, key, index) in phonemeArticulationDisplayDictionary" :value="item.simple_representation" :key="index">{{item.IPA}} {{item.example_0}}<b style="color:#FF9333">{{item.example_1}}</b>{{item.example_2}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'PhonemeArticulation'">
                  <p class="lead">Practise phoneme articulation in a word</p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose a sound</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration.phoneme">
                        <option v-for="(item, key, index) in phonemeArticulationDisplayDictionary" :value="item.simple_representation" :key="index">{{item.IPA}} {{item.example_0}}<b style="color:#FF9333">{{item.example_1}}</b>{{item.example_2}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Speech_sound from '@/assets/images/dashboard/slt/training/Speech_sound.svg'
import constants from '@/utils/constants'
import { Modal } from 'bootstrap'

export default {
  name: 'sound',
  components: {},
  props: 
  {
    number_exercises: Number
  },
  data(){
    return {
      Speech_sound: Speech_sound, 

      selected_module: '',
      local_number_exercises: 5,

      modal_references: {},

      module_configuration: {
        phoneme: "iy",
      }

    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    this.phonemeArticulationDisplayDictionary = constants.phonemeDisplayListWithExamples
  },
  mounted() {
    this.modal_references = {
      "speech_sound_modal": new Modal(document.getElementById('speech_sound_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('speech_sound_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('speech_sound_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration = {
        phoneme: "iy",
      }
    },
  }
}
</script>