<template>
  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-12">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <h1>Name the picture</h1>
          <br/>
          <div class="mt-4 d-flex flex-row justify-content-around">
            <img :src="image" class="img-fluid rounded shadow" style="height:300px"/>          
          </div>
          <br/>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <h1 >Session Ended</h1>
    <h4>Please wait while your Therapist reviews your results</h4>
  </div>
</template>
  
<script>
  
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';

export default {
  props: ['room_meta'],
  components: {
  },
  setup(props, {emit}) {

    const room_meta_reactive = toRefs(props.room_meta)
    const image = ref(null)


    watch(() => room_meta_reactive.exercise_index.value, (new_exercise_index, old_exercise_index) => {
      image.value = room_meta_reactive.blackboard.value.address[0];
    });

    onMounted(()=> {
      image.value = room_meta_reactive.blackboard.value.address[0];
    })


    onUpdated(() => {
      //image.value = room_meta_reactive.blackboard.value.address[0];
    })

    return {
      room_meta_reactive,
      image
    }

  }
}
</script>

<style scoped>
</style>
