<template>
  <div class="row mt-2" v-if="isession_intelligibility && isession_intelligibility.length > 0">
    <div class="card card-body" >
      <div>
        <p class="h4 text-start">Interactive Session Intelligibility</p>
        <p v-if="session_slt_id">Session SLT ID: {{ session_slt_id }}</p> 
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Exercise</th>
            <th scope="col">Recording</th>
            <th scope="col">Heard</th>
            <th scope="col">Ground Truth</th>
            <th scope="col">Intelligibility Score</th>
            <th scope="col">Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="exercise_data, index of isession_intelligibility">
            <th scope="row">{{Number(index) + 1}}</th>
            <td>
              <div v-for="value, try_index of exercise_data['recordings']">
                <div class="mt-1" v-if="value['audio_address']">
                  <Audio_Playback :audio_address="value['audio_address']"></Audio_Playback>
                </div>
              </div>
            </td>
            <td>{{ exercise_data['note']['heard_value']}}</td>
            <td>{{ exercise_data['ground_truth'] ? exercise_data['ground_truth']: ''}}</td>
            <td>{{ exercise_data['note']['intelligibility_score'] }}</td>
            <td>{{ exercise_data['note']['note'] }} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import {getData} from '@/firebase/index'
import firebase from 'firebase/app'
import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';
  
  export default {
    name: 'isession_intelligibility',
    components: {
      Audio_Playback
    },
    emits: ['is_isession'],
    props: {

    },
    setup(props, {emit}) {
      const userCategory = ref(localStorage.getItem('userCategory'))
        
      const isession_intelligibility = ref([])
      const session_slt_id = ref(null)


      const route = useRoute()
      let root_path = JSON.parse(route.query.root_path) //list of exercises paths that have audio address
      let module_name = route.query.module_name

      getData(root_path, function (err, snapshot) {
        let module_object = snapshot.val()

        if ('session_slt_id' in module_object[module_name]) {
          session_slt_id.value = module_object[module_name]['session_slt_id']
        }

        if (module_object[module_name].hasOwnProperty('exercises') && typeof module_object[module_name]['exercises'][Symbol.iterator] === 'function') {
          
          for (let exercise_data of module_object[module_name]['exercises']) {
            if (exercise_data) {
              if('session_intelligibility' in exercise_data) {

                if (exercise_data['session_intelligibility'].hasOwnProperty('note')) {
                  if (!exercise_data['session_intelligibility']['note'].hasOwnProperty('heard_value')) {
                    exercise_data['session_intelligibility']['note']['heard_value'] = ''
                  }
                  if (!exercise_data['session_intelligibility']['note'].hasOwnProperty('intelligibility_score')) {
                    exercise_data['session_intelligibility']['note']['intelligibility_score'] = ''
                  }
                  if (!exercise_data['session_intelligibility']['note'].hasOwnProperty('note')) {
                    exercise_data['session_intelligibility']['note']['note'] = ''
                  }
                } else {
                  exercise_data['session_intelligibility']['note'] = { 'heard_value' : '', 'intelligibility_score': '', 'note': ''}
                }

                isession_intelligibility.value.push(exercise_data['session_intelligibility'])
              }
            }
          }
        }
        if (isession_intelligibility.value.length > 0) {
          emit('is_isession', true)
        }
      })

      onMounted( () => {

      });

      return {
        userCategory,
        session_slt_id,
        isession_intelligibility,
      };

    }
  }
</script>