<template>
  <div>       


    <div  class="row">
      <div id="video_placeholder"></div>
    </div>
    <div v-if="loading">
      <span>Loading</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'watch_recording',
  props: 
  {
    video_address: {
      type: String,
    },
  },
  data(){
    return {
      loading:true,
      player:null,

    }
  },
  beforeUnmount()
  {
    
    const _this = this
    console.debug('destroy')

    if(_this.player!=null)
    {
      // _this.player.pause()
      _this.player = null
    }
  
  },
  created() {


  },
  mounted()
  {
    // this.watchVideoInstructions()
  },
  methods: {
    watchVideoInstructions(video_address)
    {
      const _this = this;
      
      var storage = _this.$firebase.storage();

      this.loading = true

      storage.ref(video_address).getDownloadURL()
        .then((url) => {
          console.debug('got url')
          _this.playVideoInstructions(url);

        })
        .catch((error) => {
          // Handle any errors
          console.debug('error = ' + error)
        });
      
    },
    playVideoInstructions(url)
    {     
      const _this = this 

      console.debug('url = ' + url)
      this.loading =false
      document.getElementById('video_placeholder').innerHTML='<video  id="video_placeholder" src="'+url+'" height="100%" width="100%" controls autoplay> </video>';
      _this.player =document.getElementById('video_placeholder');
      _this.player.load();
      //this.$forceUpdate()

      
    },
  },
}
</script>
<style scoped>


li:hover {
  background-color: #e0e0e2;
  color: white;
}


</style>