<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="row">
      <div class="col-md-9">
        <div class="card card-body">
          <div class="row">
            <div class="card card-body">
              <h4>Guess a number between 1 and 100</h4>
            </div>
          </div>
          <div class="row mt-2">
            <span :style="interaction_message_color"> {{  interaction_message}}</span> 
          </div>
          <div class="row mt-4 mb-4 justify-content-center">
            <div class="col-4">
              <button style="width: 60mm;height: 30mm;border-color: blueviolet;">
                  <button class="display-button">Your guess</button>
                  <div class="m-2">
                      <input type="text" class="form-control" v-model="input_guess" placeholder="Enter answer"/>
                  </div>
              </button>
              <!-- <span style="margin-left: 5mm;font-size: large;">Remaining guesses: {{ maxguesses }}</span> -->
            </div>
            <div class="col-4">
              <button class="count-button" style="border-color: cornflowerblue;">
                  <button class="display-button" style="background-color: cornflowerblue;border-color: cornflowerblue;">Chances left</button>
                  <div class="row" style="margin-top: 3mm;">
                      <span style="font-size: x-large;"> {{ maxguesses }}</span>
                  </div>
              </button>
              <!-- <span style="margin-left: 5mm;font-size: large;">Remaining guesses: {{ maxguesses }}</span> -->
            </div>
          </div>
          <div class="row mt-2">
            <div class="card card-body">
              <div class="row">
                <p class="h4">Guess history:</p>
              </div>
              <div class="row">
                <div class="col" v-for="i in 8" :key="i">
                    <p class="h3"><b>{{ history_guess[i-1] }}</b></p>
                </div>
              </div>
              <div class="row">
                <div class="col" v-for="j in 8" :key="j">
                  <span :style="history_guess_colour[j-1]" style="color: ;"> {{ history_guess_indication[j-1] }} </span>
                </div>
              </div>
            </div>
          </div>              
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-body h-100">
          <button v-if="maxguesses>0" class="btn-info btn btn-lg w-100 mt-2"  @click="compareResult()">Submit </button>
          <button v-else class="btn-info btn btn-lg w-100 mt-2"  @click="skipExercise()">Next </button>
          <button class='btn btn-secondary btn-lg w-100 mt-2' @click="skipExercise()"> Skip  </button>
          <!-- <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results </button> -->
        </div>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>    
</template>
<script>
  
  import { Modal } from 'bootstrap'
  import {startConfetti} from '@/common_js/confetti'
  import Footer from '@/views/Footer.vue'
  import Navbar from '@/views/Navbar.vue'
  
  export default {
    components: {Footer, Navbar },
    name: 'Number_guessing',
    data() {
      return {
        answer:0,
        input_guess:'',
        interaction_message:'',
        interaction_message_color:'',
        maxguesses:8,
        incorrect:0,
        history_guess:['','','','','','',''],
        history_guess_indication:['','','','','','',''],
        history_guess_colour:['','','','','','',''],
      }
    },
    created() 
    {
      const _this = this
      this.userCategory = localStorage.getItem('userCategory');
      this.loadNumber();
    },
    methods: 
    {
       loadNumber()
       {
            this.answer = Math.floor(Math.random()*100)+1
        
       },
       compareResult()
       {
            
            if(this.input_guess == this.answer)
            {
                this.interaction_message = 'Congrats! You got it'
                this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'

                startConfetti(this)
                setTimeout(() => {
                    this.skipExercise()
                    
                }, 1500);
            }
            else if(this.input_guess<this.answer)
            {
                if(this.maxguesses>1)
                {
                    this.interaction_message = 'You need to guess higher than '+this.input_guess + '. Try again'
                }
                else
                {
                    this.interaction_message = 'So close...The answer is '+this.answer
                }
                this.interaction_message_color = 'fontSize:x-large;color:blueviolet;white-space: pre-wrap'
                this.history_guess[this.incorrect] = this.input_guess
                this.history_guess_indication[this.incorrect] = 'too low'
                this.history_guess_colour[this.incorrect] = 'color:blue;'
                this.incorrect++
                this.maxguesses--
                //this.$forceUpdate()
            }
            else
            {
                if(this.maxguesses>1)
                {
                    this.interaction_message = 'You need to guess lower than '+this.input_guess + '. Try again'
                }
                else
                {
                    this.interaction_message = 'So close...The answer is '+this.answer
                }
                this.interaction_message_color = 'fontSize:x-large;color:blueviolet;white-space: pre-wrap'
                this.history_guess[this.incorrect] = this.input_guess
                this.history_guess_indication[this.incorrect] = 'too high'
                this.history_guess_colour[this.incorrect] = 'color:chocolate;'
                this.incorrect++
                this.maxguesses--
                //this.$forceUpdate()

            }
            console.debug('history indication: '+this.history_guess_indication)
       },
       skipExercise()
       {
            this.interaction_message = ''
            this.input_guess = ''
            this.maxguesses = 8
            this.incorrect = 0
            this.history_guess = ['','','','','','','']
            this.history_guess_indication = ['','','','','','','']
            this.history_guess_colour = ['','','','','','','']
            this.loadNumber();
            //this.$forceUpdate()

       }
    }
  }
  </script>
  <style scoped>
  .custom-button-BV
  {
      width: 130px;
      height: 130px;
      border-radius: 100px;
  }
  li:hover {
    background-color: #e0e0e2;
    color: white;
  }
  .display-button
  {
    width: 56mm;
    height: 10mm;
    font-size: large;
    font-weight: bold;
    color:white;
    background-color: blueviolet;
    border-color: blueviolet;
    border-radius:1mm;
  }
  .count-button
  {
    width: 60mm;
    height: 30mm;
    /* font-size: xx-large; */
    background-color: white;
    border-color: blueviolet;
  }
  .details
  {
    font-size: 24px;
  }
  </style>