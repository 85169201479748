<template>
  <div>
    <canvas ref="loudCanvas" id="loud_canvas" width="400" height="180"></canvas>
    <p class="fs-5">Loudness graph</p>
    <!--<h1>{{loudness}}</h1>-->
  </div>
</template>
  
<script>
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';
import smoothie from 'smoothie'

export default {
  props:['loudness'],
  setup(props) {
    console.debug('creating chart')
    const loudness = ref(props.loudness)

    const loudCanvas = ref(null)
    
    let chart_data = null

    const plotter = (v) => {
      let sound_l = v
      console.log(sound_l)
      /*
      let sound_threshold = 0;
      
      console.debug('sound_l = ' + sound_l)
      if (sound_l > sound_threshold) {
        _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
        _this.avg_loudness_show = roundNumber(_this.avg_loudness,0)
        _this.counter_loudness += 1;
      }

      if (sound_l>_this.max_loudness) {
        _this.max_loudness = sound_l
      }*/
      
      if (chart_data) {
        chart_data.append(new Date().getTime(), sound_l)
      }
    }

    onMounted(() => {
      const maxdBValue = 80
      const mindBValue = 40

      const smoothieObj = new smoothie.SmoothieChart({
        millisPerPixel:11,
        grid:{ fillStyle: '#dfd7d7', verticalSections: 2 },
        labels:{ fillStyle: '#000000', fontSize: 15 },
        horizontalLines:[{ color:'#ffffff', lineWidth:1, value:0 }],
        maxValue: maxdBValue,
        minValue: mindBValue
      })

      let canvas = loudCanvas.value
      console.log(canvas)

      chart_data = new smoothie.TimeSeries();
    
      smoothieObj.addTimeSeries(chart_data, { lineWidth: 3.3, strokeStyle: '#195e52' });

      smoothieObj.streamTo(canvas, 500);
    
    });

    watch(
      
      () => {
        return props.loudness;
      }, (newVal, oldVal) => {
        console.error(newVal)
        plotter(newVal);
      }
    
    );
    
    return {
      loudCanvas
    }
  }
}
</script>

<style scoped>
</style>
