<template>
    <div>
      <div class="row mt-2" v-if="results['Pitch Range'] && results['Pitch Range'].length > 0">
        <pitch_range :pitch_range="results['Pitch Range']"></pitch_range>
      </div>
      <div class="row mt-2" v-if="results['PitchTable'] && results['PitchTable'].length > 0">
        <pitch_table :pitch_table="results['PitchTable']"></pitch_table>
      </div>
    </div>
</template>    

<script>
  import {ref, onUpdated, onMounted} from 'vue'
  import pitch_range from '@/views/session/Result/type/pitch_range.vue'
  import pitch_table  from '@/views/session/Result/type/pitch_table.vue'

export default {
    name: 'pitch_section',
    components: {
      pitch_range,
      pitch_table
    },
    props: ['results'],
    setup(props) {

      onMounted( () => {

      });

      return {
        
      };

    }
  }
  </script>