<template>
  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-12">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <br/>
          <br/>
          <h1>{{ room_meta_reactive.blackboard.value.question }}</h1>
          <br/>
          <div class="mt-4 d-flex flex-row justify-content-around">
            <div v-for="image in room_meta_reactive.blackboard.value.images" class="">
              <img :src="image" class="img-fluid rounded" style="height:300px"/>          
            </div>
          </div>
          <br/>
          <div class="d-flex flex-row justify-content-center">
            <div v-for="(option, index) in room_meta_reactive.blackboard.value.options" :key="index">
              <button :class="['btn ms-4 btn-lg', qanswer === index ? 'btn-success': 'btn-info']" 
                      :style="{'opacity': 0.2 + (index + 1) / room_meta_reactive.blackboard.value.options.length}" @click="select_answer(index)">{{ option }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <h1 >Session Ended</h1>
    <h4>Please wait while your Therapist reviews your results</h4>
  </div>
</template>
  
<script>
  

import {generate_interactive_results_path} from '@/utils/results/data_save_utils'
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';
import firebase from '@firebase/app';
import { reactive } from 'vue'
import {setData, updateDataDirectory} from '@/firebase/index'

export default {
  props: ['room_meta'],
  components: {
  },
  setup(props, {emit}) {

    const room_meta_reactive = toRefs(props.room_meta)
    const qanswer = ref(null)

    const save = (answer) => {
      emit("save", {result_type: "session_questionnaire", exercise_index: room_meta_reactive.exercise_index.value, payload: {answer: answer}})
    }

    onMounted(()=> {
      if (room_meta_reactive.blackboard.value.answer) {
        qanswer.value = room_meta_reactive.blackboard.value.answer
      }

      if (room_meta_reactive.exercise_index.value === 1) {
        let module_name = room_meta_reactive.session_name.value
        let results_addresses = generate_interactive_results_path(room_meta_reactive.session_date.value, module_name, room_meta_reactive.exercise_index.value)
        let module_results_path = results_addresses['modules_results_path']

        let module_info_data = {module_name: module_name, assessment_type: "REMOTE_ASSESSMENT"}

        if (room_meta_reactive.session_slt_id.value != '') {
          module_info_data['session_slt_id'] = room_meta_reactive.session_slt_id.value
        }

        updateDataDirectory(module_results_path, module_info_data)
      }
    })

    const select_answer = (answer) => {
      qanswer.value = answer
      save(answer)
    }

    watch(() => room_meta_reactive.blackboard.value, (blackboard, old_blackboard) => {
      qanswer.value = blackboard.answer
    });

    onUpdated(() => { 
    })

    return {
      room_meta_reactive,
      select_answer,
      qanswer,
      save,
    }

  }
}
</script>

<style scoped>
</style>
