<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body" style="border-color: blueviolet;">
          
          <div class="row">
            <div class="col-7 text-start">
              <p><b>Memory image</b></p>
            </div>
            <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
          </div>

          <div class="row m-2">
            <div class="col-6 text-start">
              <p><b>Correct answers:</b></p>
            </div>
            <div class="col-6 text-start">
              <p>{{memoryImageOverview.correct}} out of {{memoryImageOverview.total}} ({{ memoryImageOverview.percentage}}%)</p>
            </div>
            <hr style="height:2px;border:none;color:#333;background-color:green;" />
          </div>

          <!-- <span style="float:left">
            No. of instruction replay: &emsp; &emsp; {{memoryImageOverview.average_listen_count}}
          </span>
          <br>
          <hr style="height:2px;border:none;color:#333;background-color:green;" />   -->

          <div class="row m-2">
            <div class="col-6 text-start">
              <p><b>No. of exercises requiring instruction replay:</b></p>
            </div>
            <div class="col-6 text-start">
              <p>{{memoryImageOverview.ex_multi_listen}}</p>
            </div>
            <hr style="height:2px;border:none;color:#333;background-color:green;" />
          </div>

        </div>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Memory_image',
  props: ['memoryImageOverview'],
  setup(props, {emit}) {

    return {
    }
  }
}

</script>
<style scoped>


</style>





