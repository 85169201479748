<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar :enableBack=true></Navbar>
    
    <div class="container-fluid">
      <h1 class="display-5">Describing Pictures</h1>
      <div class="card text-center">
        <Instructions v-if="!showGame" @showGame="(v) => showGame = v" />
        <Module v-if="showGame" />
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>

import Instructions from './components/instructions.vue'
import Module from './components/module.vue'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'Describing Pictures',
  components: {Instructions, Module, Footer, Navbar},
  data() {
    return {
      showGame: false,
      userCategory: ''
    }
  },
  created() {
    this.userCategory = localStorage.getItem("userCategory")
  },
  methods: {
    
  },
}
</script>
<style scoped>

</style>