<template>
  <div class="d-flex flex-column justify-content-center vh-100">
    <div>
      <div class="row mt-4 justify-content-center">
        <div class="col-8">
          <h4 v-if="userCategory=='patient'">In order to give you feedback on how well you are doing on the exercises, the platform needs to hear your speech/voice.</h4>
          <h4 v-else>In order to give feedback on how well your clients are doing the exercises, the platform needs to hear their speech/voice.</h4>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-4">
          <i v-if="granted" class="bi bi-mic-fill" style="font-size: 80px; color:green"></i>
          <i v-else class="bi bi-mic-mute-fill" style="font-size: 80px; color: gray; cursor:pointer" @click="unsupported_browser_prompt()"></i>
        </div>
      </div>
      <div class="row mt-2 justify-content-center" v-if="!granted">
        <div class="col-8">
          <h4 class="text-primary"><strong>Please allow the platform to access your microphone by clicking Allow in the popup window.</strong></h4>
          <p>Don't know how to give microphone permission? <a href="mailto:contact@beautifulvoice.co.uk">Contact</a> us.</p>
        </div>
      </div>

      <div class="row mt-2 justify-content-center" v-if="granted">
        <div class="col-8">
          <h4 class="text-success mb-4">Your microphone is now enabled.</h4>
          <button v-if="granted" class="btn btn-primary btn-lg" @click="confirm()">Continue</button>
        </div>
      </div>

    </div>
  </div>
  </template>
  
  <script>
  import { ref, onMounted, onUpdated } from 'vue';

  
  export default {
    name: 'permission',
    components: {  },
    emits: ['confirm'],
  
    setup(props, { emit }) {
      
      const supported_browser = ref(false)
      const granted = ref(false)
      const userCategory = localStorage.getItem('userCategory')

      const unsupported_browser_prompt = () => {
        
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          console.debug('You let me use your mic!');

          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          stream = null;

          granted.value = true;
          localStorage.setItem('mic_permission_allowed', true)
        })
        .catch((err) => {
          console.debug('No mic for you!');
          granted.value = false;
          localStorage.setItem('mic_permission_allowed', false)
        });
          
      }

      const getBrowserPermission = async () => {
        try {
          const permissionStatus = await navigator.permissions.query({ name: "microphone" });

          supported_browser.value = true
          console.debug("microphone permission state" + " " + permissionStatus.state); // granted, denied, prompt

          if (permissionStatus.state === "granted") {
            console.debug('You let me use your mic!');
            granted.value = true;
            localStorage.setItem('mic_permission_allowed', true)
          } else {
            console.debug('Not granted so run prompt!');
            unsupported_browser_prompt();
          }

          permissionStatus.onchange = () => {
            console.debug("Permission changed to " + permissionStatus.state);

            if (permissionStatus.state === "granted") {
              console.debug('You let me use your mic! (onchange)');
              granted.value = true;
            } else {
              console.debug('Not granted so run prompt! (onchange)');
              unsupported_browser_prompt();
            }
          };
        } catch (error) {
          supported_browser.value = false
          // probably firefox or unsupported browser in which get getUserMedia
          //console.debug('Error querying permissions:', error);

          if (localStorage.getItem('mic_permission_allowed')) {

            //alert(localStorage.getItem('mic_permission_allowed'))
            if (localStorage.getItem('mic_permission_allowed') === "true") {
              //do nothing
            } else {
              unsupported_browser_prompt()
            }
          } else {
            unsupported_browser_prompt()
          }
          //alert("supported browser?: " + supported_browser.value)
         }
      };

  
      const confirm = () => {
        emit('confirm');
      };
  
      onMounted(async () => {
        await getBrowserPermission();

      });
  
      onUpdated(async () => {
        await getBrowserPermission();
      })
  
      return {
        granted,
        getBrowserPermission,
        confirm,
        unsupported_browser_prompt,
        userCategory,
      };
    },
  };
  </script>
  
  <style>
  </style>