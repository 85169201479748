<template>
  <div>
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body"  style="width:100%;border-color: blueviolet;">
          <div class="accordion" id="accordionPitchVariation">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pitch_variation_collapse" aria-expanded="false" aria-controls="pitch_variation_collapse"><p class="text-start" >
                  <b>Pitch range results</b></p></button>
              </div>
              <div class="accordion-collapse collapse" id="pitch_variation_collapse" data-bs-parent="#accordionPitchVariation">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr class="text-center">
                          <th>Name</th>
                          <th scope="col">Loudness (dB)</th>
                          <th scope="col">Pitch Range (Hz)</th>
                          <th scope="col">Maximum Pitch (Hz)</th>
                          <th scope="col">Minimum Pitch (Hz)</th>
                          <th scope="col">Average Pitch (Hz)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" v-for="(item, index) in table" :key="index" >
                          <td>{{ item.name }}</td>
                          <td>{{ item.loudness }}</td>
                          <td>{{ item.freq_range }}</td>
                          <td>{{ item.max_freq }}</td>
                          <td>{{ item.min_freq }}</td>
                          <td>{{ item.mean_freq }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Pitch_variation',
  props: ['table'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





