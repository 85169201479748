<template>
  
  <div>
    <div class="btn-group">         
      <button v-if="audio_player == null" class="btn btn-primary" @click="play_audio()">
        <i class="bi bi-volume-up"></i> Playback
      </button>
      <button v-else class="btn btn-danger" @click="pause_audio()">
        <i class="bi bi-sign-stop"></i> Stop
      </button>
      <button class="btn btn-secondary" @click="download()"> <i class="bi bi-download"></i> </button>
    </div>
  </div>
</template>
    
<script>
  
  import {ref, onUpdated, onMounted, onBeforeUnmount} from 'vue'
  import firebase from 'firebase/app'
  
    export default {
    name: 'Audio_Playback',
    components: {

    },
    props: {
      audio_address: {
        type: String,
        default: null
      },
    },
    setup(props) {
  
      const audio_player = ref(null)
  
      const play_audio = () => {
        let storage = firebase.storage()
        storage.ref(props.audio_address).getDownloadURL().then((url) => {
          audio_player.value = new Audio(url);
          audio_player.value.play();
  
          audio_player.value.addEventListener('ended', () => {
            audio_player.value = null
          });
        }).catch((error) => {
  
        })
      }
  
      const pause_audio = () => {
        audio_player.value.pause()
        audio_player.value = null
      }
  
      const saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
        a.href = window.URL.createObjectURL(audioBlob);
        a.download = fileName;
        a.click()
      }

      onBeforeUnmount( () => {
        pause_audio()
      })
  
      const download = () => {
        let storage = firebase.storage();
  
        storage.ref(props.audio_address).getDownloadURL().then((url) => {
  
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            saveBlob(blob, 'beautiful_voice_audio.webm');
          };
          xhr.open('GET', url);
          xhr.send();
  
        })
        .catch((error) => {
  
        });
      }
  
  
      return {
        audio_player,
        play_audio,
        pause_audio,
        download,
      };
  
    }
  }
  </script>