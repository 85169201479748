<template>
  
  <div class="row mt-2">
    <div class="card card-body">
      <div>
        <p class="h4 text-start">Sound frequency analysis results</p>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <th scope="col">Exercise Name</th>
            <th scope="col">Mean F0</th>
            <th scope="col">Std Dev F0</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in pitch_table" v-bind:key="index">
            <td>{{ row.Name }}</td>
            <td>{{ row.meanF0 }}</td>
            <td>{{ row.stdevF0 }}</td>
          </tr>
          <tr class="table-group-divider" v-if="pitch_table && pitch_table.length > 1">
            <th scope="col">Average value</th>
            <th scope="col">{{ (pitch_table.reduce((sum, value) => sum + value.meanF0, 0) / pitch_table.length).toFixed(0) }}</th>
            <th scope="col">{{ (pitch_table.reduce((sum, value) => sum + value.stdevF0, 0) / pitch_table.length).toFixed(1) }}</th>
          </tr>
        </tbody>
      </table> 
    </div>
  </div>
  
</template>
  
<script>

  import {ref, onUpdated, onMounted} from 'vue'

  export default {
    name: 'pitch_table',
    components: {
    },
    props: {
      pitch_table: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      
      onMounted( () => {

      });

      return {

      };

    }
  }
</script>