import { defineStore } from 'pinia'

export const useCallStatusStore = defineStore('callStatus', {
  state: () => {
    return { call_status: 'home', callInitTimestamp: '' }
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    setCallStatus(status) {
      this.call_status = status;
    },
    setCallInitTimestamp() {
      this.callInitTimestamp =  Date.now()
    },
    resetCallInitTimestamp() {
      this.callInitTimestamp =  ''
    }
  },
});