<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-body h-100">
          <div class="row">
            <p class="lead">{{instruction}} </p>
            <p class="lead" style="color:blue"><b>{{song_name}}</b></p>
            <p class="lead" style="height:24px; fontSize:24px; color:#FF0000"><em>{{karaoke_text}}</em> </p>
            <div id="video_placeholder"></div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-body">
          <div class="container">
            <p class="lead">Songs</p>
            <div class="row shadow-sm p-1 mt-1 bg-white" v-for="song, index in available_songs" :key="index">
              <div class="col-8">
                <p class="text-start">{{song['file_name']}}</p>
              </div>
              <div class="col-4">
                <button class="btn btn-success" @click="playSong(song)" >Select</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Lyric from 'lrc-file-parser'

import {logActivity,getTodayString, getWeekNumber} from '@/media_input/utils'

/*

{
   "Name":"Karaoke song",
   "exercise_type":"karaoke",
   "song":"any",
   "plan_name":"Singing",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Singing//Exercises/General/Karaoke/exercises/any",
   "ex_path":"Singing//Exercises/General/Karaoke/exercises/any",
   "today":"2023-07-11 16:59",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"karaoke",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "module_name":"Singing",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":1
}
*/
export default {
  name: 'karaoke',
  props: 
  {
    exForm: {
      type: Object,
    },

  },
  data(){
    return {
      karaoke_text: '',
      base_audio_folder: '/BV/training/karaoke_audios/',
      instruction: 'Pick a song from the list --> ',
      song_name: '',
      audio_folder: '/BV/training/karaoke_audios/Johnny Cash - I Walk the Line/',
      lrc: null,
      available_songs:[]
    }
  },
  created() 
  {

    const _this = this
    logActivity(_this.$firebase, 'accessed', 'karaoke')

    _this.lrc = new Lyric({
      onPlay: function (line, text) { // Listening play event
          // console.debug(line, text) // line is line number of current play
                                  // text is lyric text of current play line
          _this.karaoke_text = text
      },
      onSetLyric: function (lines) { // listening lyrics seting event
          // console.debug(lines) // lines is array of all lyric text
      },
      offset: 150, // offset time(ms), default is 150 ms
      isRemoveBlankLine: false // is remove blank line, default is true
    })

    var storage = _this.$firebase.storage();

    // const listRef = storage.child( _this.base_audio_folder);

    const listRef = storage.ref(_this.base_audio_folder)

    console.debug('listRef = ' + listRef)

    // listAll(storage.ref(_this.base_audio_folder)).then((res) => {
    listRef.listAll().then((res) => {

      res.prefixes.forEach((folderRef) => {

        console.debug('folderRef = ' + folderRef)

        let temp = '1'+ folderRef
        temp = temp.split('/')
        temp = temp[temp.length - 1]


        _this.available_songs.push({name:temp,file_name:temp})
        // All the prefixes under listRef.
        // You may call listAll() recursively on them.
      });
      res.items.forEach((itemRef) => {
        console.debug('itemRef = ' + itemRef)

        // All the items under listRef.
      });
    }).catch((error) => {
      console.debug('error = ' + error)
      // Uh-oh, an error occurred!
    });
      
  },
  methods: 
  {
    playSong(song)
    {
      const _this = this
      _this.karaoke_text = ''
      _this.song_name = song['file_name'] 
      _this.audio_folder = _this.base_audio_folder + '/' + song['file_name'] + '/'

      _this.instruction = 'Follow and sing the lyrics as the song plays.'

      _this.loadLyric()

      _this.loadAudio()
    },
    loadLyric()
    {
        const _this = this


        let lyric_address = _this.audio_folder+'lyrics.lrc'
        var storage = _this.$firebase.storage();


        storage.ref(lyric_address).getDownloadURL()
        .then((url) => {

            fetch(url)
            .then(function(response) {
                response.text().then(function(lyricStr) {
                    _this.lrc.setLyric(lyricStr) // set lyric, lyricStr is lyric file text, extendedLyricStrs is extended lyric file text array (optional)

                });
            });

        
        })
        .catch((error) => {
            // Handle any errors
        });


    },
    loadAudio()
    {
        const _this = this

        // let audio_address = 'audios/karaoke/running-up-that-hill/Kate_Bush_-_Running_Up_That_Hill.mp3'
        let audio_address = _this.audio_folder+'song.mp3'
        var storage = _this.$firebase.storage();


        storage.ref(audio_address).getDownloadURL()
        .then((url) => {
            _this.playAudio(url);

        })
        .catch((error) => {
            // Handle any errors
        });

    },
    nextExercise()
    {
        const _this = this

        _this.$emit('nextExercise');
    },
    playAudio(url)
    {   
        const _this = this   

        console.debug('url = ' + url)
        document.getElementById('video_placeholder').innerHTML='<video  id="movie" src="'+url+'" height="50px" width="100%" controls autoplay> </video>';
        var player =document.getElementById('movie');
        player.load();

        player.addEventListener('timeupdate', (event) => {
            console.debug('The currentTime attribute has been updated. Again.');

            console.debug('time = ' + event.currentTarget.currentTime)
            _this.lrc.play(event.currentTarget.currentTime*1000)

            console.debug('_this.karaoke_text = ' + _this.karaoke_text)

            // _this.karoke_text = event.timeStamp


        });


        player.addEventListener('pause', () => {
            _this.lrc.pause()
          })




    
    },
  },
}
</script>
<style scoped>


</style>