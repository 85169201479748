<template>
  <div class="container">
    <div class="row mt-2">
      <p style="fontSize:24px">The goal of this game is to practise describing images</p>
      <p style="fontSize:22px">At each round, one person will become the "<b style="color:#0677d7">Describer</b>" while the rest of the group are all "<b style="color:red">Listeners</b>".</p>
      <p style="fontSize:22px">The <b style="color:#0677d7">Describer</b> will describe an image they see on the screen while the <b style="color:red">Listeners</b> have their eyes closed</p>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div>
          <img :src="talkingImg" style="height:200px">
        </div>
        <div>
          Describer
        </div>
      </div>
      <div class="col-md-6">
        <div>
          <img :src="listen_closeImg" style="height:200px">
        </div>
        <div>
          Listeners
        </div>
      </div>      
    </div>
    <div class="row text-center justify-content-center">
      <p style="fontSize:22px">After finishing describing the image, the <b style="color:red">Listeners</b> can open their eyes. There will be three images on the screen</p>
      <p style="fontSize:22px">Based on what the <b style="color:#0677d7">Describer</b> said before, <b style="color:red">Listeners</b> need to pick the correct image out of the three choices</p>
      <div class="col-5">
        <img :src="listeningImg" style="height:200px;">
      </div>
    </div>
    <div class="row justify-content-center mt-2">
      <div class="col-4">
        <button class="btn btn-primary btn-lg mt-2 mb-2" @click="startGame()">Start game</button>
      </div>
    </div>
  </div>

</template>
<script>
import homeImg from '@/assets/images/home.png'
import settingImg from '@/assets/images/setting.png'
import prePlansImg from '@/assets/images/prePlans.png'
import planImg from '@/assets/images/plan.png'
import warnImg from '@/assets/images/warn.png'
import addImg from '@/assets/images/add.png'
import talkingImg from '@/assets/images/talking.png'
import listeningImg from '@/assets/images/listen.jpg'
import listen_closeImg from '@/assets/images/listen_close.png'


export default {
  name: 'Module',
  emits: ["showGame"],
  data() {
    return {
      talkingImg:talkingImg,
      listeningImg:listeningImg,
      listen_closeImg:listen_closeImg,
      listener_ref: '',
      image_folder_ref: '',
      image_folder_handle: '',
      game_room_ref:'',
      game_room_handle: '',
      first_img: '',
      second_img: '',
      third_img: '',
      target_img: '',
      game_master_role: false,
      image_number: '0',
      input_game_room: '',
      game_room_id: '',
      game_img_1: '',
      changing_img: homeImg,
      homeImg: homeImg,
      settingImg: settingImg,
      prePlansImg: prePlansImg,
      planImg: planImg,
      warnImg: warnImg,
      addImg: addImg,
      isTemplate: false,
      newPart: false,
      menuVisible: false,
      sendEmailDialog: false,
      form: {
        email: '',
      },
      tableData:
      [
        {creator: 'Dongli Li',
        difficulty: 'High',
        password: 'Yes'},
        {creator: 'Dongli Li',
        difficulty: 'High',
        password: 'Yes'},
      ],
      search:'',
      currentRow: null,
      hasRoomID:true,
      radioDifficulty:'normal',
      newRoomID:'ooooo',
      invite_account:'',
      planName: '',
      btnClicked:false,
      btnPaused:false,
      dialogVisible:true,
      startingRoom:1000,
      styleCardWin: {
        width:'50%',
        margin: 'auto',
        position:'relative',
        top:'30px'
      },
      styleCardMob: {
          width:'100%',
          margin: 'auto',
          // position:'relative',
          // top:'50px'
      },
      styleBtn: {
          margin: 'auto',
          position:'relative',
          top:'50px'
      },
      styleBtn2: {
          margin: 'auto',
          position:'relative',
          top:'70px'
      },
    }

  },
  created() {

    // this.input_game_room = localStorage.getItem('roomID');

  
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'warning-row';
      } else {
        return '';
      }
    },
    handleCurrentChange(val) //jump to selected exercises page TBP
    {
    this.currentRow = val;
    // var client = '';
    // console.debug("go to client page");
    // console.debug("table data"+this.tableData[0]['progress']);
    },
    creatRoom()
    {
      this.hasRoomID = false;
    },
    sendInvite()
    {
      //tbp: send invitation message to the account.
    },
    startGame()
    {
      this.$emit('showGame', true)
    },
  },
}
</script>
<style scoped>

</style>