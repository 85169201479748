
import {processCookieTheftInfo} from "../postprocessing/language_postprocessing"


export function get_cookie_theft_data(exercise_data, name) {

  
  var cookie_theft_data = exercise_data['cookie_theft']

  let all_words = cookie_theft_data['all_words']


  var audio_address = exercise_data['audio_address']

  let word_types = {'characters':'#ff00ff','objects':'#FF9D5C','actions':'#47A7F4'}

  
  let temp = processCookieTheftInfo(all_words)

  let processed_info = temp[0]

  all_words = temp[1]


  let cookie_theft_output = {
    'Name': name,
    'cookie_theft_data': cookie_theft_data,
    'all_words': all_words, 
    'processed_info': processed_info,
    'colour_code': word_types,
    'AudioAddress': audio_address
  };

  let r = {}
  r['cookie_theft'] = cookie_theft_output

  return r
}

