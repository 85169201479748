<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack="true"></Navbar>
    
    <div class="container mb-5">
      <h4 class="display-5">Practise words and phrases</h4>

      <div class="card p-2 text-center">
        <div class="row">
          
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Practise single words</p>
                <img :src="singleImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button" @click="autoGenerateWordList()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="autoGenerateWordList()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('words')"> Exercise overview</button>
                <br/>
                <p>To practise specific sounds, click <b style="color:#f56c6c;cursor:pointer" @click="articulationDashboard()"> HERE </b>.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Practise words with similar sounds</p>
                <img :src="pairImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="minimal_pair()"> Start the exercise</button> -->
                <!-- <div class="row mt-2 justify-content-center"> -->
                    <button class="btn btn-primary btn-lg mt-2" type="button"  @click="minimal_pair()"> Start the exercise</button>
                    <br/>
                    <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('pair')"> Exercise overview</button>
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>3</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Practise common phrases</p>
                <img :src="short_sentenceImg" width="250" height="120" style="object-fit: cover">
                <br/>
                <!-- <div class="row mt-2 justify-content-center"> -->
                    <button class="btn btn-primary btn-lg mt-2" type="button"  @click="functional_sentences()"> Start the exercise</button>
                    <br/>
                    <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('phrases')"> Exercise overview</button>
                <!-- </div> -->
                
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">  
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">

                <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div>
             
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>4</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Practise numbers</p>
                <img :src="speakNumbersImg" width="320" height="120" style="object-fit: cover">
                <br/>
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="practiseNumbers()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('numbers')"> Exercise overview</button>
                <br/>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Practise words with similar sounds</p>
                <img :src="pairImg" width="120" height="120" style="object-fit: cover">
                <br/>
                    <button class="btn btn-primary btn-lg mt-2" type="button"  @click="minimal_pair()"> Start the exercise</button>
                    <br/>
                    <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('pair')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>3</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Practise common phrases</p>
                <img :src="short_sentenceImg" width="250" height="120" style="object-fit: cover">
                <br/>
                    <button class="btn btn-primary btn-lg mt-2" type="button"  @click="functional_sentences()"> Start the exercise</button>
                    <br/>
                    <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('phrases')"> Exercise overview</button>
                
              </div>
            </div>
          </div> -->
        </div>
      
        <div class="modal fade" id="start_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ module_title }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-around">
                  <div class="card card-body">
                    <h5>{{ benefit_statement }}</h5>
                  </div>
                  
                </div>
                <div class="row justify-content-center">
                  <Posture_instructions/>
                </div>
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <!-- <Posture_instructions/> -->

    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>



import speakNumbersImg from '@/assets/images/instructions/speak_numbers.png'

import singleImg from '@/assets/images/instructions/single_word.png'
import pairImg from '@/assets/images/instructions/word_pair.png'
import Posture_instructions from '../components/posture_instructions.vue'
import short_sentenceImg from '@/assets/images/instructions/short_sentences.png'
// import fun_factsImg from '@/assets/images/instructions/fun_facts.jpg'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
  name: 'Speak_words',
  components: {Posture_instructions, Footer, Navbar},
  props: 
  {
  },
  data(){
    // NewsPopup() {
    return {
        userCategory:'',
        region:'',
        explanation:'',
        issue_flag:[true,true,true,true],
        pre_loaded_exercises:[],
        autoGenWords:[],
        singleImg:singleImg,
        pairImg:pairImg,
        speakNumbersImg:speakNumbersImg,
        short_sentenceImg:short_sentenceImg,
        benefit_statement:'',
        module_title:'',
        module_cate:''
        // fun_factsImg:fun_factsImg
    }
  },
 
  //
  created() {

    this.userCategory = localStorage.getItem('userCategory');
    this.region = localStorage.getItem('region');
      // this.explanation[0] = "Make sure you:\n - are about 40cm/16in away from the screen.\n - have your back upright.\n - have a glass water to sip throughout."
      // this.explanation[1] = "1. You will see a video on how to pronounce a certain sound, follow the video to pratice pronouncing the sound."
      // this.explanation[2] = "2. By clicking 'Next', you will then see a few videos showing word examples that contain such sound."
      // this.explanation[3] = "3. Listen to the video, press 'Start' to record your pronunciation."
    this.explanation = ""

  },
  methods: {

    minimal_pair()
    {
      this.$router.push({ name: 'minimal_pairs' } );
    },
    practiseNumbers()
    {
      this.$router.push({ name: 'speak_numbers' } );

    },      

    functional_sentences()
    {
      this.$router.push({ name: 'functional_sentences' } );

    },      

    articulationDashboard()
    {
      this.$router.push({ name: 'IPA' });
    },
    autoGenerateWordList()
    {

      this.$router.push({ name: 'single_words' });
    },
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'phrases')
      {
        this.module_title = 'Practise common phrases'
        this.benefit_statement = 'This exercise helps you practise articulating sounds in daily life phrases. It aims to help you speak phrases loudly and clearly.'
      }
      else if(cate ==='numbers')
      {
        this.module_title = 'Practise speaking numbers'
        this.benefit_statement = 'This exercise helps you practise saying numbers aloud.'

      }
      else if(cate == 'pair')
      {
        this.module_title = 'Practise words with similar sounds'
        this.benefit_statement = 'This exercise helps you practise articulating similar sounds. It aims to help you sound more distinguishable.'
      }
      else if(cate == 'words')
      {
        this.module_title = 'Practise single words'
        this.benefit_statement = 'This exercise helps you practise articulating sounds in words. It aims to help you speak single words loudly and clearly.'
      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    gotoModule()
    {
      if(this.module_cate == 'phrases')
      {
        this.functional_sentences()
      }
      else if(this.module_cate == 'numbers')
      {
        this.practiseNumbers()
      }
      else if(this.module_cate == 'pair')
      {
        this.minimal_pair()
      }
      else if(this.module_cate == 'words')
      {
        this.autoGenerateWordList()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  },
}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}

</style>