<template>
  <div>       

    <div class="row">
      <video center id="live" autoplay playsinline></video> 
    </div>
    <div class="row justify-content-center">
      <button v-if="button_appear && !recording && !saving" class="btn btn-danger mt-2 w-75" @click="record()"><i class="bi bi-record"></i> Record </button>
      <button v-if="recording" type="button" class="btn btn-primary mt-2 w-75" @click="stop_save()"><i class="bi bi-device-hdd"></i> Save </button>
      <button v-else-if="!recording && saving" type="button" disabled class="btn btn-primary mt-2 w-75"><i class="bi bi-clock-history"></i> Saving...</button>
    </div>

  </div>
</template>
<script>

import {BV_VideoRecorder} from '@/media_input/video_input'
import {getUserDataDir, getData} from '@/firebase/index'
import { useToast } from "vue-toastification";

export default {
  name: 'record_instructions',
  props: 
  {
    video_type: {
      type: Boolean,
    },
  },
  data(){
    return {
      uid: localStorage.getItem('uid'),
      userCategory: localStorage.getItem('userCategory'),
      recording: false,
      saving: false,
      button_appear: false,
      bv_VideoRecorder: new BV_VideoRecorder()
    }
  },
  created() {
    const _this = this

    if(this.video_type==undefined || this.video_type==null) {
      this.video_type = true
    }
  },
  mounted() {
    let liveVideoElement = document.getElementById('live');
    liveVideoElement.muted = true;
    
    this.bv_VideoRecorder.checkPermissionsMedia(liveVideoElement);

    this.button_appear = true
  },
  methods: {
    record() {
      this.recording = true
      this.saving = false
      this.bv_VideoRecorder.recordVideoMedia()
    },
    async stop_save()
    {
      const _this = this
      this.recording = false

      let type_dir = ''

      if(this.video_type) {
        type_dir = 'video_instructions/'
      } else {
        type_dir = 'audio_instructions/'
      }

      let random_identifier = Math.random().toString(16).slice(2) + '.mp4'
      let record_address = 'user_instructions/' + type_dir + this.uid  + '/' + random_identifier

      this.bv_VideoRecorder.stopRecordingMedia(record_address)
      this.saving = true

      this.bv_VideoRecorder.recordingPromise.then(() => {

        const toast = useToast()
        toast.info("Recording added successfully.")
        if(_this.video_type) {
          _this.$emit('videoClosed', record_address);
        } else {
          _this.$emit('audioClosed', record_address);
        }
        _this.saving = false
      })
      .catch((error) => {
        console.error('Error during recording:', error);
      });
      
    },
  },
}
</script>
<style scoped>

</style>