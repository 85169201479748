import { defineStore } from 'pinia'

export const useInteractiveStatusStore = defineStore('interactiveSessionStatus', {
  state: () => {
    return { status: '', name: '', counter_party_uid: '' }
  },
  actions: {
    setInteractiveSessionStatus(status) {
      this.status = status;
    },
    setInteractiveSessionName(name) {
      this.name = name
    },
    setCounterPartyID(id) {
      this.counter_party_uid = id
    }
  },
});