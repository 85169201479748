<template>
  <div>
    <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='LetterWordMatch';openModal('reading_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Language_reading" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: orange">
        </div>      
        <div class="col-9 d-flex align-items-center">
          <strong>Reading</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="reading_modal" tabindex="-1" aria-labelledby="reading_modal" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">                 
            <h5 class="modal-title">Reading</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('reading_modal')"></button>
          </div>
          <div class="modal-body">
            <div class="row" style="height:50vh">
              <div class="col-4 border-3 border-success border-end">      
                <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div> -->
                <button :class="['btn','mb-2', 'w-100', selected_module == 'LetterWordMatch'?'btn-primary':'btn-light']" type="button" @click="selected_module='LetterWordMatch'">
                  Match letters to words
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ReadMenu'?'btn-primary':'btn-light']" type="button" @click="selected_module='ReadMenu'">
                  Read a menu at a restaurant
                </button>

                <button :class="['btn','mb-2', 'w-100', selected_module == 'ReadingImages'?'btn-primary':'btn-light']" type="button" @click="selected_module='ReadingImages'">
                  Match a sentence to an image

                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'Orla'?'btn-primary':'btn-light']" type="button" @click="selected_module='Orla'">
                  ORLA
                </button>
                <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ReadingComprehension'?'btn-primary':'btn-light']" type="button" @click="selected_module='ReadingComprehension'">
                  Reading Comprehension with personal interests
                </button>
                <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ReadingComprehensionDailyNewsMid'?'btn-primary':'btn-light']" type="button" @click="selected_module='ReadingComprehensionDailyNewsMid'">
                  Reading Comprehension with daily news
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'ReversiblePassive'?'btn-primary':'btn-light']" type="button" @click="selected_module='ReversiblePassive'">
                  Reversible Passive
                </button>
              </div>
              <div class="col-8">
                <div v-show="selected_module == 'ReadingImages'">
                  <p class="lead">Practise simple comprehension with key words</p>

                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="8" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:20" />
                    </div>
                  </div>
                </div>
                
                <div v-show="selected_module == 'Orla'">
                  <p class="lead">Practise reading a sentence and identifying words within a sentence. Oral reading for Language in Aphasia (ORLA)</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:7" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'LetterWordMatch'">
                  <p class="lead">Practise matching letters to words audiologically and orthographically</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="4" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'ReadMenu'">
                  <p class="lead">Practise reading a menu with formulated phrases</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="4" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:7" />
                    </div>
                  </div>
                </div>

                <div v-if="selected_module == 'ReadingComprehension'">
                  <p class="lead">Practise reading comprehension with personal interests</p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Topic (Irish history, Downton Abbey)</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="topic" type="text" name="topic" v-model="reading_comprehension_topic" 
                        :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                          messages-class="text-danger"
                          validation="required"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Vocabulary complexity level</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['reading_comprehension_level']">
                        <option v-for="k in reading_comprehension_age_range" :key="k.level" :value="k.level">{{k.level}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row m-4">
                    <button v-if="!generating_word_list" class="btn btn-success" style="width:100%" :disabled="local_number_exercises<=0" @click="generate_reading_comprehension()">Generate text </button>
                    <button v-else class="btn btn-success" type="button" style="width:100%" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Generating...
                    </button>

                    <div v-if="module_configuration['data'].length>0 && module_configuration['name'] === 'ReadingComprehension'">
                      <div class="row mt-2">
                        <div v-for="id in module_configuration['data'].length" :key="id">
                          <div class="input-group mb-3">
                            <textarea class="form-control form-control-lg" v-model="module_configuration['data'][id-1].all_questions[0]['paragraph']"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'ReadingComprehensionDailyNewsMid'">
                  <p class="lead">Practise reading comprehension with daily news</p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose a topic</label>

                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['topic']">
                        <option value="Art and Design">Art and Design</option>
                        <option value="Business">Business</option>
                        <option value="Cities">Cities</option>
                        <option value="Film">Film</option>
                        <option value="Food">Food</option>
                        <option value="Football">Football</option>
                        <option value="Latest News">Latest News</option>
                        <option value="Leisure">Leisure</option>
                        <option value="Lifestyle">Lifestyle</option>
                        <option value="Politics">Politics</option>
                        <option value="Science">Science</option>
                        <option value="Sport">Sport</option>
                        <option value="Tech">Tech</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'ReversiblePassive'">
                  <p class="lead">Practise understanding and composing active and passive sentences</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit 
                        input-class="form-control" id="nexercises" type="number" name="number" 
                        @change="onNumberExercisesChange" @keyup="onNumberExercisesChange" 
                        v-model="local_number_exercises" value="3" 
                        :sections-schema="{
                            messages: { $el: 'div' },
                            message: { $el: 'span' },
                            options: { $el: 'div' },
                            option: { $el: 'div' },
                            }"
                          messages-class="text-danger"
                          validation-visibility="dirty"
                          validation="required|min:1|max:3" />
                      </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>    
  </div>
</template>
<script>

import Language_reading from '@/assets/images/dashboard/slt/training/Language_reading.svg'

import { Modal } from 'bootstrap'

import {reading_comprehension} from '@/utils/interface_ai'

export default {
  name: 'reading',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Language_reading: Language_reading, 
      modal_references: {},
      module_configuration: {
        reading_comprehension_level:1,
        reading_comprehension_age:6,
        data: [],
        name: '',
        topic: 'Latest News',
      },
      reading_comprehension_topic: '',
      generating_word_list: false,
      reading_comprehension_age_range:[{level:1,age:6},{level:2,age:10},{level:3,age:15}],
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "reading_modal": new Modal(document.getElementById('reading_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    check() {
      
      if ( this.selected_module === "ReadingComprehension") {
        if (this.module_configuration['data'].length < 1) {
          alert("Please generate the topic first.")
          return false
        }
      }

      return true
    },
    emit_start_training() {
      if ( !this.check() ) return
      this.closeModal('reading_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      if ( !this.check() ) return
      this.closeModal('reading_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration = {
        reading_comprehension_level:1,
        reading_comprehension_age:6,
        data: '',
        name: '',
        topic: 'Latest News',
      }

      this.reading_comprehension_topic = ''
    },
    generate_reading_comprehension()
    {
      const _this = this

      if(_this.reading_comprehension_topic.length<=0)
      {
        return
      }
      
      _this.generating_word_list = true
      
      this.module_configuration.reading_comprehension_age = this.reading_comprehension_age_range[this.module_configuration.reading_comprehension_level-1].age

      console.debug('this.module_configuration.reading_comprehension_age = ' + this.module_configuration.reading_comprehension_age)

      let reading_comprehension_result = reading_comprehension(_this, _this.reading_comprehension_topic,this.module_configuration.reading_comprehension_age)
       
      _this.module_configuration['name']= "ReadingComprehension"
      _this.module_configuration['topic'] = _this.reading_comprehension_topic

      reading_comprehension_result.then(function(result) {
        /*
        for(let k in result) {
          console.debug('k = ' + k)
          console.debug(result[k])
        }*/

        _this.module_configuration['data'] = result

        _this.generating_word_list = false

      })

    },
  }
}
</script>