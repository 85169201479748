<template>
  <div>
    <div class="html2pdf__page-break" />

    <section class="pdf-item">

      <div class="row mt-2">
        <div class="card card-body"  style="border-color: blueviolet;">
          <div class="accordion" id="accordionRecognition_Memory">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#Recognition_Memory_collapse" aria-expanded="false" aria-controls="Recognition_Memory_collapse"><p class="text-start" >
                  <b>Recognition Memory Assessment</b></p>
                </button>
              </div>
              <div class="accordion-collapse collapse" id="Recognition_Memory_collapse" data-bs-parent="#accordionRecognition_Memory">

                <div class="row mb-2">
                  <div v-for="wwca, i of table">
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                    <p class="text-start" ><b>Recognition Memory </b> from {{table[i]['assessment_start']}} to {{table[i]['assessment_end']}}</p>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                    <table class="table mt-4" >
                      <thead>
                        <tr>
                          <th scope="col">Target image</th>
                          <th scope="col">Selected image</th>
                          <th scope="col">Correct</th>
                          <th scope="col">Delay (s)</th>

                        </tr>
                      </thead> 
                      <tbody>
                        <tr v-for="row, index in table[i]['results']" v-bind:key="index">
                          <td>{{ row['target']['name'].charAt(0).toUpperCase() + row['target']['name'].slice(1)}}</td>
                          <td>{{ row[row['selection']]['name'].charAt(0).toUpperCase() + row[row['selection']]['name'].slice(1) }}</td>
                          <td>
                            <img v-if="row['correct']" :src="tickImg" style="width:15px;">
                            <img v-else :src="crossImg" style="width:15px;">
                          </td>

                          <td>{{ row['delay'].toFixed(0) }}</td>

                        </tr>
                        <tr class="table-group-divider">
                          <th></th>
                          <th scope="col">Total:</th>
                          <th scope="col">{{ table[i]['average_results']['Score']}}/{{ table[i]['average_results']['Max Score']}}</th>
                          <th scope="col">{{ table[i]['average_results']['Mean_Delay'].toFixed(0)}}</th>

                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>


import crossImg from '@/assets/images/wrong_cross.png'
import tickImg from '@/assets/images/Tick.png'

export default {
  components: { 
  },
  name: 'Word_finding',
  props: ['table'],
  setup(props, { emit }) {
    
    return {
      crossImg:crossImg,
      tickImg:tickImg
    }

  }
}

</script>
<style scoped>


</style>





