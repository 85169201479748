<template>
  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-12">
      <div class="card card-body text-center">
        
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <h5><h1>{{ room_meta_reactive.blackboard.value['instruction'] }}</h1></h5>
          <br/>
          <br/>
          
          <!--<Loudness v-if="isRecording" :loudness="loudness"></Loudness>-->
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Bar_meter v-if="isRecording" :loudness="loudness"></Bar_meter>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <h1 >Session Ended</h1>
    <h4>Please wait while your Therapist reviews your results</h4>
  </div>
</template>
  
<script>
  
import {checkPermissionsMedia, recordAudioMedia, stopRecordingMedia} from '@/media_input/microphone_recording'
import {generate_interactive_results_path} from '@/utils/results/data_save_utils'
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';
import firebase from '@firebase/app';
import {updateDataDirectory} from '@/firebase/index'
import Loudness from '../components/loudness.vue'
import Bar_meter from '../components/bar_meter.vue'

export default {
  props: ['room_meta'],
  components: {
    Loudness,
    Bar_meter
  },
  setup(props, {emit}) {

    const room_meta_reactive = toRefs(props.room_meta)
    const isRecording = ref(false)
    const uid = localStorage.getItem('uid')
    const loudness = ref(0)
    const interval_time = 10;

    let transmit_interval_id

    const sample_n_transmit_stream = (isProcessing) => {
      clearInterval(transmit_interval_id)

      transmit_interval_id = setInterval(function () {
        if(isProcessing === false) {
          console.debug("stopped")
          clearInterval(transmit_interval_id)
          loudness.value = 0
          return;
        }
        //console.log("isProcessing:", isProcessing);
        
        loudness.value = window.soundMeter.db.toFixed(2)
        emit("transmit_stream", {"type": "loudness", "data" : loudness.value, "timestamp": Date.now()})        
        
      }, interval_time);
    }

    const checkIfRecording = () => {
      if (room_meta_reactive.currently_recording_uid.value) {
        
        if (room_meta_reactive.currently_recording_uid.value === uid) {
          if (!isRecording.value) {
            console.debug("start recording")
            recordAudioMedia()

            isRecording.value = true
            
            sample_n_transmit_stream(true)
          }
        } else {
          console.debug("stop recording")
          endRecordingAudio()
          isRecording.value = false
          sample_n_transmit_stream(false)
        }
      } else {        
        if (isRecording.value) {
          console.debug("stop recording")
          endRecordingAudio()
        }
        isRecording.value = false
        sample_n_transmit_stream(false)
      }
    }
    const endRecordingAudio = () => {

      let module_name = room_meta_reactive.session_name.value
      let results_addresses = generate_interactive_results_path(room_meta_reactive.session_date.value, module_name, Number(room_meta_reactive.exercise_index.value -1).toString())

      // Get current date timestamp in milliseconds
      let currentTimestamp = new Date().getTime();

      let exercises_results_path = results_addresses['exercises_results_path'] + "/session_phonation/"
      let module_results_path = results_addresses['modules_results_path']
      let audio_address = 'audios/' + uid + '/remote_assessment/' + module_name + '/' + room_meta_reactive.session_id.value + '/' + Number(room_meta_reactive.exercise_index.value -1).toString() + '/' + currentTimestamp + '.mp3';
      
      let module_info_data = {module_name: module_name, assessment_type: "REMOTE_ASSESSMENT"}

      if (room_meta_reactive.session_slt_id.value != '') {
        module_info_data['session_slt_id'] = room_meta_reactive.session_slt_id.value
      }

      updateDataDirectory(module_results_path, module_info_data)
      
      let push_structure = {
        'date': room_meta_reactive.session_date.value, 
        'name': module_name, 
        'path': exercises_results_path  + '/recordings/' + currentTimestamp, 
        'audio_address': audio_address, 
        'uid': uid, 
        'loudness': window.soundMeter.db.toFixed(2), //why we use the last loudness?
        'max_phonation': '1',
        'jitter_shimmer': '1',
        'f0': '1',
      }

      stopRecordingMedia(audio_address, push_structure, null);

      clearInterval(window.refreshIntervalId)
      stopRecordingMedia(audio_address)
      isRecording.value = false
      sample_n_transmit_stream(false)
    }

    watch(() => room_meta_reactive.exercise_index.value, (new_exercise_index, old_exercise_index) => {
      checkIfRecording()
    })

    watch(
      () => room_meta_reactive.currently_recording_uid.value,
      (newUid, oldUid) => {
        console.log('currently_recording_uid changed:', newUid);
        checkIfRecording();
      }
    );


    onUpdated(() => {
      checkIfRecording()
    })

    return {
      room_meta_reactive,
      isRecording,
      loudness,

      sample_n_transmit_stream,
    }

  }
}
</script>

<style scoped>
</style>
