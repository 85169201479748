<template>
  <div>
    <h1 class="text-centers"> {{room_meta.session_name.value}} Results</h1>
    <div v-if="room_meta.session_slt_id.value" class="text-center">
      <p class="lead">Session ID: {{ room_meta.session_slt_id.value }}</p>
    </div>
    <div class="mt-4" v-if="exercises_data.value">
      <vue3-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1800"
        :filename=room_meta.session_slt_id.value
        :pdf-quality="2"
        :manual-pagination="false"
        :margin="[10, 10]"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @beforeDownload="show=false"
        @hasDownloaded="show=true"
        ref="html2Pdf"
    > 
      <template  v-slot:pdf-content>
        <section class="pdf-item">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Exercise</th>
                <th scope="col" v-if="show">Recording</th>
                <th scope="col">Heard</th>
                <th scope="col">Ground Truth</th>
                <th scope="col">Intelligibility Score</th>
                <th scope="col">Comment</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="exercise_data, index of exercises_data.value">
                <th scope="row">{{Number(index) + 1}}</th>
                <td v-if="show">
                  <div v-for="value, try_index of exercise_data['session_intelligibility']['recordings']">
                    <div class="btn-group mt-1" v-if="value['audio_address']">
                      <button class="btn btn-danger" @click="playRecording(value)"><i class="bi bi-volume-up"></i> Playback</button>
                      <button class="btn btn-secondary" @click="downloadRecording(value)"> <i class="bi bi-download"></i> </button>
                    </div>
                  </div>
                </td>
                <td>
                  <input type="text" class="form-control" id="heard" v-model="exercise_data['session_intelligibility']['note']['heard_value']" @keyup="save(Number(index) + 1)">
                </td>
                <td>{{exercise_data['session_intelligibility']['ground_truth'] ? exercise_data['session_intelligibility']['ground_truth']: ''}}</td>
                <td>
                  <input type="text" class="form-control" id="heard" v-model="exercise_data['session_intelligibility']['note']['intelligibility_score']" @keyup="save(Number(index) + 1)">
                </td>
                <td>
                  <input type="text" class="form-control" id="heard" v-model="exercise_data['session_intelligibility']['note']['note']" @keyup="save(Number(index) + 1)">
                </td>
              </tr>
            </tbody>
          </table>        
        </section>      
      </template>
    </vue3-html2pdf>
    </div>
    
    <div class="row mt-4" v-if="exercises_data.value">
      <div class="col-12 text-end">
        <button class="btn btn-success" @click="downloadPDF"><i class="bi bi-download"></i> PDF</button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary w-25" @click="exit">Exit Session</button>
      </div>
    </div>

    <!--
    <div v-if="results.value">
      <div class="row mt-2" v-if="results.value.intelligibility && results.value.intelligibility.length > 0">
        <div class="card card-body">
          <div>
            <p class="h4 text-start">Intelligibility analysis results</p>
          </div>
          <table class="table mt-4">
            <thead>
              <tr>
                <th scope="col">Target words</th>
                <th scope="col">Loudness (dB)</th>

                <th scope="col">Accuracy</th>
                <th scope="col">What does the grade mean?</th>

                <th scope="col">Pronunciation breakdown</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in results.value.intelligibility" v-bind:key="index">
                <th>{{ row.GroundTruth }}</th>

                <td>{{ row.loudness<50?'<50':row.loudness }}</td>

                <td>{{ row.Accuracy }}</td>
                <td>{{ row.GradeMeaning }}</td>

                <td><b v-for="(pho_word, id) in row.PhoWordGrade" :key="id" :style="row.PhoWordGrade[id] === 'Needs Improvement' ? 'color:#ff0000' : row.PhoWordGrade[id] === 'Very Good' ? 'color:#00b33c' : 'color:#cca300'" class="custom-text20"> 
                {{row.phoWord_display[id]}}</b></td>

                <td>
                  <div class="btn-group"> 
                    <button class="btn btn-danger" @click="playRecording(row)"><i class="bi bi-volume-up"></i> Playback</button>
                    <button class="btn btn-secondary" @click="downloadRecording(row)"> <i class="bi bi-download"></i> </button>
                  </div>
                </td>
                <td><button class="btn btn-primary" @click="listenHowToSayIt(row['GroundTruth'])"><i class="bi bi-ear"></i> Hear it </button></td>
              </tr>
              <tr class="table-group-divider" v-if="results.value.intelligibility && results.value.intelligibility.length > 1"> 
                <th scope="col">Average value</th>
                <th scope="col">{{ (results.value.intelligibility.reduce((sum, value) => sum + value.loudness, 0) / results.value.intelligibility.length).toFixed(0) }}</th>
                <th scope="col">{{ (results.value.intelligibility.reduce((sum, value) => sum + Number(value.Accuracy.slice(0, -1)), 0) / results.value.intelligibility.length).toFixed(0) + '%' }}</th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>-->
    <!--
    <div class="row mt-2">
      <div class="card card-body" v-show="results['sentencegibility'] && results['sentencegibility'].length > 0">
        <div>
          <p class="h4 text-start">Sentence intelligibility analysis results</p>
        </div>
        <div class="row mt-2 text-start">
          <span style="font-size: large;">
            Overall, your sentence pronunciation quality <b :style="results['avg_sentence_intel'] === 'needs improvement' ? 'color:#ff0000' : results['avg_sentence_intel'] === 'is very good' ? 'color:#00b33c' : 'color:#cca300'"> {{results['avg_sentence_intel']}} : {{results['avg_sentence_interp']}}</b>
          </span>
        </div>
        <table class="table mt-4" style="text-align: left;">
          <thead>
            <tr>

              <th scope="col">Loudness (dB)</th>

              <th scope="col">Accuracy</th>

              <th scope="col">Pronunciation breakdown</th>

              <th scope="col"></th>
            </tr>
          </thead>
          <tbody class="table-group-divider" >
            <tr v-for="row, index in results['sentencegibility']" v-bind:key="index">

              <td>{{ row.loudness<50?'<50':row.loudness }}</td>

              <td>{{ row.Accuracy }}</td>

              <td>
                <strong
                v-for="(word, id) in row.WordGrade" 
                :key="id" 
                :class="[{'text-secondary' : row.WordGrade[id] === 'Omission', 'text-danger' : row.WordGrade[id] === 'Needs Improvement', 'text-success' : row.WordGrade[id] === 'Very Good', 'text-warning' : row.WordGrade[id] === 'OK'}]" 
                style="cursor: pointer;" @click="listenPhoneme(row.Words[id])"
                >
                {{row.Words[id]}}&nbsp;
                </strong>
              </td>
              
              <td>
                <div class="btn-group"> 
                  <button class="btn btn-danger" @click="playRecording(row)"><i class="bi bi-volume-up"></i> Playback</button>
                  <button class="btn btn-secondary" @click="downloadRecording(row)"> <i class="bi bi-download"></i> </button>
                </div>
              </td>
            </tr>
            <tr class="table-group-divider" v-if="results['sentencegibility'] && results['sentencegibility'].length > 1"> 
              
              <th scope="col">{{ (results['sentencegibility'].reduce((sum, value) => sum + value.loudness, 0) / results['sentencegibility'].length).toFixed(0) }}</th>
              <th scope="col">{{ (results['sentencegibility'].reduce((sum, value) => sum + Number(value.Accuracy.slice(0, -1)), 0) / results['sentencegibility'].length).toFixed(0) + '%' }}</th>
              <th scope="col">
                <div class="d-inline me-3"><div class="bg-success d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>Good </span></div>
                <div class="d-inline me-3"><div class="bg-warning d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>OK </span></div>
                <div class="d-inline me-3"><div class="bg-danger d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>Need improvement </span></div>
                <div class="d-inline"><div class="bg-secondary d-inline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div> <span>Did not recognise </span></div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    -->

  </div>
</template>
  
<script>
import jsPDF from 'jspdf'
import Vue3Html2pdf from 'vue3-html2pdf'
import firebase from '@firebase/app';
import { ref, toRefs, onMounted, onUpdated } from 'vue';
import { reactive } from 'vue'

export default {
  props: ['room_meta'],
  components: {
    Vue3Html2pdf
  },
  setup(props, {emit}) {
    
    const room_meta = props.room_meta
    const exercises_data = reactive({})
    const html2Pdf = ref(null)
    const show = ref(true)

    const downloadPDF = () => {
      html2Pdf.value.generatePdf().then
    }

    let module_name = room_meta.session_name.value

    onMounted(async () => {
      for (const [patient_uid, value] of Object.entries(room_meta.users.value)) {

        if (value['category'] === "patient") {
          const path = 'Users/' + patient_uid + '/Results/remote_assessment/general/' + room_meta.session_date.value + '/' + module_name
          
          firebase.database().ref(path).child('exercises').get().then( async (snapshot) => {

            let _exercises_data = snapshot.val()
            if (_exercises_data) {
              //check mandatory fields
              _exercises_data = Object.assign({}, _exercises_data)


              let exercise_recordings_promises = []
              Object.keys(_exercises_data).forEach(async (index) => {

                if (_exercises_data[index].hasOwnProperty('session_intelligibility') && _exercises_data[index]['session_intelligibility'].hasOwnProperty('note')) {

                  if (!_exercises_data[index]['session_intelligibility']['note'].hasOwnProperty('heard_value')) {
                    _exercises_data[index]['session_intelligibility']['note']['heard_value'] = ''
                  }

                  if (!_exercises_data[index]['session_intelligibility']['note'].hasOwnProperty('intelligibility_score')) {
                    _exercises_data[index]['session_intelligibility']['note']['intelligibility_score'] = ''
                  }

                  if (!_exercises_data[index]['session_intelligibility']['note'].hasOwnProperty('note')) {
                    _exercises_data[index]['session_intelligibility']['note']['note'] = ''
                  }
                }else {
                  _exercises_data[index]['session_intelligibility']['note'] = { 'heard_value' : '', 'intelligibility_score': '', 'note': ''}
                }


                Object.keys(_exercises_data[index]['session_intelligibility']['recordings']).forEach(recording_index => {
                  let recording_prm = new Promise ( (resolve_recording, reject_recording) => {

                    let processing_status = _exercises_data[index]['session_intelligibility']['recordings'][recording_index]['processing_status']
                    //console.error(processing_status)

                    if (processing_status === 'started' || processing_status === 'processing') {
                      
                      
                      firebase.database().ref(path).child('exercises').child(index).child('session_intelligibility').child('recordings').child(recording_index).on('child_changed', 
                        (new_child) => {
                          //console.debug(new_child)
                          firebase.database().ref(path).child('exercises').child(index).child('session_intelligibility').child('recordings').child(recording_index).get().then((recording_snapshot) => {
                            let recording_data = recording_snapshot.val()

                            _exercises_data[index]['session_intelligibility']['recordings'][recording_index] = recording_data
                            
                            if (recording_data) {
                              if (recording_data['processing_status'] === 'finished') {
                                resolve_recording()
                              } else if (recording_data['processing_status'] === 'error') {
                                resolve_recording()
                              }
                            }

                          })
                        }
                      )
                    } else {
                      resolve_recording()
                    }

                  })

                  exercise_recordings_promises.push(recording_prm)
                })
              })

              //console.error(exercise_recordings_promises);

              try {
                await Promise.all(exercise_recordings_promises);
                //console.error(exercise_recordings_promises);
                //console.error("All promises settled");
              } catch (error) {
                //console.error("One or more promises were rejected:", error);
              }

              //console.error("Passed");

              exercises_data.value = _exercises_data
            }

          });
        }
      }
    })

    

    const listenHowToSayIt = (text) => {
      let word = text.toLowerCase()

      let storage_file = '/BV/audio_resources/audio_hints/sample_words/' + word + '.mp3'
      let storage = firebase.storage()

      storage.ref(storage_file).getDownloadURL().then(onResolve, onReject);

      function onResolve(url) {
        let audio = new Audio(url);
        audio.play();
      }

      function onReject(error) {
        _this.bv_tts.say(text)
      }
    }

    const saveBlob = (blob, fileName) => {
      var a = document.createElement('a');
      let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
      a.href = window.URL.createObjectURL(audioBlob);
      a.download = fileName;
      a.click()
    }
    
    const downloadRecording = (row) => {
      let audio_file_location = row['audio_address']

      let storage = firebase.storage();

      storage.ref(audio_file_location).getDownloadURL().then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          saveBlob(blob, 'beautiful_voice_audio.webm');
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
      });
    }

    const playSound = (url) => {
      let audio_player = new Audio(url);
      audio_player.play();
    }

    const playRecording = (row) => {
      let audio_file_location = row['audio_address']
      console.log(audio_file_location)
      firebase.storage().ref(audio_file_location).getDownloadURL().then((url) => {
        playSound(url)
        console.log(url)
      })
      .catch((error) => {
        console.log(error)
      })
    }
    
    const exit = () => {
      emit("exit")
    }

    const save = (index) => {
      
      emit("save", {exercise_index: index, result_type: "session_intelligibility", payload: {
        heard_value: exercises_data.value[index-1]['session_intelligibility']['note']['heard_value'], 
        intelligibility_score: exercises_data.value[index-1]['session_intelligibility']['note']['intelligibility_score'], 
        note: exercises_data.value[index-1]['session_intelligibility']['note']['note']
      }})
    }

    return {
      html2Pdf,
      show,
      exercises_data,

      exit,
      save,
      listenHowToSayIt,
      downloadRecording,
      playRecording,
      downloadPDF

    }
  }
}
</script>

<style scoped>
</style>
