import {getUserDataDir, updateData} from '@/firebase/index'


export class BV_Points {
  constructor() {

  }
  

  static #getWeekNumber() {
    let d = new Date()
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    d = new Date()
    let day = d.getDay();
  
    //console.debug('getday = ' + day)
  
    // Return array of year and week number and week day
    return [String(d.getUTCFullYear()), String(weekNo), String(day)];
  }

  add(n_points){

    let week_info = BV_Points.#getWeekNumber()

    getUserDataDir('Points', function (err, result) {
      let year = week_info[0]
      
      let week_no = week_info[1]

      let day = week_info[2]
      
      let curr_points = 0
      
      if(result.child(year).child(week_no).hasChild(day))
      {
        curr_points = result.child(year).child(week_no).child(day).val()
      }

      curr_points = curr_points + n_points

      let points_update = {}
      points_update[day] = curr_points

      updateData(result.child(year).child(week_no).ref,points_update)
    })
  }

  subtract() {

  }

}