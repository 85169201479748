import axios from 'axios';
import { getAuthToken } from '@/firebase/index'

export class BV_VideoRecorder {

  constructor() {
    this.save_media_address = null;
    this.mediaRecorder;
    this.chunks = [];
    this.localStream = null;
    this._liveVideoElement = null

    this.recordingPromise = this.setupRecordingPromise();
  }

  checkPermissionsMedia(liveVideoElement) {
    let constraints = { audio: true, video: true };
    let _this = this
    if (!navigator.mediaDevices.getUserMedia) {
      alert('navigator.mediaDevices.getUserMedia not supported on your browser, use the latest version of Firefox or Chrome');
    } else {
      if (window.MediaRecorder == undefined) {
        alert('MediaRecorder not supported on your browser, use the latest version of Firefox or Chrome');
      } else {
        navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
          _this.localStream = stream;

          _this.localStream.getTracks().forEach(function (track) {
            if (track.kind == "audio") {
              track.onended = function (event) {
                console.debug("audio track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
              }
            }
            if (track.kind == "video") {
              track.onended = function (event) {
                console.debug("video track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
              }
            }
          });

          _this._liveVideoElement = liveVideoElement
          _this._liveVideoElement.srcObject = _this.localStream;
          _this._liveVideoElement.play();

          try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.audioContext = new AudioContext();
          } catch (e) {
            console.debug('Web Audio API not supported.');
          }

        }).catch(function (err) {
          /* handle the error */
          console.debug('navigator.getUserMedia error: ' + err);
        });
      }
    }
  }

  recordVideoMedia() {
    let _this = this
    if (this.localStream == null) {
      alert('Could not get local stream from mic/camera');
    } else {

      this.chunks = [];

      /* use the stream */
      console.debug('Start recording...');
      if (typeof MediaRecorder.isTypeSupported == 'function') {
        /*
            MediaRecorder.isTypeSupported is a function announced in https://developers.google.com/web/updates/2016/01/mediarecorder and later introduced in the MediaRecorder API spec http://www.w3.org/TR/mediastream-recording/
        */
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          var options = { mimeType: 'video/webm;codecs=vp9' };
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
          var options = { mimeType: 'video/webm;codecs=h264' };
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
          var options = { mimeType: 'video/webm' };
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
          //Safari 14.0.2 has an EXPERIMENTAL version of MediaRecorder enabled by default
          var options = { mimeType: 'video/mp4' };
        }
        console.debug('Using ' + options.mimeType);
        this.mediaRecorder = new MediaRecorder(this.localStream, options);
      } else {
        console.debug('isTypeSupported is not supported, using default codecs for browser');
        this.mediaRecorder = new MediaRecorder(this.localStream);
      }

      this.chunks = [];

      this.mediaRecorder.start(1000);

      this.mediaRecorder.ondataavailable = function (e) {
        console.debug('this.mediaRecorder.ondataavailable, e.data.size=' + e.data.size);
        if (e.data && e.data.size > 0) {
          _this.chunks.push(e.data);
        }
      };

      this.mediaRecorder.onerror = function (e) {
        console.debug('this.mediaRecorder.onerror: ' + e);
      };

      this.mediaRecorder.onstart = function () {
        _this.chunks = [];

        console.debug('this.mediaRecorder.onstart, this.mediaRecorder.state = ' + _this.mediaRecorder.state);

        _this.localStream.getTracks().forEach(function (track) {
          if (track.kind == "audio") {
            console.debug("onstart - Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
          }
          if (track.kind == "video") {
            console.debug("onstart - Video track.readyState=" + track.readyState + ", track.muted=" + track.muted);
          }
        });

      };

      this.mediaRecorder.onstop = async function () {
        console.debug('this.mediaRecorder.onstop, this.mediaRecorder.state = ' + _this.mediaRecorder.state);

        let recording = new Blob(_this.chunks, { type: _this.mediaRecorder.mimeType });

        if(_this.save_media_address != null) {
          console.log("Saving Media axios " + _this.save_media_address);

          const form = new FormData()

          let url = import.meta.env.VITE_FAST_API + '/video_conversion'
          
          form.append('payload', recording)
          form.append('path', _this.save_media_address)
          
          getAuthToken().then((idToken) => {

            axios.post(url, form, {
                headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data',
              }}).then(response => {
                _this.resolveRecordingPromise();
              }).catch(function (response) {
                _this.rejectRecordingPromise(response)
            })
          })
        }

        _this.save_media_address = null;
        
        _this.localStream.getTracks().forEach(function (track) {
          track.stop()
        })

        if (_this._liveVideoElement) {
          _this._liveVideoElement.srcObject = null;
        }

      };

      this.mediaRecorder.onpause = function () {
        console.debug('this.mediaRecorder.onpause, this.mediaRecorder.state = ' + _this.mediaRecorder.state);
      }

      this.mediaRecorder.onresume = function () {
        console.debug('this.mediaRecorder.onresume, this.mediaRecorder.state = ' + _this.mediaRecorder.state);
      }

      this.mediaRecorder.onwarning = function (e) {
        console.debug('this.mediaRecorder.onwarning: ' + e);
      };

    }
  }

  pauseRecordingMedia()
  {
    this.mediaRecorder.pause();
  }

  startRecordingMedia()
  {
    this.mediaRecorder.start();
  }

  resumeRecordingMedia()
  {
    this.mediaRecorder.resume();
  }

  resetRecordingMedia()
  {
    stopRecordingMedia(null,null,null);
    this.chunks = [];
  }


  stopRecordingMedia(address){
  
    if(this.mediaRecorder!=undefined) {
      console.debug('stopRecordingMedia')       
      if(this.mediaRecorder.state != "inactive") {
        this.save_media_address = address;

        this.mediaRecorder.stop();
      }	
    }
  }

  setupRecordingPromise() {
    let _this = this
    return new Promise((resolve, reject) => {
      _this.resolveRecordingPromise = resolve;
      _this.rejectRecordingPromise = reject;
    });
  }

}
