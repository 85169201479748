<template>
  <div>
    
    <div class="html2pdf__page-break"/>

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body" style="border-color: blueviolet;">
          <div class="accordion" id="accordionIntelligibility">
              <div class="accordion-item mb-3">
                <div class="accordion-header">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#intelligibility_collapse" aria-expanded="false" aria-controls="intelligibility_collapse"><p class="text-start" >
                    <b>Word intelligibility</b></p></button>
                </div>
                <div class="accordion-collapse collapse" id="intelligibility_collapse" data-bs-parent="#accordionIntelligibility">

                  <div class="row mt-4 m-2">
                    <div class="col-6 text-start">
                      <p>
                        Words with weakest intelligibility:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>
                        {{intel_words[0]}},
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Phonemes with weakest intelligibility:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>
                        {{intelligibilityOverview.phonemes_2_work_on}} 
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <!-- <br><br>
                  3 words with weak intelligibility: {{intel_words[0]}}, {{intel_words[1]}}, {{intel_words[2]}} <br>
                  3 phonemes with weak intelligibility: {{intelligibilityOverview.phonemes_2_work_on}} -->
                  <!-- </span> -->
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <th>Name</th>
                            <th>Loudness (dB)</th>

                            <th>(%)</th>
                            <th>Pronunciation breakdown</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="item, index in table" :key="index" >
                            <td>{{ item.name }}</td>
                            <td>{{ item.loudness }}</td>

                            <td>{{ item.accuracy }}</td>
                            <td>
                              <b v-for="(pho_word, id) in item.phoneme_word_intelligibility_info" :key="pho_word"
                                :style="'color:'+item.phoneme_word_intelligibility_info[id]['colour']" class="custom-text20"> 
                                {{item.phoWord_display[id]}}  &nbsp;
                                
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'intelligibility',
  props: ['intel_words', 'intelligibilityOverview', 'table'],
  setup(props, { emit }) {
    
    return {
    }

  }
}

</script>
<style scoped>


</style>





