<template>
  <div class="card">
    <div class="card-body">
      <div class="row justify-space-between">
        <div class="col-md-9">
          <button class="btn btn-primary v-dashboard-1" type="button" data-bs-toggle="modal" data-bs-target="#add_patient_modal" style="float: left;"><i class="bi bi-person-plus"></i> Add patient</button>
          <Add_patient 
            @createNewClient="check_patients_license_status()">
          </Add_patient>
        </div>
        <div class="col-md-3">
          <input class="form-control" v-model="search" placeholder="Type to search"/>
        </div>
      </div>
      <br/>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th class="text-center">Connected?</th>
                <th v-if="subscription_tier" class="text-center">
                  <span>
                    Licence ( {{ assigned_licenses }} / {{ total_licenses }} ) <i class="bi bi-info-circle" style="cursor:pointer" data-bs-toggle="modal" data-bs-target="#license_modal"></i>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody v-if="subscription_tier && sorted_patient_list != null && sorted_patient_list.length > 0">
            
              <tr v-for="(item, index) in sorted_patient_list" :key="index" 
                    :style="item.client_uid === uid && subscription_tier.includes('F') ?  { 'cursor' : 'not-allowed' } : { 'cursor' : 'pointer'}" 
                    :class="['v-dashboard-3', {'table-secondary': item.client_uid === uid && subscription_tier.includes('F') }]"
                    @mouseover="selectPatientIndex(index)">
                <td @click="handleClick(index)">{{item.name}}</td>
                <td @click="handleClick(index)">{{item.account_email}}</td>
                <td class="text-center">
                  <div v-if="item.connected === 'yes' ">
                    <button v-if="subscription_tier && (subscription_tier.includes('F') || subscription_tier.includes('Trial'))" 
                      class="btn btn-secondary rounded-pill v-dashboard-2"><i class="bi bi-telephone-forward-fill" style="cursor: not-allowed"></i></button>
                    <button v-else 
                      class="btn btn-outline-success rounded-pill v-dashboard-2" @click="setCallUID(index)" data-bs-toggle="offcanvas" href="#offcanvasVideoCall" 
                      role="button" aria-controls="offcanvasVideoCall"><i class="bi bi-telephone-forward-fill" style=""></i></button>
                  </div> 
                  <div v-else-if="item.connected === 'rejected'">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" size="2x" color="red" />                     
                  </div>     
                  <div v-else>
                    <font-awesome-icon icon="fa-solid fa-spinner" size="2x" color="grey" />                  
                  </div>     
                </td>
                <td v-if="item.connected !== 'yes' || item.connected === 'rejected' ">
                  <div class="text-center">
                    <a  class="btn btn-success me-1" type="button" @click="notify_patient(item.account_email, item.firstname, item.surname)">Resend</a>
                    <a class="btn btn-outline-danger" type="button" @click="deletePatient(item['client_uid'])"><i class="bi bi-x-lg"></i></a>              
                  </div>
                </td>
                <td v-else class="text-center">
                  <div v-if="item.license_status && item.license_status === 'assigned'">
                    <div v-if="subscription_tier.includes('F') || subscription_tier.includes('Trial')">
                      <i class="bi bi-check-circle-fill fs-4" style="color: MediumSeaGreen"></i>
                    </div>
                    <div v-else>
                      <VTooltip>
                        <i class="bi bi-check-circle-fill fs-4" style="color:MediumSeaGreen" data-bs-toggle="modal" data-bs-target="#revoke_license_modal"></i>
                        <template #popper>
                          Revoke licence
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                  <div v-else-if="item.license_status && item.license_status === 'no_need'">
                    <div v-if="item.client_uid !== uid">
                      <VTooltip>
                        <i class="bi bi-check-circle-fill fs-4" style="color: SkyBlue"></i>
                        <template #popper>
                          Premium client - no licence required
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="subscription_tier.includes('F') || subscription_tier.includes('Trial')">
                      <i class="bi bi-slash-circle fs-4" style="color: red; cursor: not-allowed"></i>
                    </div>
                    <div v-else>
                      <VTooltip>
                        <i v-if="item.client_uid !== uid" class="bi bi-slash-circle fs-4" style="color: red;" data-bs-toggle="modal" data-bs-target="#assign_license_modal"></i>
                        <template #popper>
                          Click to assign licence
                        </template>
                      </VTooltip>
                    </div>
                  </div>
                 </td>
              </tr>
            </tbody>
          </table>
        </div>  
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="license_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><i class="bi bi-info-circle"></i> License</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" >
            <div class="row">
              <p>This column allows you to assign licences to your clients.
                Each licence grant the recipient unlimited access to Home assignments on the Beautiful Voice Home User platform while they hold the licence.
                You can assign and remove a licence at any time. Your client will be notified.</p>
              <p>To assign or remove a licence, click on the circle symbol for the patient.</p>
              <p>The meaning of each symbol is listed below:</p>
            </div>

            <div class="d-flex">
              <i class="bi bi-check-circle-fill fs-4" style="color: MediumSeaGreen"></i>
              <p class="ms-2 mt-2">This client has a licence from your monthly allowance.</p>
            </div>

            <div class="d-flex">
              <i class="bi bi-check-circle-fill fs-4" style="color: SkyBlue"></i>
              <p class="ms-2 mt-2">This client is on free trial or subscribes to the platform directly. They do not take up your allowance.</p>
            </div>
            
            <div class="d-flex">
              <i class="bi bi-slash-circle fs-4" style="color: red"></i>
              <p class="ms-2 mt-2">This client is on free plan</p>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"> OK</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="assign_license_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Assign License</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="sorted_patient_list && sorted_patient_list[selectedIndex]">
            <p>You are allocating a licence for {{ sorted_patient_list[selectedIndex]['name'] }} ({{ sorted_patient_list[selectedIndex]['account_email'] }}). Your client will receive unlimited access to Home assignments as long as they have this licence. Would you like to proceed?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="assignLicense(selectedIndex)" data-bs-dismiss="modal"><i class="bi bi-check-square"></i> Assign License</button>
          </div>
        </div>
      </div>
    </div>
    

    <div class="modal fade" tabindex="-1" id="revoke_license_modal" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Revoke License</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" v-if="sorted_patient_list && sorted_patient_list[selectedIndex]">
            <p>You are removing the licence of {{ sorted_patient_list[selectedIndex]['name'] }} ({{ sorted_patient_list[selectedIndex]['account_email'] }}). Your client will be notified and they will no longer have unlimited access to Home assignments unless they subscribe to the platform directly. Would you like to proceed?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" @click="revokeLicense(selectedIndex)" data-bs-dismiss="modal"><i class="bi bi-x-square"></i> Revoke License</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>



<script>
  import { ref, computed, onMounted, watch } from 'vue';
  import firebase from '@firebase/app';
  import { getUserDataDir, getAuthToken } from '@/firebase/index';
  import Add_patient from './add_patient.vue';
  import { useToast } from 'vue-toastification';
  import { useRouter } from 'vue-router';
  import axios from 'axios'

  export default {
    components: {Add_patient},
    emits: ['loaded_patient_list'],
    setup(props, {emit}) {
      const api = import.meta.env.VITE_FAST_API;
      //const api = "http://0.0.0.0:8000"
      const uid = localStorage.getItem('uid');
      const subscription_tier = ref(null)

      const toast = useToast();
      const router = useRouter();

      const tableData = ref([]);
      const filtered_table_data = ref([]);
      const search = ref('');
      const selectedIndex = ref(0);
      const assigned_licenses = ref(0);
      const total_licenses = ref(0);

      getUserDataDir('Subscription', (err, result) => {

        subscription_tier.value = result.child('id').val()
        const licenses = result.child('licenses').val();
        if (licenses?.total) {
          total_licenses.value = licenses.total;
        }
      });

      const selectPatientIndex = (index) => {
        selectedIndex.value = index;
      };

      const check_patients_license_status = () => {
        getAuthToken().then((idToken) => {
          axios.get(`${api}/check_patients_license_status?uid=${uid}`, {
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                getPatientList();
              }
            })
            .catch(() => {
              getPatientList();
            });
        });
      };

      const assignLicense = (index) => {
        const post_data = {
          slt_uid: uid,
          patient_uid: sorted_patient_list.value[index].client_uid,
        };

        getAuthToken().then((idToken) => {
          axios.post(`${api}/assign_license`, post_data, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                if (response.data?.status === 'error') {
                  toast.error(response.data.info);
                } else {
                  toast.success("Assigned license to patient successfully.");
                  getPatientList();
                }
              }
            });
        });
      };

      const revokeLicense = (index) => {
        const post_data = {
          slt_uid: uid,
          patient_uid: filtered_table_data.value[index].client_uid,
        };

        getAuthToken().then((idToken) => {
          axios.post(`${api}/remove_license`, post_data, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                if (response.data?.status === 'error') {
                  toast.error(response.data.info);
                } else {
                  toast.success("Revoked license of patient successfully.");
                }
                getPatientList();
              }
            });
        });
      };

      const getPatientList = async () => {
        
        await new Promise((approve, reject) => {
           
          //emit('loaded_patient_list', false);
          emit('loaded_patient_list', true);
          getUserDataDir('Clients', (err, result) => {
            
            tableData.value = [];
            assigned_licenses.value = 0;
            
            result.forEach((childSnapshot) => {

              const firstname = childSnapshot.child('firstname').val();
              const surname = childSnapshot.child('surname').val();
              const name = childSnapshot.child('name').val();

              let client_name = firstname ?? name ?? '';
              client_name = client_name.trim() + (surname ? ` ${surname}` : '');

              const connected = childSnapshot.child('connected').val();
              const account_email = childSnapshot.child('account_email').val();
              let license_status = childSnapshot.child('license_status').val();

              if (license_status && license_status === 'assigned') {
                assigned_licenses.value += 1;
              } else if (license_status) {
                //the no_need ones goes here
              } else {
                license_status = null
              }

              const client_uid = childSnapshot.key;

              tableData.value.push({
                name: client_name,
                firstname,
                surname,
                account_email,
                client_uid,
                connected,
                license_status,
              });
            });

            emit('loaded_patient_list', true);
            approve()
          
          });

        })
        filtered_table_data.value = tableData.value;

      };

      const notify_patient = (email, firstname, surname) => {
        const postData = {
          slt_uid: uid,
          patient_email: email,
          patient_firstname: firstname,
          patient_surname: surname,
          resend: 'true',
        };

        getAuthToken().then((idToken) => {
          axios
            .post(`${api}/add_patient`, postData, {
              headers: {
                Authorization: `Bearer ${idToken}`,
                'Content-Type': 'application/json',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                toast.success("Patient will be notified shortly");
              }
            });
        });
      };

      const deletePatient = (client_uid) => {
        const patient_path = `/Users/${uid}/Clients/${client_uid}`;
        const ref = firebase.database().ref(patient_path);

        ref.remove().then(() => {
          router.push({ name: 'home' });
        });

        const filtered = filtered_table_data.value.filter(
          (item) => item.client_uid !== client_uid
        );
        filtered_table_data.value = filtered;
      };

      const handleClick = (index) => {
        const val = sorted_patient_list.value[index];

        if (val.client_uid === uid && subscription_tier.value.includes('F')) {
          return;
        }

        if (val.connected === 'yes') {
          localStorage.setItem('client_uid', val.client_uid);
          router.push({ name: 'client_interface', query: { uid: val.client_uid } });
        } else if (val.connected === 'rejected') {
          alert("Client did not approve the connection! Please delete the client and try again.");
        } else {
          alert("Patient is not connected yet");
        }
      };

      
      const setCallUID = (index) => {
        const val = filtered_table_data.value[index];
        localStorage.setItem('call_uid', val.client_uid);
        //localStorage.setItem('client_uid', val.client_uid);
      };

      const sorted_patient_list = computed(() => {

        if (filtered_table_data.value.length > 0) {
          let excludedItem = filtered_table_data.value.filter(item => item.client_uid.includes(uid))[0]

          let data = filtered_table_data.value

          if (excludedItem) {
            data = filtered_table_data.value.filter(item => item !== excludedItem);
          }
          
          data.sort((a, b) => a.surname.localeCompare(b.surname));

          if (excludedItem) {
            data.unshift(excludedItem);
          }

          return data;

        }
        
        return null

      });

      watch(
        () => search.value,
        (newSearch) => {
          if (newSearch.length > 0) {
            filtered_table_data.value = tableData.value.filter(
              (item) => item.name.includes(newSearch) || item.account_email.includes(newSearch)
            );
            
          } else {
            filtered_table_data.value = tableData.value
          }
        }
      );

      getPatientList();

      check_patients_license_status()
      
      return {
        tableData,

        sorted_patient_list,
        search,
        selectedIndex,
        assigned_licenses,
        total_licenses,
        subscription_tier,
        uid,
        
        selectPatientIndex,
        deletePatient,
        handleClick,
        notify_patient,
        revokeLicense,
        assignLicense,
        setCallUID,
        check_patients_license_status,

      }
    }
  }
</script>

<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);
}
</style>
