<template>
  <div class="container">
    <div class="row mt-2">
      <p class="display-3">Register your interest</p>
    </div>
    <div class="row">
      <div class="card card-body">
        <div class="row">
          <img :src="BVImg" style="width:300px;">
        </div>
        <div class="row mt-4">
          <p class="lead" style="color:#22B24C">Welcome! This website is for people interested in the BeautifulVoice Product Evaluation.</p>
        </div>
        <div class="row p-4">
          <div v-if="step == 1" class="card card-body shadow">
            <div class="row">
              <p>
                BeautifulVoice is a start-up from the University of Oxford, developing an online platform that supports speech, voice and language training at home. Our goal is to enhance speech and language therapy outcomes, helping you improve quicker and better. 
                <!-- <br><br> By collaborating with Hobbs Rehabilitation, this product evaluation aims to obtain your feedback on the current platform in terms of its usefulness and user-friendliness so that we can further improve it to suit your needs. -->
                <!-- <br><br> Many thanks for your interests in the study. For more details about the evaluation, please refer to the Participant Guide. -->
                <!-- <br><br> Next we will test if your device (computer/tablet) will work with the platform and ask you some questions to know you better. If you are happy with this, please click on the button below. -->
                <br><br> To participate, we will need to test if your device (computer/tablet) will work with the platform and ask you some questions to know you better. If you are happy with this, please click on the button below.
              </p>
            </div>
            <div class="row justify-content-center">
              <button class="btn btn-primary rounded-pill w-50" @click="Next()"> Test my device </button>
            </div>
            <div class="row mt-2">
              <p class="lead text-center" style="color:#22B24C">
                <i class="bi bi-exclamation-circle" style="color:#22B24C"></i> Please make sure that you are using the device (computer/tablet) that you will use during the evaluation and it is connected to the internet.
              </p>
            </div>
            <div class="row mt-4">
              <p class="lead text-center">
                If you have any difficulties or questions, please do not hesitate to contact us on: 
                <br>Dr Andre Hallack, contact@beautifulvoice.co.uk, 07717 252728
                <br> Or Lauren Cooper,  laurencooper@hobbsrehabilitation.co.uk, 01962 779796 
              </p>
            </div>
          </div>
          <div v-else-if="step == 2" class="card card-body shadow">
            <span>Let's start with some basic information about yourself. Could you fill in the following form and then press Next.</span>
            <div clas="row">
              <div class="col">
                <input v-model="form.firstname" />
              </div>
              <div>
                <input v-model="form.surname" />
              </div>
            </div>
            <input v-model="form.email"/>
          </div>
          <div v-else-if="step == 3" class="card card-body shadow">
            <div class="row">
                <span class="custom-text" style="color:#22B24C">Thanks a lot for the interest in the BeautifulVoice Product Evaluation Study.</span>
            </div>
            <div class="row">
              <div class="card card-body">
                <div class="row">
                  <span class="custom-text-medium">
                      The BeautifulVoice team will analyse your data and get back to you shortly for the next steps of the study. You may close the window now.
                  </span>
                </div>
                <div class="row">
                  <span>
                    If you have any further questions, please do not hesitate to contact us on: 
                    <br>Dr Andre Hallack, contact@beautifulvoice.co.uk, 07717 252728
                    <br> Or Lauren Cooper,  laurencooper@hobbsrehabilitation.co.uk, 01962 779796 
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import patientImg from '@/assets/images/patient.png'
import taskImg from '@/assets/images/task.png'
import addImg from '@/assets/images/add.png'
import warnImg from '@/assets/images/warn.png'
import homeImg from '@/assets/images/home.png'
import backImg from '@/assets/images/back.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import rightArrow from '@/assets/images/bxs-right-arrow-white.png'
import BVImg from '@/assets/images/BV_1_small.png'



export default {

  data() {
    return {
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      patientImg: patientImg,
      taskImg: taskImg,
      rightArrow: rightArrow,
      addImg: addImg,
      warnImg: warnImg,
      homeImg: homeImg,
      backImg: backImg,
      doneIcon: doneIcon,
      flag_test:true,
      BVImg:BVImg,
    }
  },
  created() 
  {

  },
  methods: 
  {
    Next()
    {
      this.$router.push({ name: 'evaluation_user_input'});
    }


  },
}
</script>
<style scoped>

</style>