<template>
  <div>
    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body" style="border-color: blueviolet;">

          <div class="accordion" id="accordionTokenAssessment">
            <div class="accordion-item mb-3">
              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#token_assessment_collapse" aria-expanded="false" aria-controls="token_assessment_collapse"><p class="text-start" >
                  <b>Token test</b></p></button>
              </div>
              <div class="accordion-collapse collapse" id="token_assessment_collapse" data-bs-parent="#accordionTokenAssessment">
                <div class="row mt-4 m-2">
                  <div class="col-6 text-start">
                    <p>Correct answers:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{tokenAssessmentOverview.correct}} out of {{tokenAssessmentOverview.total}} ({{ tokenAssessmentOverview.percentage}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>Correct answers on shapes:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{tokenAssessmentOverview.overview_difficulties['shape']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['shape']['total']}} ({{tokenAssessmentOverview.overview_difficulties['shape']['percentage']}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:grey;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>Correct answers on colours:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{tokenAssessmentOverview.overview_difficulties['color']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['color']['total']}} ({{tokenAssessmentOverview.overview_difficulties['color']['percentage']}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:grey;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>Correct answers on sizes:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{tokenAssessmentOverview.overview_difficulties['size']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['size']['total']}} ({{tokenAssessmentOverview.overview_difficulties['size']['percentage']}}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:grey;" />
                </div>

                <div class="row m-2">
                  <div class="col-6 text-start">
                    <p>Correct answers on multi selections:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{tokenAssessmentOverview.overview_difficulties['multi']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['multi']['total']}} ({{tokenAssessmentOverview.overview_difficulties['multi']['percentage']}}%)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>

export default {
  name: 'Token_assessment',
  props: ['tokenAssessmentOverview'],
  setup(props, {emit}) {
    
    return {

    }
  }
}

</script>
<style scoped>


</style>





