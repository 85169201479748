<template>
  <div class="card mt-2">
    <div class="card-body">
      <!-- <p class="lead"> Before starting the exercise, make sure:</p> -->
      <p class="lead"> Exercise reminders:</p>
    <div class="row justify-content-center">
      <div class="col-md-3">
        <img :src="distanceImg" style="width:260px">
        <!-- <p> You are about 40cm/15in away<br>from the screen (about half of an arm)</p> -->
      </div>
      <div class="col-md-3">
        <img :src="postureImg" style="width:260px;">
        <!-- <p> You have your back upright and feet flat on the floor</p> -->
      </div>
      <div class="col-md-3">
        <img :src="waterImg" style="width:200px;">
        <!-- <p> You have a glass of water to sip<br>throughout the training</p> -->
      </div>
      <div class="col-md-3">
        <i class="bi bi-sign-stop h-100 w-100" style="color: #f56c6c;font-size: 120px;"></i>
        <!-- <p> You have a glass of water to sip<br>throughout the training</p> -->
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-3">
        <p> Keep your distance </p>
      </div>
      <div class="col-md-3">
        <p> Use a good posture</p>
      </div>
      <div class="col-md-3">
        <p> Sip water all the time</p>
      </div>
      <div class="col-md-3">
        <p> Stop if your voice is strained</p>
      </div>
    </div>
    </div>
  </div>
</template>
<script>


import distanceImg from '@/assets/images/instructions/distance_label.jpeg'
import postureImg from '@/assets/images/instructions/posture3_label.png'
import waterImg from '@/assets/images/instructions/water2_label.png'

export default {
  name: "Posture_instructions",
  components: { },
  data() {
    return {
      distanceImg:distanceImg,
      postureImg:postureImg,
      waterImg:waterImg,
    }
  },
  created() 
  {

  },
  methods: 
  {

  }
}
</script>
<style scoped>
.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

</style>