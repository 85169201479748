<template>

  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-10">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <h1>{{ room_meta_reactive.blackboard.value.question }}</h1>
          <br/>
          <div class="mt-4 d-flex flex-row justify-content-around">
            <div v-for="image in room_meta_reactive.blackboard.value.images" class="">
              <img :src="image" class="img-fluid rounded" style="height:300px"/>          
            </div>
          </div>
          <br/>
          <div class="d-flex flex-row justify-content-center">
            <div v-for="(option, index) in room_meta_reactive.blackboard.value.options" :key="index">
              <button :class="['btn ms-4 btn-lg', 
                  qanswer === index ? 'btn-success': 'btn-info']" 
                  :style="{'opacity': 0.2 + (index + 1) / room_meta_reactive.blackboard.value.options.length}" @click="select_answer(index)"
              >{{ option }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <button v-if="room_meta_reactive.exercise_index.value + 1 <= room_meta_reactive.total_exercises.value" class="btn btn-info mt-2 w-100" @click="next" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">Next</button>
          <button v-if="room_meta_reactive.exercise_index.value > 1" class="btn btn-secondary mt-2 w-100" @click="back" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">Back</button>
          <hr/>
          <button class="btn btn-primary mt-2 w-100" @click="end">End session</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Results :room_meta="room_meta_reactive" @exit="exit"/>
  </div>
</template>
  
<script>
  
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';
import { reactive } from 'vue'
import Results from './results.vue'


export default {
  props: ['room_meta'],
  components: {
    Results,
  },
  setup(props, {emit}) {
    const room_meta_reactive = toRefs(props.room_meta)
    const qanswer = ref(null)

    const save = (answer) => {
      emit("save", {result_type: "session_questionnaire", exercise_index: room_meta_reactive.exercise_index.value, payload: {answer: answer}})
    }

    const select_answer = (answer) => {
      qanswer.value = answer
      save(answer)
    }

    watch(() => room_meta_reactive.blackboard.value, (blackboard, old_blackboard) => {
      qanswer.value = blackboard.answer
    });

    onMounted(() => {
      if (room_meta_reactive.blackboard.value.answer) {
        qanswer.value = room_meta_reactive.blackboard.value.answer
      }
    })

    const start = () => {
      emit("start")
    }

    const next = () => {
      save(null)
      emit("next")
    }

    const back = () => {
      save(null)
      emit("back")
    }

    const end = () => {
      save(null)
      emit("end")
    }

    const exit = () => {
      emit("exit")
    }

    onUpdated(() => {  
    })
  
    return {
      room_meta_reactive,
      select_answer,
      qanswer,
      save,
      start,
      next,
      back,
      end,
      exit,
    }
  }
}
</script>

<style scoped>

.blur {
    font-size: 40px;
    filter: blur(10px);
    -webkit-filter: blur(10px);
  }
</style>
