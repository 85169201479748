import { 
    AudioConfig, SpeechRecognizer, ResultReason, SpeechConfig, PhraseListGrammar
  } from 'microsoft-cognitiveservices-speech-sdk'
  
  class SpeechRec {
    // _recognizer
    // _callback
  
    constructor(callback) {
      this._callback = callback
    }
  
    start(phrase_list,only_final_recognition=false,region='UK') {
      const alreadyStarted = !!this._recognizer


      if (alreadyStarted) {
        return
      }
  
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput()


      let key = "9fc1e389ffa140a4a792b7ce2df349b0"
      let location = "uksouth"
      let language = "en-GB"

      if(region!=undefined && region!=null && region==="US")
      {
        key = "54c7a17622cb4fd9990793f94b3b7057"
        location = "eastus"
        language = "en-US"

      }
      // const speechConfig = SpeechConfig.fromSubscription("54c7a17622cb4fd9990793f94b3b7057", "eastus");
      const speechConfig = SpeechConfig.fromSubscription(key,location);

      // const speechConfig = SpeechConfig.fromSubscription("54c7a17622cb4fd9990793f94b3b7057", "uksouth");

      // const speechConfig = SpeechConfig.fromSubscription("7a58fe610ab54b12bd04411439fed50e", "uksouth");
  
      
      speechConfig.speechRecognitionLanguage = language
      console.debug('language = ' + language)


      this._recognizer = new SpeechRecognizer(speechConfig, audioConfig)
      var phraseList = PhraseListGrammar.fromRecognizer(this._recognizer);

      this.only_final_recognition = false

      // if(phrase_list!=undefined && phrase_list!=null && phrase_list)
      // {

      //   this.only_final_recognition = only_final_recognition
      // }

      this.only_final_recognition = only_final_recognition


      if(phrase_list!=undefined && phrase_list!=null && phrase_list)
      {
          this._phrase_list = phrase_list
          phraseList.clear();

          for(let k in phrase_list)
          {
            phraseList.addPhrase(phrase_list[k])
            phraseList.addPhrase(phrase_list[k] + '.')

          }

          // phraseList.addPhrases(phrase_list)

      }
      else
      {
          this._phrase_list = []
      }

      //   phraseList.clear();
        
      function recognizerCallback(s, e) {
        const result = e.result

        const reason = ResultReason[result.reason]

        console.debug('reason = ' + reason)



        if(this.only_final_recognition)
        {
          if (e.result.reason == ResultReason.RecognizedSpeech) {

            console.debug('result.text = ' + result.text)
              // console.debug(`RECOGNIZED: Text=${e.result.text}`);
              // console.debug('JSON.parse(result.json).NBest[0].Confidence = ' + JSON.parse(result.json).NBest[0].Confidence)
              this._callback({
                original: result.text,
                reason: reason
              })
      
          }

        }
        else
        {
          this._callback({
            original: result.text,
            reason: reason
          })
        }



        // console.debug('recognizerCallback')
        // console.debug('reason = ' + reason)
        // console.debug('result = ' + result.text)

        // console.debug('result keys = ' + Object.keys(result))


        // const captions = {
        //   offset: result.offset,
        //   languages: {}
        // }
        // captions.languages[getLanguageCode(options.fromLanguage)] = result.text

        // for (const lang of options.toLanguages) {
        //   const langCode = getLanguageCode(lang)
        //   const caption = result.translations.get(langCode)
        //   captions.languages[langCode] = caption
        // }

      }

      function getLanguageCode(lang) {
        return lang.substring(0, 2)
      }


      this._recognizer.recognizing = this._recognizer.recognized = recognizerCallback.bind(this)
      this._recognizer.startContinuousRecognitionAsync()
  

    }
    
    stop() {

     if(this._recognizer!=undefined)
     {
         
      this._recognizer.stopContinuousRecognitionAsync(
        stopRecognizer.bind(this),
        function (err) {
          stopRecognizer().bind(this)
          console.error(err)
        }.bind(this)
      )
      
     }
  
      function stopRecognizer() {
        this._recognizer.close()
        this._recognizer = undefined
        console.debug('stopped')
      }
    }
  }
  
  export default SpeechRec




// import { 
//     AudioConfig, SpeechRecognizer, TranslationRecognizer, ResultReason,SpeechConfig,AudioInputStream
//   } from 'microsoft-cognitiveservices-speech-sdk'

  
// export function fromStream() {


//     const fs = require("fs");
//     const speechConfig = SpeechConfig.fromSubscription("54c7a17622cb4fd9990793f94b3b7057", "eastus");
//     speechConfig.speechRecognitionLanguage = "en-GB";

//     let pushStream = AudioInputStream.createPushStream();

//     fs.createReadStream("YourAudioFile.wav").on('data', function(arrayBuffer) {
//         pushStream.write(arrayBuffer.slice());
//     }).on('end', function() {
//         pushStream.close();
//     });
 
//     let audioConfig = AudioConfig.fromStreamInput(pushStream);
//     let recognizer = new SpeechRecognizer(speechConfig, audioConfig);
//     recognizer.recognizeOnceAsync(result => {
//         console.debug(`RECOGNIZED: Text=${result.text}`);
//         recognizer.close();
//     });
// }


// export function fromFile() {
//     // let audioConfig = sdk.AudioConfig.fromWavFileInput(fs.readFileSync("YourAudioFile.wav"));
//     // let speechRecognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);

//     // speechRecognizer.recognizeOnceAsync(result => {
//     //     switch (result.reason) {
//     //         case sdk.ResultReason.RecognizedSpeech:
//     //             console.debug(`RECOGNIZED: Text=${result.text}`);
//     //             break;
//     //         case sdk.ResultReason.NoMatch:
//     //             console.debug("NOMATCH: Speech could not be recognized.");
//     //             break;
//     //         case sdk.ResultReason.Canceled:
//     //             const cancellation = sdk.CancellationDetails.fromResult(result);
//     //             console.debug(`CANCELED: Reason=${cancellation.reason}`);

//     //             if (cancellation.reason == sdk.CancellationReason.Error) {
//     //                 console.debug(`CANCELED: ErrorCode=${cancellation.ErrorCode}`);
//     //                 console.debug(`CANCELED: ErrorDetails=${cancellation.errorDetails}`);
//     //                 console.debug("CANCELED: Did you set the speech resource key and region values?");
//     //             }
//     //             break;
//     //     }
//     //     speechRecognizer.close();
//     // });
// }
