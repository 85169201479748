<template>
  <div>
    <div v-if="results['Minimal Pairs']" id="minimal_pairs">
        <div class="card card-body mt-2">
          <span style="display:flex;justify-content:center;font-size:22px"> Word pairs results</span>
          <div class="row mt-2 justify-content-center" v-for="exercise in results['Minimal Pairs']" >
            <div class="col-6 text-center">
              <div class="row">
                <span class="custom-text"> <strong>Target words:</strong> <p v-for="word, index in exercise['Target_Words']">"<i>{{ word }}</i>" x {{ exercise['Found_Words'][index] }} times</p> </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="results['Word Finding']">
        <div class="card card-body mt-2">
          <span style="display:flex;justify-content:center;font-size:22px"> Word finding exercises</span>
          <div class="row mt-2 justify-content-center">
            <div class="col-12 text-center">
              <div class="row">
                <span class="custom-text"> Number of hints: {{results['Word Finding']['Number_Hints']}}</span>
              </div>
              <div class="row">
                <span class="custom-text"> Number of words: {{results['Word Finding']['Number_Words']}} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-show="results['real_life_exercise'] && results['real_life_exercise'].length > 0">
          <div class="card card-body">
            <div>
              <p class="h4 text-start">Custom Activity Exercises</p>
            </div>
            <table class="table mt-4">
              <thead>
                <tr>
                  <th scope="col">Task</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row, index in results['real_life_exercise']" v-bind:key="index">
                  <th>{{ row.task }}</th>
                  <td>
                    <div class="btn-group"> 
                      <button class="btn btn-danger" @click="playRecording(row['AudioAddress'])"><i class="bi bi-volume-up"></i> Playback</button>
                      <button class="btn btn-secondary" @click="downloadRecording(row['AudioAddress'])"> <i class="bi bi-download"></i> </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>


      <div class="row mt-2" v-if="results['avqi_table'] && results['avqi_table'].length > 0">
        <div class="card card-body">
          <div>
            <p class="h4 text-start">Acoustic voice quality index (AVQI)</p>
          </div>
          <table class="table mt-4" >
            <thead>
              <tr>
                <th scope="col">Exercise Name</th>
                <th scope="col">AVQI</th>
                <th scope="col">HNR</th>
                <th scope="col">CPPS</th>
                <th scope="col">SHIM</th>
                <th scope="col">SHDB</th>
                <th scope="col">Slope</th>
                <th scope="col">Tilt</th>

                <!-- <th scope="col"></th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in results['avqi_table']" v-bind:key="index">
                <td>{{ row.Name }}</td>
                <td>{{ row.avqi }}</td>
                <td>{{ row.hnr }}</td>

                <td>{{ row.cpps }}</td>
                <td>{{ row.shim }}</td>
                <td>{{ row.shdb }}</td>
                <td>{{ row.slope }}</td>
                <td>{{ row.tilt }}</td>
              </tr>
              <tr class="table-group-divider" v-if="results['avqi_table'] && results['avqi_table'].length > 1">
                <th scope="col">Average value</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.avqi, 0) / results['avqi_table'].length).toFixed(0) }}</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.hnr, 0) / results['avqi_table'].length).toFixed(0) }}</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.cpps, 0) / results['avqi_table'].length).toFixed(0) }}</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.shim, 0) / results['avqi_table'].length).toFixed(0) }}</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.shdb, 0) / results['avqi_table'].length).toFixed(0) }}</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.slope, 0) / results['avqi_table'].length).toFixed(0) }}</th>
                <th scope="col">{{ (results['avqi_table'].reduce((sum, value) => sum + value.tilt, 0) / results['avqi_table'].length).toFixed(0) }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <isession_name_picture></isession_name_picture>

      <div v-if="results['Common Sayings']" id="commonSayingsDiv">
        <div class="card card-body mt-2">
          <span style="display:flex;justify-content:center;font-size:22px"> Common Sayings</span>
          <div class="row mt-2 justify-content-center">
            <div class="col-4">
              <div class="row">
                <span class="custom-text"> Correct answers: {{results['Common Sayings']['Correct']}}</span>
              </div>
              <div class="row">
                <span class="custom-text"> Not Recognised: {{results['Common Sayings']['Not Recognised']}} </span>
              </div>
            </div>
            <div class="col-2">
              <div id="chartjs-common-sayings-pie" style="margin:auto">
                <canvas id="canvas-common-sayings"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="results['error_table'] && results['error_table'].length>0" class="row mt-2">
        <div class="card card-body">
          <div slot="header" class="clearfix">
            <span style="float:left" class="custom-text">Error logs (for developers use only)</span>
          </div>
          <table class="table mt-4">
            <thead>
              <tr>
                <th scope="col">Exercise Name</th>
                <th scope="col">Error Log</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in results['error_table']" v-bind:key="index">
                <td>{{ row.Name }}</td>
                <td>{{ row.ErrorType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

  </div>
</template>    

<script>
import {onMounted} from 'vue'
import isession_name_picture from '@/views/session/Result/type/isession_name_picture.vue';

export default {
    name: 'misc_section',
    components: {
      isession_name_picture
    },
    props: ['results'],
    setup(props) {

      onMounted( () => {

      })

      return {
        
      };

    }
  }
  </script>