<template>
  <div>
    <br/>
    <div class="row my-4">
      <h1 class="display-3 text-center"><strong>You are all set</strong></h1>
    </div>
    <div class="row text-center">
      <p class="display-6">Let's start discovering your unique and beautiful voice.</p>
    </div>
    <br/>
    <div class="row mt-4 justify-content-center">
      <div class="col-12 text-center">
        <button class="btn btn-primary rounded-pill px-4" @click="next()">Let's get started</button>
      </div>
    </div>

  </div>
</template>

<script>
import {ref, watch} from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'Personal_Interests',
  setup(props, { emit }) {
    const router = useRouter()
    
    const next = () => {
      router.push({ name: 'home' });
    }

    return {
     next 
    }
  }
}
</script>

<style scoped>

</style>
