<template>
    <div>        
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasContact">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title">Contact us</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body small">
                <div>
                    <div class="row justify-content-around" v-if="!submitted"> 
                        <div class="col-md-5" style="padding-right:35px; border-right: solid 3px #ccc;">
                            <h4>Report issues on the platform</h4>
                            <FormKit type="form" :actions="false" classs="mt-5" @submit="submitForm()">
                                <div class="pt-4 mb-4">
                                    <label class="form-label">Your message <span style="color:red">*</span></label>
                                    <FormKit type="textarea" input-class="form-control" name="message" v-model="message" placeholder="Anything you want to tell us." 
                                        :sections-schema="{
                                        messages: { $el: 'div' },
                                        message: { $el: 'span' },
                                        options: { $el: 'div' },
                                        option: { $el: 'div' },
                                        }"
                                        messages-class="text-danger"
                                        validation="required" />
                                </div>
                                <button class="btn btn-primary" type="submit">Send</button>
                            </FormKit>
                        </div>
                        <div class="col-md-5 text-center">
                            <h4>Call us for support</h4>
                            <div class="card card-body text-center mt-5">
                                <p class="lead">Dr Andre Hallack <br/><font-awesome-icon icon="fa-solid fa-phone" /> 07717252728</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" v-else>
                        <div class="col-5">
                            <div class="card card-body">
                                <p class="lead">Your reference number is <u>{{ reference_number }}</u></p>
                                <p class="lead">We will review your message as soon as possible.</p>
                                <button class="btn btn-secondary mt-4" data-bs-dismiss="offcanvas" @click="reset()">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {updateDataDirectory, setData, getUserDataDir} from '@/firebase/index'

export default {
    props: {

    },
    name: "Contact",
    data() {
        return {
            submitted: false,
            reference_number: 'H42A', 
            message: '',
            title: "Need help? Send us a message or call us.",
        }
    },
    created () {
    },
    destroyed () {
    },
    mounted () {
    },
    methods: {
        submitForm() {
            this.send();
            this.showConfirmation();
        },
        showConfirmation() {



            this.title = "Thank you for your message."

            this.submitted = true
        },
        send() {
            //create new issue and store in database
            //get current url
            let _this = this
            let url = window.location.href

            let uid = localStorage.getItem("uid")
            let hex = '';
            for (let i = 0; i < 6; ++i) {
                hex += (Math.floor(Math.random() * 16)).toString(16);
            }

            this.reference_number = hex

            
            getUserDataDir("Data/", function (err, result) {
                let data = result.val();
                let user_formal_name = data['Firstname'] + data['Surname']
                let email = data['Email']

                let issue = {}
                issue[_this.reference_number] = {url: url, uid: uid, message: "Email: " + email + "\n\nName: " + user_formal_name + "\n\nMsg: " + _this.message}

                let pointer = 'Issues/' + new Date().toDateString()
                updateDataDirectory(pointer, issue)
            });
        }, 
        reset() {
            this.submitted = false
            this.reference_number = 'H42A' 
            this.title = "Need help? Send us a message or call us.",
            this.message = ""
        }
    }
}
</script>

<style scoped>
</style>