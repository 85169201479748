<template>
  <div class="row mt-2">
    <div class="card card-body">
      <div>
        <p class="h4 text-start">Diadochokinetic Rate</p>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <th scope="col">Exercise Name</th>
            <th scope="col">Loudness (dB)</th>

            <th scope="col">Test Duration (s)</th>
            <th scope="col">Repetition Count</th>
            <th scope="col">DDK Rate (syl/s)</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in ddk_table" v-bind:key="index">
            <td>{{ row.Name }}</td>
            <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.test_duration }}</td>
            <td>{{ row.repetition_count }}</td>
            <td>{{ row.ddk_rate }}</td>
            <td>
              <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
            </td>
          </tr>
          <tr class="table-group-divider" v-if="ddk_table && ddk_table.length > 1">
              <th scope="col">Average value</th>
              <th scope="col">{{ (ddk_table.reduce((sum, value) => sum + value.loudness, 0) / ddk_table.length).toFixed(0) }}</th>
              <th scope="col">{{ (ddk_table.reduce((sum, value) => sum + Number(value.test_duration), 0) / ddk_table.length).toFixed(0) }}</th>
              <th scope="col">{{ (ddk_table.reduce((sum, value) => sum + Number(value.repetition_count), 0) / ddk_table.length).toFixed(0) }}</th>
              <th scope="col">{{ (ddk_table.reduce((sum, value) => sum + value.ddk_rate, 0) / ddk_table.length).toFixed(1) }}</th>
            </tr>
        </tbody>
      </table>
      <div class="row">
        <span>Typical value range based on 5s test duration</span>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <th scope="col">Category</th>
            <th scope="col">DDK Rate (syl/s)</th>
            <th scope="col">Repeated /Pa/, /Ta/ or /Ka/</th>
            <th scope="col">/Pa Ta Ka/</th>
            <th scope="col">/Ka La/</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in ddkNormTable" v-bind:key="index">
            <td>{{ row.category }}</td>
            <td>{{ row.ddk_rate }}</td>
            <td>{{ row.single_pa_ta_ka }}</td>
            <td>{{ row.ptk }}</td>
            <td>{{ row.kala}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>
  
<script>
  import firebase from 'firebase/app'
  import {ref, onUpdated, onMounted} from 'vue'
  import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
    name: 'ddk_table',
    components: {
      Audio_Playback
    },
    props: {
      ddk_table: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      onMounted( () => {

      });

      const audio_player = ref(null)


      const ddkNormTable = [{
        category:'Normal',
        ddk_rate : '2.9-6.3',
        single_pa_ta_ka:'20-30',
        ptk:'12-15',
        kala:'15-20'
      },
      {
        category:'Good',
        ddk_rate : '',
        single_pa_ta_ka:'15-19',
        ptk:'8-11',
        kala:'10-14'
      },
      {
        category:'Fair',
        ddk_rate : '',
        single_pa_ta_ka:'8-14',
        ptk:'4-7',
        kala:'5-9'
      },
      {
        category:'Poor',
        ddk_rate : '',
        single_pa_ta_ka:'1-7',
        ptk:'1-3',
        kala:'1-4'
      }]

      const playAudio = (audio_file_location) => {

        let storage = firebase.storage()
        storage.ref(audio_file_location).getDownloadURL().then((url) => {
          audio_player.value = new Audio(url);
          audio_player.value.play();

          audio_player.value.addEventListener('ended', () => {
            audio_player.value = null
          });

        }).catch((error) => {

        })
      }

      const playRecording = (address) => {
        playAudio(address)
      }

      const pauseAudio = () => {
        audio_player.value.pause()
        audio_player.value = null
      }

      const saveBlob = (blob, fileName) => {
        var a = document.createElement('a');
        let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
        a.href = window.URL.createObjectURL(audioBlob);
        a.download = fileName;
        a.click()
      }

      const downloadRecording = (address) => {
        let storage = firebase.storage();

        storage.ref(address).getDownloadURL().then((url) => {

          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            saveBlob(blob, 'beautiful_voice_audio.webm');
          };
          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {

        });
      }

      return {
        downloadRecording,
        pauseAudio,
        playRecording,
        ddkNormTable
      };

    }
  }
</script>