<template>
  <div>
    <div class="row">
      <span class="title_txt"><img :src="researchImg" style="width:50px;float:left;margin-top:15px;margin-left:10px;"/>User activity tracking</span>
    </div>
    <div class="row">
      <div class="card card-body m-4">
        <div class="row">
          <input placeholder="Input user ID" v-model="inputID" style="width:40%" />
        </div>
        <div class="row">
          <button class="btn btn-primary mt-2 w-50" @click="addID()">Confirm</button>
        </div>
        <div class="row">

          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">User ID</th>
                <th scope="col">Number of logins</th>
                <th scope="col">Latest login date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user, id in tableData" v-bind:key="id" style="cursor: pointer" @click="handleCurrentChange(user)">
                <th scope="row">{{ id }}</th>
                <td>{{ user.name }}</td>
                <td>{{ user.number }}</td>
                <td>{{ user.last }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import patientImg from '@/assets/images/patient.png'
import taskImg from '@/assets/images/task.png'
import addImg from '@/assets/images/add.png'
import warnImg from '@/assets/images/warn.png'
import homeImg from '@/assets/images/home.png'
import backImg from '@/assets/images/back.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import rightArrow from '@/assets/images/bxs-right-arrow-white.png'
import researchImg from '@/assets/images/research.png'


import { getData, setData } from '@/firebase/index'


export default {
  data() {
    return {
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      patientImg: patientImg,
      taskImg: taskImg,
      rightArrow: rightArrow,
      addImg: addImg,
      warnImg: warnImg,
      homeImg: homeImg,
      backImg: backImg,
      targetIcon: targetIcon,
      doneIcon: doneIcon,
      researchImg:researchImg,
      inputID:'',
      tableData:[],
      group_talking: ['News','Joke','Tongue','WordCounting'],
      group_clarity:['Tikofsky','Oral','Articulation_Consonants','Articulation_Words'],
      group_loudness:['LSVT_Words','Max_Phonation','Pace','voiceAssessment'],
      group_memory:['quiz_game'],
      group_words:['ImageDescription','WordFinding','WordNaming','image_game'],
    }
  },
  created() 
  {
    this.getID();
  },
  methods: 
  {
    BackToHome()
    {
      this.$router.push({ name: 'home' });
    },
    handleCurrentChange(val)
    {
      let _this = this;
      let name = val['name'];
      let activity_list = val['tags'];
      let activity_count = val['tag_num'];
      // console.debug('name= '+name);
      _this.$router.push({ name: 'patient_history_display_master',params: {name: name,activity_list:activity_list,activity_count:activity_count}});
    },
    addID()
    {
      let _this = this;
      let clients_page_ref = _this.$firebase.database().ref('UserTracking');
      if(_this.inputID!='')
      {
        let usr_page_ref = _this.$firebase.database().ref('Users').child(_this.inputID).child('Activity')
        _this.getTableInfo(usr_page_ref,_this.inputID);
        
        setData(clients_page_ref.child(_this.inputID),{name:_this.inputID});
      }
    },
    calculateDuration(t1,t2)
    {
      let time1 = t1.split(':');
      let time2 = t2.split(':');
      let mm1 = time1[0]*60 + time1[1];
      let mm2 = time2[0]*60 + time2[1];
      return mm2-mm1
    },
    findActivity(str)
    {
      let arr = [];
      let activity = '';
      for(let k = 0; k<5;k++)
      {
        arr = [];
        if(k == 0)
        {
          arr = this.group_talking;
        }
        else if(k == 1)
        {
          arr = this.group_clarity;
        }
        else if(k==2)
        {
          arr = this.group_loudness;
        }
        else if(k == 3)
        {
          arr = this.group_memory;
        }
        else if(k == 4)
        {
          arr = this.group_words;
        }

        for(let i=0;i<arr.length;i++)
        {
          if(str.includes(arr[i]))
          {
            let pos = str.indexOf(arr[i]);
            activity = str.substring(pos);
            if(activity.includes('/'))
            {
              activity = str.substring(pos,str.indexOf('/',pos));
            }
            // console.debug('pos= '+pos);
            // console.debug('words= '+activity);
          }
        }

      }
      return activity
    },
    getID()
    {
      let _this = this;
      let clients_page_ref = _this.$firebase.database().ref('UserTracking');
      _this.tableData = [];
      let id_temp = [];
      let do_once = true;
        getData(clients_page_ref , function (err, result) {
          if (do_once) 
          {
            result.forEach(function (childSnapshot) 
            {
              id_temp.push(childSnapshot.child('name').val());
            }); 

            for(let i=0;i<id_temp.length;i++)
            {
              let usr_page_ref = _this.$firebase.database().ref('Users').child(id_temp[i]).child('Activity')
              _this.getTableInfo(usr_page_ref,id_temp[i]);
            }
          }
        });
    },
    getTableInfo(usr_page_ref,usr_name)
    {
      let _this = this;
      let do_once = true;
      getData(usr_page_ref , function (err, result) {
        if (do_once) 
        {
          let dates = [];
          let date0 = '';
          let t0 = 0;
          let dur = [];
          let activity_list = [];
          let activity_count = [];
          // let categ = result.child('Data').child('category').val();
          result.forEach(function (childSnapshot) 
          {
            let all_dates = childSnapshot.key.substring(0,10);
            let time = childSnapshot.key.substring(10,16);
            let activity = _this.findActivity(childSnapshot.val());
            if(activity_list.includes(activity))
            {
              activity_count[activity_list.indexOf(activity)] = activity_count[activity_list.indexOf(activity)]+1;
            }
            else
            {
              activity_list.push(activity);
              activity_count.push(1);
            }
            if(all_dates==date0)
            {
              let dur_temp = _this.calculateDuration(t0,time);
              dur[dur.length-1] = dur_temp;
            }
            else
            {
              date0 = all_dates;
              t0 = time;
              dur.push(0);
            }
            if(!dates.includes(all_dates))
            {
              dates.push(all_dates);
            }
          }); 
          console.debug('activitylist= '+activity_list)
          _this.tableData.push({name:usr_name,number:dates.length,last:dates[dates.length-1],tags:activity_list,tag_num:activity_count});
        }
      });
    },

  },
}
</script>
<style scoped>
.std-pre{
  word-break: break-all

}
.page_title {
  width: 100%;
  height: 80px;
  background-color: #fce5c6;
}
.page_main {
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #ebe1d8;
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 80px;
  margin-left: 2%;
  font-size: 40px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}


</style>