<template>
    <div class="row">
      <div class="col-md-10">
        <div class="card card-body h-100" style="text-align: center;"> 
          <div class="row">
            <div class="card card-body">
              <h2>
                {{ instructions_text }}
                <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="TTSInstructions()">
              </h2>
              <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->

           
              
            </div>
          </div>
          <div class="row" style="height: 15mm;">
            <span :style="interaction_message_color"> {{  interaction_message}}</span> 
          </div>

          <div class="d-flex justify-content-center flex-wrap">
            <button v-for="i in full_text_array.length" :key="i" :class="[btn_class[i-1], 'm-2']" style="margin-left: 2mm;font-size: xx-large;" @click="clickWord(i-1)">
              {{ full_text_array[i-1] }}
            </button>

          </div>
        </div>
      </div>
      <div class="col-md-2" style="padding-left: 0px">
        <div class="card card-body h-100">

          <p class="lead">Exercise {{curr_ex_number}} of {{number_exercises}}</p>
        <hr/>

          <p class="lead" font-size="medium">Task {{Math.min(task,n_tasks)}} of {{n_tasks}}</p>

          <!-- <button v-show="if_recorder && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="playPause()">Record</button> -->

          <button v-show="is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
          </button>

          <button v-show="!start_btn_disabled && !is_recording" class="btn btn-primary btn-lg w-100 mt-2"  @click="start()">{{ start_btn_text }}
            <i v-bind:class="[start_btn_text==='Start' ? 'bi bi-play-circle' : 'bi bi-arrow-clockwise']" style="margin-left: 8mm;"></i>
          </button>
          <button v-show="start_btn_disabled && !is_recording" class="btn btn-primary btn-lg w-100 mt-2" disabled>
            <div class="spinner-border spinner-border-sm" role="status"></div> Reading...
          </button>

          <button class="btn-info btn btn-lg w-100 mt-2" @click="nextTask()">Next task <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>


          <!-- <button class="btn btn-secondary btn-lg w-100 mt-2" @click="skipExercise()">Skip level</button> -->
          <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results </button>
        </div>
      </div>

      <div class="modal fade" id="finished_pop" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Congratulations</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body m-2">
              <div class="row">
                <div class="row">
                  <h4 >{{ finished_text }}</h4>

                </div>
                <div class="row mt-2">
                  <button class="btn btn-success w-100"   data-bs-dismiss="modal" @click="nextExercise()" >Next </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="nothing_heard_pop" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Sorry, we couldn't hear you well</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body m-3">
              <div class="row">
                <div class="row">
                  <h4 >Click on Start to try again. Or Next to skip this exercise</h4>

                </div>
                <div class="row mt-2">
                  <button class="btn btn-success w-100"   data-bs-dismiss="modal" >Back to the exercise</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>
<script>

import { Modal } from 'bootstrap'

import {getUserDataDir,getData,updateUserData} from '@/firebase/index'
import audioImg from '@/assets/images/audio_logo.png'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import {logActivity,removePunctuationFromString} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import {LevenshteinDistance} from '@/utils/text_distance_metrics'
import SpeechRec from '@/media_input/SpeechRecognition'
import { useTTS } from '@/stores/tts'

  // SLT\Exercises\Reading comprehension\Reading task
  export default {
    name: 'orla',
    props: 
    {
      exForm: {
        type: Object,
      },
    },
    data(){
      return {
        instructions_text:'',
        finished_text:'Congratulations, you finished the exercise. Click Next',
        first_exercise:true,
        userCategory:'',
        sentence_time_gap:300,
        start_btn_text:'Start',
        region:'UK',
        curr_ex_number: 0,
        number_exercises: 0,
        n_tasks:5,
        no_hear_message_timeout:null,
        audioImg:audioImg,
        readAndHighlightTimeout: [],
        currentSentence: '',
        speech_rec:null,
        continue_read_and_highlight:false,
        target_words:null,
        is_recording:false,
        diff_level:'easy', //easy, medium, hard for image/word level
        results:{correct:0,incorrect:0,total:0},
        first_attempt:true,
        current_setup:{},
        current_question_id:0,
        total_question_num:1,
        answer_type:'',
        total_q_a:[],
        if_recorder:true,
        level_2_question_number:0,
        btn_class: [],
        interaction_message_color:'',
        interaction_message:'',
        level_up_counter:0,
        level_up_target:3,
        task:1,
        random_index:0,
        start_btn_disabled:false,
        bv_tts: useTTS().tts_engine,
        full_sentence: 'I am well, thanks',
        full_text_array:[]
      }
    },
    created() {
  
      const _this = this
  
      this.userCategory = localStorage.getItem('userCategory');
      let region = localStorage.getItem('region');
        
      if(region!=undefined && region!=null)
      {
        _this.region = region
      }

      _this.bv_tts.say('')
  

      _this.first_exercise = !_this.exForm.has_results

      logActivity(_this.$firebase, 'accessed', 'orla')
      
      _this.curr_ex_number = _this.exForm.current_exercise_index + 1
      _this.number_exercises = _this.exForm.total_all_type_exercises

      this.full_sentence = this.exForm.name
  
      _this.full_text_array = this.full_sentence.split(' ')

      this.setupTask()

    },
    beforeUnmount()
    {
      this.clearAll()


    },
    mounted()
    {

    },
    computed: {
      currentSentence_found: function () {
        const _this = this

        console.debug('this.currentSentence = ' + this.currentSentence)
        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.is_recording)
        {

            let curr_sentence = this.currentSentence.toString().toLowerCase()

            var regex = /[.,]/g;

            curr_sentence = curr_sentence.replace(regex, '');

            let words = curr_sentence.split(' ')

            console.debug('words = ' + words)

            let copy_target_words = [..._this.target_words]; //reactivity

            let found_something = false


            for(let i in words)
            {

                let minimum_distance = 100

                let word = words[i]

                console.debug('word = ' + word)

                for(let k in copy_target_words)
                {
                  
                  let distance = LevenshteinDistance(copy_target_words[k],word)

                  distance = distance/Math.max(copy_target_words[k].length,1)


                  if(distance<minimum_distance && distance<0.25)
                  {
                      // best_answer = this.target_words[i]
                      // this.stop_recording()
                      // found_something = true

                      const index = _this.target_words.indexOf(copy_target_words[k]);
                      if (true) { // only cut when it is the first word

                        // const display_index = this.full_text_array.indexOf(copy_target_words[k])


                        // this.btn_class[index] = 'correct-button'


                        found_something = true

                        if(_this.target_words.length>0)
                        {

                          _this.target_words.splice(index, 1); // 2nd parameter means remove one item only


                        }



                      }   
                  }
                }
            }

            if(_this.target_words.length==0)
            {
              this.speech_rec.stop()

              this.is_recording = false

              setTimeout(() => {
                this.foundAllWords()
              }, 400);


            }
            return curr_sentence
        }
        return ''
      },
    },
    methods: {
        clearAll()
        {
          this.bv_tts.cancel()

          this.is_recording = false
          this.start_btn_disabled = false

          
          if (this.no_hear_message_timeout) {
              window.clearTimeout(this.no_hear_message_timeout); // will do nothing if no timeout with id is present
          }

          this.continue_read_and_highlight = false

          for(let n in this.readAndHighlightTimeout)
          {
            if (this.readAndHighlightTimeout[n]) {
              window.clearTimeout(this.readAndHighlightTimeout[n]); // will do nothing if no timeout with id is present
            }


          }

        },
        skipExercise()
        {

          this.clearAll()
          this.$emit('nextExercise');
        },
        foundAllWords()
        {
          this.interaction_message = 'Well done, you did it!'
          this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
          startConfetti(this)

          this.if_recorder = false

          this.results['correct'] += 1
          this.results['total'] += 1


          setTimeout(() => {
              this.nextTask()
          }, 200);
        },
        saveResults()
        {
          const _this = this;

          if(_this.results['total']>0)
          {
            let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total']}
            
            let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
            let results = {all_results: all_results, plot_results:plot_results}
            
            var orlaData = {resultsTablePre: {exercise_type: 'Read Sentences', task: _this.task, results: results, audio_address:''}}
            
            
            save_pre_computed_results(_this.exForm,_this.$firebase,orlaData)
            _this.$emit('logExerciseResults', orlaData);

            _this.results['correct'] = 0
            _this.results['incorrect'] = 0
            _this.results['total'] = 0



          }
          

        },
        nextTask()
        {
          if(this.task==5 && this.results['total']==0)
          {
            this.results['total'] = 1
            this.results['incorrect'] = 1

          }
          this.task = this.task+1

          this.saveResults()

          this.clearAll()

          this.setupTask()

        },
        nextExercise()
        {
          const _this = this;
       
          
          this.clearAll()

          _this.$emit('nextExercise');
            
        },
        goToResults()
        {
            const _this = this
           
            
            
            var goToResultsData = {curr_ex_done:false}
            
            
            _this.$emit('goToResults', goToResultsData);
                        
        },
        readAndHighlight(n)
        {
            const _this = this

            _this.readAndHighlightTimeout = []


            if(n<this.full_text_array.length)
            {

                let curr_word = this.full_text_array[n]

                
                _this.btn_class[n] = 'correct-button'
                this.$forceUpdate()

                _this.bv_tts.say(curr_word)



                _this.readAndHighlightTimeout.push(setTimeout(function() { 

                  if(_this.continue_read_and_highlight)
                  {
                    _this.readAndHighlight(n+1)

                  }

                },1000) )  
            }
            else
            {
              this.start_btn_disabled = false
            }

        },
      playPause()
      {

        this.speech_rec = new SpeechRec(function(captions) {
            this.currentSentence = captions.original
        }.bind(this))

        this.is_recording = true



        this.speech_rec.start(this.target_words,false,this.region)

        this.no_hear_message_timeout = setTimeout(() => {
          if(this.is_recording)
          {
            this.speech_rec.stop()
            this.is_recording = false

            let nothing_heard_pop = new Modal(document.getElementById('nothing_heard_pop'));
            nothing_heard_pop.show()

          }
        }, 30000);


      },
      start()
      {
        if(this.task===1)
        {

          this.runListenAndRead()

        }
        else if(this.task==2)
        {
          this.runChoralReading()
        }
        else if(this.task==3)
        {

          this.runIndependentReading()

        }
        else if(this.task==4)
        {
          this.runFindWord()
        }
        else if(this.task==5)
        {
          this.runReadWordAloud()
          
        }
      },
      setupTask()
      {

        if (this.no_hear_message_timeout) {
            window.clearTimeout(this.no_hear_message_timeout); // will do nothing if no timeout with id is present
        }

        this.bv_tts.cancel()

        this.start_btn_text = 'Start'

        this.interaction_message = ''

        if(this.task==1)
        {
          this.setupListenAndRead()
        }
        else if(this.task==2)
        {
          this.removeHighlightedWords()

          this.setupChoralReading()
        }
        else if(this.task==3)
        {
          this.removeHighlightedWords()

          this.setupIndependentReading()
        }
        else if(this.task==4)
        {
          this.removeHighlightedWords()

          this.setupFindWord()
        }
        else if(this.task==5)
        {
          this.removeHighlightedWords()

          this.setupReadWordAloud()
        }
        else
        {
          this.removeHighlightedWords()

          this.finishedAllTasks()
        }

      },
      TTSInstructions()
      {
        if(this.task==1)
        {
          this.bv_tts.say_sentences([this.instructions_text, this.full_sentence], this.sentence_time_gap)
        }
        else if(this.task==2)
        {
          this.setupChoralReading()
        }
        else if(this.task==3)
        {

          this.runIndependentReading()

        }
        else if(this.task==4)
        {
          this.runFindWord()
        }
        else if(this.task==5)
        {
          this.runReadWordAloud()
          
        }

      },
      finishedAllTasks()
      {

        this.bv_tts.say(this.finished_text)

        let finished_pop = new Modal(document.getElementById('finished_pop'));
        finished_pop.show()

      },
      setupListenAndRead()
      {
        this.instructions_text = 'Listen to the sentence.'

        let end_text = 'Press Repeat to hear it again.'

        this.bv_tts.say_sentences([this.instructions_text,this.full_sentence,end_text], this.sentence_time_gap)


        this.start_btn_text = 'Repeat'


      },
      runListenAndRead()
      {
        this.bv_tts.say(this.full_sentence)
        this.start_btn_text = 'Repeat'

      },
      setupChoralReading()
      {

        // Task 2

        this.instructions_text = "Click Start. Let's read it together."

        this.bv_tts.say(this.instructions_text)


      },
      removeHighlightedWords()
      {
        for(let n in this.full_text_array)
        {
            this.btn_class[n] = ''

        }
        this.$forceUpdate()

      },
      runChoralReading()
      {
        // Task 2
        const _this = this

        this.start_btn_disabled = true

        _this.bv_tts.cancel()

        this.removeHighlightedWords()

        
   

        _this.continue_read_and_highlight = true

        // _this.bv_tts.say(this.full_sentence)
        _this.readAndHighlight(0)
        // setTimeout(function () {_this.readAndHighlight(0)}, 4000);
        this.start_btn_text = 'Repeat'

      },   
      setupIndependentReading()
      {
         // Task 3

        this.instructions_text = 'Read aloud after me.'

        this.bv_tts.say_sentences([this.instructions_text,this.full_sentence], this.sentence_time_gap)

        this.start_btn_text = 'Repeat'


      },  
      runIndependentReading()
      {
        // task 3
        this.instructions_text = 'Read aloud after me.'

        this.bv_tts.say_sentences([this.instructions_text,this.full_sentence], this.sentence_time_gap)


        this.start_btn_text = 'Repeat'

      },
      setupFindWord()
      {
        // task 4

        this.random_index = Math.floor(Math.random() * this.full_text_array.length);

        this.target_click_word = removePunctuationFromString(this.full_text_array[this.random_index])

        this.instructions_text = 'Click on the following word.'

        let first_text = 'Click on the following word.'

        // let end_text = 'Press Repeat to hear it again.'

        let the_word_is = 'The word is; ' + this.target_click_word


        this.bv_tts.say_sentences([first_text,the_word_is], this.sentence_time_gap)
        this.start_btn_text = 'Repeat'



      },
      runFindWord()
      {
        // task 4


        let first_text = 'Click on the following word. '


        let the_word_is = 'The word is; ' + this.target_click_word


        this.bv_tts.say_sentences([first_text,the_word_is], this.sentence_time_gap)

      },
      setupReadWordAloud()
      {
        // task 5
        
        let prev_index = this.random_index

        let rand_index = Math.floor(Math.random() * this.full_text_array.length);

        if(rand_index==prev_index)
        {
          if(rand_index>0)
          {
            rand_index = rand_index-1
          }
          else
          {
            rand_index = rand_index+1
          }
        }

        this.target_click_word = this.full_text_array[rand_index]

        for(let n in this.btn_class)
        {
          this.btn_class[n] = ''
        }

        this.btn_class[rand_index] = 'correct-button'

        this.$forceUpdate()

        this.instructions_text = 'Say the highlighted word aloud.'

        this.bv_tts.say(this.instructions_text)

        this.target_words = []

        this.target_words.push(removePunctuationFromString(this.full_text_array[rand_index]))

        this.playPause()

      },
      runReadWordAloud()
      {
        this.instructions_text = 'Say the highlighted word aloud.'

        this.bv_tts.say(this.instructions_text)

        this.playPause()
      },
      clickWord(input_id)
      {
        if(this.task==4)
        {
          this.checkClickWord(input_id)

        }
        else
        {
          let curr_word = this.full_text_array[input_id]

          this.bv_tts.say(curr_word)

          if(this.task!=5)
          {
            for(let n in this.btn_class)
            {
              this.btn_class[n] = ''
            }

            this.btn_class[input_id] = 'correct-button'



          }


        }
      },
      checkClickWord(input_id)
      {

        // task 4

        let not_click_word = false

        let input = this.full_text_array[input_id]

        input = removePunctuationFromString(input)

        if(input === this.target_click_word)
        {

            if(this.first_attempt)
            {
              this.results.correct = this.results.correct + 1
              this.results.total = this.results.total + 1

            }

            this.first_attempt = true
            this.btn_class[input_id] = 'correct-button'
            this.interaction_message = 'Well done! You got it right'
            this.interaction_message_color = 'fontSize:x-large;color:green;white-space: pre-wrap'
            startConfetti(this)
            this.$forceUpdate()
        }
        else
        {

            if(this.first_attempt)
            {
              this.results.incorrect = this.results.incorrect + 1
              this.results.total = this.results.total + 1

            }

            this.first_attempt = false
            this.btn_class[input_id] = 'incorrect-button'
            this.interaction_message = 'Oops, a tricky one. Try again!'
            this.interaction_message_color = 'fontSize:x-large;color:red;white-space: pre-wrap'
            this.$forceUpdate()
            setTimeout(() => {
                this.btn_class[input_id] = 'basic-button'
                this.interaction_message = ''
                this.$forceUpdate()
            }, 1200);

        }
      },
      },
      nextQuestion()
      {
        this.current_question_id++
        this.interaction_message = ''
        this.bv_tts.say(this.total_q_a[this.current_question_id].qs)
        this.$forceUpdate()
      },
      
  }
  </script>
  <style scoped>
  
  .custom-text{
      font-size: 20px;
      font-family: Arial;
  }

  .basic-button
  {
    color: black;
    background-color: lightgrey;
    border-color: black;
  }

  .basic-button:hover
  {
    background-color:#002ead;
    transition: 0.7s;

  }

  .basic-wide-button:hover
  {
    background-color:#002ead;
    transition: 0.7s;

  }

  .basic-wide-button
  {
    color: blueviolet;
    background-color: lightgray;
    border-color: black;
    width: 45mm;
  }
  .incorrect-wide-button
  {
    color: white;
    background-color: red;
    border-color: red;
    width: 45mm;
  }

  .correct-button
  {
    color:white;
    background-color: green;
    border-color: green;
  }
  .incorrect-button
  {
    color:white;
    background-color: red;
    border-color: red;
  }
  
  </style>
