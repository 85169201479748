<template>
  <div class="container pb-5">

    <v-tour name="results_page_tour" :steps="steps_results"></v-tour>
    <div class="row v-patient_results-0">
      <ul class="nav nav-pills nav-fill">
        <li class="nav-item">
          <button :class="['nav-link', {'active' : activeTabName === 'clinic'}]" @click="activeTabName='clinic';isAssessmentPackage=false">Clinic Training</button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', {'active' : activeTabName === 'home'}]" @click="activeTabName='home';isAssessmentPackage=false">Home Training </button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', {'active' : activeTabName === 'assessment'}]" @click="activeTabName='assessment';isAssessmentPackage=false">Assessment</button>
        </li>
        <li class="nav-item">
          <button :class="['nav-link', {'active' : activeTabName === 'assessment_package'}]" @click="activeTabName='assessment_package';isAssessmentPackage=true">Assessment Package</button>
        </li>
      </ul>
    </div>
    <div v-if="!isAssessmentPackage" class="v-patient_results-1">
      <div class="row text-start mt-4 mb-4">
        <label class="col-3 col-form-label">Monitoring period</label>
        <div class="col-9">
          <div class="row">
            <div class="col">
              <date-picker
                input-class="form-control"
                v-model:value="drange"
                type="date"
                range
                format="DD/MM/YYYY"
                placeholder="Select date range"
                :clearable="false"
              >
                <template #sidebar="{ emit }">
                  <div style="text-align: left">
                    <button class="mx-btn mx-btn-text" @click="emit(monitor_range['Today'])">
                      Today
                    </button>
                  </div>
                  <div style="text-align: left">
                    <button class="mx-btn mx-btn-text" @click="emit(monitor_range['Yesterday'])">
                      Yesterday
                    </button>
                  </div>
                  <div style="text-align: left">
                    <button class="mx-btn mx-btn-text" @click="emit(monitor_range['Last month'])">
                      Last month
                    </button>
                  </div>
                  <div style="text-align: left">
                    <button class="mx-btn mx-btn-text" @click="emit(monitor_range['This month'])">
                      This month
                    </button>
                  </div>
                  <div style="text-align: left">
                    <button class="mx-btn mx-btn-text" @click="emit(monitor_range['This year'])">
                      This year
                    </button>
                  </div>
                  <div style="text-align: left">
                    <button class="mx-btn mx-btn-text" @click="emit(monitor_range['All time'])">
                      All time
                    </button>
                  </div>
                </template>
              </date-picker>
            </div>

          </div>
        </div>
      </div>
      <div class="row text-start">
        <div class="col-9">
          <div class="row">
            <label for="selected_module" class="col-4 col-form-label">Module</label>
            <div class="col-8">

              <select v-model="selected_module" class="form-select" id="selected_module" @change="changeSelectedModule(selected_module)">
                <option v-for="module_v in all_modules" :value="module_v">{{module_v}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-3">
          <button class="btn btn-success" @click="()=>this.$router.push({ name: 'results_list', query: { start_date: new Date(this.drange[0]).setHours(0, 0, 0, 0), end_date: new Date(this.drange[1]).setHours(23, 59, 59, 59), list_type: activeTabName, requested_module: selected_module } })">View</button>
        </div>
      </div>
      <div class="row mt-4" v-if="loading_metrics && !Object.keys(results_by_module[selected_module]).length > 0">
        <div class="col-12">
          <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div class="row mt-4 text-start" v-if="selected_module in results_by_module && Object.keys(results_by_module[selected_module]).length > 0">
        <div class="col-9">
          <div class="row">
            <label for="selectMetric" class="col-4 col-form-label">Available Metric</label>
            <div class="col-8">
              <div class="mt-2">

                <div class="form-check form-check-inline" v-for="(metric, index) in Object.keys(results_by_module[selected_module])">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" :id="index" :value="metric" v-model="selected_metric">
                  <label class="form-check-label" :for="index">{{ metrics_map[metric] }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <vue3-html2pdf v-show="selected_metric && showChart && selected_module in results_by_module && Object.keys(results_by_module[selected_module]).length > 0"
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1800"
        :filename="metrics_map[selected_metric] + '_' + activeTabName + 'training_graph'"
        :pdf-quality="5"
        :manual-pagination="false"
        :margin="[0, 0]"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        ref="html2Pdf"
      > 
        <template  v-slot:pdf-content>
          <div class="container">
            <div class="mt-4 text-center" v-show="showGraphTitle">
              <h5>{{ activeTabName.charAt(0).toUpperCase() + activeTabName.slice(1) }} training ({{ selected_module }})</h5>
            </div>
            <div class="row mt-2 mb-4 justify-content-center text-center" style="height: 350px; width: 100%;">
              <canvas id="chartContainer" class="mt-4"></canvas>
            </div>
          </div>
        </template>
      </vue3-html2pdf>
      <button class="mt-2 btn btn-primary" 
        v-if="selected_metric && showChart && selected_module in results_by_module && Object.keys(results_by_module[selected_module]).length > 0" 
        @click="generateGraphPDF()">Download Graph</button>
    </div>
    <div v-else>
      <div class="mt-4">
        <div v-if="all_packages.length > 0" class="row justify-content-center mb-4">
          <h6>Select Package</h6>
          <select v-model="selected_package" class="form-select" id="selected_module" style="width: 300px;">
            <option v-for="module_v in all_packages" :value="module_v">{{module_v}}</option>
          </select>
        </div>
        <div v-else class="row justify-content-center mb-4">
          <p class="mt-4">There are no Assessment Package results yet</p>
        </div>
        <div v-if="selected_package" class="row justify-content-center mb-4">
          <h6>Select Date</h6>
          <select v-model="selected_completed_package_date" class="form-select" id="selected_module" style="width: 300px;">
            <option v-for="option in dates_of_completed_packages[selected_package]" :value="option">{{option}}</option>
          </select>
          <div class="row mt-4">
            <Package_report v-if="selected_completed_package_date" :assessment_results_address="selected_package_exercise_address[selected_package][selected_completed_package_date]" :session_date="selected_completed_package_date"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import {updateDataDirectory, getData, updateData, getUserDataDir } from '@/firebase/index'

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import Package_report from "./package_report.vue"
import {getMetricPaths, agregateForPlot} from '@/utils/results/common'

import Vue3Html2pdf from 'vue3-html2pdf'


export default {
  name: 'Results',
  components: { DatePicker, Package_report, Vue3Html2pdf },
  data() {
    return {
      activeTabName: localStorage.getItem('results_active_tab') ? localStorage.getItem('results_active_tab'): 'clinic',
      selected_metric: '',
      selected_module: 'All modules',
      selected_package: '',

      monitor_range: {},
      drange: [new Date(), new Date()],
      isAssessmentPackage: false,

      plot_results: {},

      //////////////////////////////////////
      results_by_module: {},
      result_dates_by_module: {},
      
      all_modules: [],
      all_packages: [],
      /////////////////////////////////////

      dates_of_completed_packages: {},
      selected_completed_package_date: '',
      selected_package_exercise_address: {}, 

      loading_metrics: true,

      metrics_map: {
        "intelligibility": "Word Intelligibility",  
        "sentencegibility": "Sentence Intelligibility", 
        "speechrate_dictionary": "Speech Rate", 
        "loudness": "Loudness", 
        "ddk_rate_dictionary": "DDK rate",
        "plot_results": "% Correct Answers"
      },
      /*
        "localJitter": 'Local Jitter (%)' , 
        "localShimmer": 'Local Shimmer (%)' , 
        "meanF0": 'Mean F0 (Hz)' 
      */
      chart: null,
      showGraphTitle: false,
      showChart: false,


      steps_results: [
      {
        target: '.v-patient_results-0',
        content:  "<div class='text-start'>" +
                    "The platform tracks all the assessment and training modules performed by the patient at home or in clinic (including video sessions with you).<br/>" +
                    "<ul>" + 
                      "<li>Clinic Training: you can find results for all exercises under the Training tab here.</li>" +
                      "<li>Home Training: you can find results of homework here as well as other exercises your patient completes at home through the platform.</li>" +
                      "<li>Assessment: you can find results for all exercises under the Assessment tab (excluding Assessment package).</li>" +
                      "<li>Assessment Package: this is specifically for  results of the assessment packages whether they are completed in clinic or at home.</li>" +
                    "</ul>" +
                  "</div>",
        params: {
          highlight: true,
        }
      },
      {
        target: '.v-patient_results-1',
        content:  "<div class='text-start'>" +
                    "You can filter the results by date, module and quantitative metrics.<br/>" +
                    "Click View allows you access to detailed results of individual exercises." +
                  "</div>",
        params: {
          highlight: true,
        }
      }],
    }
  },
  filters: {
    date(val) {
      return val ? new Date(val).toDateString(): "";
    }
  },
  created() {
    const _this = this
    this.loadResults(this.activeTabName)

    let user_page_dir = 'Data/'

    getUserDataDir(user_page_dir , function (err, result) {
      let startTour = result.child('startTour').val();

      if (startTour === null || startTour['results_page_tour'] === undefined || startTour['results_page_tour'] === false) {
        _this.$tours['results_page_tour'].start()
        
        if (typeof startTour !== "object") {
          startTour = {}
        }

        startTour['results_page_tour'] = true

        let uid = localStorage.getItem('uid')
        let ref_path = 'Users/' + uid + '/Data'

        updateDataDirectory(ref_path, {'startTour': startTour})
        
      }
    });
  },
  watch:
  {
    activeTabName(newTabName, oldTabName) {

      localStorage.setItem('results_active_tab', newTabName)
      this.loadResults(newTabName)

      if (this.selected_module != "All modules") {

        if (Object.keys(this.results_by_module[this.selected_module].length > 0)) {
          this.selected_metric = Object.keys(this.results_by_module[this.selected_module])[0]
        } else {
          this.selected_metric = ''
          this.showChart = false
        }

        this.selected_module = "All modules"
      } 
    },
    selected_metric(newMetric, oldMetric) {
      const _this = this
      
      if (this.selected_metric != '' && !this.isAssessmentPackage) {
        _this.processAndCreateChart(newMetric)
      }
    },
    drange() {
      if (this.selected_metric != '' && !this.isAssessmentPackage && !this.loading_metrics) {
        this.processAndCreateChart(this.selected_metric)
      }
    }
  },
  methods:
  {
    generateGraphPDF() {
      this.showGraphTitle = true
      this.$refs.html2Pdf.generatePdf()

    },
    onProgress(progress) {
      if (progress == 100) {
        this.showGraphTitle = false
      }
    },
    updateDateRange(module_name) {
      let _this = this
      let dates = _this.result_dates_by_module[module_name];

      let today = new Date()
      today.setHours(0, 0, 0, 0)
      
      let startDate = today
      let endDate = today    
      
      if (dates) {
        dates = dates.sort()
        startDate = dates[0]
        endDate = dates[dates.length-1]

        if (endDate === 'undefined' || endDate === 'no_date') { //for undefined dates
          for (let date of dates) {
            if (date === "undefined" || date === 'no_date') {
              
            } else {
              endDate = date
            }
            //alert(date)
          }
        }
      }



      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0);

      let thisMonthStart = new Date()
      thisMonthStart = new Date(thisMonthStart.getFullYear(), thisMonthStart.getMonth(), 1);

      let thisMonthEnd = new Date()
      thisMonthEnd = new Date(thisMonthEnd.getFullYear(), thisMonthEnd.getMonth() + 1, 0);

      _this.monitor_range =
      {
        'Today': [today, today],
        'Yesterday': [yesterday, yesterday],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'All time': [new Date(startDate), new Date(endDate)]
      }

      //alert(_this.monitor_range['All time'])
      _this.drange = _this.monitor_range['All time']

    },
    changeSelectedModule(rname)
    {
      
      if (Object.keys(this.results_by_module[this.selected_module].length > 0)) {
        this.selected_metric = Object.keys(this.results_by_module[this.selected_module])[0]
      } else {
        this.selected_metric = ''
      }
      this.updateDateRange(rname)
    },
    loadResults(selected)
    {
      if (this.isAssessmentPackage) {
        this.loading_metrics = false
        this.loadPackageResults()
      } else {
        this.loading_metrics = true
        this.loadGenericResults(selected)
      }
    },
    loadPackageResults() {

      let _this = this
      let client_uid = localStorage.getItem('client_uid');
      
      let uid = localStorage.getItem('uid');
      this.all_packages = []
      this.all_packages.splice(0);
      this.selected_package = ''
      this.dates_of_completed_packages = {}
      this.selected_package_exercise_address = {}

      let results_address_home = 'Users/' + client_uid + '/Results/assignment_assessment/packages/'
      let results_address_slt = 'Users/' + client_uid + '/Results/clinic_assessment/packages/'

      let results_address_list = [results_address_home, results_address_slt]

      for (let results_address of results_address_list) {

        let do_once = true 
        getData(results_address, function (err, packages) {
    
          if(do_once)
          {
            do_once = false;
            
            packages.forEach(function(package_handle)
            {
              let package_data = package_handle.val()

              let package_uid = package_handle.key
              let package_name = package_data['name']

              if (!_this.all_packages.includes(package_name)) {
                _this.all_packages.push(package_name)
              }

              let isComplete = _this.isPackageCompleted(package_data, package_handle)

              if (isComplete) {
                let completedDate = _this.getSetCompletedDate(package_data, package_handle)

                if (completedDate != null) {


                  if (!_this.dates_of_completed_packages.hasOwnProperty(package_name)) {
                    _this.dates_of_completed_packages[package_name] = []
                  }
                  //console.log(package_name)
                  //console.log(completedDate)

                  //add date
                  let same_date_counter = 1
                  for (let date of _this.dates_of_completed_packages[package_name]) {
                    //console.log(date)                  
                    if (date.split(" : attempt #")[0] === completedDate || date === completedDate) {
                      same_date_counter = same_date_counter + 1
                    }
                  }
                  if (same_date_counter > 1) {
                    completedDate = completedDate + " : attempt #" + same_date_counter
                  }

                  _this.dates_of_completed_packages[package_name].push(completedDate)

                  if (!_this.selected_package_exercise_address.hasOwnProperty(package_name)) {
                    _this.selected_package_exercise_address[package_name] = {}
                  }
                  _this.selected_package_exercise_address[package_name][completedDate] = results_address + package_uid + "/exercises/"
                }
              }
            });
          }
        })
      }
    },
    getSetCompletedDate(package_data, package_handle) {
      let lastDate

      let exercises_date = []
      if (!package_data['exercises']) {
        //error in database no exercises folder
        return null
      }

      for (const [key, module] of Object.entries(package_data['exercises'])) {
        exercises_date.push(key.split(" ")[0])
      }

      exercises_date.sort()
      lastDate = exercises_date[exercises_date.length-1]

      updateData(package_handle.ref, {"completed_date": lastDate});

      return lastDate
    },
    isPackageCompleted(package_data) {

      let isCompleted = false

      let equalLength = false
      if (package_data['all_module_names'] && package_data['completed_module_names']) {
        if (package_data['all_module_names'].length == package_data['completed_module_names'].length) {
          equalLength = true
        }
      }

      if (equalLength) {
        isCompleted = package_data['all_module_names'].every(r=> package_data['completed_module_names'].includes(r))
      }

      return isCompleted
    },
    loadGenericResults(results_type) {
      const _this  = this;

      let client_uid = localStorage.getItem('client_uid');
      let results_address_list = []      
      let results_address = ''

      _this.result_dates_by_module = {}

      if(results_type==='clinic')
      {
        results_address = 'Users/' + client_uid + '/Results/clinic_training/general/'
        results_address_list.push(results_address)
      } else if (results_type === 'assessment')
      {
        results_address = 'Users/' + client_uid + '/Results/clinic_assessment/packages/'
        results_address_list.push(results_address)

        results_address = 'Users/' + client_uid + '/Results/clinic_assessment/general/' 
        results_address_list.push(results_address)

        results_address_list.push(results_address)
        results_address = 'Users/' + client_uid + '/Results/assignment_assessment/general/'
        results_address_list.push(results_address)
        ///interactive_session
        results_address = 'Users/' + client_uid + '/Results/remote_assessment/general/'
        results_address_list.push(results_address)
        /////////
        results_address = 'Users/' + client_uid + '/Results/assignment_assessment/packages/'
        results_address_list.push(results_address)

      } else if (results_type === 'home') {
        results_address = 'Users/' + client_uid + '/Results/training/general/'
        results_address_list.push(results_address)
        results_address = 'Users/' + client_uid + '/Results/assignment_training/general/'
        results_address_list.push(results_address)
      }
      
      this.all_modules = []
      this.all_modules.splice(0);
      this.all_modules = ['All modules']

      this.results_by_module['All modules'] = {}

      for (let res_address of results_address_list) {
        if(res_address.includes('packages'))
        {
          this.processPackageAddress(res_address)
        } else {
          this.processModuleAddress(res_address)
        }
      }
    },
    processPackageAddress(result_address) {
      let _this = this
      let do_once = true;
      getData(result_address, function (err, result) {
        if(do_once)
        {
          do_once = false

          result.forEach(function(packageSnapshot)
          {
            console.debug('package = ' + packageSnapshot.key)

            let exercisesSnapshot = packageSnapshot.child('exercises')
            let address = exercisesSnapshot.ref.toString().substring(exercisesSnapshot.ref.root.toString().length);
            _this.processModuleAddress(address)
          });
        }
      });
    },
    processModuleAddress(result_address) {
      let _this = this

      let do_once = true;
      getData(result_address, function (err, moduleParentLocation) {
        
        if(do_once)
        {
          do_once = false;
          
          moduleParentLocation.forEach(function(modulesBySingleDateTime)
          {
            let date = modulesBySingleDateTime.key
            
            let split_date = date.split(' ')
            
            date = split_date[0] //get only date not time
            
            ////Gets module name and date////////////////////////////////////
            for (const [key, module] of Object.entries(modulesBySingleDateTime.val())) {
              let module_name = key

              if (_this.result_dates_by_module[module_name]) {
                _this.result_dates_by_module[module_name].push(date)
              } else {
                _this.result_dates_by_module[module_name] = [date]
              }

              if (_this.result_dates_by_module['All modules']) {
                _this.result_dates_by_module['All modules'].push(date)
              } else {
                _this.result_dates_by_module['All modules'] = [date]
              }

              if (!_this.all_modules.includes(module_name)) {
                _this.all_modules.push(module_name)
              }

              ////Gets metric name and date////////////////////////////////////
              for (let metric of Object.keys(_this.metrics_map)) {
                let exercise_parent_array = getMetricPaths(modulesBySingleDateTime.child(key), metric, _this)
                
                if (exercise_parent_array) { //something was found

                  if (_this.results_by_module[module_name]) {
                    if (_this.results_by_module[module_name][metric]) {
                    } else {
                      _this.results_by_module[module_name][metric] = {}
                    }
                  } else {
                    _this.results_by_module[module_name] = {}
                    _this.results_by_module[module_name][metric] = {}
                  }
                  
                  if (_this.results_by_module['All modules'][metric]) {
                  } else {
                    _this.results_by_module['All modules'][metric] = {}
                  }


                  if (!_this.results_by_module['All modules'][metric][date]) {
                    _this.results_by_module['All modules'][metric][date] = []
                  }

                  if (!_this.results_by_module[module_name][metric][date]) {
                    _this.results_by_module[module_name][metric][date] = []
                  }

                  for (let results_parent of exercise_parent_array) {
                    _this.results_by_module['All modules'][metric][date].push(results_parent)
                    _this.results_by_module[module_name][metric][date].push(results_parent)
                  }
                }
              }
            }

          });

          if (Object.keys(_this.results_by_module['All modules']).length > 0)
          {
            _this.updateDateRange('All modules')
            _this.loading_metrics = false
          }
          if (_this.selected_module === '') {
            _this.selected_module = 'All modules'
            if (Object.keys(results_by_module[_this.selected_module].length > 0)) {
              _this.selected_metric = Object.keys(_this.results_by_module[selected_module])[0]
            } else {
              _this.selected_metric = ''
            }
          }

          if (Object.keys(_this.results_by_module[_this.selected_module]).length > 0) {
            if (!Object.keys(_this.results_by_module[_this.selected_module]).includes(_this.selected_metric)) {
              if (Object.keys(_this.results_by_module[_this.selected_module].length > 0)) {
                _this.selected_metric = Object.keys(_this.results_by_module[_this.selected_module])[0]
              } else {
                _this.selected_metric = ''
              }
            }
          }

        }
        _this.loading_metrics = false

      })
    },
    processAndCreateChart(metric)
    {
      const _this = this;
      
      let data = _this.results_by_module[_this.selected_module][metric]

      data = _this.removeDataByPeriod(data)

      if (_this.chart) {
        let chartStatus = Chart.getChart(this.chart)
        if (chartStatus) {
          chartStatus.destroy()
        }
      }

      if ( Object.keys(data).length === 0) {
        this.showChart = false
        alert("There are no results in this period")
        return
      }
      this.showChart = true
      
      ////////////////////////////////////////////////////////////////
      let plot_results = agregateForPlot(data, metric)
      ////////////////////////////////////////////////////////////////      

      plot_results.sort(function(a, b) {
        let keyA = new Date(a.x)
        let keyB = new Date(b.x);
        // Compare the 2 dates
        if (keyA <= keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      const xValues = plot_results.map(a => a.x);
      const yValues = plot_results.map(a => a.y);



      let ctx = document.getElementById("chartContainer").getContext("2d");
      
      _this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: xValues,
          datasets: [{
            label: _this.metrics_map[_this.selected_metric],
            backgroundColor:"rgba(0,0,255,1.0)",
            borderColor: "rgba(0,0,255,0.2)",
            borderWidth: "2",
            tension: 0.5,
            data: yValues
          }]
        },
        options: {
          scales: {
            x: {
              ticks: {
                source: 'auto'
              },
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'D'
                },
                tooltipFormat: 'D'
              }
            }
          },
          onClick: (event, elements, chart) => {
            if (elements[0]) {            
              const i = elements[0].index;
              let date = chart.data.labels[i]
              _this.$router.push({ name: 'results_list', query: { start_date: new Date(date).setHours(0, 0, 0, 0), end_date: new Date(date).setHours(23, 59, 59, 59), list_type: _this.activeTabName, requested_module: _this.selected_module } })
            }
          },
        }
      });

    },
    removeDataByPeriod(data)
    {
      const _this = this
  
      let data_cut = {}

      for(let date in data)
      {
        let curr_date = new Date(date)
        let startDate = _this.drange[0]
        let endDate = _this.drange[1]

        if(curr_date >= startDate && curr_date <= endDate)
        {
          data_cut[date] = data[date]
        }
      }
      
      return data_cut
    },
  }
}
</script>
<style scoped>
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.6);

  pointer-events: none !important;
}
</style>
  
  
  
  
  
  