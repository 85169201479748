import {getUserDataDir} from "@/firebase"
import axios from 'axios';
import { getAuthToken } from '@/firebase/index'



export class BV_TTS {

  constructor() { 
    this.voices = null //this will be filled with prefered_voice_promise() - it includes all voices supported by browser
    this.language_voices = null //this will be filled with prefered_voice_promise() - it filters voices by language
    this.prefered_voice = null //this will be filled with prefered_voice_promise() - selected or fallback voice

    this.speech = new SpeechSynthesisUtterance();
    
    this.api_tts = import.meta.env.VITE_FAST_API + '/tts'

    this.is_AI = false

    this.promise_AI_talking = null

    this.is_auto_tts_enabled_promise = this.getAutoTTSSetting() 

    this.ai_audio_object = null

    this.preferred_system_voice_list = {
      "UK": [
        //Mac Chrome
        "Google male",
        "Google female",
        "Martha",
        "Daniel",
 
        //Mac Safari
        //"Daniel",
        "Flo",
        "Grandma",
        "Reed",

        //Windows Chrome
        "Google UK English Male",
        "Google UK English Female",
      ],
      "US": [
        //Mac Chrome
        "Google",
        "Nicky",
        "Reed",
        "Ralph",

        //Mac Safari
        "Samantha",
        "Ralph",
        "Shelley",
        "Fred",

        //Windows Chrome
        "Google US English",
        "Microsoft David",
      ]
    }

    ///check this in prefered_voice
    let _this = this

    this.prefered_voice_promise = new Promise( resolve => {

      if ('speechSynthesis' in window) {
        
        const synth = window.speechSynthesis;
        _this.voices = synth.getVoices()
        
        console.log(_this.voices)
        
        if(_this.voices && _this.voices.length) {
          _this.set_voice().then( (v)=> {
            resolve(v)
          })
        }
        
        synth.onvoiceschanged = () => {
          _this.voices = synth.getVoices()
          if(_this.voices && _this.voices.length) {
            //use prefered voice
            _this.set_voice().then((v) => {  
              resolve(v)
            })
          }
        };
      } else {
        console.error('Speech Synthesis API not supported in this browser.');
      }

    })
    
  }

  async getAutoTTSSetting() {

    return await new Promise(resolve => {
      getUserDataDir('Settings/' , function (err, result) {
        let name = result.child('auto_tts').val()
        resolve(name)
      });
    })
  }

  async getStoredPreferedVoice() 
  {
    return new Promise(resolve => {
      getUserDataDir('Data/' , function (err, result) {
        let name = result.child('prefered_voice').val()
        resolve(name)
      });
    })
  }

  get_available_preferred_system_voices(language) {

    let voices = this.voices.filter(voice => voice.lang.includes(language))
    let filtered_voices = []

    if (language == 'GB') {
      language = 'UK'
    }

    for(let p_voice of this.preferred_system_voice_list[language]) {
      const matchingElement = voices.find(voice => voice.name.toLowerCase().includes(p_voice.toLowerCase()));
      if (matchingElement) { 
        filtered_voices.push(matchingElement)
      }
    }

    return filtered_voices
  }

  async set_voice()
  {
    this.is_AI = false

    console.log("looking for language in localStorage")
    let language = localStorage.getItem('language')
    console.log("total voices length: " + this.voices.length)
    if (language) {
      console.log("found language in localStorage : " + language)
      if (language == 'UK') {
        console.log("set language_voices to en-GB")
        this.language_voices = this.voices.filter(voice => voice.lang.startsWith('en-GB'))
      } else if (language == 'US') {
        this.language_voices = this.voices.filter(voice => voice.lang.startsWith('en-US'))
        console.log("set language_voices to en-US")
      }
    } else {
      console.log("couldn't find language in localStorage, using en-GB and en-US as a pool for voice")
      this.language_voices = this.voices.filter(voice => voice.lang.startsWith('en-GB') || voice.lang.startsWith('en-US'))
    }

    let filtered_voice_names = [];

    console.log("language_voices length: " + this.language_voices.length)
    for(let k = 0; k< this.language_voices.length;k++) {
      filtered_voice_names.push(this.language_voices[k].name);
    }

    this.speech.lang = "en"
    this.speech.volume = 1
    
    this.prefered_voice = await this.getStoredPreferedVoice()
    console.log("database stored prefered voice: " + this.prefered_voice)


    let chosen_voice = '';

    let found_prefered = false
    if (this.prefered_voice) {

      if (this.prefered_voice === 'BV AI') {
        found_prefered = true
        chosen_voice = this.prefered_voice

        this.is_AI = true
        return
      } else if (filtered_voice_names.includes(this.prefered_voice)) {
        found_prefered = true
        chosen_voice = this.prefered_voice
      }
    }
    
    if (!found_prefered) {
      //didn't find the prefered voice in voices. Use one of fall back voices if they exist in voices.
      console.log("didn't find the prefered voice in language_voices (language specific). Use one of fall back voices if they exist in voices.")
    
      for(let voice of this.preferred_system_voice_list[language]) {
        const matchingElement = filtered_voice_names.find(name => name.toLowerCase().includes(voice.toLowerCase()));
        if (matchingElement) { 
          chosen_voice = voice
          break
        }
      }

    }
    ///////////////////////////////////
    
    if(chosen_voice != '') { 
      //set the selected voice to speech object.
      console.log("use chosen voice: " + chosen_voice)
      for(let i = 0; i < this.language_voices.length; i++) {
        if(this.language_voices[i].name == chosen_voice) {
          this.speech.voice = this.language_voices[i] //get voice object
          this.prefered_voice = chosen_voice
          break
        }
      }
    } else {
      //still not found use one of the system voices (e.g. firefox linux)
      console.log("no fallback voice found in language_voices, using first voice available in this.voices - ignore language filter")
      if(this.voices != undefined && this.voices.length>0) {

        this.speech.voice = this.voices[0];
        this.prefered_voice = this.voices[0].name
      } else {
        console.log("No voice is available.")
        return null
      }
    }

    console.log('selected voice = ' + this.prefered_voice)
    
    return this.prefered_voice
  }

  cancel() {
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel()
    }
    
    if (this.ai_audio_object) {
      this.ai_audio_object.pause()
      this.ai_audio_object.currentTime = 0;
    }

  }

  ////////////////////////////////////////////////////////////
  //this call tts say() automatically - not user invoked

  async system_say(text, callbackv=()=>{}) {
    this.is_auto_tts_enabled_promise.then( (auto_tts_setting) => {
      if (auto_tts_setting == true|| auto_tts_setting == null) {
        this.say(text, callbackv)
      } else {
        callbackv()
      }
    })
  }

  async system_say_sentences(sentences, time_gap=75, callbackv=()=>{}) {
    this.is_auto_tts_enabled_promise.then( (auto_tts_setting) => {
      if (auto_tts_setting == true || auto_tts_setting == null) {
        this.say_sentences(sentences, time_gap, callbackv)
      }
    })
  }

  removeUnreadableCharacters(text) {
    let unreadable_characters = ['*','/']
    text = String(text)

    for(let i in unreadable_characters) {
      text = text.replaceAll(unreadable_characters[i],'')
    }
    return text
  }

  async say(text, callbackv=()=>{}) { 
    const _this = this
      
    this.prefered_voice_promise.then(() => {

      if (_this.is_AI) {
        _this.ai_say(text, callbackv)
      } else {
        text = _this.removeUnreadableCharacters(text)
        if(text.length > 200) {
          let array_text = text.split('.')
          _this.say_sentences(array_text, 75)
        } else {
          window.speechSynthesis.cancel();
          _this.speech.text = text;
          window.speechSynthesis.speak(_this.speech);

          _this.speech.onstart = function(event) {
          
          };
    
          _this.speech.onend = () => {
            callbackv()
          }
        }
      }

    })
  }

  async ai_say(text, callbackv=()=>{}) {

    let _this = this
    
    if (text) {

      getAuthToken().then((idToken) => {

        axios.get(this.api_tts + "?region=" + localStorage.getItem('region') + '&'+ "text=" + text, {
            headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob'
        }).then(async response => {

          const audioUrl = URL.createObjectURL(response.data);

          if (this.promise_AI_talking instanceof Promise && !this.ai_audio_object.paused) {    
            //alert("is waiting for promise")        
            await this.promise_AI_talking
          }

          this.promise_AI_talking = new Promise ((approve, reject) => {

            _this.ai_audio_object = new Audio(audioUrl);

            _this.ai_audio_object.play().then(() => {
              console.debug("bv_ai_tts started playing");
            })

            _this.ai_audio_object.addEventListener('ended', () => {
              if (_this.promise_AI_talking) {
                approve()
                console.debug("bv_ai_tts ended");
                callbackv()
              } 
            });

          })
        })
      })
    }

  }

  async say_sentences(sentences, time_gap=75, callbackv=()=>{}) {

    this.prefered_voice_promise.then(() => {

      if(!Array.isArray(sentences)) {
        this.say(sentences)
        return
      }

      for(let k in sentences) {
        sentences[k] = this.removeUnreadableCharacters(sentences[k])
      }
      
      this.say_sentences_list(sentences, 0, time_gap, callbackv)

    })
  }

  say_sentences_list(sentences_list, index, time_gap=75, callbackv=()=>{}) {

    let _this = this
    
    if (index < sentences_list.length) {

      let text = sentences_list[index].trim()

      if (text) {
        _this.say(text,
          () => {
            setTimeout( () => {
              _this.say_sentences_list(sentences_list, index+1, time_gap, callbackv)
            }, time_gap);
          }
        )
      } 
    } else {
      //last sentence finished so end of sentences tts reached
      callbackv()
    }
    
  }
}