
export default 
{
    phoneme_2_word:{
        "/əbændən/": "abandon",
        "/əbɪləti/": "ability",
        "/eɪbl/": "able",
        "/əbɔ:ʃn/": "abortion",
        "/əbaʊt/": "about",
        "/əbʌv/": "above",
        "/əbrɔ:d/": "abroad",
        "/æbsəns/": "absence",
        "/æbsəlu:t/": "absolute",
        "/æbsəlu:tli/": "absolutely",
        "/əbzɔ:b/": "absorb",
        "/əbju:s/": "abuse",
        "/ækədemɪk/": "academic",
        "/əksept/": "accept",
        "/ækses/": "access",
        "/æksɪdənt/": "accident",
        "/əkʌmpəni/": "accompany",
        "/əkʌmplɪʃ/": "accomplish",
        "/əkaʊnt/": "account",
        "/ækjərət/": "accurate",
        "/əkju:z/": "accuse",
        "/ətʃi:v/": "achieve",
        "/ətʃi:vmənt/": "achievement",
        "/æsɪd/": "acid",
        "/əknɒlɪdʒ/": "acknowledge",
        "/əkwaɪə(r)/": "acquire",
        "/əkrɒs/": "across",
        "/ækt/": "act",
        "/ækʃn/": "action",
        "/æktɪv/": "active",
        "/æktɪvɪst/": "activist",
        "/æktɪvəti/": "activity",
        "/æktə(r)/": "actor",
        "/æktrəs/": "actress",
        "/æktʃuəl/": "actual",
        "/æktʃuəli/": "actually",
        "/æd/": "add",
        "/ədæpt/": "adapt",
        "/ədɪʃn/": "addition",
        "/ədɪʃənl/": "additional",
        "/ədres/": "address",
        "/ædɪkwət/": "adequate",
        "/ədʒʌst/": "adjust",
        "/ədʒʌstmənt/": "adjustment",
        "/ədmɪnɪstreɪʃn/": "administration",
        "/ədmɪnɪstreɪtə(r)/": "administrator",
        "/ədmaɪə(r)/": "admire",
        "/ədmɪʃn/": "admission",
        "/ədmɪt/": "admit",
        "/ædəlesnt/": "adolescent",
        "/ədɒpt/": "adopt",
        "/ædʌlt/": "adult",
        "/ədvɑ:ns/": "advance",
        "/ədvɑ:nst/": "advanced",
        "/ədvɑ:ntɪdʒ/": "advantage",
        "/ədventʃə(r)/": "adventure",
        "/ædvətaɪzɪŋ/": "advertising",
        "/ədvaɪs/": "advice",
        "/ədvaɪz/": "advise",
        "/ədvaɪzə(r)/": "adviser",
        "/ædvəkət/": "advocate",
        "/əfeə(r)/": "affair",
        "/əfekt/": "affect",
        "/əfɔ:d/": "afford",
        "/əfreɪd/": "afraid",
        "/ɑ:ftə(r)/": "after",
        "/ɑ:ftənu:n/": "afternoon",
        "/əgen/": "again",
        "/əgenst/": "against",
        "/eɪdʒ/": "age",
        "/eɪdʒənsi/": "agency",
        "/ədʒendə/": "agenda",
        "/eɪdʒənt/": "agent",
        "/əgresɪv/": "aggressive",
        "/əgəʊ/": "ago",
        "/əgri:/": "agree",
        "/əgri:mənt/": "agreement",
        "/ægrɪkʌltʃərəl/": "agricultural",
        "/ɑ:/": "ah",
        "/əhed/": "ahead",
        "/eɪd/": "aide",
        "/eɪm/": "aim",
        "/eə(r)/": "air",
        "/eəkrɑ:ft/": "aircraft",
        "/eəlaɪn/": "airline",
        "/eəpɔ:t/": "airport",
        "/ælbəm/": "album",
        "/ælkəhɒl/": "alcohol",
        "/əlaɪv/": "alive",
        "/ɔ:l/": "all",
        "/əlaɪəns/": "alliance",
        "/əlaʊ/": "allow",
        "/ælaɪ/": "ally",
        "/ɔ:lməʊst/": "almost",
        "/ələʊn/": "alone",
        "/əlɒŋ/": "along",
        "/ɔ:lredi/": "already",
        "/ɔ:lsəʊ/": "also",
        "/ɔ:ltə(r)/": "alter",
        "/ɔ:ltɜ:nətɪv/": "alternative",
        "/ɔ:lðəʊ/": "although",
        "/ɔ:lweɪz/": "always",
        "/əmeɪzɪŋ/": "amazing",
        "/əmʌŋ/": "among",
        "/əmaʊnt/": "amount",
        "/ənæləsɪs/": "analysis",
        "/ænəlɪst/": "analyst",
        "/eɪnʃənt/": "ancient",
        "/ənd/": "and",
        "/æŋgə(r)/": "anger",
        "/æŋgl/": "angle",
        "/æŋgri/": "angry",
        "/ænɪml/": "animal",
        "/ænɪvɜ:səri/": "anniversary",
        "/ənaʊns/": "announce",
        "/ænjuəl/": "annual",
        "/ənʌðə(r)/": "another",
        "/ɑ:nsə(r)/": "answer",
        "/æntɪsɪpeɪt/": "anticipate",
        "/æŋzaɪəti/": "anxiety",
        "/eni/": "any",
        "/enibɒdi/": "anybody",
        "/eniwʌn/": "anyone",
        "/eniθɪŋ/": "anything",
        "/eniweɪ/": "anyway",
        "/eniweə(r)/": "anywhere",
        "/əpɑ:t/": "apart",
        "/əpɑ:tmənt/": "apartment",
        "/əpærənt/": "apparent",
        "/əpærəntli/": "apparently",
        "/əpi:l/": "appeal",
        "/əpɪə(r)/": "appear",
        "/əpɪərəns/": "appearance",
        "/æpl/": "apple",
        "/æplɪkeɪʃn/": "application",
        "/əplaɪ/": "apply",
        "/əpɔɪnt/": "appoint",
        "/əpɔɪntmənt/": "appointment",
        "/əpri:ʃieɪt/": "appreciate",
        "/əprəʊtʃ/": "approach",
        "/əprəʊpriət/": "appropriate",
        "/əpru:vl/": "approval",
        "/əpru:v/": "approve",
        "/əprɒksɪmətli/": "approximately",
        "/ɑ:kɪtekt/": "architect",
        "/eəriə/": "area",
        "/ɑ:gju:/": "argue",
        "/ɑ:gjumənt/": "argument",
        "/əraɪz/": "arise",
        "/ɑ:m/": "arm",
        "/ɑ:md/": "armed",
        "/ɑ:mi/": "army",
        "/əraʊnd/": "around",
        "/əreɪndʒ/": "arrange",
        "/əreɪndʒmənt/": "arrangement",
        "/ərest/": "arrest",
        "/əraɪvl/": "arrival",
        "/əraɪv/": "arrive",
        "/ɑ:t/": "art",
        "/ɑ:tɪkl/": "article",
        "/ɑ:tɪst/": "artist",
        "/ɑ:tɪstɪk/": "artistic",
        "/əz/": "as",
        "/əsaɪd/": "aside",
        "/ɑ:sk/": "ask",
        "/əsli:p/": "asleep",
        "/æspekt/": "aspect",
        "/əsɔ:lt/": "assault",
        "/əsɜ:t/": "assert",
        "/əses/": "assess",
        "/əsesmənt/": "assessment",
        "/æset/": "asset",
        "/əsaɪn/": "assign",
        "/əsaɪnmənt/": "assignment",
        "/əsɪst/": "assist",
        "/əsɪstəns/": "assistance",
        "/əsɪstənt/": "assistant",
        "/əsəʊsieɪt/": "associate",
        "/əsəʊsieɪʃn/": "association",
        "/əsju:m/": "assume",
        "/əsʌmpʃn/": "assumption",
        "/əʃʊə(r)/": "assure",
        "/ət/": "at",
        "/æθli:t/": "athlete",
        "/æθletɪk/": "athletic",
        "/ætməsfɪə(r)/": "atmosphere",
        "/ətætʃ/": "attach",
        "/ətæk/": "attack",
        "/ətempt/": "attempt",
        "/ətend/": "attend",
        "/ətenʃn/": "attention",
        "/ætɪtju:d/": "attitude",
        "/ətɜ:ni/": "attorney",
        "/ətrækt/": "attract",
        "/ətræktɪv/": "attractive",
        "/ətrɪbju:t/": "attribute",
        "/ɔ:diəns/": "audience",
        "/ɔ:θə(r)/": "author",
        "/ɔ:θɒrəti/": "authority",
        "/ɔ:təʊ/": "auto",
        "/əveɪləbl/": "available",
        "/ævərɪdʒ/": "average",
        "/əvɔɪd/": "avoid",
        "/əwɔ:d/": "award",
        "/əweə(r)/": "aware",
        "/əweənəs/": "awareness",
        "/əweɪ/": "away",
        "/ɔ:fl/": "awful",
        "/beɪbi/": "baby",
        "/bæk/": "back",
        "/bækgraʊnd/": "background",
        "/bæd/": "bad",
        "/bædli/": "badly",
        "/bæg/": "bag",
        "/beɪk/": "bake",
        "/bæləns/": "balance",
        "/bɔ:l/": "ball",
        "/bæn/": "ban",
        "/bænd/": "band",
        "/bæŋk/": "bank",
        "/bɑ:(r)/": "bar",
        "/beəli/": "barely",
        "/bærəl/": "barrel",
        "/bæriə(r)/": "barrier",
        "/beɪs/": "base",
        "/beɪsbɔ:l/": "baseball",
        "/beɪsɪk/": "basic",
        "/beɪsɪkli/": "basically",
        "/beɪsɪs/": "basis",
        "/bɑ:skɪt/": "basket",
        "/bɑ:skɪtbɔ:l/": "basketball",
        "/bɑ:θru:m/": "bathroom",
        "/bætri/": "battery",
        "/bætl/": "battle",
        "/bi/": "be",
        "/bi:tʃ/": "beach",
        "/bi:n/": "bean",
        "/beə(r)/": "bear",
        "/bi:t/": "beat",
        "/bju:tɪfl/": "beautiful",
        "/bju:ti/": "beauty",
        "/bɪkəz/": "because",
        "/bɪkʌm/": "become",
        "/bed/": "bed",
        "/bedru:m/": "bedroom",
        "/bɪə(r)/": "beer",
        "/bɪfɔ:(r)/": "before",
        "/bɪgɪn/": "begin",
        "/bɪgɪnɪŋ/": "beginning",
        "/bɪhaɪnd/": "behind",
        "/bi:ɪŋ/": "being",
        "/bɪli:f/": "belief",
        "/bɪli:v/": "believe",
        "/bel/": "bell",
        "/bɪlɒŋ/": "belong",
        "/bɪləʊ/": "below",
        "/belt/": "belt",
        "/bentʃ/": "bench",
        "/bend/": "bend",
        "/bɪni:θ/": "beneath",
        "/benɪfɪt/": "benefit",
        "/bɪsaɪd/": "beside",
        "/bɪsaɪdz/": "besides",
        "/best/": "best",
        "/bet/": "bet",
        "/betə(r)/": "better",
        "/bɪtwi:n/": "between",
        "/bɪjɒnd/": "beyond",
        "/bɪg/": "big",
        "/baɪk/": "bike",
        "/bɪl/": "bill",
        "/bɪljən/": "billion",
        "/baɪnd/": "bind",
        "/baɪəlɒdʒɪkl/": "biological",
        "/bɜ:d/": "bird",
        "/bɜ:θ/": "birth",
        "/bɜ:θdeɪ/": "birthday",
        "/bɪt/": "bit",
        "/baɪt/": "bite",
        "/blæk/": "black",
        "/bleɪd/": "blade",
        "/bleɪm/": "blame",
        "/blæŋkɪt/": "blanket",
        "/blaɪnd/": "blind",
        "/blɒk/": "block",
        "/blʌd/": "blood",
        "/bləʊ/": "blow",
        "/blu:/": "blue",
        "/bɔ:d/": "board",
        "/bəʊt/": "boat",
        "/bɒdi/": "body",
        "/bɒm/": "bomb",
        "/bɒmɪŋ/": "bombing",
        "/bɒnd/": "bond",
        "/bəʊn/": "bone",
        "/bʊk/": "book",
        "/bu:m/": "boom",
        "/bu:t/": "boot",
        "/bɔ:də(r)/": "border",
        "/bɔ:n/": "born",
        "/bɒrəʊ/": "borrow",
        "/bɒs/": "boss",
        "/bəʊθ/": "both",
        "/bɒðə(r)/": "bother",
        "/bɒtl/": "bottle",
        "/bɒtəm/": "bottom",
        "/baʊndri/": "boundary",
        "/bəʊl/": "bowl",
        "/bɒks/": "box",
        "/bɔɪ/": "boy",
        "/bɔɪfrend/": "boyfriend",
        "/breɪn/": "brain",
        "/brɑ:ntʃ/": "branch",
        "/brænd/": "brand",
        "/bred/": "bread",
        "/breɪk/": "break",
        "/brekfəst/": "breakfast",
        "/brest/": "breast",
        "/breθ/": "breath",
        "/bri:ð/": "breathe",
        "/brɪk/": "brick",
        "/brɪdʒ/": "bridge",
        "/bri:f/": "brief",
        "/bri:fli/": "briefly",
        "/braɪt/": "bright",
        "/brɪliənt/": "brilliant",
        "/brɪŋ/": "bring",
        "/brɔ:d/": "broad",
        "/brəʊkən/": "broken",
        "/brʌðə(r)/": "brother",
        "/braʊn/": "brown",
        "/brʌʃ/": "brush",
        "/bʌk/": "buck",
        "/bʌdʒɪt/": "budget",
        "/bɪld/": "build",
        "/bɪldɪŋ/": "building",
        "/bʊlɪt/": "bullet",
        "/bʌntʃ/": "bunch",
        "/bɜ:dn/": "burden",
        "/bɜ:n/": "burn",
        "/beri/": "bury",
        "/bʌs/": "bus",
        "/bɪznəs/": "business",
        "/bɪzi/": "busy",
        "/bət/": "but",
        "/bʌtə(r)/": "butter",
        "/bʌtn/": "button",
        "/baɪ/": "by",
        "/baɪə(r)/": "buyer",
        "/kæbɪn/": "cabin",
        "/kæbɪnət/": "cabinet",
        "/keɪbl/": "cable",
        "/keɪk/": "cake",
        "/kælkjuleɪt/": "calculate",
        "/kɔ:l/": "call",
        "/kæmrə/": "camera",
        "/kæmp/": "camp",
        "/kæmpeɪn/": "campaign",
        "/kæmpəs/": "campus",
        "/kænsə(r)/": "cancer",
        "/kændɪdət/": "candidate",
        "/kæp/": "cap",
        "/keɪpəbɪləti/": "capability",
        "/keɪpəbl/": "capable",
        "/kəpæsəti/": "capacity",
        "/kæpɪtl/": "capital",
        "/kæptɪn/": "captain",
        "/kæptʃə(r)/": "capture",
        "/kɑ:(r)/": "car",
        "/kɑ:bən/": "carbon",
        "/kɑ:d/": "card",
        "/keə(r)/": "care",
        "/kərɪə(r)/": "career",
        "/keəfl/": "careful",
        "/keəfəli/": "carefully",
        "/kæriə(r)/": "carrier",
        "/kæri/": "carry",
        "/keɪs/": "case",
        "/kæʃ/": "cash",
        "/kɑ:st/": "cast",
        "/kæt/": "cat",
        "/kætʃ/": "catch",
        "/kætəgəri/": "category",
        "/kɔ:z/": "cause",
        "/si:lɪŋ/": "ceiling",
        "/selɪbreɪt/": "celebrate",
        "/selɪbreɪʃn/": "celebration",
        "/səlebrəti/": "celebrity",
        "/sel/": "sell",
        "/sentə(r)/": "center",
        "/sentrəl/": "central",
        "/sentʃəri/": "century",
        "/serəməni/": "ceremony",
        "/sɜ:tn/": "certain",
        "/sɜ:tnli/": "certainly",
        "/tʃeɪn/": "chain",
        "/tʃeə(r)/": "chair",
        "/tʃeəmən/": "chairman",
        "/tʃælɪndʒ/": "challenge",
        "/tʃeɪmbə(r)/": "chamber",
        "/tʃæmpiən/": "champion",
        "/tʃæmpiənʃɪp/": "championship",
        "/tʃɑ:ns/": "chance",
        "/tʃeɪndʒ/": "change",
        "/tʃænl/": "channel",
        "/tʃæptə(r)/": "chapter",
        "/kærəktə(r)/": "character",
        "/kærəktərɪstɪk/": "characteristic",
        "/kærəktəraɪz/": "characterize",
        "/tʃɑ:dʒ/": "charge",
        "/tʃærəti/": "charity",
        "/tʃɑ:t/": "chart",
        "/tʃeɪs/": "chase",
        "/tʃi:p/": "cheap",
        "/tʃek/": "check",
        "/tʃi:k/": "cheek",
        "/tʃi:z/": "cheese",
        "/ʃef/": "chef",
        "/kemɪkl/": "chemical",
        "/tʃest/": "chest",
        "/tʃɪkɪn/": "chicken",
        "/tʃi:f/": "chief",
        "/tʃaɪld/": "child",
        "/tʃaɪldhʊd/": "childhood",
        "/tʃɪp/": "chip",
        "/tʃɒklət/": "chocolate",
        "/tʃɔɪs/": "choice",
        "/kəlestərɒl/": "cholesterol",
        "/tʃu:z/": "choose",
        "/tʃɜ:tʃ/": "church",
        "/sɪgəret/": "cigarette",
        "/sɜ:kl/": "circle",
        "/sɜ:kəmstəns/": "circumstance",
        "/saɪt/": "site",
        "/sɪtɪzn/": "citizen",
        "/sɪti/": "city",
        "/sɪvl/": "civil",
        "/səvɪliən/": "civilian",
        "/kleɪm/": "claim",
        "/klɑ:s/": "class",
        "/klæsɪk/": "classic",
        "/klɑ:sru:m/": "classroom",
        "/kli:n/": "clean",
        "/klɪə(r)/": "clear",
        "/klɪəli/": "clearly",
        "/klaɪənt/": "client",
        "/klaɪmət/": "climate",
        "/klaɪm/": "climb",
        "/klɪnɪk/": "clinic",
        "/klɪnɪkl/": "clinical",
        "/klɒk/": "clock",
        "/kləʊsli/": "closely",
        "/kləʊðz/": "clothes",
        "/kləʊðɪŋ/": "clothing",
        "/klaʊd/": "cloud",
        "/klʌb/": "club",
        "/klu:/": "clue",
        "/klʌstə(r)/": "cluster",
        "/kəʊtʃ/": "coach",
        "/kəʊl/": "coal",
        "/kəʊəlɪʃn/": "coalition",
        "/kəʊst/": "coast",
        "/kəʊt/": "coat",
        "/kəʊd/": "code",
        "/kɒfi/": "coffee",
        "/kɒgnətɪv/": "cognitive",
        "/kəʊld/": "cold",
        "/kəlæps/": "collapse",
        "/kɒli:g/": "colleague",
        "/kəlekt/": "collect",
        "/kəlekʃn/": "collection",
        "/kəlektɪv/": "collective",
        "/kɒlɪdʒ/": "college",
        "/kələʊniəl/": "colonial",
        "/kʌlə(r)/": "color",
        "/kɒləm/": "column",
        "/kɒmbɪneɪʃn/": "combination",
        "/kəmbaɪn/": "combine",
        "/kʌm/": "come",
        "/kɒmədi/": "comedy",
        "/kʌmfət/": "comfort",
        "/kʌmftəbl/": "comfortable",
        "/kəmɑ:nd/": "command",
        "/kəmɑ:ndə(r)/": "commander",
        "/kɒment/": "comment",
        "/kəmɜ:ʃl/": "commercial",
        "/kəmɪʃn/": "commission",
        "/kəmɪt/": "commit",
        "/kəmɪtmənt/": "commitment",
        "/kəmɪti/": "committee",
        "/kɒmən/": "common",
        "/kəmju:nɪkeɪt/": "communicate",
        "/kəmju:nɪkeɪʃn/": "communication",
        "/kəmju:nəti/": "community",
        "/kʌmpəni/": "company",
        "/kəmpeə(r)/": "compare",
        "/kəmpærɪsn/": "comparison",
        "/kəmpi:t/": "compete",
        "/kɒmpətɪʃn/": "competition",
        "/kəmpetətɪv/": "competitive",
        "/kəmpetɪtə(r)/": "competitor",
        "/kəmpleɪn/": "complain",
        "/kəmpleɪnt/": "complaint",
        "/kəmpli:t/": "complete",
        "/kəmpli:tli/": "completely",
        "/kɒmpleks/": "complex",
        "/kɒmplɪkeɪtɪd/": "complicated",
        "/kəmpəʊnənt/": "component",
        "/kəmpəʊz/": "compose",
        "/kɒmpəzɪʃn/": "composition",
        "/kɒmprɪhensɪv/": "comprehensive",
        "/kəmpju:tə(r)/": "computer",
        "/kɒnsntreɪt/": "concentrate",
        "/kɒnsntreɪʃn/": "concentration",
        "/kɒnsept/": "concept",
        "/kənsɜ:n/": "concern",
        "/kənsɜ:nd/": "concerned",
        "/kɒnsət/": "concert",
        "/kənklu:d/": "conclude",
        "/kənklu:ʒn/": "conclusion",
        "/kɒŋkri:t/": "concrete",
        "/kəndɪʃn/": "condition",
        "/kəndʌkt/": "conduct",
        "/kɒnfərəns/": "conference",
        "/kɒnfɪdəns/": "confidence",
        "/kɒnfɪdənt/": "confident",
        "/kənfɜ:m/": "confirm",
        "/kɒnflɪkt/": "conflict",
        "/kənfrʌnt/": "confront",
        "/kənfju:ʒn/": "confusion",
        "/kəngreʃənl/": "congressional",
        "/kənekt/": "connect",
        "/kənekʃn/": "connection",
        "/kɒnʃəsnəs/": "consciousness",
        "/kənsensəs/": "consensus",
        "/kɒnsɪkwəns/": "consequence",
        "/kənsɜ:vətɪv/": "conservative",
        "/kənsɪdə(r)/": "consider",
        "/kənsɪdərəbl/": "considerable",
        "/kənsɪdəreɪʃn/": "consideration",
        "/kənsɪst/": "consist",
        "/kənsɪstənt/": "consistent",
        "/kɒnstənt/": "constant",
        "/kɒnstəntli/": "constantly",
        "/kɒnstɪtju:t/": "constitute",
        "/kɒnstɪtju:ʃənl/": "constitutional",
        "/kənstrʌkt/": "construct",
        "/kənstrʌkʃn/": "construction",
        "/kənsʌltənt/": "consultant",
        "/kənsju:m/": "consume",
        "/kənsju:mə(r)/": "consumer",
        "/kənsʌmpʃn/": "consumption",
        "/kɒntækt/": "contact",
        "/kənteɪn/": "contain",
        "/kənteɪnə(r)/": "container",
        "/kəntemprəri/": "contemporary",
        "/kɒntest/": "contest",
        "/kɒntekst/": "context",
        "/kəntɪnju:/": "continue",
        "/kəntɪnju:d/": "continued",
        "/kɒntrækt/": "contract",
        "/kɒntrɑ:st/": "contrast",
        "/kəntrɪbju:t/": "contribute",
        "/kɒntrɪbju:ʃn/": "contribution",
        "/kəntrəʊl/": "control",
        "/kɒntrəvɜ:ʃl/": "controversial",
        "/kɒntrəvɜ:si/": "controversy",
        "/kənvenʃn/": "convention",
        "/kənvenʃənl/": "conventional",
        "/kɒnvəseɪʃn/": "conversation",
        "/kənvɜ:t/": "convert",
        "/kənvɪkʃn/": "conviction",
        "/kənvɪns/": "convince",
        "/kʊk/": "cook",
        "/kʊki/": "cookie",
        "/kʊkɪŋ/": "cooking",
        "/ku:l/": "cool",
        "/kəʊɒpəreɪʃn/": "cooperation",
        "/kɒp/": "cop",
        "/kəʊp/": "cope",
        "/kɒpi/": "copy",
        "/kɔ:(r)/": "core",
        "/kɔ:n/": "corn",
        "/kɔ:nə(r)/": "corner",
        "/kɔ:pərət/": "corporate",
        "/kɔ:pəreɪʃn/": "corporation",
        "/kərekt/": "correct",
        "/kɒrəspɒndənt/": "correspondent",
        "/kɒst/": "cost",
        "/kɒtn/": "cotton",
        "/kaʊtʃ/": "couch",
        "/kəd/": "could",
        "/kaʊnsl/": "council",
        "/kaʊnt/": "count",
        "/kaʊntə(r)/": "counter",
        "/kʌntri/": "country",
        "/kaʊnti/": "county",
        "/kʌpl/": "couple",
        "/kʌrɪdʒ/": "courage",
        "/kɔ:s/": "course",
        "/kɔ:t/": "court",
        "/kʌzn/": "cousin",
        "/kʌvə(r)/": "cover",
        "/kʌvərɪdʒ/": "coverage",
        "/kaʊ/": "cow",
        "/kræk/": "crack",
        "/krɑ:ft/": "craft",
        "/kræʃ/": "crash",
        "/kreɪzi/": "crazy",
        "/kri:m/": "cream",
        "/krieɪt/": "create",
        "/krieɪʃn/": "creation",
        "/krieɪtɪv/": "creative",
        "/kri:tʃə(r)/": "creature",
        "/kredɪt/": "credit",
        "/kru:/": "crew",
        "/kraɪm/": "crime",
        "/krɪmɪnl/": "criminal",
        "/kraɪsɪs/": "crisis",
        "/krɪtɪk/": "critic",
        "/krɪtɪkl/": "critical",
        "/krɪtɪsɪzəm/": "criticism",
        "/krɪtɪsaɪz/": "criticize",
        "/krɒp/": "crop",
        "/krɒs/": "cross",
        "/kraʊd/": "crowd",
        "/kru:ʃl/": "crucial",
        "/kraɪ/": "cry",
        "/kʌltʃərəl/": "cultural",
        "/kʌltʃə(r)/": "culture",
        "/kʌp/": "cup",
        "/kjʊəriəs/": "curious",
        "/kʌrənt/": "current",
        "/kʌrəntli/": "currently",
        "/kərɪkjələm/": "curriculum",
        "/kʌstəm/": "custom",
        "/kʌstəmə(r)/": "customer",
        "/kʌt/": "cut",
        "/saɪkl/": "cycle",
        "/dæd/": "dad",
        "/deɪli/": "daily",
        "/dæmɪdʒ/": "damage",
        "/dɑ:ns/": "dance",
        "/deɪndʒə(r)/": "danger",
        "/deɪndʒərəs/": "dangerous",
        "/deə(r)/": "dare",
        "/dɑ:k/": "dark",
        "/dɑ:knəs/": "darkness",
        "/deɪtə/": "data",
        "/deɪt/": "date",
        "/dɔ:tə(r)/": "daughter",
        "/deɪ/": "day",
        "/ded/": "dead",
        "/di:l/": "deal",
        "/di:lə(r)/": "dealer",
        "/dɪə(r)/": "deer",
        "/deθ/": "death",
        "/dɪbeɪt/": "debate",
        "/det/": "debt",
        "/dekeɪd/": "decade",
        "/dɪsaɪd/": "decide",
        "/dɪsɪʒn/": "decision",
        "/dek/": "deck",
        "/dɪkleə(r)/": "declare",
        "/dɪklaɪn/": "decline",
        "/dɪkri:s/": "decrease",
        "/di:p/": "deep",
        "/di:pli/": "deeply",
        "/dɪfi:t/": "defeat",
        "/dɪfend/": "defend",
        "/dɪfendənt/": "defendant",
        "/dɪfens/": "defense",
        "/dɪfensɪv/": "defensive",
        "/defɪsɪt/": "deficit",
        "/dɪfaɪn/": "define",
        "/defɪnətli/": "definitely",
        "/defɪnɪʃn/": "definition",
        "/dɪgri:/": "degree",
        "/dɪleɪ/": "delay",
        "/dɪlɪvə(r)/": "deliver",
        "/dɪlɪvəri/": "delivery",
        "/dɪmɑ:nd/": "demand",
        "/dɪmɒkrəsi/": "democracy",
        "/deməkrætɪk/": "democratic",
        "/demənstreɪt/": "demonstrate",
        "/demənstreɪʃn/": "demonstration",
        "/dɪnaɪ/": "deny",
        "/dɪpɑ:tmənt/": "department",
        "/dɪpend/": "depend",
        "/dɪpendənt/": "dependent",
        "/dɪpɪkt/": "depict",
        "/dɪpreʃn/": "depression",
        "/depθ/": "depth",
        "/depjuti/": "deputy",
        "/dɪraɪv/": "derive",
        "/dɪskraɪb/": "describe",
        "/dɪskrɪpʃn/": "description",
        "/dezət/": "desert",
        "/dɪzɜ:v/": "deserve",
        "/dɪzaɪn/": "design",
        "/dɪzaɪnə(r)/": "designer",
        "/dɪzaɪə(r)/": "desire",
        "/desk/": "desk",
        "/despərət/": "desperate",
        "/dɪspaɪt/": "despite",
        "/dɪstrɔɪ/": "destroy",
        "/dɪstrʌkʃn/": "destruction",
        "/di:teɪl/": "detail",
        "/di:teɪld/": "detailed",
        "/dɪtekt/": "detect",
        "/dɪtɜ:mɪn/": "determine",
        "/dɪveləp/": "develop",
        "/dɪveləpɪŋ/": "developing",
        "/dɪveləpmənt/": "development",
        "/dɪvaɪs/": "device",
        "/dɪvəʊt/": "devote",
        "/daɪəlɒg/": "dialogue",
        "/daɪ/": "die",
        "/daɪət/": "diet",
        "/dɪfə(r)/": "differ",
        "/dɪfrəns/": "difference",
        "/dɪfrənt/": "different",
        "/dɪfrəntli/": "differently",
        "/dɪfɪkəlt/": "difficult",
        "/dɪfɪkəlti/": "difficulty",
        "/dɪg/": "dig",
        "/dɪdʒɪtl/": "digital",
        "/daɪmenʃn/": "dimension",
        "/daɪnɪŋ/": "dining",
        "/dɪnə(r)/": "dinner",
        "/dərekt/": "direct",
        "/dərekʃn/": "direction",
        "/dərektli/": "directly",
        "/dərektə(r)/": "director",
        "/dɜ:t/": "dirt",
        "/dɜ:ti/": "dirty",
        "/dɪsəbɪləti/": "disability",
        "/dɪsəgri:/": "disagree",
        "/dɪsəpɪə(r)/": "disappear",
        "/dɪzɑ:stə(r)/": "disaster",
        "/dɪsəplɪn/": "discipline",
        "/dɪskɔ:s/": "discourse",
        "/dɪskʌvə(r)/": "discover",
        "/dɪskʌvəri/": "discovery",
        "/dɪskrɪmɪneɪʃn/": "discrimination",
        "/dɪskʌs/": "discuss",
        "/dɪskʌʃn/": "discussion",
        "/dɪzi:z/": "disease",
        "/dɪʃ/": "dish",
        "/dɪsmɪs/": "dismiss",
        "/dɪsɔ:də(r)/": "disorder",
        "/dɪspleɪ/": "display",
        "/dɪspju:t/": "dispute",
        "/dɪstəns/": "distance",
        "/dɪstənt/": "distant",
        "/dɪstɪŋkt/": "distinct",
        "/dɪstɪŋkʃn/": "distinction",
        "/dɪstɪŋgwɪʃ/": "distinguish",
        "/dɪstrɪbju:t/": "distribute",
        "/dɪstrɪbju:ʃn/": "distribution",
        "/dɪstrɪkt/": "district",
        "/daɪvɜ:s/": "diverse",
        "/daɪvɜ:səti/": "diversity",
        "/dɪvaɪd/": "divide",
        "/dɪvɪʒn/": "division",
        "/dɪvɔ:s/": "divorce",
        "/dɒktə(r)/": "doctor",
        "/dɒkjumənt/": "document",
        "/dɒg/": "dog",
        "/dəmestɪk/": "domestic",
        "/dɒmɪnənt/": "dominant",
        "/dɒmɪneɪt/": "dominate",
        "/dɔ:(r)/": "door",
        "/dʌbl/": "double",
        "/daʊt/": "doubt",
        "/daʊn/": "down",
        "/daʊntaʊn/": "downtown",
        "/dʌzn/": "dozen",
        "/drɑ:ft/": "draft",
        "/dræg/": "drag",
        "/drɑ:mə/": "drama",
        "/drəmætɪk/": "dramatic",
        "/drəmætɪkli/": "dramatically",
        "/drɔ:/": "draw",
        "/drɔ:ɪŋ/": "drawing",
        "/dri:m/": "dream",
        "/dres/": "dress",
        "/drɪŋk/": "drink",
        "/draɪv/": "drive",
        "/draɪvə(r)/": "driver",
        "/drɒp/": "drop",
        "/drʌg/": "drug",
        "/draɪ/": "dry",
        "/dju:/": "due",
        "/djʊərɪŋ/": "during",
        "/dʌst/": "dust",
        "/dju:ti/": "duty",
        "/i:tʃ/": "each",
        "/i:gə(r)/": "eager",
        "/ɪə(r)/": "ear",
        "/ɜ:li/": "early",
        "/ɜ:n/": "earn",
        "/ɜ:nɪŋz/": "earnings",
        "/ɜ:θ/": "earth",
        "/i:z/": "ease",
        "/i:zəli/": "easily",
        "/i:st/": "east",
        "/i:stən/": "eastern",
        "/i:zi/": "easy",
        "/i:t/": "eat",
        "/i:kənɒmɪk/": "economic",
        "/i:kənɒmɪks/": "economics",
        "/ɪkɒnəmɪst/": "economist",
        "/ɪkɒnəmi/": "economy",
        "/edʒ/": "edge",
        "/ɪdɪʃn/": "edition",
        "/edɪtə(r)/": "editor",
        "/edʒukeɪt/": "educate",
        "/edʒukeɪʃn/": "education",
        "/edʒukeɪʃənl/": "educational",
        "/edʒukeɪtə(r)/": "educator",
        "/ɪfekt/": "effect",
        "/ɪfektɪv/": "effective",
        "/ɪfektɪvli/": "effectively",
        "/ɪfɪʃnsi/": "efficiency",
        "/ɪfɪʃnt/": "efficient",
        "/efət/": "effort",
        "/eg/": "egg",
        "/eɪt/": "eight",
        "/aɪðə(r)/": "either",
        "/eldəli/": "elderly",
        "/ɪlekt/": "elect",
        "/ɪlekʃn/": "election",
        "/ɪlektrɪk/": "electric",
        "/ɪlektrɪsəti/": "electricity",
        "/ɪlektrɒnɪk/": "electronic",
        "/elɪmənt/": "element",
        "/elɪmentri/": "elementary",
        "/ɪlɪmɪneɪt/": "eliminate",
        "/eɪli:t/": "elite",
        "/els/": "else",
        "/elsweə(r)/": "elsewhere",
        "/ɪmbreɪs/": "embrace",
        "/ɪmɜ:dʒ/": "emerge",
        "/ɪmɜ:dʒənsi/": "emergency",
        "/ɪmɪʃn/": "emission",
        "/ɪməʊʃn/": "emotion",
        "/ɪməʊʃənl/": "emotional",
        "/emfəsɪs/": "emphasis",
        "/emfəsaɪz/": "emphasize",
        "/ɪmplɔɪ/": "employ",
        "/ɪmplɔɪi:/": "employee",
        "/ɪmplɔɪə(r)/": "employer",
        "/ɪmplɔɪmənt/": "employment",
        "/empti/": "empty",
        "/ɪneɪbl/": "enable",
        "/ɪnkaʊntə(r)/": "encounter",
        "/ɪnkʌrɪdʒ/": "encourage",
        "/end/": "end",
        "/enəmi/": "enemy",
        "/enədʒi/": "energy",
        "/ɪnfɔ:smənt/": "enforcement",
        "/ɪngeɪdʒ/": "engage",
        "/endʒɪn/": "engine",
        "/endʒɪnɪə(r)/": "engineer",
        "/endʒɪnɪərɪŋ/": "engineering",
        "/ɪnhɑ:ns/": "enhance",
        "/ɪndʒɔɪ/": "enjoy",
        "/ɪnɔ:məs/": "enormous",
        "/ɪnʌf/": "enough",
        "/ɪnʃʊə(r)/": "ensure",
        "/entə(r)/": "enter",
        "/entəpraɪz/": "enterprise",
        "/entəteɪnmənt/": "entertainment",
        "/ɪntaɪə(r)/": "entire",
        "/ɪntaɪəli/": "entirely",
        "/entri/": "entry",
        "/ɪnvaɪrənmənt/": "environment",
        "/ɪnvaɪrənmentl/": "environmental",
        "/epɪsəʊd/": "episode",
        "/i:kwəl/": "equal",
        "/i:kwəli/": "equally",
        "/ɪkwɪpmənt/": "equipment",
        "/ɪərə/": "era",
        "/erə(r)/": "error",
        "/ɪskeɪp/": "escape",
        "/ɪspeʃəli/": "especially",
        "/eseɪ/": "essay",
        "/ɪsenʃl/": "essential",
        "/ɪsenʃəli/": "essentially",
        "/ɪstæblɪʃ/": "establish",
        "/ɪstæblɪʃmənt/": "establishment",
        "/ɪsteɪt/": "estate",
        "/estɪmeɪt/": "estimate",
        "/et": "etc",
        "/eθnɪk/": "ethnic",
        "/ɪvæljueɪt/": "evaluate",
        "/ɪvæljueɪʃn/": "evaluation",
        "/i:vn/": "even",
        "/i:vnɪŋ/": "evening",
        "/ɪvent/": "event",
        "/ɪventʃuəli/": "eventually",
        "/evə(r)/": "ever",
        "/evri/": "every",
        "/evribɒdi/": "everybody",
        "/evrideɪ/": "everyday",
        "/evriwʌn/": "everyone",
        "/evriθɪŋ/": "everything",
        "/evriweə(r)/": "everywhere",
        "/evɪdəns/": "evidence",
        "/i:vəlu:ʃn/": "evolution",
        "/ɪvɒlv/": "evolve",
        "/ɪgzækt/": "exact",
        "/ɪgzæktli/": "exactly",
        "/ɪgzæmɪneɪʃn/": "examination",
        "/ɪgzæmɪn/": "examine",
        "/ɪgzɑ:mpl/": "example",
        "/ɪksi:d/": "exceed",
        "/eksələnt/": "excellent",
        "/ɪksept/": "except",
        "/ɪksepʃn/": "exception",
        "/ɪkstʃeɪndʒ/": "exchange",
        "/ɪksaɪtɪŋ/": "exciting",
        "/ɪgzekjətɪv/": "executive",
        "/eksəsaɪz/": "exercise",
        "/ɪgzɪbɪt/": "exhibit",
        "/eksɪbɪʃn/": "exhibition",
        "/ɪgzɪst/": "exist",
        "/ɪgzɪstəns/": "existence",
        "/ɪgzɪstɪŋ/": "existing",
        "/ɪkspænd/": "expand",
        "/ɪkspænʃn/": "expansion",
        "/ɪkspekt/": "expect",
        "/ekspekteɪʃn/": "expectation",
        "/ɪkspens/": "expense",
        "/ɪkspensɪv/": "expensive",
        "/ɪkspɪəriəns/": "experience",
        "/ɪksperɪmənt/": "experiment",
        "/ekspɜ:t/": "expert",
        "/ɪkspleɪn/": "explain",
        "/ekspləneɪʃn/": "explanation",
        "/ɪkspləʊd/": "explode",
        "/ɪksplɔ:(r)/": "explore",
        "/ɪkspləʊʒn/": "explosion",
        "/ɪkspəʊz/": "expose",
        "/ɪkspəʊʒə(r)/": "exposure",
        "/ɪkspres/": "express",
        "/ɪkspreʃn/": "expression",
        "/ɪkstend/": "extend",
        "/ɪkstenʃn/": "extension",
        "/ɪkstensɪv/": "extensive",
        "/ɪkstent/": "extent",
        "/ɪkstɜ:nl/": "external",
        "/ekstrə/": "extra",
        "/ɪkstrɔ:dnri/": "extraordinary",
        "/ɪkstri:m/": "extreme",
        "/ɪkstri:mli/": "extremely",
        "/aɪ/": "eye",
        "/fæbrɪk/": "fabric",
        "/feɪs/": "face",
        "/fəsɪləti/": "facility",
        "/fækt/": "fact",
        "/fæktə(r)/": "factor",
        "/fæktri/": "factory",
        "/fæklti/": "faculty",
        "/feɪd/": "fade",
        "/feɪl/": "fail",
        "/feɪljə(r)/": "failure",
        "/feə(r)/": "fair",
        "/feəli/": "fairly",
        "/feɪθ/": "faith",
        "/fɔ:l/": "fall",
        "/fɔ:ls/": "false",
        "/fəmɪliə(r)/": "familiar",
        "/fæməli/": "family",
        "/feɪməs/": "famous",
        "/fæn/": "fan",
        "/fæntəsi/": "fantasy",
        "/fɑ:(r)/": "far",
        "/fɑ:m/": "farm",
        "/fɑ:mə(r)/": "farmer",
        "/fæʃn/": "fashion",
        "/fɑ:st/": "fast",
        "/fæt/": "fat",
        "/feɪt/": "fate",
        "/fɑ:ðə(r)/": "father",
        "/fɔ:lt/": "fault",
        "/fɪə(r)/": "fear",
        "/fi:tʃə(r)/": "feature",
        "/fedərəl/": "federal",
        "/fi:/": "fee",
        "/fi:d/": "feed",
        "/fi:l/": "feel",
        "/fi:lɪŋ/": "feeling",
        "/feləʊ/": "fellow",
        "/fi:meɪl/": "female",
        "/fens/": "fence",
        "/fju:/": "few",
        "/fɪkʃn/": "fiction",
        "/fi:ld/": "field",
        "/fɪfti:n/": "fifteen",
        "/fɪfθ/": "fifth",
        "/fɪfti/": "fifty",
        "/faɪt/": "fight",
        "/faɪtə(r)/": "fighter",
        "/faɪtɪŋ/": "fighting",
        "/fɪgə(r)/": "figure",
        "/faɪl/": "file",
        "/fɪl/": "fill",
        "/fɪlm/": "film",
        "/faɪnl/": "final",
        "/faɪnəli/": "finally",
        "/faɪnæns/": "finance",
        "/faɪnænʃl/": "financial",
        "/faɪnd/": "find",
        "/faɪndɪŋ/": "finding",
        "/faɪn/": "fine",
        "/fɪŋgə(r)/": "finger",
        "/fɪnɪʃ/": "finish",
        "/faɪə(r)/": "fire",
        "/fɜ:m/": "firm",
        "/fɜ:st/": "first",
        "/fɪʃ/": "fish",
        "/fɪʃɪŋ/": "fishing",
        "/fɪt/": "fit",
        "/fɪtnəs/": "fitness",
        "/faɪv/": "five",
        "/fɪks/": "fix",
        "/flæg/": "flag",
        "/fleɪm/": "flame",
        "/flæt/": "flat",
        "/fli:/": "flee",
        "/fleʃ/": "flesh",
        "/flaɪt/": "flight",
        "/fləʊt/": "float",
        "/flɔ:(r)/": "floor",
        "/fləʊ/": "flow",
        "/flaʊə(r)/": "flower",
        "/flaɪ/": "fly",
        "/fəʊkəs/": "focus",
        "/fəʊk/": "folk",
        "/fɒləʊ/": "follow",
        "/fɒləʊɪŋ/": "following",
        "/fu:d/": "food",
        "/fʊt/": "foot",
        "/fʊtbɔ:l/": "football",
        "/fə(r)/": "for",
        "/fɔ:s/": "force",
        "/fɒrən/": "foreign",
        "/fɒrɪst/": "forest",
        "/fərevə(r)/": "forever",
        "/fəget/": "forget",
        "/fɔ:m/": "form",
        "/fɔ:ml/": "formal",
        "/fɔ:meɪʃn/": "formation",
        "/fɔ:mə(r)/": "former",
        "/fɔ:mjələ/": "formula",
        "/fɔ:θ/": "fourth",
        "/fɔ:tʃu:n/": "fortune",
        "/fɔ:wəd/": "forward",
        "/faʊnd/": "found",
        "/faʊndeɪʃn/": "foundation",
        "/faʊndə(r)/": "founder",
        "/fɔ:(r)/": "four",
        "/freɪm/": "frame",
        "/freɪmwɜ:k/": "framework",
        "/fri:/": "free",
        "/fri:dəm/": "freedom",
        "/fri:z/": "freeze",
        "/fri:kwənsi/": "frequency",
        "/fri:kwənt/": "frequent",
        "/fri:kwəntli/": "frequently",
        "/freʃ/": "fresh",
        "/frend/": "friend",
        "/frendli/": "friendly",
        "/frendʃɪp/": "friendship",
        "/frəm/": "from",
        "/frʌnt/": "front",
        "/fru:t/": "fruit",
        "/frʌstreɪʃn/": "frustration",
        "/fju:əl/": "fuel",
        "/fʊl/": "full",
        "/fʊli/": "fully",
        "/fʌn/": "fun",
        "/fʌŋkʃn/": "function",
        "/fʌnd/": "fund",
        "/fʌndəmentl/": "fundamental",
        "/fʌndɪŋ/": "funding",
        "/fju:nərəl/": "funeral",
        "/fʌni/": "funny",
        "/fɜ:nɪtʃə(r)/": "furniture",
        "/fɜ:ðəmɔ:(r)/": "furthermore",
        "/fju:tʃə(r)/": "future",
        "/geɪn/": "gain",
        "/gæləksi/": "galaxy",
        "/gæləri/": "gallery",
        "/geɪm/": "game",
        "/gæŋ/": "gang",
        "/gæp/": "gap",
        "/gærɑ:ʒ/": "garage",
        "/gɑ:dn/": "garden",
        "/gɑ:lɪk/": "garlic",
        "/gæs/": "gas",
        "/geɪt/": "gate",
        "/gæðə(r)/": "gather",
        "/geɪ/": "gay",
        "/geɪz/": "gaze",
        "/gɪə(r)/": "gear",
        "/dʒendə(r)/": "gender",
        "/dʒi:n/": "gene",
        "/dʒenrəl/": "general",
        "/dʒenrəli/": "generally",
        "/dʒenəreɪt/": "generate",
        "/dʒenəreɪʃn/": "generation",
        "/dʒənetɪk/": "genetic",
        "/dʒentlmən/": "gentleman",
        "/dʒentli/": "gently",
        "/dʒestʃə(r)/": "gesture",
        "/get/": "get",
        "/gəʊst/": "ghost",
        "/dʒaɪənt/": "giant",
        "/gɪft/": "gift",
        "/gɪftɪd/": "gifted",
        "/gɜ:l/": "girl",
        "/gɜ:lfrend/": "girlfriend",
        "/gɪv/": "give",
        "/gɪvn/": "given",
        "/glæd/": "glad",
        "/glɑ:ns/": "glance",
        "/glɑ:s/": "glass",
        "/gləʊbl/": "global",
        "/glʌv/": "glove",
        "/gəʊ/": "go",
        "/gəʊl/": "goal",
        "/gəʊld/": "gold",
        "/gəʊldən/": "golden",
        "/gɒlf/": "golf",
        "/gʊd/": "good",
        "/gʌvənmənt/": "government",
        "/gʌvənə(r)/": "governor",
        "/græb/": "grab",
        "/greɪd/": "grade",
        "/grædʒuəli/": "gradually",
        "/grædʒuət/": "graduate",
        "/greɪn/": "grain",
        "/grænd/": "grand",
        "/grænfɑ:ðə(r)/": "grandfather",
        "/grænmʌðə(r)/": "grandmother",
        "/grɑ:nt/": "grant",
        "/grɑ:s/": "grass",
        "/greɪ/": "gray",
        "/greɪt/": "great",
        "/gri:n/": "green",
        "/grəʊsəri/": "grocery",
        "/graʊnd/": "ground",
        "/gru:p/": "group",
        "/grəʊ/": "grow",
        "/grəʊɪŋ/": "growing",
        "/grəʊθ/": "growth",
        "/gærənti:/": "guarantee",
        "/gɑ:d/": "guard",
        "/ges/": "guess",
        "/gest/": "guest",
        "/gaɪd/": "guide",
        "/gaɪdlaɪn/": "guideline",
        "/gɪlti/": "guilty",
        "/gʌn/": "gun",
        "/gaɪ/": "guy",
        "/hæbɪt/": "habit",
        "/hæbɪtæt/": "habitat",
        "/heə(r)/": "hair",
        "/hɑ:f/": "half",
        "/hɔ:l/": "hall",
        "/hænd/": "hand",
        "/hændfʊl/": "handful",
        "/hændl/": "handle",
        "/hæŋ/": "hang",
        "/hæpən/": "happen",
        "/hæpi/": "happy",
        "/hɑ:d/": "hard",
        "/hɑ:dli/": "hardly",
        "/hæt/": "hat",
        "/heɪt/": "hate",
        "/həv/": "have",
        "/hi/": "he",
        "/hed/": "head",
        "/hedlaɪn/": "headline",
        "/hedkwɔ:təz/": "headquarters",
        "/helθ/": "health",
        "/helθi/": "healthy",
        "/hɪə(r)/": "here",
        "/hɪərɪŋ/": "hearing",
        "/hɑ:t/": "heart",
        "/hi:t/": "heat",
        "/hevn/": "heaven",
        "/hevɪli/": "heavily",
        "/hevi/": "heavy",
        "/hi:l/": "heel",
        "/haɪt/": "height",
        "/helɪkɒptə(r)/": "helicopter",
        "/hel/": "hell",
        "/hələʊ/": "hello",
        "/help/": "help",
        "/helpfl/": "helpful",
        "/hə(r)/": "her",
        "/herɪtɪdʒ/": "heritage",
        "/hɪərəʊ/": "hero",
        "/hɜ:self/": "herself",
        "/heɪ/": "hey",
        "/haɪ/": "high",
        "/haɪd/": "hide",
        "/haɪlaɪt/": "highlight",
        "/haɪli/": "highly",
        "/haɪweɪ/": "highway",
        "/hɪl/": "hill",
        "/hɪm/": "him",
        "/hɪmself/": "himself",
        "/hɪp/": "hip",
        "/haɪə(r)/": "hire",
        "/hɪz/": "his",
        "/hɪstɔ:riən/": "historian",
        "/hɪstɒrɪk/": "historic",
        "/hɪstɒrɪkl/": "historical",
        "/hɪstri/": "history",
        "/hɪt/": "hit",
        "/həʊld/": "hold",
        "/həʊl/": "whole",
        "/hɒlədeɪ/": "holiday",
        "/həʊli/": "holy",
        "/həʊm/": "home",
        "/həʊmləs/": "homeless",
        "/ɒnɪst/": "honest",
        "/hʌni/": "honey",
        "/ɒnə(r)/": "honor",
        "/həʊp/": "hope",
        "/həraɪzn/": "horizon",
        "/hɒrə(r)/": "horror",
        "/hɔ:s/": "horse",
        "/hɒspɪtl/": "hospital",
        "/həʊst/": "host",
        "/hɒt/": "hot",
        "/həʊtel/": "hotel",
        "/aʊə(r)/": "hour",
        "/haʊs/": "house",
        "/haʊshəʊld/": "household",
        "/haʊzɪŋ/": "housing",
        "/haʊ/": "how",
        "/haʊevə(r)/": "however",
        "/hju:dʒ/": "huge",
        "/hju:mən/": "human",
        "/hju:mə(r)/": "humor",
        "/hʌndrəd/": "hundred",
        "/hʌŋgri/": "hungry",
        "/hʌntə(r)/": "hunter",
        "/hʌntɪŋ/": "hunting",
        "/hɜ:t/": "hurt",
        "/hʌzbənd/": "husband",
        "/haɪpɒθəsɪs/": "hypothesis",
        "/aɪs/": "ice",
        "/aɪdɪə/": "idea",
        "/aɪdi:əl/": "ideal",
        "/aɪdentɪfɪkeɪʃn/": "identification",
        "/aɪdentɪfaɪ/": "identify",
        "/aɪdentəti/": "identity",
        "/ɪf/": "if",
        "/ɪgnɔ:(r)/": "ignore",
        "/ɪl/": "ill",
        "/ɪli:gl/": "illegal",
        "/ɪlnəs/": "illness",
        "/ɪləstreɪt/": "illustrate",
        "/ɪmɪdʒ/": "image",
        "/ɪmædʒɪneɪʃn/": "imagination",
        "/ɪmædʒɪn/": "imagine",
        "/ɪmi:diət/": "immediate",
        "/ɪmi:diətli/": "immediately",
        "/ɪmɪgrənt/": "immigrant",
        "/ɪmɪgreɪʃn/": "immigration",
        "/ɪmpækt/": "impact",
        "/ɪmplɪment/": "implement",
        "/ɪmplɪkeɪʃn/": "implication",
        "/ɪmplaɪ/": "imply",
        "/ɪmpɔ:tns/": "importance",
        "/ɪmpɔ:tnt/": "important",
        "/ɪmpəʊz/": "impose",
        "/ɪmpɒsəbl/": "impossible",
        "/ɪmpres/": "impress",
        "/ɪmpreʃn/": "impression",
        "/ɪmpresɪv/": "impressive",
        "/ɪmpru:v/": "improve",
        "/ɪmpru:vmənt/": "improvement",
        "/ɪn/": "in",
        "/ɪnsentɪv/": "incentive",
        "/ɪnsɪdənt/": "incident",
        "/ɪnklu:d/": "include",
        "/ɪnklu:dɪŋ/": "including",
        "/ɪnkʌm/": "income",
        "/ɪnkɔ:pəreɪt/": "incorporate",
        "/ɪnkri:s/": "increase",
        "/ɪnkri:st/": "increased",
        "/ɪnkri:sɪŋli/": "increasingly",
        "/ɪnkredəbl/": "incredible",
        "/ɪndi:d/": "indeed",
        "/ɪndɪpendəns/": "independence",
        "/ɪndɪpendənt/": "independent",
        "/ɪndeks/": "index",
        "/ɪndɪkeɪt/": "indicate",
        "/ɪndɪkeɪʃn/": "indication",
        "/ɪndɪvɪdʒuəl/": "individual",
        "/ɪndʌstriəl/": "industrial",
        "/ɪndəstri/": "industry",
        "/ɪnfənt/": "infant",
        "/ɪnfekʃn/": "infection",
        "/ɪnfleɪʃn/": "inflation",
        "/ɪnfluəns/": "influence",
        "/ɪnfɔ:m/": "inform",
        "/ɪnfəmeɪʃn/": "information",
        "/ɪngri:diənt/": "ingredient",
        "/ɪnɪʃl/": "initial",
        "/ɪnɪʃəli/": "initially",
        "/ɪnɪʃətɪv/": "initiative",
        "/ɪndʒəri/": "injury",
        "/ɪnə(r)/": "inner",
        "/ɪnəsnt/": "innocent",
        "/ɪnkwaɪəri/": "inquiry",
        "/ɪnsaɪd/": "inside",
        "/ɪnsaɪt/": "insight",
        "/ɪnsɪst/": "insist",
        "/ɪnspaɪə(r)/": "inspire",
        "/ɪnstɔ:l/": "install",
        "/ɪnstəns/": "instance",
        "/ɪnsted/": "instead",
        "/ɪnstɪtju:ʃn/": "institution",
        "/ɪnstɪtju:ʃənl/": "institutional",
        "/ɪnstrʌkʃn/": "instruction",
        "/ɪnstrʌktə(r)/": "instructor",
        "/ɪnstrəmənt/": "instrument",
        "/ɪnʃʊərəns/": "insurance",
        "/ɪntəlektʃuəl/": "intellectual",
        "/ɪntelɪdʒəns/": "intelligence",
        "/ɪntend/": "intend",
        "/ɪntens/": "intense",
        "/ɪntensəti/": "intensity",
        "/ɪntenʃn/": "intention",
        "/ɪntərækʃn/": "interaction",
        "/ɪntrəst/": "interest",
        "/ɪntrəstɪd/": "interested",
        "/ɪntrəstɪŋ/": "interesting",
        "/ɪntɜ:nl/": "internal",
        "/ɪntənæʃnəl/": "international",
        "/ɪntɜ:prət/": "interpret",
        "/ɪntɜ:prəteɪʃn/": "interpretation",
        "/ɪntəvenʃn/": "intervention",
        "/ɪntəvju:/": "interview",
        "/ɪntə/": "into",
        "/ɪntrədju:s/": "introduce",
        "/ɪntrədʌkʃn/": "introduction",
        "/ɪnveɪʒn/": "invasion",
        "/ɪnvest/": "invest",
        "/ɪnvestɪgeɪt/": "investigate",
        "/ɪnvestɪgeɪʃn/": "investigation",
        "/ɪnvestɪgeɪtə(r)/": "investigator",
        "/ɪnvestmənt/": "investment",
        "/ɪnvestə(r)/": "investor",
        "/ɪnvaɪt/": "invite",
        "/ɪnvɒlv/": "involve",
        "/ɪnvɒlvd/": "involved",
        "/ɪnvɒlvmənt/": "involvement",
        "/aɪən/": "iron",
        "/aɪlənd/": "island",
        "/ɪʃu:/": "issue",
        "/ɪt/": "it",
        "/aɪtəm/": "item",
        "/ɪts/": "its",
        "/ɪtself/": "itself",
        "/dʒækɪt/": "jacket",
        "/dʒeɪl/": "jail",
        "/dʒet/": "jet",
        "/dʒɒb/": "job",
        "/dʒɔɪn/": "join",
        "/dʒɔɪnt/": "joint",
        "/dʒəʊk/": "joke",
        "/dʒɜ:nl/": "journal",
        "/dʒɜ:nəlɪst/": "journalist",
        "/dʒɜ:ni/": "journey",
        "/dʒɔɪ/": "joy",
        "/dʒʌdʒ/": "judge",
        "/dʒu:s/": "juice",
        "/dʒʌmp/": "jump",
        "/dʒu:niə(r)/": "junior",
        "/dʒʊəri/": "jury",
        "/dʒʌst/": "just",
        "/dʒʌstɪs/": "justice",
        "/dʒʌstɪfaɪ/": "justify",
        "/ki:p/": "keep",
        "/ki:/": "key",
        "/kɪk/": "kick",
        "/kɪd/": "kid",
        "/kɪl/": "kill",
        "/kɪlə(r)/": "killer",
        "/kɪlɪŋ/": "killing",
        "/kaɪnd/": "kind",
        "/kɪŋ/": "king",
        "/kɪs/": "kiss",
        "/kɪtʃɪn/": "kitchen",
        "/ni:/": "knee",
        "/naɪf/": "knife",
        "/nɒk/": "knock",
        "/nəʊ/": "no",
        "/nɒlɪdʒ/": "knowledge",
        "/læb/": "lab",
        "/leɪbl/": "label",
        "/ləbɒrətri/": "laboratory",
        "/læk/": "lack",
        "/leɪdi/": "lady",
        "/leɪk/": "lake",
        "/lænd/": "land",
        "/lændskeɪp/": "landscape",
        "/læŋgwɪdʒ/": "language",
        "/læp/": "lap",
        "/lɑ:dʒ/": "large",
        "/lɑ:dʒli/": "largely",
        "/leɪt/": "late",
        "/leɪtə(r)/": "later",
        "/lætə(r)/": "latter",
        "/lɑ:f/": "laugh",
        "/lɔ:ntʃ/": "launch",
        "/lɔ:/": "law",
        "/lɔ:n/": "lawn",
        "/lɔ:su:t/": "lawsuit",
        "/lɔɪə(r)/": "lawyer",
        "/leɪ/": "lay",
        "/leɪə(r)/": "layer",
        "/li:də(r)/": "leader",
        "/li:dəʃɪp/": "leadership",
        "/li:f/": "leaf",
        "/li:g/": "league",
        "/li:n/": "lean",
        "/lɜ:n/": "learn",
        "/lɜ:nɪŋ/": "learning",
        "/li:st/": "least",
        "/leðə(r)/": "leather",
        "/li:v/": "leave",
        "/left/": "left",
        "/leg/": "leg",
        "/legəsi/": "legacy",
        "/li:gl/": "legal",
        "/ledʒənd/": "legend",
        "/ledʒɪsleɪʃn/": "legislation",
        "/lɪdʒɪtɪmət/": "legitimate",
        "/lemən/": "lemon",
        "/leŋkθ/": "length",
        "/les/": "less",
        "/lesn/": "lesson",
        "/let/": "let",
        "/letə(r)/": "letter",
        "/levl/": "level",
        "/lɪbərəl/": "liberal",
        "/laɪbrəri/": "library",
        "/laɪsns/": "license",
        "/laɪf/": "life",
        "/laɪfstaɪl/": "lifestyle",
        "/laɪftaɪm/": "lifetime",
        "/lɪft/": "lift",
        "/laɪt/": "light",
        "/laɪk/": "like",
        "/laɪkli/": "likely",
        "/lɪmɪt/": "limit",
        "/lɪmɪteɪʃn/": "limitation",
        "/lɪmɪtɪd/": "limited",
        "/laɪn/": "line",
        "/lɪŋk/": "link",
        "/lɪp/": "lip",
        "/lɪst/": "list",
        "/lɪsn/": "listen",
        "/lɪtərəli/": "literally",
        "/lɪtərəri/": "literary",
        "/lɪtrətʃə(r)/": "literature",
        "/lɪtl/": "little",
        "/lɪvɪŋ/": "living",
        "/ləʊd/": "load",
        "/ləʊn/": "loan",
        "/ləʊkl/": "local",
        "/ləʊkeɪt/": "locate",
        "/ləʊkeɪʃn/": "location",
        "/lɒk/": "lock",
        "/lɒŋ/": "long",
        "/lɒŋ": "long-term",
        "/lʊk/": "look",
        "/lu:s/": "loose",
        "/lu:z/": "lose",
        "/lɒs/": "loss",
        "/lɒst/": "lost",
        "/lɒt/": "lot",
        "/laʊd/": "loud",
        "/lʌv/": "love",
        "/lʌvli/": "lovely",
        "/lʌvə(r)/": "lover",
        "/ləʊ/": "low",
        "/lʌk/": "luck",
        "/lʌki/": "lucky",
        "/lʌntʃ/": "lunch",
        "/lʌŋ/": "lung",
        "/məʃi:n/": "machine",
        "/mæd/": "mad",
        "/mægəzi:n/": "magazine",
        "/meɪl/": "male",
        "/meɪn/": "main",
        "/meɪnli/": "mainly",
        "/meɪnteɪn/": "maintain",
        "/meɪntənəns/": "maintenance",
        "/meɪdʒə(r)/": "major",
        "/mədʒɒrəti/": "majority",
        "/meɪk/": "make",
        "/meɪkə(r)/": "maker",
        "/mɔ:l/": "mall",
        "/mæn/": "man",
        "/mænɪdʒ/": "manage",
        "/mænɪdʒmənt/": "management",
        "/mænɪdʒə(r)/": "manager",
        "/mænə(r)/": "manner",
        "/mænjufæktʃərə(r)/": "manufacturer",
        "/mænjufæktʃərɪŋ/": "manufacturing",
        "/meni/": "many",
        "/mæp/": "map",
        "/mɑ:dʒɪn/": "margin",
        "/mɑ:k/": "mark",
        "/mɑ:kɪt/": "market",
        "/mɑ:kɪtɪŋ/": "marketing",
        "/mærɪdʒ/": "marriage",
        "/mærid/": "married",
        "/mæri/": "marry",
        "/mɑ:sk/": "mask",
        "/mæs/": "mass",
        "/mæsɪv/": "massive",
        "/mɑ:stə(r)/": "master",
        "/mætʃ/": "match",
        "/mətɪəriəl/": "material",
        "/mæθ/": "math",
        "/mætə(r)/": "matter",
        "/meɪ/": "may",
        "/meɪbi/": "maybe",
        "/meə(r)/": "mayor",
        "/mi/": "me",
        "/mi:l/": "meal",
        "/mi:n/": "mean",
        "/mi:nɪŋ/": "meaning",
        "/mi:nwaɪl/": "meanwhile",
        "/meʒə(r)/": "measure",
        "/meʒəmənt/": "measurement",
        "/mi:t/": "meet",
        "/mekənɪzəm/": "mechanism",
        "/mi:diə/": "media",
        "/medɪkl/": "medical",
        "/medɪkeɪʃn/": "medication",
        "/medsn/": "medicine",
        "/mi:diəm/": "medium",
        "/mi:tɪŋ/": "meeting",
        "/membə(r)/": "member",
        "/membəʃɪp/": "membership",
        "/meməri/": "memory",
        "/mentl/": "mental",
        "/menʃn/": "mention",
        "/menju:/": "menu",
        "/mɪə(r)/": "mere",
        "/mɪəli/": "merely",
        "/mes/": "mess",
        "/mesɪdʒ/": "message",
        "/metl/": "metal",
        "/mi:tə(r)/": "meter",
        "/meθəd/": "method",
        "/mɪdl/": "middle",
        "/maɪt/": "might",
        "/mɪlətri/": "military",
        "/mɪlk/": "milk",
        "/mɪljən/": "million",
        "/maɪnd/": "mind",
        "/maɪn/": "mine",
        "/mɪnɪstə(r)/": "minister",
        "/maɪnə(r)/": "minor",
        "/maɪnɒrəti/": "minority",
        "/mɪrəkl/": "miracle",
        "/mɪrə(r)/": "mirror",
        "/mɪs/": "miss",
        "/mɪsaɪl/": "missile",
        "/mɪʃn/": "mission",
        "/mɪsteɪk/": "mistake",
        "/mɪks/": "mix",
        "/mɪkstʃə(r)/": "mixture",
        "/məʊd/": "mode",
        "/mɒdl/": "model",
        "/mɒdərət/": "moderate",
        "/mɒdn/": "modern",
        "/mɒdɪst/": "modest",
        "/mɒm/": "mom",
        "/məʊmənt/": "moment",
        "/mʌni/": "money",
        "/mɒnɪtə(r)/": "monitor",
        "/mʌnθ/": "month",
        "/mu:d/": "mood",
        "/mu:n/": "moon",
        "/mɒrəl/": "moral",
        "/mɔ:(r)/": "more",
        "/mɔ:rəʊvə(r)/": "moreover",
        "/mɔ:nɪŋ/": "morning",
        "/mɔ:gɪdʒ/": "mortgage",
        "/məʊst/": "most",
        "/məʊstli/": "mostly",
        "/mʌðə(r)/": "mother",
        "/məʊʃn/": "motion",
        "/məʊtɪveɪʃn/": "motivation",
        "/məʊtə(r)/": "motor",
        "/maʊnt/": "mount",
        "/maʊntən/": "mountain",
        "/maʊs/": "mouse",
        "/maʊθ/": "mouth",
        "/mu:v/": "move",
        "/mu:vmənt/": "movement",
        "/mu:vi/": "movie",
        "/mʌtʃ/": "much",
        "/mʌltɪpl/": "multiple",
        "/mɜ:də(r)/": "murder",
        "/mʌsl/": "muscle",
        "/mjuzi:əm/": "museum",
        "/mju:zɪk/": "music",
        "/mju:zɪkl/": "musical",
        "/mjuzɪʃn/": "musician",
        "/məst/": "must",
        "/mju:tʃuəl/": "mutual",
        "/maɪ/": "my",
        "/maɪself/": "myself",
        "/mɪstri/": "mystery",
        "/mɪθ/": "myth",
        "/neɪkɪd/": "naked",
        "/neɪm/": "name",
        "/nærətɪv/": "narrative",
        "/nærəʊ/": "narrow",
        "/neɪʃn/": "nation",
        "/næʃnəl/": "national",
        "/neɪtɪv/": "native",
        "/nætʃrəl/": "natural",
        "/nætʃrəli/": "naturally",
        "/neɪtʃə(r)/": "nature",
        "/nɪə(r)/": "near",
        "/nɪəbaɪ/": "nearby",
        "/nɪəli/": "nearly",
        "/nesəserəli/": "necessarily",
        "/nesəsəri/": "necessary",
        "/nek/": "neck",
        "/ni:d/": "need",
        "/negətɪv/": "negative",
        "/nɪgəʊʃieɪt/": "negotiate",
        "/nɪgəʊʃieɪʃn/": "negotiation",
        "/naɪðə(r)/": "neither",
        "/nɜ:v/": "nerve",
        "/nɜ:vəs/": "nervous",
        "/net/": "net",
        "/netwɜ:k/": "network",
        "/nevə(r)/": "never",
        "/nevəðəles/": "nevertheless",
        "/nju:/": "new",
        "/nju:li/": "newly",
        "/nju:z/": "news",
        "/nju:zpeɪpə(r)/": "newspaper",
        "/nekst/": "next",
        "/naɪs/": "nice",
        "/naɪt/": "night",
        "/naɪn/": "nine",
        "/nəʊbədi/": "nobody",
        "/nɒd/": "nod",
        "/nɔɪz/": "noise",
        "/nɒmɪneɪʃn/": "nomination",
        "/nʌn/": "none",
        "/nʌnðəles/": "nonetheless",
        "/nɔ:(r)/": "nor",
        "/nɔ:ml/": "normal",
        "/nɔ:məli/": "normally",
        "/nɔ:θ/": "north",
        "/nɔ:ðən/": "northern",
        "/nəʊz/": "nose",
        "/nɒt/": "not",
        "/nəʊt/": "note",
        "/nʌθɪŋ/": "nothing",
        "/nəʊtɪs/": "notice",
        "/nəʊʃn/": "notion",
        "/nɒvl/": "novel",
        "/naʊ/": "now",
        "/nəʊweə(r)/": "nowhere",
        "/nju:kliə(r)/": "nuclear",
        "/nʌmbə(r)/": "number",
        "/nju:mərəs/": "numerous",
        "/nɜ:s/": "nurse",
        "/nʌt/": "nut",
        "/ɒbdʒɪkt/": "object",
        "/əbdʒektɪv/": "objective",
        "/ɒblɪgeɪʃn/": "obligation",
        "/ɒbzəveɪʃn/": "observation",
        "/əbzɜ:v/": "observe",
        "/əbzɜ:və(r)/": "observer",
        "/əbteɪn/": "obtain",
        "/ɒbviəs/": "obvious",
        "/ɒbviəsli/": "obviously",
        "/əkeɪʒn/": "occasion",
        "/əkeɪʒnəli/": "occasionally",
        "/ɒkjupeɪʃn/": "occupation",
        "/ɒkjupaɪ/": "occupy",
        "/əkɜ:(r)/": "occur",
        "/əʊʃn/": "ocean",
        "/ɒd/": "odd",
        "/ɒdz/": "odds",
        "/əv/": "of",
        "/ɒf/": "off",
        "/əfens/": "offense",
        "/əfensɪv/": "offensive",
        "/ɒfə(r)/": "offer",
        "/ɒfɪs/": "office",
        "/ɒfɪsə(r)/": "officer",
        "/əfɪʃl/": "official",
        "/ɒfn/": "often",
        "/əʊ/": "owe",
        "/ɔɪl/": "oil",
        "/əʊkeɪ/": "ok",
        "/əʊld/": "old",
        "/ɒn/": "on",
        "/wʌns/": "once",
        "/wʌn/": "one",
        "/ɒngəʊɪŋ/": "ongoing",
        "/ʌnjən/": "onion",
        "/ɒnlaɪn/": "online",
        "/əʊnli/": "only",
        "/ɒntə/": "onto",
        "/əʊpən/": "open",
        "/əʊpənɪŋ/": "opening",
        "/ɒpəreɪt/": "operate",
        "/ɒpəreɪʃn/": "operation",
        "/ɒpəreɪtə(r)/": "operator",
        "/əpɪnjən/": "opinion",
        "/əpəʊnənt/": "opponent",
        "/ɒpətju:nəti/": "opportunity",
        "/əpəʊz/": "oppose",
        "/ɒpəzɪt/": "opposite",
        "/ɒpəzɪʃn/": "opposition",
        "/ɒpʃn/": "option",
        "/ɔ:(r)/": "or",
        "/ɒrɪndʒ/": "orange",
        "/ɔ:də(r)/": "order",
        "/ɔ:dnri/": "ordinary",
        "/ɔ:gænɪk/": "organic",
        "/ɔ:gənaɪzeɪʃn/": "organization",
        "/ɔ:gənaɪz/": "organize",
        "/ɔ:riənteɪʃn/": "orientation",
        "/ɒrɪdʒɪn/": "origin",
        "/ərɪdʒənl/": "original",
        "/ərɪdʒənəli/": "originally",
        "/ʌðə(r)/": "other",
        "/ʌðəwaɪz/": "otherwise",
        "/ɑ:(r)/": "our",
        "/ɑ:selvz/": "ourselves",
        "/aʊt/": "out",
        "/aʊtkʌm/": "outcome",
        "/aʊtsaɪd/": "outside",
        "/ʌvn/": "oven",
        "/əʊvə(r)/": "over",
        "/əʊvərɔ:l/": "overall",
        "/əʊvəkʌm/": "overcome",
        "/əʊvəlʊk/": "overlook",
        "/əʊn/": "own",
        "/əʊnə(r)/": "owner",
        "/peɪs/": "pace",
        "/pæk/": "pack",
        "/pækɪdʒ/": "package",
        "/peɪdʒ/": "page",
        "/peɪn/": "pain",
        "/peɪnfl/": "painful",
        "/peɪnt/": "paint",
        "/peɪntə(r)/": "painter",
        "/peɪntɪŋ/": "painting",
        "/peə(r)/": "pair",
        "/peɪl/": "pale",
        "/pɑ:m/": "palm",
        "/pæn/": "pan",
        "/pænl/": "panel",
        "/pænt/": "pant",
        "/peɪpə(r)/": "paper",
        "/peərənt/": "parent",
        "/pɑ:k/": "park",
        "/pɑ:kɪŋ/": "parking",
        "/pɑ:t/": "part",
        "/pɑ:tɪsɪpənt/": "participant",
        "/pɑ:tɪsɪpeɪt/": "participate",
        "/pɑ:tɪsɪpeɪʃn/": "participation",
        "/pətɪkjələ(r)/": "particular",
        "/pətɪkjələli/": "particularly",
        "/pɑ:tli/": "partly",
        "/pɑ:tnə(r)/": "partner",
        "/pɑ:tnəʃɪp/": "partnership",
        "/pɑ:ti/": "party",
        "/pɑ:s/": "pass",
        "/pæsɪdʒ/": "passage",
        "/pæsɪndʒə(r)/": "passenger",
        "/pæʃn/": "passion",
        "/pɑ:st/": "past",
        "/pætʃ/": "patch",
        "/pɑ:θ/": "path",
        "/peɪʃnt/": "patient",
        "/pætn/": "pattern",
        "/pɔ:z/": "pause",
        "/peɪ/": "pay",
        "/peɪmənt/": "payment",
        "/pi:s/": "piece",
        "/pi:k/": "peak",
        "/pɪə(r)/": "peer",
        "/penəlti/": "penalty",
        "/pi:pl/": "people",
        "/pepə(r)/": "pepper",
        "/pə(r)/": "per",
        "/pəsi:v/": "perceive",
        "/pəsentɪdʒ/": "percentage",
        "/pəsepʃn/": "perception",
        "/pɜ:fɪkt/": "perfect",
        "/pɜ:fɪktli/": "perfectly",
        "/pəfɔ:m/": "perform",
        "/pəfɔ:məns/": "performance",
        "/pəhæps/": "perhaps",
        "/pɪəriəd/": "period",
        "/pɜ:mənənt/": "permanent",
        "/pəmɪʃn/": "permission",
        "/pəmɪt/": "permit",
        "/pɜ:sn/": "person",
        "/pɜ:sənl/": "personal",
        "/pɜ:sənæləti/": "personality",
        "/pɜ:sənəli/": "personally",
        "/pɜ:sənel/": "personnel",
        "/pəspektɪv/": "perspective",
        "/pəsweɪd/": "persuade",
        "/pet/": "pet",
        "/feɪz/": "phase",
        "/fənɒmɪnən/": "phenomenon",
        "/fəlɒsəfi/": "philosophy",
        "/fəʊn/": "phone",
        "/fəʊtəʊ/": "photo",
        "/fəʊtəgrɑ:f/": "photograph",
        "/fətɒgrəfə(r)/": "photographer",
        "/freɪz/": "phrase",
        "/fɪzɪkl/": "physical",
        "/fɪzɪkli/": "physically",
        "/fɪzɪʃn/": "physician",
        "/piænəʊ/": "piano",
        "/pɪk/": "pick",
        "/pɪktʃə(r)/": "picture",
        "/paɪ/": "pie",
        "/paɪl/": "pile",
        "/paɪlət/": "pilot",
        "/paɪn/": "pine",
        "/pɪŋk/": "pink",
        "/paɪp/": "pipe",
        "/pɪtʃ/": "pitch",
        "/pleɪs/": "place",
        "/plæn/": "plan",
        "/pleɪn/": "plane",
        "/plænɪt/": "planet",
        "/plænɪŋ/": "planning",
        "/plɑ:nt/": "plant",
        "/plæstɪk/": "plastic",
        "/pleɪt/": "plate",
        "/plætfɔ:m/": "platform",
        "/pleɪ/": "play",
        "/pleɪə(r)/": "player",
        "/pli:z/": "please",
        "/pleʒə(r)/": "pleasure",
        "/plenti/": "plenty",
        "/plɒt/": "plot",
        "/pɒkɪt/": "pocket",
        "/pəʊɪm/": "poem",
        "/pəʊɪt/": "poet",
        "/pəʊətri/": "poetry",
        "/pɔɪnt/": "point",
        "/pəʊl/": "poll",
        "/pəli:s/": "police",
        "/pɒləsi/": "policy",
        "/pəlɪtɪkl/": "political",
        "/pəlɪtɪkli/": "politically",
        "/pɒlətɪʃn/": "politician",
        "/pɒlətɪks/": "politics",
        "/pəlu:ʃn/": "pollution",
        "/pu:l/": "pool",
        "/pɔ:(r)/": "pour",
        "/pɒp/": "pop",
        "/pɒpjələ(r)/": "popular",
        "/pɒpjuleɪʃn/": "population",
        "/pɔ:tʃ/": "porch",
        "/pɔ:t/": "port",
        "/pɔ:ʃn/": "portion",
        "/pɔ:treɪt/": "portrait",
        "/pɔ:treɪ/": "portray",
        "/pəʊz/": "pose",
        "/pəzɪʃn/": "position",
        "/pɒzətɪv/": "positive",
        "/pəzes/": "possess",
        "/pɒsəbɪləti/": "possibility",
        "/pɒsəbl/": "possible",
        "/pɒsəbli/": "possibly",
        "/pəʊst/": "post",
        "/pɒt/": "pot",
        "/pəteɪtəʊ/": "potato",
        "/pətenʃl/": "potential",
        "/pətenʃəli/": "potentially",
        "/paʊnd/": "pound",
        "/pɒvəti/": "poverty",
        "/paʊdə(r)/": "powder",
        "/paʊə(r)/": "power",
        "/paʊəfl/": "powerful",
        "/præktɪkl/": "practical",
        "/præktɪs/": "practice",
        "/preɪ/": "pray",
        "/preə(r)/": "prayer",
        "/prɪsaɪsli/": "precisely",
        "/prɪdɪkt/": "predict",
        "/prɪfɜ:(r)/": "prefer",
        "/prefrəns/": "preference",
        "/pregnənsi/": "pregnancy",
        "/pregnənt/": "pregnant",
        "/prepəreɪʃn/": "preparation",
        "/prɪpeə(r)/": "prepare",
        "/prɪskrɪpʃn/": "prescription",
        "/prezns/": "presence",
        "/preznt/": "present",
        "/preznteɪʃn/": "presentation",
        "/prɪzɜ:v/": "preserve",
        "/prezɪdənt/": "president",
        "/prezɪdenʃl/": "presidential",
        "/pres/": "press",
        "/preʃə(r)/": "pressure",
        "/prɪtend/": "pretend",
        "/prɪti/": "pretty",
        "/prɪvent/": "prevent",
        "/pri:viəs/": "previous",
        "/pri:viəsli/": "previously",
        "/praɪs/": "price",
        "/praɪd/": "pride",
        "/pri:st/": "priest",
        "/praɪmerəli/": "primarily",
        "/praɪməri/": "primary",
        "/praɪm/": "prime",
        "/prɪnsəpl/": "principle",
        "/prɪnt/": "print",
        "/praɪə(r)/": "prior",
        "/praɪɒrəti/": "priority",
        "/prɪzn/": "prison",
        "/prɪznə(r)/": "prisoner",
        "/prɪvəsi/": "privacy",
        "/praɪvət/": "private",
        "/prɒbəbli/": "probably",
        "/prɒbləm/": "problem",
        "/prəsi:dʒə(r)/": "procedure",
        "/prəsi:d/": "proceed",
        "/prədju:s/": "produce",
        "/prədju:sə(r)/": "producer",
        "/prɒdʌkt/": "product",
        "/prədʌkʃn/": "production",
        "/prəfeʃn/": "profession",
        "/prəfeʃənl/": "professional",
        "/prəfesə(r)/": "professor",
        "/prəʊfaɪl/": "profile",
        "/prɒfɪt/": "profit",
        "/prəʊgræm/": "program",
        "/prəʊgres/": "progress",
        "/prɒdʒekt/": "project",
        "/prɒmɪnənt/": "prominent",
        "/prɒmɪs/": "promise",
        "/prəməʊt/": "promote",
        "/prɒmpt/": "prompt",
        "/pru:f/": "proof",
        "/prɒpə(r)/": "proper",
        "/prɒpəli/": "properly",
        "/prɒpəti/": "property",
        "/prəpɔ:ʃn/": "proportion",
        "/prəpəʊzl/": "proposal",
        "/prəpəʊz/": "propose",
        "/prɒsɪkju:tə(r)/": "prosecutor",
        "/prɒspekt/": "prospect",
        "/prətekt/": "protect",
        "/prətekʃn/": "protection",
        "/prəʊti:n/": "protein",
        "/prəʊtest/": "protest",
        "/praʊd/": "proud",
        "/pru:v/": "prove",
        "/prəvaɪd/": "provide",
        "/prəvaɪdə(r)/": "provider",
        "/prɒvɪns/": "province",
        "/prəvɪʒn/": "provision",
        "/saɪkəlɒdʒɪkl/": "psychological",
        "/saɪkɒlədʒɪst/": "psychologist",
        "/saɪkɒlədʒi/": "psychology",
        "/pʌblɪk/": "public",
        "/pʌblɪkeɪʃn/": "publication",
        "/pʌblɪkli/": "publicly",
        "/pʌblɪʃ/": "publish",
        "/pʌblɪʃə(r)/": "publisher",
        "/pʊl/": "pull",
        "/pʌnɪʃmənt/": "punishment",
        "/pɜ:tʃəs/": "purchase",
        "/pjʊə(r)/": "pure",
        "/pɜ:pəs/": "purpose",
        "/pəsju:/": "pursue",
        "/pʊʃ/": "push",
        "/pʊt/": "put",
        "/kwɒlɪfaɪ/": "qualify",
        "/kwɒləti/": "quality",
        "/kwɔ:tə(r)/": "quarter",
        "/kwɔ:təbæk/": "quarterback",
        "/kwestʃən/": "question",
        "/kwɪk/": "quick",
        "/kwɪkli/": "quickly",
        "/kwaɪət/": "quiet",
        "/kwaɪətli/": "quietly",
        "/kwɪt/": "quit",
        "/kwaɪt/": "quite",
        "/kwəʊt/": "quote",
        "/reɪs/": "race",
        "/reɪʃl/": "racial",
        "/rædɪkl/": "radical",
        "/reɪdiəʊ/": "radio",
        "/reɪl/": "rail",
        "/reɪn/": "rain",
        "/reɪz/": "raise",
        "/reɪndʒ/": "range",
        "/ræŋk/": "rank",
        "/ræpɪd/": "rapid",
        "/ræpɪdli/": "rapidly",
        "/reə(r)/": "rare",
        "/reəli/": "rarely",
        "/reɪt/": "rate",
        "/rɑ:ðə(r)/": "rather",
        "/reɪtɪŋ/": "rating",
        "/reɪʃiəʊ/": "ratio",
        "/rɔ:/": "raw",
        "/ri:tʃ/": "reach",
        "/riækt/": "react",
        "/riækʃn/": "reaction",
        "/ri:d/": "read",
        "/ri:də(r)/": "reader",
        "/ri:dɪŋ/": "reading",
        "/redi/": "ready",
        "/ri:əl/": "real",
        "/riæləti/": "reality",
        "/ri:əlaɪz/": "realize",
        "/ri:əli/": "really",
        "/ri:zn/": "reason",
        "/ri:znəbl/": "reasonable",
        "/rɪkɔ:l/": "recall",
        "/rɪsi:v/": "receive",
        "/ri:snt/": "recent",
        "/ri:sntli/": "recently",
        "/resəpi/": "recipe",
        "/rekəgnɪʃn/": "recognition",
        "/rekəgnaɪz/": "recognize",
        "/rekəmend/": "recommend",
        "/rekəmendeɪʃn/": "recommendation",
        "/rekɔ:d/": "record",
        "/rɪkɔ:dɪŋ/": "recording",
        "/rɪkʌvə(r)/": "recover",
        "/rɪkʌvəri/": "recovery",
        "/rɪkru:t/": "recruit",
        "/red/": "red",
        "/rɪdju:s/": "reduce",
        "/rɪdʌkʃn/": "reduction",
        "/rɪfɜ:(r)/": "refer",
        "/refrəns/": "reference",
        "/rɪflekt/": "reflect",
        "/rɪflekʃn/": "reflection",
        "/rɪfɔ:m/": "reform",
        "/refjudʒi:/": "refugee",
        "/rɪgɑ:d/": "regard",
        "/rɪgɑ:dɪŋ/": "regarding",
        "/rɪgɑ:dləs/": "regardless",
        "/reɪʒi:m/": "regime",
        "/ri:dʒən/": "region",
        "/ri:dʒənl/": "regional",
        "/redʒɪstə(r)/": "register",
        "/regjələ(r)/": "regular",
        "/regjələli/": "regularly",
        "/regjuleɪt/": "regulate",
        "/regjuleɪʃn/": "regulation",
        "/ri:ɪnfɔ:s/": "reinforce",
        "/rɪdʒekt/": "reject",
        "/rɪleɪt/": "relate",
        "/rɪleɪʃn/": "relation",
        "/rɪleɪʃnʃɪp/": "relationship",
        "/relətɪv/": "relative",
        "/relətɪvli/": "relatively",
        "/rɪlæks/": "relax",
        "/rɪli:s/": "release",
        "/reləvənt/": "relevant",
        "/rɪli:f/": "relief",
        "/rɪlɪdʒən/": "religion",
        "/rɪlɪdʒəs/": "religious",
        "/rɪlaɪ/": "rely",
        "/rɪmeɪn/": "remain",
        "/rɪmeɪnɪŋ/": "remaining",
        "/rɪmɑ:kəbl/": "remarkable",
        "/rɪmembə(r)/": "remember",
        "/rɪmaɪnd/": "remind",
        "/rɪməʊt/": "remote",
        "/rɪmu:v/": "remove",
        "/rɪpi:t/": "repeat",
        "/rɪpi:tɪdli/": "repeatedly",
        "/rɪpleɪs/": "replace",
        "/rɪplaɪ/": "reply",
        "/rɪpɔ:t/": "report",
        "/rɪpɔ:tə(r)/": "reporter",
        "/reprɪzent/": "represent",
        "/reprɪzenteɪʃn/": "representation",
        "/reprɪzentətɪv/": "representative",
        "/repjuteɪʃn/": "reputation",
        "/rɪkwest/": "request",
        "/rɪkwaɪə(r)/": "require",
        "/rɪkwaɪəmənt/": "requirement",
        "/rɪsɜ:tʃ/": "research",
        "/rɪsɜ:tʃə(r)/": "researcher",
        "/rɪzembl/": "resemble",
        "/rezəveɪʃn/": "reservation",
        "/rezɪdənt/": "resident",
        "/rɪzɪst/": "resist",
        "/rɪzɪstəns/": "resistance",
        "/rezəlu:ʃn/": "resolution",
        "/rɪzɒlv/": "resolve",
        "/rɪzɔ:t/": "resort",
        "/rɪsɔ:s/": "resource",
        "/rɪspekt/": "respect",
        "/rɪspɒnd/": "respond",
        "/rɪspɒndənt/": "respondent",
        "/rɪspɒns/": "response",
        "/rɪspɒnsəbɪləti/": "responsibility",
        "/rɪspɒnsəbl/": "responsible",
        "/rest/": "rest",
        "/restrɒnt/": "restaurant",
        "/rɪstɔ:(r)/": "restore",
        "/rɪstrɪkʃn/": "restriction",
        "/rɪzʌlt/": "result",
        "/rɪteɪn/": "retain",
        "/rɪtaɪə(r)/": "retire",
        "/rɪtaɪəmənt/": "retirement",
        "/rɪtɜ:n/": "return",
        "/rɪvi:l/": "reveal",
        "/revənju:/": "revenue",
        "/rɪvju:/": "review",
        "/revəlu:ʃn/": "revolution",
        "/rɪðəm/": "rhythm",
        "/raɪs/": "rice",
        "/rɪtʃ/": "rich",
        "/rɪd/": "rid",
        "/raɪd/": "ride",
        "/raɪfl/": "rifle",
        "/raɪt/": "write",
        "/raɪz/": "rise",
        "/rɪsk/": "risk",
        "/rɪvə(r)/": "river",
        "/rəʊd/": "road",
        "/rɒk/": "rock",
        "/rəʊl/": "roll",
        "/rəʊmæntɪk/": "romantic",
        "/ru:f/": "roof",
        "/ru:m/": "room",
        "/ru:t/": "route",
        "/rəʊp/": "rope",
        "/rəʊz/": "rose",
        "/rʌf/": "rough",
        "/rʌfli/": "roughly",
        "/raʊnd/": "round",
        "/ru:ti:n/": "routine",
        "/rʌb/": "rub",
        "/ru:l/": "rule",
        "/rʌn/": "run",
        "/rʌnɪŋ/": "running",
        "/rʊərəl/": "rural",
        "/rʌʃ/": "rush",
        "/seɪkrɪd/": "sacred",
        "/sæd/": "sad",
        "/seɪf/": "safe",
        "/seɪfti/": "safety",
        "/sæləd/": "salad",
        "/sæləri/": "salary",
        "/seɪl/": "sale",
        "/sɔ:lt/": "salt",
        "/seɪm/": "same",
        "/sɑ:mpl/": "sample",
        "/sæŋkʃn/": "sanction",
        "/sænd/": "sand",
        "/sætəlaɪt/": "satellite",
        "/sætɪsfækʃn/": "satisfaction",
        "/sætɪsfaɪ/": "satisfy",
        "/sɔ:s/": "source",
        "/seɪv/": "save",
        "/seɪvɪŋ/": "saving",
        "/seɪ/": "say",
        "/skeɪl/": "scale",
        "/skændl/": "scandal",
        "/skeəd/": "scared",
        "/sənɑ:riəʊ/": "scenario",
        "/si:n/": "scene",
        "/ʃedju:l/": "schedule",
        "/ski:m/": "scheme",
        "/skɒlə(r)/": "scholar",
        "/skɒləʃɪp/": "scholarship",
        "/sku:l/": "school",
        "/saɪəns/": "science",
        "/saɪəntɪfɪk/": "scientific",
        "/saɪəntɪst/": "scientist",
        "/skəʊp/": "scope",
        "/skɔ:(r)/": "score",
        "/skri:m/": "scream",
        "/skri:n/": "screen",
        "/skrɪpt/": "script",
        "/si:/": "see",
        "/sɜ:tʃ/": "search",
        "/si:zn/": "season",
        "/si:t/": "seat",
        "/si:krət/": "secret",
        "/sekrətri/": "secretary",
        "/sekʃn/": "section",
        "/sektə(r)/": "sector",
        "/sɪkjʊə(r)/": "secure",
        "/sɪkjʊərəti/": "security",
        "/si:d/": "seed",
        "/si:k/": "seek",
        "/si:m/": "seem",
        "/segmənt/": "segment",
        "/si:z/": "seize",
        "/sɪlekt/": "select",
        "/sɪlekʃn/": "selection",
        "/self/": "self",
        "/senətə(r)/": "senator",
        "/send/": "send",
        "/si:niə(r)/": "senior",
        "/sens/": "sense",
        "/sensətɪv/": "sensitive",
        "/sentəns/": "sentence",
        "/seprət/": "separate",
        "/si:kwəns/": "sequence",
        "/sɪəri:z/": "series",
        "/sɪəriəs/": "serious",
        "/sɪəriəsli/": "seriously",
        "/sɜ:v/": "serve",
        "/sɜ:vɪs/": "service",
        "/seʃn/": "session",
        "/set/": "set",
        "/setɪŋ/": "setting",
        "/setl/": "settle",
        "/setlmənt/": "settlement",
        "/sevn/": "seven",
        "/sevrəl/": "several",
        "/sɪvɪə(r)/": "severe",
        "/seks/": "sex",
        "/sekʃuəl/": "sexual",
        "/ʃeɪd/": "shade",
        "/ʃædəʊ/": "shadow",
        "/ʃeɪk/": "shake",
        "/ʃəl/": "shall",
        "/ʃeɪp/": "shape",
        "/ʃeə(r)/": "share",
        "/ʃɑ:p/": "sharp",
        "/ʃi/": "she",
        "/ʃi:t/": "sheet",
        "/ʃelf/": "shelf",
        "/ʃel/": "shell",
        "/ʃeltə(r)/": "shelter",
        "/ʃɪft/": "shift",
        "/ʃaɪn/": "shine",
        "/ʃɪp/": "ship",
        "/ʃɜ:t/": "shirt",
        "/ʃɪt/": "shit",
        "/ʃɒk/": "shock",
        "/ʃu:/": "shoe",
        "/ʃu:t/": "shoot",
        "/ʃu:tɪŋ/": "shooting",
        "/ʃɒp/": "shop",
        "/ʃɒpɪŋ/": "shopping",
        "/ʃɔ:(r)/": "shore",
        "/ʃɔ:t/": "short",
        "/ʃɔ:tli/": "shortly",
        "/ʃɒt/": "shot",
        "/ʃəd/": "should",
        "/ʃəʊldə(r)/": "shoulder",
        "/ʃaʊt/": "shout",
        "/ʃəʊ/": "show",
        "/ʃaʊə(r)/": "shower",
        "/ʃrʌg/": "shrug",
        "/ʃʌt/": "shut",
        "/sɪk/": "sick",
        "/saɪd/": "side",
        "/saɪ/": "sigh",
        "/saɪn/": "sign",
        "/sɪgnəl/": "signal",
        "/sɪgnɪfɪkəns/": "significance",
        "/sɪgnɪfɪkənt/": "significant",
        "/sɪgnɪfɪkəntli/": "significantly",
        "/saɪləns/": "silence",
        "/saɪlənt/": "silent",
        "/sɪlvə(r)/": "silver",
        "/sɪmələ(r)/": "similar",
        "/sɪmələli/": "similarly",
        "/sɪmpl/": "simple",
        "/sɪmpli/": "simply",
        "/sɪn/": "sin",
        "/sɪns/": "since",
        "/sɪŋ/": "sing",
        "/sɪŋə(r)/": "singer",
        "/sɪŋgl/": "single",
        "/sɪŋk/": "sink",
        "/sɜ:(r)/": "sir",
        "/sɪstə(r)/": "sister",
        "/sɪt/": "sit",
        "/sɪtʃueɪʃn/": "situation",
        "/sɪks/": "six",
        "/saɪz/": "size",
        "/ski:/": "ski",
        "/skɪl/": "skill",
        "/skɪn/": "skin",
        "/skaɪ/": "sky",
        "/sleɪv/": "slave",
        "/sli:p/": "sleep",
        "/slaɪs/": "slice",
        "/slaɪd/": "slide",
        "/slaɪt/": "slight",
        "/slaɪtli/": "slightly",
        "/slɪp/": "slip",
        "/sləʊ/": "slow",
        "/sləʊli/": "slowly",
        "/smɔ:l/": "small",
        "/smɑ:t/": "smart",
        "/smel/": "smell",
        "/smaɪl/": "smile",
        "/sməʊk/": "smoke",
        "/smu:ð/": "smooth",
        "/snæp/": "snap",
        "/snəʊ/": "snow",
        "/səʊ/": "so",
        "/səʊ": "so-called",
        "/sɒkə(r)/": "soccer",
        "/səʊʃl/": "social",
        "/səsaɪəti/": "society",
        "/sɒft/": "soft",
        "/sɒftweə(r)/": "software",
        "/sɔɪl/": "soil",
        "/səʊlə(r)/": "solar",
        "/səʊldʒə(r)/": "soldier",
        "/sɒlɪd/": "solid",
        "/səlu:ʃn/": "solution",
        "/sɒlv/": "solve",
        "/sʌm/": "some",
        "/sʌmbədi/": "somebody",
        "/sʌmhaʊ/": "somehow",
        "/sʌmwʌn/": "someone",
        "/sʌmθɪŋ/": "something",
        "/sʌmtaɪmz/": "sometimes",
        "/sʌmwɒt/": "somewhat",
        "/sʌmweə(r)/": "somewhere",
        "/sʌn/": "sun",
        "/sɒŋ/": "song",
        "/su:n/": "soon",
        "/səfɪstɪkeɪtɪd/": "sophisticated",
        "/sɒri/": "sorry",
        "/sɔ:t/": "sort",
        "/səʊl/": "soul",
        "/saʊnd/": "sound",
        "/su:p/": "soup",
        "/saʊθ/": "south",
        "/sʌðən/": "southern",
        "/speɪs/": "space",
        "/spi:k/": "speak",
        "/spi:kə(r)/": "speaker",
        "/speʃl/": "special",
        "/speʃəlɪst/": "specialist",
        "/spi:ʃi:z/": "species",
        "/spəsɪfɪk/": "specific",
        "/spəsɪfɪkli/": "specifically",
        "/spi:tʃ/": "speech",
        "/spi:d/": "speed",
        "/spend/": "spend",
        "/spendɪŋ/": "spending",
        "/spɪn/": "spin",
        "/spɪrɪt/": "spirit",
        "/spɪrɪtʃuəl/": "spiritual",
        "/splɪt/": "split",
        "/spəʊksmən/": "spokesman",
        "/spɔ:t/": "sport",
        "/spɒt/": "spot",
        "/spred/": "spread",
        "/sprɪŋ/": "spring",
        "/skweə(r)/": "square",
        "/skwi:z/": "squeeze",
        "/stəbɪləti/": "stability",
        "/steɪbl/": "stable",
        "/stɑ:f/": "staff",
        "/steɪdʒ/": "stage",
        "/steə(r)/": "stare",
        "/steɪk/": "stake",
        "/stænd/": "stand",
        "/stændəd/": "standard",
        "/stændɪŋ/": "standing",
        "/stɑ:(r)/": "star",
        "/stɑ:t/": "start",
        "/steɪt/": "state",
        "/steɪtmənt/": "statement",
        "/steɪʃn/": "station",
        "/steɪtəs/": "status",
        "/steɪ/": "stay",
        "/stedi/": "steady",
        "/sti:l/": "steel",
        "/step/": "step",
        "/stɪk/": "stick",
        "/stɪl/": "still",
        "/stɜ:(r)/": "stir",
        "/stɒk/": "stock",
        "/stʌmək/": "stomach",
        "/stəʊn/": "stone",
        "/stɒp/": "stop",
        "/stɔ:rɪdʒ/": "storage",
        "/stɔ:(r)/": "store",
        "/stɔ:m/": "storm",
        "/stɔ:ri/": "story",
        "/streɪt/": "straight",
        "/streɪndʒ/": "strange",
        "/streɪndʒə(r)/": "stranger",
        "/strəti:dʒɪk/": "strategic",
        "/strætədʒi/": "strategy",
        "/stri:m/": "stream",
        "/stri:t/": "street",
        "/streŋkθ/": "strength",
        "/streŋkθn/": "strengthen",
        "/stres/": "stress",
        "/stretʃ/": "stretch",
        "/straɪk/": "strike",
        "/strɪŋ/": "string",
        "/strɪp/": "strip",
        "/strəʊk/": "stroke",
        "/strɒŋ/": "strong",
        "/strɒŋli/": "strongly",
        "/strʌktʃə(r)/": "structure",
        "/strʌgl/": "struggle",
        "/stju:dnt/": "student",
        "/stju:diəʊ/": "studio",
        "/stʌdi/": "study",
        "/stʌf/": "stuff",
        "/stju:pɪd/": "stupid",
        "/staɪl/": "style",
        "/sʌbdʒɪkt/": "subject",
        "/səbmɪt/": "submit",
        "/sʌbsɪkwənt/": "subsequent",
        "/sʌbstəns/": "substance",
        "/səbstænʃl/": "substantial",
        "/səksi:d/": "succeed",
        "/səkses/": "success",
        "/səksesfl/": "successful",
        "/səksesfəli/": "successfully",
        "/sʌtʃ/": "such",
        "/sʌdn/": "sudden",
        "/sʌdənli/": "suddenly",
        "/su:/": "sue",
        "/sʌfə(r)/": "suffer",
        "/səfɪʃnt/": "sufficient",
        "/ʃʊgə(r)/": "sugar",
        "/sədʒest/": "suggest",
        "/sədʒestʃən/": "suggestion",
        "/su:ɪsaɪd/": "suicide",
        "/su:t/": "suit",
        "/sʌmə(r)/": "summer",
        "/sʌmɪt/": "summit",
        "/su:pə(r)/": "super",
        "/səplaɪ/": "supply",
        "/səpɔ:t/": "support",
        "/səpɔ:tə(r)/": "supporter",
        "/səpəʊz/": "suppose",
        "/səpəʊzd/": "supposed",
        "/ʃʊə(r)/": "sure",
        "/ʃʊəli/": "surely",
        "/sɜ:fɪs/": "surface",
        "/sɜ:dʒəri/": "surgery",
        "/səpraɪz/": "surprise",
        "/səpraɪzd/": "surprised",
        "/səpraɪzɪŋ/": "surprising",
        "/səpraɪzɪŋli/": "surprisingly",
        "/səraʊnd/": "surround",
        "/sɜ:veɪ/": "survey",
        "/səvaɪvl/": "survival",
        "/səvaɪv/": "survive",
        "/səvaɪvə(r)/": "survivor",
        "/səspekt/": "suspect",
        "/səsteɪn/": "sustain",
        "/sweə(r)/": "swear",
        "/swi:p/": "sweep",
        "/swi:t/": "sweet",
        "/swɪm/": "swim",
        "/swɪŋ/": "swing",
        "/swɪtʃ/": "switch",
        "/sɪmbl/": "symbol",
        "/sɪmptəm/": "symptom",
        "/sɪstəm/": "system",
        "/teɪbl/": "table",
        "/teɪblspu:n/": "tablespoon",
        "/tæktɪk/": "tactic",
        "/teɪl/": "tale",
        "/teɪk/": "take",
        "/tælənt/": "talent",
        "/tɔ:k/": "talk",
        "/tɔ:l/": "tall",
        "/tæŋk/": "tank",
        "/tæp/": "tap",
        "/teɪp/": "tape",
        "/tɑ:gɪt/": "target",
        "/tɑ:sk/": "task",
        "/teɪst/": "taste",
        "/tæks/": "tax",
        "/tækspeɪə(r)/": "taxpayer",
        "/ti:/": "tea",
        "/ti:tʃ/": "teach",
        "/ti:tʃə(r)/": "teacher",
        "/ti:tʃɪŋ/": "teaching",
        "/ti:m/": "team",
        "/ti:spu:n/": "teaspoon",
        "/teknɪkl/": "technical",
        "/tekni:k/": "technique",
        "/teknɒlədʒi/": "technology",
        "/ti:neɪdʒə(r)/": "teenager",
        "/telɪfəʊn/": "telephone",
        "/telɪskəʊp/": "telescope",
        "/telɪvɪʒn/": "television",
        "/tel/": "tell",
        "/temprətʃə(r)/": "temperature",
        "/temprəri/": "temporary",
        "/ten/": "ten",
        "/tend/": "tend",
        "/tendənsi/": "tendency",
        "/tenɪs/": "tennis",
        "/tenʃn/": "tension",
        "/tent/": "tent",
        "/tɜ:m/": "term",
        "/tɜ:mz/": "terms",
        "/terəbl/": "terrible",
        "/terətri/": "territory",
        "/terə(r)/": "terror",
        "/terərɪzəm/": "terrorism",
        "/terərɪst/": "terrorist",
        "/test/": "test",
        "/testɪfaɪ/": "testify",
        "/testɪməni/": "testimony",
        "/testɪŋ/": "testing",
        "/tekst/": "text",
        "/ðən/": "than",
        "/θæŋk/": "thank",
        "/θæŋks/": "thanks",
        "/ðæt/": "that",
        "/ðə/": "the",
        "/ðeə(r)/": "there",
        "/ðəm/": "them",
        "/θi:m/": "theme",
        "/ðəmselvz/": "themselves",
        "/ðen/": "then",
        "/θɪəri/": "theory",
        "/θerəpi/": "therapy",
        "/ðeəfɔ:(r)/": "therefore",
        "/ði:z/": "these",
        "/ðeɪ/": "they",
        "/θɪk/": "thick",
        "/θɪn/": "thin",
        "/θɪŋ/": "thing",
        "/θɪŋk/": "think",
        "/θɪŋkɪŋ/": "thinking",
        "/θɜ:d/": "third",
        "/θɜ:ti/": "thirty",
        "/ðɪs/": "this",
        "/ðəʊz/": "those",
        "/ðəʊ/": "though",
        "/θɔ:t/": "thought",
        "/θaʊznd/": "thousand",
        "/θret/": "threat",
        "/θretn/": "threaten",
        "/θri:/": "three",
        "/θrəʊt/": "throat",
        "/θru:/": "through",
        "/θru:aʊt/": "throughout",
        "/θrəʊ/": "throw",
        "/ðʌs/": "thus",
        "/tɪkɪt/": "ticket",
        "/taɪ/": "tie",
        "/taɪt/": "tight",
        "/taɪm/": "time",
        "/taɪni/": "tiny",
        "/tɪp/": "tip",
        "/taɪə(r)/": "tire",
        "/taɪəd/": "tired",
        "/tɪʃu:/": "tissue",
        "/taɪtl/": "title",
        "/tə/": "to",
        "/təbækəʊ/": "tobacco",
        "/tədeɪ/": "today",
        "/təʊ/": "toe",
        "/təgeðə(r)/": "together",
        "/təmɑ:təʊ/": "tomato",
        "/təmɒrəʊ/": "tomorrow",
        "/təʊn/": "tone",
        "/tʌŋ/": "tongue",
        "/tənaɪt/": "tonight",
        "/tu:/": "two",
        "/tu:l/": "tool",
        "/tu:θ/": "tooth",
        "/tɒp/": "top",
        "/tɒpɪk/": "topic",
        "/tɒs/": "toss",
        "/təʊtl/": "total",
        "/təʊtəli/": "totally",
        "/tʌtʃ/": "touch",
        "/tʌf/": "tough",
        "/tʊə(r)/": "tour",
        "/tʊərɪst/": "tourist",
        "/tʊənəmənt/": "tournament",
        "/təwɔ:dz/": "towards",
        "/taʊə(r)/": "tower",
        "/taʊn/": "town",
        "/tɔɪ/": "toy",
        "/treɪs/": "trace",
        "/træk/": "track",
        "/treɪd/": "trade",
        "/trədɪʃn/": "tradition",
        "/trədɪʃənl/": "traditional",
        "/træfɪk/": "traffic",
        "/trædʒədi/": "tragedy",
        "/treɪl/": "trail",
        "/treɪn/": "train",
        "/treɪnɪŋ/": "training",
        "/trænsfɜ:(r)/": "transfer",
        "/trænsfɔ:m/": "transform",
        "/trænsfəmeɪʃn/": "transformation",
        "/trænzɪʃn/": "transition",
        "/trænzleɪt/": "translate",
        "/trænspɔ:teɪʃn/": "transportation",
        "/trævl/": "travel",
        "/tri:t/": "treat",
        "/tri:tmənt/": "treatment",
        "/tri:ti/": "treaty",
        "/tri:/": "tree",
        "/trəmendəs/": "tremendous",
        "/trend/": "trend",
        "/traɪəl/": "trial",
        "/traɪb/": "tribe",
        "/trɪk/": "trick",
        "/trɪp/": "trip",
        "/tru:p/": "troop",
        "/trʌbl/": "trouble",
        "/trʌk/": "truck",
        "/tru:/": "true",
        "/tru:li/": "truly",
        "/trʌst/": "trust",
        "/tru:θ/": "truth",
        "/traɪ/": "try",
        "/tju:b/": "tube",
        "/tʌnl/": "tunnel",
        "/tɜ:n/": "turn",
        "/twelv/": "twelve",
        "/twenti/": "twenty",
        "/twaɪs/": "twice",
        "/twɪn/": "twin",
        "/taɪp/": "type",
        "/tɪpɪkl/": "typical",
        "/tɪpɪkli/": "typically",
        "/ʌgli/": "ugly",
        "/ʌltɪmət/": "ultimate",
        "/ʌltɪmətli/": "ultimately",
        "/ʌneɪbl/": "unable",
        "/ʌŋkl/": "uncle",
        "/ʌndə(r)/": "under",
        "/ʌndəgəʊ/": "undergo",
        "/ʌndəstænd/": "understand",
        "/ʌndəstændɪŋ/": "understanding",
        "/ʌnfɔ:tʃənətli/": "unfortunately",
        "/ju:nɪfɔ:m/": "uniform",
        "/ju:niən/": "union",
        "/juni:k/": "unique",
        "/ju:nɪt/": "unit",
        "/ju:nɪvɜ:sl/": "universal",
        "/ju:nɪvɜ:s/": "universe",
        "/ju:nɪvɜ:səti/": "university",
        "/ʌnnəʊn/": "unknown",
        "/ənles/": "unless",
        "/ʌnlaɪk/": "unlike",
        "/ʌnlaɪkli/": "unlikely",
        "/əntɪl/": "until",
        "/ʌnju:ʒuəl/": "unusual",
        "/ʌp/": "up",
        "/əpɒn/": "upon",
        "/ʌpə(r)/": "upper",
        "/ɜ:bən/": "urban",
        "/ɜ:dʒ/": "urge",
        "/əs/": "us",
        "/ju:z/": "use",
        "/ju:sfl/": "useful",
        "/ju:zə(r)/": "user",
        "/ju:ʒuəl/": "usual",
        "/ju:ʒuəli/": "usually",
        "/ju:tɪləti/": "utility",
        "/veɪkeɪʃn/": "vacation",
        "/væli/": "valley",
        "/væljuəbl/": "valuable",
        "/vælju:/": "value",
        "/veəriəbl/": "variable",
        "/veərieɪʃn/": "variation",
        "/vəraɪəti/": "variety",
        "/veəriəs/": "various",
        "/veəri/": "vary",
        "/vɑ:st/": "vast",
        "/vedʒtəbl/": "vegetable",
        "/vi:əkl/": "vehicle",
        "/ventʃə(r)/": "venture",
        "/vɜ:ʃn/": "version",
        "/vɜ:səs/": "versus",
        "/veri/": "very",
        "/vesl/": "vessel",
        "/vetərən/": "veteran",
        "/vaɪə/": "via",
        "/vɪktɪm/": "victim",
        "/vɪktəri/": "victory",
        "/vɪdiəʊ/": "video",
        "/vju:/": "view",
        "/vju:ə(r)/": "viewer",
        "/vɪlɪdʒ/": "village",
        "/vaɪəleɪt/": "violate",
        "/vaɪəleɪʃn/": "violation",
        "/vaɪələns/": "violence",
        "/vaɪələnt/": "violent",
        "/vɜ:tʃuəli/": "virtually",
        "/vɜ:tʃu:/": "virtue",
        "/vaɪrəs/": "virus",
        "/vɪzəbl/": "visible",
        "/vɪʒn/": "vision",
        "/vɪzɪt/": "visit",
        "/vɪzɪtə(r)/": "visitor",
        "/vɪʒuəl/": "visual",
        "/vaɪtl/": "vital",
        "/vɔɪs/": "voice",
        "/vɒlju:m/": "volume",
        "/vɒləntɪə(r)/": "volunteer",
        "/vəʊt/": "vote",
        "/vəʊtə(r)/": "voter",
        "/vʌlnərəbl/": "vulnerable",
        "/weɪdʒ/": "wage",
        "/weɪt/": "weight",
        "/weɪk/": "wake",
        "/wɔ:k/": "walk",
        "/wɔ:l/": "wall",
        "/wɒndə(r)/": "wander",
        "/wɒnt/": "want",
        "/wɔ:(r)/": "war",
        "/wɔ:m/": "warm",
        "/wɔ:n/": "warn",
        "/wɔ:nɪŋ/": "warning",
        "/wɒʃ/": "wash",
        "/weɪst/": "waste",
        "/wɒtʃ/": "watch",
        "/wɔ:tə(r)/": "water",
        "/weɪv/": "wave",
        "/weɪ/": "weigh",
        "/wi/": "we",
        "/wi:k/": "week",
        "/welθ/": "wealth",
        "/welθi/": "wealthy",
        "/wepən/": "weapon",
        "/weə(r)/": "where",
        "/weðə(r)/": "whether",
        "/wedɪŋ/": "wedding",
        "/wi:kend/": "weekend",
        "/wi:kli/": "weekly",
        "/welkəm/": "welcome",
        "/welfeə(r)/": "welfare",
        "/wel/": "well",
        "/west/": "west",
        "/westən/": "western",
        "/wet/": "wet",
        "/wɒt/": "what",
        "/wɒtevə(r)/": "whatever",
        "/wi:l/": "wheel",
        "/wen/": "when",
        "/wenevə(r)/": "whenever",
        "/weəræz/": "whereas",
        "/wɪtʃ/": "which",
        "/waɪl/": "while",
        "/wɪspə(r)/": "whisper",
        "/waɪt/": "white",
        "/hu:/": "who",
        "/hu:m/": "whom",
        "/hu:z/": "whose",
        "/waɪ/": "why",
        "/waɪd/": "wide",
        "/waɪdli/": "widely",
        "/waɪdspred/": "widespread",
        "/waɪf/": "wife",
        "/waɪld/": "wild",
        "/wɪl/": "will",
        "/wɪlɪŋ/": "willing",
        "/wɪn/": "win",
        "/wɪndəʊ/": "window",
        "/waɪn/": "wine",
        "/wɪŋ/": "wing",
        "/wɪnə(r)/": "winner",
        "/wɪntə(r)/": "winter",
        "/waɪp/": "wipe",
        "/waɪə(r)/": "wire",
        "/wɪzdəm/": "wisdom",
        "/waɪz/": "wise",
        "/wɪʃ/": "wish",
        "/wɪð/": "with",
        "/wɪðdrɔ:/": "withdraw",
        "/wɪðɪn/": "within",
        "/wɪðaʊt/": "without",
        "/wɪtnəs/": "witness",
        "/wʊmən/": "woman",
        "/wʌndə(r)/": "wonder",
        "/wʌndəfl/": "wonderful",
        "/wʊd/": "would",
        "/wʊdn/": "wooden",
        "/wɜ:d/": "word",
        "/wɜ:k/": "work",
        "/wɜ:kə(r)/": "worker",
        "/wɜ:kɪŋ/": "working",
        "/wɜ:ks/": "works",
        "/wɜ:kʃɒp/": "workshop",
        "/wɜ:ld/": "world",
        "/wʌrid/": "worried",
        "/wʌri/": "worry",
        "/wɜ:θ/": "worth",
        "/ræp/": "wrap",
        "/raɪtə(r)/": "writer",
        "/raɪtɪŋ/": "writing",
        "/rɒŋ/": "wrong",
        "/jɑ:d/": "yard",
        "/jeə/": "yeah",
        "/jɪə(r)/": "year",
        "/jel/": "yell",
        "/jeləʊ/": "yellow",
        "/jes/": "yes",
        "/jestədeɪ/": "yesterday",
        "/jet/": "yet",
        "/ji:ld/": "yield",
        "/ju/": "you",
        "/jʌŋ/": "young",
        "/jɔ:(r)/": "your",
        "/jɔ:z/": "yours",
        "/jɔ:self/": "yourself",
        "/ju:θ/": "youth",
        "/zəʊn/": "zone"
    },
    word_2_phoneme: {
        "abandon": "/əbændən/",
        "ability": "/əbɪləti/",
        "able": "/eɪbl/",
        "abortion": "/əbɔ:ʃn/",
        "about": "/əbaʊt/",
        "above": "/əbʌv/",
        "abroad": "/əbrɔ:d/",
        "absence": "/æbsəns/",
        "absolute": "/æbsəlu:t/",
        "absolutely": "/æbsəlu:tli/",
        "absorb": "/əbzɔ:b/",
        "abuse": "/əbju:s/",
        "academic": "/ækədemɪk/",
        "accept": "/əksept/",
        "access": "/ækses/",
        "accident": "/æksɪdənt/",
        "accompany": "/əkʌmpəni/",
        "accomplish": "/əkʌmplɪʃ/",
        "account": "/əkaʊnt/",
        "accurate": "/ækjərət/",
        "accuse": "/əkju:z/",
        "achieve": "/ətʃi:v/",
        "achievement": "/ətʃi:vmənt/",
        "acid": "/æsɪd/",
        "acknowledge": "/əknɒlɪdʒ/",
        "acquire": "/əkwaɪə(r)/",
        "across": "/əkrɒs/",
        "act": "/ækt/",
        "action": "/ækʃn/",
        "active": "/æktɪv/",
        "activist": "/æktɪvɪst/",
        "activity": "/æktɪvəti/",
        "actor": "/æktə(r)/",
        "actress": "/æktrəs/",
        "actual": "/æktʃuəl/",
        "actually": "/æktʃuəli/",
        "add": "/æd/",
        "adapt": "/ədæpt/",
        "addition": "/ədɪʃn/",
        "additional": "/ədɪʃənl/",
        "address": "/ədres/",
        "adequate": "/ædɪkwət/",
        "adjust": "/ədʒʌst/",
        "adjustment": "/ədʒʌstmənt/",
        "administration": "/ədmɪnɪstreɪʃn/",
        "administrator": "/ədmɪnɪstreɪtə(r)/",
        "admire": "/ədmaɪə(r)/",
        "admission": "/ədmɪʃn/",
        "admit": "/ədmɪt/",
        "adolescent": "/ædəlesnt/",
        "adopt": "/ədɒpt/",
        "adult": "/ædʌlt/",
        "advance": "/ədvɑ:ns/",
        "advanced": "/ədvɑ:nst/",
        "advantage": "/ədvɑ:ntɪdʒ/",
        "adventure": "/ədventʃə(r)/",
        "advertising": "/ædvətaɪzɪŋ/",
        "advice": "/ədvaɪs/",
        "advise": "/ədvaɪz/",
        "adviser": "/ədvaɪzə(r)/",
        "advocate": "/ædvəkət/",
        "affair": "/əfeə(r)/",
        "affect": "/əfekt/",
        "afford": "/əfɔ:d/",
        "afraid": "/əfreɪd/",
        "after": "/ɑ:ftə(r)/",
        "afternoon": "/ɑ:ftənu:n/",
        "again": "/əgen/",
        "against": "/əgenst/",
        "age": "/eɪdʒ/",
        "agency": "/eɪdʒənsi/",
        "agenda": "/ədʒendə/",
        "agent": "/eɪdʒənt/",
        "aggressive": "/əgresɪv/",
        "ago": "/əgəʊ/",
        "agree": "/əgri:/",
        "agreement": "/əgri:mənt/",
        "agricultural": "/ægrɪkʌltʃərəl/",
        "ah": "/ɑ:/",
        "ahead": "/əhed/",
        "aide": "/eɪd/",
        "aim": "/eɪm/",
        "air": "/eə(r)/",
        "aircraft": "/eəkrɑ:ft/",
        "airline": "/eəlaɪn/",
        "airport": "/eəpɔ:t/",
        "album": "/ælbəm/",
        "alcohol": "/ælkəhɒl/",
        "alive": "/əlaɪv/",
        "all": "/ɔ:l/",
        "alliance": "/əlaɪəns/",
        "allow": "/əlaʊ/",
        "ally": "/ælaɪ/",
        "almost": "/ɔ:lməʊst/",
        "alone": "/ələʊn/",
        "along": "/əlɒŋ/",
        "already": "/ɔ:lredi/",
        "also": "/ɔ:lsəʊ/",
        "alter": "/ɔ:ltə(r)/",
        "alternative": "/ɔ:ltɜ:nətɪv/",
        "although": "/ɔ:lðəʊ/",
        "always": "/ɔ:lweɪz/",
        "amazing": "/əmeɪzɪŋ/",
        "among": "/əmʌŋ/",
        "amount": "/əmaʊnt/",
        "analysis": "/ənæləsɪs/",
        "analyst": "/ænəlɪst/",
        "ancient": "/eɪnʃənt/",
        "and": "/ənd/",
        "anger": "/æŋgə(r)/",
        "angle": "/æŋgl/",
        "angry": "/æŋgri/",
        "animal": "/ænɪml/",
        "anniversary": "/ænɪvɜ:səri/",
        "announce": "/ənaʊns/",
        "annual": "/ænjuəl/",
        "another": "/ənʌðə(r)/",
        "answer": "/ɑ:nsə(r)/",
        "anticipate": "/æntɪsɪpeɪt/",
        "anxiety": "/æŋzaɪəti/",
        "any": "/eni/",
        "anybody": "/enibɒdi/",
        "anyone": "/eniwʌn/",
        "anything": "/eniθɪŋ/",
        "anyway": "/eniweɪ/",
        "anywhere": "/eniweə(r)/",
        "apart": "/əpɑ:t/",
        "apartment": "/əpɑ:tmənt/",
        "apparent": "/əpærənt/",
        "apparently": "/əpærəntli/",
        "appeal": "/əpi:l/",
        "appear": "/əpɪə(r)/",
        "appearance": "/əpɪərəns/",
        "apple": "/æpl/",
        "application": "/æplɪkeɪʃn/",
        "apply": "/əplaɪ/",
        "appoint": "/əpɔɪnt/",
        "appointment": "/əpɔɪntmənt/",
        "appreciate": "/əpri:ʃieɪt/",
        "approach": "/əprəʊtʃ/",
        "appropriate": "/əprəʊpriət/",
        "approval": "/əpru:vl/",
        "approve": "/əpru:v/",
        "approximately": "/əprɒksɪmətli/",
        "architect": "/ɑ:kɪtekt/",
        "area": "/eəriə/",
        "argue": "/ɑ:gju:/",
        "argument": "/ɑ:gjumənt/",
        "arise": "/əraɪz/",
        "arm": "/ɑ:m/",
        "armed": "/ɑ:md/",
        "army": "/ɑ:mi/",
        "around": "/əraʊnd/",
        "arrange": "/əreɪndʒ/",
        "arrangement": "/əreɪndʒmənt/",
        "arrest": "/ərest/",
        "arrival": "/əraɪvl/",
        "arrive": "/əraɪv/",
        "art": "/ɑ:t/",
        "article": "/ɑ:tɪkl/",
        "artist": "/ɑ:tɪst/",
        "artistic": "/ɑ:tɪstɪk/",
        "as": "/əz/",
        "aside": "/əsaɪd/",
        "ask": "/ɑ:sk/",
        "asleep": "/əsli:p/",
        "aspect": "/æspekt/",
        "assault": "/əsɔ:lt/",
        "assert": "/əsɜ:t/",
        "assess": "/əses/",
        "assessment": "/əsesmənt/",
        "asset": "/æset/",
        "assign": "/əsaɪn/",
        "assignment": "/əsaɪnmənt/",
        "assist": "/əsɪst/",
        "assistance": "/əsɪstəns/",
        "assistant": "/əsɪstənt/",
        "associate": "/əsəʊsieɪt/",
        "association": "/əsəʊsieɪʃn/",
        "assume": "/əsju:m/",
        "assumption": "/əsʌmpʃn/",
        "assure": "/əʃʊə(r)/",
        "at": "/ət/",
        "athlete": "/æθli:t/",
        "athletic": "/æθletɪk/",
        "atmosphere": "/ætməsfɪə(r)/",
        "attach": "/ətætʃ/",
        "attack": "/ətæk/",
        "attempt": "/ətempt/",
        "attend": "/ətend/",
        "attention": "/ətenʃn/",
        "attitude": "/ætɪtju:d/",
        "attorney": "/ətɜ:ni/",
        "attract": "/ətrækt/",
        "attractive": "/ətræktɪv/",
        "attribute": "/ətrɪbju:t/",
        "audience": "/ɔ:diəns/",
        "author": "/ɔ:θə(r)/",
        "authority": "/ɔ:θɒrəti/",
        "auto": "/ɔ:təʊ/",
        "available": "/əveɪləbl/",
        "average": "/ævərɪdʒ/",
        "avoid": "/əvɔɪd/",
        "award": "/əwɔ:d/",
        "aware": "/əweə(r)/",
        "awareness": "/əweənəs/",
        "away": "/əweɪ/",
        "awful": "/ɔ:fl/",
        "baby": "/beɪbi/",
        "back": "/bæk/",
        "background": "/bækgraʊnd/",
        "bad": "/bæd/",
        "badly": "/bædli/",
        "bag": "/bæg/",
        "bake": "/beɪk/",
        "balance": "/bæləns/",
        "ball": "/bɔ:l/",
        "ban": "/bæn/",
        "band": "/bænd/",
        "bank": "/bæŋk/",
        "bar": "/bɑ:(r)/",
        "barely": "/beəli/",
        "barrel": "/bærəl/",
        "barrier": "/bæriə(r)/",
        "base": "/beɪs/",
        "baseball": "/beɪsbɔ:l/",
        "basic": "/beɪsɪk/",
        "basically": "/beɪsɪkli/",
        "basis": "/beɪsɪs/",
        "basket": "/bɑ:skɪt/",
        "basketball": "/bɑ:skɪtbɔ:l/",
        "bathroom": "/bɑ:θru:m/",
        "battery": "/bætri/",
        "battle": "/bætl/",
        "be": "/bi/",
        "beach": "/bi:tʃ/",
        "bean": "/bi:n/",
        "bear": "/beə(r)/",
        "beat": "/bi:t/",
        "beautiful": "/bju:tɪfl/",
        "beauty": "/bju:ti/",
        "because": "/bɪkəz/",
        "become": "/bɪkʌm/",
        "bed": "/bed/",
        "bedroom": "/bedru:m/",
        "beer": "/bɪə(r)/",
        "before": "/bɪfɔ:(r)/",
        "begin": "/bɪgɪn/",
        "beginning": "/bɪgɪnɪŋ/",
        "behind": "/bɪhaɪnd/",
        "being": "/bi:ɪŋ/",
        "belief": "/bɪli:f/",
        "believe": "/bɪli:v/",
        "bell": "/bel/",
        "belong": "/bɪlɒŋ/",
        "below": "/bɪləʊ/",
        "belt": "/belt/",
        "bench": "/bentʃ/",
        "bend": "/bend/",
        "beneath": "/bɪni:θ/",
        "benefit": "/benɪfɪt/",
        "beside": "/bɪsaɪd/",
        "besides": "/bɪsaɪdz/",
        "best": "/best/",
        "bet": "/bet/",
        "better": "/betə(r)/",
        "between": "/bɪtwi:n/",
        "beyond": "/bɪjɒnd/",
        "big": "/bɪg/",
        "bike": "/baɪk/",
        "bill": "/bɪl/",
        "billion": "/bɪljən/",
        "bind": "/baɪnd/",
        "biological": "/baɪəlɒdʒɪkl/",
        "bird": "/bɜ:d/",
        "birth": "/bɜ:θ/",
        "birthday": "/bɜ:θdeɪ/",
        "bit": "/bɪt/",
        "bite": "/baɪt/",
        "black": "/blæk/",
        "blade": "/bleɪd/",
        "blame": "/bleɪm/",
        "blanket": "/blæŋkɪt/",
        "blind": "/blaɪnd/",
        "block": "/blɒk/",
        "blood": "/blʌd/",
        "blow": "/bləʊ/",
        "blue": "/blu:/",
        "board": "/bɔ:d/",
        "boat": "/bəʊt/",
        "body": "/bɒdi/",
        "bomb": "/bɒm/",
        "bombing": "/bɒmɪŋ/",
        "bond": "/bɒnd/",
        "bone": "/bəʊn/",
        "book": "/bʊk/",
        "boom": "/bu:m/",
        "boot": "/bu:t/",
        "border": "/bɔ:də(r)/",
        "born": "/bɔ:n/",
        "borrow": "/bɒrəʊ/",
        "boss": "/bɒs/",
        "both": "/bəʊθ/",
        "bother": "/bɒðə(r)/",
        "bottle": "/bɒtl/",
        "bottom": "/bɒtəm/",
        "boundary": "/baʊndri/",
        "bowl": "/bəʊl/",
        "box": "/bɒks/",
        "boy": "/bɔɪ/",
        "boyfriend": "/bɔɪfrend/",
        "brain": "/breɪn/",
        "branch": "/brɑ:ntʃ/",
        "brand": "/brænd/",
        "bread": "/bred/",
        "break": "/breɪk/",
        "breakfast": "/brekfəst/",
        "breast": "/brest/",
        "breath": "/breθ/",
        "breathe": "/bri:ð/",
        "brick": "/brɪk/",
        "bridge": "/brɪdʒ/",
        "brief": "/bri:f/",
        "briefly": "/bri:fli/",
        "bright": "/braɪt/",
        "brilliant": "/brɪliənt/",
        "bring": "/brɪŋ/",
        "broad": "/brɔ:d/",
        "broken": "/brəʊkən/",
        "brother": "/brʌðə(r)/",
        "brown": "/braʊn/",
        "brush": "/brʌʃ/",
        "buck": "/bʌk/",
        "budget": "/bʌdʒɪt/",
        "build": "/bɪld/",
        "building": "/bɪldɪŋ/",
        "bullet": "/bʊlɪt/",
        "bunch": "/bʌntʃ/",
        "burden": "/bɜ:dn/",
        "burn": "/bɜ:n/",
        "bury": "/beri/",
        "bus": "/bʌs/",
        "business": "/bɪznəs/",
        "busy": "/bɪzi/",
        "but": "/bət/",
        "butter": "/bʌtə(r)/",
        "button": "/bʌtn/",
        "by": "/baɪ/",
        "buyer": "/baɪə(r)/",
        "cabin": "/kæbɪn/",
        "cabinet": "/kæbɪnət/",
        "cable": "/keɪbl/",
        "cake": "/keɪk/",
        "calculate": "/kælkjuleɪt/",
        "call": "/kɔ:l/",
        "camera": "/kæmrə/",
        "camp": "/kæmp/",
        "campaign": "/kæmpeɪn/",
        "campus": "/kæmpəs/",
        "cancer": "/kænsə(r)/",
        "candidate": "/kændɪdət/",
        "cap": "/kæp/",
        "capability": "/keɪpəbɪləti/",
        "capable": "/keɪpəbl/",
        "capacity": "/kəpæsəti/",
        "capital": "/kæpɪtl/",
        "captain": "/kæptɪn/",
        "capture": "/kæptʃə(r)/",
        "car": "/kɑ:(r)/",
        "carbon": "/kɑ:bən/",
        "card": "/kɑ:d/",
        "care": "/keə(r)/",
        "career": "/kərɪə(r)/",
        "careful": "/keəfl/",
        "carefully": "/keəfəli/",
        "carrier": "/kæriə(r)/",
        "carry": "/kæri/",
        "case": "/keɪs/",
        "cash": "/kæʃ/",
        "cast": "/kɑ:st/",
        "cat": "/kæt/",
        "catch": "/kætʃ/",
        "category": "/kætəgəri/",
        "cause": "/kɔ:z/",
        "ceiling": "/si:lɪŋ/",
        "celebrate": "/selɪbreɪt/",
        "celebration": "/selɪbreɪʃn/",
        "celebrity": "/səlebrəti/",
        "sell": "/sel/",
        "center": "/sentə(r)/",
        "central": "/sentrəl/",
        "century": "/sentʃəri/",
        "ceremony": "/serəməni/",
        "certain": "/sɜ:tn/",
        "certainly": "/sɜ:tnli/",
        "chain": "/tʃeɪn/",
        "chair": "/tʃeə(r)/",
        "chairman": "/tʃeəmən/",
        "challenge": "/tʃælɪndʒ/",
        "chamber": "/tʃeɪmbə(r)/",
        "champion": "/tʃæmpiən/",
        "championship": "/tʃæmpiənʃɪp/",
        "chance": "/tʃɑ:ns/",
        "change": "/tʃeɪndʒ/",
        "channel": "/tʃænl/",
        "chapter": "/tʃæptə(r)/",
        "character": "/kærəktə(r)/",
        "characteristic": "/kærəktərɪstɪk/",
        "characterize": "/kærəktəraɪz/",
        "charge": "/tʃɑ:dʒ/",
        "charity": "/tʃærəti/",
        "chart": "/tʃɑ:t/",
        "chase": "/tʃeɪs/",
        "cheap": "/tʃi:p/",
        "check": "/tʃek/",
        "cheek": "/tʃi:k/",
        "cheese": "/tʃi:z/",
        "chef": "/ʃef/",
        "chemical": "/kemɪkl/",
        "chest": "/tʃest/",
        "chicken": "/tʃɪkɪn/",
        "chief": "/tʃi:f/",
        "child": "/tʃaɪld/",
        "childhood": "/tʃaɪldhʊd/",
        "chip": "/tʃɪp/",
        "chocolate": "/tʃɒklət/",
        "choice": "/tʃɔɪs/",
        "cholesterol": "/kəlestərɒl/",
        "choose": "/tʃu:z/",
        "church": "/tʃɜ:tʃ/",
        "cigarette": "/sɪgəret/",
        "circle": "/sɜ:kl/",
        "circumstance": "/sɜ:kəmstəns/",
        "site": "/saɪt/",
        "citizen": "/sɪtɪzn/",
        "city": "/sɪti/",
        "civil": "/sɪvl/",
        "civilian": "/səvɪliən/",
        "claim": "/kleɪm/",
        "class": "/klɑ:s/",
        "classic": "/klæsɪk/",
        "classroom": "/klɑ:sru:m/",
        "clean": "/kli:n/",
        "clear": "/klɪə(r)/",
        "clearly": "/klɪəli/",
        "client": "/klaɪənt/",
        "climate": "/klaɪmət/",
        "climb": "/klaɪm/",
        "clinic": "/klɪnɪk/",
        "clinical": "/klɪnɪkl/",
        "clock": "/klɒk/",
        "closely": "/kləʊsli/",
        "clothes": "/kləʊðz/",
        "clothing": "/kləʊðɪŋ/",
        "cloud": "/klaʊd/",
        "club": "/klʌb/",
        "clue": "/klu:/",
        "cluster": "/klʌstə(r)/",
        "coach": "/kəʊtʃ/",
        "coal": "/kəʊl/",
        "coalition": "/kəʊəlɪʃn/",
        "coast": "/kəʊst/",
        "coat": "/kəʊt/",
        "code": "/kəʊd/",
        "coffee": "/kɒfi/",
        "cognitive": "/kɒgnətɪv/",
        "cold": "/kəʊld/",
        "collapse": "/kəlæps/",
        "colleague": "/kɒli:g/",
        "collect": "/kəlekt/",
        "collection": "/kəlekʃn/",
        "collective": "/kəlektɪv/",
        "college": "/kɒlɪdʒ/",
        "colonial": "/kələʊniəl/",
        "color": "/kʌlə(r)/",
        "column": "/kɒləm/",
        "combination": "/kɒmbɪneɪʃn/",
        "combine": "/kəmbaɪn/",
        "come": "/kʌm/",
        "comedy": "/kɒmədi/",
        "comfort": "/kʌmfət/",
        "comfortable": "/kʌmftəbl/",
        "command": "/kəmɑ:nd/",
        "commander": "/kəmɑ:ndə(r)/",
        "comment": "/kɒment/",
        "commercial": "/kəmɜ:ʃl/",
        "commission": "/kəmɪʃn/",
        "commit": "/kəmɪt/",
        "commitment": "/kəmɪtmənt/",
        "committee": "/kəmɪti/",
        "common": "/kɒmən/",
        "communicate": "/kəmju:nɪkeɪt/",
        "communication": "/kəmju:nɪkeɪʃn/",
        "community": "/kəmju:nəti/",
        "company": "/kʌmpəni/",
        "compare": "/kəmpeə(r)/",
        "comparison": "/kəmpærɪsn/",
        "compete": "/kəmpi:t/",
        "competition": "/kɒmpətɪʃn/",
        "competitive": "/kəmpetətɪv/",
        "competitor": "/kəmpetɪtə(r)/",
        "complain": "/kəmpleɪn/",
        "complaint": "/kəmpleɪnt/",
        "complete": "/kəmpli:t/",
        "completely": "/kəmpli:tli/",
        "complex": "/kɒmpleks/",
        "complicated": "/kɒmplɪkeɪtɪd/",
        "component": "/kəmpəʊnənt/",
        "compose": "/kəmpəʊz/",
        "composition": "/kɒmpəzɪʃn/",
        "comprehensive": "/kɒmprɪhensɪv/",
        "computer": "/kəmpju:tə(r)/",
        "concentrate": "/kɒnsntreɪt/",
        "concentration": "/kɒnsntreɪʃn/",
        "concept": "/kɒnsept/",
        "concern": "/kənsɜ:n/",
        "concerned": "/kənsɜ:nd/",
        "concert": "/kɒnsət/",
        "conclude": "/kənklu:d/",
        "conclusion": "/kənklu:ʒn/",
        "concrete": "/kɒŋkri:t/",
        "condition": "/kəndɪʃn/",
        "conduct": "/kəndʌkt/",
        "conference": "/kɒnfərəns/",
        "confidence": "/kɒnfɪdəns/",
        "confident": "/kɒnfɪdənt/",
        "confirm": "/kənfɜ:m/",
        "conflict": "/kɒnflɪkt/",
        "confront": "/kənfrʌnt/",
        "confusion": "/kənfju:ʒn/",
        "congressional": "/kəngreʃənl/",
        "connect": "/kənekt/",
        "connection": "/kənekʃn/",
        "consciousness": "/kɒnʃəsnəs/",
        "consensus": "/kənsensəs/",
        "consequence": "/kɒnsɪkwəns/",
        "conservative": "/kənsɜ:vətɪv/",
        "consider": "/kənsɪdə(r)/",
        "considerable": "/kənsɪdərəbl/",
        "consideration": "/kənsɪdəreɪʃn/",
        "consist": "/kənsɪst/",
        "consistent": "/kənsɪstənt/",
        "constant": "/kɒnstənt/",
        "constantly": "/kɒnstəntli/",
        "constitute": "/kɒnstɪtju:t/",
        "constitutional": "/kɒnstɪtju:ʃənl/",
        "construct": "/kənstrʌkt/",
        "construction": "/kənstrʌkʃn/",
        "consultant": "/kənsʌltənt/",
        "consume": "/kənsju:m/",
        "consumer": "/kənsju:mə(r)/",
        "consumption": "/kənsʌmpʃn/",
        "contact": "/kɒntækt/",
        "contain": "/kənteɪn/",
        "container": "/kənteɪnə(r)/",
        "contemporary": "/kəntemprəri/",
        "contest": "/kɒntest/",
        "context": "/kɒntekst/",
        "continue": "/kəntɪnju:/",
        "continued": "/kəntɪnju:d/",
        "contract": "/kɒntrækt/",
        "contrast": "/kɒntrɑ:st/",
        "contribute": "/kəntrɪbju:t/",
        "contribution": "/kɒntrɪbju:ʃn/",
        "control": "/kəntrəʊl/",
        "controversial": "/kɒntrəvɜ:ʃl/",
        "controversy": "/kɒntrəvɜ:si/",
        "convention": "/kənvenʃn/",
        "conventional": "/kənvenʃənl/",
        "conversation": "/kɒnvəseɪʃn/",
        "convert": "/kənvɜ:t/",
        "conviction": "/kənvɪkʃn/",
        "convince": "/kənvɪns/",
        "cook": "/kʊk/",
        "cookie": "/kʊki/",
        "cooking": "/kʊkɪŋ/",
        "cool": "/ku:l/",
        "cooperation": "/kəʊɒpəreɪʃn/",
        "cop": "/kɒp/",
        "cope": "/kəʊp/",
        "copy": "/kɒpi/",
        "core": "/kɔ:(r)/",
        "corn": "/kɔ:n/",
        "corner": "/kɔ:nə(r)/",
        "corporate": "/kɔ:pərət/",
        "corporation": "/kɔ:pəreɪʃn/",
        "correct": "/kərekt/",
        "correspondent": "/kɒrəspɒndənt/",
        "cost": "/kɒst/",
        "cotton": "/kɒtn/",
        "couch": "/kaʊtʃ/",
        "could": "/kəd/",
        "council": "/kaʊnsl/",
        "count": "/kaʊnt/",
        "counter": "/kaʊntə(r)/",
        "country": "/kʌntri/",
        "county": "/kaʊnti/",
        "couple": "/kʌpl/",
        "courage": "/kʌrɪdʒ/",
        "course": "/kɔ:s/",
        "court": "/kɔ:t/",
        "cousin": "/kʌzn/",
        "cover": "/kʌvə(r)/",
        "coverage": "/kʌvərɪdʒ/",
        "cow": "/kaʊ/",
        "crack": "/kræk/",
        "craft": "/krɑ:ft/",
        "crash": "/kræʃ/",
        "crazy": "/kreɪzi/",
        "cream": "/kri:m/",
        "create": "/krieɪt/",
        "creation": "/krieɪʃn/",
        "creative": "/krieɪtɪv/",
        "creature": "/kri:tʃə(r)/",
        "credit": "/kredɪt/",
        "crew": "/kru:/",
        "crime": "/kraɪm/",
        "criminal": "/krɪmɪnl/",
        "crisis": "/kraɪsɪs/",
        "critic": "/krɪtɪk/",
        "critical": "/krɪtɪkl/",
        "criticism": "/krɪtɪsɪzəm/",
        "criticize": "/krɪtɪsaɪz/",
        "crop": "/krɒp/",
        "cross": "/krɒs/",
        "crowd": "/kraʊd/",
        "crucial": "/kru:ʃl/",
        "cry": "/kraɪ/",
        "cultural": "/kʌltʃərəl/",
        "culture": "/kʌltʃə(r)/",
        "cup": "/kʌp/",
        "curious": "/kjʊəriəs/",
        "current": "/kʌrənt/",
        "currently": "/kʌrəntli/",
        "curriculum": "/kərɪkjələm/",
        "custom": "/kʌstəm/",
        "customer": "/kʌstəmə(r)/",
        "cut": "/kʌt/",
        "cycle": "/saɪkl/",
        "dad": "/dæd/",
        "daily": "/deɪli/",
        "damage": "/dæmɪdʒ/",
        "dance": "/dɑ:ns/",
        "danger": "/deɪndʒə(r)/",
        "dangerous": "/deɪndʒərəs/",
        "dare": "/deə(r)/",
        "dark": "/dɑ:k/",
        "darkness": "/dɑ:knəs/",
        "data": "/deɪtə/",
        "date": "/deɪt/",
        "daughter": "/dɔ:tə(r)/",
        "day": "/deɪ/",
        "dead": "/ded/",
        "deal": "/di:l/",
        "dealer": "/di:lə(r)/",
        "deer": "/dɪə(r)/",
        "death": "/deθ/",
        "debate": "/dɪbeɪt/",
        "debt": "/det/",
        "decade": "/dekeɪd/",
        "decide": "/dɪsaɪd/",
        "decision": "/dɪsɪʒn/",
        "deck": "/dek/",
        "declare": "/dɪkleə(r)/",
        "decline": "/dɪklaɪn/",
        "decrease": "/dɪkri:s/",
        "deep": "/di:p/",
        "deeply": "/di:pli/",
        "defeat": "/dɪfi:t/",
        "defend": "/dɪfend/",
        "defendant": "/dɪfendənt/",
        "defense": "/dɪfens/",
        "defensive": "/dɪfensɪv/",
        "deficit": "/defɪsɪt/",
        "define": "/dɪfaɪn/",
        "definitely": "/defɪnətli/",
        "definition": "/defɪnɪʃn/",
        "degree": "/dɪgri:/",
        "delay": "/dɪleɪ/",
        "deliver": "/dɪlɪvə(r)/",
        "delivery": "/dɪlɪvəri/",
        "demand": "/dɪmɑ:nd/",
        "democracy": "/dɪmɒkrəsi/",
        "democratic": "/deməkrætɪk/",
        "demonstrate": "/demənstreɪt/",
        "demonstration": "/demənstreɪʃn/",
        "deny": "/dɪnaɪ/",
        "department": "/dɪpɑ:tmənt/",
        "depend": "/dɪpend/",
        "dependent": "/dɪpendənt/",
        "depict": "/dɪpɪkt/",
        "depression": "/dɪpreʃn/",
        "depth": "/depθ/",
        "deputy": "/depjuti/",
        "derive": "/dɪraɪv/",
        "describe": "/dɪskraɪb/",
        "description": "/dɪskrɪpʃn/",
        "desert": "/dezət/",
        "deserve": "/dɪzɜ:v/",
        "design": "/dɪzaɪn/",
        "designer": "/dɪzaɪnə(r)/",
        "desire": "/dɪzaɪə(r)/",
        "desk": "/desk/",
        "desperate": "/despərət/",
        "despite": "/dɪspaɪt/",
        "destroy": "/dɪstrɔɪ/",
        "destruction": "/dɪstrʌkʃn/",
        "detail": "/di:teɪl/",
        "detailed": "/di:teɪld/",
        "detect": "/dɪtekt/",
        "determine": "/dɪtɜ:mɪn/",
        "develop": "/dɪveləp/",
        "developing": "/dɪveləpɪŋ/",
        "development": "/dɪveləpmənt/",
        "device": "/dɪvaɪs/",
        "devote": "/dɪvəʊt/",
        "dialogue": "/daɪəlɒg/",
        "die": "/daɪ/",
        "diet": "/daɪət/",
        "differ": "/dɪfə(r)/",
        "difference": "/dɪfrəns/",
        "different": "/dɪfrənt/",
        "differently": "/dɪfrəntli/",
        "difficult": "/dɪfɪkəlt/",
        "difficulty": "/dɪfɪkəlti/",
        "dig": "/dɪg/",
        "digital": "/dɪdʒɪtl/",
        "dimension": "/daɪmenʃn/",
        "dining": "/daɪnɪŋ/",
        "dinner": "/dɪnə(r)/",
        "direct": "/dərekt/",
        "direction": "/dərekʃn/",
        "directly": "/dərektli/",
        "director": "/dərektə(r)/",
        "dirt": "/dɜ:t/",
        "dirty": "/dɜ:ti/",
        "disability": "/dɪsəbɪləti/",
        "disagree": "/dɪsəgri:/",
        "disappear": "/dɪsəpɪə(r)/",
        "disaster": "/dɪzɑ:stə(r)/",
        "discipline": "/dɪsəplɪn/",
        "discourse": "/dɪskɔ:s/",
        "discover": "/dɪskʌvə(r)/",
        "discovery": "/dɪskʌvəri/",
        "discrimination": "/dɪskrɪmɪneɪʃn/",
        "discuss": "/dɪskʌs/",
        "discussion": "/dɪskʌʃn/",
        "disease": "/dɪzi:z/",
        "dish": "/dɪʃ/",
        "dismiss": "/dɪsmɪs/",
        "disorder": "/dɪsɔ:də(r)/",
        "display": "/dɪspleɪ/",
        "dispute": "/dɪspju:t/",
        "distance": "/dɪstəns/",
        "distant": "/dɪstənt/",
        "distinct": "/dɪstɪŋkt/",
        "distinction": "/dɪstɪŋkʃn/",
        "distinguish": "/dɪstɪŋgwɪʃ/",
        "distribute": "/dɪstrɪbju:t/",
        "distribution": "/dɪstrɪbju:ʃn/",
        "district": "/dɪstrɪkt/",
        "diverse": "/daɪvɜ:s/",
        "diversity": "/daɪvɜ:səti/",
        "divide": "/dɪvaɪd/",
        "division": "/dɪvɪʒn/",
        "divorce": "/dɪvɔ:s/",
        "doctor": "/dɒktə(r)/",
        "document": "/dɒkjumənt/",
        "dog": "/dɒg/",
        "domestic": "/dəmestɪk/",
        "dominant": "/dɒmɪnənt/",
        "dominate": "/dɒmɪneɪt/",
        "door": "/dɔ:(r)/",
        "double": "/dʌbl/",
        "doubt": "/daʊt/",
        "down": "/daʊn/",
        "downtown": "/daʊntaʊn/",
        "dozen": "/dʌzn/",
        "draft": "/drɑ:ft/",
        "drag": "/dræg/",
        "drama": "/drɑ:mə/",
        "dramatic": "/drəmætɪk/",
        "dramatically": "/drəmætɪkli/",
        "draw": "/drɔ:/",
        "drawing": "/drɔ:ɪŋ/",
        "dream": "/dri:m/",
        "dress": "/dres/",
        "drink": "/drɪŋk/",
        "drive": "/draɪv/",
        "driver": "/draɪvə(r)/",
        "drop": "/drɒp/",
        "drug": "/drʌg/",
        "dry": "/draɪ/",
        "due": "/dju:/",
        "during": "/djʊərɪŋ/",
        "dust": "/dʌst/",
        "duty": "/dju:ti/",
        "each": "/i:tʃ/",
        "eager": "/i:gə(r)/",
        "ear": "/ɪə(r)/",
        "early": "/ɜ:li/",
        "earn": "/ɜ:n/",
        "earnings": "/ɜ:nɪŋz/",
        "earth": "/ɜ:θ/",
        "ease": "/i:z/",
        "easily": "/i:zəli/",
        "east": "/i:st/",
        "eastern": "/i:stən/",
        "easy": "/i:zi/",
        "eat": "/i:t/",
        "economic": "/i:kənɒmɪk/",
        "economics": "/i:kənɒmɪks/",
        "economist": "/ɪkɒnəmɪst/",
        "economy": "/ɪkɒnəmi/",
        "edge": "/edʒ/",
        "edition": "/ɪdɪʃn/",
        "editor": "/edɪtə(r)/",
        "educate": "/edʒukeɪt/",
        "education": "/edʒukeɪʃn/",
        "educational": "/edʒukeɪʃənl/",
        "educator": "/edʒukeɪtə(r)/",
        "effect": "/ɪfekt/",
        "effective": "/ɪfektɪv/",
        "effectively": "/ɪfektɪvli/",
        "efficiency": "/ɪfɪʃnsi/",
        "efficient": "/ɪfɪʃnt/",
        "effort": "/efət/",
        "egg": "/eg/",
        "eight": "/eɪt/",
        "either": "/aɪðə(r)/",
        "elderly": "/eldəli/",
        "elect": "/ɪlekt/",
        "election": "/ɪlekʃn/",
        "electric": "/ɪlektrɪk/",
        "electricity": "/ɪlektrɪsəti/",
        "electronic": "/ɪlektrɒnɪk/",
        "element": "/elɪmənt/",
        "elementary": "/elɪmentri/",
        "eliminate": "/ɪlɪmɪneɪt/",
        "elite": "/eɪli:t/",
        "else": "/els/",
        "elsewhere": "/elsweə(r)/",
        "embrace": "/ɪmbreɪs/",
        "emerge": "/ɪmɜ:dʒ/",
        "emergency": "/ɪmɜ:dʒənsi/",
        "emission": "/ɪmɪʃn/",
        "emotion": "/ɪməʊʃn/",
        "emotional": "/ɪməʊʃənl/",
        "emphasis": "/emfəsɪs/",
        "emphasize": "/emfəsaɪz/",
        "employ": "/ɪmplɔɪ/",
        "employee": "/ɪmplɔɪi:/",
        "employer": "/ɪmplɔɪə(r)/",
        "employment": "/ɪmplɔɪmənt/",
        "empty": "/empti/",
        "enable": "/ɪneɪbl/",
        "encounter": "/ɪnkaʊntə(r)/",
        "encourage": "/ɪnkʌrɪdʒ/",
        "end": "/end/",
        "enemy": "/enəmi/",
        "energy": "/enədʒi/",
        "enforcement": "/ɪnfɔ:smənt/",
        "engage": "/ɪngeɪdʒ/",
        "engine": "/endʒɪn/",
        "engineer": "/endʒɪnɪə(r)/",
        "engineering": "/endʒɪnɪərɪŋ/",
        "enhance": "/ɪnhɑ:ns/",
        "enjoy": "/ɪndʒɔɪ/",
        "enormous": "/ɪnɔ:məs/",
        "enough": "/ɪnʌf/",
        "ensure": "/ɪnʃʊə(r)/",
        "enter": "/entə(r)/",
        "enterprise": "/entəpraɪz/",
        "entertainment": "/entəteɪnmənt/",
        "entire": "/ɪntaɪə(r)/",
        "entirely": "/ɪntaɪəli/",
        "entry": "/entri/",
        "environment": "/ɪnvaɪrənmənt/",
        "environmental": "/ɪnvaɪrənmentl/",
        "episode": "/epɪsəʊd/",
        "equal": "/i:kwəl/",
        "equally": "/i:kwəli/",
        "equipment": "/ɪkwɪpmənt/",
        "era": "/ɪərə/",
        "error": "/erə(r)/",
        "escape": "/ɪskeɪp/",
        "especially": "/ɪspeʃəli/",
        "essay": "/eseɪ/",
        "essential": "/ɪsenʃl/",
        "essentially": "/ɪsenʃəli/",
        "establish": "/ɪstæblɪʃ/",
        "establishment": "/ɪstæblɪʃmənt/",
        "estate": "/ɪsteɪt/",
        "estimate": "/estɪmeɪt/",
        "etc": "/et",
        "ethnic": "/eθnɪk/",
        "evaluate": "/ɪvæljueɪt/",
        "evaluation": "/ɪvæljueɪʃn/",
        "even": "/i:vn/",
        "evening": "/i:vnɪŋ/",
        "event": "/ɪvent/",
        "eventually": "/ɪventʃuəli/",
        "ever": "/evə(r)/",
        "every": "/evri/",
        "everybody": "/evribɒdi/",
        "everyday": "/evrideɪ/",
        "everyone": "/evriwʌn/",
        "everything": "/evriθɪŋ/",
        "everywhere": "/evriweə(r)/",
        "evidence": "/evɪdəns/",
        "evolution": "/i:vəlu:ʃn/",
        "evolve": "/ɪvɒlv/",
        "exact": "/ɪgzækt/",
        "exactly": "/ɪgzæktli/",
        "examination": "/ɪgzæmɪneɪʃn/",
        "examine": "/ɪgzæmɪn/",
        "example": "/ɪgzɑ:mpl/",
        "exceed": "/ɪksi:d/",
        "excellent": "/eksələnt/",
        "except": "/ɪksept/",
        "exception": "/ɪksepʃn/",
        "exchange": "/ɪkstʃeɪndʒ/",
        "exciting": "/ɪksaɪtɪŋ/",
        "executive": "/ɪgzekjətɪv/",
        "exercise": "/eksəsaɪz/",
        "exhibit": "/ɪgzɪbɪt/",
        "exhibition": "/eksɪbɪʃn/",
        "exist": "/ɪgzɪst/",
        "existence": "/ɪgzɪstəns/",
        "existing": "/ɪgzɪstɪŋ/",
        "expand": "/ɪkspænd/",
        "expansion": "/ɪkspænʃn/",
        "expect": "/ɪkspekt/",
        "expectation": "/ekspekteɪʃn/",
        "expense": "/ɪkspens/",
        "expensive": "/ɪkspensɪv/",
        "experience": "/ɪkspɪəriəns/",
        "experiment": "/ɪksperɪmənt/",
        "expert": "/ekspɜ:t/",
        "explain": "/ɪkspleɪn/",
        "explanation": "/ekspləneɪʃn/",
        "explode": "/ɪkspləʊd/",
        "explore": "/ɪksplɔ:(r)/",
        "explosion": "/ɪkspləʊʒn/",
        "expose": "/ɪkspəʊz/",
        "exposure": "/ɪkspəʊʒə(r)/",
        "express": "/ɪkspres/",
        "expression": "/ɪkspreʃn/",
        "extend": "/ɪkstend/",
        "extension": "/ɪkstenʃn/",
        "extensive": "/ɪkstensɪv/",
        "extent": "/ɪkstent/",
        "external": "/ɪkstɜ:nl/",
        "extra": "/ekstrə/",
        "extraordinary": "/ɪkstrɔ:dnri/",
        "extreme": "/ɪkstri:m/",
        "extremely": "/ɪkstri:mli/",
        "eye": "/aɪ/",
        "fabric": "/fæbrɪk/",
        "face": "/feɪs/",
        "facility": "/fəsɪləti/",
        "fact": "/fækt/",
        "factor": "/fæktə(r)/",
        "factory": "/fæktri/",
        "faculty": "/fæklti/",
        "fade": "/feɪd/",
        "fail": "/feɪl/",
        "failure": "/feɪljə(r)/",
        "fair": "/feə(r)/",
        "fairly": "/feəli/",
        "faith": "/feɪθ/",
        "fall": "/fɔ:l/",
        "false": "/fɔ:ls/",
        "familiar": "/fəmɪliə(r)/",
        "family": "/fæməli/",
        "famous": "/feɪməs/",
        "fan": "/fæn/",
        "fantasy": "/fæntəsi/",
        "far": "/fɑ:(r)/",
        "farm": "/fɑ:m/",
        "farmer": "/fɑ:mə(r)/",
        "fashion": "/fæʃn/",
        "fast": "/fɑ:st/",
        "fat": "/fæt/",
        "fate": "/feɪt/",
        "father": "/fɑ:ðə(r)/",
        "fault": "/fɔ:lt/",
        "fear": "/fɪə(r)/",
        "feature": "/fi:tʃə(r)/",
        "federal": "/fedərəl/",
        "fee": "/fi:/",
        "feed": "/fi:d/",
        "feel": "/fi:l/",
        "feeling": "/fi:lɪŋ/",
        "fellow": "/feləʊ/",
        "female": "/fi:meɪl/",
        "fence": "/fens/",
        "few": "/fju:/",
        "fiction": "/fɪkʃn/",
        "field": "/fi:ld/",
        "fifteen": "/fɪfti:n/",
        "fifth": "/fɪfθ/",
        "fifty": "/fɪfti/",
        "fight": "/faɪt/",
        "fighter": "/faɪtə(r)/",
        "fighting": "/faɪtɪŋ/",
        "figure": "/fɪgə(r)/",
        "file": "/faɪl/",
        "fill": "/fɪl/",
        "film": "/fɪlm/",
        "final": "/faɪnl/",
        "finally": "/faɪnəli/",
        "finance": "/faɪnæns/",
        "financial": "/faɪnænʃl/",
        "find": "/faɪnd/",
        "finding": "/faɪndɪŋ/",
        "fine": "/faɪn/",
        "finger": "/fɪŋgə(r)/",
        "finish": "/fɪnɪʃ/",
        "fire": "/faɪə(r)/",
        "firm": "/fɜ:m/",
        "first": "/fɜ:st/",
        "fish": "/fɪʃ/",
        "fishing": "/fɪʃɪŋ/",
        "fit": "/fɪt/",
        "fitness": "/fɪtnəs/",
        "five": "/faɪv/",
        "fix": "/fɪks/",
        "flag": "/flæg/",
        "flame": "/fleɪm/",
        "flat": "/flæt/",
        "flee": "/fli:/",
        "flesh": "/fleʃ/",
        "flight": "/flaɪt/",
        "float": "/fləʊt/",
        "floor": "/flɔ:(r)/",
        "flow": "/fləʊ/",
        "flower": "/flaʊə(r)/",
        "fly": "/flaɪ/",
        "focus": "/fəʊkəs/",
        "folk": "/fəʊk/",
        "follow": "/fɒləʊ/",
        "following": "/fɒləʊɪŋ/",
        "food": "/fu:d/",
        "foot": "/fʊt/",
        "football": "/fʊtbɔ:l/",
        "for": "/fə(r)/",
        "force": "/fɔ:s/",
        "foreign": "/fɒrən/",
        "forest": "/fɒrɪst/",
        "forever": "/fərevə(r)/",
        "forget": "/fəget/",
        "form": "/fɔ:m/",
        "formal": "/fɔ:ml/",
        "formation": "/fɔ:meɪʃn/",
        "former": "/fɔ:mə(r)/",
        "formula": "/fɔ:mjələ/",
        "fourth": "/fɔ:θ/",
        "fortune": "/fɔ:tʃu:n/",
        "forward": "/fɔ:wəd/",
        "found": "/faʊnd/",
        "foundation": "/faʊndeɪʃn/",
        "founder": "/faʊndə(r)/",
        "four": "/fɔ:(r)/",
        "frame": "/freɪm/",
        "framework": "/freɪmwɜ:k/",
        "free": "/fri:/",
        "freedom": "/fri:dəm/",
        "freeze": "/fri:z/",
        "frequency": "/fri:kwənsi/",
        "frequent": "/fri:kwənt/",
        "frequently": "/fri:kwəntli/",
        "fresh": "/freʃ/",
        "friend": "/frend/",
        "friendly": "/frendli/",
        "friendship": "/frendʃɪp/",
        "from": "/frəm/",
        "front": "/frʌnt/",
        "fruit": "/fru:t/",
        "frustration": "/frʌstreɪʃn/",
        "fuel": "/fju:əl/",
        "full": "/fʊl/",
        "fully": "/fʊli/",
        "fun": "/fʌn/",
        "function": "/fʌŋkʃn/",
        "fund": "/fʌnd/",
        "fundamental": "/fʌndəmentl/",
        "funding": "/fʌndɪŋ/",
        "funeral": "/fju:nərəl/",
        "funny": "/fʌni/",
        "furniture": "/fɜ:nɪtʃə(r)/",
        "furthermore": "/fɜ:ðəmɔ:(r)/",
        "future": "/fju:tʃə(r)/",
        "gain": "/geɪn/",
        "galaxy": "/gæləksi/",
        "gallery": "/gæləri/",
        "game": "/geɪm/",
        "gang": "/gæŋ/",
        "gap": "/gæp/",
        "garage": "/gærɑ:ʒ/",
        "garden": "/gɑ:dn/",
        "garlic": "/gɑ:lɪk/",
        "gas": "/gæs/",
        "gate": "/geɪt/",
        "gather": "/gæðə(r)/",
        "gay": "/geɪ/",
        "gaze": "/geɪz/",
        "gear": "/gɪə(r)/",
        "gender": "/dʒendə(r)/",
        "gene": "/dʒi:n/",
        "general": "/dʒenrəl/",
        "generally": "/dʒenrəli/",
        "generate": "/dʒenəreɪt/",
        "generation": "/dʒenəreɪʃn/",
        "genetic": "/dʒənetɪk/",
        "gentleman": "/dʒentlmən/",
        "gently": "/dʒentli/",
        "gesture": "/dʒestʃə(r)/",
        "get": "/get/",
        "ghost": "/gəʊst/",
        "giant": "/dʒaɪənt/",
        "gift": "/gɪft/",
        "gifted": "/gɪftɪd/",
        "girl": "/gɜ:l/",
        "girlfriend": "/gɜ:lfrend/",
        "give": "/gɪv/",
        "given": "/gɪvn/",
        "glad": "/glæd/",
        "glance": "/glɑ:ns/",
        "glass": "/glɑ:s/",
        "global": "/gləʊbl/",
        "glove": "/glʌv/",
        "go": "/gəʊ/",
        "goal": "/gəʊl/",
        "gold": "/gəʊld/",
        "golden": "/gəʊldən/",
        "golf": "/gɒlf/",
        "good": "/gʊd/",
        "government": "/gʌvənmənt/",
        "governor": "/gʌvənə(r)/",
        "grab": "/græb/",
        "grade": "/greɪd/",
        "gradually": "/grædʒuəli/",
        "graduate": "/grædʒuət/",
        "grain": "/greɪn/",
        "grand": "/grænd/",
        "grandfather": "/grænfɑ:ðə(r)/",
        "grandmother": "/grænmʌðə(r)/",
        "grant": "/grɑ:nt/",
        "grass": "/grɑ:s/",
        "gray": "/greɪ/",
        "great": "/greɪt/",
        "green": "/gri:n/",
        "grocery": "/grəʊsəri/",
        "ground": "/graʊnd/",
        "group": "/gru:p/",
        "grow": "/grəʊ/",
        "growing": "/grəʊɪŋ/",
        "growth": "/grəʊθ/",
        "guarantee": "/gærənti:/",
        "guard": "/gɑ:d/",
        "guess": "/ges/",
        "guest": "/gest/",
        "guide": "/gaɪd/",
        "guideline": "/gaɪdlaɪn/",
        "guilty": "/gɪlti/",
        "gun": "/gʌn/",
        "guy": "/gaɪ/",
        "habit": "/hæbɪt/",
        "habitat": "/hæbɪtæt/",
        "hair": "/heə(r)/",
        "half": "/hɑ:f/",
        "hall": "/hɔ:l/",
        "hand": "/hænd/",
        "handful": "/hændfʊl/",
        "handle": "/hændl/",
        "hang": "/hæŋ/",
        "happen": "/hæpən/",
        "happy": "/hæpi/",
        "hard": "/hɑ:d/",
        "hardly": "/hɑ:dli/",
        "hat": "/hæt/",
        "hate": "/heɪt/",
        "have": "/həv/",
        "he": "/hi/",
        "head": "/hed/",
        "headline": "/hedlaɪn/",
        "headquarters": "/hedkwɔ:təz/",
        "health": "/helθ/",
        "healthy": "/helθi/",
        "here": "/hɪə(r)/",
        "hearing": "/hɪərɪŋ/",
        "heart": "/hɑ:t/",
        "heat": "/hi:t/",
        "heaven": "/hevn/",
        "heavily": "/hevɪli/",
        "heavy": "/hevi/",
        "heel": "/hi:l/",
        "height": "/haɪt/",
        "helicopter": "/helɪkɒptə(r)/",
        "hell": "/hel/",
        "hello": "/hələʊ/",
        "help": "/help/",
        "helpful": "/helpfl/",
        "her": "/hə(r)/",
        "heritage": "/herɪtɪdʒ/",
        "hero": "/hɪərəʊ/",
        "herself": "/hɜ:self/",
        "hey": "/heɪ/",
        "high": "/haɪ/",
        "hide": "/haɪd/",
        "highlight": "/haɪlaɪt/",
        "highly": "/haɪli/",
        "highway": "/haɪweɪ/",
        "hill": "/hɪl/",
        "him": "/hɪm/",
        "himself": "/hɪmself/",
        "hip": "/hɪp/",
        "hire": "/haɪə(r)/",
        "his": "/hɪz/",
        "historian": "/hɪstɔ:riən/",
        "historic": "/hɪstɒrɪk/",
        "historical": "/hɪstɒrɪkl/",
        "history": "/hɪstri/",
        "hit": "/hɪt/",
        "hold": "/həʊld/",
        "whole": "/həʊl/",
        "holiday": "/hɒlədeɪ/",
        "holy": "/həʊli/",
        "home": "/həʊm/",
        "homeless": "/həʊmləs/",
        "honest": "/ɒnɪst/",
        "honey": "/hʌni/",
        "honor": "/ɒnə(r)/",
        "hope": "/həʊp/",
        "horizon": "/həraɪzn/",
        "horror": "/hɒrə(r)/",
        "horse": "/hɔ:s/",
        "hospital": "/hɒspɪtl/",
        "host": "/həʊst/",
        "hot": "/hɒt/",
        "hotel": "/həʊtel/",
        "hour": "/aʊə(r)/",
        "house": "/haʊs/",
        "household": "/haʊshəʊld/",
        "housing": "/haʊzɪŋ/",
        "how": "/haʊ/",
        "however": "/haʊevə(r)/",
        "huge": "/hju:dʒ/",
        "human": "/hju:mən/",
        "humor": "/hju:mə(r)/",
        "hundred": "/hʌndrəd/",
        "hungry": "/hʌŋgri/",
        "hunter": "/hʌntə(r)/",
        "hunting": "/hʌntɪŋ/",
        "hurt": "/hɜ:t/",
        "husband": "/hʌzbənd/",
        "hypothesis": "/haɪpɒθəsɪs/",
        "ice": "/aɪs/",
        "idea": "/aɪdɪə/",
        "ideal": "/aɪdi:əl/",
        "identification": "/aɪdentɪfɪkeɪʃn/",
        "identify": "/aɪdentɪfaɪ/",
        "identity": "/aɪdentəti/",
        "if": "/ɪf/",
        "ignore": "/ɪgnɔ:(r)/",
        "ill": "/ɪl/",
        "illegal": "/ɪli:gl/",
        "illness": "/ɪlnəs/",
        "illustrate": "/ɪləstreɪt/",
        "image": "/ɪmɪdʒ/",
        "imagination": "/ɪmædʒɪneɪʃn/",
        "imagine": "/ɪmædʒɪn/",
        "immediate": "/ɪmi:diət/",
        "immediately": "/ɪmi:diətli/",
        "immigrant": "/ɪmɪgrənt/",
        "immigration": "/ɪmɪgreɪʃn/",
        "impact": "/ɪmpækt/",
        "implement": "/ɪmplɪment/",
        "implication": "/ɪmplɪkeɪʃn/",
        "imply": "/ɪmplaɪ/",
        "importance": "/ɪmpɔ:tns/",
        "important": "/ɪmpɔ:tnt/",
        "impose": "/ɪmpəʊz/",
        "impossible": "/ɪmpɒsəbl/",
        "impress": "/ɪmpres/",
        "impression": "/ɪmpreʃn/",
        "impressive": "/ɪmpresɪv/",
        "improve": "/ɪmpru:v/",
        "improvement": "/ɪmpru:vmənt/",
        "in": "/ɪn/",
        "incentive": "/ɪnsentɪv/",
        "incident": "/ɪnsɪdənt/",
        "include": "/ɪnklu:d/",
        "including": "/ɪnklu:dɪŋ/",
        "income": "/ɪnkʌm/",
        "incorporate": "/ɪnkɔ:pəreɪt/",
        "increase": "/ɪnkri:s/",
        "increased": "/ɪnkri:st/",
        "increasingly": "/ɪnkri:sɪŋli/",
        "incredible": "/ɪnkredəbl/",
        "indeed": "/ɪndi:d/",
        "independence": "/ɪndɪpendəns/",
        "independent": "/ɪndɪpendənt/",
        "index": "/ɪndeks/",
        "indicate": "/ɪndɪkeɪt/",
        "indication": "/ɪndɪkeɪʃn/",
        "individual": "/ɪndɪvɪdʒuəl/",
        "industrial": "/ɪndʌstriəl/",
        "industry": "/ɪndəstri/",
        "infant": "/ɪnfənt/",
        "infection": "/ɪnfekʃn/",
        "inflation": "/ɪnfleɪʃn/",
        "influence": "/ɪnfluəns/",
        "inform": "/ɪnfɔ:m/",
        "information": "/ɪnfəmeɪʃn/",
        "ingredient": "/ɪngri:diənt/",
        "initial": "/ɪnɪʃl/",
        "initially": "/ɪnɪʃəli/",
        "initiative": "/ɪnɪʃətɪv/",
        "injury": "/ɪndʒəri/",
        "inner": "/ɪnə(r)/",
        "innocent": "/ɪnəsnt/",
        "inquiry": "/ɪnkwaɪəri/",
        "inside": "/ɪnsaɪd/",
        "insight": "/ɪnsaɪt/",
        "insist": "/ɪnsɪst/",
        "inspire": "/ɪnspaɪə(r)/",
        "install": "/ɪnstɔ:l/",
        "instance": "/ɪnstəns/",
        "instead": "/ɪnsted/",
        "institution": "/ɪnstɪtju:ʃn/",
        "institutional": "/ɪnstɪtju:ʃənl/",
        "instruction": "/ɪnstrʌkʃn/",
        "instructor": "/ɪnstrʌktə(r)/",
        "instrument": "/ɪnstrəmənt/",
        "insurance": "/ɪnʃʊərəns/",
        "intellectual": "/ɪntəlektʃuəl/",
        "intelligence": "/ɪntelɪdʒəns/",
        "intend": "/ɪntend/",
        "intense": "/ɪntens/",
        "intensity": "/ɪntensəti/",
        "intention": "/ɪntenʃn/",
        "interaction": "/ɪntərækʃn/",
        "interest": "/ɪntrəst/",
        "interested": "/ɪntrəstɪd/",
        "interesting": "/ɪntrəstɪŋ/",
        "internal": "/ɪntɜ:nl/",
        "international": "/ɪntənæʃnəl/",
        "interpret": "/ɪntɜ:prət/",
        "interpretation": "/ɪntɜ:prəteɪʃn/",
        "intervention": "/ɪntəvenʃn/",
        "interview": "/ɪntəvju:/",
        "into": "/ɪntə/",
        "introduce": "/ɪntrədju:s/",
        "introduction": "/ɪntrədʌkʃn/",
        "invasion": "/ɪnveɪʒn/",
        "invest": "/ɪnvest/",
        "investigate": "/ɪnvestɪgeɪt/",
        "investigation": "/ɪnvestɪgeɪʃn/",
        "investigator": "/ɪnvestɪgeɪtə(r)/",
        "investment": "/ɪnvestmənt/",
        "investor": "/ɪnvestə(r)/",
        "invite": "/ɪnvaɪt/",
        "involve": "/ɪnvɒlv/",
        "involved": "/ɪnvɒlvd/",
        "involvement": "/ɪnvɒlvmənt/",
        "iron": "/aɪən/",
        "island": "/aɪlənd/",
        "issue": "/ɪʃu:/",
        "it": "/ɪt/",
        "item": "/aɪtəm/",
        "its": "/ɪts/",
        "itself": "/ɪtself/",
        "jacket": "/dʒækɪt/",
        "jail": "/dʒeɪl/",
        "jet": "/dʒet/",
        "job": "/dʒɒb/",
        "join": "/dʒɔɪn/",
        "joint": "/dʒɔɪnt/",
        "joke": "/dʒəʊk/",
        "journal": "/dʒɜ:nl/",
        "journalist": "/dʒɜ:nəlɪst/",
        "journey": "/dʒɜ:ni/",
        "joy": "/dʒɔɪ/",
        "judge": "/dʒʌdʒ/",
        "juice": "/dʒu:s/",
        "jump": "/dʒʌmp/",
        "junior": "/dʒu:niə(r)/",
        "jury": "/dʒʊəri/",
        "just": "/dʒʌst/",
        "justice": "/dʒʌstɪs/",
        "justify": "/dʒʌstɪfaɪ/",
        "keep": "/ki:p/",
        "key": "/ki:/",
        "kick": "/kɪk/",
        "kid": "/kɪd/",
        "kill": "/kɪl/",
        "killer": "/kɪlə(r)/",
        "killing": "/kɪlɪŋ/",
        "kind": "/kaɪnd/",
        "king": "/kɪŋ/",
        "kiss": "/kɪs/",
        "kitchen": "/kɪtʃɪn/",
        "knee": "/ni:/",
        "knife": "/naɪf/",
        "knock": "/nɒk/",
        "no": "/nəʊ/",
        "knowledge": "/nɒlɪdʒ/",
        "lab": "/læb/",
        "label": "/leɪbl/",
        "laboratory": "/ləbɒrətri/",
        "lack": "/læk/",
        "lady": "/leɪdi/",
        "lake": "/leɪk/",
        "land": "/lænd/",
        "landscape": "/lændskeɪp/",
        "language": "/læŋgwɪdʒ/",
        "lap": "/læp/",
        "large": "/lɑ:dʒ/",
        "largely": "/lɑ:dʒli/",
        "late": "/leɪt/",
        "later": "/leɪtə(r)/",
        "latter": "/lætə(r)/",
        "laugh": "/lɑ:f/",
        "launch": "/lɔ:ntʃ/",
        "law": "/lɔ:/",
        "lawn": "/lɔ:n/",
        "lawsuit": "/lɔ:su:t/",
        "lawyer": "/lɔɪə(r)/",
        "lay": "/leɪ/",
        "layer": "/leɪə(r)/",
        "leader": "/li:də(r)/",
        "leadership": "/li:dəʃɪp/",
        "leaf": "/li:f/",
        "league": "/li:g/",
        "lean": "/li:n/",
        "learn": "/lɜ:n/",
        "learning": "/lɜ:nɪŋ/",
        "least": "/li:st/",
        "leather": "/leðə(r)/",
        "leave": "/li:v/",
        "left": "/left/",
        "leg": "/leg/",
        "legacy": "/legəsi/",
        "legal": "/li:gl/",
        "legend": "/ledʒənd/",
        "legislation": "/ledʒɪsleɪʃn/",
        "legitimate": "/lɪdʒɪtɪmət/",
        "lemon": "/lemən/",
        "length": "/leŋkθ/",
        "less": "/les/",
        "lesson": "/lesn/",
        "let": "/let/",
        "letter": "/letə(r)/",
        "level": "/levl/",
        "liberal": "/lɪbərəl/",
        "library": "/laɪbrəri/",
        "license": "/laɪsns/",
        "life": "/laɪf/",
        "lifestyle": "/laɪfstaɪl/",
        "lifetime": "/laɪftaɪm/",
        "lift": "/lɪft/",
        "light": "/laɪt/",
        "like": "/laɪk/",
        "likely": "/laɪkli/",
        "limit": "/lɪmɪt/",
        "limitation": "/lɪmɪteɪʃn/",
        "limited": "/lɪmɪtɪd/",
        "line": "/laɪn/",
        "link": "/lɪŋk/",
        "lip": "/lɪp/",
        "list": "/lɪst/",
        "listen": "/lɪsn/",
        "literally": "/lɪtərəli/",
        "literary": "/lɪtərəri/",
        "literature": "/lɪtrətʃə(r)/",
        "little": "/lɪtl/",
        "living": "/lɪvɪŋ/",
        "load": "/ləʊd/",
        "loan": "/ləʊn/",
        "local": "/ləʊkl/",
        "locate": "/ləʊkeɪt/",
        "location": "/ləʊkeɪʃn/",
        "lock": "/lɒk/",
        "long": "/lɒŋ/",
        "long-term": "/lɒŋ",
        "look": "/lʊk/",
        "loose": "/lu:s/",
        "lose": "/lu:z/",
        "loss": "/lɒs/",
        "lost": "/lɒst/",
        "lot": "/lɒt/",
        "loud": "/laʊd/",
        "love": "/lʌv/",
        "lovely": "/lʌvli/",
        "lover": "/lʌvə(r)/",
        "low": "/ləʊ/",
        "luck": "/lʌk/",
        "lucky": "/lʌki/",
        "lunch": "/lʌntʃ/",
        "lung": "/lʌŋ/",
        "machine": "/məʃi:n/",
        "mad": "/mæd/",
        "magazine": "/mægəzi:n/",
        "male": "/meɪl/",
        "main": "/meɪn/",
        "mainly": "/meɪnli/",
        "maintain": "/meɪnteɪn/",
        "maintenance": "/meɪntənəns/",
        "major": "/meɪdʒə(r)/",
        "majority": "/mədʒɒrəti/",
        "make": "/meɪk/",
        "maker": "/meɪkə(r)/",
        "mall": "/mɔ:l/",
        "man": "/mæn/",
        "manage": "/mænɪdʒ/",
        "management": "/mænɪdʒmənt/",
        "manager": "/mænɪdʒə(r)/",
        "manner": "/mænə(r)/",
        "manufacturer": "/mænjufæktʃərə(r)/",
        "manufacturing": "/mænjufæktʃərɪŋ/",
        "many": "/meni/",
        "map": "/mæp/",
        "margin": "/mɑ:dʒɪn/",
        "mark": "/mɑ:k/",
        "market": "/mɑ:kɪt/",
        "marketing": "/mɑ:kɪtɪŋ/",
        "marriage": "/mærɪdʒ/",
        "married": "/mærid/",
        "marry": "/mæri/",
        "mask": "/mɑ:sk/",
        "mass": "/mæs/",
        "massive": "/mæsɪv/",
        "master": "/mɑ:stə(r)/",
        "match": "/mætʃ/",
        "material": "/mətɪəriəl/",
        "math": "/mæθ/",
        "matter": "/mætə(r)/",
        "may": "/meɪ/",
        "maybe": "/meɪbi/",
        "mayor": "/meə(r)/",
        "me": "/mi/",
        "meal": "/mi:l/",
        "mean": "/mi:n/",
        "meaning": "/mi:nɪŋ/",
        "meanwhile": "/mi:nwaɪl/",
        "measure": "/meʒə(r)/",
        "measurement": "/meʒəmənt/",
        "meet": "/mi:t/",
        "mechanism": "/mekənɪzəm/",
        "media": "/mi:diə/",
        "medical": "/medɪkl/",
        "medication": "/medɪkeɪʃn/",
        "medicine": "/medsn/",
        "medium": "/mi:diəm/",
        "meeting": "/mi:tɪŋ/",
        "member": "/membə(r)/",
        "membership": "/membəʃɪp/",
        "memory": "/meməri/",
        "mental": "/mentl/",
        "mention": "/menʃn/",
        "menu": "/menju:/",
        "mere": "/mɪə(r)/",
        "merely": "/mɪəli/",
        "mess": "/mes/",
        "message": "/mesɪdʒ/",
        "metal": "/metl/",
        "meter": "/mi:tə(r)/",
        "method": "/meθəd/",
        "middle": "/mɪdl/",
        "might": "/maɪt/",
        "military": "/mɪlətri/",
        "milk": "/mɪlk/",
        "million": "/mɪljən/",
        "mind": "/maɪnd/",
        "mine": "/maɪn/",
        "minister": "/mɪnɪstə(r)/",
        "minor": "/maɪnə(r)/",
        "minority": "/maɪnɒrəti/",
        "miracle": "/mɪrəkl/",
        "mirror": "/mɪrə(r)/",
        "miss": "/mɪs/",
        "missile": "/mɪsaɪl/",
        "mission": "/mɪʃn/",
        "mistake": "/mɪsteɪk/",
        "mix": "/mɪks/",
        "mixture": "/mɪkstʃə(r)/",
        "mode": "/məʊd/",
        "model": "/mɒdl/",
        "moderate": "/mɒdərət/",
        "modern": "/mɒdn/",
        "modest": "/mɒdɪst/",
        "mom": "/mɒm/",
        "moment": "/məʊmənt/",
        "money": "/mʌni/",
        "monitor": "/mɒnɪtə(r)/",
        "month": "/mʌnθ/",
        "mood": "/mu:d/",
        "moon": "/mu:n/",
        "moral": "/mɒrəl/",
        "more": "/mɔ:(r)/",
        "moreover": "/mɔ:rəʊvə(r)/",
        "morning": "/mɔ:nɪŋ/",
        "mortgage": "/mɔ:gɪdʒ/",
        "most": "/məʊst/",
        "mostly": "/məʊstli/",
        "mother": "/mʌðə(r)/",
        "motion": "/məʊʃn/",
        "motivation": "/məʊtɪveɪʃn/",
        "motor": "/məʊtə(r)/",
        "mount": "/maʊnt/",
        "mountain": "/maʊntən/",
        "mouse": "/maʊs/",
        "mouth": "/maʊθ/",
        "move": "/mu:v/",
        "movement": "/mu:vmənt/",
        "movie": "/mu:vi/",
        "much": "/mʌtʃ/",
        "multiple": "/mʌltɪpl/",
        "murder": "/mɜ:də(r)/",
        "muscle": "/mʌsl/",
        "museum": "/mjuzi:əm/",
        "music": "/mju:zɪk/",
        "musical": "/mju:zɪkl/",
        "musician": "/mjuzɪʃn/",
        "must": "/məst/",
        "mutual": "/mju:tʃuəl/",
        "my": "/maɪ/",
        "myself": "/maɪself/",
        "mystery": "/mɪstri/",
        "myth": "/mɪθ/",
        "naked": "/neɪkɪd/",
        "name": "/neɪm/",
        "narrative": "/nærətɪv/",
        "narrow": "/nærəʊ/",
        "nation": "/neɪʃn/",
        "national": "/næʃnəl/",
        "native": "/neɪtɪv/",
        "natural": "/nætʃrəl/",
        "naturally": "/nætʃrəli/",
        "nature": "/neɪtʃə(r)/",
        "near": "/nɪə(r)/",
        "nearby": "/nɪəbaɪ/",
        "nearly": "/nɪəli/",
        "necessarily": "/nesəserəli/",
        "necessary": "/nesəsəri/",
        "neck": "/nek/",
        "need": "/ni:d/",
        "negative": "/negətɪv/",
        "negotiate": "/nɪgəʊʃieɪt/",
        "negotiation": "/nɪgəʊʃieɪʃn/",
        "neither": "/naɪðə(r)/",
        "nerve": "/nɜ:v/",
        "nervous": "/nɜ:vəs/",
        "net": "/net/",
        "network": "/netwɜ:k/",
        "never": "/nevə(r)/",
        "nevertheless": "/nevəðəles/",
        "new": "/nju:/",
        "newly": "/nju:li/",
        "news": "/nju:z/",
        "newspaper": "/nju:zpeɪpə(r)/",
        "next": "/nekst/",
        "nice": "/naɪs/",
        "night": "/naɪt/",
        "nine": "/naɪn/",
        "nobody": "/nəʊbədi/",
        "nod": "/nɒd/",
        "noise": "/nɔɪz/",
        "nomination": "/nɒmɪneɪʃn/",
        "none": "/nʌn/",
        "nonetheless": "/nʌnðəles/",
        "nor": "/nɔ:(r)/",
        "normal": "/nɔ:ml/",
        "normally": "/nɔ:məli/",
        "north": "/nɔ:θ/",
        "northern": "/nɔ:ðən/",
        "nose": "/nəʊz/",
        "not": "/nɒt/",
        "note": "/nəʊt/",
        "nothing": "/nʌθɪŋ/",
        "notice": "/nəʊtɪs/",
        "notion": "/nəʊʃn/",
        "novel": "/nɒvl/",
        "now": "/naʊ/",
        "nowhere": "/nəʊweə(r)/",
        "nuclear": "/nju:kliə(r)/",
        "number": "/nʌmbə(r)/",
        "numerous": "/nju:mərəs/",
        "nurse": "/nɜ:s/",
        "nut": "/nʌt/",
        "object": "/ɒbdʒɪkt/",
        "objective": "/əbdʒektɪv/",
        "obligation": "/ɒblɪgeɪʃn/",
        "observation": "/ɒbzəveɪʃn/",
        "observe": "/əbzɜ:v/",
        "observer": "/əbzɜ:və(r)/",
        "obtain": "/əbteɪn/",
        "obvious": "/ɒbviəs/",
        "obviously": "/ɒbviəsli/",
        "occasion": "/əkeɪʒn/",
        "occasionally": "/əkeɪʒnəli/",
        "occupation": "/ɒkjupeɪʃn/",
        "occupy": "/ɒkjupaɪ/",
        "occur": "/əkɜ:(r)/",
        "ocean": "/əʊʃn/",
        "odd": "/ɒd/",
        "odds": "/ɒdz/",
        "of": "/əv/",
        "off": "/ɒf/",
        "offense": "/əfens/",
        "offensive": "/əfensɪv/",
        "offer": "/ɒfə(r)/",
        "office": "/ɒfɪs/",
        "officer": "/ɒfɪsə(r)/",
        "official": "/əfɪʃl/",
        "often": "/ɒfn/",
        "owe": "/əʊ/",
        "oil": "/ɔɪl/",
        "ok": "/əʊkeɪ/",
        "old": "/əʊld/",
        "on": "/ɒn/",
        "once": "/wʌns/",
        "one": "/wʌn/",
        "ongoing": "/ɒngəʊɪŋ/",
        "onion": "/ʌnjən/",
        "online": "/ɒnlaɪn/",
        "only": "/əʊnli/",
        "onto": "/ɒntə/",
        "open": "/əʊpən/",
        "opening": "/əʊpənɪŋ/",
        "operate": "/ɒpəreɪt/",
        "operation": "/ɒpəreɪʃn/",
        "operator": "/ɒpəreɪtə(r)/",
        "opinion": "/əpɪnjən/",
        "opponent": "/əpəʊnənt/",
        "opportunity": "/ɒpətju:nəti/",
        "oppose": "/əpəʊz/",
        "opposite": "/ɒpəzɪt/",
        "opposition": "/ɒpəzɪʃn/",
        "option": "/ɒpʃn/",
        "or": "/ɔ:(r)/",
        "orange": "/ɒrɪndʒ/",
        "order": "/ɔ:də(r)/",
        "ordinary": "/ɔ:dnri/",
        "organic": "/ɔ:gænɪk/",
        "organization": "/ɔ:gənaɪzeɪʃn/",
        "organize": "/ɔ:gənaɪz/",
        "orientation": "/ɔ:riənteɪʃn/",
        "origin": "/ɒrɪdʒɪn/",
        "original": "/ərɪdʒənl/",
        "originally": "/ərɪdʒənəli/",
        "other": "/ʌðə(r)/",
        "otherwise": "/ʌðəwaɪz/",
        "our": "/ɑ:(r)/",
        "ourselves": "/ɑ:selvz/",
        "out": "/aʊt/",
        "outcome": "/aʊtkʌm/",
        "outside": "/aʊtsaɪd/",
        "oven": "/ʌvn/",
        "over": "/əʊvə(r)/",
        "overall": "/əʊvərɔ:l/",
        "overcome": "/əʊvəkʌm/",
        "overlook": "/əʊvəlʊk/",
        "own": "/əʊn/",
        "owner": "/əʊnə(r)/",
        "pace": "/peɪs/",
        "pack": "/pæk/",
        "package": "/pækɪdʒ/",
        "page": "/peɪdʒ/",
        "pain": "/peɪn/",
        "painful": "/peɪnfl/",
        "paint": "/peɪnt/",
        "painter": "/peɪntə(r)/",
        "painting": "/peɪntɪŋ/",
        "pair": "/peə(r)/",
        "pale": "/peɪl/",
        "palm": "/pɑ:m/",
        "pan": "/pæn/",
        "panel": "/pænl/",
        "pant": "/pænt/",
        "paper": "/peɪpə(r)/",
        "parent": "/peərənt/",
        "park": "/pɑ:k/",
        "parking": "/pɑ:kɪŋ/",
        "part": "/pɑ:t/",
        "participant": "/pɑ:tɪsɪpənt/",
        "participate": "/pɑ:tɪsɪpeɪt/",
        "participation": "/pɑ:tɪsɪpeɪʃn/",
        "particular": "/pətɪkjələ(r)/",
        "particularly": "/pətɪkjələli/",
        "partly": "/pɑ:tli/",
        "partner": "/pɑ:tnə(r)/",
        "partnership": "/pɑ:tnəʃɪp/",
        "party": "/pɑ:ti/",
        "pass": "/pɑ:s/",
        "passage": "/pæsɪdʒ/",
        "passenger": "/pæsɪndʒə(r)/",
        "passion": "/pæʃn/",
        "past": "/pɑ:st/",
        "patch": "/pætʃ/",
        "path": "/pɑ:θ/",
        "patient": "/peɪʃnt/",
        "pattern": "/pætn/",
        "pause": "/pɔ:z/",
        "pay": "/peɪ/",
        "payment": "/peɪmənt/",
        "piece": "/pi:s/",
        "peak": "/pi:k/",
        "peer": "/pɪə(r)/",
        "penalty": "/penəlti/",
        "people": "/pi:pl/",
        "pepper": "/pepə(r)/",
        "per": "/pə(r)/",
        "perceive": "/pəsi:v/",
        "percentage": "/pəsentɪdʒ/",
        "perception": "/pəsepʃn/",
        "perfect": "/pɜ:fɪkt/",
        "perfectly": "/pɜ:fɪktli/",
        "perform": "/pəfɔ:m/",
        "performance": "/pəfɔ:məns/",
        "perhaps": "/pəhæps/",
        "period": "/pɪəriəd/",
        "permanent": "/pɜ:mənənt/",
        "permission": "/pəmɪʃn/",
        "permit": "/pəmɪt/",
        "person": "/pɜ:sn/",
        "personal": "/pɜ:sənl/",
        "personality": "/pɜ:sənæləti/",
        "personally": "/pɜ:sənəli/",
        "personnel": "/pɜ:sənel/",
        "perspective": "/pəspektɪv/",
        "persuade": "/pəsweɪd/",
        "pet": "/pet/",
        "phase": "/feɪz/",
        "phenomenon": "/fənɒmɪnən/",
        "philosophy": "/fəlɒsəfi/",
        "phone": "/fəʊn/",
        "photo": "/fəʊtəʊ/",
        "photograph": "/fəʊtəgrɑ:f/",
        "photographer": "/fətɒgrəfə(r)/",
        "phrase": "/freɪz/",
        "physical": "/fɪzɪkl/",
        "physically": "/fɪzɪkli/",
        "physician": "/fɪzɪʃn/",
        "piano": "/piænəʊ/",
        "pick": "/pɪk/",
        "picture": "/pɪktʃə(r)/",
        "pie": "/paɪ/",
        "pile": "/paɪl/",
        "pilot": "/paɪlət/",
        "pine": "/paɪn/",
        "pink": "/pɪŋk/",
        "pipe": "/paɪp/",
        "pitch": "/pɪtʃ/",
        "place": "/pleɪs/",
        "plan": "/plæn/",
        "plane": "/pleɪn/",
        "planet": "/plænɪt/",
        "planning": "/plænɪŋ/",
        "plant": "/plɑ:nt/",
        "plastic": "/plæstɪk/",
        "plate": "/pleɪt/",
        "platform": "/plætfɔ:m/",
        "play": "/pleɪ/",
        "player": "/pleɪə(r)/",
        "please": "/pli:z/",
        "pleasure": "/pleʒə(r)/",
        "plenty": "/plenti/",
        "plot": "/plɒt/",
        "pocket": "/pɒkɪt/",
        "poem": "/pəʊɪm/",
        "poet": "/pəʊɪt/",
        "poetry": "/pəʊətri/",
        "point": "/pɔɪnt/",
        "poll": "/pəʊl/",
        "police": "/pəli:s/",
        "policy": "/pɒləsi/",
        "political": "/pəlɪtɪkl/",
        "politically": "/pəlɪtɪkli/",
        "politician": "/pɒlətɪʃn/",
        "politics": "/pɒlətɪks/",
        "pollution": "/pəlu:ʃn/",
        "pool": "/pu:l/",
        "pour": "/pɔ:(r)/",
        "pop": "/pɒp/",
        "popular": "/pɒpjələ(r)/",
        "population": "/pɒpjuleɪʃn/",
        "porch": "/pɔ:tʃ/",
        "port": "/pɔ:t/",
        "portion": "/pɔ:ʃn/",
        "portrait": "/pɔ:treɪt/",
        "portray": "/pɔ:treɪ/",
        "pose": "/pəʊz/",
        "position": "/pəzɪʃn/",
        "positive": "/pɒzətɪv/",
        "possess": "/pəzes/",
        "possibility": "/pɒsəbɪləti/",
        "possible": "/pɒsəbl/",
        "possibly": "/pɒsəbli/",
        "post": "/pəʊst/",
        "pot": "/pɒt/",
        "potato": "/pəteɪtəʊ/",
        "potential": "/pətenʃl/",
        "potentially": "/pətenʃəli/",
        "pound": "/paʊnd/",
        "poverty": "/pɒvəti/",
        "powder": "/paʊdə(r)/",
        "power": "/paʊə(r)/",
        "powerful": "/paʊəfl/",
        "practical": "/præktɪkl/",
        "practice": "/præktɪs/",
        "pray": "/preɪ/",
        "prayer": "/preə(r)/",
        "precisely": "/prɪsaɪsli/",
        "predict": "/prɪdɪkt/",
        "prefer": "/prɪfɜ:(r)/",
        "preference": "/prefrəns/",
        "pregnancy": "/pregnənsi/",
        "pregnant": "/pregnənt/",
        "preparation": "/prepəreɪʃn/",
        "prepare": "/prɪpeə(r)/",
        "prescription": "/prɪskrɪpʃn/",
        "presence": "/prezns/",
        "present": "/preznt/",
        "presentation": "/preznteɪʃn/",
        "preserve": "/prɪzɜ:v/",
        "president": "/prezɪdənt/",
        "presidential": "/prezɪdenʃl/",
        "press": "/pres/",
        "pressure": "/preʃə(r)/",
        "pretend": "/prɪtend/",
        "pretty": "/prɪti/",
        "prevent": "/prɪvent/",
        "previous": "/pri:viəs/",
        "previously": "/pri:viəsli/",
        "price": "/praɪs/",
        "pride": "/praɪd/",
        "priest": "/pri:st/",
        "primarily": "/praɪmerəli/",
        "primary": "/praɪməri/",
        "prime": "/praɪm/",
        "principle": "/prɪnsəpl/",
        "print": "/prɪnt/",
        "prior": "/praɪə(r)/",
        "priority": "/praɪɒrəti/",
        "prison": "/prɪzn/",
        "prisoner": "/prɪznə(r)/",
        "privacy": "/prɪvəsi/",
        "private": "/praɪvət/",
        "probably": "/prɒbəbli/",
        "problem": "/prɒbləm/",
        "procedure": "/prəsi:dʒə(r)/",
        "proceed": "/prəsi:d/",
        "produce": "/prədju:s/",
        "producer": "/prədju:sə(r)/",
        "product": "/prɒdʌkt/",
        "production": "/prədʌkʃn/",
        "profession": "/prəfeʃn/",
        "professional": "/prəfeʃənl/",
        "professor": "/prəfesə(r)/",
        "profile": "/prəʊfaɪl/",
        "profit": "/prɒfɪt/",
        "program": "/prəʊgræm/",
        "progress": "/prəʊgres/",
        "project": "/prɒdʒekt/",
        "prominent": "/prɒmɪnənt/",
        "promise": "/prɒmɪs/",
        "promote": "/prəməʊt/",
        "prompt": "/prɒmpt/",
        "proof": "/pru:f/",
        "proper": "/prɒpə(r)/",
        "properly": "/prɒpəli/",
        "property": "/prɒpəti/",
        "proportion": "/prəpɔ:ʃn/",
        "proposal": "/prəpəʊzl/",
        "propose": "/prəpəʊz/",
        "prosecutor": "/prɒsɪkju:tə(r)/",
        "prospect": "/prɒspekt/",
        "protect": "/prətekt/",
        "protection": "/prətekʃn/",
        "protein": "/prəʊti:n/",
        "protest": "/prəʊtest/",
        "proud": "/praʊd/",
        "prove": "/pru:v/",
        "provide": "/prəvaɪd/",
        "provider": "/prəvaɪdə(r)/",
        "province": "/prɒvɪns/",
        "provision": "/prəvɪʒn/",
        "psychological": "/saɪkəlɒdʒɪkl/",
        "psychologist": "/saɪkɒlədʒɪst/",
        "psychology": "/saɪkɒlədʒi/",
        "public": "/pʌblɪk/",
        "publication": "/pʌblɪkeɪʃn/",
        "publicly": "/pʌblɪkli/",
        "publish": "/pʌblɪʃ/",
        "publisher": "/pʌblɪʃə(r)/",
        "pull": "/pʊl/",
        "punishment": "/pʌnɪʃmənt/",
        "purchase": "/pɜ:tʃəs/",
        "pure": "/pjʊə(r)/",
        "purpose": "/pɜ:pəs/",
        "pursue": "/pəsju:/",
        "push": "/pʊʃ/",
        "put": "/pʊt/",
        "qualify": "/kwɒlɪfaɪ/",
        "quality": "/kwɒləti/",
        "quarter": "/kwɔ:tə(r)/",
        "quarterback": "/kwɔ:təbæk/",
        "question": "/kwestʃən/",
        "quick": "/kwɪk/",
        "quickly": "/kwɪkli/",
        "quiet": "/kwaɪət/",
        "quietly": "/kwaɪətli/",
        "quit": "/kwɪt/",
        "quite": "/kwaɪt/",
        "quote": "/kwəʊt/",
        "race": "/reɪs/",
        "racial": "/reɪʃl/",
        "radical": "/rædɪkl/",
        "radio": "/reɪdiəʊ/",
        "rail": "/reɪl/",
        "rain": "/reɪn/",
        "raise": "/reɪz/",
        "range": "/reɪndʒ/",
        "rank": "/ræŋk/",
        "rapid": "/ræpɪd/",
        "rapidly": "/ræpɪdli/",
        "rare": "/reə(r)/",
        "rarely": "/reəli/",
        "rate": "/reɪt/",
        "rather": "/rɑ:ðə(r)/",
        "rating": "/reɪtɪŋ/",
        "ratio": "/reɪʃiəʊ/",
        "raw": "/rɔ:/",
        "reach": "/ri:tʃ/",
        "react": "/riækt/",
        "reaction": "/riækʃn/",
        "read": "/ri:d/",
        "reader": "/ri:də(r)/",
        "reading": "/ri:dɪŋ/",
        "ready": "/redi/",
        "real": "/ri:əl/",
        "reality": "/riæləti/",
        "realize": "/ri:əlaɪz/",
        "really": "/ri:əli/",
        "reason": "/ri:zn/",
        "reasonable": "/ri:znəbl/",
        "recall": "/rɪkɔ:l/",
        "receive": "/rɪsi:v/",
        "recent": "/ri:snt/",
        "recently": "/ri:sntli/",
        "recipe": "/resəpi/",
        "recognition": "/rekəgnɪʃn/",
        "recognize": "/rekəgnaɪz/",
        "recommend": "/rekəmend/",
        "recommendation": "/rekəmendeɪʃn/",
        "record": "/rekɔ:d/",
        "recording": "/rɪkɔ:dɪŋ/",
        "recover": "/rɪkʌvə(r)/",
        "recovery": "/rɪkʌvəri/",
        "recruit": "/rɪkru:t/",
        "red": "/red/",
        "reduce": "/rɪdju:s/",
        "reduction": "/rɪdʌkʃn/",
        "refer": "/rɪfɜ:(r)/",
        "reference": "/refrəns/",
        "reflect": "/rɪflekt/",
        "reflection": "/rɪflekʃn/",
        "reform": "/rɪfɔ:m/",
        "refugee": "/refjudʒi:/",
        "regard": "/rɪgɑ:d/",
        "regarding": "/rɪgɑ:dɪŋ/",
        "regardless": "/rɪgɑ:dləs/",
        "regime": "/reɪʒi:m/",
        "region": "/ri:dʒən/",
        "regional": "/ri:dʒənl/",
        "register": "/redʒɪstə(r)/",
        "regular": "/regjələ(r)/",
        "regularly": "/regjələli/",
        "regulate": "/regjuleɪt/",
        "regulation": "/regjuleɪʃn/",
        "reinforce": "/ri:ɪnfɔ:s/",
        "reject": "/rɪdʒekt/",
        "relate": "/rɪleɪt/",
        "relation": "/rɪleɪʃn/",
        "relationship": "/rɪleɪʃnʃɪp/",
        "relative": "/relətɪv/",
        "relatively": "/relətɪvli/",
        "relax": "/rɪlæks/",
        "release": "/rɪli:s/",
        "relevant": "/reləvənt/",
        "relief": "/rɪli:f/",
        "religion": "/rɪlɪdʒən/",
        "religious": "/rɪlɪdʒəs/",
        "rely": "/rɪlaɪ/",
        "remain": "/rɪmeɪn/",
        "remaining": "/rɪmeɪnɪŋ/",
        "remarkable": "/rɪmɑ:kəbl/",
        "remember": "/rɪmembə(r)/",
        "remind": "/rɪmaɪnd/",
        "remote": "/rɪməʊt/",
        "remove": "/rɪmu:v/",
        "repeat": "/rɪpi:t/",
        "repeatedly": "/rɪpi:tɪdli/",
        "replace": "/rɪpleɪs/",
        "reply": "/rɪplaɪ/",
        "report": "/rɪpɔ:t/",
        "reporter": "/rɪpɔ:tə(r)/",
        "represent": "/reprɪzent/",
        "representation": "/reprɪzenteɪʃn/",
        "representative": "/reprɪzentətɪv/",
        "reputation": "/repjuteɪʃn/",
        "request": "/rɪkwest/",
        "require": "/rɪkwaɪə(r)/",
        "requirement": "/rɪkwaɪəmənt/",
        "research": "/rɪsɜ:tʃ/",
        "researcher": "/rɪsɜ:tʃə(r)/",
        "resemble": "/rɪzembl/",
        "reservation": "/rezəveɪʃn/",
        "resident": "/rezɪdənt/",
        "resist": "/rɪzɪst/",
        "resistance": "/rɪzɪstəns/",
        "resolution": "/rezəlu:ʃn/",
        "resolve": "/rɪzɒlv/",
        "resort": "/rɪzɔ:t/",
        "resource": "/rɪsɔ:s/",
        "respect": "/rɪspekt/",
        "respond": "/rɪspɒnd/",
        "respondent": "/rɪspɒndənt/",
        "response": "/rɪspɒns/",
        "responsibility": "/rɪspɒnsəbɪləti/",
        "responsible": "/rɪspɒnsəbl/",
        "rest": "/rest/",
        "restaurant": "/restrɒnt/",
        "restore": "/rɪstɔ:(r)/",
        "restriction": "/rɪstrɪkʃn/",
        "result": "/rɪzʌlt/",
        "retain": "/rɪteɪn/",
        "retire": "/rɪtaɪə(r)/",
        "retirement": "/rɪtaɪəmənt/",
        "return": "/rɪtɜ:n/",
        "reveal": "/rɪvi:l/",
        "revenue": "/revənju:/",
        "review": "/rɪvju:/",
        "revolution": "/revəlu:ʃn/",
        "rhythm": "/rɪðəm/",
        "rice": "/raɪs/",
        "rich": "/rɪtʃ/",
        "rid": "/rɪd/",
        "ride": "/raɪd/",
        "rifle": "/raɪfl/",
        "write": "/raɪt/",
        "rise": "/raɪz/",
        "risk": "/rɪsk/",
        "river": "/rɪvə(r)/",
        "road": "/rəʊd/",
        "rock": "/rɒk/",
        "roll": "/rəʊl/",
        "romantic": "/rəʊmæntɪk/",
        "roof": "/ru:f/",
        "room": "/ru:m/",
        "route": "/ru:t/",
        "rope": "/rəʊp/",
        "rose": "/rəʊz/",
        "rough": "/rʌf/",
        "roughly": "/rʌfli/",
        "round": "/raʊnd/",
        "routine": "/ru:ti:n/",
        "rub": "/rʌb/",
        "rule": "/ru:l/",
        "run": "/rʌn/",
        "running": "/rʌnɪŋ/",
        "rural": "/rʊərəl/",
        "rush": "/rʌʃ/",
        "sacred": "/seɪkrɪd/",
        "sad": "/sæd/",
        "safe": "/seɪf/",
        "safety": "/seɪfti/",
        "salad": "/sæləd/",
        "salary": "/sæləri/",
        "sale": "/seɪl/",
        "salt": "/sɔ:lt/",
        "same": "/seɪm/",
        "sample": "/sɑ:mpl/",
        "sanction": "/sæŋkʃn/",
        "sand": "/sænd/",
        "satellite": "/sætəlaɪt/",
        "satisfaction": "/sætɪsfækʃn/",
        "satisfy": "/sætɪsfaɪ/",
        "source": "/sɔ:s/",
        "save": "/seɪv/",
        "saving": "/seɪvɪŋ/",
        "say": "/seɪ/",
        "scale": "/skeɪl/",
        "scandal": "/skændl/",
        "scared": "/skeəd/",
        "scenario": "/sənɑ:riəʊ/",
        "scene": "/si:n/",
        "schedule": "/ʃedju:l/",
        "scheme": "/ski:m/",
        "scholar": "/skɒlə(r)/",
        "scholarship": "/skɒləʃɪp/",
        "school": "/sku:l/",
        "science": "/saɪəns/",
        "scientific": "/saɪəntɪfɪk/",
        "scientist": "/saɪəntɪst/",
        "scope": "/skəʊp/",
        "score": "/skɔ:(r)/",
        "scream": "/skri:m/",
        "screen": "/skri:n/",
        "script": "/skrɪpt/",
        "see": "/si:/",
        "search": "/sɜ:tʃ/",
        "season": "/si:zn/",
        "seat": "/si:t/",
        "secret": "/si:krət/",
        "secretary": "/sekrətri/",
        "section": "/sekʃn/",
        "sector": "/sektə(r)/",
        "secure": "/sɪkjʊə(r)/",
        "security": "/sɪkjʊərəti/",
        "seed": "/si:d/",
        "seek": "/si:k/",
        "seem": "/si:m/",
        "segment": "/segmənt/",
        "seize": "/si:z/",
        "select": "/sɪlekt/",
        "selection": "/sɪlekʃn/",
        "self": "/self/",
        "senator": "/senətə(r)/",
        "send": "/send/",
        "senior": "/si:niə(r)/",
        "sense": "/sens/",
        "sensitive": "/sensətɪv/",
        "sentence": "/sentəns/",
        "separate": "/seprət/",
        "sequence": "/si:kwəns/",
        "series": "/sɪəri:z/",
        "serious": "/sɪəriəs/",
        "seriously": "/sɪəriəsli/",
        "serve": "/sɜ:v/",
        "service": "/sɜ:vɪs/",
        "session": "/seʃn/",
        "set": "/set/",
        "setting": "/setɪŋ/",
        "settle": "/setl/",
        "settlement": "/setlmənt/",
        "seven": "/sevn/",
        "several": "/sevrəl/",
        "severe": "/sɪvɪə(r)/",
        "sex": "/seks/",
        "sexual": "/sekʃuəl/",
        "shade": "/ʃeɪd/",
        "shadow": "/ʃædəʊ/",
        "shake": "/ʃeɪk/",
        "shall": "/ʃəl/",
        "shape": "/ʃeɪp/",
        "share": "/ʃeə(r)/",
        "sharp": "/ʃɑ:p/",
        "she": "/ʃi/",
        "sheet": "/ʃi:t/",
        "shelf": "/ʃelf/",
        "shell": "/ʃel/",
        "shelter": "/ʃeltə(r)/",
        "shift": "/ʃɪft/",
        "shine": "/ʃaɪn/",
        "ship": "/ʃɪp/",
        "shirt": "/ʃɜ:t/",
        "shit": "/ʃɪt/",
        "shock": "/ʃɒk/",
        "shoe": "/ʃu:/",
        "shoot": "/ʃu:t/",
        "shooting": "/ʃu:tɪŋ/",
        "shop": "/ʃɒp/",
        "shopping": "/ʃɒpɪŋ/",
        "shore": "/ʃɔ:(r)/",
        "short": "/ʃɔ:t/",
        "shortly": "/ʃɔ:tli/",
        "shot": "/ʃɒt/",
        "should": "/ʃəd/",
        "shoulder": "/ʃəʊldə(r)/",
        "shout": "/ʃaʊt/",
        "show": "/ʃəʊ/",
        "shower": "/ʃaʊə(r)/",
        "shrug": "/ʃrʌg/",
        "shut": "/ʃʌt/",
        "sick": "/sɪk/",
        "side": "/saɪd/",
        "sigh": "/saɪ/",
        "sign": "/saɪn/",
        "signal": "/sɪgnəl/",
        "significance": "/sɪgnɪfɪkəns/",
        "significant": "/sɪgnɪfɪkənt/",
        "significantly": "/sɪgnɪfɪkəntli/",
        "silence": "/saɪləns/",
        "silent": "/saɪlənt/",
        "silver": "/sɪlvə(r)/",
        "similar": "/sɪmələ(r)/",
        "similarly": "/sɪmələli/",
        "simple": "/sɪmpl/",
        "simply": "/sɪmpli/",
        "sin": "/sɪn/",
        "since": "/sɪns/",
        "sing": "/sɪŋ/",
        "singer": "/sɪŋə(r)/",
        "single": "/sɪŋgl/",
        "sink": "/sɪŋk/",
        "sir": "/sɜ:(r)/",
        "sister": "/sɪstə(r)/",
        "sit": "/sɪt/",
        "situation": "/sɪtʃueɪʃn/",
        "six": "/sɪks/",
        "size": "/saɪz/",
        "ski": "/ski:/",
        "skill": "/skɪl/",
        "skin": "/skɪn/",
        "sky": "/skaɪ/",
        "slave": "/sleɪv/",
        "sleep": "/sli:p/",
        "slice": "/slaɪs/",
        "slide": "/slaɪd/",
        "slight": "/slaɪt/",
        "slightly": "/slaɪtli/",
        "slip": "/slɪp/",
        "slow": "/sləʊ/",
        "slowly": "/sləʊli/",
        "small": "/smɔ:l/",
        "smart": "/smɑ:t/",
        "smell": "/smel/",
        "smile": "/smaɪl/",
        "smoke": "/sməʊk/",
        "smooth": "/smu:ð/",
        "snap": "/snæp/",
        "snow": "/snəʊ/",
        "so": "/səʊ/",
        "so-called": "/səʊ",
        "soccer": "/sɒkə(r)/",
        "social": "/səʊʃl/",
        "society": "/səsaɪəti/",
        "soft": "/sɒft/",
        "software": "/sɒftweə(r)/",
        "soil": "/sɔɪl/",
        "solar": "/səʊlə(r)/",
        "soldier": "/səʊldʒə(r)/",
        "solid": "/sɒlɪd/",
        "solution": "/səlu:ʃn/",
        "solve": "/sɒlv/",
        "some": "/sʌm/",
        "somebody": "/sʌmbədi/",
        "somehow": "/sʌmhaʊ/",
        "someone": "/sʌmwʌn/",
        "something": "/sʌmθɪŋ/",
        "sometimes": "/sʌmtaɪmz/",
        "somewhat": "/sʌmwɒt/",
        "somewhere": "/sʌmweə(r)/",
        "sun": "/sʌn/",
        "song": "/sɒŋ/",
        "soon": "/su:n/",
        "sophisticated": "/səfɪstɪkeɪtɪd/",
        "sorry": "/sɒri/",
        "sort": "/sɔ:t/",
        "soul": "/səʊl/",
        "sound": "/saʊnd/",
        "soup": "/su:p/",
        "south": "/saʊθ/",
        "southern": "/sʌðən/",
        "space": "/speɪs/",
        "speak": "/spi:k/",
        "speaker": "/spi:kə(r)/",
        "special": "/speʃl/",
        "specialist": "/speʃəlɪst/",
        "species": "/spi:ʃi:z/",
        "specific": "/spəsɪfɪk/",
        "specifically": "/spəsɪfɪkli/",
        "speech": "/spi:tʃ/",
        "speed": "/spi:d/",
        "spend": "/spend/",
        "spending": "/spendɪŋ/",
        "spin": "/spɪn/",
        "spirit": "/spɪrɪt/",
        "spiritual": "/spɪrɪtʃuəl/",
        "split": "/splɪt/",
        "spokesman": "/spəʊksmən/",
        "sport": "/spɔ:t/",
        "spot": "/spɒt/",
        "spread": "/spred/",
        "spring": "/sprɪŋ/",
        "square": "/skweə(r)/",
        "squeeze": "/skwi:z/",
        "stability": "/stəbɪləti/",
        "stable": "/steɪbl/",
        "staff": "/stɑ:f/",
        "stage": "/steɪdʒ/",
        "stare": "/steə(r)/",
        "stake": "/steɪk/",
        "stand": "/stænd/",
        "standard": "/stændəd/",
        "standing": "/stændɪŋ/",
        "star": "/stɑ:(r)/",
        "start": "/stɑ:t/",
        "state": "/steɪt/",
        "statement": "/steɪtmənt/",
        "station": "/steɪʃn/",
        "status": "/steɪtəs/",
        "stay": "/steɪ/",
        "steady": "/stedi/",
        "steel": "/sti:l/",
        "step": "/step/",
        "stick": "/stɪk/",
        "still": "/stɪl/",
        "stir": "/stɜ:(r)/",
        "stock": "/stɒk/",
        "stomach": "/stʌmək/",
        "stone": "/stəʊn/",
        "stop": "/stɒp/",
        "storage": "/stɔ:rɪdʒ/",
        "store": "/stɔ:(r)/",
        "storm": "/stɔ:m/",
        "story": "/stɔ:ri/",
        "straight": "/streɪt/",
        "strange": "/streɪndʒ/",
        "stranger": "/streɪndʒə(r)/",
        "strategic": "/strəti:dʒɪk/",
        "strategy": "/strætədʒi/",
        "stream": "/stri:m/",
        "street": "/stri:t/",
        "strength": "/streŋkθ/",
        "strengthen": "/streŋkθn/",
        "stress": "/stres/",
        "stretch": "/stretʃ/",
        "strike": "/straɪk/",
        "string": "/strɪŋ/",
        "strip": "/strɪp/",
        "stroke": "/strəʊk/",
        "strong": "/strɒŋ/",
        "strongly": "/strɒŋli/",
        "structure": "/strʌktʃə(r)/",
        "struggle": "/strʌgl/",
        "student": "/stju:dnt/",
        "studio": "/stju:diəʊ/",
        "study": "/stʌdi/",
        "stuff": "/stʌf/",
        "stupid": "/stju:pɪd/",
        "style": "/staɪl/",
        "subject": "/sʌbdʒɪkt/",
        "submit": "/səbmɪt/",
        "subsequent": "/sʌbsɪkwənt/",
        "substance": "/sʌbstəns/",
        "substantial": "/səbstænʃl/",
        "succeed": "/səksi:d/",
        "success": "/səkses/",
        "successful": "/səksesfl/",
        "successfully": "/səksesfəli/",
        "such": "/sʌtʃ/",
        "sudden": "/sʌdn/",
        "suddenly": "/sʌdənli/",
        "sue": "/su:/",
        "suffer": "/sʌfə(r)/",
        "sufficient": "/səfɪʃnt/",
        "sugar": "/ʃʊgə(r)/",
        "suggest": "/sədʒest/",
        "suggestion": "/sədʒestʃən/",
        "suicide": "/su:ɪsaɪd/",
        "suit": "/su:t/",
        "summer": "/sʌmə(r)/",
        "summit": "/sʌmɪt/",
        "super": "/su:pə(r)/",
        "supply": "/səplaɪ/",
        "support": "/səpɔ:t/",
        "supporter": "/səpɔ:tə(r)/",
        "suppose": "/səpəʊz/",
        "supposed": "/səpəʊzd/",
        "sure": "/ʃʊə(r)/",
        "surely": "/ʃʊəli/",
        "surface": "/sɜ:fɪs/",
        "surgery": "/sɜ:dʒəri/",
        "surprise": "/səpraɪz/",
        "surprised": "/səpraɪzd/",
        "surprising": "/səpraɪzɪŋ/",
        "surprisingly": "/səpraɪzɪŋli/",
        "surround": "/səraʊnd/",
        "survey": "/sɜ:veɪ/",
        "survival": "/səvaɪvl/",
        "survive": "/səvaɪv/",
        "survivor": "/səvaɪvə(r)/",
        "suspect": "/səspekt/",
        "sustain": "/səsteɪn/",
        "swear": "/sweə(r)/",
        "sweep": "/swi:p/",
        "sweet": "/swi:t/",
        "swim": "/swɪm/",
        "swing": "/swɪŋ/",
        "switch": "/swɪtʃ/",
        "symbol": "/sɪmbl/",
        "symptom": "/sɪmptəm/",
        "system": "/sɪstəm/",
        "table": "/teɪbl/",
        "tablespoon": "/teɪblspu:n/",
        "tactic": "/tæktɪk/",
        "tale": "/teɪl/",
        "take": "/teɪk/",
        "talent": "/tælənt/",
        "talk": "/tɔ:k/",
        "tall": "/tɔ:l/",
        "tank": "/tæŋk/",
        "tap": "/tæp/",
        "tape": "/teɪp/",
        "target": "/tɑ:gɪt/",
        "task": "/tɑ:sk/",
        "taste": "/teɪst/",
        "tax": "/tæks/",
        "taxpayer": "/tækspeɪə(r)/",
        "tea": "/ti:/",
        "teach": "/ti:tʃ/",
        "teacher": "/ti:tʃə(r)/",
        "teaching": "/ti:tʃɪŋ/",
        "team": "/ti:m/",
        "teaspoon": "/ti:spu:n/",
        "technical": "/teknɪkl/",
        "technique": "/tekni:k/",
        "technology": "/teknɒlədʒi/",
        "teenager": "/ti:neɪdʒə(r)/",
        "telephone": "/telɪfəʊn/",
        "telescope": "/telɪskəʊp/",
        "television": "/telɪvɪʒn/",
        "tell": "/tel/",
        "temperature": "/temprətʃə(r)/",
        "temporary": "/temprəri/",
        "ten": "/ten/",
        "tend": "/tend/",
        "tendency": "/tendənsi/",
        "tennis": "/tenɪs/",
        "tension": "/tenʃn/",
        "tent": "/tent/",
        "term": "/tɜ:m/",
        "terms": "/tɜ:mz/",
        "terrible": "/terəbl/",
        "territory": "/terətri/",
        "terror": "/terə(r)/",
        "terrorism": "/terərɪzəm/",
        "terrorist": "/terərɪst/",
        "test": "/test/",
        "testify": "/testɪfaɪ/",
        "testimony": "/testɪməni/",
        "testing": "/testɪŋ/",
        "text": "/tekst/",
        "than": "/ðən/",
        "thank": "/θæŋk/",
        "thanks": "/θæŋks/",
        "that": "/ðæt/",
        "the": "/ðə/",
        "there": "/ðeə(r)/",
        "them": "/ðəm/",
        "theme": "/θi:m/",
        "themselves": "/ðəmselvz/",
        "then": "/ðen/",
        "theory": "/θɪəri/",
        "therapy": "/θerəpi/",
        "therefore": "/ðeəfɔ:(r)/",
        "these": "/ði:z/",
        "they": "/ðeɪ/",
        "thick": "/θɪk/",
        "thin": "/θɪn/",
        "thing": "/θɪŋ/",
        "think": "/θɪŋk/",
        "thinking": "/θɪŋkɪŋ/",
        "third": "/θɜ:d/",
        "thirty": "/θɜ:ti/",
        "this": "/ðɪs/",
        "those": "/ðəʊz/",
        "though": "/ðəʊ/",
        "thought": "/θɔ:t/",
        "thousand": "/θaʊznd/",
        "threat": "/θret/",
        "threaten": "/θretn/",
        "three": "/θri:/",
        "throat": "/θrəʊt/",
        "through": "/θru:/",
        "throughout": "/θru:aʊt/",
        "throw": "/θrəʊ/",
        "thus": "/ðʌs/",
        "ticket": "/tɪkɪt/",
        "tie": "/taɪ/",
        "tight": "/taɪt/",
        "time": "/taɪm/",
        "tiny": "/taɪni/",
        "tip": "/tɪp/",
        "tire": "/taɪə(r)/",
        "tired": "/taɪəd/",
        "tissue": "/tɪʃu:/",
        "title": "/taɪtl/",
        "to": "/tə/",
        "tobacco": "/təbækəʊ/",
        "today": "/tədeɪ/",
        "toe": "/təʊ/",
        "together": "/təgeðə(r)/",
        "tomato": "/təmɑ:təʊ/",
        "tomorrow": "/təmɒrəʊ/",
        "tone": "/təʊn/",
        "tongue": "/tʌŋ/",
        "tonight": "/tənaɪt/",
        "two": "/tu:/",
        "tool": "/tu:l/",
        "tooth": "/tu:θ/",
        "top": "/tɒp/",
        "topic": "/tɒpɪk/",
        "toss": "/tɒs/",
        "total": "/təʊtl/",
        "totally": "/təʊtəli/",
        "touch": "/tʌtʃ/",
        "tough": "/tʌf/",
        "tour": "/tʊə(r)/",
        "tourist": "/tʊərɪst/",
        "tournament": "/tʊənəmənt/",
        "towards": "/təwɔ:dz/",
        "tower": "/taʊə(r)/",
        "town": "/taʊn/",
        "toy": "/tɔɪ/",
        "trace": "/treɪs/",
        "track": "/træk/",
        "trade": "/treɪd/",
        "tradition": "/trədɪʃn/",
        "traditional": "/trədɪʃənl/",
        "traffic": "/træfɪk/",
        "tragedy": "/trædʒədi/",
        "trail": "/treɪl/",
        "train": "/treɪn/",
        "training": "/treɪnɪŋ/",
        "transfer": "/trænsfɜ:(r)/",
        "transform": "/trænsfɔ:m/",
        "transformation": "/trænsfəmeɪʃn/",
        "transition": "/trænzɪʃn/",
        "translate": "/trænzleɪt/",
        "transportation": "/trænspɔ:teɪʃn/",
        "travel": "/trævl/",
        "treat": "/tri:t/",
        "treatment": "/tri:tmənt/",
        "treaty": "/tri:ti/",
        "tree": "/tri:/",
        "tremendous": "/trəmendəs/",
        "trend": "/trend/",
        "trial": "/traɪəl/",
        "tribe": "/traɪb/",
        "trick": "/trɪk/",
        "trip": "/trɪp/",
        "troop": "/tru:p/",
        "trouble": "/trʌbl/",
        "truck": "/trʌk/",
        "true": "/tru:/",
        "truly": "/tru:li/",
        "trust": "/trʌst/",
        "truth": "/tru:θ/",
        "try": "/traɪ/",
        "tube": "/tju:b/",
        "tunnel": "/tʌnl/",
        "turn": "/tɜ:n/",
        "twelve": "/twelv/",
        "twenty": "/twenti/",
        "twice": "/twaɪs/",
        "twin": "/twɪn/",
        "type": "/taɪp/",
        "typical": "/tɪpɪkl/",
        "typically": "/tɪpɪkli/",
        "ugly": "/ʌgli/",
        "ultimate": "/ʌltɪmət/",
        "ultimately": "/ʌltɪmətli/",
        "unable": "/ʌneɪbl/",
        "uncle": "/ʌŋkl/",
        "under": "/ʌndə(r)/",
        "undergo": "/ʌndəgəʊ/",
        "understand": "/ʌndəstænd/",
        "understanding": "/ʌndəstændɪŋ/",
        "unfortunately": "/ʌnfɔ:tʃənətli/",
        "uniform": "/ju:nɪfɔ:m/",
        "union": "/ju:niən/",
        "unique": "/juni:k/",
        "unit": "/ju:nɪt/",
        "universal": "/ju:nɪvɜ:sl/",
        "universe": "/ju:nɪvɜ:s/",
        "university": "/ju:nɪvɜ:səti/",
        "unknown": "/ʌnnəʊn/",
        "unless": "/ənles/",
        "unlike": "/ʌnlaɪk/",
        "unlikely": "/ʌnlaɪkli/",
        "until": "/əntɪl/",
        "unusual": "/ʌnju:ʒuəl/",
        "up": "/ʌp/",
        "upon": "/əpɒn/",
        "upper": "/ʌpə(r)/",
        "urban": "/ɜ:bən/",
        "urge": "/ɜ:dʒ/",
        "us": "/əs/",
        "use": "/ju:z/",
        "useful": "/ju:sfl/",
        "user": "/ju:zə(r)/",
        "usual": "/ju:ʒuəl/",
        "usually": "/ju:ʒuəli/",
        "utility": "/ju:tɪləti/",
        "vacation": "/veɪkeɪʃn/",
        "valley": "/væli/",
        "valuable": "/væljuəbl/",
        "value": "/vælju:/",
        "variable": "/veəriəbl/",
        "variation": "/veərieɪʃn/",
        "variety": "/vəraɪəti/",
        "various": "/veəriəs/",
        "vary": "/veəri/",
        "vast": "/vɑ:st/",
        "vegetable": "/vedʒtəbl/",
        "vehicle": "/vi:əkl/",
        "venture": "/ventʃə(r)/",
        "version": "/vɜ:ʃn/",
        "versus": "/vɜ:səs/",
        "very": "/veri/",
        "vessel": "/vesl/",
        "veteran": "/vetərən/",
        "via": "/vaɪə/",
        "victim": "/vɪktɪm/",
        "victory": "/vɪktəri/",
        "video": "/vɪdiəʊ/",
        "view": "/vju:/",
        "viewer": "/vju:ə(r)/",
        "village": "/vɪlɪdʒ/",
        "violate": "/vaɪəleɪt/",
        "violation": "/vaɪəleɪʃn/",
        "violence": "/vaɪələns/",
        "violent": "/vaɪələnt/",
        "virtually": "/vɜ:tʃuəli/",
        "virtue": "/vɜ:tʃu:/",
        "virus": "/vaɪrəs/",
        "visible": "/vɪzəbl/",
        "vision": "/vɪʒn/",
        "visit": "/vɪzɪt/",
        "visitor": "/vɪzɪtə(r)/",
        "visual": "/vɪʒuəl/",
        "vital": "/vaɪtl/",
        "voice": "/vɔɪs/",
        "volume": "/vɒlju:m/",
        "volunteer": "/vɒləntɪə(r)/",
        "vote": "/vəʊt/",
        "voter": "/vəʊtə(r)/",
        "vulnerable": "/vʌlnərəbl/",
        "wage": "/weɪdʒ/",
        "weight": "/weɪt/",
        "wake": "/weɪk/",
        "walk": "/wɔ:k/",
        "wall": "/wɔ:l/",
        "wander": "/wɒndə(r)/",
        "want": "/wɒnt/",
        "war": "/wɔ:(r)/",
        "warm": "/wɔ:m/",
        "warn": "/wɔ:n/",
        "warning": "/wɔ:nɪŋ/",
        "wash": "/wɒʃ/",
        "waste": "/weɪst/",
        "watch": "/wɒtʃ/",
        "water": "/wɔ:tə(r)/",
        "wave": "/weɪv/",
        "weigh": "/weɪ/",
        "we": "/wi/",
        "week": "/wi:k/",
        "wealth": "/welθ/",
        "wealthy": "/welθi/",
        "weapon": "/wepən/",
        "where": "/weə(r)/",
        "whether": "/weðə(r)/",
        "wedding": "/wedɪŋ/",
        "weekend": "/wi:kend/",
        "weekly": "/wi:kli/",
        "welcome": "/welkəm/",
        "welfare": "/welfeə(r)/",
        "well": "/wel/",
        "west": "/west/",
        "western": "/westən/",
        "wet": "/wet/",
        "what": "/wɒt/",
        "whatever": "/wɒtevə(r)/",
        "wheel": "/wi:l/",
        "when": "/wen/",
        "whenever": "/wenevə(r)/",
        "whereas": "/weəræz/",
        "which": "/wɪtʃ/",
        "while": "/waɪl/",
        "whisper": "/wɪspə(r)/",
        "white": "/waɪt/",
        "who": "/hu:/",
        "whom": "/hu:m/",
        "whose": "/hu:z/",
        "why": "/waɪ/",
        "wide": "/waɪd/",
        "widely": "/waɪdli/",
        "widespread": "/waɪdspred/",
        "wife": "/waɪf/",
        "wild": "/waɪld/",
        "will": "/wɪl/",
        "willing": "/wɪlɪŋ/",
        "win": "/wɪn/",
        "window": "/wɪndəʊ/",
        "wine": "/waɪn/",
        "wing": "/wɪŋ/",
        "winner": "/wɪnə(r)/",
        "winter": "/wɪntə(r)/",
        "wipe": "/waɪp/",
        "wire": "/waɪə(r)/",
        "wisdom": "/wɪzdəm/",
        "wise": "/waɪz/",
        "wish": "/wɪʃ/",
        "with": "/wɪð/",
        "withdraw": "/wɪðdrɔ:/",
        "within": "/wɪðɪn/",
        "without": "/wɪðaʊt/",
        "witness": "/wɪtnəs/",
        "woman": "/wʊmən/",
        "wonder": "/wʌndə(r)/",
        "wonderful": "/wʌndəfl/",
        "would": "/wʊd/",
        "wooden": "/wʊdn/",
        "word": "/wɜ:d/",
        "work": "/wɜ:k/",
        "worker": "/wɜ:kə(r)/",
        "working": "/wɜ:kɪŋ/",
        "works": "/wɜ:ks/",
        "workshop": "/wɜ:kʃɒp/",
        "world": "/wɜ:ld/",
        "worried": "/wʌrid/",
        "worry": "/wʌri/",
        "worth": "/wɜ:θ/",
        "wrap": "/ræp/",
        "writer": "/raɪtə(r)/",
        "writing": "/raɪtɪŋ/",
        "wrong": "/rɒŋ/",
        "yard": "/jɑ:d/",
        "yeah": "/jeə/",
        "year": "/jɪə(r)/",
        "yell": "/jel/",
        "yellow": "/jeləʊ/",
        "yes": "/jes/",
        "yesterday": "/jestədeɪ/",
        "yet": "/jet/",
        "yield": "/ji:ld/",
        "you": "/ju/",
        "young": "/jʌŋ/",
        "your": "/jɔ:(r)/",
        "yours": "/jɔ:z/",
        "yourself": "/jɔ:self/",
        "youth": "/ju:θ/",
        "zone": "/zəʊn/"
    }

}


export function find_words_containing(containing_letters)
{
  const  word_list = ["abandon",
    "ability",
    "able",
    "abortion",
    "about",
    "above",
    "abroad",
    "absence",
    "absolute",
    "absolutely",
    "absorb",
    "abuse",
    "academic",
    "accept",
    "access",
    "accident",
    "accompany",
    "accomplish",
    "according",
    "account",
    "accurate",
    "accuse",
    "achieve",
    "achievement",
    "acid",
    "acknowledge",
    "acquire",
    "across",
    "act",
    "action",
    "active",
    "activist",
    "activity",
    "actor",
    "actress",
    "actual",
    "actually",
    "ad",
    "adapt",
    "add",
    "addition",
    "additional",
    "address",
    "adequate",
    "adjust",
    "adjustment",
    "administration",
    "administrator",
    "admire",
    "admission",
    "admit",
    "adolescent",
    "adopt",
    "adult",
    "advance",
    "advanced",
    "advantage",
    "adventure",
    "advertising",
    "advice",
    "advise",
    "adviser",
    "advocate",
    "affair",
    "affect",
    "afford",
    "afraid",
    "African",
    "African-American",
    "after",
    "afternoon",
    "again",
    "against",
    "age",
    "agency",
    "agenda",
    "agent",
    "aggressive",
    "ago",
    "agree",
    "agreement",
    "agricultural",
    "ah",
    "ahead",
    "aid",
    "aide",
    "AIDS",
    "aim",
    "air",
    "aircraft",
    "airline",
    "airport",
    "album",
    "alcohol",
    "alive",
    "all",
    "alliance",
    "allow",
    "ally",
    "almost",
    "alone",
    "along",
    "already",
    "also",
    "alter",
    "alternative",
    "although",
    "always",
    "AM",
    "amazing",
    "American",
    "among",
    "amount",
    "analysis",
    "analyst",
    "analyze",
    "ancient",
    "and",
    "anger",
    "angle",
    "angry",
    "animal",
    "anniversary",
    "announce",
    "annual",
    "another",
    "answer",
    "anticipate",
    "anxiety",
    "any",
    "anybody",
    "anymore",
    "anyone",
    "anything",
    "anyway",
    "anywhere",
    "apart",
    "apartment",
    "apparent",
    "apparently",
    "appeal",
    "appear",
    "appearance",
    "apple",
    "application",
    "apply",
    "appoint",
    "appointment",
    "appreciate",
    "approach",
    "appropriate",
    "approval",
    "approve",
    "approximately",
    "Arab",
    "architect",
    "area",
    "argue",
    "argument",
    "arise",
    "arm",
    "armed",
    "army",
    "around",
    "arrange",
    "arrangement",
    "arrest",
    "arrival",
    "arrive",
    "art",
    "article",
    "artist",
    "artistic",
    "as",
    "Asian",
    "aside",
    "ask",
    "asleep",
    "aspect",
    "assault",
    "assert",
    "assess",
    "assessment",
    "asset",
    "assign",
    "assignment",
    "assist",
    "assistance",
    "assistant",
    "associate",
    "association",
    "assume",
    "assumption",
    "assure",
    "at",
    "athlete",
    "athletic",
    "atmosphere",
    "attach",
    "attack",
    "attempt",
    "attend",
    "attention",
    "attitude",
    "attorney",
    "attract",
    "attractive",
    "attribute",
    "audience",
    "author",
    "authority",
    "auto",
    "available",
    "average",
    "avoid",
    "award",
    "aware",
    "awareness",
    "away",
    "awful",
    "baby",
    "back",
    "background",
    "bad",
    "badly",
    "bag",
    "bake",
    "balance",
    "ball",
    "ban",
    "band",
    "bank",
    "bar",
    "barely",
    "barrel",
    "barrier",
    "base",
    "baseball",
    "basic",
    "basically",
    "basis",
    "basket",
    "basketball",
    "bathroom",
    "battery",
    "battle",
    "be",
    "beach",
    "bean",
    "bear",
    "beat",
    "beautiful",
    "beauty",
    "because",
    "become",
    "bed",
    "bedroom",
    "beer",
    "before",
    "begin",
    "beginning",
    "behavior",
    "behind",
    "being",
    "belief",
    "believe",
    "bell",
    "belong",
    "below",
    "belt",
    "bench",
    "bend",
    "beneath",
    "benefit",
    "beside",
    "besides",
    "best",
    "bet",
    "better",
    "between",
    "beyond",
    "Bible",
    "big",
    "bike",
    "bill",
    "billion",
    "bind",
    "biological",
    "bird",
    "birth",
    "birthday",
    "bit",
    "bite",
    "black",
    "blade",
    "blame",
    "blanket",
    "blind",
    "block",
    "blood",
    "blow",
    "blue",
    "board",
    "boat",
    "body",
    "bomb",
    "bombing",
    "bond",
    "bone",
    "book",
    "boom",
    "boot",
    "border",
    "born",
    "borrow",
    "boss",
    "both",
    "bother",
    "bottle",
    "bottom",
    "boundary",
    "bowl",
    "box",
    "boy",
    "boyfriend",
    "brain",
    "branch",
    "brand",
    "bread",
    "break",
    "breakfast",
    "breast",
    "breath",
    "breathe",
    "brick",
    "bridge",
    "brief",
    "briefly",
    "bright",
    "brilliant",
    "bring",
    "British",
    "broad",
    "broken",
    "brother",
    "brown",
    "brush",
    "buck",
    "budget",
    "build",
    "building",
    "bullet",
    "bunch",
    "burden",
    "burn",
    "bury",
    "bus",
    "business",
    "busy",
    "but",
    "butter",
    "button",
    "buy",
    "buyer",
    "by",
    "cabin",
    "cabinet",
    "cable",
    "cake",
    "calculate",
    "call",
    "camera",
    "camp",
    "campaign",
    "campus",
    "can",
    "Canadian",
    "cancer",
    "candidate",
    "cap",
    "capability",
    "capable",
    "capacity",
    "capital",
    "captain",
    "capture",
    "car",
    "carbon",
    "card",
    "care",
    "career",
    "careful",
    "carefully",
    "carrier",
    "carry",
    "case",
    "cash",
    "cast",
    "cat",
    "catch",
    "category",
    "Catholic",
    "cause",
    "ceiling",
    "celebrate",
    "celebration",
    "celebrity",
    "cell",
    "center",
    "central",
    "century",
    "ceremony",
    "certain",
    "certainly",
    "chain",
    "chair",
    "chairman",
    "challenge",
    "chamber",
    "champion",
    "championship",
    "chance",
    "change",
    "changing",
    "channel",
    "chapter",
    "character",
    "characteristic",
    "characterize",
    "charge",
    "charity",
    "chart",
    "chase",
    "cheap",
    "check",
    "cheek",
    "cheese",
    "chef",
    "chemical",
    "chest",
    "chicken",
    "chief",
    "child",
    "childhood",
    "Chinese",
    "chip",
    "chocolate",
    "choice",
    "cholesterol",
    "choose",
    "Christian",
    "Christmas",
    "church",
    "cigarette",
    "circle",
    "circumstance",
    "cite",
    "citizen",
    "city",
    "civil",
    "civilian",
    "claim",
    "class",
    "classic",
    "classroom",
    "clean",
    "clear",
    "clearly",
    "client",
    "climate",
    "climb",
    "clinic",
    "clinical",
    "clock",
    "close",
    "closely",
    "closer",
    "clothes",
    "clothing",
    "cloud",
    "club",
    "clue",
    "cluster",
    "coach",
    "coal",
    "coalition",
    "coast",
    "coat",
    "code",
    "coffee",
    "cognitive",
    "cold",
    "collapse",
    "colleague",
    "collect",
    "collection",
    "collective",
    "college",
    "colonial",
    "color",
    "column",
    "combination",
    "combine",
    "come",
    "comedy",
    "comfort",
    "comfortable",
    "command",
    "commander",
    "comment",
    "commercial",
    "commission",
    "commit",
    "commitment",
    "committee",
    "common",
    "communicate",
    "communication",
    "community",
    "company",
    "compare",
    "comparison",
    "compete",
    "competition",
    "competitive",
    "competitor",
    "complain",
    "complaint",
    "complete",
    "completely",
    "complex",
    "complicated",
    "component",
    "compose",
    "composition",
    "comprehensive",
    "computer",
    "concentrate",
    "concentration",
    "concept",
    "concern",
    "concerned",
    "concert",
    "conclude",
    "conclusion",
    "concrete",
    "condition",
    "conduct",
    "conference",
    "confidence",
    "confident",
    "confirm",
    "conflict",
    "confront",
    "confusion",
    "Congress",
    "congressional",
    "connect",
    "connection",
    "consciousness",
    "consensus",
    "consequence",
    "conservative",
    "consider",
    "considerable",
    "consideration",
    "consist",
    "consistent",
    "constant",
    "constantly",
    "constitute",
    "constitutional",
    "construct",
    "construction",
    "consultant",
    "consume",
    "consumer",
    "consumption",
    "contact",
    "contain",
    "container",
    "contemporary",
    "content",
    "contest",
    "context",
    "continue",
    "continued",
    "contract",
    "contrast",
    "contribute",
    "contribution",
    "control",
    "controversial",
    "controversy",
    "convention",
    "conventional",
    "conversation",
    "convert",
    "conviction",
    "convince",
    "cook",
    "cookie",
    "cooking",
    "cool",
    "cooperation",
    "cop",
    "cope",
    "copy",
    "core",
    "corn",
    "corner",
    "corporate",
    "corporation",
    "correct",
    "correspondent",
    "cost",
    "cotton",
    "couch",
    "could",
    "council",
    "counselor",
    "count",
    "counter",
    "country",
    "county",
    "couple",
    "courage",
    "course",
    "court",
    "cousin",
    "cover",
    "coverage",
    "cow",
    "crack",
    "craft",
    "crash",
    "crazy",
    "cream",
    "create",
    "creation",
    "creative",
    "creature",
    "credit",
    "crew",
    "crime",
    "criminal",
    "crisis",
    "criteria",
    "critic",
    "critical",
    "criticism",
    "criticize",
    "crop",
    "cross",
    "crowd",
    "crucial",
    "cry",
    "cultural",
    "culture",
    "cup",
    "curious",
    "current",
    "currently",
    "curriculum",
    "custom",
    "customer",
    "cut",
    "cycle",
    "dad",
    "daily",
    "damage",
    "dance",
    "danger",
    "dangerous",
    "dare",
    "dark",
    "darkness",
    "data",
    "date",
    "daughter",
    "day",
    "dead",
    "deal",
    "dealer",
    "dear",
    "death",
    "debate",
    "debt",
    "decade",
    "decide",
    "decision",
    "deck",
    "declare",
    "decline",
    "decrease",
    "deep",
    "deeply",
    "deer",
    "defeat",
    "defend",
    "defendant",
    "defense",
    "defensive",
    "deficit",
    "define",
    "definitely",
    "definition",
    "degree",
    "delay",
    "deliver",
    "delivery",
    "demand",
    "democracy",
    "Democrat",
    "democratic",
    "demonstrate",
    "demonstration",
    "deny",
    "department",
    "depend",
    "dependent",
    "depending",
    "depict",
    "depression",
    "depth",
    "deputy",
    "derive",
    "describe",
    "description",
    "desert",
    "deserve",
    "design",
    "designer",
    "desire",
    "desk",
    "desperate",
    "despite",
    "destroy",
    "destruction",
    "detail",
    "detailed",
    "detect",
    "determine",
    "develop",
    "developing",
    "development",
    "device",
    "devote",
    "dialogue",
    "die",
    "diet",
    "differ",
    "difference",
    "different",
    "differently",
    "difficult",
    "difficulty",
    "dig",
    "digital",
    "dimension",
    "dining",
    "dinner",
    "direct",
    "direction",
    "directly",
    "director",
    "dirt",
    "dirty",
    "disability",
    "disagree",
    "disappear",
    "disaster",
    "discipline",
    "discourse",
    "discover",
    "discovery",
    "discrimination",
    "discuss",
    "discussion",
    "disease",
    "dish",
    "dismiss",
    "disorder",
    "display",
    "dispute",
    "distance",
    "distant",
    "distinct",
    "distinction",
    "distinguish",
    "distribute",
    "distribution",
    "district",
    "diverse",
    "diversity",
    "divide",
    "division",
    "divorce",
    "DNA",
    "do",
    "doctor",
    "document",
    "dog",
    "domestic",
    "dominant",
    "dominate",
    "door",
    "double",
    "doubt",
    "down",
    "downtown",
    "dozen",
    "draft",
    "drag",
    "drama",
    "dramatic",
    "dramatically",
    "draw",
    "drawing",
    "dream",
    "dress",
    "drink",
    "drive",
    "driver",
    "drop",
    "drug",
    "dry",
    "due",
    "during",
    "dust",
    "duty",
    "each",
    "eager",
    "ear",
    "early",
    "earn",
    "earnings",
    "earth",
    "ease",
    "easily",
    "east",
    "eastern",
    "easy",
    "eat",
    "economic",
    "economics",
    "economist",
    "economy",
    "edge",
    "edition",
    "editor",
    "educate",
    "education",
    "educational",
    "educator",
    "effect",
    "effective",
    "effectively",
    "efficiency",
    "efficient",
    "effort",
    "egg",
    "eight",
    "either",
    "elderly",
    "elect",
    "election",
    "electric",
    "electricity",
    "electronic",
    "element",
    "elementary",
    "eliminate",
    "elite",
    "else",
    "elsewhere",
    "e-mail",
    "embrace",
    "emerge",
    "emergency",
    "emission",
    "emotion",
    "emotional",
    "emphasis",
    "emphasize",
    "employ",
    "employee",
    "employer",
    "employment",
    "empty",
    "enable",
    "encounter",
    "encourage",
    "end",
    "enemy",
    "energy",
    "enforcement",
    "engage",
    "engine",
    "engineer",
    "engineering",
    "English",
    "enhance",
    "enjoy",
    "enormous",
    "enough",
    "ensure",
    "enter",
    "enterprise",
    "entertainment",
    "entire",
    "entirely",
    "entrance",
    "entry",
    "environment",
    "environmental",
    "episode",
    "equal",
    "equally",
    "equipment",
    "era",
    "error",
    "escape",
    "especially",
    "essay",
    "essential",
    "essentially",
    "establish",
    "establishment",
    "estate",
    "estimate",
    "etc",
    "ethics",
    "ethnic",
    "European",
    "evaluate",
    "evaluation",
    "even",
    "evening",
    "event",
    "eventually",
    "ever",
    "every",
    "everybody",
    "everyday",
    "everyone",
    "everything",
    "everywhere",
    "evidence",
    "evolution",
    "evolve",
    "exact",
    "exactly",
    "examination",
    "examine",
    "example",
    "exceed",
    "excellent",
    "except",
    "exception",
    "exchange",
    "exciting",
    "executive",
    "exercise",
    "exhibit",
    "exhibition",
    "exist",
    "existence",
    "existing",
    "expand",
    "expansion",
    "expect",
    "expectation",
    "expense",
    "expensive",
    "experience",
    "experiment",
    "expert",
    "explain",
    "explanation",
    "explode",
    "explore",
    "explosion",
    "expose",
    "exposure",
    "express",
    "expression",
    "extend",
    "extension",
    "extensive",
    "extent",
    "external",
    "extra",
    "extraordinary",
    "extreme",
    "extremely",
    "eye",
    "fabric",
    "face",
    "facility",
    "fact",
    "factor",
    "factory",
    "faculty",
    "fade",
    "fail",
    "failure",
    "fair",
    "fairly",
    "faith",
    "fall",
    "false",
    "familiar",
    "family",
    "famous",
    "fan",
    "fantasy",
    "far",
    "farm",
    "farmer",
    "fashion",
    "fast",
    "fat",
    "fate",
    "father",
    "fault",
    "favor",
    "favorite",
    "fear",
    "feature",
    "federal",
    "fee",
    "feed",
    "feel",
    "feeling",
    "fellow",
    "female",
    "fence",
    "few",
    "fewer",
    "fiber",
    "fiction",
    "field",
    "fifteen",
    "fifth",
    "fifty",
    "fight",
    "fighter",
    "fighting",
    "figure",
    "file",
    "fill",
    "film",
    "final",
    "finally",
    "finance",
    "financial",
    "find",
    "finding",
    "fine",
    "finger",
    "finish",
    "fire",
    "firm",
    "first",
    "fish",
    "fishing",
    "fit",
    "fitness",
    "five",
    "fix",
    "flag",
    "flame",
    "flat",
    "flavor",
    "flee",
    "flesh",
    "flight",
    "float",
    "floor",
    "flow",
    "flower",
    "fly",
    "focus",
    "folk",
    "follow",
    "following",
    "food",
    "foot",
    "football",
    "for",
    "force",
    "foreign",
    "forest",
    "forever",
    "forget",
    "form",
    "formal",
    "formation",
    "former",
    "formula",
    "forth",
    "fortune",
    "forward",
    "found",
    "foundation",
    "founder",
    "four",
    "fourth",
    "frame",
    "framework",
    "free",
    "freedom",
    "freeze",
    "French",
    "frequency",
    "frequent",
    "frequently",
    "fresh",
    "friend",
    "friendly",
    "friendship",
    "from",
    "front",
    "fruit",
    "frustration",
    "fuel",
    "full",
    "fully",
    "fun",
    "function",
    "fund",
    "fundamental",
    "funding",
    "funeral",
    "funny",
    "furniture",
    "furthermore",
    "future",
    "gain",
    "galaxy",
    "gallery",
    "game",
    "gang",
    "gap",
    "garage",
    "garden",
    "garlic",
    "gas",
    "gate",
    "gather",
    "gay",
    "gaze",
    "gear",
    "gender",
    "gene",
    "general",
    "generally",
    "generate",
    "generation",
    "genetic",
    "gentleman",
    "gently",
    "German",
    "gesture",
    "get",
    "ghost",
    "giant",
    "gift",
    "gifted",
    "girl",
    "girlfriend",
    "give",
    "given",
    "glad",
    "glance",
    "glass",
    "global",
    "glove",
    "go",
    "goal",
    "God",
    "gold",
    "golden",
    "golf",
    "good",
    "government",
    "governor",
    "grab",
    "grade",
    "gradually",
    "graduate",
    "grain",
    "grand",
    "grandfather",
    "grandmother",
    "grant",
    "grass",
    "grave",
    "gray",
    "great",
    "greatest",
    "green",
    "grocery",
    "ground",
    "group",
    "grow",
    "growing",
    "growth",
    "guarantee",
    "guard",
    "guess",
    "guest",
    "guide",
    "guideline",
    "guilty",
    "gun",
    "guy",
    "habit",
    "habitat",
    "hair",
    "half",
    "hall",
    "hand",
    "handful",
    "handle",
    "hang",
    "happen",
    "happy",
    "hard",
    "hardly",
    "hat",
    "hate",
    "have",
    "he",
    "head",
    "headline",
    "headquarters",
    "health",
    "healthy",
    "hear",
    "hearing",
    "heart",
    "heat",
    "heaven",
    "heavily",
    "heavy",
    "heel",
    "height",
    "helicopter",
    "hell",
    "hello",
    "help",
    "helpful",
    "her",
    "here",
    "heritage",
    "hero",
    "herself",
    "hey",
    "hi",
    "hide",
    "high",
    "highlight",
    "highly",
    "highway",
    "hill",
    "him",
    "himself",
    "hip",
    "hire",
    "his",
    "historian",
    "historic",
    "historical",
    "history",
    "hit",
    "hold",
    "hole",
    "holiday",
    "holy",
    "home",
    "homeless",
    "honest",
    "honey",
    "honor",
    "hope",
    "horizon",
    "horror",
    "horse",
    "hospital",
    "host",
    "hot",
    "hotel",
    "hour",
    "house",
    "household",
    "housing",
    "how",
    "however",
    "huge",
    "human",
    "humor",
    "hundred",
    "hungry",
    "hunter",
    "hunting",
    "hurt",
    "husband",
    "hypothesis",
    "I",
    "ice",
    "idea",
    "ideal",
    "identification",
    "identify",
    "identity",
    "ie",
    "if",
    "ignore",
    "ill",
    "illegal",
    "illness",
    "illustrate",
    "image",
    "imagination",
    "imagine",
    "immediate",
    "immediately",
    "immigrant",
    "immigration",
    "impact",
    "implement",
    "implication",
    "imply",
    "importance",
    "important",
    "impose",
    "impossible",
    "impress",
    "impression",
    "impressive",
    "improve",
    "improvement",
    "in",
    "incentive",
    "incident",
    "include",
    "including",
    "income",
    "incorporate",
    "increase",
    "increased",
    "increasing",
    "increasingly",
    "incredible",
    "indeed",
    "independence",
    "independent",
    "index",
    "Indian",
    "indicate",
    "indication",
    "individual",
    "industrial",
    "industry",
    "infant",
    "infection",
    "inflation",
    "influence",
    "inform",
    "information",
    "ingredient",
    "initial",
    "initially",
    "initiative",
    "injury",
    "inner",
    "innocent",
    "inquiry",
    "inside",
    "insight",
    "insist",
    "inspire",
    "install",
    "instance",
    "instead",
    "institution",
    "institutional",
    "instruction",
    "instructor",
    "instrument",
    "insurance",
    "intellectual",
    "intelligence",
    "intend",
    "intense",
    "intensity",
    "intention",
    "interaction",
    "interest",
    "interested",
    "interesting",
    "internal",
    "international",
    "Internet",
    "interpret",
    "interpretation",
    "intervention",
    "interview",
    "into",
    "introduce",
    "introduction",
    "invasion",
    "invest",
    "investigate",
    "investigation",
    "investigator",
    "investment",
    "investor",
    "invite",
    "involve",
    "involved",
    "involvement",
    "Iraqi",
    "Irish",
    "iron",
    "Islamic",
    "island",
    "Israeli",
    "issue",
    "it",
    "Italian",
    "item",
    "its",
    "itself",
    "jacket",
    "jail",
    "Japanese",
    "jet",
    "Jew",
    "Jewish",
    "job",
    "join",
    "joint",
    "joke",
    "journal",
    "journalist",
    "journey",
    "joy",
    "judge",
    "judgment",
    "juice",
    "jump",
    "junior",
    "jury",
    "just",
    "justice",
    "justify",
    "keep",
    "key",
    "kick",
    "kid",
    "kill",
    "killer",
    "killing",
    "kind",
    "king",
    "kiss",
    "kitchen",
    "knee",
    "knife",
    "knock",
    "know",
    "knowledge",
    "lab",
    "label",
    "labor",
    "laboratory",
    "lack",
    "lady",
    "lake",
    "land",
    "landscape",
    "language",
    "lap",
    "large",
    "largely",
    "last",
    "late",
    "later",
    "Latin",
    "latter",
    "laugh",
    "launch",
    "law",
    "lawn",
    "lawsuit",
    "lawyer",
    "lay",
    "layer",
    "lead",
    "leader",
    "leadership",
    "leading",
    "leaf",
    "league",
    "lean",
    "learn",
    "learning",
    "least",
    "leather",
    "leave",
    "left",
    "leg",
    "legacy",
    "legal",
    "legend",
    "legislation",
    "legitimate",
    "lemon",
    "length",
    "less",
    "lesson",
    "let",
    "letter",
    "level",
    "liberal",
    "library",
    "license",
    "lie",
    "life",
    "lifestyle",
    "lifetime",
    "lift",
    "light",
    "like",
    "likely",
    "limit",
    "limitation",
    "limited",
    "line",
    "link",
    "lip",
    "list",
    "listen",
    "literally",
    "literary",
    "literature",
    "little",
    "live",
    "living",
    "load",
    "loan",
    "local",
    "locate",
    "location",
    "lock",
    "long",
    "long-term",
    "look",
    "loose",
    "lose",
    "loss",
    "lost",
    "lot",
    "lots",
    "loud",
    "love",
    "lovely",
    "lover",
    "low",
    "lower",
    "luck",
    "lucky",
    "lunch",
    "lung",
    "machine",
    "mad",
    "magazine",
    "mail",
    "main",
    "mainly",
    "maintain",
    "maintenance",
    "maɪor",
    "maɪority",
    "make",
    "maker",
    "makeup",
    "male",
    "mall",
    "man",
    "manage",
    "management",
    "manager",
    "manner",
    "manufacturer",
    "manufacturing",
    "many",
    "map",
    "margin",
    "mark",
    "market",
    "marketing",
    "marriage",
    "married",
    "marry",
    "mask",
    "mass",
    "massive",
    "master",
    "match",
    "material",
    "math",
    "matter",
    "may",
    "maybe",
    "mayor",
    "me",
    "meal",
    "mean",
    "meaning",
    "meanwhile",
    "measure",
    "measurement",
    "meat",
    "mechanism",
    "media",
    "medical",
    "medication",
    "medicine",
    "medium",
    "meet",
    "meeting",
    "member",
    "membership",
    "memory",
    "mental",
    "mention",
    "menu",
    "mere",
    "merely",
    "mess",
    "message",
    "metal",
    "meter",
    "method",
    "Mexican",
    "middle",
    "might",
    "military",
    "milk",
    "million",
    "mind",
    "mine",
    "minister",
    "minor",
    "minority",
    "minute",
    "miracle",
    "mirror",
    "miss",
    "missile",
    "mission",
    "mistake",
    "mix",
    "mixture",
    "mm-hmm",
    "mode",
    "model",
    "moderate",
    "modern",
    "modest",
    "mom",
    "moment",
    "money",
    "monitor",
    "month",
    "mood",
    "moon",
    "moral",
    "more",
    "moreover",
    "morning",
    "mortgage",
    "most",
    "mostly",
    "mother",
    "motion",
    "motivation",
    "motor",
    "mount",
    "mountain",
    "mouse",
    "mouth",
    "move",
    "movement",
    "movie",
    "Mr",
    "Mrs",
    "Ms",
    "much",
    "multiple",
    "murder",
    "muscle",
    "museum",
    "music",
    "musical",
    "musician",
    "Muslim",
    "must",
    "mutual",
    "my",
    "myself",
    "mystery",
    "myth",
    "naked",
    "name",
    "narrative",
    "narrow",
    "nation",
    "national",
    "native",
    "natural",
    "naturally",
    "nature",
    "near",
    "nearby",
    "nearly",
    "necessarily",
    "necessary",
    "neck",
    "need",
    "negative",
    "negotiate",
    "negotiation",
    "neighbor",
    "neighborhood",
    "neither",
    "nerve",
    "nervous",
    "net",
    "network",
    "never",
    "nevertheless",
    "new",
    "newly",
    "news",
    "newspaper",
    "next",
    "nice",
    "night",
    "nine",
    "no",
    "nobody",
    "nod",
    "noise",
    "nomination",
    "none",
    "nonetheless",
    "nor",
    "normal",
    "normally",
    "north",
    "northern",
    "nose",
    "not",
    "note",
    "nothing",
    "notice",
    "notion",
    "novel",
    "now",
    "nowhere",
    "n't",
    "nuclear",
    "number",
    "numerous",
    "nurse",
    "nut",
    "object",
    "objective",
    "obligation",
    "observation",
    "observe",
    "observer",
    "obtain",
    "obvious",
    "obviously",
    "occasion",
    "occasionally",
    "occupation",
    "occupy",
    "occur",
    "ocean",
    "odd",
    "odds",
    "of",
    "off",
    "offense",
    "offensive",
    "offer",
    "office",
    "officer",
    "official",
    "often",
    "oh",
    "oil",
    "ok",
    "okay",
    "old",
    "Olympic",
    "on",
    "once",
    "one",
    "ongoing",
    "onion",
    "online",
    "only",
    "onto",
    "open",
    "opening",
    "operate",
    "operating",
    "operation",
    "operator",
    "opinion",
    "opponent",
    "opportunity",
    "oppose",
    "opposite",
    "opposition",
    "option",
    "or",
    "orange",
    "order",
    "ordinary",
    "organic",
    "organization",
    "organize",
    "orientation",
    "origin",
    "original",
    "originally",
    "other",
    "others",
    "otherwise",
    "ought",
    "our",
    "ourselves",
    "out",
    "outcome",
    "outside",
    "oven",
    "over",
    "overall",
    "overcome",
    "overlook",
    "owe",
    "own",
    "owner",
    "pace",
    "pack",
    "package",
    "page",
    "pain",
    "painful",
    "paint",
    "painter",
    "painting",
    "pair",
    "pale",
    "Palestinian",
    "palm",
    "pan",
    "panel",
    "pant",
    "paper",
    "parent",
    "park",
    "parking",
    "part",
    "participant",
    "participate",
    "participation",
    "particular",
    "particularly",
    "partly",
    "partner",
    "partnership",
    "party",
    "pass",
    "passage",
    "passenger",
    "passion",
    "past",
    "patch",
    "path",
    "patient",
    "pattern",
    "pause",
    "pay",
    "payment",
    "PC",
    "peace",
    "peak",
    "peer",
    "penalty",
    "people",
    "pepper",
    "per",
    "perceive",
    "percentage",
    "perception",
    "perfect",
    "perfectly",
    "perform",
    "performance",
    "perhaps",
    "period",
    "permanent",
    "permission",
    "permit",
    "person",
    "personal",
    "personality",
    "personally",
    "personnel",
    "perspective",
    "persuade",
    "pet",
    "phase",
    "phenomenon",
    "philosophy",
    "phone",
    "photo",
    "photograph",
    "photographer",
    "phrase",
    "physical",
    "physically",
    "physician",
    "piano",
    "pick",
    "picture",
    "pie",
    "piece",
    "pile",
    "pilot",
    "pine",
    "pink",
    "pipe",
    "pitch",
    "place",
    "plan",
    "plane",
    "planet",
    "planning",
    "plant",
    "plastic",
    "plate",
    "platform",
    "play",
    "player",
    "please",
    "pleasure",
    "plenty",
    "plot",
    "plus",
    "PM",
    "pocket",
    "poem",
    "poet",
    "poetry",
    "point",
    "pole",
    "police",
    "policy",
    "political",
    "politically",
    "politician",
    "politics",
    "poll",
    "pollution",
    "pool",
    "poor",
    "pop",
    "popular",
    "population",
    "porch",
    "port",
    "portion",
    "portrait",
    "portray",
    "pose",
    "position",
    "positive",
    "possess",
    "possibility",
    "possible",
    "possibly",
    "post",
    "pot",
    "potato",
    "potential",
    "potentially",
    "pound",
    "pour",
    "poverty",
    "powder",
    "power",
    "powerful",
    "practical",
    "practice",
    "pray",
    "prayer",
    "precisely",
    "predict",
    "prefer",
    "preference",
    "pregnancy",
    "pregnant",
    "preparation",
    "prepare",
    "prescription",
    "presence",
    "present",
    "presentation",
    "preserve",
    "president",
    "presidential",
    "press",
    "pressure",
    "pretend",
    "pretty",
    "prevent",
    "previous",
    "previously",
    "price",
    "pride",
    "priest",
    "primarily",
    "primary",
    "prime",
    "principal",
    "principle",
    "print",
    "prior",
    "priority",
    "prison",
    "prisoner",
    "privacy",
    "private",
    "probably",
    "problem",
    "procedure",
    "proceed",
    "process",
    "produce",
    "producer",
    "product",
    "production",
    "profession",
    "professional",
    "professor",
    "profile",
    "profit",
    "program",
    "progress",
    "project",
    "prominent",
    "promise",
    "promote",
    "prompt",
    "proof",
    "proper",
    "properly",
    "property",
    "proportion",
    "proposal",
    "propose",
    "proposed",
    "prosecutor",
    "prospect",
    "protect",
    "protection",
    "protein",
    "protest",
    "proud",
    "prove",
    "provide",
    "provider",
    "province",
    "provision",
    "psychological",
    "psychologist",
    "psychology",
    "public",
    "publication",
    "publicly",
    "publish",
    "publisher",
    "pull",
    "punishment",
    "purchase",
    "pure",
    "purpose",
    "pursue",
    "push",
    "put",
    "qualify",
    "quality",
    "quarter",
    "quarterback",
    "question",
    "quick",
    "quickly",
    "quiet",
    "quietly",
    "quit",
    "quite",
    "quote",
    "race",
    "racial",
    "radical",
    "radio",
    "rail",
    "rain",
    "raise",
    "range",
    "rank",
    "rapid",
    "rapidly",
    "rare",
    "rarely",
    "rate",
    "rather",
    "rating",
    "ratio",
    "raw",
    "reach",
    "react",
    "reaction",
    "read",
    "reader",
    "reading",
    "ready",
    "real",
    "reality",
    "realize",
    "really",
    "reason",
    "reasonable",
    "recall",
    "receive",
    "recent",
    "recently",
    "recipe",
    "recognition",
    "recognize",
    "recommend",
    "recommendation",
    "record",
    "recording",
    "recover",
    "recovery",
    "recruit",
    "red",
    "reduce",
    "reduction",
    "refer",
    "reference",
    "reflect",
    "reflection",
    "reform",
    "refugee",
    "refuse",
    "regard",
    "regarding",
    "regardless",
    "regime",
    "region",
    "regional",
    "register",
    "regular",
    "regularly",
    "regulate",
    "regulation",
    "reinforce",
    "reject",
    "relate",
    "relation",
    "relationship",
    "relative",
    "relatively",
    "relax",
    "release",
    "relevant",
    "relief",
    "religion",
    "religious",
    "rely",
    "remain",
    "remaining",
    "remarkable",
    "remember",
    "remind",
    "remote",
    "remove",
    "repeat",
    "repeatedly",
    "replace",
    "reply",
    "report",
    "reporter",
    "represent",
    "representation",
    "representative",
    "Republican",
    "reputation",
    "request",
    "require",
    "requirement",
    "research",
    "researcher",
    "resemble",
    "reservation",
    "resident",
    "resist",
    "resistance",
    "resolution",
    "resolve",
    "resort",
    "resource",
    "respect",
    "respond",
    "respondent",
    "response",
    "responsibility",
    "responsible",
    "rest",
    "restaurant",
    "restore",
    "restriction",
    "result",
    "retain",
    "retire",
    "retirement",
    "return",
    "reveal",
    "revenue",
    "review",
    "revolution",
    "rhythm",
    "rice",
    "rich",
    "rid",
    "ride",
    "rifle",
    "right",
    "ring",
    "rise",
    "risk",
    "river",
    "road",
    "rock",
    "role",
    "roll",
    "romantic",
    "roof",
    "room",
    "root",
    "rope",
    "rose",
    "rough",
    "roughly",
    "round",
    "route",
    "routine",
    "row",
    "rub",
    "rule",
    "run",
    "running",
    "rural",
    "rush",
    "Russian",
    "sacred",
    "sad",
    "safe",
    "safety",
    "sake",
    "salad",
    "salary",
    "sale",
    "sales",
    "salt",
    "same",
    "sample",
    "sanction",
    "sand",
    "satellite",
    "satisfaction",
    "satisfy",
    "sauce",
    "save",
    "saving",
    "say",
    "scale",
    "scandal",
    "scared",
    "scenario",
    "scene",
    "schedule",
    "scheme",
    "scholar",
    "scholarship",
    "school",
    "science",
    "scientific",
    "scientist",
    "scope",
    "score",
    "scream",
    "screen",
    "script",
    "sea",
    "search",
    "season",
    "seat",
    "second",
    "secret",
    "secretary",
    "section",
    "sector",
    "secure",
    "security",
    "see",
    "seed",
    "seek",
    "seem",
    "segment",
    "seize",
    "select",
    "selection",
    "self",
    "sell",
    "Senate",
    "senator",
    "send",
    "senior",
    "sense",
    "sensitive",
    "sentence",
    "separate",
    "sequence",
    "series",
    "serious",
    "seriously",
    "serve",
    "service",
    "session",
    "set",
    "setting",
    "settle",
    "settlement",
    "seven",
    "several",
    "severe",
    "sex",
    "sexual",
    "shade",
    "shadow",
    "shake",
    "shall",
    "shape",
    "share",
    "sharp",
    "she",
    "sheet",
    "shelf",
    "shell",
    "shelter",
    "shift",
    "shine",
    "ship",
    "shirt",
    "shit",
    "shock",
    "shoe",
    "shoot",
    "shooting",
    "shop",
    "shopping",
    "shore",
    "short",
    "shortly",
    "shot",
    "should",
    "shoulder",
    "shout",
    "show",
    "shower",
    "shrug",
    "shut",
    "sick",
    "side",
    "sigh",
    "sight",
    "sign",
    "signal",
    "significance",
    "significant",
    "significantly",
    "silence",
    "silent",
    "silver",
    "similar",
    "similarly",
    "simple",
    "simply",
    "sin",
    "since",
    "sing",
    "singer",
    "single",
    "sink",
    "sir",
    "sister",
    "sit",
    "site",
    "situation",
    "six",
    "size",
    "ski",
    "skill",
    "skin",
    "sky",
    "slave",
    "sleep",
    "slice",
    "slide",
    "slight",
    "slightly",
    "slip",
    "slow",
    "slowly",
    "small",
    "smart",
    "smell",
    "smile",
    "smoke",
    "smooth",
    "snap",
    "snow",
    "so",
    "so-called",
    "soccer",
    "social",
    "society",
    "soft",
    "software",
    "soil",
    "solar",
    "soldier",
    "solid",
    "solution",
    "solve",
    "some",
    "somebody",
    "somehow",
    "someone",
    "something",
    "sometimes",
    "somewhat",
    "somewhere",
    "son",
    "song",
    "soon",
    "sophisticated",
    "sorry",
    "sort",
    "soul",
    "sound",
    "soup",
    "source",
    "south",
    "southern",
    "Soviet",
    "space",
    "Spanish",
    "speak",
    "speaker",
    "special",
    "specialist",
    "species",
    "specific",
    "specifically",
    "speech",
    "speed",
    "spend",
    "spending",
    "spin",
    "spirit",
    "spiritual",
    "split",
    "spokesman",
    "sport",
    "spot",
    "spread",
    "spring",
    "square",
    "squeeze",
    "stability",
    "stable",
    "staff",
    "stage",
    "stair",
    "stake",
    "stand",
    "standard",
    "standing",
    "star",
    "stare",
    "start",
    "state",
    "statement",
    "station",
    "statistics",
    "status",
    "stay",
    "steady",
    "steal",
    "steel",
    "step",
    "stick",
    "still",
    "stir",
    "stock",
    "stomach",
    "stone",
    "stop",
    "storage",
    "store",
    "storm",
    "story",
    "straight",
    "strange",
    "stranger",
    "strategic",
    "strategy",
    "stream",
    "street",
    "strength",
    "strengthen",
    "stress",
    "stretch",
    "strike",
    "string",
    "strip",
    "stroke",
    "strong",
    "strongly",
    "structure",
    "struggle",
    "student",
    "studio",
    "study",
    "stuff",
    "stupid",
    "style",
    "subject",
    "submit",
    "subsequent",
    "substance",
    "substantial",
    "succeed",
    "success",
    "successful",
    "successfully",
    "such",
    "sudden",
    "suddenly",
    "sue",
    "suffer",
    "sufficient",
    "sugar",
    "suggest",
    "suggestion",
    "suicide",
    "suit",
    "summer",
    "summit",
    "sun",
    "super",
    "supply",
    "support",
    "supporter",
    "suppose",
    "supposed",
    "Supreme",
    "sure",
    "surely",
    "surface",
    "surgery",
    "surprise",
    "surprised",
    "surprising",
    "surprisingly",
    "surround",
    "survey",
    "survival",
    "survive",
    "survivor",
    "suspect",
    "sustain",
    "swear",
    "sweep",
    "sweet",
    "swim",
    "swing",
    "switch",
    "symbol",
    "symptom",
    "system",
    "table",
    "tablespoon",
    "tactic",
    "tail",
    "take",
    "tale",
    "talent",
    "talk",
    "tall",
    "tank",
    "tap",
    "tape",
    "target",
    "task",
    "taste",
    "tax",
    "taxpayer",
    "tea",
    "teach",
    "teacher",
    "teaching",
    "team",
    "tear",
    "teaspoon",
    "technical",
    "technique",
    "technology",
    "teen",
    "teenager",
    "telephone",
    "telescope",
    "television",
    "tell",
    "temperature",
    "temporary",
    "ten",
    "tend",
    "tendency",
    "tennis",
    "tension",
    "tent",
    "term",
    "terms",
    "terrible",
    "territory",
    "terror",
    "terrorism",
    "terrorist",
    "test",
    "testify",
    "testimony",
    "testing",
    "text",
    "than",
    "thank",
    "thanks",
    "that",
    "the",
    "theater",
    "their",
    "them",
    "theme",
    "themselves",
    "then",
    "theory",
    "therapy",
    "there",
    "therefore",
    "these",
    "they",
    "thick",
    "thin",
    "thing",
    "think",
    "thinking",
    "third",
    "thirty",
    "this",
    "those",
    "though",
    "thought",
    "thousand",
    "threat",
    "threaten",
    "three",
    "throat",
    "through",
    "throughout",
    "throw",
    "thus",
    "ticket",
    "tie",
    "tight",
    "time",
    "tiny",
    "tip",
    "tire",
    "tired",
    "tissue",
    "title",
    "to",
    "tobacco",
    "today",
    "toe",
    "together",
    "tomato",
    "tomorrow",
    "tone",
    "tongue",
    "tonight",
    "too",
    "tool",
    "tooth",
    "top",
    "topic",
    "toss",
    "total",
    "totally",
    "touch",
    "tough",
    "tour",
    "tourist",
    "tournament",
    "toward",
    "towards",
    "tower",
    "town",
    "toy",
    "trace",
    "track",
    "trade",
    "tradition",
    "traditional",
    "traffic",
    "tragedy",
    "trail",
    "train",
    "training",
    "transfer",
    "transform",
    "transformation",
    "transition",
    "translate",
    "transportation",
    "travel",
    "treat",
    "treatment",
    "treaty",
    "tree",
    "tremendous",
    "trend",
    "trial",
    "tribe",
    "trick",
    "trip",
    "troop",
    "trouble",
    "truck",
    "true",
    "truly",
    "trust",
    "truth",
    "try",
    "tube",
    "tunnel",
    "turn",
    "TV",
    "twelve",
    "twenty",
    "twice",
    "twin",
    "two",
    "type",
    "typical",
    "typically",
    "ugly",
    "ultimate",
    "ultimately",
    "unable",
    "uncle",
    "under",
    "undergo",
    "understand",
    "understanding",
    "unfortunately",
    "uniform",
    "union",
    "unique",
    "unit",
    "United",
    "universal",
    "universe",
    "university",
    "unknown",
    "unless",
    "unlike",
    "unlikely",
    "until",
    "unusual",
    "up",
    "upon",
    "upper",
    "urban",
    "urge",
    "us",
    "use",
    "used",
    "useful",
    "user",
    "usual",
    "usually",
    "utility",
    "vacation",
    "valley",
    "valuable",
    "value",
    "variable",
    "variation",
    "variety",
    "various",
    "vary",
    "vast",
    "vegetable",
    "vehicle",
    "venture",
    "version",
    "versus",
    "very",
    "vessel",
    "veteran",
    "via",
    "victim",
    "victory",
    "video",
    "view",
    "viewer",
    "village",
    "violate",
    "violation",
    "violence",
    "violent",
    "virtually",
    "virtue",
    "virus",
    "visible",
    "vision",
    "visit",
    "visitor",
    "visual",
    "vital",
    "voice",
    "volume",
    "volunteer",
    "vote",
    "voter",
    "vs",
    "vulnerable",
    "wage",
    "wait",
    "wake",
    "walk",
    "wall",
    "wander",
    "want",
    "war",
    "warm",
    "warn",
    "warning",
    "wash",
    "waste",
    "watch",
    "water",
    "wave",
    "way",
    "we",
    "weak",
    "wealth",
    "wealthy",
    "weapon",
    "wear",
    "weather",
    "wedding",
    "week",
    "weekend",
    "weekly",
    "weigh",
    "weight",
    "welcome",
    "welfare",
    "well",
    "west",
    "western",
    "wet",
    "what",
    "whatever",
    "wheel",
    "when",
    "whenever",
    "where",
    "whereas",
    "whether",
    "which",
    "while",
    "whisper",
    "white",
    "who",
    "whole",
    "whom",
    "whose",
    "why",
    "wide",
    "widely",
    "widespread",
    "wife",
    "wild",
    "will",
    "willing",
    "win",
    "wind",
    "window",
    "wine",
    "wing",
    "winner",
    "winter",
    "wipe",
    "wire",
    "wisdom",
    "wise",
    "wish",
    "with",
    "withdraw",
    "within",
    "without",
    "witness",
    "woman",
    "wonder",
    "wonderful",
    "wood",
    "wooden",
    "word",
    "work",
    "worker",
    "working",
    "works",
    "workshop",
    "world",
    "worried",
    "worry",
    "worth",
    "would",
    "wound",
    "wrap",
    "write",
    "writer",
    "writing",
    "wrong",
    "yard",
    "yeah",
    "year",
    "yell",
    "yellow",
    "yes",
    "yesterday",
    "yet",
    "yield",
    "you",
    "young",
    "your",
    "yours",
    "yourself",
    "youth",
    "zone"]

    let output = []

    for(let index in word_list)
    {
        const curr_word = word_list[index]
        if(curr_word.includes(containing_letters))
        {
            output.push(curr_word)
        }
    }

    return output


}

import words from "@/media_input/words"
import "@/utils/pronouncing_us"

export function check_syllable_count(word, syllable_conditions)
{

    if(syllable_conditions.condition=='any')
    {
        return true
    }
    else if(syllable_conditions.condition=='greater')
    {
        let syllables = syllableCount(phonesForWord(word)[0])

        if(syllables>syllable_conditions.n_syl)
        {
            return true
        }
        else
        {
            return false
        }


    }
    else if(syllable_conditions.condition=='lower')
    {
        let syllables = syllableCount(phonesForWord(word)[0])

        if(syllables<syllable_conditions.n_syl)
        {
            return true
        }
        else
        {
            return false
        }


    }
    else if(syllable_conditions.condition=='equal')
    {
        let syllables = syllableCount(phonesForWord(word)[0])

        console.debug('syllables = ' + syllables)

        if(syllables==syllable_conditions.n_syl)
        {
            return true
        }
        else
        {
            return false
        }


    }
    else
    {
        return true
    }


}

export function check_phoneme(phonetic_word, containing_phonemes, phoneme_position)
{



    if(phoneme_position=='WI')
    {
        let first_phoneme = phonetic_word[1]

        if(first_phoneme.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }

    }
    else if(phoneme_position=='WM')
    {
        let middle_phonemes = phonetic_word.substring(2,phonetic_word.length-2)

        if(middle_phonemes.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }

    }
    else if(phoneme_position=='WF')
    {
        let last_phoneme = phonetic_word[phonetic_word.length-2]

        if(last_phoneme.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }


    }
    else
    {
        if(phonetic_word.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }

    }

}

function check_size_condition(word,size_conditions)
{
    let word_size = word.length

    if(word_size>=size_conditions.min && word_size<=size_conditions.max)
    {
        return true
    }
    else
    {
        return false
    }



}

export function word_2_phoneme_function(str1)
{
    if(str1 in words.word_2_phoneme)
    {
        return words.word_2_phoneme[str1]
    }
    else
    {
        return null
    }

}

export function find_words_containing_letters(n_words,containing_letters,letter_position='WI',size_conditions={'min':1,'max':10})
{
    var output_words = []
    
    let word_list = words.word_2_phoneme


    let special_case_letters = {'t':'th','s':'sh','c':'ch'}

    let is_special_case = false


    if(special_case_letters[containing_letters]!=undefined)
    {
        is_special_case = true
    }

      
    let keys = Object.keys(word_list)
  
    let shuffled_words = shuffle_array(keys)

    for(let i in shuffled_words)
    {
        let word = shuffled_words[i]

        if(i ==0)
        {
            word = 'there'
        }
        else if(i==1)
        {
            word = 'town'
        }

        if(check_size_condition(word,size_conditions))
        {

            let index_letters = word.indexOf(containing_letters)


            if(index_letters>-1)
            {
                let word_pre_selected = false

                if(letter_position==='any')
                {
                    word_pre_selected = true
          
                }
                else if(letter_position==='WI' && index_letters==0)
                {
                    word_pre_selected = true
                }
                else if(letter_position==='WF' && index_letters==(word.length-containing_letters.length))
                {

                    word_pre_selected = true
                }
                else if(letter_position==='WM' && index_letters>0 && index_letters<(word.length-containing_letters.length))
                {
                    word_pre_selected = true
                }

                if(word_pre_selected && !is_special_case)
                {
                    output_words.push(word)
                }
                else if(word_pre_selected && is_special_case)
                {
                    let other_index = word.indexOf(special_case_letters[containing_letters])


                    if(other_index!=index_letters)
                    {
                        output_words.push(word)
                    }
                }



                if(output_words.length>=n_words)
                {
                    break
                }
            }


        }
    }

    return output_words
}


export function find_words_containing_phonemes(containing_phonemes,removing_phonemes,phoneme_position='any',syllable_conditions={condition:'any',n_syl:3})
{

    var output_words = []
    var output_phonetic_words = []


    if(containing_phonemes=='any')
    {
        for(let i in words.phoneme_2_word)
        {
            if(check_syllable_count(words.phoneme_2_word[i],syllable_conditions))
            {
                output_words.push(capitalizeFirstLetter(words.phoneme_2_word[i]))

            }

        }
        return output_words

    }
    else
    {

        if(removing_phonemes.length==0)
        {
            
            for(let i in words.phoneme_2_word)
            {


                if(check_phoneme(i, containing_phonemes, phoneme_position) && check_syllable_count(words.phoneme_2_word[i],syllable_conditions))
                {

                    output_phonetic_words.push(i)
                    output_words.push(capitalizeFirstLetter(words.phoneme_2_word[i]))
                }

                
            }
        
        }
        else
        {
            for(let i in words.phoneme_2_word)
            {


    
                if(check_phoneme(i, containing_phonemes, phoneme_position) && check_syllable_count(words.phoneme_2_word[i],syllable_conditions))
                {
    
                    let found_remove = false
    
    
                    for(let k in removing_phonemes)
                    {
                        if(i.includes(removing_phonemes[k]))
                        {
                            found_remove=true
                            break;
                        }
                    }
    
    
                    
                    if(!found_remove)
                    {

                        output_phonetic_words.push(i)
                        output_words.push(capitalizeFirstLetter(words.phoneme_2_word[i]))
    
                    }
                }
            }
    
        }
    
    
        return output_words

    }



   

}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  


export async function get_phonetic_representation_of_word(word)
{

    const promiseArray = []


    promiseArray.push(get_phonetic_representation_of_word_promise(word))


    const all_results = await Promise.all(promiseArray)

    console.debug('all_results[0] = ' + all_results[0])


    return all_results[0]



}


export function get_phonetic_representation_of_word_promise(word)
{
    word = word.toLowerCase()

    let word_list_dir = 'Exercises/Resources/Word_List/0/'+word

    console.debug('searching = ' + word_list_dir)

    let output_word = new Promise(function(resolve)
    {
        getData(word_list_dir , function (err, result) {

            let output_word = result.val()



            if(output_word!=undefined && output_word!=null)
            {
                console.debug('output_word = ' + output_word)

                resolve(output_word)
            }
            else
            {
                word_list_dir = 'Exercises/Resources/Word_List/1/'+word

                console.debug('searching = ' + word_list_dir)


                console.debug('using uncommon_word_list')
                getData(word_list_dir , function (err, result) {
                    output_word = result.val()

                    console.debug('output_word = ' + output_word)

                    resolve(output_word)


                })

            }


        })
    })


    return output_word

    

}


export function find_n_random_words_containing(number_words,containing_letters)
{

    let selected_words  = find_words_containing(containing_letters)

    let out_list = []

    var arr = [];
    const n_selected_words = selected_words.length

    number_words = Math.min(number_words,n_selected_words)

    while(arr.length < number_words){
        var r = Math.floor(Math.random() * n_selected_words) ;
        if(arr.indexOf(r) === -1) 
        {
            arr.push(r)
            out_list.push(selected_words[r])
        };
    }

    return out_list

}
import {getData} from "../firebase"



export function find_n_random_words_containing_phonemes_promise(number_words,containing_phonemes,phoneme_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions)
{

    let word_list_dir = 'Exercises/Resources/Word_List/0/'

    let word_list = new Promise(function(resolve)
    {
        getData(word_list_dir , function (err, result) {

            let common_word_list_db = result.val()


            let common_word_list = find_word_list_by_specification(common_word_list_db,number_words,containing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions)

            if(common_word_list.length>=number_words)
            {
                resolve(common_word_list)
            }
            else
            {
                word_list_dir = 'Exercises/Resources/Word_List/1/'



                console.debug('using uncommon_word_list')
                getData(word_list_dir , function (err, result) {
                    let uncommon_word_list_db = result.val()

                    let uncommon_word_list = find_word_list_by_specification(uncommon_word_list_db,number_words-common_word_list.length,containing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions)

                    for(let k in uncommon_word_list)
                    {
                        common_word_list.push(uncommon_word_list[k])
                    }

                    resolve(common_word_list)


                })

            }


        })
    })

    return word_list
}

export function find_n_random_words_containing_letters_promise(number_words,containing_letters,letter_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions)
{

    
    let word_list_dir = 'Exercises/Resources/Word_List/0/'

    let word_list = new Promise(function(resolve)
    {
        getData(word_list_dir , function (err, result) {

            let common_word_list_db = result.val()

            console.debug('syllable_condition = ' + syllable_conditions.condition)

            let common_word_list = find_words_containing_letters_2(common_word_list_db,number_words,containing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions)

            console.debug('common_word_list = ' + common_word_list)
            if(common_word_list.length>=number_words)
            {
                resolve(common_word_list)
            }
            else
            {
                word_list_dir = 'Exercises/Resources/Word_List/1/'



                console.debug('using uncommon_word_list')
                getData(word_list_dir , function (err, result) {
                    let uncommon_word_list_db = result.val()

                    let uncommon_word_list = find_words_containing_letters_2(uncommon_word_list_db,number_words-common_word_list.length,containing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions)

                    for(let k in uncommon_word_list)
                    {
                        common_word_list.push(uncommon_word_list[k])
                    }

                    resolve(common_word_list)


                })

            }


        })
    })


    return word_list


}

export async function find_n_random_words_containing_phonemes_2(number_words,containing_phonemes,phoneme_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions={difficulty_rating:'hard',manners:[],placings:[],voicings:[]})
{

    const promiseArray = []


    promiseArray.push(find_n_random_words_containing_phonemes_promise(number_words,containing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions))


    const all_results = await Promise.all(promiseArray)


    return all_results[0]

}


export async function find_n_random_words_containing_letters(number_words,containing_letters,letter_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions={difficulty_rating:'hard',manners:[],placings:[],voicings:[]})
{

    const promiseArray = []


    promiseArray.push(find_n_random_words_containing_letters_promise(number_words,containing_letters,letter_position,syllable_conditions,phonetic_conditions))


    const all_results = await Promise.all(promiseArray)


    return all_results[0]

}




export function find_word_list_by_specification(all_word_list,number_words,containing_phonemes,phoneme_position='any',syllable_conditions,phonetic_conditions)
{


    let removing_phonemes = prepare_removing_phonemes(containing_phonemes)

    console.debug('removing_phonemes = ' + removing_phonemes)

    let selected_words = find_words_containing_phonemes_2(all_word_list,number_words,containing_phonemes,removing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions)

    return selected_words
    

}

export function check_conditions(word,containing_phonemes,removing_phonemes,phoneme_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions)
{
    let condition_satisfied = true
    


    if(check_syllable_conditions_2(word,syllable_conditions)==false)
    {
        condition_satisfied = false
        return condition_satisfied
    }
    else if(check_phoneme_2(word,containing_phonemes,removing_phonemes,phoneme_position)==false)
    {
        condition_satisfied = false
        return condition_satisfied

    }
    else if(check_phonetic_properties(word,phonetic_conditions)==false)
    {
        condition_satisfied = false
        return condition_satisfied

    }

    return condition_satisfied

}
export function check_clusters(word,phonetic_conditions)
{
    let has_clusters = 2
    if('cluster_conditions' in phonetic_conditions)
    {
        let cluster_conditions = phonetic_conditions['cluster_conditions']

        let has_clusters = cluster_conditions['has_clusters']

    }


    if(has_clusters == 2) //doesnt matter
    {
        console.debug('clusters dont matter')

        return true
    }
    else if(has_clusters == 1) //has to have a cluster
    {
        console.debug('has to have a cluster')
        let which_cluster = cluster_conditions['which_cluster']

        let phonetic_properties = word['Phonetic_Properties']


        if(which_cluster=='any')
        {
            if(phonetic_properties['Clusters']!=undefined)
            {
                //word has at least a cluster
                return true
            }
            else
            {
                //no clusters in this word
                return false
            }

        }
        else //there is a specific cluster target
        {
            console.debug('cant have a cluster cluster')

            if(phonetic_properties['Clusters']!=undefined)
            {
                if(which_cluster in phonetic_properties['Clusters'])
                {
                    //found the target cluster
                    return true
                }
                else
                {
                    return false
                }
            }
            else
            {
                return false
            }


        }
    }
    else //has to have no clusters
    {
        let phonetic_properties = word['Phonetic_Properties']

        if(phonetic_properties['Clusters']==undefined)
        {
            //no clusters in this word

            return true
        }
        else
        {
            //at least one cluster
            return false
        }
    }

    return true


    


}

export function check_phonetic_properties(word,phonetic_conditions)
{

    if(check_clusters(word,phonetic_conditions)==false)
    {
        return false
    }
    let phonetic_properties = word['Phonetic_Properties']

    let difficulty_rating = phonetic_properties['difficulty_rating']
    

    let pass_test = true

    let manners_to_avoid = phonetic_conditions['manners']
    
    let voicings_to_avoid = phonetic_conditions['voicings']
    
    let placings_to_avoid = phonetic_conditions['placings']



    for(let i in manners_to_avoid)
    {
        let manner = manners_to_avoid[i]

        if(phonetic_properties['manners'].includes(manner))
        {

            pass_test = false
            return pass_test
        }
    }

    for(let i in voicings_to_avoid)
    {
        let voicing = voicings_to_avoid[i]
        if(phonetic_properties['voicings'].includes(voicing))
        {
            console.debug('remove voicings = ' + word['Word'])

            pass_test = false
            return pass_test
        }
    }

    for(let i in placings_to_avoid)
    {
        let placing = placings_to_avoid[i]
        if(phonetic_properties['placings'].includes(placing))
        {
            console.debug('remove placings = ' + word['Word'])

            pass_test = false
            return pass_test
        }
    }





    return pass_test
}

export function check_phoneme_2(word, containing_phonemes,removing_phonemes, phoneme_position)
{
    let phonetic_word = word['Phonetic']


    if(containing_phonemes==='any')
    {
        return true
    }
    
    for(let k in removing_phonemes)
    {
        if(phonetic_word.includes(removing_phonemes[k]))
        {
            
            return false

        }
    }

    let target_phoneme_length = containing_phonemes.length


    if(phoneme_position==='WI' && phonetic_word.length>=target_phoneme_length)
    {

        let first_phoneme = phonetic_word.substring(0,target_phoneme_length+1)


        if(first_phoneme.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }

    }
    else if(phoneme_position=='WM' && phonetic_word.length>2 )
    {
        let middle_phonemes = phonetic_word.substring(1,phonetic_word.length-1)

        if(middle_phonemes.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }

    }
    else if(phoneme_position=='WF' && phonetic_word.length>target_phoneme_length)
    {
        let last_phoneme = phonetic_word.substring(phonetic_word.length-target_phoneme_length,phonetic_word.length)
        

        if(last_phoneme.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            return false
        }


    }
    else
    {
        if(phonetic_word.includes(containing_phonemes))
        {
            return true
        }
        else
        {
            

            return false
        }

    }

}


export function check_syllable_conditions_2(word,syllable_conditions)
{
    if(syllable_conditions.condition=='any')
    {
        return true
    }
    else if(syllable_conditions.condition=='greater')
    {
        let syllables = word['Number_Syllables']

        if(syllables>syllable_conditions.n_syl)
        {
            return true
        }
        else
        {
            return false
        }


    }
    else if(syllable_conditions.condition=='lower')
    {
        let syllables = word['Number_Syllables']

        if(syllables<syllable_conditions.n_syl)
        {
            return true
        }
        else
        {
            return false
        }

    }
    else if(syllable_conditions.condition=='equal')
    {
        let syllables = word['Number_Syllables']

        if(syllables==syllable_conditions.n_syl)
        {
            return true
        }
        else
        {
            return false
        }

    }

    else
    {
        return true
    }


}

export function shuffle_array(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
  export function find_words_containing_letters_2(all_word_list,number_words,containing_letters,letter_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions)
  {

    //Work in progress
  
      var output_words = []
  
      let keys = Object.keys(all_word_list)
  
      let shuffled_keys = shuffle_array(keys)
  
  
      for(let i in shuffled_keys)
      {
  
          let word = shuffled_keys[i]
          if(check_conditions(all_word_list[word],containing_phonemes,removing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions))
          {
  
              output_words.push(capitalizeFirstLetter(all_word_list[word]['Word']))
  
              if(output_words.length==number_words)
              {
                  break;
              }
          }
      }
  
      return output_words
  
  }


export function find_words_containing_phonemes_2(all_word_list,number_words,containing_phonemes,removing_phonemes,phoneme_position='any',syllable_conditions={condition:'any',n_syl:3},phonetic_conditions)
{

    var output_words = []

    let keys = Object.keys(all_word_list)

    let shuffled_keys = shuffle_array(keys)


    for(let i in shuffled_keys)
    {

        let word = shuffled_keys[i]
        if(check_conditions(all_word_list[word],containing_phonemes,removing_phonemes,phoneme_position,syllable_conditions,phonetic_conditions))
        {

            output_words.push(capitalizeFirstLetter(all_word_list[word]['Word']))

            if(output_words.length==number_words)
            {
                break;
            }
        }
    }

    return output_words

}

export function prepare_removing_phonemes(containing_phonemes)
{
    let removing_phonemes = []

    let phoneme_list = ['i:','ɪ', 'eɪ','e', 'æ','ɑ:','ɒ','ɔ:','ʊ','əʊ','u:','ʌ',
    'aɪ','aʊ','ɔɪ','ə','ɪə','eə','ʊə','ɜ:','w','j','p','b','t','d','k','g','m','n','ŋ','f','v','θ','ð','s','z','ʃ','ʒ','h','tʃ','dʒ','l','r']


    if(containing_phonemes!='any')
    {
        for(let i in phoneme_list)
        {
            let p = phoneme_list[i]
            if(p.includes(containing_phonemes) && p!=containing_phonemes)
            {
                removing_phonemes.push(p)

            }
        }

        if(containing_phonemes.length>1)
        {
            for(let k in containing_phonemes)
            {
                let c = containing_phonemes[k]

                for(let i in phoneme_list)
                {
                    let p = phoneme_list[i]
                    if(p.includes(c) && p!=containing_phonemes && p!=c && !removing_phonemes.includes(p))
                    {
                        removing_phonemes.push(p)
            
                    }
                }
            
            }
        }
    
    }
    return removing_phonemes
}

export function find_n_random_words_containing_phonemes(number_words,containing_phonemes,phoneme_position='any',syllable_conditions={condition:'any',n_syl:3})
{

    let removing_phonemes = []

    let phoneme_list = ['i:','ɪ', 'eɪ','e', 'æ','ɑ:','ɒ','ɔ:','ʊ','əʊ','u:','ʌ',
    'aɪ','aʊ','ɔɪ','ə','ɪə','eə','ʊə','ɜ:','w','j','p','b','t','d','k','g','m','n','ŋ','f','v','θ','ð','s','z','ʃ','ʒ','h','tʃ','dʒ','l','r']


    if(containing_phonemes!='any')
    {
        for(let i in phoneme_list)
        {
            let p = phoneme_list[i]
            if(p.includes(containing_phonemes) && p!=containing_phonemes)
            {
                removing_phonemes.push(p)

            }
        }

        if(containing_phonemes.length>1)
        {
            for(let k in containing_phonemes)
            {
                let c = containing_phonemes[k]

                for(let i in phoneme_list)
                {
                    let p = phoneme_list[i]
                    if(p.includes(c) && p!=containing_phonemes && p!=c && !removing_phonemes.includes(p))
                    {
                        removing_phonemes.push(p)
            
                    }
                }
            
            }
        }

    
    }



    let selected_words  = find_words_containing_phonemes(containing_phonemes,removing_phonemes,phoneme_position,syllable_conditions)

    let out_list = []

    var arr = [];
    const n_selected_words = selected_words.length


    number_words = Math.min(number_words,n_selected_words)

    while(arr.length < number_words){
        var r = Math.floor(Math.random() * n_selected_words) ;
        if(arr.indexOf(r) === -1) 
        {
            arr.push(r)
            out_list.push(selected_words[r])
        };
    }

    return out_list

}