<template>
<div>
  <div class="card card-body vh-100 mb-4">
    <div class="mt-4">
      <ConfettiExplosion v-if="confetti_visible" />
      <p v-if="tasks_list.length > 0" class="text-end mb-0"><i>{{completed_tasks}} out of {{ tasks_list.length }} goals completed</i></p>
      <ul class="list-group mt-4">
        <div v-for="task, index in tasks_list">
          <li v-if="task.status === 'started'" :class="['text-start list-group-item']">
            <div class="d-flex justify-content-between" @mouseover="hovering_index=index" @mouseleave="hovering_index=null">
              <div class="d-flex gap-3 align-items-center"> 
                <VTooltip>
                  <i style="cursor:pointer" :class="['bi fs-4', {'bi-circle': task.status === 'started'}, {'bi-check-circle': task.status === 'completed'}]" @click="mark_as_complete(index)">
                  </i>
                  <template #popper>
                    <span>
                      mark as completed
                    </span>
                  </template>
                </VTooltip>
                <div v-if="edit_mode.length > 0 && edit_mode[index] == false">
                  <VTooltip>
                    <h5 class="mt-2" @click="edit_mode = edit_mode.map(element => false);edit_mode[index] = true" @focusout="edit_mode[index] = false">{{ task.text }}</h5>
                    <template #popper>
                      edit
                    </template>
                  </VTooltip>
                </div>
                <div v-else>
                  <div class="input-group">
                    <input type="text" class="form-control" placeholder="type a goal" v-model="task.text">
                    <button class="btn btn-outline-secondary" type="button" :disabled="task.text.length<1" @click="edit_task()">Save</button>
                  </div>
                </div>
              </div>
              <div v-if="hovering_index === index" class="align-items-center">
                <VTooltip>
                  <button class="btn btn-sm btn-outline-danger" @click="delete_task(index)"><i class="bi bi-trash3"></i></button>
                  <template #popper>
                    delete
                  </template>
                </VTooltip>
              </div>
            </div>
          </li>
        </div>
      </ul>
      
      
      <div class="input-group mt-3">
        <input type="text" class="form-control" placeholder="Add a new goal here" v-model="new_goal_message" @keyup.enter="add_task">
        <button class="btn btn-outline-primary" type="button" @click="add_task">Add Goal</button>
      </div>

      <div v-if="tasks_list.some(task => task.status === 'completed')" class="mt-4 accordion" id="accordionGoals" :style="{'--bs-accordion-active-bg': 'none', '--bs-accordion-bg': show_completed_collapse ? 'rebeccapurple' : 'none'}">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button :class="['accordion-button btn-primary', { 'text-white': show_completed_collapse }, { 'collapsed': !show_completed_collapse } ]" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" @click="show_completed_collapse = !show_completed_collapse">
              Completed Goals
            </button>
          </h2>
          <div id="collapseOne" :class="['accordion-collapse collapse', {'show' : show_completed_collapse }]" data-bs-parent="#accordionGoals">
            <div>
              <ul class="list-group">
                <div v-for="task, index in tasks_list">
                  <li v-if="task.status === 'completed'" :class="['text-start list-group-item', {'list-group-item-success' : task.status === 'completed'}]">
                    <div class="d-flex justify-content-between" @mouseover="hovering_index=index" @mouseleave="hovering_index=null">
                      <div class="d-flex gap-3 align-items-center"> 
                        <VTooltip>
                          <i style="cursor:pointer" :class="['bi fs-4', {'bi-check-circle': task.status === 'completed'}]" @click="mark_as_complete(index)">
                          </i>
                          <template #popper>
                            <span>
                              mark as incomplete
                            </span>
                          </template>
                        </VTooltip>
                        <div v-if="edit_mode.length > 0 && edit_mode[index] == false">
                          <VTooltip>
                            <h5 class="mt-2" @click="edit_mode = edit_mode.map(element => false);edit_mode[index] = true" @focusout="edit_mode[index] = false">{{ task.text }}</h5>
                            <template #popper>
                              edit
                            </template>
                          </VTooltip>
                        </div>
                        <div v-else>
                          <div class="input-group">
                            <input type="text" class="form-control" placeholder="type a goal" v-model="task.text">
                            <button class="btn btn-outline-secondary" type="button" :disabled="task.text.length<1" @click="edit_task()">Save</button>
                          </div>
                        </div>
                      </div>
                      <div v-if="hovering_index === index" class="align-items-center">
                        <VTooltip>
                          <button class="btn btn-sm btn-outline-danger" @click="delete_task(index)"><i class="bi bi-trash3"></i></button>
                          <template  #popper>
                            delete
                          </template>
                        </VTooltip>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import { nextTick, ref, computed } from 'vue';
import firebase from '@firebase/app';
import { useToast } from "vue-toastification";

export default {
  name: 'Goals',
  setup() {


    const uid = localStorage.getItem('userCategory') === 'patient' ? localStorage.getItem('uid') : localStorage.getItem('client_uid')
    const hovering_index = ref(null)
    const tasks_list = ref([])
    const new_goal_message = ref("")
    const confetti_visible = ref(false);
    const toast = useToast();
    const show_completed_collapse = ref(false)

    const edit_mode = ref([])
    
    const explode = async (index) => {
      confetti_visible.value = false;
      await nextTick();
      confetti_visible.value = true;
    };

    firebase.database().ref('Users').child(uid).child('Goals').get().then( (value) => {
      if (value.val()) {
        tasks_list.value = value.val()
        edit_mode.value = tasks_list.value.map(element => false);
      }  
    })

    const completed_tasks = computed(() => {
      let completed = 0;
      for (let task of tasks_list.value) {
        if (task.status === 'completed') {
          completed = completed + 1
        }
      }

      return completed
    });

    const update_task_list = () => {
      firebase.database().ref('Users').child(uid).child('Goals').set(tasks_list.value)
      edit_mode.value = tasks_list.value.map(element => false);
    }
    
    const mark_as_complete = (index) => {
      let task = tasks_list.value[index]
      if (task.status == "started") {
        task.status = "completed"
        explode(index)
        toast.success("Congratulations goal achieved!");
        show_completed_collapse.value = true
      } else if (task.status === "completed") {
        task.status = "started"
      }

      update_task_list()
    }

    const edit_task = async () => {
      //const editedText = event.target.innerText;
      //console.log(editedText);
      //tasks_list.value[index].text = editedText
      update_task_list()
    }

    const delete_task = (index) => {
      tasks_list.value.splice(index, 1)
      update_task_list()
      toast.error("Goal removed!");
    }

    const add_task = () => {
      tasks_list.value.push({text: new_goal_message.value, status: "started"})
      new_goal_message.value = ""
      update_task_list()
      toast.info("New goal added!");
    }

    return {
      hovering_index,
      tasks_list,
      new_goal_message,
      confetti_visible,
      edit_mode,
      show_completed_collapse,

      mark_as_complete,
      add_task,
      edit_task,
      delete_task,
      completed_tasks,
    };
  }
}

</script>