export async function get_microphone_permission(_this) {
  try { //this is only supported by chrome/safari but only on macOS/windows/linux not on iOS
    const permissionStatus = await navigator.permissions.query({ name: "microphone" });

    _this.mic_permission_supported_browser = true
    console.debug("microphone permission state" + " " + permissionStatus.state); // granted, denied, prompt

    if (permissionStatus.state !== "granted") {
      //not granted so show mic page
      _this.mic_permission_allowed = false
      localStorage.setItem('mic_permission_allowed', false)
    } else {
      _this.mic_permission_allowed = true
      localStorage.setItem('mic_permission_allowed', true)
    }

    permissionStatus.onchange = () => {
      console.debug("Permission changed to " + permissionStatus.state);

      if (permissionStatus.state !== "granted") {
        //not granted so show mic page
        _this.mic_permission_allowed = false
        localStorage.setItem('mic_permission_allowed', false)
      }
    };
  } catch (error) {
    
    // probably firefox or unsupported browser in iOS in which case get getUserMedia
    console.debug('Error querying permissions:', error);
    
    if (localStorage.getItem('mic_permission_allowed') && localStorage.getItem('mic_permission_allowed') === 'true') {
      _this.mic_permission_allowed = true
      localStorage.setItem('mic_permission_allowed', true)
    } else {
      _this.mic_permission_allowed = false
      localStorage.setItem('mic_permission_allowed', false)
    }
  }
}
