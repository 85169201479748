<template>
  <div>
    <div class="row">
      <div class="col-md-10">

        <div class="card card-body h-100">

          <div class="row">
            <p class="fs-3" :style="answer_text_color">{{answer_text}}</p>
          </div>
          <div class="row">
            <div class="col-md-4">

              <div v-if="cue_type==='Audio'">
                <p class="text-30">
                      Listen and write the word
                  </p>
                <div class="row mt-2  mb-2 justify-content-center">
                  <button class="btn btn-primary btn-lg mt-2 w-100" @click="sayInstructions()" ><i class="bi bi-play" style="font-size: 1.5rem"></i> Hear word</button>
                </div>

                <div class="row mt-2  mb-2 justify-content-center">
                  <div class="card card-body ">

                    <p v-if="!showImage" class="lead" style="font-size: x-large;">
                      Need help? 
                    </p>  

                    
                    <div v-if="!showImage" class="row justify-content-center">
                      <button  class="btn btn-secondary btn-lg mt-2 w-50" @click="showImage=true" >See image <i class="bi bi-eye"></i></button>
                    </div>   

                    <div v-if="showImage" class="row">
                      <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: cover"> 
                    </div>
                    
                  </div>
                </div>
              </div>


              <div v-if="cue_type==='Image'" class="card card-body ">
                <p class="text-30">
                      Name the picture
                  </p>
                <div class="row">
                  <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="150" height="150" style="object-fit: cover"> 
                </div>

              </div>
            </div>
            <div class="col-md-8">
                <div class="card card-body h-100">

                  <div v-show="showClickLetters">
                  
                  
                    <div class="row mt-2  mb-2 justify-content-center">
                      <button v-for="letter, index in word_array_missing_letter" type="text" class="display-button" >
                        {{word_array_missing_letter[index]}}
                      </button>
                    </div>

                    <p class="text-30">
                      {{ instructions_msg }}
                    </p>


                    <div class="row mt-2  mb-2 justify-content-center">
                      <button v-for="letter, index in option_buttons" type="text" @click=select_letter(letter,index) :class="option_buttons_class[index]" >
                        {{option_buttons[index]}}
                      </button>
                    </div>
                  </div>

                  <div v-show="showWriteWord" class="row mt-2  mb-2 justify-content-center">
                      <p class="text-30">
                        {{ type_your_answer_text }}
                    </p>
                    <div class="row mt-2 justify-content-center">
                      <input v-model="written_answer"  @input="answer_check('auto')" :placeholder="placeholder_text" style="font-size: x-large; width:40%"/>
                      <button class="btn btn-primary btn-lg ms-1 w-25" @click="answer_check('manual')">
                        Submit answer
                      </button>
                    </div>
                  </div>
                  
      
                  <div v-if="cue_type==='Image'">
                    <p class="lead" style="font-size: x-large;">
                      Need help? 
                   </p>


                    <div class="row justify-content-center">
                      <!-- <div v-if="half_word_url!=null || phoneme_single_target_word.length>1"> -->
                      <button v-if="half_word_url!=null || phoneme_single_target_word.length>1" class="btn btn-secondary btn-lg mt-2 w-50" @click="listenBeginningSound()" >Hear its first sound  <i class="bi bi-ear" style="margin-left: 3mm;"></i></button>
                    </div>   
                    <div class="row justify-content-center">
                      <!-- </div> -->
                      <button v-if="half_word_url==null || half_sound_hints" class="btn btn-secondary btn-lg mt-2 w-50" @click="listenFullWord()" >Hear the word  <i class="bi bi-ear" style="margin-left: 3mm;"></i></button>
                    </div>

                    <div v-if="showHints">
                        <span>Hint: {{hints[0]}}
                            <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(hints[0])"><br>
                        </span>  
                    </div>

  
                      
                </div>

                <div class="row justify-content-center" v-if="full_sound_hints || (cue_type==='Audio' && showImage)">
                        <button class="btn btn-bd-yellow btn-lg mt-4 w-50" style="width: 95%;" @click="showAnswer()" >Show answer <i class="bi bi-file-earmark-check" style="margin-left: 3mm;"></i></button>
                    </div>

              
                
                    
                </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="modal fade" id="level_up_pop" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Level up</h4>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="m-3">
                <div class="row">
                  <h4 >{{ level_up_message }}</h4>
                </div>
                <div class="row mt-2">
                  <button class="btn btn-success w-100" @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2" style="padding-left: 0px">
        <div class="card card-body h-100">
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
          <button class="btn btn-info btn-lg w-100 mt-2" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
          <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import words from '@/media_input/words'
import constants from '@/utils/constants'
import { Modal } from 'bootstrap'


import {getData, updateDataDirectory} from '@/firebase/index'

import {logActivity} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'
import {BV_Points} from '@/utils/bv_points'

/*

{
   "Address":[
      "/images/word_naming_easy/onion_1.jpg",
      "/images/word_naming_easy/onion_2.jpg"
   ],
   "category":"vegetables",
   "difficulty":"easy",
   "exercise_type":"name_a_picture_easy",
   "hints":[
      "This is a vegetable",
      "This vegetable has layers",
      "This vegetable grows in the ground"
   ],
   "instruct":"Press Record. Say the word that matches the picture below",
   "name":"onion;onions;red_onion;white_onion",
   "word_type":"noun",
   "wrong_words":"tomato;sweetcorn;pepper",
   "plan_name":"Picture naming",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Picture naming/16",
   "ex_path":"Picture naming/16",
   "today":"2023-07-11 17:20",
   "audioIns1":null,
   "imageAddress":[
      "/images/word_naming_easy/onion_1.jpg",
      "/images/word_naming_easy/onion_2.jpg"
   ],
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"name_a_picture_easy",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"Press Record. Say the word that matches the picture below",
   "module_name":"Picture naming",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
export default {
    name: 'write_words',
    props: {
      exForm: {
        type: Object,
      },
    },
    data() {
      return {
        cue_type:'Image',
        showImage:false,
        half_sound_hints:false,
        full_sound_hints:false,
        hovering_letter:false,
        hover_sound_on:true,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        answer_text: '',
        content_data:null,
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        showHints:false,
        answer_options:['Saucepan','Fridge'],
        type_your_answer_text:'Write the word in the box below',
        placeholder_text: ' _ _ _ _ _ _',
        written_answer: '',
        first_written_answer:'',
        level_up_message:'',
        total_exercises:1,
        level_properties:{
          1:{'level':1,'type':'copy','word_difficulty':'short'},
          2:{'level':2,'type':'letter','n_missing_letters':1,'n_option_letters':3,'word_difficulty':'short'},
          3:{'level':3,'type':'letter','n_missing_letters':2,'n_option_letters':6,'word_difficulty':'short'},  
          4:{'level':4,'type':'first_letter','n_missing_letters':1,'n_option_letters':4,'word_difficulty':'short'},
          5:{'level':5,'type':'anagram','n_missing_letters':0,'n_option_letters':0,'word_difficulty':'short'},
          6:{'level':6,'type':'first_letter','n_missing_letters':3,'n_option_letters':8,'word_difficulty':'short'},
          7:{'level':7,'type':'anagram','n_missing_letters':0,'n_option_letters':3,'word_difficulty':'short'},
          8:{'level':8,'type':'write','word_difficulty':'short'}         
          // {'level':9,'type':'letter','n_missing_letters':2,'n_option_letters':6,'word_difficulty':'long'},  
          // {'level':10,'type':'first_letter','n_missing_letters':1,'n_option_letters':4,'word_difficulty':'long'},
          // {'level':11,'type':'anagram','n_missing_letters':0,'n_option_letters':0,'word_difficulty':'long'},
          // {'level':12,'type':'first_letter','n_missing_letters':3,'n_option_letters':8,'word_difficulty':'long'},
          // {'level':13,'type':'anagram','n_missing_letters':0,'n_option_letters':3,'word_difficulty':'long'},
          // {'level':14,'type':'write','word_difficulty':'long'},
        },
        task_type:'write',
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        first_exercise:true,
        showWriteWord:false,
        showClickLetters:false,
        current_level:2,
        level_up_counter:0,
        level_up_target:3,
        curr_level_properties:null,
        word_array_missing_letter:[],
        word_array:[],
        missing_letters:[],
        missing_letter_indexes:[],
        n_missing_letters:2,
        option_buttons:[],
        option_buttons_class:[],
        disappear_first_letter:false,
        chosen_image: '',
        target_word: '',
        base_level_address:'/Levels/Write_words',
        level_address:'',
        single_target_word: '',
        audio_player: null,
        phoneme_single_target_word: '',
        target_img_number: '',
        instructions_msg: '',
        homeplanImg: homeplanImg,
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        play_pause_text: 'Start',
        curr_exercise_number: 0,
        number_exercises:1,
        started: false,
        target_words: [],
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: '',
        userType: '',
        resultsTable: [],
        half_word_url: null,
        retest_count: null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
      }
    },
    created() {

      const _this = this
      _this.userType = localStorage.getItem('userCategory');

      this.bv_tts.say('')

      if('setting' in this.exForm && this.exForm.setting==='Audio')
      {
        this.cue_type = 'Audio'
      }

      if(this.userType=='slp')
      {
        let client_number = localStorage.getItem('client_uid');

        if(client_number!='undefined' && client_number!=null && client_number!='')
        {
          _this.client_number = client_number; 
        }
      }

      let uid = localStorage.getItem('uid');
      console.debug('uid = ' + uid)
      _this.uid = uid;   

      logActivity(_this.$firebase, 'accessed', 'write_words')

      this.level_address = this.level_address+this.cue_type

      this.getUserLevel()


    },
    mounted()
    {

    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()

    },
    methods: 
    {
      getUserLevel()
      { 
        const _this = this

        let userType = localStorage.getItem('userCategory');


        let uid = localStorage.getItem('uid');

        this.level_address = '/Users/' + uid + this.base_level_address


        if(userType!=undefined || userType==='slp')
        {
            let client_number = localStorage.getItem('client_uid');

            if(client_number!='undefined' && client_number!=null && client_number!='')
            {

                this.level_address = '/Users/'+ client_number + this.base_level_address

            }
        }


        let do_once = true


        getData(_this.level_address, function (err, result) {

          if(do_once)
          {
            do_once = false
            
            let current_level = result.child('current_level').val()
            let lvl_up_counter = result.child('level_up_counter').val()


            if(current_level==undefined || lvl_up_counter==undefined)
            {
              current_level = 1
              lvl_up_counter = 0
              updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
            }

            _this.level_up_counter = lvl_up_counter

            if(current_level<1 || current_level>Object.keys(_this.level_properties).length)
            {
              current_level = 1
              
            }
            
            if(current_level==1)
            {
              current_level=2
            }
            _this.current_level = current_level

            console.debug('current_level = ' + current_level)

            _this.curr_level_properties = _this.level_properties[_this.current_level]

          


            _this.getContentData()
          }
        })


      },
      updateLevel(correct)
      {

        if(correct)
        {
          this.level_up_counter++
          if(this.level_up_counter == this.level_up_target)
          {
              this.level_up_counter = 0
              this.current_level++

              if(this.current_level>Object.keys(this.level_properties).length)
              {
                this.current_level = Object.keys(this.level_properties).length
              }
              else
              {
                this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
                let level_up_pop = new Modal(document.getElementById('level_up_pop'));
                level_up_pop.show()

              }

          }
        }
        else
        {
          this.level_up_counter--

          if(this.level_up_counter<0)
          {
            this.level_up_counter = 0
          }
        }

        updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter})

      },

      saveResults()
      {
        const _this = this

        if(this.results['correct']!=0 || _this.results['total']!=0)
        {
          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1, Show_Answer:_this.showedAnswer, Half_Sound_Hints:_this.half_sound_hints, Full_Sound_Hints:_this.full_sound_hints, cue_type:this.cue_type, showImage:this.showImage, Identified_Answer:this.first_written_answer}

          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}

          let results = {all_results:all_results, plot_results:plot_results}

          var write_words_data = {resultsTablePre: {exercise_type: 'Write Words', exercise: 'Write Words', results: results, audio_address:'',word:_this.single_target_word,category:_this.content_data.category,word_type:_this.content_data.word_type, cue_type:this.cue_type, showImage:this.showImage,difficulty:_this.content_data.difficulty}}


          save_pre_computed_results(_this.exForm, _this.$firebase, write_words_data)

          _this.bv_points.add(10)

          _this.$emit('logExerciseResults', write_words_data);
        }
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        let goToResultsData = {curr_ex_done:false}
        _this.$emit('goToResults', goToResultsData);
      },
      mouseOverButton(letter)
      {

        if(this.hover_sound_on)
        {
          this.bv_tts.system_say(letter)

          this.hover_sound_on = false

          setTimeout(() => {
            this.hover_sound_on = true
            }, 900);



        }

      },
      listenFullWord()
      {
        const _this = this

        if(_this.cue_type==='Image')
        {
          _this.full_sound_hints = true

        }


        _this.bv_tts.say(_this.single_target_word)

      },
      listenBeginningSound()
      {
        const _this = this
        _this.half_sound_hints = true

        if(_this.half_word_url!=null)
        {
          _this.playSound(_this.half_word_url)
        }
        else
        {
          _this.listenPhoneme()
        }
      },
      listenPhoneme()
      {
        const _this = this
        let phoneme = _this.phoneme_single_target_word[1]

        let simple_representation_phoneme = ''
        for(let i in constants.phonemeDisplayDictionaryIPA)
        {
          if(constants.phonemeDisplayDictionaryIPA[i]==phoneme)
          {
            simple_representation_phoneme = i
            break
          }
        }
        if(simple_representation_phoneme!='')
        {
          let audio_file_location = '/BV/audio_resources/audio_hints/phonemes/'+simple_representation_phoneme+'.mp3'
          // let audio_file_location = 'audios/phoneme_pronunciation_new/'+phoneme+'.mp4'
          console.debug(audio_file_location)
          this.playAudio(audio_file_location)
        }
      },
      get_half_word_url(word)
      {
        let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'

        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.half_word_url = url
          console.debug('Found half word url!!')
        })
        .catch((error) => {
          console.debug('error ' + error )
        });
      },
      playAudio(audio_file_location)
      {
        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.playSound(url);
        })
        .catch((error) => {
          console.debug('error ' + error )
        });      
      },
      playSound(url) {
        const _this = this
        if(_this.audio_player==null)
        {
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
        else
        {
          if(!_this.audio_player.paused)
          {
            _this.audio_player.pause();
          }
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
      },
      getContentData()
      {
        let _this = this;
        let do_once = true

        let resources_address = '/Exercises/Resources/WriteWords/'

        if(this.curr_level_properties.word_difficulty==='short')
        {
          resources_address = resources_address+'Short'
        }
        else
        {
          resources_address = resources_address+'Long'

        }
        


        getData(resources_address, function (err, result) {

          if(do_once)
          {
            do_once = false

            let resources_data = result.val()



            _this.content_data = resources_data[Math.floor(Math.random() * resources_data.length)]


            _this.setup()
          }
        })

      },
      setup()
      {
        const _this = this



        _this.answer_text = '';

        _this.first_exercise = !_this.exForm.has_results
        console.debug("content_data = " + _this.content_data)

        let img_address = _this.content_data.imageAddress[Math.floor(Math.random() * _this.content_data.imageAddress.length)]
        console.debug("img_address = " + img_address)
        _this.target_word = _this.content_data.name
        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises
        _this.retest_count = null
        _this.target_word = _this.target_word.toLowerCase()

  

        _this.target_words = _this.target_word.split(';')
        _this.single_target_word = _this.target_words[0]

       
        this.sayInstructions()
        

        _this.task_type = _this.curr_level_properties['type']

        if(_this.task_type==='write')
        {
          _this.showWriteWord = true

          this.placeholder_text = ''

          for(let k in this.single_target_word)
          {
            this.placeholder_text = this.placeholder_text + ' _'
          }
        }
        else
        {
          _this.showClickLetters = true

          _this.n_missing_letters = _this.curr_level_properties['n_missing_letters']

          _this.n_option_letters = _this.curr_level_properties['n_option_letters']

          if(_this.task_type==='first_letter')
          {
            _this.disappear_first_letter = true
          }

          console.debug('_this.task_type = ' + _this.task_type)

          if(_this.task_type==='first_letter' || _this.task_type==='letter')
          {
            if(_this.n_missing_letters==1)
            {
              _this.instructions_msg = 'Choose the missing letter.'

            }
            else
            {
              _this.instructions_msg = 'Choose the missing letters.'

            }

          }
          else if(_this.task_type==='anagram')
          {
            _this.instructions_msg = 'Click on the letters in order to write the word.'
            _this.n_missing_letters = _this.single_target_word.length
          }
        }

        _this.prepare_target_word()

  

        _this.get_half_word_url(_this.single_target_word)

        _this.phoneme_single_target_word = words.word_2_phoneme[_this.single_target_word]

        if(_this.phoneme_single_target_word==undefined || _this.phoneme_single_target_word==null)
        {
            _this.phoneme_single_target_word = ''
        }

        console.debug('_this.single_target_word = ' + _this.single_target_word)

        console.debug('phoneme_single_target_word = ' + _this.phoneme_single_target_word)

        let wrong_words = _this.content_data.wrong_words.toLowerCase()
        wrong_words = wrong_words.split(';')

        _this.answer_options = [_this.target_words[0],wrong_words[Math.floor(Math.random() * wrong_words.length)]]
        _this.hints = [_this.content_data.hints[Math.floor(Math.random() * _this.content_data.hints.length)]]

        var storageRef = _this.$firebase.storage().ref();
        storageRef.child(img_address).getDownloadURL().then(function(url) {
            _this.imgSrc = url;
        }).catch(function(error) {

        });
      },
      prepare_target_word()
      {

        const _this = this
        _this.word_array = []
        _this.word_array_missing_letter = []

        for(let k in _this.single_target_word){
          _this.word_array.push(_this.single_target_word[k])
          _this.word_array_missing_letter.push(_this.single_target_word[k])
        }

        this.missing_letter_indexes = []

        this.missing_letters = []


        if(_this.disappear_first_letter || _this.task_type==='anagram')
        {
          this.missing_letter_indexes = [0]

          this.missing_letters.push(this.word_array_missing_letter[0])


          this.word_array_missing_letter[this.missing_letter_indexes[0]] = ''

          this.n_missing_letters = this.n_missing_letters-1

        }

        
        {


          _this.n_missing_letters = Math.min(_this.n_missing_letters,_this.word_array.length-1)+this.missing_letters.length




          while(this.missing_letter_indexes.length<this.n_missing_letters)
          {

            let curr_missing_letter_index = Math.floor(Math.random()*(this.word_array.length-1))+1

            if(!this.missing_letter_indexes.includes(curr_missing_letter_index))
            {
              this.missing_letters.push(this.word_array[curr_missing_letter_index])
              this.missing_letter_indexes.push(curr_missing_letter_index)
            }
          }

          for(let n in this.missing_letter_indexes)
          {
            this.word_array_missing_letter[this.missing_letter_indexes[n]] = ''

          }
        }

        // if(this.task_type==='anagram')
        // {
        //   this.missing_letters = []
        //   this.missing_letter_indexes = []
        //   for(let k in this.word_array)
        //   {
        //     // this.missing_letter_indexes.push(k)
        //     // this.missing_letters.push(this.word_array[k])
        //   }
        // }


        this.create_option_buttons()


        
      },
      select_letter(letter,index)
      {
        let correct_letter = false

        if(this.task_type==='anagram')
        {
          let correct_letter_wrong_position = false
          for(let k in this.missing_letters)
          {
              if(this.missing_letters[k]===letter)
              {
                if(this.missing_letter_indexes[k]==(this.word_array_missing_letter.length-this.missing_letters.length))
                {
                  correct_letter = true

                  this.word_array_missing_letter[this.missing_letter_indexes[k]] = letter
                  this.missing_letter_indexes.splice(k,1)

                  this.missing_letters.splice(k,1)

                  this.option_buttons_class[index] = 'keyboard-button-correct'


                  this.$forceUpdate()

                }
                correct_letter_wrong_position = true

              }

          }

  
          if(correct_letter_wrong_position && !correct_letter)
          {
            this.option_buttons_class[index] = 'keyboard-button-incorrect'


            this.incorrect_answer(true)
            

          }
          else if(!correct_letter_wrong_position)
          {
            this.option_buttons_class[index] = 'keyboard-button-incorrect'

            this.incorrect_answer()


          }
          
          if(correct_letter)
          {
            for(let n in this.option_buttons_class)
            {
              if(this.option_buttons_class[n]==='keyboard-button-incorrect')
              {
                this.option_buttons_class[n] = 'keyboard-button'
              }
            }
          }


        }
        else
        {
          for(let k in this.missing_letters)
          {
            if(this.missing_letters[k]===letter)
            {
              correct_letter = true
              this.word_array_missing_letter[this.missing_letter_indexes[k]] = letter
              this.missing_letter_indexes.splice(k,1)

              this.missing_letters.splice(k,1)
              this.$forceUpdate()


              break
            }
          }

          if(!correct_letter)
          {
            this.incorrect_answer()

            this.option_buttons_class[index] = 'keyboard-button-incorrect'



          }
          else
          {
            this.option_buttons_class[index] = 'keyboard-button-correct'

          }

        }


        if(this.missing_letters.length==0)
        {

          this.correct_answer(this.single_target_word)

        }
        



        

      },
      sayInstructions()
      {
        if(this.cue_type==='Audio')
        {
          let text = 'Write the word; ' + this.single_target_word

          this.bv_tts.say(text)
        }
        else
        {
          let text = 'Name the picture'

          this.bv_tts.say(text)

        }

      },
      correct_answer(word)
      {
        const _this = this

        startConfetti(this)



        _this.answer_text = 'Congratulations! You wrote the right word: ' + word

        let audio_text_options = ['Correct',"That's right",'Good job']

        let audio_text = audio_text_options[Math.floor(Math.random()*audio_text_options.length)]

        _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'

        this.bv_tts.system_say(audio_text)

        if(this.results['total']==0)
        {
          this.updateLevel(true)
          _this.results['total'] = 1

          _this.results['correct'] = 1
          _this.results['incorrect'] = 0

        }


      },
      incorrect_answer(wrong_position=false)
      {
        const _this = this

        console.log('incorrect_answer = ' + wrong_position)

        if(wrong_position)
        {
          _this.answer_text = 'This is not the next letter on the word. Try again!'


        }
        else
        {
          _this.answer_text = 'Not quite right, try again!';

        }


        _this.answer_text_color = 'fontSize:24px;color:red;white-space: pre-wrap'

        if(this.results['total']==0)
        {
          this.updateLevel(false)
          _this.results['total'] = 1

          _this.results['correct'] = 0
          _this.results['incorrect'] = 1

          this.hover_sound_on = false

          setTimeout(() => {
            this.hover_sound_on = true
            }, 2000);


          _this.bv_tts.system_say(_this.answer_text)

        }


      },
      create_option_buttons()
      {
        const _this = this
        let temp_option_buttons = []

        const characters ='abcdefghijklmnopqrstuvwxyz';


        for(let n in this.missing_letter_indexes)
        {
          temp_option_buttons.push(this.word_array[this.missing_letter_indexes[n]])

        }

        console.debug('_this.n_option_letters = ' + _this.n_option_letters)
        console.debug('_this.missing_letter_indexes.length = ' + _this.missing_letter_indexes.length)

        if(this.task_type==='anagram')
        {
          _this.n_option_letters = _this.n_option_letters+_this.missing_letter_indexes.length
        }

        let k = 0

        while(temp_option_buttons.length<_this.n_option_letters || k>500)
        {

          let random_character = characters.charAt(Math.floor(Math.random() * characters.length));

          if(!temp_option_buttons.includes(random_character))
          {
            temp_option_buttons.push(random_character)
          }
          k = k+1

        }

        console.debug('temp_option_buttons.length = ' + temp_option_buttons.length)



        this.shuffle_option_letters(temp_option_buttons)




        this.$forceUpdate

      },
      shuffle_option_letters(temp_option_buttons)
      {

        this.n_option_letters = temp_option_buttons.length

        let all_indexes = []


        let reshuffled_option_letters = []


        for(let n in temp_option_buttons)
        {
          all_indexes.push(n)
          reshuffled_option_letters.push('0')

        }

        this.option_buttons_class = []


        for(let n in temp_option_buttons)
        {
          let rand_index = Math.floor(Math.random()*all_indexes.length)

          reshuffled_option_letters[all_indexes[rand_index]] = temp_option_buttons[n]

          all_indexes.splice(rand_index,1)

          this.option_buttons_class.push('keyboard-button')



        }


        this.option_buttons = reshuffled_option_letters

        this.$forceUpdate


      },
      found_correct_word(curr_answer)
      {
        const _this = this


        _this.best_answer = curr_answer

        _this.answer_text = 'Congratulations! You wrote the right word: ' + curr_answer
        _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
        _this.play_pause_text = 'Start'

        if(_this.results['total']==1)
        {
          _this.results['total'] = 1

          _this.results['correct'] = 1
          _this.results['incorrect'] = 0
          


        }

      },
      answer_check(type)
      {
        const _this = this

        let found_any_answer = false

        for(let k in _this.target_words)
        {
          let curr_target_word = _this.target_words[k]

          if(_this.written_answer.toLowerCase()==curr_target_word.toLowerCase())
          {

            found_any_answer = true
            break
          }
        }

    

        if(found_any_answer)
        {
          if(this.first_written_answer==='')
          {
            this.first_written_answer = this.written_answer
          }
          this.correct_answer(_this.written_answer)

        }
        else if(type=='manual')
        {
          if(this.first_written_answer==='')
          {
            this.first_written_answer = this.written_answer
          }

          this.incorrect_answer()
          
        }
      },
      showAnswer()
      {
        let _this = this;
        _this.answer_text = 'The word is '+_this.single_target_word;
        _this.answer_text_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'
        this.bv_tts.say(_this.answer_text)


        if(_this.results['correct'] == 0)
        {
          _this.showedAnswer = true
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
          _this.results['total'] = 1
        }
      },
    }
}
</script>

<style scoped>
.display-button
{
  width: 20mm;
  height: 20mm;
  font-size: xxx-large;
  margin-left: 1mm;
  background-color: white;
  border-color: blueviolet;
}

.keyboard-button
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  background-color: white;
  border-color: blue;

}

.keyboard-button-correct
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  color: white;
  background-color: green;
  border-color: green;

}

.keyboard-button-incorrect
{
  width: 20mm;
  height: 20mm;
  font-size: xx-large;
  margin-left: 5mm;
  margin-bottom: 5mm;
  color: white;
  background-color: red;
  border-color: red;

}

.keyboard-button:hover
{
  background-color:lightblue;
  transition: 0.7s;

}



.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: #195e52;
    height: 20mm;
    width: 35mm;
}
</style>



<!-- Results Descriptions
{
  "Name a picture": {
    "<key>": { 
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>",
        "category": "<string>",
        "difficulty": "<string>",
        "exercise": "Word Naming",
        "exercise_type": "Word Naming",
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Identified_Answer": "string",
            "Incorrect": "<int>", //right now this is always 0, but we should improve
            "Show_Answer": "<bool>",
            "Show_Options": "<bool>",
            "Sound_Hints": "<bool>",
            "Total": "<int>" //number tries
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        },
        "word": "<target_word>",
        "word_type": "<string>" //noun, verb, adjective
      },
      "results_type": "pre_computed"

    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Name a picture"
  }
} -->


<!--
{
  "Name a picture": { --decide the module name ID (this or 'Word Naming')
    "<key>": { --move under 'exercises'
      "audio_address": "<empty>", --not used remove
      "resultsTablePre": {
        "audio_address": "<empty>", --not used remove
        "category": "<string>",
        "difficulty": "<string>",
        "exercise": "Word Naming", --change to 'Name of picture'
        "exercise_type": "Word Naming", --change to 'Name a picture'
        "results": {
          "all_results": {
            "Correct": "<int>",
            "Identified_Answer": "string",
            "Incorrect": "<int>", //right now this is always 0, but we should improve
            "Show_Answer": "<bool>",
            "Show_Options": "<bool>",
            "Sound_Hints": "<bool>",
            "Total": "<int>" //number tries
          },
          "plot_results": {
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        },
        "word": "<target_word>",
        "word_type": "<string>" //noun, verb, adjective
      },
      "results_type": "pre_computed" --not used remove

    },
    "assessment_type": "USER_TRAINING/SLP_TRAINING",
    "plan_name": "Name a picture" --not used remove
  }
} -->