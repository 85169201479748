<template>
  <div>
    <vue3-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1800"
        :filename=clientUID
        :pdf-quality="2"
        :manual-pagination="false"
        :margin="[10, 10]"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @progress="onProgress($event)"
        
        ref="html2Pdf"
    > 
      <template  v-slot:pdf-content>
        <div class="card card-body" v-show="session_date!=null">
          <div class="container">
            <section class="pdf-item">
              <div class="row justify-content-center text-center mb-4">
                <div class="col">
                  <img src="../../../../../../assets/images/logo/logo_white.svg" alt="" class="d-inline-block align-text-top">
                </div>
              </div>
              <div class="row text-center mb-4">
                <h5>
                  <b>
                    Assessment Package Report
                  </b>
                </h5>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col text-start">
                  Patient: {{client_name}}
                </div>
                <div class="col text-center">
                  SLT:{{slt_name }}
                </div>
                <div class="col text-end">
                  Session on the {{session_date}} 
                </div>
              </div>              
              <div class="row mt-4">
                <p class="lead text-center">
                  Session summary
                </p>
                <textarea class="form-control"
                  placeholder="Please add an overview summary (if any)"
                  v-model="text_overview"/>
              </div>
            </section>

            <section class="pdf-item" v-if="!found_results_for_date">
              <div class="row text-center">
                <div class="card card-body" >
                  <p> Unfortunately we don't yet support reports for the exercises/assessments conducted on this date.</p>
                  <p>This will be included in the near future</p>
                </div>
              </div>
            </section>

            <section class="pdf-item" v-if="all_results_tables.tokenAssessmentTable.length>0">
              <div class="row mt-2">
                <div class="card card-body" style="border-color: blueviolet;">

                  <div class="row">
                    <div class="col-7 text-start">
                      <p><b>Token test</b></p>
                    </div>
                    <div class="col-5 text-end">
                      <p>conducted from {{tokenAssessmentOverview.time_start}} to {{tokenAssessmentOverview.time_end}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p>Correct answers:</p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{tokenAssessmentOverview.correct}} out of {{tokenAssessmentOverview.total}} ({{ tokenAssessmentOverview.percentage}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row justify-content-center m-4">
                    <p class="lead text-center">Result breakdown</p>
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p>Correct answers on shapes:</p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{tokenAssessmentOverview.overview_difficulties['shape']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['shape']['total']}} ({{tokenAssessmentOverview.overview_difficulties['shape']['percentage']}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:grey;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p>Correct answers on colours:</p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{tokenAssessmentOverview.overview_difficulties['color']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['color']['total']}} ({{tokenAssessmentOverview.overview_difficulties['color']['percentage']}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:grey;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p>Correct answers on sizes:</p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{tokenAssessmentOverview.overview_difficulties['size']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['size']['total']}} ({{tokenAssessmentOverview.overview_difficulties['size']['percentage']}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:grey;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p>Correct answers on multi selections:</p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{tokenAssessmentOverview.overview_difficulties['multi']['correct']}} out of  {{tokenAssessmentOverview.overview_difficulties['multi']['total']}} ({{tokenAssessmentOverview.overview_difficulties['multi']['percentage']}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                    Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_token_assessment"/>
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.memoryImageTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.memoryImageTable.length>0">
              <div class="row mt-2">
                <div class="card card-body"  style="border-color: blueviolet;">
                  
                  <div class="row">
                    <div class="col-7 text-start">
                      <p><b>Memory image</b></p>
                    </div>
                    <div class="col-5 text-end">
                      <p>conducted from {{memoryImageOverview.time_start}} to {{memoryImageOverview.time_end}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p><b>Correct answers:</b></p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{memoryImageOverview.correct}} out of {{memoryImageOverview.total}} ({{ memoryImageOverview.percentage}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p><b>No. of exercises requiring instruction replay:</b></p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{memoryImageOverview.ex_multi_listen}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                    Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_memory_image"/>
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.pictureNamingTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.pictureNamingTable.length>0">
              <div class="row mt-2">
                <div class="card card-body"  style="border-color: blueviolet;">
                  <div class="row">
                    <div class="col-7 text-start">
                      <p><b>Picture naming</b></p>
                    </div>
                    <div class="col-5 text-end">
                      <p>conducted from {{pictureNamingOverview.time_start}} to {{pictureNamingOverview.time_end}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>

                  <div class="row">
                    <div class="col-6 text-start">
                      <p>
                      Correct answers: 
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{pictureNamingOverview.correct}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.percentage}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  
                  <div class="row">
                    <div class="col-6 text-start">
                      <p>
                        Use hints in: 
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{pictureNamingOverview.total_hints}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.hint_percentage}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Show choices in:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{pictureNamingOverview.total_options}} out of {{pictureNamingOverview.total}} ({{ pictureNamingOverview.option_percentage}}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row justify-content-center m-4">
                    <p class="lead text-center">
                      Result breakdown
                    </p>
                  </div>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <th>Words</th>
                            <th>Category</th>
                            <th>Correct</th>
                            <th>Show Choices</th>
                            <th>Sound Hint</th>
                            <th>Show Answer</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="(item, index) in all_results_tables.pictureNamingTable" :key="index" >
                            <td>{{ item.word }}</td>
                            <td>{{ item.category }}</td>
                            <td>
                              <img v-if="item.correct" :src="tickImg" style="width:15px;">
                              <img v-else :src="crossImg" style="width:15px;">
                            </td>
                            <td>
                              <img v-if="item.show_options" :src="tickImg" style="width:15px;">
                              <img v-else :src="crossImg" style="width:15px;">
                            </td>
                            <td>
                              <img v-if="item.sound_hints" :src="tickImg" style="width:15px;">
                              <img v-else :src="crossImg" style="width:15px;">
                            </td>
                            <td>
                              <img v-if="item.show_answer" :src="tickImg" style="width:15px;">
                              <img v-else :src="crossImg" style="width:15px;">
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>  
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_pictureNaming" />
                  </div>
                </div>
              </div>
            </section>
            
            <div class="html2pdf__page-break" v-if="all_results_tables['questionnaires'] && all_results_tables['questionnaires'].length>0"/>

            <section class="pdf-item" v-if="all_results_tables['questionnaires'] && all_results_tables['questionnaires'].length>0">
              <div class="row mt-2">
                <div class="card card-body"  style="border-color: blueviolet;">
                  
                  <div class="row mb-2">
                    <div class="col-12 text-start">
                      <p>
                        <b>Questionnaire</b>
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>

                  <div class="row mb-2">
                    <div v-for="questionnaire, i of all_results_tables['questionnaires']">
                      <div v-for="questions, index of all_results_tables['questionnaires'][i]['questions']">
                        <div class="mt-2">
                          <div class="row">
                            <div class="col-2">
                              <p class="text-start">
                                Question {{index+1}}: 
                              </p>
                            </div>
                            <div class="col-10">
                              <p class="text-start">
                                {{ questions }}
                              </p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-2">
                              <p class="text-start">
                                Answer:
                              </p>
                            </div>
                            <div class="col-10">
                              <p class="text-start">
                                {{ all_results_tables['questionnaires'][i]['answers'][index] }}
                              </p>
                            </div>
                          </div>  
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_phonation"/>
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.pitchVariationTable.length>0"/>

              <section class="pdf-item" v-if="all_results_tables.pitchVariationTable.length>0">
                <div class="row mt-2">
                  <div class="card card-body"  style="width:100%;border-color: blueviolet;">
                    <div class="row mb-2">
                      <div class="col-7 text-start">
                        <p>
                          <b>Pitch range results</b>
                        </p>
                      </div>
                      <div class="col-5 text-end">
                        <p>conducted from {{pitchVariationOverview.time_start}} to {{pitchVariationOverview.time_end}}</p>
                      </div>
                      <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                    </div>
                    <div class="row justify-content-center m-4">
                      <p class="lead text-center">
                        Result breakdown
                      </p>
                    </div>

                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr class="text-center">
                              <th>Name</th>
                              <th scope="col">Loudness (dB)</th>
                              <th scope="col">Pitch Range (Hz)</th>
                              <th scope="col">Maximum Pitch (Hz)</th>
                              <th scope="col">Minimum Pitch (Hz)</th>
                              <th scope="col">Average Pitch (Hz)</th>
                              <th v-if="show_elements" scope="col">Playback</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="text-center" v-for="(item, index) in all_results_tables.pitchVariationTable" :key="index" >
                              <td>{{ item.name }}</td>
                              <td>{{ item.loudness }}</td>
                              <td>{{ item.freq_range }}</td>
                              <td>{{ item.max_freq }}</td>
                              <td>{{ item.min_freq }}</td>
                              <td>{{ item.mean_freq }}</td>
                              <td v-if="show_elements"><button class="btn btn-danger" @click="playRecording(item)"><i class="bi bi-volume-up"></i></button></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <hr style="height:2px;border:none;color:#333;background-color:green;" />
                    </div>

                    <div class="row mt-4 ps-2 pe-2">
                      <p class="lead text-center">
                        Additional notes
                      </p>
                      <textarea class="form-control"
                        placeholder="Please add additional comments (if any)"
                        v-model="text_phonation"/>  
                    </div>
                  </div>
                </div>
              </section>


            <div class="html2pdf__page-break" v-if="all_results_tables.phonationTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.phonationTable.length>0">
              <div class="row mt-2">
                <div class="card card-body"  style="border-color: blueviolet;">

                  <div class="row mb-2">
                    <div class="col-7 text-start">
                      <p>
                        <b>Sound phonation</b>
                      </p>
                    </div>
                    <div class="col-5 text-end">
                      <p>conducted from {{phonationOverview.time_start}} to {{phonationOverview.time_end}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Avg. phonation duration:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p> {{phonationOverview.average_duration}}s </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Longest phonation:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p> {{phonationOverview.highest_duration}}s </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Shortest phonation:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{phonationOverview.lowest_duration}}s </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Average loudness:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{phonationOverview.average_loudness}}dB </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <!-- <span>
                      Result summary: <br>Average phonation duration: {{phonationOverview.average_duration}}s. <br> 
                      Longest phonation: {{phonationOverview.highest_duration}}s. Shortest phonation: {{phonationOverview.lowest_duration}}s.<br>  Total Exercises: {{phonationOverview.total_exercises}}
                    </span> -->
                  <div class="row justify-content-center m-4">
                    <p class="lead text-center">
                      Result breakdown
                    </p>
                  </div>

                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <th>Exercise</th>
                            <th>Loudness (dB)</th>

                            <th>Duration (s)</th>
                            <th v-if="show_elements">Playback</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="(item, index) in all_results_tables.phonationTable" :key="index" >
                            <td>{{ item.name }}</td>
                            <td>{{ item.loudness }}</td>

                            <td>{{ item.max_phonation }}</td>
                            <td v-if="show_elements"><button class="btn btn-danger" @click="playRecording(item)"><i class="bi bi-volume-up"></i></button></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_phonation"/>
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.word_findingTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.word_findingTable.length>0">
              <div class="row mt-2">
                <div class="card card-body"  style="border-color: blueviolet;">
                  <div class="row mb-2">
                    <div class="col-7 text-start">
                      <p>
                        <b>Semantic fluency</b>
                      </p>
                    </div>
                    <div class="col-5 text-end">
                      <p>conducted from {{word_findingOverview.time_start}} to {{word_findingOverview.time_end}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Avg. number of words answered:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{word_findingOverview.average_words}} per topic</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Topic with least answers:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p><span v-for="it in word_findingOverview.leastTopic.length">{{ word_findingOverview.leastTopic[it-1].topic }},</span></p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  
                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Use hints in:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{ word_findingOverview.total_hint }} out of {{ word_findingOverview.total }}  topics ({{ word_findingOverview.hint_percentage }}%)</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Avg. number of hints used:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{word_findingOverview.average_hints}} per topic</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row justify-content-center m-4">
                    <p class="lead text-center">
                      Result breakdown
                    </p>
                  </div>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <th>Topic</th>
                            <th>Number of words answered</th>
                            <th>Number of hints used</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="(item, index) in all_results_tables.word_findingTable" :key="index" >
                            <td>{{ item.Topic }}</td>
                            <td>{{ item.Number_Words }}</td>
                            <td>{{ item.Number_Hints }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_phonation" />
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.ddkTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.ddkTable.length>0">
              <div class="row mt-2">
                <div class="card card-body"  style="width:100%;border-color: blueviolet;">
                  <div class="row mb-2">
                    <div class="col-7 text-start">
                      <p>
                        <b>DDK rate</b>
                      </p>
                    </div>
                    <div class="col-5 text-end">
                      <p>conducted from {{ddkOverview.time_start}} to {{ddkOverview.time_end}}</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>
                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Single syllable (Pa/Ta/Ka):
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>{{ddk_interp.single}} repetitions in {{ ddk_interp.duration }}s &emsp; &emsp; ({{ ddk_interp.single_rating }})</p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Double syllables (/Ka La/):
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>
                        {{ddk_interp.double}} 
                        <span v-if="ddk_interp.double!== 'NA'">
                          repetitions in {{ ddk_interp.duration }}s &emsp; &emsp; ({{ ddk_interp.double_rating }})
                        </span>
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Triple syllables (/Pa Ta Ka/):
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>
                        {{ddk_interp.triple}} repetitions in {{ ddk_interp.duration }}s &emsp; &emsp; ({{ ddk_interp.triple_rating }})
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row justify-content-center m-4">
                    <p class="lead text-center">
                      Result breakdown
                    </p>
                  </div>

                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <th>Name</th>
                            <th>Loudness (dB)</th>

                            <th>Number repetitions</th>
                            <th>Test duration (s)</th>
                            <th>Score</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="(item, index) in all_results_tables.ddkTable" :key="index" >
                            <td>{{ item.name }}</td>
                            <td>{{ item.loudness }}</td>

                            <td>{{ item.number_repetitions }}</td>
                            <td>{{ item.test_duration }}</td>
                            <td>{{ item.interp }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_phonation"/>  
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.intelligibilityTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.intelligibilityTable.length>0">
              <div class="row mt-2">
                <div class="card card-body" style="border-color: blueviolet;">
                  <div class="row mb-2">
                    <div class="col-7 text-start">
                      <p>
                        <b>Word intelligibility</b>
                      </p>
                    </div>
                    <div class="col-5 text-end">
                      <p>
                        conducted from {{intelligibilityOverview.time_start}} to {{intelligibilityOverview.time_end}}
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Words with weakest intelligibility:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>
                        {{intel_words[0]}},
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>

                  <div class="row mb-2">
                    <div class="col-6 text-start">
                      <p>
                        Phonemes with weakest intelligibility:
                      </p>
                    </div>
                    <div class="col-6 text-start">
                      <p>
                        {{intelligibilityOverview.phonemes_2_work_on}} 
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <!-- <br><br>
                  3 words with weak intelligibility: {{intel_words[0]}}, {{intel_words[1]}}, {{intel_words[2]}} <br>
                  3 phonemes with weak intelligibility: {{intelligibilityOverview.phonemes_2_work_on}} -->
                  <!-- </span> -->
                  <div class="row">
                    <span style="float:left">Result breakdown:</span>
                  </div>

          

                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <th>Name</th>
                            <th>Loudness (dB)</th>

                            <th>(%)</th>
                            <th>Pronunciation breakdown</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="item, index in all_results_tables.intelligibilityTable" :key="index" >
                            <td>{{ item.name }}</td>
                            <td>{{ item.loudness }}</td>

                            <td>{{ item.accuracy }}</td>
                            <td>
                              <b v-for="(pho_word, id) in item.PhoWordGrade" :key="pho_word"
                                :style="item.PhoWordGrade[id] === 'Needs Improvement' ? 'color:#ff0000' : item.PhoWordGrade[id] === 'Very Good' ? 'color:#00b33c' : 'color:#cca300'" class="custom-text20"> 
                                {{item.phoWord_display[id]}}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_intelligibility"/>
                  </div>
                </div>
              </div>
            </section>

            <div class="html2pdf__page-break" v-if="all_results_tables.sentence_intelligibilityTable.length>0"/>

            <section class="pdf-item" v-if="all_results_tables.sentence_intelligibilityTable.length>0">
              <div class="row mt-2">
                <div class="card card-body" style="border-color: blueviolet;">
                  <div class="row mb-2">
                    <div class="col-7 text-start">
                      <p>
                        <b>Sentence intelligibility</b>
                      </p>
                    </div>
                    <div class="col-5 text-end">
                      <p>
                        conducted from {{sentence_intelligibilityOverview.time_start}} to {{sentence_intelligibilityOverview.time_end}}
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
                  </div>
                  <div class="row mb-2" v-if="sentence_intelligibilityOverview.words_2_work_on!=null">
                    <div class="col-5 text-start">
                      <p>
                        Words with weakest intelligibility:
                      </p>
                    </div>
                    <div class="col-7 text-start">
                      <p>
                        {{sentence_intelligibilityOverview.words_2_work_on}} 
                      </p>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <!-- <div class="row" style="float:left;"> 
                    Average intelligibility for 1 syllable words: &emsp; &emsp; {{sentence_intelligibilityOverview.average_syllable_count_score[0]}} 
                  </div>
                  <br>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" /> -->

                  <div class="row justify-content-center m-4">
                    <p>Result break down</p>
                  </div>
                  <div class="row">
                    <div class="table-responsive">
                      <table class="table table-hover">
                        <thead>
                          <tr class="text-center">
                            <!-- <th>Name</th> -->
                            <th>(dB)</th>

                            <th>(%)</th>
                            <th>Sentence pronunciation quality breakdown</th>
                            <th v-if="show_elements">Playback</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="text-center" v-for="(row, index) in all_results_tables.sentence_intelligibilityTable" :key="index" >
                            <!-- <td>{{ row.name }}</td> -->
                            <td>{{ row.loudness }}</td>

                            <td>{{ row.accuracy }}</td>

                            <td>
                              <div class="mt-2">
                                <p v-for="(word, id) in row.WordGrade" :key="word" :class="['d-inline h4', {'text-secondary' : row.WordGrade[id] === 'Omission', 'text-danger' : row.WordGrade[id] === 'Needs Improvement', 'text-success' : row.WordGrade[id] === 'Very Good', 'text-warning' : row.WordGrade[id] === 'OK'}]" style="cursor: pointer;"  @click="listenPhoneme(row.Words[id])"><strong>{{row.Words[id]}} &nbsp;</strong></p>
                              </div>
                            </td>

                            <td v-if="show_elements"><button class="btn btn-danger" @click="playRecording(row)"><i class="bi bi-volume-up"></i></button></td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                    <hr style="height:2px;border:none;color:#333;background-color:green;" />
                  </div>
                  <div class="row mt-4 ps-2 pe-2">
                    <p class="lead text-center">
                      Additional notes
                    </p>
                    <textarea class="form-control"
                      placeholder="Please add additional comments (if any)"
                      v-model="text_sentence_intelligibility"/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="row justify-content-center mt-4" v-if="session_date!=null">
      <div class="col">
        <button class="btn btn-primary w-25" @click="generatePDFReport()"><i class="bi bi-download"></i> Download Report</button>
      </div>
    </div>
  </div>
</template>


<script>
import backImg from '@/assets/images/back.png'
import planImg from '@/assets/images/plan.png'
import warnImg from '@/assets/images/warn.png'
import addImg from '@/assets/images/add.png'
import {getDataAndDates, generateAssessmentReport} from '@/utils/report_generation'
import { roundNumber } from "@/utils/utils"

import crossImg from '@/assets/images/wrong_cross.png'
import tickImg from '@/assets/images/Tick.png'

import Vue3Html2pdf from 'vue3-html2pdf'

export default {
  name: 'Report',
  props:{
    assessment_results_address: String,
    session_date: String
  },
  components: {
    Vue3Html2pdf
  },
  data() {
    return {
      client_name:'',
      slt_name:'',
      data_assessment:[],
      dates:[],
      planImg: planImg,
      warnImg: warnImg,
      addImg: addImg,
      backImg: backImg,
      tickImg:tickImg,
      crossImg:crossImg,
      found_results_for_date:true,
      all_results_tables:{phonationTable:[],word_findingTable:[],sentence_intelligibilityTable:[],intelligibilityTable:[],pictureNamingTable:[],tokenAssessmentTable:[],memoryImageTable:[],ddkTable:[],pitchVariationTable:[]},
      word_findingOverview:{},
      phonationOverview:{},
      intelligibilityOverview:{},
      sentence_intelligibilityOverview:{},
      ddkOverview:{},
      pitchVariationOverview:{},
      pictureNamingOverview:{},
      tokenAssessmentOverview:{},
      memoryImageOverview:{},
      selected_session: '',
      text_overview: '',
      text_intelligibility: '',
      text_sentence_intelligibility: '',
      text_memory_image:'',
      text_phonation:'',
      text_wordFinding:'',
      text_pictureNaming:'',
      text_ddkrate:'',
      text_token_assessment:'',
      ddk_interp:{
        single:'NA',
        double:'NA',
        triple: 'NA'
      },
      intel_words:['','',''],
      clientUID: this.$route.query.uid,
      show_elements: true,
    }
  },

  created() {
    const _this = this
    _this.client_name = localStorage.getItem('clientName');
    _this.slt_name = localStorage.getItem('slt_name');

  },
  watch: {
    session_date(){
      this.getDataAndDates()
    }
  },
  mounted: function()
  {
    this.getDataAndDates()
  },
  methods:
  {
    generatePDFReport () {
      this.show_elements = false
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(progress) {
      if (progress == 100) {
        this.show_elements = true
      }
    },
    async getDataAndDates()
    {
      const _this = this

      console.debug(_this.assessment_results_address)

      let promise_data_dates = [getDataAndDates(_this.assessment_results_address)]

      const resolved_promise = await Promise.all(promise_data_dates)

      let data_dates_assessment = resolved_promise[0]

      let all_dates_assessment = data_dates_assessment.all_dates

      let data_assessment = data_dates_assessment.data

      _this.dates = []

      for(let i in all_dates_assessment)
      {
        if(!(_this.dates.includes(all_dates_assessment[i])))
        {
          _this.dates.push(all_dates_assessment[i])
        }
      }

      _this.dates = _this.dates.sort(function (a, b) {
        if (a < b) {
          return 1;
        }
        if (a > b) {
          return -1;
        }
        return 0;
      });

      _this.data_assessment = data_assessment
      await _this.generateReport()
    },
    async generateReport()
    {
      const _this = this

      let curr_report = await generateAssessmentReport(_this.data_assessment, _this.session_date)
      _this.found_results_for_date = _this.populate_with_report_data(curr_report)
    },
    populate_with_report_data(curr_report)
    {
      const _this = this
      
      if('overview' in curr_report)
      {
        _this.session_date = curr_report.overview.date
      }

      if('picture_naming' in curr_report)
      {
          _this.pictureNamingOverview = curr_report.picture_naming.overview
          _this.all_results_tables.pictureNamingTable = curr_report.picture_naming.individual_outputs
          let total_hints = 0;
          let total_options = 0;
          for(let i in _this.all_results_tables.pictureNamingTable)
          {
            // console.debug('pic table = '+  _this.all_results_tables.pictureNamingTable[i].correct);
            if(_this.all_results_tables.pictureNamingTable[i].sound_hints)
            {
              total_hints = total_hints +1;
            }
            if(_this.all_results_tables.pictureNamingTable[i].show_options)
            {
              total_options = total_options + 1;
            }
          }
          _this.pictureNamingOverview['percentage'] = Math.ceil(_this.pictureNamingOverview.correct/_this.pictureNamingOverview.total *100)
          _this.pictureNamingOverview['total_hints'] = total_hints;
          _this.pictureNamingOverview['total_options'] = total_options;
          _this.pictureNamingOverview['hint_percentage'] = Math.ceil(total_hints/_this.pictureNamingOverview.total *100)
          _this.pictureNamingOverview['option_percentage'] = Math.ceil(total_options/_this.pictureNamingOverview.total *100)
      }

      if('phonation' in curr_report)
      {
        _this.phonationOverview = curr_report.phonation.overview
        _this.all_results_tables.phonationTable = curr_report.phonation.individual_outputs
        // console.debug('avg loudness = ' + _this.phonationOverview.average_loudness + ' avg duration= '+_this.phonationOverview.average_duration)
      }

      if('questionnaires' in curr_report)
      {
        _this.all_results_tables['questionnaires'] = curr_report.questionnaires
      }

      if('token_assessment' in curr_report)
      {
          _this.tokenAssessmentOverview = curr_report.token_assessment.overview
          _this.all_results_tables.tokenAssessmentTable = curr_report.token_assessment.individual_outputs
          _this.tokenAssessmentOverview['percentage'] = Math.ceil(_this.tokenAssessmentOverview.correct/_this.tokenAssessmentOverview.total *100)
          
          console.debug('_this.tokenAssessmentTable = ' + _this.all_results_tables.tokenAssessmentTable.length)

      }

      if('memory_image' in curr_report)
      {
        console.debug('memory_image')
          _this.memoryImageOverview = curr_report.memory_image.overview
          _this.all_results_tables.memoryImageTable = curr_report.memory_image.individual_outputs
          _this.memoryImageOverview['percentage'] = Math.ceil(_this.memoryImageOverview.correct/_this.memoryImageOverview.total *100)
          console.debug('_this.memoryImageTable.length = ' + _this.all_results_tables.memoryImageTable.length)
      }

      if('word_finding' in curr_report)
      {
          _this.word_findingOverview = curr_report.word_finding.overview
          _this.all_results_tables.word_findingTable = curr_report.word_finding.individual_outputs
          let sortable = _this.all_results_tables.word_findingTable.toSorted((a, b) => {
              return a.Number_Words - b.Number_Words;
          });
          let leastTopic = [];
          let total_hint = 0;
          for(let count_hint in sortable)
          {
            if(count_hint <3)
            {
              if(sortable[count_hint].Number_Words < 10)
              {
                leastTopic.push({topic: sortable[count_hint].Topic,num_words : sortable[count_hint].Number_Words})
              }
              else
              {
                leastTopic[0] = {topic: sortable[0].Topic,num_words : sortable[0].Number_Words}
              }
            }
            if(sortable[count_hint].Number_Hints>0)
            {
              total_hint = total_hint + 1;
            }
          }
                  // console.debug('sort topic = '+sortable[0].Topic)
                  // console.debug('least topic = '+leastTopic[1].topic)
          _this.word_findingOverview['leastTopic'] = leastTopic
          _this.word_findingOverview['total_hint'] = total_hint
          _this.word_findingOverview['total'] = _this.all_results_tables.word_findingTable.length
          _this.word_findingOverview['hint_percentage'] = Math.ceil(total_hint/_this.all_results_tables.word_findingTable.length *100)
          // console.debug('length = '+  _this.word_findingOverview.leastTopic.length)
      }

      if('sentence_intelligibility' in curr_report)
      {
          _this.sentence_intelligibilityOverview = curr_report.sentence_intelligibility.overview
          _this.all_results_tables.sentence_intelligibilityTable = curr_report.sentence_intelligibility.individual_outputs
      }



      if('intelligibility' in curr_report)
      {
          _this.intelligibilityOverview = curr_report.intelligibility.overview
          _this.all_results_tables.intelligibilityTable = curr_report.intelligibility.individual_outputs
          let intel_acc = [];
          let newArr = []
          for(let n=0; n<_this.all_results_tables.intelligibilityTable.length;n++)
          {
            intel_acc.push(_this.all_results_tables.intelligibilityTable[n]['accuracy']);
          }
          newArr = intel_acc.map(function(o, i){return {idx: i, obj: o}; }).sort(function(a, b) {
              return a.obj - b.obj;
          });
          if(newArr.length>0)
          {
            for(let k=0;k<Math.min(newArr.length,3);k++)
            {
              let id = newArr[k].idx;
              _this.intel_words[k] = _this.all_results_tables.intelligibilityTable[id]['name'] +' ('+_this.all_results_tables.intelligibilityTable[id]['accuracy']+'%)';
            }

          }

      }

      if('pitch_variation' in curr_report)
      {

        _this.pitchVariationOverview = curr_report.pitch_variation.overview
        _this.all_results_tables.pitchVariationTable = curr_report.pitch_variation.individual_outputs


      }



      if('ddk_rate' in curr_report)
      {
          _this.ddkOverview = curr_report.ddk_rate.overview
          _this.all_results_tables.ddkTable = curr_report.ddk_rate.individual_outputs
          // console.debug("ddk ="+ _this.ddkTable[0]['name'])
          let avg_single_count = 0;
          let single_num = 0;
          let avg_double_count = 0;
          let double_num = 0;
          let avg_triple_count = 0;
          let triple_num = 0;

          
          for(let i=0; i<_this.all_results_tables.ddkTable.length;i++)
          {
            console.debug("_this.ddkTable[i]['name'] = " + _this.all_results_tables.ddkTable[i]['name'])
            if((_this.all_results_tables.ddkTable[i]['name'] == 'Pa') || (_this.all_results_tables.ddkTable[i]['name'] == 'Ta') || (_this.all_results_tables.ddkTable[i]['name'] == 'Ka'))
            {
              avg_single_count = avg_single_count + _this.all_results_tables.ddkTable[i]['number_repetitions'];
              single_num = single_num + 1;
              _this.all_results_tables.ddkTable[i]['interp'] = _this.compare_ddkCounts(_this.all_results_tables.ddkTable[i]['number_repetitions'],'single',true);
            }
            else if(_this.all_results_tables.ddkTable[i]['name'] == 'Ka La')
            {
              avg_double_count = avg_double_count + _this.all_results_tables.ddkTable[i]['number_repetitions'];
              double_num = double_num + 1;
              _this.all_results_tables.ddkTable[i]['interp'] = _this.compare_ddkCounts(_this.all_results_tables.ddkTable[i]['number_repetitions'],'double',true);
            }
            else if(_this.all_results_tables.ddkTable[i]['name'] == 'Pa Ta Ka')
            {
              avg_triple_count = avg_triple_count + _this.all_results_tables.ddkTable[i]['number_repetitions'];
              triple_num = triple_num + 1;
              _this.all_results_tables.ddkTable[i]['interp'] = _this.compare_ddkCounts(_this.all_results_tables.ddkTable[i]['number_repetitions'],'triple',true);
            }
          }
          if(avg_single_count != 0)
          {
            let single_score = avg_single_count/single_num;
            single_score = roundNumber(single_score,0)
            _this.ddk_interp.single = single_score 
            _this.ddk_interp['single_rating'] = _this.compare_ddkCounts(single_score,'single',false)
          }
          if(avg_double_count != 0)
          {
            let double_score = avg_double_count/double_num;
            double_score = roundNumber(double_score,0)
            _this.ddk_interp.double = double_score 
            _this.ddk_interp['double_rating'] = _this.compare_ddkCounts(double_score,'double',false)
          }
          if(avg_triple_count != 0)
          {
            let triple_score = avg_triple_count/triple_num;
            triple_score = roundNumber(triple_score,0)
            _this.ddk_interp.triple = triple_score 
            _this.ddk_interp['triple_rating'] = _this.compare_ddkCounts(triple_score,'triple',false)
          }

          if(_this.all_results_tables.ddkTable.length>0)
          {
            _this.ddk_interp['duration'] = _this.all_results_tables.ddkTable[0]['test_duration'] 

          }
          console.debug('double score= '+ _this.ddk_interp.double)
      }

      let results_to_display_for_date = false

      for(let i in _this.all_results_tables)
      {

        if(_this.all_results_tables[i].length>0)
        {
          results_to_display_for_date = true

          break
        }

      }

      return results_to_display_for_date


    },
    playRecording(row)
    {
      console.log(row)
      let audio_file_location = row['AudioAddress']

      this.playAudio(audio_file_location)
    },
    playAudio(audio_file_location)
    {
      let _this = this;
      var storage = _this.$firebase.storage();

      storage.ref(audio_file_location).getDownloadURL()

      .then((url) => {
        _this.play(url);

      })
      .catch((error) => {
      });
    
    },
    play(url) {
      const _this = this

      if(_this.audio_player == null)
      {
        _this.audio_player = new Audio(url);
        _this.audio_player.play();
      } else {
        if(!_this.audio_player.paused) {
          _this.audio_player.pause();
        }
        _this.audio_player = new Audio(url);
        _this.audio_player.play();
      }
    },
    handleSelect()
    {
      
    },
    compare_ddkCounts(counts, type, metadata)
    {
      if(type == 'single')
      {
        if(counts >=20)
        {
          let op_str = 'Normal'
          if(metadata)
          {
            op_str = op_str + ' (20-30)';
          }
          
          return op_str
        }
        else if((counts>=15) && (counts<20))
        {
          let op_str = 'Good'
          if(metadata)
          {
            op_str = op_str + ' (15-19)';
          }
          
          return op_str
        }
        else if((counts>=8) && (counts<15))
        {
          let op_str = 'Fair'
          if(metadata)
          {
            op_str = op_str + ' (8-14)';
          }
          
          return op_str
        }
        else
        {
          let op_str = 'Poor'
          if(metadata)
          {
            op_str = op_str + ' (1-7)';
          }
          
          return op_str
        }
      }
      else if(type == 'double')
      {
        if(counts >=15)
        {
          let op_str = 'Normal'
          if(metadata)
          {
            op_str = op_str + ' (15-20)';
          }
          
          return op_str
        }
        else if((counts>=10) && (counts<15))
        {
          let op_str = 'Good'
          if(metadata)
          {
            op_str = op_str + ' (10-14)';
          }
          
          return op_str
        }
        else if((counts>=5) && (counts<10))
        {
          let op_str = 'Fair'
          if(metadata)
          {
            op_str = op_str + ' (5-9)';
          }
          
          return op_str
        }
        else
        {
          let op_str = 'Poor'
          if(metadata)
          {
            op_str = op_str + ' (1-4)';
          }
          
          return op_str
        }
      }
      else if(type == 'triple')
      {
        if(counts >=12)
        {
          let op_str = 'Normal'
          if(metadata)
          {
            op_str = op_str + ' (12-15)';
          }
          
          return op_str
        }
        else if((counts>=8) && (counts<12))
        {
          let op_str = 'Good'
          if(metadata)
          {
            op_str = op_str + ' (8-11)';
          }
          
          return op_str
        }
        else if((counts>=4) && (counts<8))
        {
          let op_str = 'Fair'
          if(metadata)
          {
            op_str = op_str + ' (4-7)';
          }
          
          return op_str
        }
        else
        {
          let op_str = 'Poor'
          if(metadata)
          {
            op_str = op_str + ' (1-3)';
          }
          
          return op_str
        }
      }
    },
  }
}
</script>
<style scoped>

li:hover {
background-color: #e0e0e2;
color: white;
}
</style>





