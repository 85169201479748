<template>
  <div>
    <h1 class="display-3 mt-4"><strong>Register</strong></h1>
    <div class="row">
      <div class="col-12">
        <div clas="row">
          <div class="min-vh-100">
              <FormKit type="form" :actions="false" classs="mt-5" id="form" v-model="form" #default="{ isValid }" 
                :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'h5' },
                }"
                messsages-class="mb-2"
                message-class="text-danger"                
                @submit="register()">

                <div class="pt-4 mb-4">
                  <label class="form-label" for="firstname">Name <span style="color:red">*</span></label>
                  <FormKit outer-class="$reset only-me-please" input-class="form-control" id="firstname" name="firstname" v-model="form.firstname" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                    }"
                    messages-class="text-danger"
                    validation="required" :disabled="registering"/>
                </div>
                <div class="mb-4">
                  <label class="form-label" for="surname">Surname <span style="color:red">*</span></label>
                  <FormKit input-class="form-control" id="surname" name="surname" v-model="form.surname" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                    }"
                    messages-class="text-danger"
                    validation="required" :disabled="registering"/>
                </div>
                <div class="mb-4">
                  <label class="form-label" for="email">Email <span style="color:red">*</span></label>
                  <FormKit input-class="form-control" id="email" type="email" name="email" v-model="form.email" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                    }"
                    messages-class="text-danger"
                    validation="required|email" 
                    :disabled="registering"
                    />
                </div>
                <div class="mb-4">
                  <label class="form-label" for="password">Password <span style="color:red">*</span></label>
                  <FormKit input-class="form-control" id="password" type="password" name="password" v-model="form.password" 
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                    }"
                    messages-class="text-danger"
                    validation="required|length:6,20" :disabled="registering" />
                </div>

                <div class="mt-4 mb-2">
                  <FormKit
                    type="checkbox"
                    label-position="before"
                    label="What is your field of interest? (select one or more)"
                    v-model="form.foi"
                    :options="foi_options"
                    input-class= "form-check-input me-2"
                    wrapper-class= "mt-2 mb-3"
                    :sections-schema="{
                      messages: { $el: 'div' },
                      message: { $el: 'span' },
                      options: { $el: 'div' },
                      option: { $el: 'div' },
                    }"
                    messages-class="text-danger"
                    validation-name="Field of interest"
                    validation="required|min:1" :disabled="registering"
                  />
                </div>

                <label class="form-label">Region</label>
                <select class="form-select" v-model="form.region" :disabled="registering">
                  <option
                    v-for="item in location_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </option>
                </select>

                <div class="mt-4 mb-2">
                  <label class="form-label">Language</label>
                  <select class="form-select" v-model="form.language" :disabled="registering">
                    <option label="British English" value="UK"></option>
                    <option label="American English" value="US"></option>
                  </select>
                </div>
                
                <div class="mt-4 mb-2">
                  <label class="form-label" for="affiliation">Affiliated clinic (if applicable)</label>
                  <input class="form-control" type="text" v-model="form.affiliation" id="affiliation" :disabled="registering">
                </div>

                <label class="form-label" for="reference">Reference code (if you have one)</label>
                <input class="form-control" type="text" v-model="form.reference" id="reference" :disabled="registering" @keyup="check_voucher">
                <div v-if="form.reference && validVoucher != null">
                  <span v-if="validVoucher" style="color:green">
                    <i class="bi bi-check-circle"></i> Valid Reference Code
                  </span>
                  <span v-else style="color:red">
                    <i class="bi bi-exclamation-circle"></i> Invalid Reference Code
                  </span>
                </div>

                <div class="mt-4 mb-2">
                  <div class="mt-4 mb-2" style="border:1px solid #0D302A; padding: 5px">
                    <div class="m-2">
                      <div class="row justify-content-between">
                        <div class="col">
                          <span> I would like patients looking for speech and language therapists to be able to find and contact me through the platform (optional)</span>
                        </div>
                        <div class="col-2 d-flex justify-content-end align-items-end flex-column">
                          <div class="d-flex align-items-center">
                            <input class="form-check-input me-2" type="checkbox" v-model="form.discoverable" id="discoverable" name="discoverable" :disabled="registering"/> 
                            <span>I accept</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-4 mb-2" style="border:1px solid #0D302A; padding: 5px">
                  <h4 >Consent <span style="color:red">*</span></h4>
                  <div class="m-2">
                    <div class="row">
                      <div class="col">
                        <span>Please read the BeautifulVoice's </span><a type="button" class="btn-link" @click="downloadTermsOfUse()">Terms of Use</a>
                      </div>
                      <div class="col-2 d-flex justify-content-end align-items-end flex-column">
                        <div class="d-flex align-items-center">
                          <input class="form-check-input me-2" type="checkbox" v-model="form.consent" id="accept" name="consent" validation="required" :disabled="registering"/> 
                          <span>I accept</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </FormKit>
          </div>
        </div>
        <br/>
        <div class="row mt-4 mb-4 justify-content-between ">
          <button type="primary" class="btn btn-outline-primary btn-lg rounded-5 w-25" @click="emitBack()" :disabled="registering">Back</button>

          <button form="form" v-if="!registering" class="btn btn-primary btn-lg rounded-5 w-50" :disabled="!form.consent" ><i class="bi bi-check2-square"></i> Register</button>
          <button v-if="registering" class="btn btn-primary btn-lg rounded-5 w-50" :disabled="true" ><font-awesome-icon icon="spinner" class="fa-spin" /> Signing up...</button>
        </div>
      </div>
    </div>
    <div class="modal fade" id="nhs_modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">NHS Users</h5>
          </div>
          <div class="modal-body">
            <p>You have successfully registered, you can now login to the platform.</p>
            <br/>
            <p>Please note some platform features (in-depth voice and speech analysis) may be blocked on some NHS networks. We can work with your IT department to solve this, please contact us on <a href="mailto:contact@beautifulvoice.co.uk">contact@beautifulvoice.co.uk</a></p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" aria-label="Close" @click="redirect()">I understand</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { Modal } from 'bootstrap'

export default {
  name: 'slt_registration',
  components: {},
  data() {
    return {
      api: import.meta.env.VITE_FAST_API,
      nhs_modal: null,
      
      registering: false,

      form : {
        email: "",
        password: "",
        firstname: "",
        surname: "",
        region: "UK",
        language: "UK",
        consent: "",
        foi: [],
      },
      
      affiliation: "",
      reference: "",
      discoverable: "",

      foi_options: [
        {
          label: "Voice",
          value: "Voice",
          "label-class": "d-inline",
          "input-class": "form-check-input",
          "element-class": "d-inline mb-4",
          "wrapper-class": "mt-2 mb-3",
          "decorator-class": "d-inline",
        },
        {
          label: "Speech",
          value: "Speech",
          "label-class": "d-inline",
          "input-class": "form-check-input",
          "element-class": "d-inline mb-4",
          "wrapper-class": "mt-2 mb-3",
          "decorator-class": "d-inline",
        },
        {
          label: "Language",
          value: "Language",
          "label-class": "d-inline",
          "input-class": "form-check-input",
          "element-class": "d-inline mb-4",
          "wrapper-class": "mt-2 mb-3",
          "decorator-class": "d-inline",
        },
        {
          label: "Cognition",
          value: "Cognition",
          "label-class": "d-inline",
          "input-class": "form-check-input",
          "element-class": "d-inline mb-4",
          "wrapper-class": "mt-2 mb-3",
          "decorator-class": "d-inline",
        },
        {
          label: "Swallowing",
          value: "Swallowing",
          "label-class": "d-inline",
          "input-class": "form-check-input",
          "element-class": "d-inline mb-4",
          "wrapper-class": "mt-2 mb-3",
          "decorator-class": "d-inline",
        },
      ],
      location_options:[
        {
          value: 'UK',
          label: 'United Kingdom'
        },
        {
          value: 'US',
          label: 'United States'
        },
      ],
      validVoucher: null,
    }
  },
  mounted() {
  },
  methods: {
    emitBack() {
      this.$emit('backToRoot');
    },
    downloadTermsOfUse()
    {
      const _this = this
      let storage = _this.$firebase.storage();

      let file = 'BV/terms/BV_TERMS.pdf'

      storage.ref(file).getDownloadURL()
        .then((url) => {
          let link = document.createElement("a");
          if (link.download !== undefined) {
              link.setAttribute("href", url);
              link.setAttribute("target", "_blank");
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          }

        })
        .catch((error) => {

          console.debug('error = ' + error)
          // Handle any errors
      });

    },
    is_nhs_email() {
      const nhs_email_regex = /\b[A-Za-z0-9._%+-]+@nhs\.[A-Za-z]{2,}\b/i;
      return nhs_email_regex.test(this.form.email)
    },
    redirect() {
      let _this = this
      _this.$router.push({ name: 'signIn', params: {email: _this.form.email}});
    },
    register() {
      let _this = this

      _this.registering = true

      if (_this.form.discoverable) {
        _this.form.discoverable = _this.form.discoverable.toString()
      } else {
        _this.form.discoverable = 'false'
      }
      let input = {
          firstname: _this.form.firstname,
          surname: _this.form.surname,
          email: _this.form.email,
          password: _this.form.password,
          region: _this.form.region,
          language: _this.form.language,

          foi: _this.form.foi,
          discoverable: _this.form.discoverable,
          affiliation: _this.form.affiliation,
          consent:_this.form.consent,
          reference: _this.form.reference
        }

      let axpromise = _this.axios.post(_this.api + "/register_slt", input).then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {

            if (_this.is_nhs_email()) {
              const toast = useToast()
              toast.success("Registered successfully!")

              this.nhs_modal = new Modal(document.getElementById('nhs_modal'))
              this.nhs_modal.show()
            } else {
              const toast = useToast()
              toast.success("Registered successfully! Redirecting...")
              _this.redirect()             
            }
          } else if (response.data.status === "error") {
            const toast = useToast()
            toast.error(response.data.info)

            _this.registering = false
          } else {
            const toast = useToast()
            toast.error("Error please contact contact@beautifulvoice.co.uk")

            _this.registering = false
          }
        } else {

          const toast = useToast()
          toast.error("Error please contact contact@beautifulvoice.co.uk")

          _this.registering = false
        }
      });

    },
    check_voucher() {
      let _this = this

      if (this.form.reference != '') {
        let params = {
          category : 'slp',
          reference : this.form.reference
        }
        
        this.axios.post(this.api + "/check_voucher", {}, {params}).then(response => {
          if (response.status === 200) {

            if ('valid' in response.data) {
              _this.validVoucher = response.data['valid']
            }
          }
        });

      }
    }
  },
}
</script>

<style scoped>
</style>