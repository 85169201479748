<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>

    <div class="container mb-5">
      <h1 class="display-5">Facial expressions</h1>

      <div class="card p-2">
        <div clas="card-body text-center">
          <div class="row">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>1</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Big smile</p>
                  <img :src="smileImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('smile')"> Start this exercise</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <!-- <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div> -->
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>2</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Angry</p>
                  <img :src="angryImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('angry')"> Start this exercise</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>3</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Surprised</p>
                  <!-- <p> Find items inside a scene </p> -->
                  <img :src="surprisedImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('surprised')"> Start this exercise</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>4</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Disgust</p>
                  <!-- <p> Answer questions about items inside a scene </p> -->
                  <img :src="disgustImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('disgust')"> Start this exercise</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>5</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Excited</p>
                  <img :src="excitedImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('excited')"> Start this exercise</button>
                </div>
              </div>
            </div>
            <div v-if="issue_flag_dic.voice" class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>6</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Mixed expressions</p>
                  <img :src="mixImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('mixed')"> Start this exercise</button>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>


    </div>
    <Footer class="mt-auto"></Footer>

  </div>
</template>
<script>

import smileImg from '@/assets/images/facial_expressions/big smile.png'
import angryImg from '@/assets/images/facial_expressions/angry.png'
import disgustImg from '@/assets/images/facial_expressions/disgust.png'

import excitedImg from '@/assets/images/facial_expressions/excited.png'
import surprisedImg from '@/assets/images/facial_expressions/surprised.png'
import mixImg from '@/assets/images/facial_expressions/mixed.png'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'Facial Expressions',
  components: {Footer, Navbar},
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      
      pre_loaded_exercises:[],
      autoGenWords:[],
      smileImg:smileImg,
      angryImg:angryImg,
      disgustImg:disgustImg,
      excitedImg:excitedImg,
      mixImg:mixImg,
      surprisedImg:surprisedImg,
      issue_flag:[false,false,false,false],//speeech,motor,voice,aphasia
      issue_flag_dic:{"speech":false,"motor":false,"voice":false,"aphasia":false},
      conditions:["speech","motor","voice","aphasia"],
    }
  },
  created() {  
    this.userCategory = localStorage.getItem('userCategory');
    this.getIssue_flag();
  },
  methods: {

    facial_exercise(type)
    {
        
      if(this.issue_flag_dic.voice)
      {
        if(type==='smile')
        {
          this.$router.push({ name: 'pd_smile'});


        } 
        else if(type==='angry')
        {
          this.$router.push({ name: 'pd_angry'});

        }
        else if(type==='surprised')
        {
          this.$router.push({ name: 'pd_surprised'});

        }
        else if(type==='disgust')
        {
          this.$router.push({ name: 'pd_disgust'});

        }
        else if(type==='excited')
        {
          this.$router.push({ name: 'pd_excited'});

        }
        else if(type==='mixed')
        {
          this.$router.push({ name: 'pd_mixed'});

        }
          //go to pd oral muscle using 'type'
      }
      else if((this.issue_flag_dic.motor || this.issue_flag_dic.speech)&&!this.issue_flag_dic.voice)
      {
          //go to stroke oral muscle using 'type'

        if(type==='smile')
        {
          this.$router.push({ name: 'stroke_smile'});


        } 
        else if(type==='angry')
        {
          this.$router.push({ name: 'stroke_frown'});

        }
        else if(type==='surprised')
        {
          this.$router.push({ name: 'stroke_surprised'});

        }
        else if(type==='disgust')
        {
          this.$router.push({ name: 'stroke_disgust'});

        }
        else if(type==='excited')
        {
          this.$router.push({ name: 'stroke_excited'});

        }
        else if(type==='mixed')
        {

        }

      }
      else
      {
          console.debug('ERR: unexpected selection: '+this.issue_flag)
      }
        
    },
    getIssue_flag()
    {
        if(this.userCategory == 'slp')
        {
            for(let i in this.issue_flag)
            {
            this.issue_flag[i] = true
            this.issue_flag_dic[this.conditions[i]] = true
            console.debug('issue flag dic= '+this.issue_flag_dic)
            }
        }
        else
        {
            let temp_iss_flag = localStorage.getItem('issue_flag');

            if(temp_iss_flag!=undefined && temp_iss_flag!=null)
            {
            let issue_flag_arr = temp_iss_flag.split(',')
            this.issue_flag = [];
            for(let i=0;i<issue_flag_arr.length;i++)
            {
                let flag = (issue_flag_arr[i] == 'true')
                this.issue_flag.push(flag);
                console.debug('condition= '+this.conditions[i])
                this.issue_flag_dic[this.conditions[i]] = flag
                console.debug('issue flag dic= '+this.issue_flag_dic[this.conditions[i]])

            }
            console.debug('issue at word pop= '+this.issue_flag);
            }
        }
    },
  },
}
</script>
<style scoped>

.custom-card_blue{
    background-color: #cfe2f3;
    border-color: #cfe2f3;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}


</style>