<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='MayMeMyMoeMum';openModal('warmup_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Voice_warmup" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Warm up</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="warmup_modal" tabindex="-1" aria-labelledby="warmup_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Warm up</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('warmup_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'MayMeMyMoeMum'?'btn-primary':'btn-light']" type="button" @click="selected_module='MayMeMyMoeMum'">
                  Articulator warm-up 
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'BreathingInstruction'?'btn-primary':'btn-light']" type="button" @click="selected_module='BreathingInstruction'">
                  Diaphragmatic breathing
                </button>
              </div>
              <div class="col-8">
                <div v-show="selected_module == 'MayMeMyMoeMum'">
                  <p class="lead">Demonstrate how to warm up your voice</p>
                </div>
                <div v-show="selected_module == 'BreathingInstruction'">
                  <p class="lead">Diaphragmatic breathing demonstration</p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Voice_warmup from '@/assets/images/dashboard/slt/training/Voice_warmup.svg'
import Voice_training from '@/assets/images/dashboard/slt/training/collapse/voice_training.png'

import { Modal } from 'bootstrap'

export default {
  name: 'warm_up',
  components: {},
  props: 
  {
  },
  data(){
    return {
      selected_module: '',

      Voice_warmup: Voice_warmup, 

      Voice_training: Voice_training,
      modal_references: {},
    }
  },
  watch: {

    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
  },
  async created() {

  },
  mounted() {
    this.modal_references = {
      "warmup_modal": new Modal(document.getElementById('warmup_modal')),
    }
  },
  methods: {
    emit_start_training() {
      this.closeModal('warmup_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('warmup_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
  }
}
</script>