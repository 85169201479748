<template>

<div class="row">
  <div class="card card-body">
    <span class="read-text">
      From the 4 option images on the bottom, click on the button that shows <b style="color:#f56c6c"> opposite direction</b> to the target image. 
    </span>
  </div>
  <div class="row mt-4 justify-content-center">
    <div class="col-2">
      <span style="font-size: x-large;">Target image</span>
    </div>
    <div class="col-3 text-start">
      <img :src="rightFinger" style="width:80px">
    </div>
  </div>
  <div class="row mt-5 justify-content-center">
    <div class="col-2">
      <span style="font-size: x-large;">Option images</span>
    </div>    
    <div class="col-3">
      <div class="row">
        <div class="col-3">
          <button v-if="!clicked[0]" class="btn btn-outline-secondary" @click="gotoClick(0)"><img :src="rightFinger" style="width:40px;"></button>
          <button v-if="clicked[0]" class="btn btn-danger"><img :src="rightFinger" style="width:40px;"></button>
        </div>
        <div class="col-3">
          <button v-if="!clicked[1]" class="btn btn-outline-secondary" @click="gotoClick(1)"><img :src="rightFinger" style="width:40px;transform: rotate(180deg)"></button>
          <button v-if="clicked[1]" class="btn btn-success"><img :src="rightFinger" style="width:40px;transform: rotate(180deg)"></button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <button v-if="!clicked[2]" class="btn btn-outline-secondary" @click="gotoClick(2)"><img :src="rightFinger" style="width:40px;transform: rotate(90deg)"></button>
          <button v-if="clicked[2]" class="btn btn-danger"><img :src="rightFinger" style="width:40px;transform: rotate(90deg)"></button>
        </div>
        <div class="col-3">
          <button v-if="!clicked[3]" class="btn btn-outline-secondary" @click="gotoClick(3)"><img :src="rightFinger" style="width:40px;transform: rotate(270deg)"></button>
          <button v-if="clicked[3]" class="btn btn-danger"><img :src="rightFinger" style="width:40px;transform: rotate(270deg)"></button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 justify-content-center">
    <button class="btn btn-primary w-25" @click="startGame()">Start Game</button>
  </div>
</div>
</template>
<script>

import homeplanImg from '@/assets/images/homeplan.png'
import settingImg from '@/assets/images/setting.png'
import patientImg from '@/assets/images/patient.png'
import taskImg from '@/assets/images/task.png'
import addImg from '@/assets/images/add.png'
import warnImg from '@/assets/images/warn.png'
import homeImg from '@/assets/images/home.png'
import backImg from '@/assets/images/back.png'
import targetIcon from '@/assets/images/target.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import rightArrow from '@/assets/images/bxs-right-arrow-white.png'
import rightFinger from '@/assets/images/right_finger.png'
import libImg from '@/assets/images/prePlans.png'
import { useTTS } from '@/stores/tts'

export default {
  name: 'Instructions',
  props: 
  {
  },
  emits: ['showGame'],
  data(){
    return {
      homeplanImg: homeplanImg,
      settingImg: settingImg,
      patientImg: patientImg,
      taskImg: taskImg,
      rightArrow: rightArrow,
      addImg: addImg,
      warnImg: warnImg,
      homeImg: homeImg,
      backImg: backImg,
      targetIcon: targetIcon,
      doneIcon: doneIcon,
      rightFinger: rightFinger,
      countDown : 10,
      clicked:[false,false,false,false],
      libImg:libImg,
      bv_tts: useTTS().tts_engine,

      
    }
  },
  created() {
    setTimeout(() => {
      this.bv_tts.say_sentences(['In this exercise, you will see a target image. ', ' Click on the image that shows the opposite direction to the target image.' ,'You have 30 seconds','Press start game and try to do as many as you can within 30 seconds'])
    }, 500)

  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

  },

  methods: {
    startGame()
    {
      this.$emit('showGame', true)
    },
    gotoClick(num)
    {
        this.clicked = [false,false,false,false];
        this.clicked[num] = true;
    },
    
  },
}
</script>
<style scoped>

</style>