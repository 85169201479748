<template>
  <div>
    <div class="card card-body h-100" :style="{ background: 'linear-gradient(to bottom, gainsboro, white)' }">
      <div class="d-flex flex-column justify-content-between h-100">
        <div>
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
        </div>
        <div>
          <div v-if="!break_page">
            <div v-if="!mic_on" class="w-100 mt-2">
              <button class="btn-primary btn btn-lg rounded-circle" style="aspect-ratio: 1 / 1;width: 110px; height: 110px;" @click="start()"> 
                <div class="d-flex flex-column">
                  <span>
                    <i class="bi bi-play-fill fs-1"></i>
                  </span>
                  <span style="margin-top: -15px;">
                    Start
                  </span>
                </div>
              </button>
            </div>
            <div v-else>
              <div class="row justify-content-center">
                <div class="outer-circle" style="cursor: pointer;" @click="stop()">
                  <div class="wave-container">
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                    <div class="wave"></div>
                  </div>
                  <div class="rounded-circle text-white inner-circle">
                    <div class="d-flex justify-content-center align-items-center h-100">
                      <div class="row">
                        <i class="bi bi-stop-fill fs-1"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              <p><b>Good job <i class="bi bi-hand-thumbs-up text-primary"></i></b></p>
            </div>
            <button class="btn btn-primary btn-lg w-100" @click="next()">Next <i class="bi bi-skip-forward"></i></button>
            <div>
              <!-- <br/> -->
              <!-- <p>There are more exercises to do.</p>
              <br/> -->
              <img :src="water_bottle" />
              <p><b>Do not forget to drink water.</b></p>
            </div>
            <button v-if="has_listen_back" class="btn btn-info btn-lg w-100 mt-4" @click="listen_back()">Listen Back</button>
            <button v-if="has_repeat" class="btn btn-success btn-lg w-100 mt-4" @click="repeat()">Repeat</button>
          </div>
        </div>
        <div>
          <div v-if="!break_page">
            <!--<div v-show="audio_player!=null" class="form-check mt-4">
              <input class="form-check-input" type="checkbox" id="flexCheckChecked" v-value="background_noise_checked" @click="toggleBackgroundNoise()">
              <label class="form-check-label" for="flexCheckChecked">
                Background noise 
              </label>
            </div>-->
            <div v-if="has_demo_video == true" >
              <p class="mb-0">Need help?</p>

              <button
                class="btn btn-outline-primary btn-lg w-100 rounded" 
                @click="goToVideoDemo()">
                <p class="mb-0" style="font-size: 16px;"> <i class="bi bi-play-circle"></i> Watch Therapist Demo</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { ref, reactive, computed, onMounted } from 'vue';
  import water_bottle from '@/assets/images/session/water_bottle.svg'

  export default {
    props: {
      curr_exercise_number : {
        type: Number,
        required: true
      },
      total_exercises : {
        type: Number,
        required: true
      },
      has_demo_video: {
        type: String,
        required: true
      },
      break_page: {
        type: Boolean
      },
      mic_on: {
        type: Boolean,
        required: true
      },
      has_listen_back: {
        type: Boolean
      },
      has_repeat: {
        type: Boolean
      }
    },
    setup(props, {emit}) {
    
      onMounted(() => {
        
      });

      const next = () => {
        emit('next')
      }

      const start = () => {
        emit('started')
      }
      const stop = () => {
        emit('stopped')
      }

      const toggleBackgroundNoise = () => {
        emit('toggled_background_noise')
      }

      const goToVideoDemo = () => {
        emit('toggled_demo_video')
      }

      const listen_back = () => {
        emit('listen_back')
      }

      const repeat = () => {
        emit('repeat')
      }
  
      return {
        next,
        start,
        stop,
        toggleBackgroundNoise,
        goToVideoDemo,
        listen_back,
        repeat,
        water_bottle
      };
    }
  };
</script>
  
<style scoped>

.outer-circle {
  position: relative;
  width: 150px;
  height: 150px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.outer-circle:hover {
  background-color: transparent;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}

.outer-circle:hover .wave {
  /*animation-play-state: paused; */ /* Pause the animation on hover */
  animation:none;
}

.wave-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-color: rgba(255, 0, 119, 0.7);
  box-shadow: 0 0 0 0 rgba(255, 0, 119, 0.7);
  animation: wave 5s infinite;
  animation-play-state: running;
}

.wave:nth-child(2) {
  animation-delay: 0.3s;
}

.wave:nth-child(3) {
  animation-delay: 0.6s;
}

.wave:nth-child(4) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0.75);
    opacity: 0.5;
  }
  25% {
    transform: scale(1);
    opacity: 0.75;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.85;
  }
  75% {
    transform: scale(1);
    opacity: 0.75;
  }
  100% {
    transform: scale(0.75);
    opacity: 0.5;
  }
}

.inner-circle {
  width: 110px;
  height: 110px;
  background-color: rebeccapurple;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

}
</style>
  