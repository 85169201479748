<template>

  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack="true"></Navbar>

    <div class="container">
      <h1 class="display-5">Group Activities</h1>
      <div class="card h-100">
        <div class="card-body">
          <h5 class="mb-4">Communication training <i class="bi bi-mic-fill"></i></h5>
          <div class="row text-center">
            <div class="col-md-6">
              <button class="btn btn-success rounded-circle custom-button" @click="describingPictureGame()">Describing Pictures</button>
            </div>
            <div class="col-md-6">
              <button class="btn btn-success rounded-circle custom-button" @click="findingWordsGame()">Finding Words</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>

import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  components: {Footer, Navbar},
  data() {
    return {
      userCategory: '',
    }
  },
  created() 
  {
    const _this = this

    _this.region = localStorage.getItem('region');
    _this.userCategory = localStorage.getItem('userCategory');
  },
  methods: 
  {
    describingPictureGame()
    {
      const _this = this
      _this.$router.push({ name: 'image_game' });
    },
    findingWordsGame()
    {
        const _this = this
      _this.$router.push({ name: 'word_game' });
    }
  }
}
</script>
<style scoped>

.custom-button
{
    font-size: 20px;
    width: 140px;
    height: 140px;
}
</style>