export function VHI_computeZScore(selected_options,rating,population_mean,population_std)
{

  let total_value = 0
  let total_questions = 0


    for(let i in selected_options)
    {
        let curr_result = selected_options[i]

        let curr_result_value = 0

        for(let k in rating)
        {
          if(rating[k].judge == curr_result)
          {
              curr_result_value = k

              total_value+=parseInt(curr_result_value)

              total_questions+=1
          }
        }

    }



    let z_score = 0


    if(total_value>population_mean)
    {
        z_score = (total_value-population_mean)/population_std

    }


    let vhi_message = VHI_translateZScore(z_score)

    let output = {'z_score':z_score,'msg_color':vhi_message.msg_color,'z_score_meaning':vhi_message.z_score_meaning}

    return output
}

export function VHI_translateZScore(z_score)
{
    let output = {'msg_color':'','z_score_meaning':''}

    if(z_score<=1)
    {

      output.msg_color = 'background-color:#1aff1a'
      output.z_score_meaning = 'Very good. Your voice does not have a significant impact on your daily life.'
      // Keep it this way.'
    }
    else if(z_score>1 && z_score<2)
    {
        output.msg_color = 'background-color:#ffe066'
        output.z_score_meaning = 'It seems that your voice condition has mild impact on your daily life.'
      // Consider talking to a therapist and start voice training.'
    }
    else if(z_score>=2 && z_score<3)
    {
       output.msg_color = 'background-color:#ff944d'
       output.z_score_meaning = 'It seems that your voice condition has moderate impact on your daily life.'
      // Talk to a speech and language therapist or an occupational therapist.'
    }
    else
    {
       output.msg_color = 'background-color:#ff1a1a'
       output.z_score_meaning = 'It seems that your voice condition has severe impact on your daily life.'
      // Talk to a speech and language therapist or an occupational therapist.'
    }

    return output


}



export function VHI_computeStandardScore(selected_options,rating,q_type)
{
  let results_dict = {}

  let total_value = 0
  let total_questions = 0




    for(let i in selected_options)
    {
        let curr_result = selected_options[i]

        let curr_result_value = 0

        for(let k in rating)
        {
          if(rating[k].judge == curr_result)
          {
              curr_result_value = k

              total_value+=parseInt(curr_result_value)

              total_questions+=1
          }
        }

    }
    results_dict['VHI_type'] = 'VHI10'

    if(total_questions==30)
    {
      results_dict['VHI_type'] = 'VHI30'
      let severity_level = ''
      if(total_value<=14)
      {

        severity_level = 'Healthy'
      }
      else if(total_value>14 && total_value<=28)
      {
        severity_level = 'Mild'
      
      }
      else if(total_value>28 && total_value<=50)
      {
        severity_level = 'Moderate'
      }
      else
      {
        severity_level = 'Severe'
      }
      results_dict['severity_level'] = severity_level


    }
    else if(total_questions==10)
    {
      let severity_level = ''
      let total_score_meaning = ''
      if(total_value<=3)
      {

        severity_level = 'Healthy'
        total_score_meaning = 'Very good. Your voice does not have an issue.'

      }
      else if(total_value>3 && total_value<=11)
      {
        severity_level = 'Mild'
        total_score_meaning = 'It seems that your voice condition has mild impact on your daily life.'

      
      }
      else if(total_value>11)
      {
        severity_level = 'Moderate to Severe'
        total_score_meaning = "It seems that your voice condition has moderate to severe impact on your daily life. Consult a speech and language therapist."

      }
  
      results_dict['severity_level'] = severity_level
      results_dict['total_score_meaning'] = total_score_meaning


    }

    let mean = total_value/Math.max(1,total_questions)

    results_dict['mean_score'] = mean
    results_dict['total_score'] = total_value
    results_dict['total_questions'] = total_questions


    if(q_type.length>0 && q_type.length===selected_options.length)
    {
      function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
      }


      let unique_question_types = q_type.filter(onlyUnique)
      console.debug('unique_question_types = ' + unique_question_types)

      let result_by_category = {}



      for(let k in unique_question_types)
      {
        let type = 'Emotional'
        if(k==='F')
        {
          type = 'Functional'
          
        }
        if(k==='P')
        {
          type = 'Physical'
        }
        result_by_category[unique_question_types[k]] = {'total_score':0,'total_questions':0,'type':type}
      }


      for(let i in selected_options)
      {

          let curr_result = selected_options[i]

          let curr_category = q_type[i]

          let curr_result_value = 0

          for(let k in rating)
          {

            if(rating[k].judge == curr_result)
            {
              curr_result_value = k

              curr_result_value = parseInt(curr_result_value)

              result_by_category[curr_category].total_score = result_by_category[curr_category].total_score + curr_result_value
              result_by_category[curr_category].total_questions = result_by_category[curr_category].total_questions + 1

            }
          }

      }
    

    for(let k in result_by_category)
    {

      result_by_category[k]['mean_score'] = result_by_category[k].total_score/Math.max(1,result_by_category[k].total_questions)

    }

    results_dict['vhi_categories'] = result_by_category

  }


  return results_dict


}



