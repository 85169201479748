<template>
  <div>
    <router-view/>
    <Video_call />

  </div>
</template>

<script>
import Video_call from '@/views/components/Video_call.vue'

export default {
  name: 'App',
  components: {Video_call}
}
</script>

<style>
#app {
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;    
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;    
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;    
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;    
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;    
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;    
  }
}

.divH {
  background: white;
  border-radius: 20px;
  transition: 500ms box-shadow;
  padding: 10px;
}

.divH:hover {
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,.2);
}

.divH:hover::before, .divH:hover::after {
  display: block;
  content: '';
  position: absolute;
  background: #FDA8CF;
  border-radius: 20px;
  z-index: -1;

  animation-name: clockwise;
  animation-duration: 1s;
}

.divH:hover:after {
  background: #F3CE5E;
  animation: 2s counterclockwise infinite;
}


/*
 Read text size
*/

.read-text{
  font-size: 35px;
}

.text-30{
  font-size: 30px;
}
</style>
