<template>
  <div v-if="results['loudness'] && results['loudness']['avg_loudness'] > 30" class="card card-body mt-2">
    <loudness_indicator :loudness="results['loudness']"/>
  </div>
</template>
<script>

import loudness_indicator from '@/views//session/Result/type/loudness_indicator.vue'

export default {
  name: 'loudness_indicator_section',
  components: {
    loudness_indicator
  },
  props: ['results'],
  setup(props) {
    return {

    }
  }
}
</script>
<style>
  canvas {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  #chartjs-tooltip {
    opacity: 1;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: white;
    border-radius: 3px;
    -webkit-transition: all .1s ease;
    transition: all .1s ease;
    pointer-events: none;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  #chartjs-radar{
    width : 60%;
    height: 60%;
  }
  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
</style>