<template>
  <div>
    <div class="modal fade" id="slt_request_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Connect with your therapist</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <p class="lead">Your speech therapist, <strong>{{slt_request_details['SLT_name']}}</strong>, would like to connect with you.</p>
              <p class="mt-2">By accepting to connect, you agree to sharing your activity data on the BeautifulVoice platform (i.e. practice routine, recording and results) with the therapist. The data will only be used for treatment purposes.</p>
            </div>
            <div class="row mt-4">
              <div class="col">
                <button class="btn btn-danger w-100" @click="handleAuthorise(false)">Reject connection</button>
              </div>
              <div class="col">
                <button class="btn btn-success w-100" @click="handleAuthorise(true)">Accept connection</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="training_reminder_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Training reminder</h5>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <p class="lead">Train frequently to improve speech and language.</p>
                <br/>
                <p class="lead">Remind me if I haven't practised for:</p>
                <select class="form-select m-2" v-model="freq">
                    <option key="1" label="1 day" value="1"> </option>
                    <option key="2" label="2 days" value="2"> </option>
                    <option key="3" label="3 days" value="3" selected> </option>
                    <option key="4" label="4 days" value="4"> </option>
                    <option key="5" label="5 days" value="5"> </option>
                    <option key="6" label="6 days" value="6"> </option>
                    <option key="7" label="7 days" value="7"> </option>
                    <option key="8" label="Don't remind me" value="0"> </option>
                </select>
              </div>
              <div class="row mt-2 justify-content-center">
                <button class="btn btn-success btn-lg w-50 m-2" @click="setReminder()">Start</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>


import {getUserDataDir, updateUserData, getAuthToken} from '@/firebase/index'
import firebase from '@firebase/app';
import { Modal } from 'bootstrap'
import { useToast } from "vue-toastification";

export default {
  props: {
      userCategory:''
  },
  data() {
    return {
      show_SLT_request: false,
      show_SLT_request_modal: '',
      show_training_reminder: false,
      show_training_reminder_modal: '',
      slt_request_details: {"SLT_name": "", "SLT_email": ""},
      freq:"3",
      api: import.meta.env.VITE_FAST_API + '/authorise_link',
    }
  },

  created () {
  },
  mounted () {
    let _this = this

    const user = firebase.auth().currentUser;
    if (user) {
      if ( _this.userCategory === "patient" ) {
        _this.checkHomeTourFinished()
      }     
    } else {
      _this.$router.push({name: "home"});
    }

  },
  methods: {
    checkHomeTourFinished() {
      let _this = this
      getUserDataDir('Data/' ,function (err, result) {
          let handle = result.val()
          
          if ('startTour' in handle) {
            if (!handle['startTour']) {
              _this.getLinkRequests();
              _this.checkAllowReminder()
            }
          }

      });
    },
    checkAllowReminder() {
        let _this = this
        getUserDataDir('Data/' ,function (err, result) {
          let handle = result.val()
          
          if (handle['notification_frequency']) {
            _this.show_training_reminder = false
            if (_this.show_training_reminder_modal) {
              _this.show_training_reminder_modal.hide()
            }
          } else {
            _this.show_training_reminder = true
            _this.show_training_reminder_modal = new Modal(document.getElementById('training_reminder_modal', {backdrop: 'static', keyboard: false}))
            _this.show_training_reminder_modal.show()
          }
        });
    },
    setReminder() {

      updateUserData('Data/', {'notification_frequency' : this.freq})
      const toast = useToast()
      toast.success("Notification frequency updated!")

      if (this.freq === "0") {
        this.show_training_reminder = false
        if (this.show_training_reminder_modal) {
          this.show_training_reminder_modal.hide()
        }

      } else {
        this.show_training_reminder = false
        if (this.show_training_reminder_modal) {
          this.show_training_reminder_modal.hide()
        }
      }
        
    },
    getLinkRequests() {
      let _this = this

      getUserDataDir('Notifications/' ,function (err, result) {
          result.forEach(function(childSnapshot)
          {   
            let handle = childSnapshot.val()

            if (handle['decided'] === false) {
                _this.slt_request_details = handle
                _this.show_SLT_request = true
            } else {
                _this.show_SLT_request = false
            }
          });
          
          if (_this.show_SLT_request) {
            _this.show_SLT_request_modal = new Modal(document.getElementById('slt_request_modal', {backdrop: 'static', keyboard: false}))
            _this.show_SLT_request_modal.show()
          } else {
            if (_this.show_SLT_request_modal) {
              _this.show_SLT_request_modal.hide()
            }
          }
      });
    },
    async handleAuthorise(approve) {
      let uid = localStorage.getItem("uid")

      let postData = {
        slt_uid: this.slt_request_details['from'],
        patient_uid: uid,
        request_timestamp: this.slt_request_details['timestamp'],
        approved: approve
      }

      this.show_SLT_request = false

      if (this.show_SLT_request_modal) {
        this.show_SLT_request_modal.hide()
      }

      let idToken = await getAuthToken()

      await this.axios.post(this.api, postData, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      }).then(response => {
        let rt = response.data
        if (response.status === 200) {

          if (rt['status'] === 'approved') {
            const toast = useToast()
            toast.success("Congratulations you have connected with your therapist!")
          } else if (rt['status'] === 'denied') {
            const toast = useToast()
            toast.error("Access to therapist denied!")
          } else if (rt['status'] === 'deleted') {
            const toast = useToast()
            toast.warning("Request from therapist expired!")
            alert("Request from therapist has expired. Please contact your therapist if you believe there has been a mistake.")            
          } else {
            console.error(rt)            
          }
        }
      })


    
      setTimeout(() => {  window.location.reload() }, 2500);
      
    },
  }
}
</script>

