<template>
  <div>
    <div class="row">
      <span class="title_txt" style="font-size:24px"> Your average loudness is <b style="color:blue"> {{loudness['avg_loudness']}} dB</b>. Check below to see what this means.</span>
    </div>
    <div class="row mt-4">
      <div class="row">
        <div class="progress" role="progressbar">
          <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" :style="{width: loudness['avg_loudness']+'%'}"></div>
        </div>

        <div class="col" v-for="col in 5" :key="col">
          <h5 class="text-info">{{loudness['vol_stage'][col-1]}}</h5>
        </div>
      </div>
      <div class="row justify-content-center">
        <div :class="['col text-danger border-end',{'opacity-25': !loudness['vol_stage'][0]}, {'shadow': loudness['vol_stage'][0]}]">
          <i class="bi bi-x-circle fs-4"></i>
          <h5>Quiet whispering</h5>
          <p>It's hard for people to hear you</p>
        </div>
        <div :class="['col text-danger border-end',{'opacity-25': !loudness['vol_stage'][1]}, {'shadow': loudness['vol_stage'][1]}]">
          <i class="bi bi-x-circle fs-4"></i>
          <h5>Low voice</h5>
          <p>It takes effort for people to hear you</p>
        </div>
        <div :class="['col text-success border-end',{'opacity-25': !loudness['vol_stage'][2]}, {'shadow': loudness['vol_stage'][2]}]">
          <i class="bi bi-check-circle fs-4"></i>
          <h5>Quiet conversation</h5>
          <p>People can hear you well in a quiet environment</p>
        </div>
        <div :class="['col text-success border-end',{'opacity-25': !loudness['vol_stage'][3]}, {'shadow': loudness['vol_stage'][3]}]">
          <i class="bi bi-check-circle fs-4"></i>
          <h5>Conversation</h5>
          <p>People can hear you well in a noisy environment</p>
        </div>
        <div :class="['col text-success border-end',{'opacity-25': !loudness['vol_stage'][4]}, {'shadow': loudness['vol_stage'][4]}]">
          <i class="bi bi-check-circle fs-4"></i>
          <h5>Loud conversation</h5>
          <p>People can hear you across a room or outdoor</p>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>

  import {ref, onUpdated} from 'vue'

  export default {
    name: 'loudness_indicator',
    components: {
    },
    props: {
      loudness: {
        type: Object,
        default: {}
      }
    },
    setup(props) {
      const loudness = ref(props.loudness)
      
      
      onUpdated(()=> {
        loudness.value = props.loudness
      })

      return {
        loudness
      }

    }
  }
</script>