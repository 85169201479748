<template>
  <div class="training_popup">
    <div class="modal fade" id="training_popup_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Training
            </h5>
            <button type="button" class="btn-close" @click="closeTraining"></button>
          </div>
          <div class="modal-body">
            <div class="card card-body">
              <div class="row justify-content-center">
                <p class="lead" v-if="target_word==='conso' || target_word==='vowel'">Follow the video instruction, practise phoneme /{{phonemeDisplayDictionary[target_phoneme]}}/.</p>
                <p class="lead" v-else >Follow the video instruction, practise phoneme /{{phonemeDisplayDictionary[target_phoneme]}}/ in word: {{target_word}}.</p>
              </div>
              <div class="row justify-content-center">
                <div class="col-12">
                  <video v-show="showTraining" id="movie" :src="videoURL" controls autoplay style="width: 100%"> </video>
                </div>
              </div>
            </div>
            <div class="row justify-content-center text-center">
              <p v-if="moreExamplesAvailable"> Exercise {{video_number+1}}/{{video_total}}</p>
            </div>
            <div v-if="moreExamplesAvailable" class="row justify-content-center">
              <button class="btn btn-primary w-50"  @click="watchNextVideo()">Next video practise</button>
            </div>
            <div v-if="!moreExamplesAvailable">
              <div class="row">
                <span style="color:#67C23A;fontSize:24px">Practise complete for this phoneme!</span>
              </div>
              <div class="row mt-4 justify-content-center">
                <button class="btn btn-primary w-50" @click="closeTraining">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from '@/utils/constants'

import { Modal } from 'bootstrap'


export default {
    name: 'training_popup',
    props: {
      showTraining: {
        type: Boolean,
        required: true,
      },
      phoneme_exercise_list:
      {
        type: Array,
        required: true,
      },
      target_phoneme:
      {
        type: String,
        required: true

      }
    },
    data() {
      return {
        target_word: '',
        video_number: 0,
        moreExamplesAvailable: true,
        video_total:0,
        phonemeDisplayDictionary: null,
        training_popup_modal: null,
        videoURL: null
      }
    },
    created() {
      const _this = this
      _this.phonemeDisplayDictionary = constants.phonemeDisplayDictionary
      _this.video_number = 0;
    },
    watch: {
      showTraining(new_param, old_param) {
        if (new_param == true) {
            this.video_number = 0;
            this.watchVideoInstructions()
        } else {
          if (this.training_popup_modal) {
            this.training_popup_modal.hide();
          }
        }
      }
    },
    mounted()
    {
      this.training_popup_modal= new Modal(document.getElementById('training_popup_modal'))
    },
    methods: {
      watchNextVideo()
      {
        var _this = this;

        _this.video_number = _this.video_number + 1
        
        _this.watchVideoInstructions()

      },
      watchVideoInstructions()
      {
        var _this = this;

        var storage = _this.$firebase.storage();

        console.debug('video_number = ' + _this.video_number)
        //alert(_this.video_number)
        console.debug('length = ' + _this.phoneme_exercise_list.length)
        //alert(_this.phoneme_exercise_list.length)
        _this.video_total = _this.phoneme_exercise_list.length;

        if(_this.video_number<_this.phoneme_exercise_list.length)
        {

            let type = _this.phoneme_exercise_list[_this.video_number]['type']
            console.debug('type= '+type)

            if(type == 'Consonant')
            {
                _this.target_word = 'conso';
            }
            else if(type == 'Vowel')
            {
                _this.target_word = 'vowel';
            }
            else
            {
              _this.target_word = _this.phoneme_exercise_list[_this.video_number]['exerciseSnapshot'].child('name').val()
            }


          console.debug("target_word  = " + this.target_word)

          let video_file_location = _this.phoneme_exercise_list[_this.video_number]['exerciseSnapshot'].child('Video_Address').val()

          storage.ref(video_file_location).getDownloadURL()
            .then((url) => {
              _this.videoURL = url
              if (this.showTraining ) {
                if (this.training_popup_modal) {
                  this.training_popup_modal.show();
                }
              }
            }).catch((error) => {
              // Handle any errors
            });
        }
        else
        {
          _this.moreExamplesAvailable = false
        }
      },
      closeTraining() {
        this.videoURL = ""
        this.$emit('closeTraining')
      }
    }
}
</script>

<style scoped>
  .text {
    font-size: 14px;
  }

  .text_item {
    /* margin-bottom: 18px; */
    font-size: 30px;
    /* vertical-align: middle; */
    padding: 40px 0;
    /* height:80px; */
  }
  
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }

  .box-card {
    width: 560px;
  }
</style>