<template>

  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-10">
      <div class="card card-body text-center">

        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <div class="row">
            <Loudness v-if="received_stream.value" :loudness="received_stream.value['data']"></Loudness>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 text-start">
              <div class="mb-3">
                <label for="comments" class="form-label">Note</label>
                <textarea class="form-control" id="comments" rows="3" v-model="note"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0 && room_meta_reactive.mode.value === 'exercise'">
          {{ isRecording.value }}
          <button 
            :class="['btn w-100', isRecording?'btn-success':'btn-danger']" 
            @click="toggleRecording(!isRecording)" 
            :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">
            {{ isRecording ? 'Stop': 'Start' }} Recording
          </button>
          <hr v-if="room_meta_reactive.exercise_index.value + 1 <= room_meta_reactive.total_exercises.value" />
          <button v-if="room_meta_reactive.exercise_index.value + 1 <= room_meta_reactive.total_exercises.value" 
            class="btn btn-info mt-2 w-100" 
            @click="next" 
            :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">
            Next
          </button>
          <button v-if="room_meta_reactive.exercise_index.value > 1 && room_meta_reactive.mode.value === 'exercise'" 
            class="btn btn-secondary mt-2 w-100" 
            @click="back" 
            :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">
            Back
          </button>
          <hr/>
          <button class="btn btn-primary mt-2 w-100" @click="end" :disabled="isRecording">End session</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Results :room_meta="room_meta_reactive" @exit="exit" @save="save_results_edit"/>
  </div>
</template>
  
<script>
  
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';
import { reactive } from 'vue'
import Loudness from '../components/loudness.vue'
import Results from './results.vue'


export default {
  props: ['room_meta', 'received_stream'],
  components: {
    Results,
    Loudness,
  },
  setup(props, {emit}) {
    const room_meta_reactive = toRefs(props.room_meta)
    const received_stream = reactive(props.received_stream)
    
    const isRecording = ref(false)

    const note = ref('')
    const session_slt_id = ref('')


    watch(() => room_meta_reactive.exercise_index.value, (new_exercise_index, old_exercise_index) => {
      if ('slt_note' in room_meta_reactive.blackboard.value) {

        note.value = room_meta_reactive.blackboard.value.slt_note.note
      } else {
        note.value = ''
      }

    });

    const exit = () => {
      emit("exit")
    }

    const toggleRecording = (v) => {
      let command

      if (v) {

        command = "start_recording"
        isRecording.value = true
        
      } else {
        command = "stop_recording"
        isRecording.value = false
      }
      emit("command", {command: command, meta: {'uid': localStorage.getItem('client_uid') }})
    }

    const checkIfRecording = () => {
      if (room_meta_reactive.currently_recording_uid.value) {
        isRecording.value = true
      } else {
        isRecording.value = false
      }
    }

    const save = () => {
      if (note.value != '') {

        emit("save", {
          result_type: "session_phonation", 
          exercise_index: room_meta_reactive.exercise_index.value, 
          payload: { 
            note: note.value 
          }
        })

        note.value = ''
      }
    }

    const save_results_edit = (v) => {
      emit('save', v)
    }

    const next = () => {
      toggleRecording(false)
      save()
      emit("next")
    }

    const back = () => {
      toggleRecording(false)
      save()
      emit("back")
    }

    const end = () => {
      save()
      emit("end")
    }

    onMounted(() => {
      checkIfRecording()
    })
  
    return {
      session_slt_id,
      isRecording,
      room_meta_reactive,
      received_stream,
      note,
      save,
      save_results_edit,
      next,
      back,
      end,
      exit,
      toggleRecording,
      checkIfRecording
    }
  }
}
</script>

<style scoped>

</style>
