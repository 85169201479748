<template>
  <div>
    <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='CopyWord';openModal('writing_modal')">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Language_writing" class="img-thumbnail text-white rounded-circle me-2 d-inline" style="background-color: orange">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Writing / Spelling</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="writing_modal" tabindex="-1" aria-labelledby="writing_modal" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
                  
            <h5 class="modal-title">Writing/Spelling</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('writing_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:50vh">
              <div class="col-4 border-3 border-success border-end">
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'CopyWord'?'btn-primary':'btn-light']" type="button" @click="selected_module='CopyWord'">
                  Copy words 
                </button>
                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'CopyWordLetter'?'btn-primary':'btn-light']" type="button" @click="selected_module='CopyWordLetter'">
                  Copy words starting with a given letter 
                </button>

                <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'CopyWordSubject'?'btn-primary':'btn-light']" type="button" @click="selected_module='CopyWordSubject'">
                  Copy words from a given subject 
                </button>

                <button :class="['btn','mb-2', 'w-100', selected_module == 'WriteWordsAudio'?'btn-primary':'btn-light']" type="button" @click="selected_module='WriteWordsAudio'">
                  Write words (audio)
                </button>
   
                <button :class="['btn','mb-2', 'w-100', selected_module == 'WriteWordsPicture'?'btn-primary':'btn-light']" type="button" @click="selected_module='WriteWordsPicture'">
                  Write words (image)
                </button>

                <button :class="['btn','mb-2', 'w-100', selected_module == 'WordSearch'?'btn-primary':'btn-light']" type="button" @click="selected_module='WordSearch'">
                  Search words
                </button>

                <button :class="['btn','mb-2', 'w-100', selected_module == 'Hangman'?'btn-primary':'btn-light']" type="button" @click="selected_module='Hangman'">
                  Hangman
                </button>
                <button :class="['btn','mb-2', 'w-100', selected_module == 'AntonymExercises'?'btn-primary':'btn-light']" type="button" @click="selected_module='AntonymExercises'">
                  Antonym retrieval
                </button>
              </div>
              <div class="col-8">

                <div v-show="selected_module == 'CopyWord'">
                  <p class="lead">Practise orthographic spelling</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'CopyWordLetter'">
                  <p class="lead">Practise informal divergent naming by initial phoneme and orthographic spelling</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'CopyWordSubject'">
                  <p class="lead">Practise informal divergent naming by category and orthographic spelling</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>



                <div v-show="selected_module == 'WriteWordsAudio'">
                  <p class="lead">Practise writing a word with audiological input and a visual prompt</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'WriteWordsPicture'">
                  <p class="lead">Practise writing a word with visual input and an auditory prompt</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>

                <div v-show="selected_module == 'WordSearch'">
                  <p class="lead">Practise grapheme recognition and spelling simple words</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
                
                <div v-show="selected_module == 'Hangman'">
                  <p class="lead">Practise finding words by guessing the letters</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="3" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:10" />
                    </div>
                  </div>
                </div>


                <div v-show="selected_module == 'AntonymExercises'">
                  <p class="lead">Practise retreiving antonyms</p>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>
<script>

import Language_writing from '@/assets/images/dashboard/slt/training/Language_writing.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'writing',
  components: {},
  props: 
  {
    number_exercises : Number
  },
  data(){
    return {
      selected_module: '',
      local_number_exercises: 5,
      Language_writing: Language_writing, 
      modal_references: {},
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "writing_modal": new Modal(document.getElementById('writing_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('writing_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('writing_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    }
  }
}
</script>