<template>
    <div>
      
      <div class="row mt-2" v-if="results['intelligibility'] && results['intelligibility'].length > 0 && !is_isession">
        <word_intelligibility :word_intelligibility="results['intelligibility']"></word_intelligibility>
      </div>
      <div class="row mt-2" v-if="results['sentencegibility'] && results['sentencegibility'].length > 0 && !is_isession">
        <sentence_intelligibility 
          :sentence_intelligibility="results['sentencegibility']"
          :avg_sentence_intel="results['avg_sentence_intel']"
          :avg_sentence_interp="results['avg_sentence_interp']"
        ></sentence_intelligibility>
      </div>
      <div class="row mt-2">
        <isession_intelligibility @is_isession="is_isession=true"></isession_intelligibility>
      </div>
    </div>
  </template>
    
  <script>
  
  import {ref, onUpdated, onMounted} from 'vue'
  import word_intelligibility from '@/views//session/Result/type/word_intelligibility.vue'
  import sentence_intelligibility from '@/views//session/Result/type/sentence_intelligibility.vue'
  import isession_intelligibility from '@/views//session/Result/type/isession_intelligibility.vue'
import { result } from 'lodash';
  
  export default {
    name: 'intelligibility_section',
    components: {
      word_intelligibility,
      sentence_intelligibility,
      isession_intelligibility
    },
    props: ['results'],
    setup(props) {

      const is_isession = ref(false)
      onMounted( () => {

      });

      return {
        is_isession
      };

    }
  }
  </script>