<template>
  <div class="row mt-2" v-if="isession_name_picture && isession_name_picture.length > 0">
    <div class="card card-body" >
      <div>
        <p class="h4 text-start">Interactive Session Picture Naming</p>
        <p v-if="session_slt_id">Session SLT ID: {{ session_slt_id }}</p> 
      </div>
      <table class="table mt-4">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Word Heard</th>
            <th scope="col">Used beginning of word</th>
            <th scope="col">Used Answer</th>
            <th scope="col">Used Hint</th>
            <th scope="col" style="width: 300px;">Note</th>
            <th scope="col">Picture</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in isession_name_picture" v-bind:key="index">
            <td>{{ index + 1 }}</td>
            <td class="text-start">{{ row['word_heard'] ? row['word_heard'] : ' -- '}}</td>
            <td>{{ row['used_beginning_of_word'] ? "Yes" : "No" }}</td>
            <td>{{ row['used_answer'] ? "Yes" : "No" }}</td>
            <td>{{ row['used_hint'] ? "Yes" : "No"  }}</td>
            <td scope="col" style="width: 300px;">{{ row['note'] }}</td>
            <td>
              <a :href="row['image'][0]" target="_blank">
                <img :src="row['image'][0]" class="img-thumbnail" style="height:80px; cursor: pointer;" />
              </a>
            </td>
          </tr>
        </tbody>
      </table> 
    </div>
  </div>
</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import {getData} from '@/firebase/index'
import firebase from 'firebase/app'

  export default {
    name: 'isession_name_picture',
    components: {
    },
    props: {
    },
    setup(props) {
      
      const session_slt_id = ref(null)
      const isession_name_picture = ref([])
      

      const route = useRoute()
      let root_path = JSON.parse(route.query.root_path) //list of exercises paths that have audio address
      let module_name = route.query.module_name
      
      getData(root_path, function (err, snapshot) {
        let module_object = snapshot.val()
        if ('session_slt_id' in module_object[module_name]) {
          session_slt_id.value = module_object[module_name]['session_slt_id']
        }
        
        if (module_object[module_name].hasOwnProperty('exercises') && typeof module_object[module_name]['exercises'][Symbol.iterator] === 'function') {

          for (let exercise_data of module_object[module_name]['exercises']) {

            if (exercise_data['session_name_picture']) {
              let data = {
                note: exercise_data['session_name_picture']['note'], 
                word_heard: exercise_data['session_name_picture']['word_heard'], 
                used_beginning_of_word: exercise_data['session_name_picture']['used_beginning_of_word'], 
                used_answer: exercise_data['session_name_picture']['used_answer'], 
                used_hint: exercise_data['session_name_picture']['used_hint'], 
                image: exercise_data['session_name_picture']['address']
              }

              isession_name_picture.value.push(data)
            }
          }
        }
      })

      return {
        isession_name_picture,
        session_slt_id
      };

    }
  }
</script>