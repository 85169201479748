<template>
  <div class="mt-4" v-if="current_stage_index != null">
    <Back @back="goBack()" v-if="current_stage_index == 1 && current_substage_index > 0 || current_stage_index != 1"/>
    <ProgressIndicator class="mt-4 mb-4" 
      :total_stages="stages.length-1" 
      :current_stage_index="current_stage_index" 
      :current_stage_progress="current_stage_progress" />

    <!-- Stages Starting Point -->    
    <Profile v-if="'profile' == stages[current_stage_index]"  
      @next="current_stage_index++" />
    
    <Self_Check v-if="'self_check' == stages[current_stage_index]" 
      :current_substage_index="current_substage_index"
      @next="current_stage_index++; current_stage_progress=0; current_substage_index=0" 
      @next_substage="(value) => current_substage_index = value"
      @update_current_progress="(progress) => current_stage_progress=progress" />
    
    <Set_Goals v-if="'set_goals' == stages[current_stage_index]" 
      @next="current_stage_index++"
    />

    <Personal_Interests v-if="'personal_interests' == stages[current_stage_index]" 
      @next="current_stage_index++"
    />
    
    <Select_TTS v-if="'select_tts' == stages[current_stage_index]" 
      @next="current_stage_index++"
    />

    <Reminder_Frequency v-if="'reminder_frequency' == stages[current_stage_index]" 
      @next="current_stage_index++"
    />

    <End v-if="'end' == stages[current_stage_index]"
      @next="current_stage_index++"
    />

    <!-- Stages Stopping Point -->
  </div>
</template>

<script>
import {ref, watch} from 'vue'
import firebase from '@firebase/app';
import {getUserDataDir} from '@/firebase/index'

import Back from './components/back.vue'
import ProgressIndicator from './components/progressIndicator.vue'
import Profile from './stages/profile.vue'
import Self_Check from './stages/self_check.vue'
import Set_Goals from './stages/set_goals.vue'
import Personal_Interests from './stages/personal_interests.vue'
import Select_TTS from './stages/select_tts.vue'
import Reminder_Frequency from './stages/reminder_frequency.vue'
import End from './stages/end.vue'

export default {
  name: 'Patient',
  components: {
    Back,
    ProgressIndicator,
    Profile,
    Self_Check,
    Set_Goals,
    Personal_Interests,
    Select_TTS,
    Reminder_Frequency,
    End
  },
  setup(props, { emit }) {
    const current_stage_index = ref(0)
    const current_substage_index = ref(0)
    const current_stage_progress = ref(0)

    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {

        getUserDataDir('Data' , function (err, result) {
          let onboarding = result.child('onboarding').val()
          current_stage_index.value = onboarding['current_stage_index']
        });
      } else {
        current_stage_index.value = 0
      }
    });

    
    const stages = [
      'profile', 
      'self_check', 
      'set_goals', 
      'personal_interests', 
      'select_tts',
      'reminder_frequency',
      'end'
    ]


    const goBack = () => {

      if (current_stage_index.value > 0 && current_stage_progress.value == 0) {
        current_stage_index.value--

        if (current_stage_index.value == 1) {
          //self check reached so set step to 4 (results page)
          current_substage_index.value = 3
        }
      } else if (current_stage_index.value >= 0 && current_substage_index.value > 0) {
        current_substage_index.value--
      } else {
        emit('backToRoot')
      }

    };

    return {
      stages,
      current_stage_index,
      current_substage_index,
      current_stage_progress, 

      goBack
    }
  }
}
</script>

<style scoped>

</style>
