

import {getData} from "../firebase"
import {getTodayString} from '@/media_input/utils'
import {capitalizeFirstLetter} from '@/media_input/words'

export function roundNumber(number, number_of_decimals)
{   
  let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)
  return rounded_number
}

export function phonemeToWord(word, phonemes)
{
  // console.debug('phoenme0= '+phonemes[1])
  let pho_pos = [];
  let word_pos = [];
  let pho_letter = [];
  let word_pos_in_pho = [];
  let phoneme_word = []


  if(word==undefined)
  {
    return [phoneme_word,word_pos_in_pho]

  }


  phoneme_word = word.split('');
  for(let i = 0;i<phonemes.length;i++)
  {

    if(constants.consonants.includes(phonemes[i]))
    {
      let pho = phonemes[i];
      let conso_arr = constants.conso_letters[pho];
      let temp_cons_pos = word.length+1;
      let temp_letter = ''
      for(let j=0;j<conso_arr.length;j++)
      {
        let temp_pos = ''
        if(word_pos.length>0)
        {
          temp_pos = word.indexOf(conso_arr[j],word_pos[word_pos.length-1]+1);
        }
        else
        {
          temp_pos = word.indexOf(conso_arr[j]);
        }
        if((temp_pos!=-1)&&(temp_pos<temp_cons_pos))
        {
          temp_cons_pos = temp_pos;
          temp_letter = conso_arr[j];
        }
      }
      if((temp_letter == 'cc')&&(i<phonemes.length-1)) //for cases to distinguish 'success' from 'succulent'
      {
        if(phonemes[i+1]=='s')
        {
          temp_letter = 'c'
        }
      }
      
      for(let n=0;n<temp_letter.length;n++)
      {
        pho_pos.push(i);
      }
      if(temp_cons_pos!=word.length+1)
      {
        for(let m=0;m<temp_letter.length;m++)
        {
          word_pos.push(temp_cons_pos+m)
          phoneme_word[temp_cons_pos+m] = pho;  
        }
        
        pho_letter.push(temp_letter)
      }
      else
      {
        console.debug('phoneme '+ pho+' doesnt have corresponding letters')
      }
      // if((pho == 'k')&&(phonemes[i+1]=='s'))
      // {
      //   i=i+1;
      // }

    }
  }
  for(let k=0;k<phoneme_word.length;k++)
  {
    if(word_pos.includes(k))
    {
      let id = pho_pos[word_pos.indexOf(k)];
      phoneme_word[k] = phonemes[id];
      word_pos_in_pho.push(id);
      // if(word == test)
      // {
      //   console.debug('k= '+k+' pho_id= '+id)
      // }
    }
    else
    {
      let [min_word_pos,max_word_pos] = findIDRange(word_pos,k);
      if((min_word_pos == ''))
      {
        min_word_pos = word_pos[0];
      }
      if(max_word_pos == '')
      {
        max_word_pos = word_pos[word_pos.length-1];
      }
      let min_pho_pos = pho_pos[word_pos.indexOf(min_word_pos)];
      let max_pho_pos = pho_pos[word_pos.indexOf(max_word_pos)];
      
      let pho_id = min_pho_pos+k-min_word_pos;
      // if(word == test)
      // {
      //   console.debug('word pos min='+min_word_pos+' max= '+max_word_pos);
      //   console.debug('pho pos min='+min_pho_pos+' max= '+max_pho_pos);
      //   console.debug('k= '+k+' pho_id= '+pho_id)
      // }
      while(pho_id>=max_pho_pos)
      {
        pho_id = pho_id - 1
      }
      if(k>max_word_pos)
      {
        pho_id = max_pho_pos+k-max_word_pos;
        if(pho_id>phonemes.length-1)
        {
          pho_id = phonemes.length-1
        }
      }
      if(k<min_word_pos)
      {
        pho_id = min_pho_pos - (min_word_pos - k)
        if(pho_id<0)
        {
          pho_id = 0;
        }
      }
      // if(word == test)
      // {
      //   console.debug('new pho_id= '+pho_id)
      // }
      phoneme_word[k] = phonemes[pho_id];
      word_pos_in_pho.push(pho_id)
    }
  }
  return [phoneme_word,word_pos_in_pho]
}

export function findIDRange(pos,target)
{
  let min_target = 0;
  let max_target = pos[pos.length-1];
  if(target<pos[0])
  {
    min_target = '';
    max_target = pos[0];
  }
  else if(target>pos[pos.length-1])
  {
    min_target = pos[pos.length-1];
    max_target = ''
  }
  else
  {
    for(let i=0;i<pos.length;i++)
    {
      if((pos[i]<target)&&(pos[i]>min_target))
      {
        min_target = pos[i];
      }

      if((pos[i]>target)&&(pos[i]<max_target))
      {
        max_target = pos[i]
      }
    }
  }

  return[min_target,max_target]
}

export function getIssueFlag()
{
    
    let temp_iss_flag = localStorage.getItem('issue_flag');

    if(temp_iss_flag!=undefined && temp_iss_flag!=null)
    {
        
        let issue_flag_arr = temp_iss_flag.split(',')
        let issue_flag = [];
        for(let i=0;i<issue_flag_arr.length;i++)
        {
            let flag = (issue_flag_arr[i] == 'true')
            issue_flag.push(flag);
        }
        return issue_flag

    }
    else
    {
        let issue_flag = [true,true,true,true]

        return issue_flag
    }

}

export async function Create_Phoneme_Exercise_List(phoneme_list, words_per_phoneme)
{
    var do_once = true;
    let today = getTodayString()
     

    let targetDir = 'Exercises/Articulation/'

    let exForm_list = new Promise(function(resolve) {
      let exForm_list = []
      
      getData(targetDir , function (err, result) {
        if (do_once) {

          do_once = false;

          for(let i in phoneme_list) {
            Create_Single_Phoneme_Exercise_List(phoneme_list[i],words_per_phoneme,exForm_list,result,today)
          }
          console.debug('exForm_list.length = ' + exForm_list.length)
          resolve(exForm_list)
        }
      });
    })

    return exForm_list
}

import constants from "./constants"

function Create_Single_Phoneme_Exercise_List(phoneme,words_per_phoneme,exForm_list,database,today,record_text)
{

    let temp_word_list = []


    database.child('Consonants').forEach(function(childSnapshot)
    {

        if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
        {

            let exForm = childSnapshot.val()

            exForm.instruct = null
            // exForm.text = "Practise the sound /" + constants.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()]+"/ with the video"
            exForm.text = "Practise the sound '" + constants.phonemeDisplayUKSpelling[childSnapshot.key.toLowerCase()]+"' with the video"
            exForm.curr_exercise_path = childSnapshot.key 
            exForm.today = today
            exForm.module_name = 'IPA_'+ childSnapshot.key
            exForm.assessment_type = "USER_TRAINING"

            exForm_list.push(exForm)            
            
        }
    });

    database.child('Vowels').forEach(function(childSnapshot)
    {

        if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
        {

            let exForm = childSnapshot.val()

            exForm.instruct = null
            // exForm.text = "Practise the sound /" + constants.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()]+"/ with the video"
            exForm.text = "Practise the sound '" + constants.phonemeDisplayUKSpelling[childSnapshot.key.toLowerCase()]+"' with the video"
            exForm.curr_exercise_path = childSnapshot.key 
            exForm.today = today
            exForm.module_name = 'IPA_'+ childSnapshot.key
            exForm.assessment_type = "USER_TRAINING"

            exForm_list.push(exForm)
            
        }
    });

    database.child('Words').forEach(function(childSnapshot)
    {
        if(childSnapshot.key.toUpperCase() == phoneme.toUpperCase())
        {
            childSnapshot.forEach(function(exerciseSnapshot)
            {

                let exForm = exerciseSnapshot.val()

                exForm.text =  capitalizeFirstLetter(exForm.text)
                // exForm.instruct = "Press Record and practise pronouncing the phoneme '" + constants.phonemeDisplayDictionary[childSnapshot.key.toLowerCase()] + "' in word: "
                exForm.instruct = "Watch the video. Press Record and say the word: "
                exForm.curr_exercise_path = childSnapshot.key + "/" + exerciseSnapshot.key
                exForm.today = today
                exForm.module_name = 'IPA_'+ childSnapshot.key
                exForm.start_btn_text = 'Record'
                exForm.assessment_type = "USER_TRAINING"


                temp_word_list.push(exForm)

            
            });
        }
    });


    if(words_per_phoneme>0)
    {
        if(temp_word_list.length>words_per_phoneme)
        {
            temp_word_list = getRandom(temp_word_list, words_per_phoneme)
        }
        
    }


    for(let i in temp_word_list)
    {
        exForm_list.push(temp_word_list[i])
    }

}

function getRandom(arr, n) {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}

export function count_db_requested_exercises(module_root) {
  let n_exercises = 0

  ///tmp workaround for incosistent database
  for (let key of Object.keys(module_root)) {
    if (key.toLowerCase().includes('module')) {
      //this shouldn't be correct as instruction types are also counted
      n_exercises = n_exercises + 1
    }
  }
  ////

  if (module_root.hasOwnProperty('number_exercises')) {
    n_exercises = Number(module_root['number_exercises'])
    return n_exercises
  }

  if (module_root.hasOwnProperty('exercises')) {
    for (let exercise of module_root['exercises']) {

      if(exercise)
      {
        let exercise_range = exercise['exercise_range']
        if (exercise_range) {
          if (exercise_range === 'multi_exercises') {
            n_exercises = n_exercises + Number(exercise['number_exercises'])
          } else {
              //this shouldn't be correct as instruction types are also counted
              //n_exercises = n_exercises + 1
          }
        }
      }
    }
  
  } else {
    
    if (n_exercises > 0) {      
      n_exercises = 5
      //do nothing it is a module0-2 module (so no exercises folder included)
    } else {
      n_exercises = 5
    }
  }

  return n_exercises
}

export function count_db_total_all_type_exercises(module_root) {
  let n_exercises = 0

  ///tmp workaround for incosistent database
  for (let key of Object.keys(module_root)) {
    if (key.toLowerCase().includes('module')) {
      //this shouldn't be correct as instruction types are also counted
      n_exercises = n_exercises + 1
    }
  }

  if (module_root.hasOwnProperty('number_exercises')) {
    n_exercises = Number(module_root['number_exercises'])
  }
  console.log(n_exercises)
  if (module_root.hasOwnProperty('exercises')) {
    for (let exercise of module_root['exercises']) {
      if(exercise) {
        let exercise_type = exercise['type']
        if (exercise_type) {
          if (!exercise_type.includes('demo_video')) 
          {
            n_exercises = n_exercises + 1
          }
        }
      }
    }
  }

  return n_exercises
}