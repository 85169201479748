<template>
  <div class="d-flex flex-column min-vh-100">
    <nav class="navbar navbar-expand-md navbar-dark align-items-end" style="background-color: #0D302A;">
      <div class="container-fluid align-items-end">
        <a class="navbar-brand v-step-0" @click="$router.push({name: 'home'})" style="cursor:pointer">
          <img src="../../assets/images/logo/logo.svg" alt="" width="225" class="d-inline-block align-text-top">
        </a>
      </div>
    </nav>
    <div class="container">
      <h1 class="display-4 mt-4">About the platform</h1>
      <div class="row ms-4 mt-4 mb-4">
        <p>Beautiful Voice platform is an online application that is intended
            for long-term use by adult users with speech, voice or language
            difficulties and by speech and language therapists. Beautiful
            Voice is intended to provide evidence-based exercise materials
            and real-time feedback to help users practise speaking more
            clearly and loudly. Regular speech, voice and language practices
            can help improve communication and language skills.
        </p>
        <div class="row mt-2 mb-4">
          <div class="col-md-6">
            <div class="row pb-2 pt-2 border border-primary">
              <div class="col-md-3">
                <img class="img-fluid" src="../../assets/images/about/eifu.png"/>
              </div>
              <div class="col-md-9">
                <ul>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/about%2Fhome_user_IFU.pdf?alt=media&token=389de4fb-2a17-4154-bacf-42600339ffeb" target="_blank"><u>Home user</u></a></li>
                  <li><a href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/about%2FSLT_IFU.pdf?alt=media&token=bc28a7d1-2a78-48fb-9ca9-896b3e1d9d70" target="_blank"><u>Speech and language therapists</u></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-2">
                <img class="img-fluid" src="../../assets/images/about/UKCA_filled.svg"/>
              </div>
              <div class="col-md-4">
                <div class="row justify-content-center g-0">
                  <div class="col-6">
                    <img class="img-fluid" src="../../assets/images/about/ref.png"/>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <p class="mb-0">BV01</p>
                  </div>
                </div>
              </div>
              <!--
              <div class="col-md-4">
                <div class="row justify-content-center g-0">
                  <div class="col-6">
                    <img class="img-fluid" src="../../assets/images/about/lot.png"/>
                  </div>
                  <div class="col-4 d-flex align-items-center">
                    <p class="mb-0">{{ version }}</p>
                  </div>
                </div>

              </div>-->
            </div>
          </div>
        </div>
        <hr style="height:2px;border:none;color:#333;background-color:blueviolet;" />
        <h4 class="mt-4 mb-4">What's new</h4>

        <div class="border shadow-sm">
          <p>Release: v1.4 31/05/2024 </p>
          <p>
            New Features:
            <ul class="mt-2" style="list-style-type:circle;">
              <li>
                Reading comprehension - personal interest, news
              </li>
              <li>
                Hangman
              </li>
              <li>
                Copy words
              </li>
              <li>
                Common sayings
              </li>
            </ul>
            Home user platform:
            <ul class="mt-2" style="list-style-type:circle;">
              <li>
                Home users can now directly subscribe to Premium plan, enjoying personalised exercises created for your needs
              </li>
            </ul>
            SLT platform:
            <ul class="mt-2" style="list-style-type:circle;">
              <li>
                New language assessment: Yes/No assessments
              </li>
              <li>
                SLTs are now able to view home assignment history
              </li>
              <li>
                SLTs are now able to assign licences to clients, providing them with unlimited access to home assignments
              </li>
            </ul>
            All platforms:
            <ul class="mt-2" style="list-style-type:circle;">
              <li>
                Improved reset password process
              </li>
              <li>
                Non-essential text-to-speech can now be turned off
              </li>
            </ul>
          </p>
        </div>
        <hr/>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>

<script>

import Footer from '@/views/Footer.vue'
export default {
  props: {

  },
  components: {Footer},
  name: "About",
  data() {
    return {
      version: import.meta.env.VITE_VERSION
    }
  },
  created () {
  },
  destroyed () {
  },
  mounted () {
  },
 
}
</script>

<style scoped>

#footer {
  position: fixed;
  height: 50px;
  background-color: red;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

</style>