
import {updateDataDirectory} from '@/firebase/index'

const list_of_symbols_2_replace = {
  '&laquo;': '«',
  '&raquo;': '»',
  '&reg;':'®',
  '&deg;':'°',
  '&plusmn;':'±',
  '&middot;':'·',
  '&frac12;':'½',
  '&ndash;':'–',
  '&mdash;':'—',
  '&lsquo;':'‘',
  '&rsquo;':'’',
  '&sbquo;':'‚',
  '&ldquo;':'“',
  '&rdquo;':'”',
  '&bdquo;':'„',
  '&bull;':'•',
  '&prime;':'′',
  '&Prime;':'″',
  '&euro;':'€',
  '&trade;':'™',
  '&le;':'≤',
  '&ge;':'≥',
  '&lt;':'<',
  '&gt;':'>',
  '&rsquo;': '\'',
  '&quot;':'\"',
  '&pound;': '\£',
  '&dollar;':'\$'

}

const list_of_punctuation_2_remove= {
  '\\.': '',
  '\!': 'a',
  '\\?': '',
  '\,': 'a',
  '\;': 'a',
  '\\(': 'a',
  '\\)': 'a',
  '\\"': 'a'
}



export function checkUndefinedNull(in_data)
{

  return (typeof in_data===undefined || in_data===null)


}


export function getSeconds()
{

  var seconds = new Date().getTime() / 1000;

  return seconds
}

export function getTodayString()
{

  let today = new Date();
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0! so we add 1
  var yyyy = today.getFullYear()
  var hour = String(today.getHours()).padStart(2, '0')
  var minute = String(today.getMinutes()).padStart(2, '0')

  function zeroPad(nr,base){
    var  len = (String(base).length - String(nr).length)+1;
    return len > 0? new Array(len).join('0')+nr : nr;
  }

  mm = zeroPad(mm,2)
  dd = zeroPad(dd,2)
  hour = zeroPad(hour,2)
  minute = zeroPad(minute,2)

  today = yyyy + '-' + mm + '-' + dd + ' ' + hour + ':' + minute;


  return today;


}

export function removePunctuationFromString(text)
{
  if(text == undefined || text == null || text === "")
  {
    console.debug('Empty or undefined string')

  }
  else
  { 


    for(let k in list_of_punctuation_2_remove)
    {

      text = text.toString()

      let temp = {}
      temp[k] = '1'

  
  
      let re = new RegExp([k].join("|"),"gi"); 
  
  
      
      text = text.replace(re, '')
  
  

    }

  }

  return text
}

export function removeQuotesAndSimilarForAnalysis(text)
{

  text = text.replaceAll('\n','')

  let regex = new RegExp('[^a-zA-Z0-9][^a-zA-Z0-9]','g')

  text = text.replace(regex,' ') 

  let first_characters_replace = ["'","`",'"', "‘","’"]

  for(let k in first_characters_replace)
  {
    if(text.charAt(0)===first_characters_replace[k])
    {
      text = text.substring(1)
    }
  }

  let last_characters_replace = ["'","`",'"', "‘","’"]


  for(let k in last_characters_replace)
  {
    if(text.charAt(text.length-1)===last_characters_replace[k])
    {
      text = text.substring(0, text.length - 1);
    }
  }

  return text


}


export function removeWeirdCharactersFromString(text)
{
  if(text == undefined || text == null || text === "")
  {
    console.debug('Empty or undefined string')

  }
  else
  {
    text = text.toString()

    for(let i in list_of_symbols_2_replace)
    {
      let regex  = new RegExp(i,'g') //g is global, so it applies to all instances of this regex
      text = text.replace(regex,list_of_symbols_2_replace[i]) 
    }

  }

  return text
}

export function roundNumber(number,number_of_decimals)
{   
  let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

  return rounded_number;

}

export function logActivity(firebase, type, activityData)
{

  let uid = localStorage.getItem('uid')

  const d = new Date();
  let month = d.getUTCMonth() + 1
  let datef = d.getUTCFullYear() + "-" + month + "-" + d.getUTCDate()

  let ms = d.valueOf()
  let log_data = {}
  log_data[ms] = activityData

  let user_page_red ='Users/' + uid + '/Activity/' + datef + '/' + type
  updateDataDirectory(user_page_red, log_data);
}

export function getWeekNumber() {
  let d = new Date()
  // Copy date so don't modify original
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
  d = new Date()
  let day = d.getDay();

  console.debug('getday = ' + day)

  // Return array of year and week number and week day
  return [String(d.getUTCFullYear()), String(weekNo), String(day)];
}

export function substractDayFromWeekInfo(week_info)
{

  let day = week_info[2]
  let week = week_info[1]
  let year = week_info[0]

  if(day>1)
  {
    day = day-1
  }
  else
  {
    day = 7

    if(week>1)
    {
      week = week-1
    }
    else
    {
      week = 52
      year = year - 1
    }

    
  }


  return [year,week,day]



}