<template>
  <div>
    <!-- <div class="position-relative">
      <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
    </div> -->
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer"  @click="selected_module='pd_facial_expressions';openModal('facial_expressions_modal')">
    <div class="row">
      <div class="col-3" style="padding: 0px 0px 0px 10px;">
        <img :src="Facial_expression_img" class="img-thumbnail bg-warning text-white rounded-circle me-2 d-inline">
      </div>
      <div class="col-9 d-flex align-items-center">
        <strong>Facial Expressions</strong>
      </div>
    </div>
  </div>
  <div class="modal fade" id="facial_expressions_modal" tabindex="-1" aria-labelledby="facial_expressions_modal" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
            
          <h5 class="modal-title">Facial Expressions</h5>
        
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('pd_facial_expressions')"></button>
        </div>
        <div class="modal-body">
          <div class="row" style="height:40vh">
            <div class="col-4 border-3 border-success border-end">
              <!-- <div class="position-relative">
                  <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                </div> -->
              <button :class="['btn','mb-2', 'w-100', selected_module == 'pd_facial_expressions'?'btn-primary':'btn-light']" type="button" @click="selected_module='pd_facial_expressions'">
                Facial expressions
              </button>
            </div>
            <div class="col-8">
              <div v-show="selected_module == 'pd_facial_expressions'">
                <p class="lead">Exercise facial muscles with facial expressions</p>
                <div class="row mb-2">
                  <label class="col-6 form-label" for="pd_facial_expression">Choose a facial expression</label>

                  <div class="col-6">
                    <select class="form-select form-select" v-model="module_configuration.pd_facial_expression">
                      <option value="mixed">Mixed</option>
                      <option value="smile">Smile</option>
                      <option value="angry">Angry</option>
                      <option value="surprised">Surprised</option>
                      <option value="disgust">Disgust</option>
                      <option value="excited">Excited</option>
                    </select>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list">Add to home training list</button>
            <button v-if="subscription_tier.includes('F')" type="button" class="btn btn-primary" disabled >Start training</button>
            <button v-else type="button" class="btn btn-primary" @click="emit_start_training">Start training</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import Facial_expression_img from '@/assets/images/dashboard/slt/training/Voice_facial_expression_exercise.svg'

import { Modal } from 'bootstrap'

export default {
  name: 'facial_expressions',
  components: {},
  props: 
  {
  },
  data(){
    return {
      subscription_tier: sessionStorage.getItem("tier"),
      Facial_expression_img: Facial_expression_img, 

      selected_module: '',
      local_number_exercises: 5,

      modal_references: {},

      module_configuration: {
        pd_facial_expression: 'mixed'
      }
    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
    
  },
  mounted() {
    this.modal_references = {
      "facial_expressions_modal": new Modal(document.getElementById('facial_expressions_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('facial_expressions_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('facial_expressions_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration = {
        pd_facial_expression: 'mixed'
      }
    },
  }
}
</script>