<template>
  <div>
    <canvas ref="loudCanvas" height="30"></canvas>
  </div>
</template>
  
<script>
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue';

export default {
  props:['loudness'],
  setup(props) {
    console.debug('creating chart')
    const loudness = ref(props.loudness)

    const loudCanvas = ref(null)
    const ctx = ref(null);

    const plotter = (sound_l) => {
  
      console.log(sound_l)
      /*
      let sound_threshold = 0;
      
      console.debug('sound_l = ' + sound_l)
      if (sound_l > sound_threshold) {
        _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
        _this.avg_loudness_show = roundNumber(_this.avg_loudness,0)
        _this.counter_loudness += 1;
      }

      if (sound_l>_this.max_loudness) {
        _this.max_loudness = sound_l
      }*/

      let WIDTH = 400;
      let HEIGHT = 30;

      let green_threshold = 50;

      if (ctx.value) {
        ctx.value.clearRect(0, 0, WIDTH, HEIGHT);
      
        if (sound_l > green_threshold) {
          ctx.value.fillStyle = "green";
        } else {
          ctx.value.fillStyle = "red";
        }
        ctx.value.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
      }
    }

    onMounted(() => {
      if (loudCanvas.value) {
        ctx.value = loudCanvas.value.getContext('2d');
      }
    });

    watch(
      
      () => {
        return props.loudness;
      }, (newVal, oldVal) => {
        console.error(newVal)
        plotter(newVal);
      }
    
    );
    
    return {
      loudCanvas
    }
  }
}
</script>

<style scoped>
</style>
