<template>
  <div> 
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-2">Account information</h5>
        <div class="row justify-content-between">
          <div class="col-md-5">
            <p class="lead mt-3">{{ name }}</p>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-lg-5">
            <p class="mt-3">Email Address</p>
            <input class="form-control" v-model="email" autocomplete="off" disabled>
            <div>
              <p class="mt-3">Display name</p>
              <div class="input-group">
                <input class="form-control" v-model="userName" autocomplete="off">
                <button class="btn btn-outline-primary" @click="updateUserInfo('userName')">Update</button>
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="tier_name && next_payment_due">
            <div class="card h-100">
              <div class="card-body">
                <p><strong>Subscription plan:</strong> <i>{{ tier_name }}</i></p>
                <p v-if="tier !== 'F1' && expiry_date != ''" ><strong>Expiry date:</strong> <i>{{ expiry_date }}</i></p>
                <p v-if="tier !== 'F1' && tier !== 'Trial1'" ><strong>Next payment due:</strong> <i>{{ next_payment_due }}</i></p>
                <button v-if="tier == 'F1' || tier == 'Trial1'" class="btn btn-primary float-start" 
                @click="goToSubscriptionPage()">Upgrade Subscription</button>
                <button v-if="!tier.includes('Trial1') && !tier.includes('F1')" class="btn btn-outline-success float-end" 
                @click="goToCustomerPortal()">Payment details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Affiliation</h5>
        <div class="row justify-content-between">
          <div class="col-lg-5 col-12 mb-4">
            <p class="form-label">Organisation</p>
            <div class="input-group">
              <input class="form-control" v-model="affiliation" placeholder="Name of your organisation" type="text"> 
              <button class="btn btn-outline-primary" @click="updateUserInfo('affiliation')">Update</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <br/>
            <div class="form-check form-check-inline d-flex">
              <input class="form-check-input me-2" type="checkbox" v-model="discoverable" @change="updateUserInfo('discoverable')">
              <label class="form-check-label"> I would like patients looking for speech and language therapists to be able to find and contact me through the platform (optional).</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Field of interests</h5>
        <div class="row justify-content-center">
          
          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d1" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Voice">
                <label class="form-check-label" for="d1">
                  Voice
                </label>
            </div>
          </div>
          
          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d2" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Speech"> 
              <label class="form-check-label" for="d2">
                Speech
              </label>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d3" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Language"> 
              <label class="form-check-label" for="d3">
                Language
              </label>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d3" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Cognition"> 
              <label class="form-check-label" for="d3">
                Cognition
              </label>
            </div>
          </div>
          
          <div class="col-lg-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d4" v-model="field_of_interest" @change="updateUserInfo('field_of_interest')" value="Swallowing"> 
              <label class="form-check-label" for="d4">
                Swallowing
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
    
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Preferences</h5>
        <div class="row">
          <div class="d-flex flex-row">
            <div class="col-lg-5 me-2">
              <label class="form-label">Region</label>
              <div class="row justify-content-between">
                <div class="col">
                  <select class="form-select" v-model="region" placeholder="Select" @change="updateUserInfo('region')">
                    <option label="United Kingdom" value="UK"></option>
                    <option label="United States" value="US"></option>
                  </select>
                </div>
              </div>

            </div>
            <div class="col-lg-6">
              <label class="form-label">Language</label>
              <div class="row justify-content-between">
                <div class="col">
                  <select class="form-select" v-model="language" placeholder="Select" @change="updateUserInfo('language')">
                    <option label="British English" value="UK"></option>
                    <option label="American English" value="US"></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between mt-4">
          <div class="col-lg-6" v-if="voices.length > 0">
            <label class="form-label">Select voice</label>
            <div class="row justify-content-between">
              <div class="col">
                <select class="form-select" v-model="prefered_voice" placeholder="Select" @change="updateUserInfo('prefered_voice')">
                  <option v-for="(voice, key) in voices.filter(voice => voice.lang.includes(language == 'UK'? 'GB': language) && voice.name != 'Fiona')" :key="key" :label="voice.name" :value="voice.name"></option>
                </select>
              </div>
              <div class="col-4 text-start">
                <button class="btn btn-primary" v-if="voices.some(voice => voice.lang.includes(language == 'UK' ? 'GB' : language) && voice.name === prefered_voice)" @click="test_voice()">Test Voice</button>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <label class="form-label">Text-to-Speech Synthesis</label>
            <br/>
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" v-model="tts_enabled" @change="update_auto_tts()">
              <label v-if="tts_enabled" class="form-check-label">Text-to-Speech synthesis is <span class="text-success">active</span></label>
              <label v-else class="form-check-label">Text-to-Speech synthesis is <span class="text-secondary">inactive</span></label>
            </div>
          </div>
        </div>
        <hr/>
        <div class="row mt-2">
          <div class="col-4">
            <p>Restart On-Screen Welcome Tutorials</p>
            <button class="btn btn-primary" @click="reset_tour()">Restart</button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Calibrate your microphone</h5>
        <div class="row justify-content-between">
          <div class="col-lg-10">
            <label class="form-label">Calibrating your microphone will make your loudness readings more accurate. Contact contact@beautifulvoice.co.uk for help.</label>
          </div>
          <div class="col-lg-2">        
            <button class="btn btn-primary" @click="goToCalibrate()">Calibrate</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {getUserDataDir, updateDataDirectory} from '@/firebase/index'
import { useToast } from "vue-toastification";
import { useTTS } from '@/stores/tts'

export default {
  components: {  },
  name: 'slt_settings',
  props: 
  {
    tier_name: {
      type:String,
      required: true,
    }, 
    next_payment_due: {
      type:String,
      required: true,
    }, 
  },
  data(){
    return {
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      language: localStorage.getItem('language'),
      name: '',
      email: '',
      userName: '',
      field_of_interest: [],
      issue_lib: ['Slurred/choppy speech', 'Difficulty in moving lips, jaw or tongue', 'Hoarse/breathy voice', 'Dysphasia/aphasia'],
      issue_flag: [false,false,false,false],
      interestCheck: [],
      allInterests: [],
      affiliation: '', 
      discoverable: false,

      tier: sessionStorage.getItem("tier"),
      
      expiry_date: "",

      bv_tts: useTTS().tts_engine,
      voices: [],
      prefered_voice: null,
      tts_enabled: false,
    }
  },
  created() {
    this.loadUserInfo(); 
  },
  methods: {
    reset_tour() {
      let uid = localStorage.getItem('uid')
      let ref_path = 'Users/' + uid + '/Data/startTour'

      sessionStorage.setItem('patient_interface_selected_page', 1)
      updateDataDirectory(ref_path, {
        dashboard: false,
        patient_home_dashboard: false,
        patient_interface: false,
        results_page_tour: false,
        voice_speech_custom: false,
      })
      this.$router.push({name: "home"})
    },
    test_voice() {
      this.bv_tts.say("Hello. What a nice day to practice speech!")
    },
    update_auto_tts() 
    {
      let uid = localStorage.getItem('uid');
      let ref_path = 'Users/' + uid + '/Settings'
      updateDataDirectory(ref_path, {'auto_tts': this.tts_enabled})
    },
    async loadUserInfo()
    {
      let _this = this;

      await new Promise(resolve => {
        getUserDataDir('Data/', async function (err, result) {

          _this.email = result.child('Email').val()

          _this.field_of_interest = [];
          _this.field_of_interest = result.child('Qs_foi').val();
          if(_this.field_of_interest == undefined || _this.field_of_interest == null) {
            _this.field_of_interest = []
          }
          
          let userName = result.child('userName').val()
          if(userName == undefined || userName == null) {
            _this.userName = result.child('Firstname').val();
          } else {
            _this.userName = userName;
          }

          _this.name =  result.child('Firstname').val().charAt(0).toUpperCase() + result.child('Firstname').val().slice(1) + ' ' +
                        result.child('Surname').val().charAt(0).toUpperCase() + result.child('Surname').val().slice(1)

          let discoverable = result.child('discoverable').val()
          
          if(discoverable == "True" || discoverable == true) {
            _this.discoverable = true
          } else {
            _this.discoverable = false
          }

          let affiliation = result.child('affiliation').val()

          if(affiliation == undefined || affiliation == null) {
            _this.affiliation = result.child('affiliation').val();
          } else {
            _this.affiliation = affiliation;
          }
          
          _this.prefered_voice = await _this.bv_tts.prefered_voice

          _this.voices = _this.bv_tts.language_voices //depends on the above promise
          _this.voices.unshift({"lang": "GB", "name": "BV AI"})
          _this.voices.unshift({"lang": "US", "name": "BV AI"})
          
          resolve()
        });
      })


      await new Promise(resolve => {
        getUserDataDir('Settings/', async function (err, result) {

          _this.region = result.child('region').val()
          if(_this.region == undefined || _this.region == null) {
            _this.region = 'UK'
          }

          localStorage.setItem('region', _this.region)

          _this.language = result.child('language').val()
          if(_this.language == undefined || _this.language == null) {
            _this.language = 'UK'
          }

          localStorage.setItem('language', _this.language)


          let value = result.child('auto_tts').val()
          if (value != null) {
            _this.tts_enabled = value
          } else {
            _this.tts_enabled = true
            _this.update_auto_tts()
          }

          resolve()
        })
      })

    },
    updateUserInfo(inp)
    {
      let _this = this;

      let uid = localStorage.getItem('uid')
      let ref_path = 'Users/' + uid + '/Data/'
      
      let info_updated = false
      if(inp == 'userName') {
        updateDataDirectory(ref_path, {'userName':_this.userName})

        info_updated = true
      } else if(inp == 'field_of_interest') {
        updateDataDirectory(ref_path, {'Qs_foi':_this.field_of_interest})

        info_updated = true
      } else if(inp == 'affiliation') {
        updateDataDirectory(ref_path, {'affiliation':_this.affiliation})

        info_updated = true
      } else if(inp == 'discoverable') {
        updateDataDirectory(ref_path, {'discoverable':_this.discoverable})

        info_updated = true
      } else if(inp == 'region') {
        updateDataDirectory('Users/' + uid + '/Settings/', {'region': _this.region})
        localStorage.setItem('region', _this.region)

        info_updated = true
      }
      else if(inp == 'language') {
        updateDataDirectory('Users/' + uid + '/Settings/', {'language': _this.language})
        localStorage.setItem('language', _this.region)

        info_updated = true
      } 
      else if(inp == 'prefered_voice') {
        
        updateDataDirectory(ref_path, {'prefered_voice': _this.prefered_voice})
        this.bv_tts.set_voice()

        info_updated = true
      }

      if (info_updated) {
        const toast = useToast()
        toast.info("Your information has been updated.")
      }

      _this.$emit('closeDialog',false);
    },
    loadPersonalInterst()
    {
      const _this = this

      let interests_array = localStorage.getItem('interests_array');
      console.debug('getting interests array = ' + interests_array)

      if(interests_array!=undefined && interests_array!=null)
      {
        interests_array = JSON.parse(interests_array)
      }
      else
      {
        interests_array = []
      }

      console.debug('interests array2 = ' + interests_array)

      for(let n in interests_array)
      {
        for(let i in _this.allInterests)
        {
          if(interests_array[n]==_this.allInterests[i].name)
          {
            console.debug('found interest')
            _this.allInterests[i].check = 'danger'
            break;
          }
        }
      }
    },
    goToCalibrate()
    {
      this.$router.push({name: "microphone_calibration"})
    },
    goToSubscriptionPage() {
      this.$router.push({name: "subscribe"})
    },
    goToCustomerPortal() {
      window.location.href = import.meta.env.VITE_CUSTOMER_PORT_LINK + this.email
    },
  },
}
</script>
<style scoped>

li:hover {
  background-color: #e0e0e2;
  color: white;
}
</style>