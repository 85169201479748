<template>
  <div>
    <h1 class="text-center">Questionnaire Assessment Results</h1>
    <div v-if="room_meta.session_slt_id.value" class="text-center">
      <p class="lead">Session ID: {{ room_meta.session_slt_id.value }}</p>
    </div>
    <div v-if="results.value">
      <div class="row mt-2">
        <div class="card card-body" v-if="results.value.length > 0">
          <div>
            <p class="h4 text-start">Session Questionnaire</p>
          </div>
          <table class="table mt-4">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Question</th>
                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in results.value" v-bind:key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-start">{{ row['session_questionnaire']['question'] }}</td>
                <td>{{ row['session_questionnaire']['options'][row['session_questionnaire']['answer']]? row['session_questionnaire']['options'][row['session_questionnaire']['answer']] : 'Not Answered'}}</td>
              </tr>
            </tbody>
          </table> 
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary" @click="exit">Exit Session</button>
      </div>
    </div>

  </div>
</template>
  
<script>
import firebase from '@firebase/app';
import { reactive } from 'vue'

export default {
  props: ['room_meta'],
  components: {
  },
  setup(props, {emit}) {
    
    const room_meta = props.room_meta
    const results = reactive({})
    let module_name = room_meta.session_name.value
    
    for (const [patient_uid, value] of Object.entries(room_meta.users.value)) {

      if (value['category'] === "patient") {
        const path = 'Users/' + patient_uid + '/Results/remote_assessment/general/' + room_meta.session_date.value + '/' + module_name
        firebase.database().ref(path).child('exercises').get().then( (snapshot) => {

          let exercises_data = snapshot.val()
          if (exercises_data) {
            results.value = exercises_data
            console.error(exercises_data)
          }
        });
      }
    }
    
    const exit = () => {
      emit("exit")
    }

    return {
      exit,
      results,

    }
  }
}
</script>

<style scoped>
</style>
