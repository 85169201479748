<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>

    <div class="container mb-5">
      <h1 class="display-5">Exercise facial muscles</h1>

      <div class="card p-2">
        <div clas="card-body text-center">
          <div class="row">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>1</strong>
                  </div>
                    <p class="lead mt-4" style="font-size: x-large;"> Blow a Tissue</p>
                    <img :src="blowImg" width="180" height="180" style="object-fit: cover">
                    <br/>
                    <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('blow')"> Start this exercise</button>

                  </div>
                </div>
              </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>2</strong>
                    </div>
                    <p class="lead mt-4" style="font-size: x-large;"> Smile (single side)</p>
                  <img :src="smilesideImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('smileside')"> Start this exercise</button>

                  </div>
                </div>
              </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>3</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Puff Cheeks (both sides)</p>
                  <img :src="puff_cheeks_bothImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('puff_cheeks_both')"> Start this exercise</button>

                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>4</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Puff Cheeks (single side)</p>
                  <img :src="puff_cheeks_sideImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('puff_cheeks_side')"> Start this exercise</button>

                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>5</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Say Sound P</p>
                  <img :src="sayPImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('sayP')"> Start this exercise</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>6</strong>
                  </div>
                  
                  <p class="lead mt-4" style="font-size: x-large;"> Pouting Lips</p>
                  <img :src="poutingImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('pouting')"> Start this exercise</button>

                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>7</strong>
                  </div>

                  <p class="lead mt-4" style="font-size: x-large;"> Raise Eyebrows</p>
                  <img :src="eyebrowsImg" width="180" height="180" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="facial_exercise('eyebrows')"> Start this exercise</button>

                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>


    </div>
    <Footer class="mt-auto"></Footer>

  </div>
</template>
<script>
  
import puff_cheeks_sideImg from '@/assets/images/facial_muscles/puffing cheek side to side.png'
import puff_cheeks_bothImg from '@/assets/images/facial_muscles/puffing cheeks with air.png'
import blowImg from '@/assets/images/facial_muscles/blowing on a tissue.png'

import eyebrowsImg from '@/assets/images/facial_muscles/raising eyebrows.png'
import poutingImg from '@/assets/images/facial_muscles/pouting lips.png'
import sayPImg from '@/assets/images/facial_muscles/saying sounds p.png'

import smilesideImg from '@/assets/images/facial_muscles/smiling on each side.png'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'Facial Expressions',
  components: {Footer, Navbar},
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      
      pre_loaded_exercises:[],
      autoGenWords:[],
      smilesideImg:smilesideImg,
      sayPImg:sayPImg,
      puff_cheeks_sideImg:puff_cheeks_sideImg,
      puff_cheeks_bothImg:puff_cheeks_bothImg,
      blowImg:blowImg,
      eyebrowsImg:eyebrowsImg,
      poutingImg:poutingImg
    }
  },
  created() {

  },
  methods: {

      facial_exercise(type)
      {
          
          if(type==='puff_cheeks_both')
          {
          this.$router.push({ name: 'stroke_puff_cheeks_both'});
          }
          else if(type==='puff_cheeks_side')
          {
          this.$router.push({ name: 'stroke_puff_cheeks_side'});

          } 
          else if(type==='blow')
          {
          this.$router.push({ name: 'stroke_blow'});

          }
          else if(type==='pouting')
          {
          this.$router.push({ name: 'stroke_pout_lips'});

          }
          else if(type==='eyebrows')
          {
          this.$router.push({ name: 'stroke_eyebrows'});

          }
          else if(type==='sayP')
          {
          this.$router.push({ name: 'stroke_facial_say_p'});

          }
          else if(type==='smileside')
          {
          this.$router.push({ name: 'stroke_smile_sides'});

          }
      
      },
  },
}
</script>
<style scoped>

.custom-card_blue{
    background-color: #cfe2f3;
    border-color: #cfe2f3;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}


</style>