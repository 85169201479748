<template>
  <div>

    <div class="card card-body">
      <div class="row mt-2 mb-2 text-start">
        <h5>Home Assignment</h5>
      </div>
      <div class="row">
        <ul class="nav nav-underline nav-justified" role="tablist">
          <li class="nav-item" style='cursor:pointer'>
            <a class="nav-link active" data-bs-toggle="tab" data-bs-target="#nav-list" >List</a>
          </li>
          <li class="nav-item" style='cursor:pointer' @click="getAssignmentHistory()">
            <a class="nav-link" data-bs-toggle="tab" data-bs-target="#nav-history">History</a>
          </li>
        </ul>
      </div>
      <div class="row">
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-list" role="tabpanel" tabindex="0">
            <br/>
            <div class="mt-4">
              <div v-if="!sending_homework">
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn btn-warning me-2" type="button" v-if="has_last_assignment" @click="load_last_sent_assignment()">Load last sent assignment</button>
                  <button class="btn btn-primary me-2" type="button" data-bs-toggle="modal" data-bs-target="#send_list_modal" :disabled="table_assignment_list.length<1">Send list to patient</button>
                  <button class="btn btn-danger" type="button" @click="clearAllAssignmentList()" :disabled="table_assignment_list.length<1">Clear all</button>
                </div>
              </div>
              <div v-else>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn btn-primary me-2" type="button" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Sending home assessment</button>
                  <button class="btn btn-danger" type="button" disabled>Clear all</button>
                </div>
              </div>
              <div v-if="table_assignment_list.length > 0">
                <table class="table mt-4" >
                  <thead>
                    <tr class="text-start">
                      <th scope="col">#</th>
                      <th scope="col" >Name</th>
                      <th scope="col">Key configuration</th>
                      <th scope="col"># Exercises</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="list_type==='assessment'" class="text-start">
                    <tr v-for="element, index in table_assignment_list" :key="element.index" >
                      <th>{{ index+1 }}</th>
                      <td>{{ element.name }}</td>
                      <td>{{ element.config }}</td>
                      <td>{{element.snapshot.val()['number_exercises']}}</td>
                      <td><button class="btn btn-secondary" @click="deleteAssignment(element)" ><i class="bi bi-trash"></i></button></td>
                    </tr>
                  </tbody>
                  <draggable v-else v-model="table_assignment_list" @end="reOrderTable()" tag="tbody" style="cursor:move" class="text-start">
                    <tr v-for="element, index in table_assignment_list" :key="element.index" >
                      <th>{{ index +1}}</th>
                      <td>{{ element.name }}</td>
                      <td>{{ element.config }}</td>
                      <td>{{element.snapshot.val()['number_exercises']}}</td>
                      <td class="text-end"><button class="btn btn-primary me-2" @click="startClinicModule(element.snapshot, 'assignment')" >Start</button><button class="btn btn-secondary" @click="deleteAssignment(element)" ><i class="bi bi-trash"></i></button></td>
                    </tr>
                  </draggable>
                </table>

              </div>
              <div v-else class="mt-4">
                <p><i>The modules added for home assignment will appear here. Please add some to start sending to the patient.</i></p>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-history" role="tabpanel" tabindex="0">
            <br/>
            <div v-if="assignment_history" class="row mt-4 justify-content-between">
              <div class="col-4 border-end">
                <ul class="nav nav-pills flex-column">
                  <li class="nav-item border-bottom" v-for="key in Object.keys(assignment_history)">
                    <a class="nav-link button-outline-primary" style="cursor:pointer" data-bs-toggle="tab" :data-bs-target="'#nav-history-' + key">{{new Date(Number(key)).toLocaleString()}}</a>
                  </li>
                </ul>
              </div>
              <div class="col-7">
                <div class="tab-content mt-4" id="nav-tabContent">
                  <div class="tab-pane fade show" v-for="key in Object.keys(assignment_history)" :id="'nav-history-' + key" role="tabpanel" >
                    <p>Your patient has received the following assignments:<br/></p>
                    <ol>
                      <li v-for="module in assignment_history[key]" class="text-start">
                        <p v-if="module !== null && typeof module === 'object'">{{ module['name'] }} 
                          <span v-if="'config' in module && module['config'] != ''">({{ module['config'] }}) </span>
                          <span v-if="'number_exercises' in module && module['number_exercises'] != ''">x {{ module['number_exercises'] }}</span>
                        </p>
                        <p v-else>{{module}}</p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" tabindex="-1" id="send_list_modal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Send assignment list to patient</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-start">

          <p>Attach a notification message. This message will be sent to the patient by email.</p>

          <div class="input-group mt-4">
            <input class="form-control" type="text" placeholder="Type a message for your patient" v-model="send_assignment_message">
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="sendToPatient()">Send</button>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
  import { ref, watch, onMounted } from 'vue';
  import { deleteTmpAssignmentList, getTmpAssignmentList, copyToPatient, check_has_last_sent_assignment, get_last_sent_assignment } from '@/utils/assignment/common.js'
  import { getData, getDataDirectory, updateDataDirectory, setDataDirectory } from '@/firebase/index'
  import { useToast } from "vue-toastification";
  import { VueDraggableNext } from "vue-draggable-next";

  export default {
    props: {
      table_assignment_list: Object,
      list_type: String
    },
    emits: ['startModule', 'assignment_list_updated'],
    components: {draggable: VueDraggableNext },
    setup(props, { emit }) {
      const client_uid = localStorage.getItem('client_uid')
      const table_assignment_list = ref(props.table_assignment_list)
      const has_last_assignment = ref(false)
      const assignment_history = ref({})
      const sending_homework = ref(false)

      const send_assignment_message = ref('')
      
      check_has_last_sent_assignment(client_uid, props.list_type).then(v => has_last_assignment.value=v)

      const deleteAssignment = async (row) => {
        let remove_dir = row['snapshot'].ref

        await remove_dir.remove()

        let general_package_lists = await Promise.all(getTmpAssignmentList(client_uid, props.list_type))
        table_assignment_list.value = general_package_lists[0].concat(general_package_lists[1])

        const toast = useToast()
        toast.info("Assignment deleted")
      }
      
      const clearAllAssignmentList = async (userClicked=true) => {

        deleteTmpAssignmentList(client_uid, props.list_type)

        let general_package_lists = await Promise.all(getTmpAssignmentList(client_uid, props.list_type))
        table_assignment_list.value = general_package_lists[0].concat(general_package_lists[1])

        if (userClicked) {
          const toast = useToast()
          toast.info("Assignment list deleted")
        }
      }
      const sendToPatient = async () => {
        let uid = localStorage.getItem('uid')

        let path_type = props.list_type
        if (props.list_type === 'homework') {
          path_type = 'training'
        }

        let directory = '/Users/' + uid + '/Clients/' + client_uid + '/Assignment_History/' + path_type + '/'

        const currentTimestamp = new Date().getTime()

        let module_list = []
        for (let element of table_assignment_list.value) {
          let config = element.config
          let number_exercises = null
          if ('number_exercises' in element.snapshot.val()) {
            number_exercises = element.snapshot.val()['number_exercises']
          }

          module_list.push({'name': element.name, 'config': config, 'number_exercises': number_exercises})
        }

        updateDataDirectory(directory, {[currentTimestamp]: module_list})

        sending_homework.value = true
        await copyToPatient(client_uid, props.list_type, send_assignment_message.value)

        clearAllAssignmentList(false)

        const toast = useToast();

        toast.success("Assignment list was sent to the patient successfully.");

        sending_homework.value = false

        send_assignment_message.value = ''
        
        check_has_last_sent_assignment(client_uid, props.list_type).then(v => has_last_assignment.value=v)
      }
      const reOrderTable = async () => {
        
        let uid = localStorage.getItem('uid')
        let target_dir = 'Users/' + uid + '/Clients/' + client_uid + '/Draft_Assignment_List' + '/homework/general/' ;

        let do_once = true;
        
        await getData(target_dir, function (err, snaps) {
  
          if(do_once)
          {
            do_once = false;

            let index = 0;
            for(let row of table_assignment_list.value){
                
              snaps.forEach(function(childSnapshot)
              {

                let original_key = childSnapshot.key;
                let clean_original_key = original_key;

                let tmp = original_key.split("__");

                if (tmp.length > 1) {
                  clean_original_key = tmp[tmp.length-1];
                } else {
                  
                }
                
                //alert("clean key: " + clean_original_key)
                //alert("row key: " + row['key'])
                if (row['key'].includes(clean_original_key)) {
                  //alert(row['key'] + "         " + clean_original_key)
                  //found
                  //add index number in front
                  let newKey = index.toString() + "__" + clean_original_key; //hex ordering

                  let data = childSnapshot.val()

                  setDataDirectory(target_dir + original_key, {})
                  updateDataDirectory(target_dir + newKey, data)

                  row['index'] = index
                  row['key'] = newKey
                  index = index + 1;
                }
              });
            }
            
          }
        });
      }
      const getAssignmentHistory = async () => {
        let uid = localStorage.getItem('uid')
        
        let path_type = props.list_type
        if (props.list_type === 'homework') {
          path_type = 'training'
        }

        let history_path = 'Users/' + uid + '/Clients/' + client_uid + '/Assignment_History/' + path_type + '/'
        let databaseobj = await getDataDirectory(history_path);

        if (databaseobj.val()) {
          assignment_history.value = Object.fromEntries(Object.entries(databaseobj.val()).sort((a, b) => b[0].localeCompare(a[0])))
        }

      }
      
      const load_last_sent_assignment = () => {

        get_last_sent_assignment(client_uid, props.list_type).then(v => {
          emit('assignment_list_updated');
          const toast = useToast()
          toast.info("Last sent assignment loaded.")
        })
      }

      const startClinicModule = (snapshot, module_type) => {
        emit('startModule', snapshot, module_type);
      }
      onMounted(()=> {
        getAssignmentHistory()
      })

      watch(()=>[props.table_assignment_list, props.assignment_history], ([new_table_assignment_list, new_assignment_history], [old_table_assignment_list, old_assignment_history]) => {
        table_assignment_list.value = new_table_assignment_list
        assignment_history.value = new_assignment_history
        if (props.list_type === 'training') {
          reOrderTable()
        }

      })
      
      return {
        sending_homework,
        table_assignment_list,
        assignment_history,
        send_assignment_message,

        deleteAssignment,
        clearAllAssignmentList,
        getAssignmentHistory,
        sendToPatient,
        reOrderTable,
        load_last_sent_assignment,
        has_last_assignment,
        startClinicModule,

      };
    }
  };
</script>