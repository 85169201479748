<template>
  <div>


  <div id="add_patient_modal" class="modal modal-lg"  data-bs-backdrop="static" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add patient</h5>
      </div>
      <div class="modal-body">
        <div v-show="register">
          <p class="lead">Link a patient to your account to review their progress, generate case notes, assign home exercises, and listen to their performance audio.</p>
          <div class="row mb-4">
            <FormKit type="form" :actions="false" classs="mt-5" id="formPatient" @submit="add_patient()">
              <div class="row g-3">
                <div class="col">
                  <label class="form-label" for="firstname">Name <span style="color:red">*</span></label>
                  <FormKit input-class="form-control" id="firstname" name="name" v-model="patient_firstname" 
                  :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'span' },
                  options: { $el: 'div' },
                  option: { $el: 'div' },
                  }"
                  messages-class="text-danger"
                  validation="required" />
                </div>
                <div class="col">
                  <label class="form-label" for="surname">Surname <span style="color:red">*</span></label>
                  <FormKit input-class="form-control" id="surname" name="surname" v-model="patient_surname" 
                  :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'span' },
                  options: { $el: 'div' },
                  option: { $el: 'div' },
                  }"
                  messages-class="text-danger"
                  validation="required" />
                </div>
              </div>
              <div class="row">
                <label class="form-label" for="email">Email <span style="color:red">*</span></label>
                <FormKit input-class="form-control" id="email" type="email" name="email" v-model="patient_account_email" 
                :sections-schema="{
                messages: { $el: 'div' },
                message: { $el: 'span' },
                options: { $el: 'div' },
                option: { $el: 'div' },
                }"
                messages-class="text-danger"
                validation="required|email"/>
              </div>
            </FormKit>
          </div>
          <div class="form-check" >
            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="agreement">
            <label class="form-check-label" for="flexSwitchCheckChecked"><p>I consent to sharing the above information with Beautiful Voice for the purpose of creating an account for this patient if they do not already have one. 
              Please read Beautiful Voice's <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/terms%2FBV_TERMS.pdf?alt=media&token=602cef8d-fb54-4dc7-bd16-9ba44215689c">Terms of Use</a> and 
              <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/beautifulvoice-health.appspot.com/o/terms%2FPrivacy%20Policy.pdf?alt=media&token=a8132518-3a58-405d-8f6b-f0616af2ebff">Privacy Policy</a>.</p></label>
          </div>
        
        </div>
        <div v-if="!register">
          <div v-if="error_registration">
            <h5 style="text-align:left">There has been an error when adding the patient</h5>
            <p>Please contact Beautifulvoice to resolve the issue. <br/> Contact email: <a href="mailto:support@beautifulvoice.co.uk">support@beautifulvoice.co.uk</a></p>  
          </div>
          <div v-else>
            <h5 style="text-align:left">You have successfully linked a patient {{ newClientCredentials.firstname }} {{ newClientCredentials.surname }}</h5>

            <div v-if="new_account_created">
              <h5 style="text-align:left">Please provide these credentials to the patient to login: </h5>
              <div style="border:1px solid black;">
                <h5>Email: <i>{{ newClientCredentials.account_email }}</i> </h5>
                <h5>Password: <i>{{ newClientCredentials.password }}</i> </h5>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div v-if="register">
          <button type="button" class="btn btn-secondary me-2" data-bs-dismiss="modal" @click="closeRegistration()">Cancel</button>
          <button v-if="!loading" form="formPatient" :disabled="!agreement" class="btn btn-success" type="submit">Register</button>
          <button v-else :disabled="true" type="button" class="btn btn-success"><font-awesome-icon icon="spinner" class="fa-spin" /> Processing...</button>
        </div>
        <div v-else>
          <button class="btn btn-success" type="button" data-bs-dismiss="modal" @click="closeRegistration()">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</template>

<script>

import { useToast } from "vue-toastification";
import { updateDataDirectory, getAuthToken} from '@/firebase/index'

export default {
    name: 'add_patient',
    props: {
      show: {
        type: Boolean,
      },
    },
    data() {
        return {
          api: import.meta.env.VITE_FAST_API + '/add_patient',
          loading: false,
          register: true,
          agreement: false,
          patient_firstname: '',
          patient_surname: '',
          patient_account_email: '',
          new_account_created: false,
          error_registration: false,
          newClientCredentials: {}
      }
    },
    mounted() {
    },
    methods: 
    {
      async add_patient() {

        let _this = this;
        if (this.agreement) {

          _this.loading = true

          //check if email exists in database already
          let uid = localStorage.getItem('uid')

          let cpromise = await new Promise( (resolve) => {
            _this.$firebase.database().ref('Users').child(uid).child('Clients').get().then((result)=>{
              let already_exist_in_client_list = false

              if (result.exists()) {
                for (let key in result.val()) {
                  if (result.val()[key]['account_email'] === _this.patient_account_email) {
                    already_exist_in_client_list = true
                  }
                }
              } else {
                console.debug("No data available");
                resolve(false)
              }

              if (already_exist_in_client_list) {
                alert("Patient is already added. Please add a new patient with a different email address.")
                this.closeRegistration()
                resolve(false)
              }

              resolve(true)
            });
          })

          if (!cpromise) {
            return
          }


          let postData = {
              slt_uid: uid,
              patient_email: _this.patient_account_email,
              patient_firstname: _this.patient_firstname,
              patient_surname: _this.patient_surname,
              patient_agreement: _this.agreement,
              resend: "false" //set this to false always here
            }
          
          getAuthToken().then((idToken) => {

            let axpromise = _this.axios.post(_this.api, postData, {
              headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'application/json'
              }
            }).then(response => {

            let rt = response.data
            if (response.status === 200) {

              if (rt.status === 'success') {
                if (rt.credentials) {
                  _this.new_account_created = true
                  _this.newClientCredentials['password'] = rt.credentials
                  _this.newClientCredentials['account_email'] = postData['patient_email']
                  _this.newClientCredentials['firstname'] = postData['account_email']
                  _this.newClientCredentials['surname'] = postData['account_email']
                }

                const toast = useToast()
                toast.success("A new patient was added successfully.")
              } else {
                _this.error_registration = true
                if (rt.info) {
                  _this.send_error_email(postData['patient_email'], rt.info)
                } else {
                  _this.send_error_email(postData['patient_email'], "")
                }
                console.error(rt)
              }

            }

            Promise.all([cpromise, axpromise])

            _this.register = false
            _this.loading = true
            })

          })
        } else {
          alert("You have to agree with the terms and conditions!") //this should never run
        }
      },
      send_error_email(patient_email, rt) {
        let uid = localStorage.getItem('uid')
        let issue = {
          "BV_AUTO_ISSUE": 
          { 
            url: "/add_patient api", 
            uid: uid, 
            message: "AUTOGENERATED ISSUE: \n\nFailed to add patient: " + patient_email + " \n\n" + rt
          }
        }

        let pointer = 'Issues/' + new Date().toDateString()
        updateDataDirectory(pointer, issue)
      },
      closeRegistration() {
        this.register = true
        this.new_account_created = false
        this.error_registration = false
        this.agreement = false
        this.patient_firstname = ''
        this.patient_surname = ''
        this.patient_account_email = ''

        this.agreement = false
        this.$formkit.reset("formPatient")

        this.patient_has_account = false
        this.loading = false
        this.$emit('createNewClient');
      },
    }
}
</script>

<style scoped>
</style>