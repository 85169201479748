<template>
  <div class="d-flex flex-column justify-content-center vh-100">
    <div v-if="index == 0">
      <div class="row">
        <div v-if="!recording_started">
          <h3 class="mb-4">Let's test your microphone.</h3>
          <br/>
        </div>
        <div v-else>
          <h4>Say the following word:</h4>
          <br/>
          <p class="display-2"><strong>School</strong></p>
        </div>
      </div>
      <div class="row justify-content-center" style="height:200px">
        <div class="col-4">
          <div v-if="!recording_started">
            <button class="btn btn-primary btn-lg" @click="start()"><i class="bi bi-record-circle"></i> Start Recording </button>
          </div>
          <div v-else>
            <div class="wave-container">
              <canvas ref="waveCanvas" class="wave-canvas"></canvas>
            </div>
            <br/>
            
            <button v-show="show_stop_recording_button" class="btn btn-success btn-lg" @click="stop()"><i class="bi bi-stop-circle"></i> Stop Recording</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="index == 1">
      <div class="row justify-content-center">
        <div class="col-6">
          <h4>Are you able to hear the recording?</h4>
        </div>
      </div>
      <br/>
      <div class="row mt-4 justify-content-center">
        <div class="col-4">
          <audio :src="audioUrl" controls autoplay v-if="audioUrl"></audio>
        </div>
      </div>
      <br/>
      <div class="row mt-4 justify-content-center">
        <div class="col-2 text-end">
        <button class="btn btn-primary btn rounded-pill w-75" @click="confirm">Yes</button>
        </div>
        <div class="col-2 text-start">
          <button class="btn btn-secondary btn rounded-pill w-75" @click="restart">No</button>          
        </div>
      </div>

      
    </div>
  </div>
</template>
    
<script>
  import { ref, onMounted, onUpdated } from 'vue';
  import firebase from 'firebase/app'


  export default {
      name: 'test',
      components: {  },
      emits: ['error', 'confirm'],
    
      setup(props, { emit }) {
        const recording_started = ref(false)
        const show_stop_recording_button = ref(false)
        const index = ref(0)
        const waveCanvas = ref(null)
        const timer = ref(0)
        let record_interval;
        let audioContext;
        let analyser;
        let dataArray;
        let canvasContext;
        let mediaStream;

        const audioUrl = ref('');
        let mediaRecorder;
        let audioChunks = [];
        
        const drawWaveform = () => {
          if (!waveCanvas.value) return;


          canvasContext.clearRect(0, 0, waveCanvas.value.width, waveCanvas.value.height);
    
          analyser.getByteTimeDomainData(dataArray);
  

          if (timer.value > 2.5 ) {
            show_stop_recording_button.value = true
          }

          canvasContext.lineWidth = 2;
    
          const gradient = canvasContext.createLinearGradient(0, 0, waveCanvas.value.width, 0);
          gradient.addColorStop("0", "white");
          gradient.addColorStop("0.5", "rebeccapurple");
          gradient.addColorStop("1", "white");
          canvasContext.strokeStyle = gradient;
    
          canvasContext.beginPath();
    
          const sliceWidth = waveCanvas.value.width * 1.0 / analyser.fftSize;
          let x = 0;
          
          for (let i = 0; i < analyser.fftSize; i++) {
            const v = dataArray[i] / 128.0;
            const y = v * waveCanvas.value.height / 2;
            
            if (i === 0) {
              canvasContext.moveTo(x, y);
            } else {
              canvasContext.lineTo(x, y);
            }
    
            x += sliceWidth;
          }
    
          canvasContext.lineTo(waveCanvas.value.width, waveCanvas.value.height / 2);
          canvasContext.stroke();
    
          requestAnimationFrame(drawWaveform);
        };
        
        const setupAudioProcessing = (stream) => {
          audioContext = new (window.AudioContext || window.webkitAudioContext)();
          analyser = audioContext.createAnalyser();
          const source = audioContext.createMediaStreamSource(stream);
          source.connect(analyser);
          analyser.fftSize = 2048;
          dataArray = new Uint8Array(analyser.fftSize);
          
          if (waveCanvas.value) {
            waveCanvas.value.width = waveCanvas.value.clientWidth;
            waveCanvas.value.height = waveCanvas.value.clientHeight;
            canvasContext = waveCanvas.value.getContext('2d');
          }
    
          drawWaveform();
        };
        
    
        const getStream = () => {
          navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            
            recording_started.value = true
            
            mediaStream = stream
            
            mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.ondataavailable = event => {
              audioChunks.push(event.data);
            };

            mediaRecorder.onstop = () => {
              const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
              audioUrl.value = URL.createObjectURL(audioBlob);

              const storage = firebase.storage();
              const storageRef = storage.ref('audios/' + localStorage.getItem('uid') + '/mic_test/' + new Date().toISOString() + '.wav');

              storageRef.put(audioBlob).then((snapshot) => {
                console.log('Audio Blob uploaded successfully:', snapshot);
                
                //const downloadURL = snapshot.ref.getDownloadURL();
                //console.log('Download URL:', downloadURL);
              }).catch((error) => {
                console.error('Error uploading audio Blob:', error);
              });


              audioChunks = [];
            };

            mediaRecorder.start();
            
            setupAudioProcessing(mediaStream);
          })
          .catch((err) => {

            console.debug(err)

            emit('error', true)
          });
        }
    

        const start = () => {
          getStream()

          record_interval = setInterval(() => {
            timer.value++;
          }, 1000);

        }
        const stop = () => {

          clearInterval(record_interval);
          timer.value = 0

          index.value = 1

          if (mediaStream) {
            mediaRecorder.stop();
            const tracks = mediaStream.getTracks();
            tracks.forEach(track => track.stop());
            mediaStream = null;
          }

          recording_started.value = false
          show_stop_recording_button.value = false
        }
        
        const restart = () => {
          index.value = 0
        }
    
        const confirm = () => {
          emit("confirm")
        }
        onMounted(() => {
          if (mediaStream) {
            setupAudioProcessing(mediaStream);
          }
        });
    
        onUpdated(() => {
          if (mediaStream) {
            setupAudioProcessing(mediaStream);
          }
        })
    
        return {
          recording_started,
          index,
          waveCanvas,
          start,
          stop,
          show_stop_recording_button,
          audioUrl,
          restart, 
          confirm,
        };
      },
    };
    </script>
    
    <style>
    .wave-container {
      position: relative;
      width: 100%;
      height: 120px;
      overflow: hidden;
    }
    
    .wave-canvas {
      width: 100%;
      height: 100%;
      background-color: #f3f3f3;
    }
    </style>