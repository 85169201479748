

import { updateDataDirectory} from "../../../firebase"

export function storeUpdatedCookieTheftInfo(address, cookie_theft_data)
{
  address = address + '/cookie_theft/all_words/'
  console.debug('address = ' + address)
  
  updateDataDirectory(address, cookie_theft_data )

  console.debug('Success')
}

