<template>
  <div>
    <div class="row">
      <div class="col-10">

        <div class="card card-body h-100">
          <div class="row read-text justify-content-md-center" v-for="(line,lineNumber) of Ex_Description_Split.toString().split('\n')" 
            v-bind:key="lineNumber">
            {{ line }}<br/>
          </div>   
          <!-- <div class="row mt-2">
            <div class="d-grid gap-2 d-md-flex justify-content-md-center">
              <button class="btn btn-primary btn-lg w-25" @click="bv_tts.say_sentences(question_words)" type="button"> {{btn_text}} </button>
              <button class="btn btn-info btn-lg w-25" @click="nextExercise()" type="button">Next</button>
            </div>   
          </div> -->

          <div v-show="delayShow">
            <div class="row mt-4">
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'background':frameColour[0], 'height': '250px'}">
                  <img id= "img_1" v-bind:src="all_img[0]"  @click="isNotClickable!=0 ? null : selectImg(0)" class="img-fluid w-100 h-100" style="object-fit: cover;">        
                </div>
              </div>
              
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'background':frameColour[1], 'height': '250px'}">
                  <img id= "img_2" v-bind:src="all_img[1]"  @click="isNotClickable!=0 ? null : selectImg(1)" class="img-fluid w-100 h-100" style="object-fit: cover;">
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'background':frameColour[2], 'height': '250px'}">
                  <img id= "img_3" v-bind:src="all_img[2]"  @click="isNotClickable!=0 ? null : selectImg(2)" class="img-fluid w-100 h-100" style="object-fit: cover;">
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'background':frameColour[3], 'height': '250px'}">
                  <img id= "img_4"  v-bind:src="all_img[3]" @click="isNotClickable!=0 ? null : selectImg(3)" class="img-fluid w-100 h-100" style="object-fit: cover;">
                </div>
              </div>
            </div>
            <div class="row mt-2" v-if="n_images>4">
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'height': '250px', 'background':frameColour[4]}" >
                  <img id= "img_5"  v-bind:src="all_img[4]" @click="isNotClickable!=0 ? null : selectImg(4)" class="img-fluid w-100 h-100" style="object-fit: cover;">        
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'height': '250px', 'background':frameColour[5]}" >
                  <img id= "img_6" v-bind:src="all_img[5]"  @click="isNotClickable!=0 ? null : selectImg(5)" class="img-fluid w-100 h-100" style="object-fit: cover;">
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'height': '250px','background':frameColour[6]}" >
                  <img id= "img_7" v-bind:src="all_img[6]"  @click="isNotClickable!=0 ? null : selectImg(6)" class="img-fluid w-100 h-100" style="object-fit: cover;">
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-body" v-bind:style="{'height': '250px','background':frameColour[7]}" >
                  <img id= "img_8" v-bind:src="all_img[7]"  @click="isNotClickable!=0 ? null : selectImg(7)" class="img-fluid w-100 h-100" style="object-fit: cover;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  
      <div class="modal fade" id="result_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{finish_statement}}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row m-2">
                  <div class="row">
                    <h4 >{{finish_detail}}</h4>

                  </div>
                  <div class="row mt-2">
                    <button class="btn btn-success w-100"    @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



      <div class="col-2" style="padding-left: 0px">
        <div class="card card-body h-100">
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
          <div>

            <!-- <button v-if="isNotClickable==2 && !all_finished" disabled class="btn btn-primary btn-lg w-100 mt-2"  type="button"> Reading words </button> -->
            <button v-show="isNotClickable==2 && !all_finished" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Reading...
            </button>
            <button v-if="isNotClickable!=2 && !all_finished" class="btn btn-primary btn-lg w-100 mt-2" @click="say_sentences(question_words)" type="button"> {{btn_text}} 
              <i v-bind:class="[btn_text==='Start' ? 'bi bi-play-circle' : 'bi bi-arrow-clockwise']" style="margin-left: 8mm;"></i>
            </button>
            <button v-if="all_finished" class="btn btn-info btn-lg w-100 mt-2" @click="nextExercise()" type="button">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
            <button v-if="!all_finished" class="btn btn-secondary btn-lg w-100 mt-2" @click="nextExercise()" type="button">Skip <i class="bi bi-chevron-bar-right" style="margin-left: 8mm;"></i></button>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import { Modal } from 'bootstrap'


import {getData} from '@/firebase/index'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'

import {logActivity} from '@/media_input/utils'
import { useTTS } from '@/stores/tts'

export default {
  name: "Word_memory",
  /*

  {
   "exercise_type":"memory_image",
   "instruct":"memory_image",
   "n_images":8,
   "n_words":5,
   "name":4,
   "plan_name":"Memory (image)",
   "assessment_type":"SLP_TRAINING",
   "curr_exercise_path":"Memory (image)/4",
   "ex_path":"Memory (image)/4",
   "today":"2023-07-11 17:24",
   "audioIns1":null,
   "imageAddress":null,
   "videoAddress":null,
   "audioAddress":null,
   "audioIns2":null,
   "videoIns":null,
   "exercise_type":"memory_image",
   "url":null,
   "url2image":null,
   "audioIns":null,
   "Ex_Description":"memory_image",
   "module_name":"Memory (image)",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
  props: {
    exForm: {
      type: Object,
    }
  },
  data() {
    return {
      previous_selections:[],

      listen_count:0,
      destroying: false,
      n_words: 2,
      n_images: 4,
      results:{correct:0,incorrect:0,n_words:0,n_images:0, total:0},
      finish_statement:'',
      finish_detail:'',
      frameColour: ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
      frameColour_mem:  ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
      Ex_Description_Split: '',
      question_words:[],
      all_finished: false,
      qs_image_idx:[],
      all_img:[],
      btn_text:'Start',
      delayShow:false,
      img_idx: ['img_1','img_2','img_3','img_4','img_5','img_6','img_7','img_8'],
      count_ex:0,
      chosen_images:[],
      audioTimeout: null,
      curr_ex_done:false,
      curr_exercise_number:1,
      number_exercises: 1,
      isNotClickable:1,
      delayShow:false,
      show_results:false,
      bv_tts: useTTS().tts_engine,
    }
  },
  created() 
  {
    let _this = this;
    _this.count_ex = 0;
    
    logActivity(_this.$firebase, 'accessed', 'word_memory')

    setTimeout(()=>{
      this.delayShow=true;
    },500)

    _this.setup()
  },
  beforeUnmount()
  {
    this.bv_tts.cancel()

    this.destroying = true

    clearTimeout(this.audioTimeout)
  },
  mounted()
  {
    const _this = this
  },
  methods: 
  {
    say_sentences(sentences)
    {
      let _this = this
      _this.isNotClickable = 2
      _this.btn_text = 'Repeat'
      _this.bv_tts.say_sentences(sentences, 500, ()=> {_this.isNotClickable = 0;})
    },
    setup()
    {

      const _this = this

      let n_images = _this.exForm.n_images
      let n_words = _this.exForm.n_words

      _this.show_results = (_this.exForm.current_exercise_index-_this.exForm.current_exercise_index)>=3

      _this.curr_exercise_number = _this.exForm.current_exercise_index+1

      _this.total_exercises = _this.exForm.total_all_type_exercises


      if(n_words==undefined || n_words==null || n_words<1)
      {
        n_words = 2
      }

      if(n_images==undefined || n_images==null || n_images<2)
      {
        n_images = 4
      }

      _this.n_words = n_words
      _this.n_images = n_images

      _this.Ex_Description_Split = 'Press Start. You will hear '+ n_words + ' words.\n Click on the pictures that match the words, in any order.';

      setTimeout(() => {
        _this.bv_tts.say_sentences([this.Ex_Description_Split])
      }, 500)


      _this.loadImages();
    },
    showimage(count,img_id) 
    {
        const _this = this
        document.getElementById(img_id).src = _this.all_img[count];
        // var storageRef = _this.$firebase.storage().ref();
        // storageRef.child(img_address).getDownloadURL().then(function(url) {
        //     var test = url;
        //     document.getElementById(img_id).src = test;

        // }).catch(function(error) {

        // });
    },
    loadImages()
    {
        let _this = this;
        _this.delayShow = false;
        var image_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('ImageDescription').child('All');
        var do_once = true;
        getData(image_folder_ref , function (err, result) {

          if(do_once)
          {
            do_once = false;

            var image_folder_handle = result;
            var folder_n_sets = image_folder_handle.numChildren();
            _this.chosen_images = [];

            var k=0;
            while(k<_this.n_images)
            {
                let curr_set_num = Math.floor(Math.random() * folder_n_sets ) +1   

                if(!_this.chosen_images.includes(curr_set_num))
                {
                    _this.chosen_images[k] = curr_set_num;
                    let img_address = result.child(curr_set_num).child('Address').val();

                    var storageRef = _this.$firebase.storage().ref();
                    const x = k
                    storageRef.child(img_address).getDownloadURL().then(function(url) {
                      _this.all_img[x] = url;
                      document.getElementById(_this.img_idx[x]).src = url;
                    }).catch(function(error) {

                    });
                    k=k+1;


                }
            }
           
            let qs_images = [];
            _this.question_words = [];
            _this.qs_image_idx = [];
            while(qs_images.length<_this.n_words)
            {
                let random_image = Math.floor(Math.random() * _this.chosen_images.length); 
                if(!qs_images.includes(random_image))
                {
                    qs_images.push(random_image);
                    _this.qs_image_idx.push(_this.chosen_images[random_image]);
                    let qs = result.child(_this.chosen_images[random_image]).child('name').val();
                    _this.question_words.push(qs.split(';')[0]);
                }
            }
          }
        });
    },    
    nextExercise()
    {
      const _this = this;
      let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'],Total:_this.results['total'],ListenCount:_this.listen_count}

      let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}
      let results = {all_results:all_results, plot_results:plot_results}
       
      var memoryImageData = {resultsTablePre: {exercise_type: 'Memory Games', exercise: 'Memory Image', results: results, audio_address:''}}


      _this.saveResults(memoryImageData)
      _this.$emit('logExerciseResults', memoryImageData);
      
      _this.$emit('nextExercise');



    },
    goToResults()
    {
      const _this = this
      let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'],Total:_this.results['total'],ListenCount:_this.listen_count}

      let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}
      let results = {all_results:all_results, plot_results:plot_results}
      
      var memoryImageData = {resultsTablePre: {exercise_type: 'Memory Games', exercise: 'Memory Image', results: results, audio_address:''}}

      _this.saveResults(memoryImageData)

      _this.$emit('logExerciseResults', memoryImageData);

      var goToResultsData = {curr_ex_done:_this.curr_ex_done}
      
      _this.$emit('goToResults', goToResultsData);


    },
    saveResults(memoryImageData)
    {
      const _this = this

      save_pre_computed_results(_this.exForm,_this.$firebase,memoryImageData)
    },
    selectImg(img_num)
    {
        const _this = this
        this.frameColour =['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'];
        this.frameColour = this.frameColour_mem;

        _this.results['n_words'] = _this.n_words    

        _this.results['n_images'] = _this.n_images    

        if(this.qs_image_idx.includes(this.chosen_images[img_num]) && !this.previous_selections.includes(img_num))
        {
          this.previous_selections.push(img_num)
            _this.results['correct'] = _this.results['correct']  + 1           
            this.frameColour[img_num] = '#00FF00';

            let images_left = this.qs_image_idx.length-_this.results['correct']



            let correct_text = 'Correct.'
            
            if(images_left==0)
            {
              this.all_finished = true
              correct_text = 'You got them all. Press Next to move to the next exercise.'
              this.finish_statement = 'Congrats! You got all ' + this.qs_image_idx.length + ' words.';
              this.finish_detail = 'Click Next to go to the next exercise.'
              let result_pop = new Modal(document.getElementById('result_pop'));
              result_pop.show()

            }
            else if(images_left==1)
            {
              correct_text = 'Correct. ' + images_left + ' word left.'

            }
            else
            {

              correct_text = 'Correct. ' + images_left + ' words left.'
            }
            _this.bv_tts.say_sentences([correct_text])

        }
        else if(!this.qs_image_idx.includes(this.chosen_images[img_num]))
        {
            _this.results['incorrect'] = _this.results['incorrect']  + 1           

            this.frameColour[img_num] = '#FF0000';

            let incorrect_text = 'Not correct'
            _this.bv_tts.say_sentences([incorrect_text])

        }
        _this.curr_ex_done = true
        _this.results['total'] = _this.n_words
        this.frameColour_mem = this.frameColour;

    },

  },
}
</script>
<style scoped>
.std-pre{
  word-break: break-all

}
.page_title {
  width: 100%;
  height: 13mm;
  background-color: #7cc543;
    /* opacity: 80%; */
}
.page_main {
  width: 100%;
  height: calc(100vh - 13mm);
  background-color: #195e52;
  /* opacity: 80%; */
  overflow: auto;
}
.title_txt {
  float: left;
  line-height: 13mm;
  margin-left: 2%;
  font-size: 30px;
}
.plansTitle {
  float: left;
  font-size: 35px;
  margin-left: 10px;
}
.planDisplay {
  margin: 0px 130px;
}
.planName {
  float: left;
  font-size: 28px;
  margin-left: 10px;
  cursor: pointer;
}

.text_item {
/* margin-bottom: 18px; */
font-size: 30px;
/* vertical-align: middle; */
/* padding: 40px 200px; */
/* height:40%; */
}

.custom-text{
  font-size: 20px;
    /* color:#FFFFFF; */
}

.menu__item {
  display: block;
  line-height: 24px;
  text-align: center;
  margin: 10px;
  cursor: pointer;
  border: 1px solid #555555;
}
.menu__item:hover {
  color: #555555;
}

.menu {
  height: auto;
  width: 100px;
  position: absolute;
  font-size: 24px;
  text-align: center;
  background-color: #facd91;
  border: 1px solid #555555;
  padding: 0px;
}

li:hover {
  background-color: #e0e0e2;
  color: white;
}

.bottom {
    margin-top: 13px;
    line-height: 12px;
  }

.button::-moz-focus-inner{
  border: 0;
  padding: 0;
}


</style>

<!-- Result Description
{
  "Memory Image":
  {
    "<key>": {
      "audio_address": "<empty>",
      "resultsTablePre": {
        "audio_address": "<empty>", //audio address is here because that's how I used to find where the results are
        "exercise": "Memory Image",
        "exercise_type": "Memory Games",
        "results": {
          "all_results": { 
            "Correct": "<int>",
            "Incorrect": "<int>",
            "ListenCount": "<int>",
            "Total": "<int>"
          },
          "plot_results": { //These values are the ones that feed into the end results plot
            "Correct": "<int>",
            "Incorrect": "<int>"
          }
        }
      },
      "results_type": "pre_computed"
    },
    "assessment_type": "USER_TRAINING",
    "plan_name": "Memory Image"
  }
} -->