<template>
  <div> 
    <Navbar  :enableBack="true" ></Navbar>
    <div class="container">       
      <FormKit type="form"  ref="myForm" :actions="false" classs="mt-2" id="form" #default="{ isValid }" @submit="submit()">      
        <div class="card card-body">
          <div class="row">
            <p class="lead text-start mb-2">Custom Life Activity Module</p>
            <hr/>
          </div>
          <div class="row mb-2">
            <p class="col-2 form-label lead text-start" for="exercise_name">Module name <span style="color:red">*</span></p>
            <div class="col-10">
              <VTooltip>
                <FormKit input-class="form-control" id="exercise_name" validation-name="Module name" name="name" type="text" v-model="form.name" 
                :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'span' },
                  options: { $el: 'div' },
                  option: { $el: 'div' },
                }"
                messages-class="text-danger"
                validation="required"/>
                <template #popper>
                  Enter the name of your training module
                </template>
              </VTooltip>
            </div>
          </div>
          <div class="row mb-2">
            <p class="col-2 form-label lead text-start" for="instructions">Instruction to patients <span style="color:red">*</span></p>
            <div class="col-8">
              <VTooltip>
                <FormKit input-class="form-control" id="instructions" validation-name="Instruction" name="text" 
                :sections-schema="{
                  messages: { $el: 'div' },
                  message: { $el: 'span' },
                  options: { $el: 'div' },
                  option: { $el: 'div' },
                }"
                messages-class="text-danger"
                placeholder='E.g., Call a take-away and order the food for the house tonight.' v-model="form.text[0]" validation="required"/>
                <template #popper>
                  Call a take-away and order the food for the house tonight.
                </template>
              </VTooltip>
            </div>
            <div class="col-2">
              <!--<button v-if="(form.Audio_Address[0]==null)||(form.Video_Address[0]==null)" class="btn btn-primary w-100" type="button" @click="addVideo(0)"> Add demo</button>-->
              <!--<button v-if="form.Video_Address.length>(0) & form.Video_Address[0]!=null" class="btn btn-warning w-100" type="button" @click="watchVideo(0)"> Review demo</button>-->

              <button v-if="(form.Audio_Address[0]==null)||(form.Video_Address[0]==null)" class="btn btn-primary w-100" type="button" data-bs-toggle="modal" data-bs-target="#record_instructions_modal" @click="addVideo(0)"> Add video</button>
              <button v-if="form.Video_Address[0]" class="btn btn-warning w-100 mt-1" type="button" data-bs-toggle="modal" data-bs-target="#watch_instructions_modal" @click="watchVideo(0)"> Review video</button>
              <button v-if="form.Video_Address[0]" class="btn btn-danger w-100 mt-1" type="button" @click="deleteVideo(0)"> Delete video</button>
            </div>
          </div>
          <div class="row mt-4 justify-content-center text-center">
            <div class="col-6">
              <div class="input-group">
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="checkbox">Checkbox for the client to tick when done</label>
                  <input class="form-check-input" type="checkbox" v-model="form.checkbox" name="checkbox" id="checkbox" value="checkbox">
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="capture_audio">Audio recording of the exercise</label>
                  <input class="form-check-input" type="checkbox" v-model="form.capture_audio" name="capture_audio" id="capture_audio" value="capture_audio">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4 justify-content-center">
          <button class="btn btn-success w-25 mt-2" type="submit" @click="add_homework=false">Confirm</button>
          <button class="btn btn-outline-success w-25 ms-2 mt-2" type="submit" @click="add_homework=true">Add to assignment list</button>
        </div>
      </FormKit>

      <div class="modal fade" id="record_instructions_modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="video_type" class="modal-title">
                Record video
              </h5> 
              <h5 v-else class="modal-title">
                Record audio
              </h5> 
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Instructions_recording :video_type="video_type" @audioClosed="audioClosed($event)" @videoClosed="videoClosed($event)"/>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="watch_instructions_modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Watch video
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <Watch_recording ref="Watch_recording" @close="watch_instructions=false"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </template>
  <script>
  
  import Navbar from '@/views/Navbar.vue'
  import {updateData,getData} from '@/firebase/index'
  import Instructions_recording from '../components/instructions_recording.vue'
  import Watch_recording from '../components/watch_recording.vue'
  import { useToast } from "vue-toastification";

  export default {
    name: 'activity_custom_module_setup',
    components: {Instructions_recording,Watch_recording, Navbar},
    props: 
    {

    },
    data(){
      return {
        userCategory:'',
        region:'',
        record_instructions: false,
        watch_instructions:false,
        audio_address:'',
        curr_item_index: 0,
        video_type: true,
        form: {
          name: '',
          keywords: '',
          instruct: '',
          n_item : 1,

          text : [],
          n_repeats : [1],
          assist : [],
          Audio_Instructions : [null],
          Video_Instructions : [null],
          Video_Address : [null],
          Audio_Address : [null],
          voicetype : [null],
          speechtype : [null],
          capture_audio : '',
          checkbox : ''
        },
        custom_module: null,
        add_homework: false,
      }
    },
    created() {
      this.userCategory = localStorage.getItem('userCategory');
      this.region = localStorage.getItem('region');

      if (this.$route.query.edit_mode) {
        this.form = JSON.parse(this.$route.query.module_data)
        console.debug(this.form)
      }
    },
    methods: {
      async submit()
      {
        
        console.debug('submit')

        const _this = this              
        let uid = localStorage.getItem('uid');

        let n_exercises = _this.form.n_item

        let module_page_ref = _this.$firebase.database().ref('/Custom_Modules').child(uid).child('Modules').child('ActivityModules');

        let module_info = {
          homework:true,
          mod_name: _this.form.name
        }

        updateData(module_page_ref.child(_this.form.name), module_info);

        let counter = 0


        for(let n=0;n<n_exercises;++n)
        {
          for(let k=0;k<_this.form.n_repeats[n];++k)
          {
            
            let exercise_address = 'Custom_Exercises/'+uid+'/Modules/ActivityModules/'

            let exercise_page_ref = _this.$firebase.database().ref(exercise_address);
            let push_key = exercise_page_ref.push().key;

            let temp_form =  
            {
              name: _this.form.name,
              keywords:_this.form.keywords,
              instruct: '',
              text: _this.form.text[n],
              assist:_this.form.assist,
              Audio_Instructions:_this.form.Audio_Instructions[n],
              Video_Instructions:_this.form.Video_Instructions[n],
              Audio_Address:_this.form.Audio_Address[n],
              Video_Address:_this.form.Video_Address[n],
              voicetype: _this.form.voicetype,
              speechtype: _this.form.speechtype,
              capture_audio:_this.form.capture_audio,
              checkbox:_this.form.checkbox
            }

            console.debug('temp_form.Audio_Address = ' + temp_form.Audio_Address)

            console.debug('temp_form.Video_Address = ' + temp_form.Video_Address)
            console.debug('temp_form.Video_Instructions = ' + temp_form.Video_Instructions)


            updateData(exercise_page_ref.child(push_key),temp_form)

            let module_exercise_info = {
              address: exercise_address+push_key,
              name:_this.form.name,
              exercise_range:'single_exercise'
            }

            let module_index = counter 
            console.debug('module_index = ' + module_index)
            updateData(module_page_ref.child(_this.form.name).child('exercises').child(module_index), module_exercise_info);

            counter = counter + 1
          }
        }



        _this.custom_module = module_page_ref.child(_this.form.name)

        if (this.add_homework) {
          this.addToHomeworkList()

          const toast = useToast()
          toast.success("Module added to assignment list!")
        } else {
        
          const toast = useToast()
          toast.success("Module saved!")
        }
        
        let clientUID = localStorage.getItem('client_uid');

        this.$router.push({ name: 'client_interface', query: {uid: clientUID} });
      },
      async addToHomeworkList()
      {

        const _this = this

        let pro = await this.custom_module.get()
        let curr_module_data = pro.val()

        let uid = localStorage.getItem('uid');
        let clientUID = localStorage.getItem('client_uid');

        let target_dir = 'Users/' + uid + '/Clients/' + clientUID + '/Draft_Assignment_List' + '/homework/general/' ;
        let tmp_slt_homework_list = _this.$firebase.database().ref(target_dir)
        let push_key = tmp_slt_homework_list.push().key;

        curr_module_data['modifier'] =  ''

        curr_module_data['current_exercise_index'] = 0    

        /*if (_this.number_exercises != undefined) {
          curr_module_data['number_exercises'] = _this.number_exercises
        }*/

        console.debug('target_dir  = ' + target_dir)
        console.debug('push_key = ' + push_key)
        
        updateData(tmp_slt_homework_list.child(push_key), curr_module_data)

      },
      deleteVideo(index)
      {
        
        this.form.Audio_Instructions[index] = null
        this.form.Audio_Address[index] = null

        this.form.Video_Instructions[index] = null
        this.form.Video_Address[index] = null
        //this.$forceUpdate()

      },
      watchVideo(index)
      {
        const _this = this

        let curr_video_address = this.form.Video_Address[index]
        this.watch_instructions = true

        setTimeout(() => {_this.$refs.Watch_recording.watchVideoInstructions(curr_video_address)
        }, 100);

      },
      addVideo(index)
      {
        console.debug('addVideo')
        this.curr_item_index = index
        this.record_instructions = true
        this.video_type = true

        console.debug('addVideo2')

  
      },
      addAudio(index)
      {
        this.curr_item_index = index
        this.record_instructions = true
        this.video_type = false
      },
      audioClosed(audio_address)
      {
        const _this = this
  
        console.debug('audio_address = ' + audio_address)
        
        _this.record_instructions = false

        if(audio_address!=null)
        {
          let index = _this.curr_item_index
          _this.form.Audio_Instructions[index] = audio_address
          _this.form.Audio_Address[index] = audio_address
    
          _this.form.Video_Instructions[index] = null
          _this.form.Video_Address[index] = null
        }
      },
      videoClosed(video_address)
      {
        const _this = this

        console.debug('video_address = ' + video_address)
  
        _this.record_instructions = false

        if(video_address!=null)
        {
          let index = _this.curr_item_index

          console.debug('index = ' + index)
          console.debug('_this.form.Video_Instructions = ' + _this.form.Video_Instructions)
          console.debug('_this.form.Video_Instructions.length = ' + _this.form.Video_Instructions.length)

  
          _this.form.Video_Instructions[index] = video_address
          _this.form.Video_Address[index] = video_address

          _this.form.Audio_Instructions[index] = null
          _this.form.Audio_Address[index] = null

          console.debug('_this.form.Video_Instructions = ' + _this.form.Video_Instructions)


        }  
  
      },
    },
  }
  </script>
  
  <style scoped>
  </style>