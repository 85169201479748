<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar  :enableBack=true></Navbar>

    <div class="container mb-5">
      <h1 class="display-5">Auditory Comprehension</h1>

      <div class="card p-2">
        <div clas="card-body text-center">
          <div class="row">
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">


                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>1</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Differentiate similar words</p>
                  <img :src="listenMinimalPairsImg" width="220" height="140" style="object-fit: contain">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="listen_minimal_pairs()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('listen_pair')"> Exercise overview</button>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card h-100">
                <div class="card-body text-center">
                  <div class="position-relative">
                    <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span>
                  </div>

                  <div class="special-badge">
                  </div>
                  <div class="mask-t">
                    <strong>2</strong>
                  </div>
                  <p class="lead mt-4" style="font-size: x-large;"> Identifying numbers you hear</p>
                  <img :src="writeNumbersImg" width="260" height="120" style="object-fit: cover">
                  <br/>
                  <button class="btn btn-primary btn-lg mt-2" type="button" @click="write_numbers()"> Start this exercise</button>
                  <br/>
                  <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('write_numbers')"> Exercise overview</button>
                </div>
              </div>
            </div>
          </div>

          

          <div class="modal fade" id="start_pop" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">{{ module_title }}</h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                  <div class="row justify-content-around">
                    <div class="card card-body">
                      <h5>{{ benfite_statement }}</h5>
                    </div>
                    
                  </div>
                  <div class="row justify-content-center">
                    <Posture_instructions/>
                  </div>
                  <div class="row justify-content-center mt-4">
                    <div class="col-3">
                      <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                    </div>
                    <div class="col-3">
                      <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>

      <!-- <Posture_instructions/> -->
    </div>
    <Footer class="mt-auto"></Footer> 
  </div>
</template>
<script>

import Posture_instructions from '../components/posture_instructions.vue'
import listenMinimalPairsImg from '@/assets/images/listen_minimal_pairs.png'
import writeNumbersImg from '@/assets/images/instructions/write_numbers.png'



import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'
import { Modal } from 'bootstrap'

export default {
  name: 'Word_aphasia_instructions',
  components: {Posture_instructions, Footer, Navbar},
  props: 
  {
  },
  data(){
    // NewsPopup() {
    return {

        userCategory:'',
        region:'',
        writeNumbersImg:writeNumbersImg,
        listenMinimalPairsImg:listenMinimalPairsImg,
        module_title:'',
        benfite_statement:'',
        module_cate:''
    }
  },
  created() {

      this.userCategory = localStorage.getItem('userCategory');
      this.region = localStorage.getItem('region');

  },
  methods: {
    write_numbers()
    {
      this.$router.push({ name: 'write_numbers' });
    },
    listen_minimal_pairs()
    {
      this.$router.push({ name: 'listen_minimal_pairs' });
    },
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'listen_pair')
      {
        this.module_title = 'Differentiate similar words'
        this.benfite_statement = 'This exercise helps you practise listening and differentiating similar words.'
      }
      else if(cate == 'write_numbers')
      {
        this.module_title = 'Write numbers'
        this.benfite_statement = 'This exercise helps you practise listening and differentiating numbers.'


      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    gotoModule()
    {
      if(this.module_cate == 'pic_name')
      {
        this.wordNaming()
      }
      else if(this.module_cate == 'word_category')
      {
        this.wordCategory()
      }
      else if(this.module_cate == 'common_sayings')
      {
        this.common_sayings()
      }
      else if(this.module_cate == 'find_item')
      {
        this.sceneClick()
      }
      else if(this.module_cate == 'answer_question')
      {
        this.aphasia_scene()
      }
      else if(this.module_cate == 'word_finding')
      {
        this.wordFinding()
      }
      else if(this.module_cate == 'opposite')
      {
        this.oppositeMeaning()
      }
      else if(this.module_cate == 'match_shape')
      {
        this.matching_shapes()
      }
      else if(this.module_cate == 'listen_pair')
      {
        this.listen_minimal_pairs()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  },
}
</script>
<style scoped>

.custom-card_blue{
    background-color: #cfe2f3;
    border-color: #cfe2f3;
    font-size: 20px;
}
.custom-card_pink{
    background-color: #ead1dc;
    border-color: #ead1dc;
    font-size: 20px;
}

.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}


</style>