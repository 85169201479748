<template>
  <div class="row mt-2">
    <div class="card card-body" >
      <div v-if="'VHI' in q_data">

        <div class="row">
          <div class="col">
            <div v-if="'vhi_categories' in q_data['VHI']">
              <div class="row border-bottom" style="text-align: left;">
                <h4>Subscale by category</h4>
              </div>
              <div class="row mt-2" style="text-align: left;">
                <div class="col">
                  <h4>Emotional</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['E']['total_score'].toFixed(0)}}</h4>
                </div>
              </div>
              <div class="row" style="text-align: left;">
                <div class="col">
                  <h4>Physical</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['P']['total_score'].toFixed(0)}} </h4>
                </div>
              </div>
              <div class="row border-bottom" style="text-align: left;">
                <div class="col">
                  <h4>Functional</h4>
                </div>
                <div class="col">
                  <h4>{{q_data['VHI']['vhi_categories']['F']['total_score'].toFixed(0)}}</h4>
                </div>
              </div>
            </div>


            <div v-if="'total_score' in q_data['VHI'] && q_data['VHI']['total_questions']==30" class="row mt-2" style="text-align: left;">
              <div class="col">
                <h4>Total</h4>
              </div>
              <div class="col">
                <h4>{{q_data['VHI']['total_score'].toFixed(0)}}</h4>
              </div>
            </div>

            <div v-if="'total_score' in q_data['VHI'] && q_data['VHI']['total_questions']==10" class="row mt-2" style="text-align: left;">
              <div class="col">
                <h4>VHI-10 Total</h4>
              </div>
              <div class="col">
                <h4>{{q_data['VHI']['total_score'].toFixed(0)}}</h4>
              </div>
            </div>
            <div v-if="'total_score' in q_data['VHI'] && q_data['VHI']['total_questions']==10" class="row" style="text-align: left;">
              <!-- <p class="lead">VHI-10 score interpretation: {{ q_data['severity_level'] }}</p> -->
              <p class="lead">VHI-10 score interpretation: {{ q_data['VHI']['total_score_meaning'] }}</p>
              <!-- <p class="text-start"> {{ q_data['total_score_meaning'] }}</p> -->

            </div>
              
          </div>
          
          <div v-if="q_data['VHI']['total_questions']==30" class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">VHI-30 score</th>
                  <th scope="col">Level of impact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>0-14</td>
                  <td>Healthy</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>15-28</td>
                  <td>Mild</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>29-50</td>
                  <td>Moderate</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>51-120</td>
                  <td>Severe</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="q_data['VHI']['total_questions']==10" class="col">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">VHI-10 score</th>
                  <th scope="col">Level of impact</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>0-3</td>
                  <td>Healthy</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>3-11</td>
                  <td>Mild </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>12-40</td>
                  <td>Moderate to severe</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
          <div v-if="'z_score' in q_data['VHI']" class="row" style="text-align: left;margin-top: 10mm;">
            <div class="col-md-3">
              <h4>Z score</h4>
            </div>
            <div class="col-md-2">
              <h4>{{q_data['VHI']['z_score'].toFixed(0)}}</h4>
            </div>
          </div>
          <div v-if="'z_score' in q_data['VHI']" class="row" style="text-align: left;">
            <p class="lead">Z score interpretation: {{q_data['VHI']['z_score_meaning']}}</p>
          </div>
        </div>
        
          <div class="mt-4">
            <p class="h4 text-start">Individual questionnaire response</p>
          </div>
          <table class="table mt-4" >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Question</th>
                <th scope="col">Score</th>

                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row, index in q_data['questions']" v-bind:key="index">
                <td>{{ index +1 }}</td>
                <td class="text-start">{{ q_data['questions'][index] }}</td>
                <td v-if="'rating_scores' in q_data">{{ q_data['rating_scores'][index]}}</td>
                <td v-else>NA</td>

                <td>{{ q_data['answers'][index]}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
  </div>
</template>
  
<script>

  import {ref, onUpdated, onMounted} from 'vue'

  export default {
    name: 'questionnaire',
    components: {
    },
    props: {
      q_data: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      onMounted( () => {

      });

    
      return {
        
      };

    }
  }
</script>