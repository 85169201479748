<template>
    <div class="container">
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasVideoCall">
            <div class="offcanvas-header">
                <h5 v-if="userCategory === 'patient' ">Video call with your speech therapist</h5>
                <h5 v-else>Video call with your patient</h5>
                <div v-if="call_status!='call'">
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>                
                </div>
                <div v-else>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="offcanvas" aria-label="Close">Continue call on platform <i class="bi bi-chevron-right"></i></button>
                </div>
            </div>
            
            <div class="offcanvas-body">
                <!--<div v-if="call_status === 'call'" class="mb-4 justify-contente-center">
                    <div class="col-12 text-center">
                        <button type="button" class="btn btn-success rounded-pill" @click="$router.push({name: 'training_room'})" data-bs-dismiss="offcanvas" aria-label="Close">Go to Training Room <i class="bi bi-mortarboard"></i></button>
                    </div>
                </div>-->
                <div v-if="call_status === 'home'" class="row h-100 d-flex align-items-center">
                    <div class="col-12 text-center">
                        <p class="lead">Please remember to schedule a meeting beforehand.</p>
                        <button v-if="loading_room" class="btn btn-primary rounded-pill btn-lg w-50" disabled>
                            <div class="spinner-border spinner-border-sm" role="status" style="font-size: 10px">
                            </div>
                            Loading meeting room...
                        </button>
                        <button v-else class="btn btn-outline-primary rounded-pill btn-lg w-50" @click="joinRoom()">Start Meeting</button>
                    </div>
                </div>
                <div v-show="call_status !== 'home'" class="row d-flex align-items-center">
                    <div class="col-12 text-center">
                        <!-- The Daily Prebuilt iframe is embedded in the div below using the ref -->
                        <div id="call" ref="callRef"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { Modal } from 'bootstrap'

import DailyIframe from "@daily-co/daily-js";
import daily_api from "@/common_js/daily_api.js";

import { useCallStatusStore } from '@/stores/callStatus'
import { storeToRefs } from 'pinia'
import { mapStores } from 'pinia'

import {getUserDataDir, getAuthToken} from '@/firebase/index'
import axios from 'axios'

export default {
    props: {

    },
    components: {
    },
    data() {
        return {
            api: import.meta.env.VITE_FAST_API,
            //api: "http://0.0.0.0:8000",
            userCategory: '',
            callModal: null,
            callFrame: null,
            call_status: "home",
            participants: null,
            count: 0,
            messages: [],
            loading: false,
            screen: null,
            loading_room: false,
            userName: ''
        }
    },
    created () {

    },
    mounted () {
      let _this  = this;

      let myOffcanvas = document.getElementById('offcanvasVideoCall')
      myOffcanvas.addEventListener('show.bs.offcanvas', 
      function () {
        _this.getUserMetaData();
      })

    },
    computed: {
      ...mapStores(useCallStatusStore)
    },
    watch: {
    },
    updated() {

    },
    methods: {
        getUserMetaData() {
          let _this  = this;

          let user_page_dir = 'Data/';
          getUserDataDir(user_page_dir , function (err, result) {
            _this.userCategory = result.child('category').val()
            if(result.hasChild('userName') && result.child('userName').val() !== '')
            {
              _this.userName = result.child('userName').val();
            }
            else
            {
              _this.userName = result.child('Firstname').val() + " " + result.child('Surname').val();
            }
          });  
        },
        initVideoCall(roomURL) {
            //const callFrame

            //if (callFrame) {
            //    callFrame.destroy();
            //}

            // DailyIframe container element
            const callWrapper = this.$refs.callRef;

            const IFRAME_OPTIONS = {
                height: "auto",
                width: "100%",
                aspectRatio: 16 / 9,
                minWidth: "400px",
                maxWidth: "100%",
                border: "1px solid var(--grey)",
                borderRadius: "4px",
            };

            // Create Daily call
            let callFrame = DailyIframe.createFrame(callWrapper, {
                iframeStyle: IFRAME_OPTIONS,
                showLeaveButton: true,
                userName: this.userName,
                showUserNameChangeUI: false
            });

            // Daily event callbacks
            const logEvent = (ev) => console.debug(ev);
            const goToLobby = () => {
                this.call_status = "lobby"
                this.callStatusStore.setCallStatus('lobby')
            };
            const goToCall = (e) => {
                this.loading_room = false
                this.call_status = "call"
                this.callStatusStore.setCallStatus('call')
                this.updateParticpants(e)
                this.callStatusStore.setCallInitTimestamp()
            };
            const leaveCall = () => {
                if (callFrame) {
                    this.call_status = "home";
                    this.loading_room = false
                    this.callStatusStore.setCallStatus('home')
                    callFrame.destroy();
                    this.callStatusStore.resetCallInitTimestamp()
                }
            };





            callFrame.join({ url: roomURL, showFullscreenButton: true });
            
            // Add event listeners and join call
            callFrame
                .on("loaded", logEvent)
                .on("started-camera", logEvent)
                .on("camera-error", logEvent)
                .on("joining-meeting", goToLobby)
                .on("joined-meeting", goToCall)
                .on("left-meeting", leaveCall)            
        },
        async joinRoom() {
            this.loading_room = true
            let data = {
                "from_uid": localStorage.getItem('uid'),
                "to_uid": localStorage.getItem('call_uid')
            }

            let _this = this
            getAuthToken().then((idToken) => {
                this.axios.post(this.api + "/get_room", data,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    let roomURL = response.data

                    _this.initVideoCall(roomURL)
                })
            })
        },
    
    }
}
</script>

<style scoped>

.participants-container {
  display: flex;
  margin: 0 -20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: #121a24;
  height: inherit;
}
</style>