import firebase from 'firebase/app'
import 'firebase/database'

export function handleGlobalError(message) {
 
  const uid = localStorage.getItem('uid')
  if (!uid) {
    return
  } 
  
  let timestamp = Math.floor(Date.now() / 1000);
  const logRef = firebase.database().ref('System_Logs').child('Telemetry').child('Error_Entries').child(uid);
  logRef.update({[timestamp]:{
    error: message.toString(),
    url: window.location.href,
  }});

}
  