<template>
  <div>
    <p class="text-primary" @click="back()" style="cursor: pointer;">
      <strong>
        <i class="bi bi-chevron-left"></i> Back
      </strong>
    </p>
  </div>
</template>

<script>
import {ref} from 'vue'

export default {
  name: 'Back',
  setup(props, { emit }) {

    const back = () => {
      emit('back')
    }

    return {
      back
    }
    
  }
}
</script>

<style scoped>

</style>
