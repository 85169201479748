<template>
  <div class="row mt-2">
    <div class="card card-body" >
      <div>
        <p class="h4 text-start">Speech analysis results</p>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <th scope="col">Exercise Name</th>
            <th scope="col">Loudness (dB)</th>
            <th scope="col">Words/min</th>
            <th scope="col">syllables/s</th>
            <th scope="col">Phonation time(s)</th>
            <th scope="col">Number of syllables</th>
            <th scope="col">Number of breaks</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in speech_table" v-bind:key="index">
            <td>{{ row.Name }}</td>
            <td>{{ row.loudness<50?'<50':row.loudness }}</td>
            <td>{{ row.WordsMin }}</td>
            <td>{{ row.SpeechRate }}</td>
            <td>{{ row.PhonationTime }}</td>
            <td>{{ row.NumberSyllables }}</td>
            <td>{{ row.NumberPauses }}</td>
            <td>
              <div class="btn-group"> 
                <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
              </div>
            </td>
          </tr>
          <tr class="table-group-divider" v-if="speech_table && speech_table.length > 1">
            <th scope="col">Average value</th>
            <th scope="col">{{ (speech_table.reduce((sum, value) => sum + value.loudness, 0) / speech_table.length).toFixed(0) }}</th>
            <th scope="col">{{ (speech_table.reduce((sum, value) => sum + value.WordsMin, 0) / speech_table.length).toFixed(0) }}</th>
            <th scope="col">{{ (speech_table.reduce((sum, value) => sum + value.SpeechRate, 0) / speech_table.length).toFixed(0) }}</th>
            <th scope="col">{{ (speech_table.reduce((sum, value) => sum + value.PhonationTime, 0) / speech_table.length).toFixed(0) }}</th>
            <th scope="col">{{ (speech_table.reduce((sum, value) => sum + value.NumberSyllables, 0) / speech_table.length).toFixed(0) }}</th>
            <th scope="col">{{ (speech_table.reduce((sum, value) => sum + value.NumberPauses, 0) / speech_table.length).toFixed(0) }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
      
</template>
  
<script>
  import firebase from 'firebase/app'
  import {ref, onUpdated, onMounted} from 'vue'
  import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
    name: 'speech_table',
    components: {
      Audio_Playback
    },
    props: {
      speech_table: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      onMounted( () => {

      });

      return {
      };

    }
  }
</script>