<template>
  
  <div class="d-flex flex-column min-vh-100">
  <Navbar currentIndex="settings"></Navbar>
  
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="display-5 ms-3">Microphone calibration</h1>
        <div class="card m-2">
            <div class="card-body">
                <div >
                <h5 class="mb-2">A. Requirements</h5>
                <p class="mt-3">To calibrate your computer/tablet microphone you will require an external <b>reference microphone</b>.</p>
                <p class="mt-3">We recommend using a Type II Sound Level Meter as a <b>reference microphone</b>, such as <a target="_blank" rel="noopener noreferrer" href=" https://www.amazon.co.uk/Center32-Sound-Level-Meter-Bargraph/dp/B091Z8WGD8/ref=sr_1_4?crid=3SB7667WH7J3W&keywords=type+ii+sound+level+meter&qid=1701168939&s=diy&sprefix=type+ii+sound+level+m%2Cdiy%2C90&sr=1-4">this</a>.</p>
                
            </div>
            </div>
            </div>
            <div class="card m-2">

            <div class="card-body">

            <div >
                <h5 class="mb-2">B. Instructions</h5>
                <p class="mt-3">Step 1 - Please read all the instructions before starting the calibration process. If you require any assistance, contact the Beautiful Voice team on contact@beautifulvoice.co.uk.</p>

                <p class="mt-3">Step 2 - Place your <b>reference microphone</b> next to your computer/tablet.</p>
                <p class="mt-3">Step 3 - Turn on your <b>reference microphone</b>. Click on the <b>Start Calibration</b> button below (<b>Section C</b>).</p>
                <p class="mt-3">Step 4 - Say Ah with a loud and clear voice for about 3 seconds. Pay attention to the loudest reading in your <b> reference microphone</b>.</p>
                <p class="mt-3">Step 5 - Turn off <b>reference microphone</b>. Click on the <b>Stop Calibration</b> button below (<b>Section C</b>).</p>
                <p class="mt-3">Step 6 - Input the loudest reading from your <b>reference microphone</b> below (<b>Section D</b>).</p>
                <p class="mt-3">Step 7 - Click on <b>Update setting</b> below (<b>Section D</b>).</p>
                <p class="mt-3">Step 8 - You can repeat the process until you are happy with your readings.</p>
                <p class="mt-3">Step 9 - If you want to go back to the original status, with no calibration done, click below on <b>Reset calibration</b>.</p>





                <div class="input-group">

                <button class="btn btn-outline-primary" @click="resetCalibration()">Reset calibration</button>

                </div>
                <div class="input-group">
                    <!-- <input class="form-control" v-model="form.userName" autocomplete="off"> -->
                    <!-- <button class="btn btn-outline-primary" @click="updateUserInfo('userName')">Update</button> -->
                </div>
                </div>
            </div>
        </div>      
        <div class="card m-2">
            <div class="card-body">
                <div class="col-md-6">
                <h5 class="mb-2">C. Capture audio with Beautiful Voice</h5>
                <div id="SPL-div" class="row align-items-center">
                    <div class="col-md-3">
                    
                    </div>
                    <p class="mt-3">Max loudness {{ max_loudness }}</p>
                    <!-- <p class="mt-3">Average loudness {{avg_loudness_show }}</p> -->

                    <div class="col-md-6">
                    <canvas ref="loud_canvas" id="loud_canvas" width="400" height="180"></canvas>
                    <p style="fontSize:12px">Your loudness graph</p>
                    </div>
                </div>

                <button v-bind:class="[play_pause_text===start_btn_text ? 'btn-primary' : 'btn-info', 'btn btn-lg w-100 mt-2']" @click="playPause()">{{play_pause_text}}</button>

                </div>
            </div>
        </div>    
        <div class="card m-2">
            <div class="card-body">
                <div class="col-md-6">
                    <h5 class="mb-2">D. Input readings</h5>

                    <p class="mt-3">Reading from Beautiful Voice</p>

                    <div class="input-group">

                        <input class="form-control" type="number"  v-model="bv_reading_loudness" @change="updatedReading()" autocomplete="off">
                    </div>
                    <p class="mt-3">Reading from your <b>reference microphone</b></p>
                    <div class="input-group">

                        <input class="form-control" type="number"  v-model="ground_truth_reading_loudness" @change="updatedReading()" autocomplete="off">
                    </div>
                    <p class="mt-3">Calibration </p>

                    <div class="input-group">

                            <input class="form-control" type="number"  v-model="calibration" disabled autocomplete="off">
                            <button class="btn btn-outline-primary" @click="updateCalibration()">Update setting</button>

                    </div>

        

                    <div class="modal fade" id="calibration_pop" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h4 class="modal-title">Good job</h4>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                            <div class="m-3">
                                <div class="row">
                                <h4 >You have updated your microphone settings!</h4>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>      

        <div class="row p-2">
        </div>
      </div>
    </div>
    
  </div>

  <Footer class="mt-auto"></Footer>

</div>

</template>


<style scoped>

.custom-text
{
  font-size:20px;
  color: white;
}

</style>

<script>
import Navbar from '../Navbar.vue'
import Footer from '../Footer.vue'
import {updateDataDirectory} from '@/firebase/index'
import smoothie from 'smoothie'
import { Modal } from 'bootstrap'

import {getStorageMicCalibration, checkPermissionsMedia, deleteSoundMeter, recordAudioMedia, stopRecordingMedia } from '@/media_input/microphone_recording'
import { roundNumber } from "@/utils/utils"

export default {
    components: { Navbar, Footer },
    name: 'Settings',
    data(){
        return {
            userCategory:'',
            firstname:'',
            bv_reading_loudness:0,
            ground_truth_reading_loudness:0,
            calibration:0,
            chart_data: null,
            smoothie: null,
            maxdBValue:80,
            mindBValue:40,
            is_recording:false,
            play_status:false,
            avg_loudness_show:0,
            avg_loudness:0,
            counter_loudness:0,
            max_loudness:0,
            user_mic_calibration:0,
            start_btn_text: 'Start Calibration',
            play_pause_text:'Start Calibration',



        }
    },
    created() {
        const _this = this

        checkPermissionsMedia(); 

        this.user_mic_calibration = getStorageMicCalibration()

   
    },
    mounted()
    {
        this.createChart()

    },
    methods: {

        resetCalibration()
        {

            let uid = localStorage.getItem('uid');
            let ref_path = 'Users/' + uid + '/Data/'

            updateDataDirectory(ref_path, {'user_mic_calibration':0})
            localStorage.setItem('user_mic_calibration', Number(0));

            this.finishCalibration()

           


        },
        finishCalibration()
        {
            let calibration_pop = new Modal(document.getElementById('calibration_pop'));
            calibration_pop.show() 

            setTimeout(() => {
                calibration_pop.hide()
                this.$router.push({name: "settings"})


             }, 1200);
        },
        updatedReading()
        {
            this.calibration = Number(this.bv_reading_loudness)-Number(this.ground_truth_reading_loudness)

        },
        updateCalibration()
        {
            this.updatedReading()

            let uid = localStorage.getItem('uid');
            let ref_path = 'Users/' + uid + '/Data/'

            this.user_mic_calibration = -this.calibration+this.user_mic_calibration
            updateDataDirectory(ref_path, {'user_mic_calibration':this.user_mic_calibration })
            localStorage.setItem('user_mic_calibration', Number( -this.calibration));

            this.finishCalibration()
        },
        createChart()
        {
            const _this = this
            
            console.debug('creating chart')
            _this.smoothie = new smoothie.SmoothieChart({millisPerPixel:11,grid:{fillStyle:'#dfd7d7',verticalSections:2},labels:{fillStyle:'#000000',fontSize:15},horizontalLines:[{color:'#ffffff',lineWidth:1,value:0}],maxValue:_this.maxdBValue,minValue:_this.mindBValue})
            var canvas = document.getElementById("loud_canvas")
            _this.chart_data = new smoothie.TimeSeries();
            _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#195e52'});
            // _this.smoothie.addTimeSeries(this.chart_data, {lineWidth:3.3,strokeStyle:'#1100ff'});
            _this.smoothie.streamTo(canvas, 500);
    
        },
        playPause()
        {
            
          const _this = this;

  
          if(_this.play_status)
          {
  
  
            if(_this.is_recording)
            {

               _this.bv_reading_loudness = _this.max_loudness
              _this.is_recording = false
  
              _this.endRecordingAudio()
  
            }
            clearInterval(_this.timerID)

            clearInterval(window.refreshIntervalId)

            this.play_status = false

            _this.play_pause_text = _this.start_btn_text


  
            
          }
          else
          {
            _this.play_status = true;
            _this.play_pause_text = 'Stop microphone'

            _this.avg_loudness = 0
            _this.avg_loudness_show = 0
            _this.max_loudness = 0
            _this.counter_loudness = 0

  
            _this.resumeRecording()
  
              
            _this.runTimer()
              
  
          }
  
        },
        resumeRecording()
        {
            var _this = this;
        
            // resumeRecordingMedia();
            if(!_this.is_recording)
            {
                recordAudioMedia()
                _this.is_recording = true;
            }
    
        },
        runTimer() {
      
    
            var interval_time = 250;

            const _this = this;

            clearInterval(window.refreshIntervalId)
            
            window.refreshIntervalId = setInterval(function () {
            
                if (soundMeter != null) {

                
                    var sound_l = soundMeter.db.toFixed(2);
                    


                    let sound_threshold = 0;
                    

                    console.debug('sound_l = ' + sound_l)
                    if(sound_l>sound_threshold)
                    {
                    
                        _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
                        

                        _this.avg_loudness_show = roundNumber(_this.avg_loudness,0)

                        _this.counter_loudness += 1;

                        
                    
                    }

                    if(sound_l>_this.max_loudness)
                    {
                        _this.max_loudness = sound_l
                    }


                    _this.addDataChart(sound_l)

                    
                    
                }

            

            }, interval_time);
            

        },
        addDataChart(in_data)
        {
            const _this = this
            if(_this.smoothie!=undefined && _this.smoothie!= null)
            {
            _this.chart_data.append(new Date().getTime(),in_data)
            }
    
        },
        endRecordingAudio()
        {
            stopRecordingMedia(null,null,null);
            this.is_recording = false;

        }
    }
}
</script>
  
  
  
  
  
  
  