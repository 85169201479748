<template>
    <div>
      <div class="row">
        <div class="col-md-10">
          <div class="card card-body h-100">  
            <div class="card card-body">
              <span class="read-text"> {{instruction}} <img :src="audioImg" style="width:35px;margin-left:1%;cursor: pointer;" @click="bv_tts.say(tts_instruction)"></span>
              <p class="fs-3" :style="interaction_color">{{interaction_msg}}</p>
            </div>  

            <div class="row justify-content-center"  v-if="q_type === 'options'">
                <div class="col-md-9">
                    <div class="parent">
                        <span hidden>{{dummy}}</span>
                
                        <img class="backimg" :src="backImg" :style="backImg_info.size">
                        <!-- <img class="frontimg" :src="vaseImg"
                        style="width:120px;top:50px;left:500px;cursor: pointer;" @click="testFunc()"> -->
                        <img v-for="num in frontImg.length" :key="num" class="frontimg" :src="frontImg[num-1]"
                        :style="frontImg_info[num-1].size" style="cursor: pointer;margin-left: 50%;">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="input-group mt-4" v-for="num in all_choices.length" :key="num">
                        <div class="form-check">
                            <label class="form-check-label" :for=num v-bind:style="quiz_choice===null || (all_choices[num-1]!=correct_answer && all_choices[num-1]!=quiz_choice) ? {backgroundColor:'#FFFFFF'} : all_choices[num-1] === correct_answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}"  style="font-size: 22px;">{{all_choices[num-1]}}</label>
                            <input class="form-check-input" type="radio" v-model="quiz_choice" :id=num :value="all_choices[num-1]" @change="selected()">
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="parent" v-else>
                <span hidden>{{dummy}}</span>
        
                <img class="backimg" :src="backImg" :style="backImg_info.size" @click="interaction('back',0)">
                <!-- <img class="frontimg" :src="vaseImg"
                style="width:120px;top:50px;left:500px;cursor: pointer;" @click="testFunc()"> -->
                <img v-for="num in frontImg.length" :key="num" class="frontimg" :src="frontImg[num-1]"
                :style="frontImg_info[num-1].size" style="cursor: pointer;margin-left: 50%;" @click="interaction('front',num-1)">
            </div>
          </div>
        </div>
        <div class="col-md-2" style="padding-left: 0px">
            <div class="card card-body h-100">
            <p class="lead">Exercise {{current_exercise_index}} of {{total_exercises}}</p>
            <button class="btn btn-info btn-lg w-100 mt-2" @click="nextExercise()">Next <i class="bi bi-skip-forward" style="margin-left: 5mm;"></i></button>
            <button v-if="show_results" class="btn btn-outline-warning btn-lg w-100 mt-4" @click="goToResults()"> See your results </button>
            </div>
        </div>
    </div>
    </div>
    </template>
    <script>
    import {checkPermissionsMediaBasic} from '@/media_input/microphone_recording'
    import {save_pre_computed_results} from '@/utils/results/data_save_utils'
    import {getUserDataDir,getData,updateDataDirectory} from '@/firebase/index'
    import {logActivity} from '@/media_input/utils'
    
    // import tableImg from '@/assets/images/image_click/table_alpha.png'
    // import vaseImg from '@/assets/images/image_click/vase_white_alpha.png'
    // import flowerImg from '@/assets/images/image_click/vase_alpha.png'
    // import testImg from '@/assets/images/image_click/burger_white_alpha.png'
    import audioImg from '@/assets/images/audio_logo.png'
    import {startConfetti} from '@/common_js/confetti'

    import { useTTS } from '@/stores/tts'
    import {BV_Points} from '@/utils/bv_points'

    export default {
      name: 'Scene_position',
      props: 
      {
        exForm: {
          type: Object,
        },
      },
      data(){
        // NewsPopup() {
        return {
          base_level_address:'/Levels/Scene_positions',
          level_address:'',
          userCategory:'',
          region:'',
          // tableImg:tableImg,
          // vaseImg:vaseImg,
          // flowerImg:flowerImg,
          // testImg:testImg,
          skip_button: "Skip the whole exercise",
          audioImg:audioImg,
          backImg:null,
          backImg_info:{},
          frontImg:[],
          frontImg_info:[],
          dummy:'',
          instruction:'',
          tts_instruction:'',
          interaction_msg:'',
          interaction_color:'',
          show_btn:false,
          current_exercise_index: 0,
          number_exercises: 1,
          play_pause_text:'Record answer',
          listener_ref:null,
          answer_text: '',
          resultsTable: [],
          results:{'correct':0,'incorrect':0,'total':0},
          uid:'',
          play_status: false,
          audio_player: null,
          half_word_url: null,
          current_qs:[],
          qs_count:0,
          max_qs:3,
          check_count:0,
          right_answer:'',
          previous_img_name: null,
          previous_img_id: 0,
          target_scenario:null,
          finishScene:false,
          history_scene_id:[],
          total_scene_num:0,
          target_words:[],
          started: false,
          answer_text_color: '',
          found_target_word:false,
          scenario_id:0,
          bv_tts: useTTS().tts_engine,
          start_object:  {
            module_path:'', 
            assessment_type: "USER_TRAINING", 
            module_name: 'Position Game', 
            current_exercise_index: 0
            },
          q_type:'options',
          diff_level:'easy',
          used_vals:[],
          show_results: false,
          first_exercise: false,
          position_cate:'',
          correct_answer: '',
          all_position_list:[],
          all_choices:[],
          correct_counter:0,
          incorrect_counter:0,
          final_answer:null,
          quiz_choice: null,
          num_item:0,
          item_name:[],
          scene_repeat_num:0,
          target_repeat_num:5,
          scale_factor:0.9,
          bv_points: new BV_Points()

        }
      },
      created() {
    
        const _this = this
    
        checkPermissionsMediaBasic()
    
        _this.total_exercises = _this.exForm.total_all_type_exercises
    
        this.userCategory = localStorage.getItem('userCategory');
        this.region = localStorage.getItem('region');
        
        logActivity(_this.$firebase, 'accessed', 'scene_position')
        
        let uid = localStorage.getItem('uid');
        _this.uid = uid;
    
        this.chooseScenario();
    
      },
      beforeUnmount()
    {
        this.bv_tts.cancel()

    },

      methods: {
        chooseScenario()
        {
          const _this = this

          let userType = localStorage.getItem('userCategory');


            let uid = localStorage.getItem('uid');

            this.level_address = '/Users/' + uid + this.base_level_address


            if(userType!=undefined || userType==='slp')
            {
                let client_number = localStorage.getItem('client_uid');

                if(client_number!='undefined' && client_number!=null && client_number!='')
                {

                    this.level_address = '/Users/'+ client_number + this.base_level_address

                }
            }


    
          let address = _this.level_address
    
          let do_once = true
    
        //   console.debug('chooseScenario')
            _this.current_exercise_index = _this.exForm.current_exercise_index + 1
    
            _this.total_exercises = _this.exForm.total_all_type_exercises 
    
            if(_this.current_exercise_index > _this.total_exercises)
            {
                _this.nextExercise()
            // _this.endExercise()
            }
            else
            {
                _this.first_exercise = !_this.exForm.has_results
  
                _this.show_results = !_this.first_exercise

                getData(address, function (err, result) {
        
                if(do_once)
                {
                do_once = false
                console.debug('chooseScenario2')
        
                let curr_scenario = result.child('Scenario ID').val()
        
                console.debug('curr_scenario = ' + curr_scenario)
        
                if(curr_scenario==undefined || curr_scenario==null)
                {
                    curr_scenario = 0
                }
                // else
                // {
                //     curr_scenario = parseInt(curr_scenario)
                // }
                console.debug('curr_scenario = ' + curr_scenario)
                _this.scenario_id = curr_scenario
                // _this.scenario_id = 9; //for debugging

                let temp_used_items = result.child('Used_items').val()
                if(temp_used_items==undefined || temp_used_items==null)
                {
                    temp_used_items = []
                }
                _this.used_vals = temp_used_items
                
                let temp_scene_repeat = result.child('Scene_repeat_num').val()
                if(temp_scene_repeat==undefined || temp_scene_repeat==null)
                {
                    temp_scene_repeat = 0
                }
                _this.scene_repeat_num = temp_scene_repeat

                let q_type_setting = Math.floor(Math.random() * 3);
                if(q_type_setting == 0)
                {
                    _this.q_type = "click"
                }
                else
                {
                    _this.q_type = "options"
                }
                // _this.q_type = 'options' //for debugging
                _this.loadImage()
        
                }
            })
            }
    
          
    
        },
        saveResults()
        {
            const _this = this
    
            if(this.correct_counter!=0 || this.incorrect_counter!=0)
            {
            let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1}
    
            let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1}
            
            let results = {all_results:all_results, plot_results:plot_results}
    
            var aphasia_position_data = {resultsTablePre: {exercise_type: 'Position Games', exercise: 'Position Games', results: results, audio_address:''}}
    
    
            save_pre_computed_results(_this.exForm,_this.$firebase,aphasia_position_data)
    
            _this.bv_points.add(10)
    
            _this.$emit('logExerciseResults', aphasia_position_data);
    
            }
        },
        endExercise()
        {
            const _this = this
    
            _this.saveResults()
            let start_object = _this.start_object
    
            let results_table = {'back':[],'pre':[]}
    
            let all_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter,Total:1}
    
            let plot_results = {Correct:_this.correct_counter,Incorrect:_this.incorrect_counter, Total:1}
            
            let results = {all_results:all_results, plot_results:plot_results}
    
    
            let resultsTablePre = {exercise_type: 'Position Games', exercise: 'Position Games', results: results}
    
    
            results_table['pre'].push(resultsTablePre)
    
            start_object.results_table = results_table
            //_this.$router.push({ name: 'results', params: {start_object: start_object, results:results_table}});
    
        },
        closeDialog()
        {
          this.finishScene = false;
        },
        selected()
        {

            console.debug('this.quiz_choice = ' + this.quiz_choice)
            console.debug('this.correct_answer = ' + this.correct_answer)

            if(this.final_answer===null)
            {
                if(this.quiz_choice===this.correct_answer)
                {
                    this.correct_counter = 1
                }
                else
                {
                    this.incorrect_counter = 1
                }

                this.final_answer=this.quiz_choice
            }

            if(this.quiz_choice===this.correct_answer)
            {
                this.bv_tts.system_say("That's correct. Click Next to go to the next exercise")
                startConfetti(this);

            }
            else
            {
                this.bv_tts.system_say("Not quite right!")

            }
        },
        loadImage()
        {
          let _this = this;
          _this.backImg = null;
          _this.backImg_info = {};
          _this.frontImg = [];
          _this.frontImg_info = [];
          let image_folder_ref = _this.$firebase.database().ref("Exercises").child('General').child('Aphasia').child('Image_click');
          var do_once = true;
          getData(image_folder_ref , function (err, result) {
    
              if(do_once)
              {
                  do_once = false;
                  let full_scenario = []
                  result.forEach(function (childSnapshot) {
                      let scenario = childSnapshot.key;
                      full_scenario.push(scenario);
                  }); 
                  _this.total_scene_num = full_scenario.length;
    
                  let address = _this.level_address
    
    
                  let curr_scenario = _this.scenario_id
                //   curr_scenario = curr_scenario+1
                  if(curr_scenario>(_this.total_scene_num-1))
                  {
                    curr_scenario = 0
                  }
    
                //   curr_scenario = curr_scenario.toString()
    
    
    
                  if(_this.history_scene_id.length!=0)
                  {
                    for(let i=0;i<_this.history_scene_id.length;i++)
                    {
                      full_scenario.splice(_this.history_scene_id[i],1);
                    }
                  }
                  _this.scenario_id = Math.min(_this.scenario_id,full_scenario.length-1)
                  let scenario_id = _this.scenario_id
                  _this.history_scene_id.push(scenario_id);
                  _this.target_scenario = full_scenario[scenario_id];
                  _this.backImg_info = result.child(_this.target_scenario).child('backImg').child('0').val();

                  _this.backImg = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${_this.backImg_info.name}_sat0.png`, import.meta.url)
                //   let numbersStr = _this.backImg_info.size.replace(/^\D+/g, '')

                _this.backImg_info.size = _this.rescaleImgConfigByFactor(_this.backImg_info.size,_this.scale_factor)

                _this.backImg_info.size = 'width:' + _this.backImg_info.size 

                //   let numbers = parseInt(_this.backImg_info.size.replace(/^\D+/g, ''), 10)

                //   console.log('size - '+_this.backImg_info.size+'bg size str = '+numbersStr+' to num = '+numbers)
                //   _this.backImg_info.size = "width:"+ numbers*_this.scale_factor+"mm";

                //   console.log('bg size = '+ (numbers*_this.scale_factor)+' nums= '+numbers)
                //   _this.backImg_info.size = "width:"+ _this.backImg_info.size
                  _this.backImg_info.checked = true;
    
                  let temp_all_frontImg = []
                  let temp_all_fontInfo = []
                  let all_front_num = []
                  let total_front_num = 0
                  
                  result.child(_this.target_scenario).child('frontImg').forEach(function (childSnapshot) {
                      let frontInfo_temp = childSnapshot.val();

                      frontInfo_temp.size = _this.rescaleImgConfigByFactor(frontInfo_temp.size,_this.scale_factor)
                      frontInfo_temp.loc = _this.rescaleImgConfigByFactor(frontInfo_temp.loc,_this.scale_factor)

                      frontInfo_temp.size = "width:"+frontInfo_temp.size+";"+frontInfo_temp.loc;

                      frontInfo_temp.checked = false;
                      temp_all_fontInfo.push(frontInfo_temp);
                      all_front_num.push(total_front_num)
                      total_front_num++
                      if(_this.q_type == 'options')
                      {
                        let frontImg_temp = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${frontInfo_temp.name}_halo_alpha.png`, import.meta.url)
                        
                        
                        temp_all_frontImg.push(frontImg_temp)
                      }
                      else
                      {
                        let frontImg_temp = new URL(`../../../../../assets/images/image_click/${_this.target_scenario}/${frontInfo_temp.name}_halo_alpha.png`, import.meta.url)
                        temp_all_frontImg.push(frontImg_temp)
                      }
                      
                  });
                  let exclude_id = _this.used_vals;
                //   if(_this.used_vals.length>1)
                //   {
                //     exclude_id = _this.getRandNums(_this.used_vals,2,[])
                //   }
                //   else
                //   {
                //     exclude_id = _this.used_vals
                //   }
                //   let num_item = 0
                  if((_this.diff_level == 'easy') || (_this.q_type == 'click'))
                  {
                    _this.num_item = 2
                  }
                  else
                  {
                    _this.num_item = 3;
                  }


                  let all_item_combinations = []
                  let all_item_combinations_indexes = []

                  for(let n=0;n<all_front_num.length;++n)
                  {
                    for(let m=0;m<all_front_num.length;++m)
                    {
                        if(n!=m)
                        {
                            all_item_combinations_indexes.push(all_item_combinations_indexes.length)
                            all_item_combinations.push([n,m])
                        }
                    }
                  }


                  let temp_front_ids_combination_index = _this.getRandNumWithExclusion(all_item_combinations_indexes,exclude_id)
                  let temp_front_ids = all_item_combinations[temp_front_ids_combination_index]

        
                  for(let selection in temp_front_ids)
                  {
                    let selected_id = temp_front_ids[selection]
                    _this.frontImg_info.push(temp_all_fontInfo[selected_id])
                    _this.frontImg.push(temp_all_frontImg[selected_id])
                  }

                //   let item_name = []
                  for(let img_id =0; img_id<2; img_id++)
                  {
                    if('description' in _this.frontImg_info[img_id])
                    {
                        _this.item_name.push(_this.frontImg_info[img_id].description)
                    }
                    else
                    {
                        _this.item_name.push(_this.frontImg_info[img_id].name)
                    }
                  }
                  
                  if(_this.q_type == 'options')
                  {
                    _this.instruction = "Fill in the blank: the "+_this.item_name[0] +" is ________ the "+_this.item_name[1]
                    _this.tts_instruction =   "Fill in the blank: the "+_this.item_name[0] +" is in what position compared to the "+_this.item_name[1]

                    _this.bv_tts.say(_this.tts_instruction)
                  }
                
                  _this.used_vals.push(temp_front_ids_combination_index)
                //    _this.used_vals = temp_front_ids.concat(exclude_id)
                   _this.scene_repeat_num = _this.scene_repeat_num+1
                   if((_this.used_vals.length >= total_front_num)||(_this.scene_repeat_num == _this.target_repeat_num))
                   {
                    if(curr_scenario>=(_this.total_scene_num-1))
                    {
                        curr_scenario = 0
                    }
                    else
                    {
                        curr_scenario = curr_scenario+1
                    }
                    _this.used_vals = []
                    _this.scene_repeat_num = 0
                   }
                //    _this.used_vals.push(exclude_id)
                  //TODO update used vals in user database
                  updateDataDirectory(address,{'Scenario ID':curr_scenario,'Used_items': _this.used_vals,'Scene_repeat_num':_this.scene_repeat_num})

                  _this.getPositionDescription()
              }
          });
            
        },
        rescaleImgConfigByFactor(img_size_string,scale_factor)
        {
            let numbers = img_size_string.match(/\d+/g);

            let numbers_scaled = {}

            for(let n in numbers)
            {
                numbers_scaled[String(numbers[n])] = String(numbers[n]*scale_factor)
            }


            var re = new RegExp(Object.keys(numbers_scaled).join("|"),"gi"); 

            img_size_string = img_size_string.replace(re, function(matched){
                return numbers_scaled[matched];
            });

            return img_size_string

        },
        comparePosition()
        {
            let pos_hor = []
            let pos_ver = []
            let pos_dep = []
            let temp_answer_position = ''
            for(let i in this.frontImg)
            {
                if(this.frontImg_info[i].position['horizontal']!=undefined)
                {
                    pos_hor.push(this.frontImg_info[i].position.horizontal)
                }
                if(this.frontImg_info[i].position['vertical']!=undefined)
                {
                    pos_ver.push(this.frontImg_info[i].position.vertical)
                }
                if(this.frontImg_info[i].position['depth']!=undefined)
                {
                    pos_dep.push(this.frontImg_info[i].position.depth)
                }
            }

            
            this.setPositions(pos_hor,"further left","further right")
            this.setPositions(pos_ver,"lower","higher")
            this.setPositions(pos_dep,"farther","nearer")


            let all_suitable_pos = this.getDictionaryFlagId(this.all_position_list,'yes')

            let rand_pos_ques_id = Math.floor(Math.random() * all_suitable_pos.length);

            let temp_options = []
            this.correct_answer = this.all_position_list[all_suitable_pos[rand_pos_ques_id]].descript
            temp_options.push(this.correct_answer) 

            if(this.q_type == 'click')
            {
                let pos = this.all_position_list[all_suitable_pos[rand_pos_ques_id]].name
                if((pos == 'nearer')||(pos == 'farther'))
                {
                    this.instruction = "Among the "+this.num_item +" items, click on the one that is "+pos+' to the screen'
                }
                else
                {
                    this.instruction = "Among the "+this.num_item +" items, click on the one that is "+pos
                }

                this.tts_instruction = this.instruction
                this.bv_tts.say(this.tts_instruction)
            }

            let temp_other_options = [...this.all_position_list]

            let exclude_pos = []
            exclude_pos = this.getDictionaryFlagId(this.all_position_list,'exclude')
            let full_exclude_list = all_suitable_pos.concat(exclude_pos)
            
            // for(let tmp in temp_other_options)
            // {
            //     console.log('temp_other_options before= '+ tmp+' = '+temp_other_options[tmp].name)
            // }
            full_exclude_list.sort()
            full_exclude_list.reverse()

            for(let k in full_exclude_list)
            {
                temp_other_options.splice(full_exclude_list[k],1)
            }
            
            // for(let tmp2 in temp_other_options)
            // {
            //     console.log('temp_other_options after= '+temp_other_options[tmp2].name)
            // }
            let target_other_options = 2 //two other options for radio buttons other than the correct answer
            if(temp_other_options.length<target_other_options)
            {
                target_other_options = temp_other_options.length
            }
            for(let kk = 0; kk<target_other_options;kk++)
            {
                let rand_op_id = Math.floor(Math.random() * temp_other_options.length);
                temp_options.push(temp_other_options[rand_op_id].descript)
                temp_other_options.splice(rand_op_id,1)
            }
            this.all_choices = this.createRandChoices(temp_options)

        },
        setPositions(arr,scale_low,scale_high)
        {
            let temp_answer_position = ''
            if(arr.length<2)
            {
                let id = this.getDictionaryNameId(this.all_position_list,scale_low)
                this.all_position_list[id].suitable = 'exclude'
                id = this.getDictionaryNameId(this.all_position_list,scale_high)
                this.all_position_list[id].suitable = 'exclude'
            }
            else
            {
                if(arr[0] - arr[1]<0)
                {
                    temp_answer_position = scale_low
                    
                }
                else if(arr[0] == arr[1])
                {
                    temp_answer_position = 'none'
                }
                else
                {
                    temp_answer_position = scale_high
                }
                let id = this.getDictionaryNameId(this.all_position_list,temp_answer_position)
                // console.log('answer_hor= '+temp_answer_position + ' id= '+id)
                if(id != -1)
                {
                    this.all_position_list[id].suitable = 'yes'
                }
            }
        },
        createRandChoices(arr)
        {
            let temp_arr = [...arr]
            let output = []
            for(let i in arr)
            {
                let rand_id = Math.floor(Math.random() * temp_arr.length);
                output.push(temp_arr[rand_id])
                temp_arr.splice(rand_id,1)
            }
            return output
        },
        getDictionaryNameId(dic, kw)
        {
            let output = -1
            for(let i in dic)
            {
                if(dic[i].name == kw)
                {
                    output = i
                }
            }
            return output
        },
        getDictionaryFlagId(dic,kw)
        {
            let output = []
            for(let i in dic)
            {
                if(dic[i].suitable == kw)
                {
                    output.push(i)
                }
            }
            return output
        },
        getPositionDescription()
        {
            let _this  = this
            var position_ref = _this.$firebase.database().ref("Exercises").child('Resources').child('Position_relation');
            var do_once = true;
            getData(position_ref , function (err, result) {
    
                if(do_once)
                {
                    do_once = false;

                    _this.all_position_list = []
                    result.forEach(function(childSnapshot)
                    {
                        
                        var pos_variations = childSnapshot.numChildren();
                        let rand_variation_id = Math.floor(Math.random() * pos_variations);
                        let pos_description = childSnapshot.val()[rand_variation_id]
                        
                        let temp_pos_info = {name:childSnapshot.key,descript: pos_description,suitable:'no'}

                        _this.all_position_list.push(temp_pos_info);


                    });

                    _this.comparePosition()
                }
            });
        },
        getAllIndexes(arr, val) 
        {
            var indexes = [], i = -1;
            while ((i = arr.indexOf(val, i+1)) != -1){
                indexes.push(i);
            }
            return indexes;
        },
        getRandNumWithExclusion(arr,excludeVal)
        {
            let random_array = arr.sort(() => Math.random() - 0.5)

            for(let n in random_array)
            {
                if(!excludeVal.includes(random_array[n]))
                {
                    return random_array[n]
                }
            }
            
            return random_array[0]

        },
        getRandNums(arr,num,excludeVal)
        {
            let temp_arr = [...arr]
            let excludeVal_id = []
            if(excludeVal.length>0)
            {
                if(arr.length - excludeVal.length<num)
                {
                    let rand_excludeVal_id = Math.floor(Math.random() * excludeVal.length);
                    excludeVal_id.push(temp_arr.indexOf(excludeVal[rand_excludeVal_id]))
                }
                else
                {
                    for(let k in excludeVal)
                    {
                        let exclude_id = temp_arr.indexOf(excludeVal)
                        if(excludeVal_id>=0)
                        {
                            excludeVal_id.push(exclude_id)
                        }
                    }
                }
                
            }
            
            if(excludeVal_id.length>0)
            {
                excludeVal_id.sort()
                excludeVal_id.reverse()
                for(let j in excludeVal_id)
                {
                    temp_arr.splice(excludeVal_id[j],1)
                }
            }
            let output = []
            for(let i = 0; i<num;i++)
            {
                let randID = Math.floor(Math.random() * temp_arr.length);
                output.push(temp_arr[randID])
                temp_arr.splice(randID,1)
            }
            return output
        },
        interaction(name,id)
        {
            this.dummy = this.dummy + '1'
            this.interaction_msg = '';
            this.qs_count = 0;
          
            if(name == 'front')
            {
                if(!this.frontImg_info[id].checked)
                {
                //   if(this.previous_img_name != null)
                //   {
                //     if(this.previous_img_name == 'back')
                //     {
                //       this.backImg = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.backImg_info.name}.png`, import.meta.url)
                //     }
                //     else if(this.previous_img_name == 'front')
                //     {
                //       this.frontImg[this.previous_img_id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${this.frontImg_info[this.previous_img_id].name}_alpha.png`, import.meta.url)
                //     }
                //   }
                //   this.check_count = this.check_count+1;
                let temp_item_name = ''
                if('description' in this.frontImg_info[id])
                {
                    temp_item_name = this.frontImg_info[id].description
                }
                else
                {
                    temp_item_name = this.frontImg_info[id].name
                }
                if(temp_item_name == this.item_name[0])
                {
                    this.frontImg_info[id].checked = true;
                    this.frontImg[id] = new URL(`../../../../../assets/images/image_click/${this.target_scenario}/${ this.frontImg_info[id].name}_halo_alpha.png`, import.meta.url) 
                    this.interaction_msg = 'Well done. You got it right!'
                    this.interaction_color = 'fontSize:24px;color:green';
                    this.bv_tts.system_say(this.interaction_msg)
                    startConfetti(this)

                    this.correct_counter = 1

                }
                else
                {
                    this.interaction_msg = "Oops..that's not right."
                    this.interaction_color = 'fontSize:24px;color:red';
                    this.bv_tts.system_say(this.interaction_msg)

                    this.incorrect_counter = 1

                }
                  
                //   console.debug('front clicked= '+this.frontImg_info[id].name);
                //   this.instruction = this.frontImg_info[id].q_a[0].question;
                //   console.debug('saying ' + this.instruction)
                //   this.bv_tts.say(this.instruction)
                //   this.right_answer = this.frontImg_info[id].q_a[0].answer;
                //   this.target_words = this.right_answer.split(';')
    
                //   this.operation_instruction = this.record_instruction;
                //   this.show_btn = true;
                //   this.half_word_url = null
    
                //   let first_answer = this.right_answer.split(';')[0]
                //   this.get_half_word_url(first_answer)
    
                //   this.current_qs = [];
                //   this.current_qs = this.frontImg_info[id].q_a;
                //   this.current_qs.splice(0,1);
                }
                
                
           
                
            }
            // this.previous_img_name = name;
            // this.previous_img_id = id;
            
        },
        get_half_word_url(word)
        {
    
            let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'
    
    
            const _this = this;
            const storage = _this.$firebase.storage();
    
            storage.ref(audio_file_location).getDownloadURL()
            .then((url) => {
    
                _this.half_word_url = url
    
                console.debug('Found half word url!!')
    
            })
            .catch((error) => {
    
                console.debug('error ' + error )
            });
        
    
        },
        listenHint() 
        {
    
          const _this = this
    
          let url = _this.half_word_url
    
          if(_this.audio_player==null)
          {
              _this.audio_player = new Audio(url);
    
              _this.audio_player.play();
    
          }
          else
          {
    
              if(!_this.audio_player.paused)
              {
              _this.audio_player.pause();
    
              
              }
              // else
          
              {
              _this.audio_player = new Audio(url);
    
              _this.audio_player.play();
    
              }
          }
    
      },
      goToResults()
      {
          const _this = this;
  
          _this.saveResults()
  
          var goToResultsData = {curr_ex_done:false}
  
          _this.$emit('goToResults', goToResultsData);
  
      },
      nextExercise()
      {
          const _this = this;
       
  
          _this.saveResults()
  
          _this.$emit('nextExercise');
      },
        found_correct_word(curr_sentence)
        {
            const _this = this
    
            _this.results['total'] = 1
    
            _this.interaction_msg = 'Congratulations! You said the right word: ' + curr_sentence
            _this.results['correct'] = 1
            _this.results['incorrect'] = 0
            _this.interaction_color = 'fontSize:24px;color:green';
    
            _this.started = false
            _this.play_status = false
            _this.play_pause_text = 'Record answer'
    
    
            console.debug('_this.answer_text = ' + _this.curr_sentence)
    
    
    
        },
        showAnswer()
        {
            let first_answer = this.right_answer.split(';')[0]
            this.interaction_msg = "The answer is: "+first_answer;
            this.interaction_color = 'fontSize:24px;color:#0099ff';
        },
        loadLocation()
        {
            let all_loc = this.backImg_info.all_loc;
            for(let m =0; m<this.frontImg.length;m++)
            {
                let rand_loc_id = Math.floor(Math.random() * all_loc.length);
                this.frontImg_info[m].size = this.frontImg_info[m].size + ";"+all_loc[rand_loc_id];
                console.debug('front image loc= '+this.frontImg_info[m].size)
                all_loc.splice(rand_loc_id,1);
            }
        },
        randLocation()
        {
            let range_x_min = this.backImg_info.range_x[0];
            let range_x_max = this.backImg_info.range_x[1];
            let range_y_min = this.backImg_info.range_y[0];
            let range_y_max = this.backImg_info.range_y[1];
            let div_x = Math.floor(this.frontImg.length/2);
            let div_y = this.frontImg.length-div_x;
    
            let all_loc = [];
            for(let j = 0;j<div_x;j++)
            {
                for(let k=0;k<div_y;k++)
                {
                    let x = range_x_min + Math.floor((range_x_max-range_x_min)/div_x)*(j+1);
                    let y = range_y_min + Math.floor((range_y_max-range_y_min)/div_y)*(k+1);
                    let loc = "top:"+y+"%;left:"+x+"%";
                    all_loc.push(loc);
                }   
            }
    
            for(let m =0; m<this.frontImg.length;m++)
            {
                let rand_loc_id = Math.floor(Math.random() * all_loc.length);
                this.frontImg_info[m].size = this.frontImg_info[m].size + ";"+all_loc[rand_loc_id];
                console.debug('front image loc= '+this.frontImg_info[m].size)
                all_loc.splice(rand_loc_id,1);
            }
        },
      }
    }
    </script>
    
    <style scoped>
    
    .parent {
      position: relative;
      top: 0;
      left: 0;
    }
    .backimg {
      position: relative;
      top: 0;
      left: 0;
      /* border: 1px red solid; */
    }
    .frontimg {
      position: absolute;
      /* top: 60px;
      left: 500px; */
      /* border: 1px green solid; */
    }
    
    </style>