import {getData, updateDataDirectory} from '@/firebase/index'





export async function getDifficulty(base_level_address)
{

  let output = [1,0]

  let promise = new Promise((resolve) => {


    let userType = localStorage.getItem('userCategory');


    let uid = localStorage.getItem('uid');

    let level_address = '/Users/' + uid + base_level_address


    if(userType!=undefined || userType==='slp')
    {
        let client_number = localStorage.getItem('client_uid');

        if(client_number!='undefined' && client_number!=null && client_number!='')
        {

            level_address = '/Users/'+ client_number + base_level_address

        }
    }



    let address = level_address

    let do_once = true

    console.debug('address = ' + address)

    getData(address, function (err, result) {

      if(do_once)
      {
        do_once = false

        console.debug('result = ' + result)


        let level = 1

        let correct_streak = 0

        if(result!=undefined)
        {
          level = result.child('Level').val()
          correct_streak = result.child('Streak').val()

        }




        if(result==undefined || level==undefined || level==null)
        {
          level = 1
          correct_streak = 0

          updateDataDirectory(address, {Level:level, Streak:correct_streak})
        }

        let output = [level,correct_streak]

        resolve(output)




      }
    })
  })

  output = await promise

  return output

}

export function updateLevel(base_level_address,level,streak,max_level,streak_target)
{

  let level_up = false
  if(streak>=streak_target)
  {
    streak = 0
    

    level = level+1

    level_up = true
    
    if(level>max_level)
    {
      level = level-1

      level_up = false
    }
  }

  let userType = localStorage.getItem('userCategory');


  let uid = localStorage.getItem('uid');

  let level_address = '/Users/' + uid + base_level_address


  if(userType!=undefined || userType==='slp')
  {
      let client_number = localStorage.getItem('client_uid');

      if(client_number!='undefined' && client_number!=null && client_number!='')
      {

          level_address = '/Users/'+ client_number + base_level_address

      }
  }

  updateDataDirectory(level_address,{Level:level,Streak:streak})

  return [level,streak,level_up]


}


