
import {updateDataDirectory, getAuthToken} from '@/firebase/index'
import axios from 'axios'
import firebase from '@firebase/app';
import TraceKit from 'tracekit';
import { handleGlobalError } from '../telemetry/errorHandler.js';

var recording = null;
var save_audio_address = null;
var mediaRecorder;
var chunks = [];
var localStream = null;
var soundMeter = null;
var containerType = "audio/webm"; //defaults to webm but we switch to mp4 on Safari 14.0.2+
var constraints = { audio: {autoGainControl:false,echoCancellation:true,noiseSuppression:false}, video: false };
var other_this = null

var user_mic_calibration = 0

var last_blob = null
// var constraints = { audio: true, video: false };

var timedata = null;
var CTX = null;
var W = null;
var H = null;
var push_structure = null;

var blobs = []

var save_blob = false


export function getStorageMicCalibration()
{
  let temp_user_mic_calibration = localStorage.getItem('user_mic_calibration');

  if(temp_user_mic_calibration)
  {
    return Number(temp_user_mic_calibration)
  }

  return 0
}

export function checkPermissionsMediaBasic() {

  user_mic_calibration = getStorageMicCalibration()

  if (!navigator.mediaDevices.getUserMedia) {
    alert('navigator.mediaDevices.getUserMedia not supported on your browser, use the latest version of Firefox or Chrome');
  } else {
    if (window.MediaRecorder == undefined) {
      alert('MediaRecorder not supported on your browser, use the latest version of Firefox or Chrome');
    } else {

     
      navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
          localStream = stream;

          localStream.getTracks().forEach(function (track) {
            if (track.kind == "audio") {
              track.onended = function (event) {
                console.debug("audio track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
              }
            }
            if (track.kind == "video") {
              track.onended = function (event) {
                console.debug("video track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
              }
            }
          });


        }).catch(function (err) {
          /* handle the error */
          console.debug('navigator.getUserMedia error: ' + err);
        });

    }
  }
}

export function deleteSoundMeter()
{
  console.debug('delete soundmeter')


  if(window.soundMeter!=undefined && window.soundMeter!=null)
  {
    window.soundMeter.script.onaudioprocess = null
    window.soundMeter = null;
  }
  soundMeter = null;
}

export async function checkPermissionsMediaAsync() {

  user_mic_calibration = getStorageMicCalibration()

  const promiseArray = []
  

  promiseArray.push(checkPermissionsMediaAsyncFollowOn())

  const all_results = await Promise.all(promiseArray)

  // console.debug('all_results = ' + all_results)
  // console.debug('all_results[0] = ' + all_results[0])

  return all_results[0]
}

export function checkPermissionsMediaAsyncFollowOn()
{
  let output = new Promise(function(resolve)
  {
    let output = false
    if (!navigator.mediaDevices.getUserMedia) {
      alert('navigator.mediaDevices.getUserMedia not supported on your browser, use the latest version of Firefox or Chrome');
      resolve(output)
    } else {
      if (window.MediaRecorder == undefined) {
        alert('MediaRecorder not supported on your browser, use the latest version of Firefox or Chrome');
        resolve(output)
      } else {

    
        navigator.mediaDevices.getUserMedia(constraints)
          .then(function (stream) {
            localStream = stream;

            localStream.getTracks().forEach(function (track) {
              if (track.kind == "audio") {
                track.onended = function (event) {
                  console.debug("audio track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
                }
              }
              if (track.kind == "video") {
                track.onended = function (event) {
                  console.debug("video track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
                }
              }
            });

            //liveVideoElement.srcObject = localStream;
            //liveVideoElement.play();

            try {
              window.AudioContext = window.AudioContext || window.webkitAudioContext;
              window.audioContext = new AudioContext();
            } catch (e) {
              console.debug('Web Audio API not supported.');
              resolve(output)

            }

            soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
            soundMeter.connectToSource(localStream, function (e) {
              if (e) {
                console.debug("error = " + e);
                resolve(output);
              } else {
                output = true
                resolve(output)

              }
            });

          }).catch(function (err) {
            /* handle the error */
            console.debug('navigator.getUserMedia error: ' + err);
            resolve(output)
          });

      }
    }
  })
  return output
}

export function checkPermissionsMedia() {

  user_mic_calibration = getStorageMicCalibration()

  if (!navigator.mediaDevices.getUserMedia) {
    alert('navigator.mediaDevices.getUserMedia not supported on your browser, use the latest version of Firefox or Chrome');
  } else {
    if (window.MediaRecorder == undefined) {
      alert('MediaRecorder not supported on your browser, use the latest version of Firefox or Chrome');
    } else {

  
      navigator.mediaDevices.getUserMedia(constraints)
        .then(function (stream) {
          localStream = stream;

          localStream.getTracks().forEach(function (track) {
            if (track.kind == "audio") {
              track.onended = function (event) {
                console.debug("audio track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
              }
            }
            if (track.kind == "video") {
              track.onended = function (event) {
                console.debug("video track.onended Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
              }
            }
          });

          //liveVideoElement.srcObject = localStream;
          //liveVideoElement.play();

          try {
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            window.audioContext = new AudioContext();
          } catch (e) {
            console.debug('Web Audio API not supported.');
          }

          soundMeter = window.soundMeter = new SoundMeter(window.audioContext);
          soundMeter.connectToSource(localStream, function (e) {
            if (e) {
              console.debug("error = " + e);
              return;
            } else {

            }
          });

        }).catch(function (err) {
          /* handle the error */
          console.debug('navigator.getUserMedia error: ' + err);
        });

    }
  }
}

export function pauseRecordingMedia()
{
  mediaRecorder.pause();
}

export function startRecordingMedia()
{
  mediaRecorder.start();
}

export function resumeRecordingMedia()
{
  mediaRecorder.resume();
}

export function resetRecordingMedia()
{

  stopRecordingMedia(null, null, null); 
  recording = null;
  chunks = [];
}


export function addSpectrogram()
{
  // const CVS = document.body.querySelector('spectrogram');

  const CVS = document.getElementById('spectrogram');

  CVS.hidden = false;
  CTX = CVS.getContext('2d');
  W = CVS.width;
  H = CVS.height;
}

export function removeSpectrogram()
{
  CTX = null;

  const CVS = document.getElementById('spectrogram');

  if(CVS!=null)
  {
    CVS.hidden = true;
  }
}

export function stopRecordingMedia(address, _push_structure=null, _this=null){

  console.debug('stopRecordingMedia')

  if(mediaRecorder!=undefined)
  {
    
    if(mediaRecorder.state != "inactive")
    {      
      save_audio_address = address;
      push_structure = _push_structure
      other_this = _this

      mediaRecorder.stop();
    }	
  }
}

function stopStream(stream){
  stream.getTracks().forEach( track => track.stop() );
};

export function getRecordingMedia()
{
  if(recording!=null)
  {
    return recording;
  }
  
  return null;
}

export function change_save_blob_config(new_config,_this)
{
  save_blob = new_config
  
  other_this = _this  
}

export function get_blobs()
{
  return blobs
}

export function get_last_recording()
{
  return last_blob
}

export function clear_blobs()
{
  blobs = []
}

export function recordAudioMedia() {
  if (localStream == null) {
    alert('Could not get local stream from mic/camera');
  } else {

    chunks = [];

    /* use the stream */
    console.debug('Start recording...');
    if (typeof MediaRecorder.isTypeSupported == 'function') {
      /*
          MediaRecorder.isTypeSupported is a function announced in https://developers.google.com/web/updates/2016/01/mediarecorder and later introduced in the MediaRecorder API spec http://www.w3.org/TR/mediastream-recording/
      */

      var options = { mimeType: 'audio/webm' };

      if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
        // var options = { mimeType: 'video/webm;codecs=vp9' };
        containerType = "audio/webm";

      } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
        // var options = { mimeType: 'video/webm;codecs=h264' };
        containerType = "audio/webm";

      } else if (MediaRecorder.isTypeSupported('video/webm')) {
        // var options = { mimeType: 'video/webm' };
        containerType = "audio/webm";

      } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        //Safari 14.0.2 has an EXPERIMENTAL version of MediaRecorder enabled by default
        containerType = "audio/mp4";
        // var options = { mimeType: 'video/mp4' };
      }
      var options = { mimeType: containerType };

      console.debug('Using ' + options.mimeType);
      mediaRecorder = new MediaRecorder(localStream, options);
    } else {
      console.debug('isTypeSupported is not supported, using default codecs for browser');
      mediaRecorder = new MediaRecorder(localStream);
    }

    mediaRecorder.ondataavailable = function (e) {
      console.debug('mediaRecorder.ondataavailable, e.data.size=' + e.data.size);
      if (e.data && e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    mediaRecorder.onerror = function (e) {
      console.debug('mediaRecorder.onerror: ' + e);
    };

    mediaRecorder.onstart = function () {
      chunks = [];

      console.debug('mediaRecorder.onstart, mediaRecorder.state = ' + mediaRecorder.state);

      localStream.getTracks().forEach(function (track) {
        if (track.kind == "audio") {
          console.debug("onstart - Audio track.readyState=" + track.readyState + ", track.muted=" + track.muted);
        }
        if (track.kind == "video") {
          console.debug("onstart - Video track.readyState=" + track.readyState + ", track.muted=" + track.muted);
        }
      });

    };

    mediaRecorder.onstop = function () {


      console.debug('mediaRecorder.onstop, mediaRecorder.state = ' + mediaRecorder.state);

      recording = new Blob(chunks, { type: mediaRecorder.mimeType });

      last_blob = recording

      if(save_blob)
      {
        // blobs.push(recording)

        if('audio_blobs' in other_this)
        {
          // console.log('blobs.length = ' + other_this.audio_blobs.length)

          other_this.audio_blobs_watcher = other_this.audio_blobs_watcher+1

          other_this.audio_blobs.push(recording)
          // console.log('blobs.length = ' + other_this.audio_blobs.length)

        }
      }
      else if(push_structure!=null && save_audio_address!=null)
      {

        // sending audio to be processed in the back end
        const form = new FormData();
        
        const json = JSON.stringify(push_structure);
    
        console.log(push_structure)
        let file = new File([recording], 'file.mp3');
        form.append('json_data', json);
  
        console.debug('file = ' + file)
        form.append('files', file, 'file.mp3');
  
        
        console.debug("Saving Media axios " + save_audio_address);
        let api = import.meta.env.VITE_FAST_API + "/analysis_runner"

        //set processing_status (started, processing, finished, failed)
        updateDataDirectory(push_structure['path'], {"processing_status": "started"})

        getAuthToken().then((idToken) => {
          axios.post(api, form, {
            headers: {
              'Authorization': `Bearer ${idToken}`,
              'Content-Type': `multipart/form-data`,
            }}).then(response => {
              console.debug(response)
            }).catch(error => {
              updateDataDirectory(push_structure['path'], {"error": "error with axios post request - error message: " + 
                error.message + "| server response: " + JSON.stringify(error.response ? error.response : 'no response') + " push_structure: " + JSON.stringify(push_structure) + " | request config: " + JSON.stringify(error.config), "processing_status": "error"})
            });
        }).catch(error => {
          updateDataDirectory(push_structure['path'], {"error": "error with axios getAuthToken request", "processing_status": "error"})          
        });
      }
      else if(save_audio_address!=null)
      {
        // no analysis needs to be done, but we still want to save the data
        let storageRef = firebase.storage().ref();
        const voiceRef = storageRef.child(save_audio_address);
        voiceRef.put(recording);
      }

      save_audio_address = null;
    
    };

    mediaRecorder.onpause = function () {
      console.debug('mediaRecorder.onpause, mediaRecorder.state = ' + mediaRecorder.state);
    }

    mediaRecorder.onresume = function () {
      console.debug('mediaRecorder.onresume, mediaRecorder.state = ' + mediaRecorder.state);


    }

    mediaRecorder.onwarning = function (e) {
      console.debug('mediaRecorder.onwarning: ' + e);
    };

    chunks = [];

    mediaRecorder.start(1000);



    localStream.getTracks().forEach(function (track) {
      console.debug(track.kind + ":" + JSON.stringify(track.getSettings()));
      console.debug(track.getSettings());
    })
  }

}

export function SoundMeter(context) {
  this.context = context;
  this.instant = 0.0;

  this.slow = 0.0;
  this.clip = 0.0;
  this.db = 0.0
  this.db2 = 0.0;


  this.calibration = 40.0 + 4 + user_mic_calibration;

  this.script = context.createScriptProcessor(4*2048, 1, 1);
  // this.script = context.createScriptProcessor(1024, 1, 1);
  var that = this;
  this.script.onaudioprocess = function (event) {


    if(mediaRecorder==undefined)
    {
      // console.debug('Media recorder undefined')
      return;
    }
    else if(mediaRecorder.state == 'inactive')
    {
      // console.debug('mediarecorder.state = ' + mediaRecorder.state)

      return
    }
    else
    {
      // console.debug('mediarecorder.state = ' + mediaRecorder.state)


    }
    var input = event.inputBuffer.getChannelData(0);
    var i;

    var LEVEL = 0.0;
    // var timedata = new Uint8Array(that.analyser.frequencyBinCount);
    that.analyser.getByteFrequencyData(timedata);
    // console.debug('get byte frequency data')

    
    let max_freq_intensity = 0
    let max_freq_val = 10000

    let rms = 0
    let tdata_length = timedata.length





    if(CTX==null)
    {



      let freq_step = context.sampleRate/2/tdata_length


      for (i = 0; i < tdata_length/2; i++) 
      {

        let timedata_value = timedata[i]

        rms += Math.abs(timedata_value)

      }

      const max_allowed_frequency = 500


      
      for (i = 1; i < tdata_length-1; i++) 
      {

        let timedata_value = timedata[i]
        LEVEL += timedata_value;

        let spec_value = timedata[i] + timedata[i-1] + timedata[i+1] 


        if(max_freq_intensity*1.2<spec_value && i*freq_step<max_allowed_frequency)
        {
          max_freq_intensity = spec_value

          // console.debug('prev_max_freq = ' + max_freq_val + ' curr_max_freq = ' + i*freq_step )
          max_freq_val = i*freq_step

        }
      }
      // console.debug('tdata_length1 = ' + tdata_length)

      // let test = (500/freq_step)

      // console.debug('tdata_length2 = ' + test)

      

      // console.debug('timedata = ' + timedata)
      // console.debug('LEVEL = ' + LEVEL)


      // console.debug('AAA max_freq_index ' + max_freq_index)


     
    }
    else
    {

      let hor_step = 3;

      

      
      let imgData = CTX.getImageData(hor_step, 0, W - W/3, H);
      CTX.fillRect(0, 0, W, H);
      CTX.putImageData(imgData, 0, 0);

      var LEN = timedata.length*7/8;
      const h = H / LEN;
      const x = W - W/3;
      const scale_factor = 20;


      for (i = 0; i < LEN; i++) 
      {
        LEVEL += timedata[i];

        let rat = timedata[i] / 255;
        // rat = rat*rat;//trying to make it sharper
        let hue = Math.round((rat * 120) + 280 % 360);
        let sat = '100%';
        let lit = 10 + (70 * rat) + '%';


        
        CTX.beginPath();


        CTX.strokeStyle = `hsl(${hue}, ${sat}, ${lit})`;
        for(let x1 = x;x1<x+hor_step;++x1)
        {
          for(let k=0;k<scale_factor;++k)
          {
            CTX.moveTo(x1, H - (i* scale_factor * h + k));
            CTX.lineTo(x1, H - (i* scale_factor * h + h*scale_factor + k));
          }
        }
        CTX.stroke();
      }

      let hex_green_colour = '#00ff00'

      let line_1_height = H-H/8;
      CTX.beginPath();
      CTX.lineWidth = 5;

      CTX.strokeStyle = hex_green_colour
      CTX.moveTo(0, line_1_height);
      CTX.lineTo(W, line_1_height);
      CTX.stroke();



      let line_2_height = H-H/3;
      CTX.beginPath();
      CTX.lineWidth = 5;

      CTX.strokeStyle = hex_green_colour
      CTX.moveTo(0, line_2_height);
      CTX.lineTo(W, line_2_height);
      CTX.stroke();

      

      CTX.lineWidth = 1;


    }
    
    var sum = 0.0;
    var clipcount = 0;
    var sum0 = 0.0;
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
      sum0 += input[i];
      if (Math.abs(input[i]) > 0.99) {
        clipcount += 1;
      }
    }

    that.max_freq_index = max_freq_val

    // https://stackoverflow.com/questions/38971884/how-to-correctly-determine-volume-in-db-from-getbytefrequencydata
    that.db2 = Math.max.apply( null, timedata );

    let sort_timedata = timedata.sort(function(a, b){return b - a});
    let sound_db = 0
    let n_samples = 30

    for(let n=0;n<n_samples;++n)
    {
      sound_db = sound_db + sort_timedata[n]
    }
    sound_db = sound_db/256/n_samples*41+39

    // that.db = sound_db

    
    // that.db = 20 * Math.log10(LEVEL /tdata_length) + that.calibration;
    // that.db = 20 * Math.log(Math.sqrt(sum/input.length))/Math.log(10) + that.calibration+5
    that.db = 20 * Math.log(rms/tdata_length/2)/Math.log(10) + that.calibration+3

    that.db = (that.db+sound_db)/2
    
    // that.db = 10*Math.log10(LEVEL/(tdata_length*1))

    // console.debug('Level = ' + LEVEL)

    // console.debug('tdata_length = ' + tdata_length)
    // console.debug('db = ' + that.db)
    // console.debug('that.db2 = ' + that.db2)

  

    // that.db = (top3[0]+top3[1]+top3[2])/(3*256)*65+10
    // that.db = that.db2/256*75
    // console.debug('that.db = ' + that.db)


    that.instant = Math.sqrt(sum / input.length);
    that.slow = 0.95 * that.slow + 0.05 * that.instant;
    that.clip = clipcount / input.length;
  };
}

SoundMeter.prototype.connectToSource = function (stream, callback) {
  console.debug('SoundMeter connecting');
  try {
    this.mic = this.context.createMediaStreamSource(stream);
    this.analyser = this.context.createAnalyser();
    // this.analyser.fftSize = 2*1024;
    this.analyser.fftSize = 4*1024;
    this.analyser.smoothingTimeConstant = 0.3;
    this.mic.connect(this.script);


    this.mic.connect(this.analyser);
    this.analyser.connect(this.script)
    timedata = new Uint8Array(this.analyser.frequencyBinCount);

    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination);



    if (typeof callback !== 'undefined') {
      callback(null);
    }



  } catch (e) {
    console.error(e);
    if (typeof callback !== 'undefined') {
      callback(e);
    }
  }

};

SoundMeter.prototype.stop = function () {
  this.mic.disconnect();
  this.script.disconnect();
};



