<template>
  <div class="d-flex flex-column min-vh-100">
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="card card-body">
            <h4>
                Start with the number on the left, work across to the right following instructions in each box
            </h4>
          </div>
        </div>
        <div class="row">
            <span :style="interaction_message_color"> {{  interaction_message}}</span> 
        </div>
        <div class="d-flex">
            <div >
              <span class="badge rounded-pill bg-primary position-absolute m-2" style="z-index: 1">Starting number</span>
              <button style="width: 35mm;height: 25mm;"> <b style="font-size: xx-large;">{{ numbers[0] }}</b>  </button>
              <i class="bi bi-chevron-right" style="font-size: xx-large;"></i>
            </div>
            <div>
              <span v-if="flag_showAnswer" class="badge rounded-pill bg-success position-absolute m-2" style="z-index: 1;font-size: medium;">{{ showAnswer_steps[0] }}</span>
              <button class="maths-button"> <b style="font-size: xx-large;">{{ display_op[0] }}</b>  </button>
              <i class="bi bi-chevron-right" style="font-size: xx-large;"></i>
            </div>
            <div>
              <span v-if="flag_showAnswer" class="badge rounded-pill bg-success position-absolute m-2" style="z-index: 1;font-size: medium;">{{ showAnswer_steps[1]  }}</span>
              <button class="maths-button"> <b style="font-size: xx-large;">{{ display_op[1] }}</b>  </button>
              <i class="bi bi-chevron-right" style="font-size: xx-large;"></i>
            </div>
            <div>
              <span v-if="flag_showAnswer" class="badge rounded-pill bg-success position-absolute m-2" style="z-index: 1;font-size: medium;">{{ showAnswer_steps[2]  }}</span>
              <button class="maths-button"> <b style="font-size: xx-large;">{{ display_op[2] }}</b>  </button>
              <i class="bi bi-chevron-right" style="font-size: xx-large;"></i>
            </div>
            <div>
              <span class="badge rounded-pill bg-info position-absolute m-2" style="z-index: 1">Your answer</span>
              <button style="width: 40mm;height: 25mm;">
              <input type="text" class="form-control mt-4" v-model="input" placeholder="Enter answer"/>
              </button>
              <!-- <button style="width: 40mm;height: 25mm;"> <b style="font-size: xx-large;">{{ numbers[0] }}</b>  </button> -->
            </div>
          </div>
      </div>
      <div class="col-md-3">
        <div class="card card-body">
          <button class="btn-info btn btn-lg w-100 mt-2"  @click="compareResult()">Submit </button>
          <button v-if="flag_showBtn" class="btn btn-success btn-lg w-100 mt-2" @click="showAnswer()" >Show answer </button>
          <button class='btn btn-secondary btn-lg w-100 mt-2' @click="skipExercise()"> Skip  </button>
          <!-- <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results </button> -->
        </div>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>    
</template>
<script>
  
import { Modal } from 'bootstrap'
import {startConfetti} from '@/common_js/confetti'
import { shallowReactive } from 'vue'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

  export default {
    components: { },
    name: 'Maths_table',
    components: {Footer, Navbar},
    data() {
      return {
        plus: ['plus','add','+'],
        minus: ['minus','take away','-'],
        multiply:['multiply by','times','x'],
        divide:['divided by', '/'],
        op_full:[{'name':'plus','operators':['plus','add','+']},
                {'name':'multiply','operators':['multiply by','times','x']},
                {'name':'minus','operators':['minus','take away','-']},
                {'name':'divide','operators':['divide by', '/']}],
        operators:['','',''], //op1,op2,op3
        display_op:['','',''],
        numbers:[1,2,3,4], //[start,op1,op2,op3]
        inter_values:[1,2,3,4], //[start,after op1, after op2, after op3]
        finish_statement:'',
        finish_detail:'',
        input:'',
        multipleOf:[2,3,5,7],
        interaction_message:'',
        interaction_message_color:'',
        flag_showAnswer:false,
        flag_showBtn:false,
        showAnswer_steps:['','','']
      }
    },
    created() 
    {
      const _this = this
      this.userCategory = localStorage.getItem('userCategory');
      this.loadQuestion();
    },
    methods: 
    {
       loadQuestion()
       {
            console.debug('refreshed')
            this.numbers[0] = Math.floor(Math.random()*10)

            this.numbers[0] = 1
            this.inter_values[0] = this.numbers[0]

            for(let cnt=0;cnt<(this.inter_values.length-1);++cnt)
            {
                // console.debug('here ' + cnt)
                let multiple = this.ifMultipleOf(this.inter_values[cnt])
                // console.debug('multiple = ' + multiple)
                let randOp = 0
                if(multiple != this.inter_values[cnt]) //if multiple factor exists, 4 operations to choose ffrom:+,-,x,/
                {
                    if(this.inter_values[cnt]>0)
                    {
                        randOp = Math.floor(Math.random()*this.op_full.length) 
                    }
                    else
                    {
                        randOp = 0
                    }
                }
                else
                {
                    // console.debug('CANT DIVIDE')
                    if(this.inter_values[cnt]>0)
                    {
                        randOp = Math.floor(Math.random()*(this.op_full.length-1)) //if multiple factor exists, 3 operations to choose ffrom:+,-,x
                    }
                    else
                    {
                        randOp = 0
                    }
                    // [this.inter_values[cnt+1],this.numbers[cnt+1]] = this.randOperation(this.numbers[cnt],this.op_full[randOp].name,multiple)
                }
                [this.inter_values[cnt+1],this.numbers[cnt+1]] = this.randOperation(this.inter_values[cnt],this.op_full[randOp].name,multiple)
                let rand_display_operator = Math.floor(Math.random()*this.op_full[randOp].operators.length)
                this.display_op[cnt] = this.op_full[randOp].operators[rand_display_operator]+' '+this.numbers[cnt+1]
                let last_rand_id = this.op_full[randOp].operators.length-1
                this.operators[cnt] = this.op_full[randOp].operators[last_rand_id]
                // console.debug('trains= '+this.display_op[cnt])
            }
            // //this.$forceUpdate()
        
       },
       randOperation(firstNum,op,factor)
       {
            let result = firstNum
            let newNum = 0
            if(op == 'plus')
            {
                let rand_plus_num = Math.floor(Math.random()*10)
                result = firstNum + rand_plus_num
                newNum = rand_plus_num
            }
            else if(op == 'minus')
            {
                let rand_minus_num = 0
                if(firstNum>9)
                {
                    rand_minus_num = Math.floor(Math.random()*8)+1
                }
                else
                {
                    rand_minus_num = Math.floor(Math.random()*(Math.floor(firstNum/2)))
                }
                 
                result = firstNum - rand_minus_num
                newNum = rand_minus_num
            }
            else if(op == 'multiply')
            {
                let rand_multi_num = 1
                if(firstNum <= 10)
                {
                    rand_multi_num = Math.floor(Math.random()*9) 
                }
                else
                {
                    rand_multi_num = Math.floor(Math.random()*2)+1 
                }
                result = firstNum * rand_multi_num 
                newNum = rand_multi_num
            }
            else if(op == 'divide')
            {
                result = firstNum/factor
                newNum = factor
            }
            else
            {
                console.debug('ERROR: operations undefined!!!')
            }
            return [result,newNum]
       },
       ifMultipleOf(randNum)
       {
            let guessBase = 1
            let found_multiple = false

            for(let i in this.multipleOf)
            {
                guessBase = this.multipleOf[i]
                let remainder = randNum%guessBase
                // console.debug('randNum = ' + randNum)

                // console.debug('remainder = ' + remainder)
                // console.debug('guessBase = ' + guessBase)
                if(remainder == 0)
                {
                    found_multiple = true
                    break
                }
                
            }
            if(found_multiple)
            {
                return guessBase
            }
            else
            {
                return randNum
            }
        
       },
       compareResult()
       {
            console.debug('input = '+this.input)
            if(this.input == this.inter_values[3])
            {
                console.debug('input correct')
                this.interaction_message = 'Congrats! You got it right!'
                this.interaction_message_color = 'fontSize:24px;color:green;white-space: pre-wrap'
                startConfetti(this)

                setTimeout(() => {
                    this.skipExercise();
                }, 1500);
            }
            else
            {
                this.interaction_message = "Hmmm... It doesn't seem right. Try it again"
                this.interaction_message_color = 'fontSize:24px;color:red;white-space: pre-wrap'
                this.flag_showBtn = true
                console.debug('input incorrect')
            }
       },
       showAnswer()
       {
            this.interaction_message = ''
            for(let i=0;i<this.inter_values.length-1;i++)
            {
                this.showAnswer_steps[i] = this.inter_values[i] + ' '+this.operators[i]+' '+this.numbers[i+1]+' = '+this.inter_values[i+1]
                // console.debug('show answer '+i+' = '+this.showAnswer_steps[i])
            }
            this.interaction_message = 'The answer is '+this.inter_values[3]+'. See the step by step process below.'
            this.interaction_message_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'
            this.flag_showAnswer = true
            // //this.$forceUpdate()
       },
       skipExercise()
       {
            this.interaction_message = ''
            this.input = ''
            this.flag_showAnswer = false
            this.flag_showBtn = false
            this.loadQuestion();
            ////this.$forceUpdate()

       }
    }
  }
  </script>
  <style scoped>
  .custom-button-BV
  {
      width: 130px;
      height: 130px;
      border-radius: 100px;
  }
  li:hover {
    background-color: #e0e0e2;
    color: white;
  }
  .keyboard-button
  {
    width: 20mm;
    height: 20mm;
    font-size: xx-large;
    margin-left: 5mm;
  }
  .maths-button
  {
    width: 55mm;
    height: 25mm;
    font-size: x-large;
    margin-left: 1mm;
    background-color: white;
    border-color: blueviolet;
  }
  .details
  {
    font-size: 24px;
  }
  </style>