<template>
  <div>
    <cats 
      :cats_data="results['cats']" 
      v-if="results['cats']"></cats>
 
  </div>
</template>    

<script>
import {ref, onUpdated, onMounted} from 'vue'
import cats  from '@/views/session/Result/type/cats.vue'

export default {
    name: 'cats_section',
    components: {
      cats,
    },
    props: ['results'],
    setup(props) {



      onMounted( () => {


      });

      return {
        
      };

    }
  }
  </script>