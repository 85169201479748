<template>
  <div>
    <div class="card-body divH text-start border border-dark border-opacity-25" style="cursor:pointer" @click="selected_module='MinimalPairs';openModal('word_pairs_modal');">
      <div class="row">
        <div class="col-3" style="padding: 0px 0px 0px 10px;">
          <img :src="Speech_word_pairs" class="img-thumbnail bg-info text-white rounded-circle me-2 d-inline">
        </div>
        <div class="col-9 d-flex align-items-center">
          <strong>Word Pairs</strong>
        </div>
      </div>
    </div>
    <div class="modal fade" id="word_pairs_modal" tabindex="-1" aria-labelledby="word_pairs_modal" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Word Pairs</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal('word_pairs_modal')"></button>
          </div>
          <div class="modal-body">

            <div class="row" style="height:20vh">
              <div class="col-4 border-3 border-success border-end">
                <button :class="['btn','mb-2', 'w-100', selected_module == 'MinimalPairs'?'btn-primary':'btn-light']" type="button" @click="selected_module='MinimalPairs'">
                  Minimal pairs articulation
                </button>
              </div>
              <div class="col-8">
                <div v-if="selected_module == 'MinimalPairs'">
                  <p class="lead">Practise phoneme articulation with minimal pairs </p>
                  <div class="row mb-2">
                    <label class="col-6 form-label" for="topic">Choose sound change</label>
                    <div class="col-6">
                      <select class="form-select form-select" v-model="module_configuration['sound_change']">
                        <option value="c_g">C to G</option>
                        <option value="c_p">C to P</option>
                        <option value="c_t">C to T</option>
                        <option value="p_b">P to B</option>
                        <option value="p_t">P to T</option>
                        <option value="t_d">T to D</option>
                        <option value="ch_b">CH to B</option>
                        <option value="ch_d">CH to D</option>
                        <option value="ch_p">CH to P</option>
                        <option value="ch_t">CH to T</option>
                        <option value="l_b">L to B</option>
                        <option value="l_d">L to D</option>
                        <option value="l_p">L to P</option>
                        <option value="l_t">L to T</option>
                        <option value="r_b">R to B</option>
                        <option value="r_d">R to D</option>
                        <option value="r_p">R to P</option>
                        <option value="r_t">R to T</option>
                        <option value="s_b">S to B</option>
                        <option value="s_d">S to D</option>
                        <option value="s_p">S to P</option>
                        <option value="s_t">S to T</option>
                        <option value="sh_s">SH to S</option>

                        <option value="sh_b">SH to B</option>
                        <option value="sh_d">SH to D</option>
                        <option value="sh_p">SH to P</option>
                        <option value="sh_t">SH to T</option>
                        <option value="th_b">TH to B</option>
                        <option value="th_b_voiceless">TH (voiceless) to B</option>
                        <option value="th_d">TH to D</option>
                        <option value="th_d_voiceless">TH (voiceless) to D</option>
                        <option value="th_p">TH to P</option>
                        <option value="th_p_voiceless">TH (voiceless) to P</option>
                        <option value="th_t">TH to T</option>
                        <option value="th_t_voiceless">TH (voiceless) to T</option>
                        <option value="z_b">Z to B</option>
                        <option value="z_d">Z to D</option>
                        <option value="z_t">Z to T</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <label class="col-6 form-label" for="nexercises">Number of exercises</label>
                    <div class="col-6">
                      <FormKit input-class="form-control" id="nexercises" type="number" name="number" @change="onNumberExercisesChange" @keyup="onNumberExercisesChange"  v-model="local_number_exercises" value="5" 
                      :sections-schema="{
                          messages: { $el: 'div' },
                          message: { $el: 'span' },
                          options: { $el: 'div' },
                          option: { $el: 'div' },
                          }"
                        messages-class="text-danger"
                        validation-visibility="dirty"
                        validation="required|min:1|max:15" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success me-2" @click="emit_add_to_assignment_list" :disabled="this.local_number_exercises <= 0">Add to home training list</button>
            <button type="button" class="btn btn-primary" @click="emit_start_training" :disabled="this.local_number_exercises <= 0">Start training</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Speech_word_pairs from '@/assets/images/dashboard/slt/training/Speech_word_pairs.svg'
import { Modal } from 'bootstrap'

export default {
  name: 'sound',
  components: {},
  props: 
  {
    number_exercises: Number
  },
  data(){
    return {
      Speech_word_pairs: Speech_word_pairs, 

      selected_module: '',
      local_number_exercises: 5,

      modal_references: {},

      module_configuration: {
        sound_change: "ch_b",
      }

    }
  },
  watch: {
    selected_module(newModule, oldModule) {
      this.$emit('selected_module', newModule)
      this.$emit('module_configuration_change', this.module_configuration)
    },
    number_exercises(new_number_exercises, old_number_exercises) {
      this.local_number_exercises = new_number_exercises
    },
    module_configuration: {
      handler(newValue, oldValue) {
        this.$emit('module_configuration_change', this.module_configuration)
      },
      deep: true
    }
  },
  created() {
  },
  mounted() {
    this.modal_references = {
      "word_pairs_modal": new Modal(document.getElementById('word_pairs_modal')),
    }
    this.local_number_exercises = this.number_exercises
  },
  methods: {
    emit_start_training() {
      this.closeModal('word_pairs_modal')
      this.$emit('start_training', this.selected_module)
    },
    emit_add_to_assignment_list() {
      this.closeModal('word_pairs_modal')
      this.$emit('add_to_assignment_list', this.selected_module)
    },
    openModal(name_id) {
      this.reset()
      if (name_id in this.modal_references) {
        this.$emit('selected_module', this.selected_module)
        this.modal_references[name_id].show()
      }
    },
    closeModal(name_id) {
      if (name_id in this.modal_references) {
    
        this.modal_references[name_id].hide()
      }
    },
    onNumberExercisesChange() {
      this.$emit('onNumberExercisesChange', this.local_number_exercises)
    },
    reset() {
      this.module_configuration = {
        sound_change: "ch_b",
      }
    },
  }
}
</script>