<template>
  <div>
    
    <div class="html2pdf__page-break" />

    <section class="pdf-item">
      <div class="row mt-2">
        <div class="card card-body"  style="border-color: blueviolet;">
          <div class="accordion" id="accordionSemantic">
            <div class="accordion-item mb-3">

              <div class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#semantic_fluency_collapse" aria-expanded="false" aria-controls="semantic_fluency_collapse"><p class="text-start" >
                  <b>Semantic fluency</b></p></button>
              </div>
              <div class="accordion-collapse collapse" id="semantic_fluency_collapse" data-bs-parent="#accordionSemantic">

                <div class="row mt-4 m-2">
                  <div class="col-6 text-start">
                    <p>Avg. number of words answered:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{word_findingOverview.average_words}} per topic</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row mb-2 m-2">
                  <div class="col-6 text-start">
                    <p>Topic with least answers:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p><span v-for="it in word_findingOverview.leastTopic.length">{{ word_findingOverview.leastTopic[it-1].topic }},</span></p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>
                
                <div class="row mb-2 m-2">
                  <div class="col-6 text-start">
                    <p>Use hints in:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{ word_findingOverview.total_hint }} out of {{ word_findingOverview.total }}  topics ({{ word_findingOverview.hint_percentage }}%)</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>

                <div class="row mb-2 m-2">
                  <div class="col-6 text-start">
                    <p>Avg. number of hints used:</p>
                  </div>
                  <div class="col-6 text-start">
                    <p>{{word_findingOverview.average_hints}} per topic</p>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>


                <div class="row m-2">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                        <tr class="text-center">
                          <th>Topic</th>
                          <th>Number of words answered</th>
                          <th>Number of hints used</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-center" v-for="(item, index) in table" :key="index" >
                          <td>{{ item.Topic }}</td>
                          <td>{{ item.Number_Words }}</td>
                          <td>{{ item.Number_Hints }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr style="height:2px;border:none;color:#333;background-color:green;" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>


<script>

export default {
  components: { 
  },
  name: 'Word_finding',
  props: ['word_findingOverview', 'table'],
  setup(props, { emit }) {
    
    return {
      
    }

  }
}

</script>
<style scoped>


</style>





