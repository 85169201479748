<template>
  <div>
    <questionnaire 
      :q_data="results['Questionnaire']" 
      v-if="results['Questionnaire']"></questionnaire>
    <isession_questionnaire @is_isession="is_isession=true"
      :results="results['session_questionnaire']" 
      v-if="results['session_questionnaire'] && results['session_questionnaire'].length > 0">
    </isession_questionnaire>
  </div>
</template>    

<script>
import {ref, onUpdated, onMounted} from 'vue'
import questionnaire  from '@/views/session/Result/type/questionnaire.vue'
import isession_questionnaire from '@/views/session/Result/type/isession_questionnaire.vue'

export default {
    name: 'questionnaire_section',
    components: {
      questionnaire,
      isession_questionnaire
    },
    props: ['results'],
    setup(props) {

      const is_isession = ref(false)
      onMounted( () => {

      });

      return {
        is_isession
      };


    }
  }
  </script>