import {Module} from '@/utils/session/module.js'
import {Package} from '@/utils/session/package.js'
import {getUserDataDir} from '@/firebase/index'

export class Session {
  constructor(requestType, context) {

    this.context = context
    
    this.requestType = requestType

    this.module = null
    
    this.package = null

    this.isPackage = false

    this.userCategory = localStorage.getItem("userCategory")

    this.title = ''
  }

  async initialise() {
    let _this = this
    
    await this.getSubscription()

    await this.checkActivity()

    let meta = {}

    let start_object = {
      module_path:'', 
      assessment_type: "USER_TRAINING",
      module_name: '', 
      current_exercise_index: 1
    }

    if (_this.context.$route.query.start_object) {
      start_object = JSON.parse(decodeURIComponent(_this.context.$route.query.start_object))
    }
    
    meta = {  //don't move this
      start_object: start_object
    }
    

    if (_this.requestType === "assignment") {
      
      _this.isPackage = _this.context.$route.query.isPackage === "true"? true:false

      let pmeta = {
        index: Number(_this.context.$route.query.module_index),
        isPackage: _this.context.$route.query.isPackage === "true"? true:false,
        isAssessment: _this.context.$route.query.isAssessment === "true"? true:false,
        package_uid: _this.context.$route.query.package_uid,
        current_exercise_index: Number(_this.context.$route.query.current_exercise_index),
        start_object: meta.start_object
      }

      if (_this.isPackage) {
        this.package = new Package(pmeta)
        await this.package.initialise(_this.requestType, pmeta)
        _this.title = _this.package.title
      } else {
        _this.module = new Module(pmeta)
        await _this.module.initialise(_this.requestType)
        _this.title = _this.module.title
      }
      
    } else if (_this.requestType === "clinic_assessment_package") {
      //assessment package in clinic

      let isPackage = _this.context.$route.query.isPackage === "true" ? true : false

      if (isPackage) {
        let package_data = JSON.parse(decodeURIComponent(_this.context.$route.query.package_data))
        let package_dir = _this.context.$route.query.package_dir
  
        meta = {
          package_name: package_data['name'],
          package_data: package_data,
          package_dir: package_dir,
          start_object: start_object
        }
        //console.error(meta)
      }

      _this.isPackage = true

      let pmeta = {
        package_data: meta.package_data,
        package_dir: meta.package_dir
      }
      
      this.package = new Package(meta)
      await this.package.initialise(_this.requestType, pmeta)
      _this.title = _this.package.title
    } else {
      _this.isPackage = false
      
      _this.module = new Module(meta)
      await _this.module.initialise(_this.requestType)
      _this.title = _this.module.title
    }

    return true
  }

  async getSubscription() {

    return new Promise(function(resolve)
    {

      getUserDataDir('Subscription/' ,function (err, result) {
        let sub = result.val()
      
        sessionStorage.setItem("expired", sub['expired'])
        sessionStorage.setItem("expiry", sub['expiry'])
        sessionStorage.setItem("tier", sub['id'])

        if (sub.hasOwnProperty('license_status')) {
          for (const [key, value] of Object.entries(sub['license_status'])) {
            if (value === 'assigned') {
              sessionStorage.setItem("license_status", 'assigned')
            }
          }
        }
        resolve()
      });
    })

  }

  async next() {
    if (this.isPackage) {
      await this.package.next()
      this.title = this.package.title
    } else {
      await this.module.next()
      this.title = this.module.title
    }
    await this.checkActivity()
  }

  async checkActivity() {
    let _this = this

    const d = new Date();
    let month = d.getUTCMonth() + 1
    let datef = d.getUTCFullYear() + "-" + month + "-" + d.getUTCDate()
    let count = 0
    
    await new Promise(function(resolve)
    {
      getUserDataDir("Activity/" + datef, function (err, handle) {

        if(handle.hasChildren())
        {
          handle.forEach(function (childSnapshot) {
            if (childSnapshot.key == "completed") {
              count = Object.keys(childSnapshot.val()).length
            }
          })
        }

        let subscription_tier = sessionStorage.getItem("tier")
        
        if (count>=5) {
          if (subscription_tier.includes("F")) {

            if (_this.userCategory === 'patient' && sessionStorage.getItem('license_status') == "assigned") {

              if (_this.requestType === "assignment") {
                //do nothing as patient has license by their SLT
              } else {
                _this.context.$router.push({ name: 'subscribe'})                
              }
            } else {
              //SLT or Patient with F tier go here
              //whether it is _this.requestType === "assignment" or not they all go to subscribe page

              _this.context.$router.push({ name: 'subscribe'})
            }

          } else if (subscription_tier === "Trial1") {
            //has super access for limited period so bypass restrictions
            
          } else if (subscription_tier === "T1") {
            if (_this.userCategory === "slp") {
              //do nothing there are no restrictions
            } else {
              //this tier is no assigned anymore
              if (_this.requestType === "assignment") {
                //do nothing as homework is unlimited in T1
              } else {
                //either take to subscription page to upgrade or notify that reached daily training limit
                _this.context.$router.push({ name: 'subscribe'})
              }
            }
          } else if (subscription_tier === "T2") {
            //do nothing as there is no restriction

          } else if (subscription_tier === "T3") {
            //do nothing as there is no restriction

          } else {
            //invalid tier -> take to subscription page
            _this.context.$router.push({ name: 'subscribe'})
          }
        }
        resolve()
      });
    })
  }

  getExForm() {
    let exForm
    
    if (this.isPackage) {
      exForm = this.package.getExForm()
    } else {
      exForm = this.module.exForm
    }

    return exForm
  }

  getShowResults() {
    let showResults

    if (this.isPackage) {
      showResults = this.package.getShowResults()
    } else {
      showResults = this.module.showResults
    }

    return showResults
  }

  getExerciseOn() {
    let exercises_on

    if (this.isPackage) {
      exercises_on = this.package.getExercises_on()
    } else {
      exercises_on = this.module.exercises_on
    }

    return exercises_on
  }

  getResults() {
    let more_exercises_available
    let results_table
    if (this.isPackage) {
      more_exercises_available = this.package.more_exercises_available
      results_table = this.package.results_table
    } else {
      more_exercises_available = this.module.more_exercises_available
      results_table = this.module.results_table
    }

    return {"more_exercises_available": more_exercises_available, "results_table": results_table}
  }
  
  goToResults(goToResultsData)
  {
    let more_exercises_available
    let results_table
    if (this.isPackage) {
      this.package.showResultPage()
      more_exercises_available = this.package.more_exercises_available
      results_table = this.package.results_table
    } else {
      this.module.goToResults(goToResultsData)
      more_exercises_available = this.module.more_exercises_available
      results_table = this.module.results_table
    }

    return {"more_exercises_available": more_exercises_available, "results_table": results_table}
  }

  logExerciseResults(data)
  {
    if (this.isPackage) {
      this.package.logExerciseResults(data)
    } else {
      this.module.logExerciseResults(data)
    }
  }

}