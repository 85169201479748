<template>

  <div v-if="room_meta_reactive.mode.value === 'exercise'" class="row">
    <div class="col-md-10">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <h1>Your patient sees the following picture</h1>
          <br/>
          <div v-if="image" class="mt-4 d-flex flex-row justify-content-around">
            <img :src="image" class="img-fluid rounded shadow" style="height:300px"/>
          </div>
          <div v-else class="mt-4" style="height: 300px;">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <br/>
            <span>Retrieving Picture</span>
          </div>
          <br/>
          <br/>
          <div class="row justify-content-center">
            <div class="col-12 text-start">
              <div class="mb-3">
                <div class="row justify-content-center">
                  <div class="col-4">
                    <label for="heard" class="form-label">Word heard:</label>
                    <input type="text" class="form-control" id="heard" v-model="word_heard">
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-3">
                    <br/>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="used_beginning_of_word">
                      <label class="form-check-label" >
                        Asked for beginning of word (Hint)
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <br/>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="used_hint">
                      <label class="form-check-label" >
                        Used choice between words (Hint)
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <br/>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="used_semantic_cues">
                      <label class="form-check-label" >
                        Used semantic cues
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <br/>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="used_answer">
                      <label class="form-check-label" >
                        Asked for answer
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="comments" class="form-label">Note:</label>
                <textarea class="form-control" id="comments" rows="3" v-model="note"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="card card-body text-center">
        <div v-if="room_meta_reactive.exercise_index.value > 0">
          <button v-if="room_meta_reactive.exercise_index.value + 1 <= room_meta_reactive.total_exercises.value" class="btn btn-info mt-2 w-100" @click="next" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">Next</button>
          <button v-if="room_meta_reactive.exercise_index.value > 1" class="btn btn-secondary mt-2 w-100" @click="back" :disabled="Object.values(room_meta_reactive.users.value).filter(user => user.category === 'patient').length < 1">Back</button>
          <hr/>
          <button class="btn btn-primary mt-2 w-100" @click="end">End session</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Results :room_meta="room_meta_reactive" @exit="exit"/>
  </div>
</template>
  
<script>
  
import { ref, toRefs, onMounted, onUpdated, watch } from 'vue'
import Results from './results.vue'


export default {
  props: ['room_meta'],
  components: {
    Results,
  },
  setup(props, {emit}) {
    const room_meta_reactive = toRefs(props.room_meta)
    const note = ref('')
    const word_heard = ref('')
    const used_hint = ref(false)
    const used_semantic_cues = ref(false)
    const used_answer = ref(false)
    const used_beginning_of_word = ref(false)
    
    const image = ref(null)

    const reset = (event) => {
      if (event === 'end') {
        //do nothing to the image as it is the end
      } else {
        image.value = null
      }


      used_semantic_cues.value = false
      used_answer.value = false
      used_beginning_of_word.value = false 
      used_hint.value = false
      word_heard.value = ''
      note.value = ''
    }
    
    const save = (event) => {
      emit("save", {
          result_type: "session_name_picture", 
          exercise_index: room_meta_reactive.exercise_index.value, 
          payload: {
            note: note.value, 
            word_heard: word_heard.value,
            used_beginning_of_word: used_beginning_of_word.value, 
            used_hint: used_hint.value,
            used_semantic_cues: used_semantic_cues.value,  
            used_answer: used_answer.value, 
          }
        })
      
      reset(event)
    }

    watch(() => room_meta_reactive.exercise_index.value, (new_exercise_index, old_exercise_index) => {

      image.value = room_meta_reactive.blackboard.value.address[0];

      if ('note' in room_meta_reactive.blackboard.value) {
        note.value = room_meta_reactive.blackboard.value.note
      } else {
        note.value = ''
      }

      if ('word_heard' in room_meta_reactive.blackboard.value) {
        word_heard.value = room_meta_reactive.blackboard.value.word_heard
      } else {
        word_heard.value = ''
      }

      if ('used_semantic_cues' in room_meta_reactive.blackboard.value) {
        used_semantic_cues.value = room_meta_reactive.blackboard.value.used_semantic_cues
      } else {
        used_semantic_cues.value = false
      }

      if ('used_answer' in room_meta_reactive.blackboard.value) {
        used_answer.value = room_meta_reactive.blackboard.value.used_answer
      } else {
        used_answer.value = false
      }

      if ('used_beginning_of_word' in room_meta_reactive.blackboard.value) {
        used_beginning_of_word.value = room_meta_reactive.blackboard.value.used_beginning_of_word
      } else {
        used_beginning_of_word.value = false
      }

      if ('used_hint' in room_meta_reactive.blackboard.value) {
        used_hint.value = room_meta_reactive.blackboard.value.used_hint
      } else {
        used_hint.value = false
      }
    });

    onMounted(() => {
      image.value = room_meta_reactive.blackboard.value.address[0];

      if ('note' in room_meta_reactive.blackboard.value) {
        note.value = room_meta_reactive.blackboard.value.note
      } else {
        note.value = ''
      }

      if ('word_heard' in room_meta_reactive.blackboard.value) {
        word_heard.value = room_meta_reactive.blackboard.value.word_heard
      } else {
        word_heard.value = ''
      }

      if ('used_beginning_of_word' in room_meta_reactive.blackboard.value) {
        used_beginning_of_word.value = room_meta_reactive.blackboard.value.used_beginning_of_word
      } else {
        used_beginning_of_word.value = false   
      }

      if ('used_hint' in room_meta_reactive.blackboard.value) {
        used_hint.value = room_meta_reactive.blackboard.value.used_hint
      } else {
        used_hint.value = false
      }

      if ('used_semantic_cues' in room_meta_reactive.blackboard.value) {
        used_semantic_cues.value = room_meta_reactive.blackboard.value.used_semantic_cues
      } else {
        used_semantic_cues.value = false
      }

      if ('used_answer' in room_meta_reactive.blackboard.value) {
        used_answer.value = room_meta_reactive.blackboard.value.used_answer
      } else {
        used_answer.value = false   
      }

    })

    onUpdated(() => {
      //image.value = room_meta_reactive.blackboard.value.address[0];
    })

    const start = () => {
      emit("start")
    }

    const next = () => {
      save("next")
      emit("next")
    }

    const back = () => {
      save("back")
      emit("back")
    }

    const end = () => {
      save("end")
      emit("end")
    }

    const exit = () => {
      emit("exit")
    }

    onUpdated(() => {  
    })
  
    return {
      room_meta_reactive,

      note,
      used_hint,
      used_semantic_cues,
      used_answer,
      used_beginning_of_word,  
      word_heard,

      save,
      start,
      next,
      back,
      end,
      exit,

      image
    }
  }
}
</script>

<style scoped>

.blur {
    font-size: 40px;
    filter: blur(10px);
    -webkit-filter: blur(10px);
  }
</style>
