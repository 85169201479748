<template>
  <div>
    <div class="row">
      <div class="col-md-10">

        <div class="card card-body h-100">

            <span hidden>{{currentSentence_found}}</span><!-- DO NOT COMMENT THIS LINE -->
            
            <div class="row">
                <p v-if="recognition_setting==0" class="lead fs-4">
                    Press Start. Answer the question with Yes or No
                </p>
                <p v-else class="lead fs-4">
                    Answer the question with Yes or No
                </p>
            </div>
            <div class="row" style="height: 20mm;">
                <span :style="interaction_message_color"> {{  interaction_message}}</span> 
            </div>
            <div class="card card-body h-100">
                <p class="h3">{{ question_text }} <img :src="audioImg" style="width:30px;;cursor: pointer;" @click="bv_tts.say(question_text)"></p>
            </div>
            <!-- <div class="row mt-4 justify-content-center">
                <div class="col-6">
                    <img id="img_naming" class="img-thumbnail" v-bind:src="imgSrc"  width="200" height="200" style="object-fit: cover;"> 
                </div>
            </div> -->
            <div class="row mt-4 justify-content-center">

                <div class="col-4">
                    <button class="btn btn-primary btn-lg" @click="showOptions = true">Show choices
                    </button>
                </div>
            </div>
            <div v-if="showOptions" class="row mt-4 justify-content-center align-items-center">
                <!-- <div class="col-8"> -->
                    <button class="custom-button-BV" @click="submit_answer('yes')" style="font-size: xx-large;">Yes <i class="bi bi-hand-thumbs-up"></i>
                    </button>
                    <div class="col-1"></div>
                    <button class="custom-button-BV" @click="submit_answer('no')" style="font-size: xx-large;">No <i class="bi bi-hand-thumbs-down"></i>
                    </button>
                <!-- </div> -->
                
            </div>
          
        </div>
      </div>
      <div class="col-md-2" style="padding-left: 0px">
        <div class="card card-body h-100">
          <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>
          <div v-if="recognition_setting==0">
            <button v-if="!started" class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button>
            <button v-if="started && !congatulationsOnce" class="btn btn-primary btn-lg w-100 mt-2" disabled>
              <div class="spinner-border spinner-border-sm" role="status"></div> Recording...
            </button>
            <button v-if="started && congatulationsOnce" disabled class="btn btn-primary btn-lg w-100 mt-2" @click="playPause()">{{play_pause_text}} <i class="bi bi-play-circle" style="margin-left: 8mm;"></i></button>
          </div>
          <button class="btn btn-info btn-lg w-100 mt-2" @click="nextQuestion()">Next <i class="bi bi-skip-forward" style="margin-left: 8mm;"></i></button>
          <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {LevenshteinDistance,LevenshteinDistanceWord_Phonemes,get_phonetic_transcription,LevenshteinDistanceNoLower} from '@/utils/text_distance_metrics'
import homeplanImg from '@/assets/images/homeplan.png'
import crossImg from '@/assets/images/cross.png'
import doneIcon from '@/assets/images/icon_green_check.png'
import SpeechRec from '@/media_input/SpeechRecognition'
import audioImg from '@/assets/images/audio_logo.png'
import loadingSVG from '@/assets/images/loader.svg'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import words from '@/media_input/words'
import constants from '@/utils/constants'
import {getUserDataDir,getData,updateUserData} from '@/firebase/index'

import {logActivity} from '@/media_input/utils'
import {startConfetti} from '@/common_js/confetti'
import { useTTS } from '@/stores/tts'
import {BV_Points} from '@/utils/bv_points'
import { all } from 'axios'

export default {
    name: 'name_a_picture_easy',
    props: {
      exForm: {
        type: Object,
      },
    },
    data() {
      return {
        fullset:[],
        randomise:false,
        yes_no_order:[],
        yes_no_ex_number:0,
        intermediate_array:{},
        question_number:0,
        total_exercises:10,
        sound_hints:false,
        recognition_setting: 0,
        client_demo: true,
        audioImg:audioImg,
        loadingSVG: loadingSVG,
        diffMarks:{
          0:{
            style:{color: '#000000'},
            label:'Auto'
          },
          1:{
            style:{color: '#000000'},
            label:'Manual'
          },
        },
        question_text: '',
        answer_text_color:'fontSize:24px;color:#F75724;white-space: pre-wrap',
        imgSrc: loadingSVG,
        hints:['It is used to cook','It is made of metal'],
        showHints:false,
        answer_options:['Saucepan','Fridge'],
        showOptions:false,
        showedOptions:false,
        showedAnswer:false,
        first_exercise:true,
        chosen_image: '',
        target_words_phonetics:{},
        single_target_word: '',
        audio_player: null,
        phoneme_single_target_word: '',
        target_img_number: '',
        instructions_msg: '',
        homeplanImg: homeplanImg,
        crossImg: crossImg,
        doneIcon: doneIcon,
        inputAcc:'',
        play_pause_text: 'Start',
        curr_exercise_number: 0,
        number_exercises:1,
        speech_rec: null,
        started: false,
        target_words: ['yes','no'],
        tested_words_phonetics:{},
        currentSentence: '',
        best_answer:'',
        results:{'correct':0,'incorrect':0,'total':0},
        uid: localStorage.getItem('uid'),
        userType: localStorage.getItem('userCategory'),
        region: localStorage.getItem('region'),
        resultsTable: [],
        half_word_url: null,
        retest_count: null,
        is_recording: false,
        congatulationsOnce: false,
        bv_tts: useTTS().tts_engine,
        bv_points: new BV_Points(),
        user_name:'',
        user_loc:'',
        sentence_time_gap:300,
        interaction_message:'',
        interaction_message_color:''
      }
    },
    created() {

      const _this = this

      logActivity(_this.$firebase, 'accessed', 'yes_no_assessment')

      this.speech_rec = new SpeechRec(function(captions) {
        this.currentSentence = captions.original
      }.bind(this))

      this.setup();

      this.setup_target_words_phonetics()

    },
    mounted()
    {
      const _this = this

      
      if(_this.recognition_setting==0)
      {
        let inital_text = 'Press Start. Answer the question with Yes or No.'

        _this.bv_tts.system_say(inital_text) 
      }

    },
    beforeUnmount()
    {    
      this.bv_tts.cancel()
      this.speech_rec.stop()

      clearTimeout(window.countDownTimer)
      clearTimeout(window.countDownTimer2)
    },
    computed: {

      currentSentence_found: async function () {
        if(this.currentSentence!=undefined && this.currentSentence!=null && this.currentSentence!='' && this.started)
        {

          let curr_sentence = this.currentSentence.toString().toLowerCase()

          var regex = /[.,]/g;

          curr_sentence = curr_sentence.replace(regex, '');

          let words = curr_sentence.split(' ')


          for(let i in words)
          {
            let found_something = false

            let minimum_distance = 100
            let best_answer = null

            let word = words[i]


            for(let k in this.target_words)
            {
              let distance = 100

              if(word in this.tested_words_phonetics && this.tested_words_phonetics[word]!=null)
              {

                distance = LevenshteinDistanceNoLower(this.target_words_phonetics[this.target_words[k]],this.tested_words_phonetics[word])
                distance = distance/Math.max(this.target_words_phonetics[this.target_words[k]].length,1)

              }
              else if(word in this.tested_words_phonetics && this.tested_words_phonetics[word]==null)
              {

                distance = LevenshteinDistance(this.target_words[k],word)
                distance = distance/Math.max(this.target_words[k].length,1)

              }
              else
              {
                const promiseArray = []
                promiseArray.push(LevenshteinDistanceWord_Phonemes(this.target_words[k],this.target_words_phonetics[this.target_words[k]],word))
                const all_results = await Promise.all(promiseArray)

                distance = all_results[0]['normalised_distance']
                let phonetic = all_results[0]['phonetic']

                this.tested_words_phonetics[word] = phonetic

              }

    


              if(distance<minimum_distance && distance<0.25)
              {
                best_answer = this.target_words[k]
                this.stop_recording()
                found_something = true
              }
            }
            if(best_answer!=null)
            {   
              if (this.congatulationsOnce == false) {
                this.congatulationsOnce = true
              }


              this.submit_answer(best_answer)
            }
            if(!
            found_something)
            {
              this.found_target_word = 'false'
            }
            else
            {
              return curr_sentence

            }
          }
          return curr_sentence
        }

        return ''

      },
    },
    methods: 
    {
      async setup_target_words_phonetics()
      {
        this.target_words_phonetics = {}

        const promiseArray = []



        for(let k in this.target_words)
        {
          promiseArray.push(get_phonetic_transcription(this.target_words[k]))
        }

        const all_results = await Promise.all(promiseArray)

        for(let k in all_results)
        {
          this.target_words_phonetics[this.target_words[k]] = all_results[k]
        }

        console.debug(this.target_words_phonetics)


      },
      saveResults()
      {
        const _this = this

        if(this.results['correct']!=0 || _this.results['total']!=0)
        {
          this.first_exercise = false
          let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct'], Total:1, Show_Options:_this.showedOptions,Show_Answer:_this.showedAnswer}

          let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['total']-_this.results['correct']}

          let results = {all_results:all_results, plot_results:plot_results}

          var yes_no_data = {resultsTablePre: {exercise_type: 'Yes No Assessment', exercise: 'Yes No Assessment', results: results, audio_address:''}}


          save_pre_computed_results(_this.exForm, _this.$firebase, yes_no_data)

          _this.bv_points.add(10)

          _this.$emit('logExerciseResults', yes_no_data);
        }
      },
      nextExercise()
      {
        const _this = this;
        _this.saveResults()

        _this.$emit('nextExercise');
      },
      goToResults()
      {
        const _this = this

        _this.saveResults()
        let curr_ex_done = false
        if(this.results['total']==1)
        {
          curr_ex_done = true
        }
        let goToResultsData = {curr_ex_done:curr_ex_done}
        _this.$emit('goToResults', goToResultsData);
      },
      listenBeginningSound()
      {
        const _this = this
        _this.sound_hints = true

        this.bv_tts.cancel()


        if(_this.half_word_url!=null)
        {
          _this.playSound(_this.half_word_url)
        }
        else
        {
          _this.listenPhoneme()
        }
      },
      listenPhoneme()
      {
        const _this = this
        let phoneme = _this.phoneme_single_target_word[1]

        let simple_representation_phoneme = ''
        for(let i in constants.phonemeDisplayDictionaryIPA)
        {
          if(constants.phonemeDisplayDictionaryIPA[i]==phoneme)
          {
            simple_representation_phoneme = i
            break
          }
        }
        if(simple_representation_phoneme!='')
        {
          let audio_file_location = '/BV/audio_resources/audio_hints/phonemes/'+simple_representation_phoneme+'.mp3'
          this.playAudio(audio_file_location)
        }
      },
      get_half_word_url(word)
      {
        let audio_file_location = '/BV/audio_resources/audio_hints/half_words/'+word.toLowerCase()+'.mp3'

        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.half_word_url = url
        })
        .catch((error) => {
          console.debug('error ' + error )
        });
      },
      playAudio(audio_file_location)
      {
        const _this = this;
        const storage = _this.$firebase.storage();

        storage.ref(audio_file_location).getDownloadURL()
        .then((url) => {
          _this.playSound(url);
        })
        .catch((error) => {
          console.debug('error ' + error )
        });      
      },
      playSound(url) {
        const _this = this
        if(_this.audio_player==null)
        {
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
        else
        {
          if(!_this.audio_player.paused)
          {
            _this.audio_player.pause();
          }
          _this.audio_player = new Audio(url);
          _this.audio_player.play();
        }
      },
      setup()
      {
        let _this = this;

        _this.question_text = '';
        _this.fullset = []

        _this.first_exercise = !_this.exForm.has_results

        _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
        _this.total_exercises = _this.exForm.total_all_type_exercises


        if(this.curr_exercise_number==1)
        {

          this.randomise = true
        }
        else
        {

          this.yes_no_order = sessionStorage.getItem('yes_no_order');
          this.yes_no_ex_number = sessionStorage.getItem('yes_no_ex_number');


          if(this.yes_no_order==undefined || this.yes_no_ex_number==undefined || this.yes_no_order==null || this.yes_no_ex_number==null)
          {

            this.yes_no_order = []
            this.yes_no_ex_number = 0
            this.randomise = true
          }

          if(Number(this.yes_no_ex_number)>9)
          {
            this.yes_no_ex_number = 0
            this.randomise = true
            this.yes_no_order = []

          }
        }







        let client_demo = _this.exForm.client_demo


        if(client_demo!=undefined && client_demo!=null)
        {
            _this.client_demo = client_demo
        }

        let uid = localStorage.getItem('uid');


        let target_dir = 'Users/' + uid + '/Data/'

        let userCategory = localStorage.getItem('userCategory');

        if(userCategory!=undefined && userCategory!=null && userCategory === 'slp')
        {
          const client_uid = localStorage.getItem('client_uid')

          if(client_uid!=undefined && client_uid!=null)
          {
            target_dir = 'Users/' + client_uid + '/Data';

          }

        }
        



        getData(target_dir , function (err, result) {
            _this.user_name = result.val()['Firstname'];
            _this.user_loc = localStorage.getItem('region')

            if(_this.user_loc==undefined || _this.user_loc==null)
            {
              _this.user_loc = 'UK'
            }

            let do_once = true
            let setting_address = '/Exercises/Settings/Yes_no/'
            getData(setting_address, function (err, result) {

            if(do_once)
            {
                do_once = false

                let setting_data = result.val()

                for(let i in setting_data)
                {
                    let q_topic = setting_data[i].topic
                    
                    _this.createQuestions(q_topic)
                    // let shuffle_array = qs_per_topic.sort(() => Math.random() - 0.5)
                }
            }
            })
        });

       
      },
      nextQuestion()
      {
        const _this = this
        this.question_number = this.question_number+1
        _this.showOptions = false
        _this.interaction_message = ''
        // if(this.question_number>=this.fullset.length)
        if(true)
        {
          sessionStorage.setItem('yes_no_ex_number', this.question_number)


          this.nextExercise()


        }
        else
        {
          this.saveResults()
          this.results['correct']=0 
          this.results['total']=0
          this.results['incorrect']=0

          this.question_text = this.fullset[this.question_number].question

          if(_this.recognition_setting==0)
          {
            this.bv_tts.cancel()
            let inital_text = 'Press Start. Answer the question with Yes or No.'
            // this.bv_tts.say_sentences([inital_text,this.question_text],this.sentence_time_gap)
            _this.bv_tts.system_say(_this.question_text) 
          }

        }

      },
      check_fullset()
      {

        if('names' in this.intermediate_array && 'location' in this.intermediate_array && 'fact' in this.intermediate_array)
        {

            this.fullset = []


            if(this.randomise)
            {

              this.yes_no_order = []

              let temp_names = this.randomise_array(this.intermediate_array['names'])
              this.fullset = this.fullset.concat(temp_names.shuffled_array)
              this.yes_no_order = this.yes_no_order.concat(temp_names.shuffled_ids)

              let temp_location = this.randomise_array(this.intermediate_array['location'])
              this.fullset = this.fullset.concat(temp_location.shuffled_array)
              this.yes_no_order = this.yes_no_order.concat(temp_location.shuffled_ids)


              let temp_fact_yes = this.randomise_array(this.intermediate_array['fact_Yes'])

              let full_fact_array = []
              full_fact_array = full_fact_array.concat(this.intermediate_array['fact_Yes'])
              full_fact_array = full_fact_array.concat(this.intermediate_array['fact_No'])

              let temp_fact_ids = []


              for(let k=0;k<3;++k)
              {
                temp_fact_ids.push(temp_fact_yes.shuffled_ids[k])
              }


              let temp_fact_no = this.randomise_array(this.intermediate_array['fact_No'])



              for(let k=0;k<3;++k)
              {
                temp_fact_ids.push(temp_fact_no.shuffled_ids[k]+temp_fact_yes.shuffled_ids.length)

              }


              let temp_shuffle = this.randomise_array(temp_fact_ids)

              temp_fact_ids = temp_shuffle.shuffled_array


              let temp_fact_shuffled_array = []


              for(let k in temp_fact_ids)
              {
                temp_fact_shuffled_array.push(this.intermediate_array['fact'][temp_fact_ids[k]])
              }

              // let temp_fact = this.randomise_array(this.intermediate_array['fact'])
              this.fullset = this.fullset.concat(temp_fact_shuffled_array)
              this.yes_no_order = this.yes_no_order.concat(temp_fact_ids)


              sessionStorage.setItem('yes_no_order', this.yes_no_order)
              sessionStorage.setItem('yes_no_ex_number', 0)

              let temp = sessionStorage.getItem('yes_no_order')


            }
            else
            {




              this.yes_no_order = this.yes_no_order.split(',').map(function(item) {
                  return parseInt(item, 10);
              });


              for(let n =0;n<this.intermediate_array['names'].length;++n)
              {

                this.fullset.push(this.intermediate_array['names'][this.yes_no_order[n]])
              }

              this.question_number = Number(this.yes_no_ex_number)



              let array_pre_size = this.fullset.length

              for(let n =0;n<this.intermediate_array['location'].length;++n)
              {
             

                this.fullset.push(this.intermediate_array['location'][this.yes_no_order[n+array_pre_size]])
              }


              array_pre_size = this.fullset.length

              


              for(let n =0;n<6;++n)
              {
                this.fullset.push(this.intermediate_array['fact'][this.yes_no_order[Number(n)+Number(array_pre_size)]])
              }


            }
            


            this.question_text = this.fullset[this.question_number].question

            let inital_text = 'Press Start. Answer the question with Yes or No.'
            this.bv_tts.say_sentences([inital_text,this.question_text],this.sentence_time_gap)

            this.$forceUpdate()




        }

      },
      randomise_array(array)
      {
        var ids = [];
        for (var i = 0; i < array.length; i++) {
            ids.push(i);
        }
        let shuffled_ids = ids.sort(() => Math.random() - 0.5);

        let shuffled_array = []

        for(let k in shuffled_ids)
        {
          shuffled_array.push(array[shuffled_ids[k]])
        }

        return {'shuffled_ids':shuffled_ids,'shuffled_array':shuffled_array}



      },
      createQuestions(topic)
      {
        let _this = this
        let output_arr = []

        if(topic == 'name')
        {
            let do_once = true

            let name_address = '/Exercises/Resources/Human_names/'
            getData(name_address, function (err, result) {

            if(do_once)
            {
                do_once = false

                let name_data = result.val()

                let user_name_firstchar = _this.user_name.substring(0,1)
                delete name_data[user_name_firstchar]

                let available_name_firstchar = Object.keys(name_data)
                let rand_char_id = Math.floor(Math.random() * available_name_firstchar.length)
                let available_names = name_data[available_name_firstchar[rand_char_id]]

                let rand_name_id = Math.floor(Math.random() * available_names.length)

                let right_name_q = "Is your name " + _this.user_name+"?"
                let wrong_name_q = "Is your name "+ available_names[rand_name_id]+"?"
                output_arr.push({question: right_name_q,answer:"Yes"})
                output_arr.push({question: wrong_name_q,answer:"No"})


                // let shuffle_array = output_arr.sort(() => Math.random() - 0.5)



                _this.intermediate_array['names'] = output_arr

                _this.check_fullset()

            }
            })
            
        }
        if(topic == 'location')
        {

            let right_loc_q = "Do you live in the " + _this.user_loc+"?"
            let wrong_loc_options = ['China','Japan','Finland','South Africa','Thailand','Australia']
            let rand_loc_id = Math.floor(Math.random() * wrong_loc_options.length)
            let wrong_loc_q = "Do you live in "+ wrong_loc_options[rand_loc_id]+"?"
            output_arr.push({question: right_loc_q,answer:"Yes"})
            output_arr.push({question: wrong_loc_q,answer:"No"})
            // let shuffle_array = output_arr.sort(() => Math.random() - 0.5)

            _this.intermediate_array['location'] = output_arr

            _this.check_fullset()


        }
        if(topic == 'fact') //3 right, 3 wrong facts
        {
            let do_once = true

            let fact_address = '/Exercises/Resources/Simple_facts/'
            getData(fact_address, function (err, result) {

            if(do_once)
            {
                do_once = false

                let fact_data = result.val()

                // let selected_yes_facts = _this.rand_arr_multi_selection(fact_data['Yes'],3)
                // let selected_no_facts = _this.rand_arr_multi_selection(fact_data['No'],3)

                let selected_yes_facts = []

                let selected_no_facts = []

                for(let n = 0;n <fact_data['Yes'].length;n++)
                {
                  selected_yes_facts.push({question: fact_data['Yes'][n],answer:"Yes"})
                }
                for(let n = 0;n <fact_data['No'].length;n++)
                {
                  selected_no_facts.push({question: fact_data['No'][n],answer:"No"})
                }

                // let shuffle_array = output_arr.sort(() => Math.random() - 0.5)

                // console.debug('shuffle_array[0] = ' + shuffle_array[0].question)


                _this.intermediate_array['fact_Yes'] = selected_yes_facts

                _this.intermediate_array['fact_No'] = selected_no_facts

                _this.intermediate_array['fact'] = selected_yes_facts

                _this.intermediate_array['fact'] = _this.intermediate_array['fact'].concat(selected_no_facts)


    


                _this.check_fullset()

            }
            })
            
        }

      },
      rand_arr_multi_selection(arr,selection_num)
      {
        let output_arr = []
        let copy_arr = [...arr]
        for(let i = 0;i<selection_num;i++)
        {
            let rand_id = Math.floor(Math.random() * copy_arr.length)
            output_arr.push(copy_arr[rand_id])
            copy_arr.splice(rand_id,1)
        }
        return output_arr
      },
      playPause()
      {
        let _this = this;

        _this.play_status = true;
        _this.resumeRecording()
      },
      resumeRecording()
      {
        let _this = this;
        _this.is_recording = true;
        _this.congatulationsOnce = false;
        _this.start_recording()
      },
      submit_answer(answer)
      {
        let correct_answer = this.fullset[this.question_number].answer.toLowerCase()

        if(correct_answer===answer)
        {
          this.interaction_message = 'Correct'
          this.interaction_message_color = 'font-size:xx-large;color:green;white-space: pre-wrap'

          if(this.results['total']==0)
          {
            this.results['total'] = 1
            this.results['correct'] = this.results['correct']+1
          }
          
          startConfetti(this)
        } else {
          this.interaction_message = 'Not quite right'

          this.interaction_message_color = 'font-size:xx-large;color:red;white-space: pre-wrap'
          if(this.results['total']==0)
          {
            this.results['total'] = 1
            this.results['incorrect'] = this.results['incorrect']+1
          }
        }

        this.bv_tts.system_say(this.interaction_message, this.nextQuestion) 

      },
      start_recording() {
        const _this = this
        this.bv_tts.cancel()
        this.speech_rec.start(this.target_words,false,this.region)
        this.started = true
        this.play_pause_text = 'Recording'

        clearTimeout(window.countDownTimer)

        clearTimeout(window.countDownTimer2)
        
        // window.countDownTimer2 = setTimeout(() => {
        //   _this.answer_text = 'It is recording now, name the picture out loud'
        //   _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        // }, 500)
          
        // window.countDownTimer = setTimeout(() => {
        //   if(_this.play_status)
        //   {
        //     _this.started = false
        //     _this.play_pause_text = 'Start'

        //     _this.answer_text = "Oops we missed what you said! Press Start and try again"
        //     _this.answer_text_color = 'fontSize:24px;color:blue;white-space: pre-wrap'
        //   }
        //   _this.stop_recording()
        // }, 45000)
      },
      stop_recording() {
        const _this = this
        _this.play_status = false;
        this.started = false
        this.play_pause_text = 'Start'

        _this.currentSentence = ''
        _this.speech_rec.stop()
      },
      found_correct_word(curr_sentence)
      {
        const _this = this

        _this.results['total'] = 1

        _this.best_answer = curr_sentence

        _this.answer_text = 'Congratulations! You said the right word: ' + curr_sentence
        _this.answer_text_color = 'fontSize:24px;color:green;white-space: pre-wrap'
        _this.play_pause_text = 'Start'


        _this.results['correct'] = 1
        _this.results['incorrect'] = 0

        clearTimeout(window.countDownTimer)
        clearTimeout(window.countDownTimer2)
      },
      manualAnswer(is_correct)
      {
        const _this = this
        _this.results['total'] = 1

        if(is_correct)
        {
          _this.results['correct'] = 1
          _this.results['incorrect'] = 0
        }
        else
        {
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
        }
        _this.nextExercise()
      },
      showAnswer()
      {
        let _this = this;
        _this.showOptions = true
        _this.answer_text = 'The answer is '+_this.single_target_word;
        _this.answer_text_color = 'fontSize:24px;color:blueviolet;white-space: pre-wrap'

        if(_this.results['correct'] == 0)
        {
          _this.showedAnswer = true
          _this.results['correct'] = 0
          _this.results['incorrect'] = 1
          _this.results['total'] = 1
        }
      },
      pause()
      {
        let _this = this;
        _this.play_status = false;
        _this.play_pause_text = 'Start'
      },
    }
}
</script>

<style scoped>
.custom-button-BV
{
    color: white;
    background-color: #195e52;
    border-color: transparent;
    height: 20mm;
    width: 35mm;
    border-radius: 8px
}
</style>
