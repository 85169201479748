import TraceKit from 'tracekit';
import firebase from 'firebase/app'
import 'firebase/database'



TraceKit.report.subscribe((errorReport) => {
  console.error('TraceKit caught an error:', errorReport);
  // You can send this errorReport to your server or logging service
  const uid = localStorage.getItem('uid')
  if (!uid) {
    return
  } 
  
  let timestamp = Math.floor(Date.now() / 1000);
  const logRef = firebase.database().ref('System_Logs').child('Telemetry').child('Error_Entries').child(uid);
  logRef.update({[timestamp]:{
    error: errorReport.message || 'Unknown error',
    stack: errorReport.stack,
    url: window.location.href,
    timestamp: new Date().toISOString(),
  }});

});

// Define the TraceKit plugin
const tracekitPlugin = {
  install(app) {
    // Set up global error handling with TraceKit
    window.onerror = (message, source, lineno, colno, error) => {
      TraceKit.report(error || message);
      return true; // Prevents the default browser error handling
    };
  }
};

// Export the plugin
export default tracekitPlugin;