<template>
<div>
  <div class="row">
    <div class="col-md-10">
      <div class="card card-body h-100">
        <div class="row">
          <h4 v-if="!soundRecorded">
            {{question_instructions}}
          </h4>
          <h4 v-else>
            {{options_instructions}}
          </h4>
        </div>
        <div class="row m-2">
          <div class="card card-body">
            <div class="row text-center">
              <p class="h2">{{question}}</p>
            </div>      
            <div v-if="!soundRecorded" class="row justify-content-center mt-2" id="meter-row" >
              <div class="row justify-content-center" id="meter-row">
                <div class="col-6">
                  <canvas id="meter" height="30"></canvas>
                </div>
              </div>
            </div>
            <div class="row justify-content-end mt-4" v-if="soundRecorded">
              <div class="col-8">
                <div class="input-group">
                  <div class="form-check">
                    <label class="form-check-label fs-4" for="0" v-bind:style="quiz_choice===null || (choiceA!=correct_answer && choiceA!=quiz_choice) ? {backgroundColor:'#FFFFFF'} : choiceA === correct_answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}" >{{choiceA}}</label>
                    <input class="form-check-input" type="radio" v-model="quiz_choice" id="0" :value="choiceA" @change="selected()" style="font-size: 18px;">
                  </div>
                </div>
                <div class="input-group">
                  <div class="form-check">
                    <label class="form-check-label fs-4" for="1" v-bind:style="quiz_choice===null || (choiceB!=correct_answer && choiceB!=quiz_choice) ? {backgroundColor:'#FFFFFF'} : choiceB === correct_answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}" >{{choiceB}}</label>
                    <input class="form-check-input" type="radio" v-model="quiz_choice" id="1" :value="choiceB" @change="selected()" style="font-size: 18px;">
                  </div>
                </div>
                <div class="input-group">
                  <div class="form-check">
                    <label class="form-check-label fs-4" for="2" v-bind:style="quiz_choice===null || (choiceC!=correct_answer && choiceC!=quiz_choice) ? {backgroundColor:'#FFFFFF'} : choiceC === correct_answer ? {backgroundColor:'rgb(127,255,0)'} : {backgroundColor:'rgb(255,160,122)'}" >{{choiceC}}</label>
                    <input class="form-check-input" type="radio" v-model="quiz_choice" id="2" :value="choiceC" @change="selected()" style="font-size: 18px;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2" style="padding-left: 0px">
      <div class="card card-body h-100">
        <p class="lead">Exercise {{curr_exercise_number}} of {{total_exercises}}</p>

        <button v-if="!soundRecorded && !is_recording" class="btn-primary btn btn-lg w-100 mt-2" @click="recordAudio()">{{record_audio_txt}} </button>
        <button v-if="!soundRecorded && is_recording" class="btn-success btn btn-lg w-100 mt-2" @click="recordAudio()">{{record_audio_txt}} </button>

        <button v-if="soundRecorded" class="btn-info btn btn-lg w-100 mt-2"  @click="nextQuestion()">Next </button>
        <button v-if="!first_exercise" class='btn btn-outline-warning w-100 btn-lg mt-4' @click="goToResults()">See your results</button>

      </div>
    </div>
  </div>
</div>
</template>
<script>

import {updateDataDirectory, getData } from '@/firebase/index'
import {checkPermissionsMedia, recordAudioMedia, stopRecordingMedia, deleteSoundMeter } from '@/media_input/microphone_recording'
import {logActivity} from '@/media_input/utils'
import {save_pre_computed_results} from '@/utils/results/data_save_utils'
import { BV_Points } from '@/utils/bv_points'
import {generate_results_path} from '@/utils/results/data_save_utils'
import { useToast } from "vue-toastification";
import FuzzySet from 'fuzzyset'

/*


{
   "name":" What is the name of the famous river in Egypt?/ What is the name of the ancient civilization centered in the Nile Delta region in Egypt?/ What is the name of the most famous pyramid of Egypt?/ Who is believed to be the first pyramid constructor in Egypt?/ What was the principal form of writing used in ancient Egypt?/-0",
   "instruct":"Press Start. Read the following sentence with a loud and clear voice and speech. Press Next.",
   "assist":[
      "spm_live"
   ],
   "voicetype":[
      "spm"
   ],
   "speechtype":[
      "sentencegibility"
   ],
   "module_name":"Pub Quiz",
   "curr_exercise_path":"pub_quiz/",
   "assessment_type":"SLP_TRAINING",
   "exercise_type":"quiz",
   "question":" What is the name of the famous river in Egypt?",
   "answer":" Nile",
   "options":[
      " Nile",
      " Ganges",
      " Euphrates"
   ],
   "today":"2023-07-11 16:55",
   "plan_name":"Pub Quiz",
   "has_results":false,
   "current_exercise_index":0,
   "number_exercises":5
}

*/
export default {
  props: {
    exForm: {
      type: Object,
    },
  },
  name: 'Pub_Quiz',
  data() {
    return {
      userCategory:'',
      week_info:'',
      question_instructions:'Press Record. Read out the question below to reveal the options',
      options_instructions:'Now pick your answer from the options. Press Next.',
      curr_exercise_number:1,
      number_exercises:1,
      userType:'',
      first_exercise:false,
      soundRecorded:false,
      choiceA:'testA',
      choiceB:'testB',
      choiceC:'testC',
      question:'test description',
      final_answer:null,
      quiz_choice: null,
      audio_player: null,
      uid: localStorage.getItem('uid'),
      audio_file_location: '',
      is_recording: false,
      record_audio_txt: 'Record',
      last_loudness: 0,
      total_loudness: 0,
      counter_loudness: 0,
      resultsTableBack: [],
      modules_results_path: null,
      sound_l:'',
      sound_l2:'',
      avg_loudness: 0,
      avg_loudness_show: 0,
      recordingFinished:false,
      recordAgain:false,
      save_audio_file_location: '',
      bv_points: new BV_Points(),
      region:'UK'
    }
  },
  created() {

    const _this = this
    checkPermissionsMedia();

    _this.userCategory = localStorage.getItem('userCategory');

    let region = localStorage.getItem('region');
    
    if(region!=undefined && region!=null)
    {
      _this.region = region
    }

    
    _this.first_exercise = !_this.exForm.has_results

    logActivity(_this.$firebase, 'accessed', 'quiz_game')

    _this.loadQuestion();
    // this.processGameRoomInput();
  },
  beforeUnmount()
  {
    deleteSoundMeter()
  },
  methods: {
    selected()
    {
      let _this = this
      this.final_answer = this.quiz_choice


      let avg_loudness_array = []

      if(_this.avg_loudness>0)
      {
        avg_loudness_array.push(_this.avg_loudness)
      }

      var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
     
      _this.$emit('logExerciseResults', basicData);

      if(_this.quiz_choice!=null)
      {

        let correct = 0
        let incorrect = 1
        if(_this.final_answer===_this.correct_answer)
        {
          correct = 1
          incorrect=0

        }

        console.debug('correct = ' + correct)
      

        let all_results = {Correct:correct,Incorrect:incorrect, Total:1}
        let plot_results = {Correct:correct,Incorrect:incorrect,Total:1}

        let results = {all_results:all_results, plot_results:plot_results}

        var quiz_data = {resultsTablePre: {exercise_type: 'Pub Quiz', exercise: 'Pub Quiz', results: results, audio_address:''}}


        save_pre_computed_results(_this.exForm,_this.$firebase,quiz_data)

        _this.bv_points.add(10)

        _this.$emit('logExerciseResults', quiz_data);
      }
    },
    goToResults()
    {
      const _this = this

      var goToResultsData = {curr_ex_done:false}

      if(_this.is_recording)
      {
        _this.last_loudness = _this.avg_loudness

        let avg_loudness_array = []

        if(_this.avg_loudness>0)
        {
          avg_loudness_array.push(_this.avg_loudness)
        }

        _this.avg_loudness = 0;
        _this.total_loudness = 0;
        _this.counter_loudness = 0;

        if(_this.is_recording)
        {
          _this.is_recording = false

          _this.endRecordingAudio(true)

        }

        var basicData = {resultsTableBack: _this.resultsTableBack, avg_loudness_array: avg_loudness_array}
        goToResultsData.curr_ex_done = true

        _this.$emit('logExerciseResults', basicData);
      }

      _this.$emit('goToResults', goToResultsData);

      if(_this.quiz_choice!=null) { //this is when user has made a selection so when they go to results and do more exercises they won't revisit the same question/exercise.
        _this.nextQuestion()
      }

    },
    nextQuestion()
    {
      const _this = this


      _this.$emit('nextExercise');
      
      _this.hasTimer = false;
    },
    loadQuestion()
    {
      const _this = this

      _this.curr_question = _this.exForm
     
      _this.soundRecorded = false

      _this.curr_exercise_number = _this.exForm.current_exercise_index + 1
      _this.total_exercises = _this.exForm.total_all_type_exercises



      _this.choiceA = _this.curr_question['options'][0].trim()
      _this.choiceB = _this.curr_question['options'][1].trim()

      _this.choiceC = _this.curr_question['options'][2].trim()
      _this.correct_answer = _this.curr_question['answer'].trim()
      
      
      let no_matching = true

      for(let n in _this.curr_question['options'])
      {

        
        if(_this.curr_question['options'][n].trim()===_this.correct_answer)
        {
          no_matching = false
        }
      }

      if(no_matching)
      {

        let a = FuzzySet([_this.correct_answer]);

        let best_index = 0
        let best_value = 0

        for(let n in _this.curr_question['options'])
        {
          let value = a.get(_this.curr_question['options'][n].trim(),null)


          if(value!=undefined && value!=null)
          {
            value = value[0][0]

            if(value>best_value)
            {
              best_index = n
              best_value = value
            }

          }

        }

        _this.correct_answer = _this.curr_question['options'][best_index].trim()


      }
      
      _this.question = _this.curr_question['question'].trim()

      let correct = [false,false,false,false]

      if(_this.correct_answer==_this.choiceA)
      {
        correct[0] = true
      }
      else if(_this.correct_answer==_this.choiceB)
      {
        correct[1] = true
      }
      else if(_this.correct_answer==_this.choiceC)
      {
        correct[2] = true
      }


    },
    roundNumber(number,number_of_decimals)
    {   
      let rounded_number = Number(Math.round(number+"e"+number_of_decimals)+"e-"+number_of_decimals)

      return rounded_number;

    },
    runTimer() {
      

      var interval_time = 250;

      const _this = this;

      clearInterval(window.refreshIntervalId)


      window.refreshIntervalId = setInterval(function () {

      if (soundMeter != null) {

          
        let element = document.getElementById("meter")

        // if(element!=null)
        {

          // var sound_l = soundMeter.instant.toFixed(2) ;
          var sound_l = soundMeter.db.toFixed(2);
          _this.sound_l2 = soundMeter.db2.toFixed(2);


          // let sound_calibration = 20;



          let sound_threshold = 40;
          

          if(sound_l>sound_threshold)
          {
          
            _this.avg_loudness = _this.avg_loudness*(_this.counter_loudness/(_this.counter_loudness+1)) + (sound_l)/(_this.counter_loudness+1);
            
            _this.avg_loudness_show = _this.roundNumber(_this.avg_loudness,0)

            console.debug('_this.avg_loudness0 = ' + _this.avg_loudness)


            _this.counter_loudness += 1;
          }

          console.debug('here')

          _this.canvasContext = element.getContext("2d");
          _this.drawLoop(sound_l);       
        
        }

      }

      }, interval_time);
    

    },
    drawLoop(sound_l) {
      // clear the background

      const _this = this;


      var WIDTH = 400;//document.getElementById('meter').clientWidth;
      var HEIGHT = 30;//document.getElementById('meter').clientHeight;;

      var green_threshold = 50;

      // console.debug("Width = " + WIDTH);
      
      // console.debug("HEIGHT = " + HEIGHT);

      _this.sound_l = sound_l;



      if(_this.canvasContext != undefined )
      {
        _this.canvasContext.clearRect(0, 0, WIDTH, HEIGHT);
      
        if (sound_l > green_threshold) {
          _this.canvasContext.fillStyle = "green";

          // if(_this.silence_flag)
          // {
          //   _this.Repeat_Counter = _this.Repeat_Counter + 1;

          //   _this.silence_flag = false;

          //   if(_this.Repeat_Counter>=1)
          //   {
          //     _this.Repeat_Counter =0;
          //     _this.getNextExercise();
          //   }
          // }

        }
        else {

          // _this.silence_flag = true;

          _this.canvasContext.fillStyle = "red";
        }
        _this.canvasContext.fillRect(0, 0, sound_l /90.0 * WIDTH , HEIGHT);
                // _this.canvasContext.fillRect(0, 0,  WIDTH , HEIGHT);


      }

    // draw a bar based on the current volume


    },

    recordAudio()
    {
      var _this = this;
      _this.nextGame = false;

      if(_this.recordingFinished)
      {
        _this.recordAgain = true;
        _this.recordingFinished = false;
      }

      if(_this.is_recording)
      {
        clearInterval(window.refreshIntervalId)

        _this.stopRecordingAudio();


      }
      else
      {

        const toast = useToast();
        toast.info("Recording has started.");

        _this.record_audio_txt = 'Show options';

        _this.runTimer()


        recordAudioMedia();
        _this.is_recording = true;
      }
    },
    
    stopRecordingAudio()
    {
    
      const _this = this;
      _this.is_recording = false;
      _this.record_audio_txt = 'Record';
      _this.soundRecorded = true;
      _this.recordingFinished = true;


      if(true)
      {

        console.debug('curr_exercise_path = ' + _this.exForm.curr_exercise_path)


        let results_addresses = generate_results_path(this.exForm)

        let random_identifier = results_addresses['random_identifier']

        let exercises_results_path = results_addresses['exercises_results_path']
        let modules_results_path = results_addresses['modules_results_path']



        let audio_folder = _this.uid + '/' + _this.exForm.curr_exercise_path + random_identifier;

        if (localStorage.getItem('client_uid') && localStorage.getItem('client_uid') !== '') {
          audio_folder = localStorage.getItem('client_uid') + '/' + _this.exForm.curr_exercise_path + random_identifier;
        }

        var audio_address = 'audios/' + audio_folder + '.mp3';

        audio_address = audio_address.replaceAll('//','/')


        console.debug("Saving " + audio_address);


        
        let today = _this.exForm.today
        
        _this.exForm.module_name = _this.exForm.module_name.replaceAll('/','')

        updateDataDirectory(modules_results_path, {plan_name:_this.exForm.module_name, assessment_type: _this.exForm.assessment_type})

        let name = _this.exForm.question


        let push_structure = {
          'date': today,
          'name': name,
          'path': exercises_results_path, 
          'audio_address': audio_address,
          'uid':_this.uid,
          'loudness': _this.avg_loudness,
          'localisation': _this.region
        }

        let ground_truth = _this.question

        if(ground_truth!=undefined && ground_truth!=null)
        {
          ground_truth = ground_truth.replaceAll('\n','')
        } else{
          ground_truth = ''
        }


        push_structure['sentencegibility'] = ground_truth

        console.debug('push structure = ' + push_structure)
        _this.resultsTableBack.push(exercises_results_path)
        _this.modules_results_path = modules_results_path
        console.debug('path = ' + modules_results_path)
        // _this.$firebase.database().ref('ProcessRequest').push(push_structure)
        stopRecordingMedia(audio_address, push_structure, _this);
      }
      else
      {
          stopRecordingMedia(null,null,null);
      }            


      this.is_recording = false;

    }, 
  }

}
</script>
<style scoped>

</style>


