import { defineStore } from 'pinia'
import { BV_TTS } from '@/utils/tts/bv_tts'

export const useTTS = defineStore('TTS', {
  state: () => {
    return { tts_engine: new BV_TTS() }
  },
  actions: {

  },
});