<template>
  <div class="row mt-2">
    <div class="card card-body" >
      <div>
        <p class="h4 text-start">Interactive Session Phonation</p>
        <p v-if="session_slt_id">Session SLT ID: {{ session_slt_id }}</p> 
      </div>
      <table class="table mt-4">
        <thead>
          <tr>
            <th scope="col">Exercise</th>
            <th scope="col">Loudness (dB)</th>
            <th scope="col">Mean F0</th>
            <th scope="col">Std Dev F0</th>
            <th scope="col">Jitter (%)</th>
            <th scope="col">Shimmer (%)</th>
            <th scope="col">Note</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in results['jittershimmerTable']" v-bind:key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ row.loudness<50?'<50':row.loudness }}</td>
            <td>{{ row.meanF0 }}</td>
            <td>{{ row.stdevF0 }}</td>
            <td>{{ row.localJitter }}</td>
            <td>{{ row.localShimmer }}</td>
            <td>{{ isession_phonation[index]?.note != null ? isession_phonation[index]['note']['note'] : '' }}</td>
            <td>
              <Audio_Playback :audio_address="row['AudioAddress']"></Audio_Playback>
            </td>
          </tr>
          
        </tbody>
      </table>
      <div class="row mt-2">
        <span>Typical value ranges</span>
      </div>
      <table class="table mt-4">
        <thead>
          <tr>
            <th scope="col">Jitter (%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in voiceNormTable" v-bind:key="index">
            <td>{{ row.localJitter }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>

import {ref, onUpdated, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import {getData} from '@/firebase/index'
import firebase from 'firebase/app'
import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
    name: 'isession_phonation',
    components: {
      Audio_Playback
    },
    props: {
      results: {
        type: Object,
        default: null
      },
    },
    emits: ['is_isession'],
    setup(props, {emit}) {

      const voiceNormTable = [{
        localJitter : '0-1.2',
        localShimmer : '2.8-6.3'
      }]

      onMounted( () => {

      });

      const isession_phonation = ref([])
      const session_slt_id = ref(null)
      

      const route = useRoute()
      let root_path = JSON.parse(route.query.root_path) //list of exercises paths that have audio address
      let module_name = route.query.module_name
      
      //these are for interactive session
      
      getData(root_path, function (err, snapshot) {
        let module_object = snapshot.val()
        if ('session_slt_id' in module_object[module_name]) {
          session_slt_id.value = module_object[module_name]['session_slt_id']
        }
        if (module_object[module_name].hasOwnProperty('exercises') && typeof module_object[module_name]['exercises'][Symbol.iterator] === 'function') {

          for (let exercise_data of module_object[module_name]['exercises']) {

            if (exercise_data) {

              if('session_phonation' in exercise_data) {
                console.error(exercise_data['session_phonation'])

                let data = {note: ''}
                if (exercise_data['session_phonation'].hasOwnProperty('note')) {
                  let note = exercise_data['session_phonation']['note']
                  data = {note: note}
                } 

                isession_phonation.value.push(data)

              }
            }
          }
        }
        if (isession_phonation.value.length > 0) {
          emit('is_isession', true)
        }
      })

    
      return {
        session_slt_id,
        voiceNormTable,
        isession_phonation
      };

    }
  }
</script>