<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar></Navbar>
    <div class="container">
      <div class="row mb-4">
        <div class="border rounded-1" style="background: rgba(221, 160, 221, 0.5);;">
          <div class="d-flex justify-content-between align-items-center">
            <i class="bi bi-info-circle-fill fs-3" style="color:rebeccapurple"></i>
            <div>
              <p class="mb-0">You are on the patient's view dashboard. <button class="btn btn-sm btn-primary rounded-5" style="cursor: pointer;" @click="$router.push({ name: 'home' });">exit view</button></p>
            </div>
            <div>

            </div>
          </div>
        </div>
      </div>

      <Patient_dashboard ></Patient_dashboard>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>

import Patient_dashboard from '../../patient/dashboard.vue'
import Navbar from '@/views/Navbar.vue'
import Footer from '@/views/Footer.vue'

export default {
  components: {Patient_dashboard, Navbar, Footer},

  data() {
    return {
      userCategory: localStorage.getItem("userCategory")
    }
  },
  created() 
  {
    sessionStorage.setItem("view_mode", "demo")
  },
  methods: 
  {
  },
}
</script>
<style scoped>

li:hover {
  background-color: #e0e0e2;
  color: white;
}

</style>