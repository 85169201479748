<template>
  <div v-if="mic_permission_allowed === 'false'">
    <MicPermission @response="(rtn) => mic_permission_allowed = rtn" />
    </div>
  <div v-else>

    <Navbar :title="title" :enableBack=true></Navbar>
    <div class="d-flex flex-column min-vh-100">

      <div class="container-fluid text-center">

        <div v-if="!showResults" style="width:100%;height:100%;margin-top:0%">      
          <div v-if="exercises_on['scene_description']">
            <Scene_description
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Scene_description>
          </div>

          <div v-if="exercises_on['selection_questionnaire']">
            <Selection_questionnaire
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Selection_questionnaire>
          </div>
          
          <div v-if="exercises_on['reverse_direction']">
            <Reverse_direction
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Reverse_direction>
          </div>

          <div v-if="exercises_on['reversible_passive']">
            <Reversible_passive
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Reversible_passive>
          </div>

          <div v-if="exercises_on['orla']">
            <Orla
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Orla>
          </div>

          <div v-if="exercises_on['common_sayings']">
            <Common_sayings
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Common_sayings>
          </div>

          <div v-if="exercises_on['read_menu']">
            <Reading_menu
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Reading_menu>
          </div>

          <div v-if="exercises_on['word_category']">
            <Word_category
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Word_category>
          </div>

          <div v-if="exercises_on['word_guessing']">
            <Word_guessing
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Word_guessing>
          </div>

          <div v-if="exercises_on['stepping_stone']">
            <Stepping_stone
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Stepping_stone>
          </div>

          <div v-if="exercises_on['quiz']">
            <Pub_quiz
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Pub_quiz>
          </div>

          <div v-if="exercises_on['assessment_questionnaire']">
            <Assessment_questionnaire
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Assessment_questionnaire>       
          </div>
          
          <div v-if="exercises_on['scene_click']">     
            <Scene_click
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Scene_click>
          </div>

          <div v-if="exercises_on['pace_training']">
            <Pace_training
              :exForm="exForm"
              
              @goToResults="goToResults($event)"
              @logExerciseResults="logExerciseResults($event)"
              @nextExercise="next()">
            </Pace_training>
          </div>

          <div v-if="exercises_on['number_sequence']">
            <Number_sequence
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Number_sequence>
          </div>

          <div v-if="exercises_on['copy_word']">
            <Copy_word
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Copy_word>
          </div>

          <div v-if="exercises_on['copy_word_speak']">

            <Copy_word_speak
              :exForm="exForm" 
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Copy_word_speak>

          </div>

          <div v-if="exercises_on['antonym_exercise']">
            <Antonym_module
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Antonym_module>

          </div>

          <div v-if="exercises_on['letter_word_match']">

            <Letter_word_match
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Letter_word_match>
          </div>

          <div v-if="exercises_on['minimal_pairs']">
            <Minimal_pairs
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Minimal_pairs>
          </div>

          <div v-if="exercises_on['listen_minimal_pairs']">
            <Listen_minimal_pairs
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Listen_minimal_pairs>
          </div>

          <div v-if="exercises_on['aphasia_position']">
            <Aphasia_position_module
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Aphasia_position_module>
          </div>

          <div v-if="exercises_on['aphasia_story']">
            <Aphasia_story_module
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Aphasia_story_module>
          </div>

          <div v-if="exercises_on['reading_comprehension']">
            <Reading_comprehension
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Reading_comprehension>
          </div>

          <div v-if="exercises_on['hangman']">

            <Hangman
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Hangman>
          </div>

          <div v-if="exercises_on['write_words']">
            <Write_words
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Write_words>
          </div>

          <div v-if="exercises_on['spoken_pca']">
            <Spoken_PCA
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
          </Spoken_PCA>
          </div>

          
          <div v-if="exercises_on['pca']">
            <PCA
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
          </PCA>
          </div>

          <div v-if="exercises_on['name_a_picture_easy'] || exercises_on['Name_a_picture_easy'] || exercises_on['word_naming_easy']">
            <Name_a_picture_easy
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Name_a_picture_easy>
          </div>

          <div v-if="exercises_on['yes_no']">
            <Aphasia_yes_no
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Aphasia_yes_no>
          </div>

          <div v-if="exercises_on['reading_images']">
            <Reading_images
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Reading_images>
          </div>

          <div v-if="exercises_on['maths_table']">
            <Maths_table
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Maths_table>
          </div>

          <div v-if="exercises_on['number_guessing']">
            <Number_guessing
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Number_guessing>
          </div>

          <div v-if="exercises_on['simon_says']">
            <Simon_says
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Simon_says>
          </div>

          <div v-if="exercises_on['word_search']">
            <Word_search
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Word_search>
          </div>

          
          <div v-if="exercises_on['cats_image_selection']">
            <Cats_image_selection
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Cats_image_selection>
          </div>


          <div v-if="exercises_on['memory_image']">
            <Word_memory
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Word_memory>
          </div>
    
          <div v-if="exercises_on['matching_shapes']">
            <Matching_shapes
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Matching_shapes>
          </div>

          <div v-if="exercises_on['word_finding']">
            <Word_finding
              :exForm="exForm"
              @logExerciseResults="logExerciseResults($event)"

              @nextExercise="next()">
            </Word_finding>
          </div>

          <div v-if="exercises_on['clock_game']">
            <Clock_game
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Clock_game>
          </div>

          <div v-if="exercises_on['money_recognition']">
            <Money_recognition
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Money_recognition>
          </div>

          <div v-if="exercises_on['write_numbers']">
            <Write_numbers
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Write_numbers>
          </div>

          <div v-if="exercises_on['karaoke']">
            <Karaoke_module
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Karaoke_module>

         
          </div>

          <div v-if="exercises_on['token_test']">
            <Token_test
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Token_test>
          </div>

          <div v-if="exercises_on['ddk_rate']">
            <Ddk_rate_module
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Ddk_rate_module>
          </div>

          <div v-if="exercises_on['basic_module']">
            <Voice_n_speech_multi_word
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Voice_n_speech_multi_word>
          </div>

          <div v-if="exercises_on['speak_numbers']">
            <Voice_n_speech_multi_word
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Voice_n_speech_multi_word>
          </div>

          <div v-if="exercises_on['single_word']">
            <Voice_n_speech_multi_word_auto
              :exForm="exForm"
              
              @goToResults="goToResults($event)"
              @logExerciseResults="logExerciseResults($event)"
              @nextExercise="next()">
            </Voice_n_speech_multi_word_auto>
          </div>

          <div v-if="exercises_on['speak_background_noise']">
            <Speak_background_noise
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Speak_background_noise>
          </div>

          <div v-if="exercises_on['cookie_theft']">
            <Cookie_theft
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
          </Cookie_theft>
          </div>

          <div v-if="exercises_on['avqi']">
            <Avqi
              :exForm="exForm"
              
              @logExerciseResults="logExerciseResults($event)"
              @goToResults="goToResults($event)"
              @nextExercise="next()">
            </Avqi>
          </div>

        </div>
        <div v-else style="width:100%;height:100%;margin-top:0%">
          <Results
            :more_exercises_available="more_exercises_available" 
            :resultsTable="results_table"
            @discardResults="discardResults()"
            @nextExercise="next()" />
        </div>
      </div>
      <Footer class="mt-auto"></Footer>
    </div>
  </div>
</template>
<script>

import {get_microphone_permission} from '@/media_input/mic/permission.js'
import MicPermission from '@/views/components/mic_permission/MicPermission.vue'
import {deleteSoundMeter } from '@/media_input/microphone_recording'
import {generate_results_path} from '@/utils/results/data_save_utils'

import Results from './End_results.vue'
import Scene_click from './exercise_type/scene_click/scene_click_module.vue'

import Scene_description from './exercise_type/scene_description/scene_description_module.vue'
import Aphasia_position_module from './exercise_type/aphasia_position/scene_position.vue'
import Selection_questionnaire from './exercise_type/selection_questionnaire/selection_questionnaire.vue'

import Pub_quiz from './exercise_type/quiz/quizGame.vue'

import Word_guessing from '@/views/dashboard/patient/daily_puzzles/word_guessing/word_guessing.vue'
import Stepping_stone from '@/views/dashboard/patient/daily_puzzles/stepping_stone/stepping_stone.vue'

import Maths_table from '@/views/dashboard/patient/daily_puzzles/maths_table/maths_table.vue'
import Number_guessing from '@/views/dashboard/patient/daily_puzzles/number_guessing/number_guessing.vue'
import Simon_says from '@/views/dashboard/patient/daily_puzzles/simon_says/simon_says.vue'
import Word_search from '@/views/dashboard/patient/daily_puzzles/word_search/word_search.vue'

import Pace_training from './exercise_type/pace_training/pace_training.vue'

import Number_sequence from './exercise_type/number_sequence/number_sequence.vue'

import Reading_comprehension from './exercise_type/reading_comprehension/reading_comprehension.vue'

import Aphasia_story_module from './exercise_type/story_memory/story_memory.vue'
import Minimal_pairs from './exercise_type/minimal_pairs/minimal_pairs_module.vue'
import Listen_minimal_pairs from './exercise_type/listen_minimal_pairs/listen_minimal_pairs.vue'

import Reading_images from './exercise_type/reading_images/reading_images.vue'


import PCA from './exercise_type/pca/pca.vue'
import Spoken_PCA from './exercise_type/spoken_pca/spoken_pca.vue'


import Name_a_picture_easy from './exercise_type/name_a_picture/name_a_picture_module_easy.vue'
import Aphasia_yes_no from './exercise_type/aphasia_yes_no/aphasia_yes_no.vue'

import Antonym_module from './exercise_type/antonym/antonym_module.vue'
import Copy_word from './exercise_type/copy_word/copy_word.vue'

import Copy_word_speak from './exercise_type/copy_word/copy_word_speak.vue'

import Letter_word_match from './exercise_type/letter_word_match/letter_word_match.vue'


import Matching_shapes from './exercise_type/matching_shapes/matching_shapes.vue'

import Cats_image_selection from './exercise_type/cats_image_selection/cats_image_selection.vue'

import Word_memory from './exercise_type/word_memory/word_memory.vue'
import Word_finding from './exercise_type/word_finding/word_finding_js.vue'
import Clock_game from './exercise_type/clock_game/clock_game.vue'

import Money_recognition from './exercise_type/money_recognition/money_recognition.vue'

import Write_numbers from './exercise_type/write_numbers/write_numbers.vue'

import Karaoke_module from './exercise_type/karaoke/karaoke_module.vue'
import Token_test from './exercise_type/token_test/token_test_module.vue'
import Ddk_rate_module from './exercise_type/ddk_rate/ddk_rate_module.vue'

import Avqi from './exercise_type/voice_n_speech/avqi.vue'


import Cookie_theft from './exercise_type/cookie_theft/cookie_theft.vue'

import Speak_background_noise from './exercise_type/voice_n_speech/speak_background_noise.vue'



import Voice_n_speech_multi_word from './exercise_type/voice_n_speech/multi_word.vue'
import Voice_n_speech_multi_word_auto from './exercise_type/voice_n_speech/multi_word_auto.vue'

import Word_category from './exercise_type/word_category/word_category.vue'
import Reversible_passive from './exercise_type/reversible_passive/reversible_passive.vue'
import Orla from './exercise_type/orla/orla.vue'
import Common_sayings from './exercise_type/common_sayings/common_sayings.vue'
import Reading_menu from './exercise_type/reading_menu/reading_menu.vue'

import Write_words from './exercise_type/write_words/write_words.vue'
import Hangman from './exercise_type/hangman/hangman.vue'

import Reverse_direction from './exercise_type/reverse_direction/reverseDirection.vue'

import Assessment_questionnaire from './exercise_type/vhi/assessment_questionnaire.vue'

import {Session} from '@/utils/session/session.js'

import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'

export default {
  name: 'Session',
  props: {
    requested: String
  },

  components: {
    Footer,
    Navbar,
    MicPermission, 
    Results, 
    
    Cookie_theft,
    Write_numbers,
    Reading_menu,
    Write_words,
    Hangman,
    Orla,
    Common_sayings,
    Selection_questionnaire,
    Listen_minimal_pairs,
    Matching_shapes,
    Avqi,
    Reverse_direction, 
    Reversible_passive,
    Word_category,
    Stepping_stone,
    Simon_says,
    Word_search,
    Word_guessing,
    Number_guessing,
    Maths_table,
    Pub_quiz, 
    Assessment_questionnaire,
    Scene_click,
    Scene_description, 
    Pace_training, 
    Number_sequence, 
    Copy_word,
    Copy_word_speak,
    Antonym_module, 
    Letter_word_match,
    Aphasia_position_module, 
    Reading_comprehension,
    Aphasia_story_module, 
    Name_a_picture_easy, 
    PCA,
    Spoken_PCA,
    Aphasia_yes_no, 
    Reading_images,
    Word_memory, 
    Cats_image_selection,
    Word_finding,
    Minimal_pairs, 
    Clock_game, 
    Money_recognition,
    Karaoke_module, 
    Token_test, 
    Ddk_rate_module, 
    Voice_n_speech_multi_word,
    Speak_background_noise,
    Voice_n_speech_multi_word_auto
  },
  data() {
    return { 
      mic_permission_allowed: false,
      userCategory: localStorage.getItem('userCategory'),

      session: null,

      exForm: {},

      more_exercises_available: false,
      results_table: {back:[], pre:[]},

      exercises_on:{},

      title: '',
      showResults: false,
    }
  },
  async created() {

    let _this = this
    
    await get_microphone_permission(_this)
    
    this.session = new Session(_this.requested, this)
    await this.session.initialise()
    _this.title = this.session.title
  
    _this.exForm = Object.assign({}, _this.session.getExForm())
    this.exercises_on = this.session.getExerciseOn()
  },
  mounted() {

  },
  watch: {

  },
  beforeUnmount()
  {
    deleteSoundMeter()
  },
  methods: {

    async next() {
      let _this = this
      
      _this.showResults = false
      _this.exercises_on = []

      
      await this.session.next()
      
      _this.exForm = Object.assign({}, _this.session.getExForm())
      _this.exercises_on = Object.assign({}, _this.session.getExerciseOn())
      _this.title = _this.session.title

      if (_this.session.getShowResults()) {
        //forced to show the results
        let rt = _this.session.getResults()
        
        _this.more_exercises_available = rt['more_exercises_available']
        _this.results_table = rt['results_table']

        _this.showResults = true;
      }

    },

    async goToResults(goToResultsData)
    {
      let _this = this
      
      _this.showResults = false

      let rt = await _this.session.goToResults(goToResultsData)

      _this.more_exercises_available = rt['more_exercises_available']
      _this.results_table = rt['results_table']

      _this.showResults = true;
    },

    discardResults()
    {
      let _this = this
      let paths = generate_results_path(this.exForm)

      let module_path = paths['modules_results_path']

      let core_directory_handle =  this.$firebase.database().ref(module_path) 

      console.error(core_directory_handle)
      core_directory_handle.remove().then(function() {
        console.debug("Removal succeeded!")
        
        let clientUID = localStorage.getItem("client_uid")
        _this.$router.push({ name: 'client_interface', query: {uid: clientUID} });

        alert("Results have been discarded.")
      })
      .catch(function(error) {
        console.error("Remove failed: " + error.message)
      });


    },
    
    logExerciseResults(data)
    {
      this.session.logExerciseResults(data)
    },
  },
  
}
</script>
<style scoped>

</style>