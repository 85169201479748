<template>
  <div>
    <h1 class="text-center">Phonation Assessment Results</h1>
    <div v-if="room_meta.session_slt_id.value" class="text-center">
      <p class="lead">Session ID: {{ room_meta.session_slt_id.value }}</p>
    </div>
    <div class="mt-4" v-if="exercises_data.value">
      
      <vue3-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1800"
        :filename=room_meta.session_slt_id.value
        :pdf-quality="2"
        :manual-pagination="false"
        :margin="[10, 10]"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        @beforeDownload="show=false"
        @hasDownloaded="show=true"
        ref="html2Pdf"
      > 
      <template  v-slot:pdf-content>
        <section class="pdf-item">
          <div class="card card-body mb-1" v-for="exercise_data, index of exercises_data.value">
            <p class="lead" v-if="exercises_data.value.length > 1">Exercise {{ Number(index) + 1 }}</p>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" v-if="show">Recording</th>
                  <th scope="col">Loudness (dB)</th>
                  <th scope="col">Duration (sec)</th>
                  <th scope="col">Mean F0</th>
                  <th scope="col">Std Dev F0</th>
                  <th scope="col">Jitter (%)</th>
                  <th scope="col">Shimmer (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="value, try_index of exercise_data['session_phonation']['recordings']">
                  <td v-if="show">
                    <div>
                      <div class="btn-group mt-1" v-if="value['audio_address']">
                        <button class="btn btn-danger" @click="playRecording(value)"><i class="bi bi-volume-up"></i> Playback</button>
                        <button class="btn btn-secondary" @click="downloadRecording(value)"> <i class="bi bi-download"></i> </button>
                      </div>
                    </div>
                  </td>
                  <td>{{ value['processing_status'] === 'finished' ? value['loudness']['loudness'] : ''}}</td>
                  <td>{{ value['processing_status'] === 'finished' ? value['f0_dictionary']['audio_duration'] : '' }}</td>
                  <td>{{ value['processing_status'] === 'finished' ? value['jitter_shimmer_dictionary']['meanF0'].toFixed(0) : '' }}</td>
                  <td>{{ value['processing_status'] === 'finished' ? value['jitter_shimmer_dictionary']['stdevF0'].toFixed(1) : '' }}</td>
                  <td>{{ value['processing_status'] === 'finished' ? value['jitter_shimmer_dictionary']['localJitter'].toFixed(3) : '' }}</td>
                  <td>{{ value['processing_status'] === 'finished' ? value['jitter_shimmer_dictionary']['localShimmer'].toFixed(3) : '' }}</td>
                </tr>
              </tbody>
            </table>
            <div class="row justify-content-center">
              <div class="input-group mb-3 w-50">
                <span class="input-group-text">Note</span>
                <input type="text" class="form-control w-50" id="heard" v-model="exercise_data['session_phonation']['note']['note']" @keyup="save(Number(index) + 1)">
              </div>
            </div>
          </div>
        </section>      
      </template>
      </vue3-html2pdf>
    </div>
    <div v-else-if="loading" class="row mt-4">
      <div class="mt-2 text-center">
        <h1 class="display-4">Loading Data</h1>
        <div class="mt-4 spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    
    <div class="row mt-4" v-if="exercises_data.value">
      <div class="col-12 text-end">
        <button class="btn btn-success" @click="downloadPDF"><i class="bi bi-download"></i> PDF</button>
      </div>
    </div>

    <div class="row mt-4 mb-4">
      <div class="col-12 text-center">
        <button class="btn btn-primary w-25" @click="exit">Exit Session</button>
      </div>
    </div>

  </div>
</template>
  
<script>
import jsPDF from 'jspdf'
import Vue3Html2pdf from 'vue3-html2pdf'
import firebase from '@firebase/app';
import { ref, toRefs, onMounted, onUpdated } from 'vue';
import { reactive } from 'vue'

export default {
  props: ['room_meta'],
  components: {
    Vue3Html2pdf
  },
  setup(props, {emit}) {
    
    const room_meta = props.room_meta
    const exercises_data = reactive({})
    const html2Pdf = ref(null)
    const show = ref(true)
    const loading = ref(false)

    const downloadPDF = () => {
      html2Pdf.value.generatePdf().then
    }

    let module_name = room_meta.session_name.value
    //let local_tmp_data = {}
    
    for (const [patient_uid, value] of Object.entries(room_meta.users.value)) {

      if (value['category'] === "patient") {
        const path = 'Users/' + patient_uid + '/Results/remote_assessment/general/' + room_meta.session_date.value + '/' + module_name
        firebase.database().ref(path).child('exercises').get().then( async (snapshot) => {

          let _exercises_data = snapshot.val()
          console.error(_exercises_data)
          
          if (_exercises_data) {
            //check mandatory fields
            _exercises_data = Object.assign({}, _exercises_data)

            let all_recording_promises = []

            Object.keys(_exercises_data).forEach(index => {

              if (_exercises_data[index].hasOwnProperty('session_phonation')) {
                
                if (_exercises_data[index]['session_phonation'].hasOwnProperty('note')) {
                  if (!_exercises_data[index]['session_phonation']['note'].hasOwnProperty('note')) {
                    _exercises_data[index]['session_phonation']['note']['note'] = ''
                  }
                } else {
                  _exercises_data[index]['session_phonation']['note'] = { 'note': ''}
                }

                //wait for processing or started recording processing
                if (_exercises_data[index]['session_phonation'].hasOwnProperty('recordings')) {

                  Object.keys(_exercises_data[index]['session_phonation']['recordings']).forEach(recording_index => {

                    let promise_recording_processing_finished = new Promise ( (resolve_recording, reject_recording) => {
                      
                      let processing_status = _exercises_data[index]['session_phonation']['recordings'][recording_index]['processing_status']
                      if (processing_status === 'started' || processing_status === 'processing') {
                        //retry or wait
                        loading.value = true
                        console.debug("started")
                        
                        firebase.database().ref(path).child('exercises').child(index).child('session_phonation').child('recordings').child(recording_index).on('child_changed', 
                          (new_child) => {
                            firebase.database().ref(path).child('exercises').child(index).child('session_phonation').child('recordings').child(recording_index).get().then((recording_snapshot) => {

                              let recording_data = recording_snapshot.val()

                              _exercises_data[index]['session_phonation']['recordings'][recording_index] = recording_data

                              if (recording_data) {
                                if (recording_data['processing_status'] === 'finished') {
                                  console.debug("finished")
                                  resolve_recording()
                                } else if (recording_data['processing_status'] === 'error') {
                                  console.debug("error")
                                  resolve_recording()
                                }
                              }

                            })
                          }
                        )
                      } else {
                        resolve_recording()
                      }

                    })

                    all_recording_promises.push(promise_recording_processing_finished)
                    
                  })
                }
                
              }

            })
            
            await Promise.all(all_recording_promises)
            exercises_data.value = _exercises_data
            
            
            /*for (let [index, exercise_data] of Object.entries(exercises_data)) {
              console.log(index)
              console.error(exercise_data)
              //results.value = parseProccesedAudioExerciseData(exercise_data, local_tmp_data)
            }
            console.error(results.value)
            */
          }
        });
      }
    }

    const saveBlob = (blob, fileName) => {
      var a = document.createElement('a');
      let audioBlob = new Blob([blob], {type: 'audio/mpeg'})
      a.href = window.URL.createObjectURL(audioBlob);
      a.download = fileName;
      a.click()
    }
    
    const downloadRecording = (row) => {
      let audio_file_location = row['audio_address']

      let storage = firebase.storage();

      storage.ref(audio_file_location).getDownloadURL().then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          const blob = xhr.response;
          saveBlob(blob, 'beautiful_voice_audio.webm');
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
      });
    }

    const playSound = (url) => {
      let audio_player = new Audio(url);
      audio_player.play();
    }

    const playRecording = (row) => {
      let audio_file_location = row['audio_address']
      console.log(audio_file_location)
      firebase.storage().ref(audio_file_location).getDownloadURL().then((url) => {
        playSound(url)
        console.log(url)
      })
      .catch((error) => {
        console.log(error)
      })
    }
    
    const exit = () => {
      console.log("clicked reset")
      emit("exit")
    }

    const save = (index) => {
      
      emit("save", {exercise_index: index, result_type: "session_phonation", payload: {
        note: exercises_data.value[index-1]['session_phonation']['note']['note']
      }})
      
    }

    return {
      html2Pdf,
      show,
      exercises_data,
      loading,

      exit,
      save,
      downloadRecording,
      playRecording,
      downloadPDF

    }
  }
}
</script>

<style scoped>
</style>
