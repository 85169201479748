<template>
  <div class=""> 
    <div class="card m-2" v-if="userCategory=='patient'">
      <div class="card-body">
        <h5 class="mb-4">Communication difficulties</h5>
        <div class="row justify-content-between">
          <div class="col-lg-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d1" v-model="diseaseType" @change="updateUserInfo('diseaseType')" :disabled="diseaseType.length == 1 && diseaseType.includes('Slurred/choppy speech')" value="Slurred/choppy speech">
              <label class="form-check-label" for="d1">Slurred/choppy speech</label>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d2" v-model="diseaseType" @change="updateUserInfo('diseaseType')" :disabled="diseaseType.length == 1 && diseaseType.includes('Difficulty in moving lips, jaw or tongue')" value="Difficulty in moving lips, jaw or tongue"> 
              <label class="form-check-label" for="d2">Difficulty in moving lips, jaw or tongue</label>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d3" v-model="diseaseType" @change="updateUserInfo('diseaseType')" :disabled="diseaseType.length == 1 && diseaseType.includes('Hoarse/breathy voice')" value="Hoarse/breathy voice"> 
              <label class="form-check-label" for="d3">Hoarse/breathy voice</label>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" id="d4" v-model="diseaseType" @change="updateUserInfo('diseaseType')" :disabled="diseaseType.length == 1 && diseaseType.includes('Dysphasia/aphasia')" value="Dysphasia/aphasia"> 
              <label class="form-check-label" for="d4">Dysphasia/aphasia</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2" v-if="diseaseType.includes('Hoarse/breathy voice')">
      <div class="card-body">
        <div class="row justify-content-between">
          <div class="col-lg-5 mb-4">
            <h5 class="mb-4">Loudness target</h5>
            <label class="form-label">Loudness target in exercises</label>
            <select class="form-select" v-model="loudness_target" placeholder="Select" @change="updateUserInfo('loudness_target')">
              <!-- <option key="1" label="55 dB - Low voice" value="55"> </option> -->
              <option key="2" label="60 dB - Quiet conversation" value="60"> </option>
              <option key="3" label="65 dB - Normal conversation" value="65"> </option>
              <option key="4" label="70 dB - Loud conversation" value="70"> </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Preferences</h5>
        <div class="row">
          <div class="d-flex flex-row">
            <div class="col-lg-5 me-2">
              <label class="form-label">Region</label>
              <div class="row justify-content-between">
                <div class="col">
                  <select class="form-select" v-model="region" placeholder="Select" @change="updateUserInfo('region')">
                    <option label="United Kingdom" value="UK"></option>
                    <option label="United States" value="US"></option>
                  </select>
                </div>
              </div>

            </div>
            <div class="col-lg-6">
              <label class="form-label">Language</label>
              <div class="row justify-content-between">
                <div class="col">
                  <select class="form-select" v-model="language" placeholder="Select" @change="updateUserInfo('language')">
                    <option label="British English" value="UK"></option>
                    <option label="American English" value="US"></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between mt-4">
          <div class="col-lg-6" v-if="voices.length > 0">
            <label class="form-label">Select voice</label>
            <div class="row justify-content-between">
              <div class="col">
                <select class="form-select" v-model="prefered_voice" placeholder="Select" @change="updateUserInfo('prefered_voice')">
                  <option v-for="(voice, key) in voices.filter(voice => voice.lang.includes(language == 'UK'? 'GB': language) && voice.name != 'Fiona')" :key="key" :label="voice.name" :value="voice.name"></option>
                </select>
              </div>
              <div class="col-4 text-start">
                <button class="btn btn-primary" v-if="voices.some(voice => voice.lang.includes(language == 'UK' ? 'GB' : language) && voice.name === prefered_voice)" @click="test_voice()">Test Voice</button>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <label class="form-label">Text-to-Speech Synthesis</label>
            <br/>
            <div class="form-check form-check-inline">
              <input class="form-check-input mb-2" type="checkbox" v-model="tts_enabled" @change="update_auto_tts()">
              <label v-if="tts_enabled" class="form-check-label">Text-to-Speech synthesis is <span class="text-success">active</span></label>
              <label v-else class="form-check-label">Text-to-Speech synthesis is <span class="text-secondary">inactive</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2">
      <div class="card-body">
        <h5>Topics of interest</h5>
        <p class="lead">Select topics of interest to personalise your exercises</p>
        <div class="row" v-for="i in allInterests.length" :key="i">
          <div class="col text-center d-flex justify-content-center" v-for="j in allInterests[i-1].length" :key="j"> 
            <a @click="checkbutton(i-1, j-1)" > 
              <div v-if="allInterests[i-1][j-1].checked" class="interestButtonChecked" style="position:relative">
                <img class="interestThumbnail" :src="allInterests[i-1][j-1].bg"  style="opacity: 80%"/>
              </div>
              <div v-else class="interestButtonUnChecked" style="position:relative">
                <img class="interestThumbnail" :src="allInterests[i-1][j-1].bg" style="opacity: 40%"/>
                <div class="bi bi-plus-lg" style="height:100%; margin-left: auto; margin-right: auto; left: 0; right: 0; top:18px; text-align: center; font-size: 60px; color:gray; font-weight: 1000; position:absolute;"/>
              </div>
              <p class="text-overlay">{{allInterests[i-1][j-1].name}} <i v-if="allInterests[i-1][j-1].checked" class="bi bi-check" style="font-size: 24px; color: rgb(7, 241, 77); font-weight: 1000;"/></p> 
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2">
      <div class="card-body">
        <h5 class="mb-4">Account information</h5>
        <div class="row justify-content-between">
          <div class="col-md-5">
            <p class="lead mt-3">{{ name }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label class="form-label">Email Address</label>
            <input class="form-control mb-3" v-model="email" autocomplete="off" disabled />

            <label class="form-label">Display name</label>
            <input class="form-control mb-3" v-model="userName" autocomplete="off" />

            <label class="form-label">Reminder frequency</label>
            <select class="form-select mb-3" v-model="freq" placeholder="Select">
              <option key="1" label="Every day of inactivity" value="1"> </option>
              <option key="2" label="Every 2 days of inactivity" value="2"> </option>
              <option key="3" label="Every 3 days of inactivity" value="3"> </option>
              <option key="4" label="Every 4 days of inactivity" value="4"> </option>
              <option key="5" label="Every 5 days of inactivity" value="5"> </option>
              <option key="6" label="Every 6 days of inactivity" value="6"> </option>
              <option key="7" label="Every 7 days of inactivity" value="7"> </option>
              <option key="8" label="Opt out" value="0"> </option>
            </select>
            <button class="btn btn-outline-primary float-end" @click="updateUserInfo('userName')">Update</button>
          </div>
          <div class="col-lg-6" v-if="tier_name && next_payment_due">
            <div class="card h-100">
              <div class="card-body">
                <p><strong>Subscription plan:</strong> <i>{{ tier_name }}</i></p>
                <p v-if="!tier.includes('F') && expiry_date != ''" ><strong>Expiry date:</strong> <i>{{ expiry_date }}</i></p>
                <p v-if="!tier.includes('F') && !tier.includes('Trial')" ><strong>Next payment due:</strong> <i>{{ next_payment_due }}</i></p>

                <button v-if="tier.includes('F') || tier == 'T1' || tier.includes('Trial')" class="btn btn-primary float-start" @click="goToSubscriptionPage()">Upgrade Subscription</button>
                <button v-if="!tier.includes('Trial') && !tier.includes('F')" class="btn btn-outline-success float-end" @click="goToCustomerPortal()">Payment details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card m-2" v-if="diseaseType.includes('Hoarse/breathy voice')">
      <div class="card-body">
        <h5 class="mb-4">Calibrate your microphone</h5>
        <div class="row justify-content-between">
          <div class="col-lg-10">        
            <label class="form-label">Calibrating your microphone will make your loudness readings more accurate. Contact contact@beautifulvoice.co.uk for help.</label>
          </div>
          <div class="col-lg-2">        
            <button class="btn btn-primary" @click="goToCalibrate()">Calibrate</button>
          </div>
        </div>
      </div>

    </div>  
  </div>
</template>
<script>

import { getUserDataDir, getData, updateDataDirectory } from '@/firebase/index'
import { useToast } from "vue-toastification";
import { useTTS } from '@/stores/tts'

export default {
  components: { },
  name: 'Patient_setting',
  props: 
  {
    tier_name: {
      type:String,
      required: true,
    }, 
    next_payment_due: {
      type:String,
      required: true,
    }, 
  },
  data(){
    return {
      userCategory: localStorage.getItem('userCategory'),
      region: localStorage.getItem('region'),
      language: localStorage.getItem('language'),
      tier: sessionStorage.getItem("tier"),
      license_status: sessionStorage.getItem('license_status'),

      email: '',
      userName:'',
      freq:'3',
      loudness_target:"65",
      diseaseType:[],
      issue_lib:['Slurred/choppy speech', 
                'Difficulty in moving lips, jaw or tongue', 
                'Hoarse/breathy voice',
                'Dysphasia/aphasia'],
      issue_flag:[false,false,false,false],
      colNum:5,
      allInterests:[],
      already_showed_disease_type_watch: false,

      expiry_date: '',

      bv_tts: useTTS().tts_engine,
      voices: [],
      prefered_voice: null,

      tts_enabled: false,
    }
  },
  created() {
    this.loadUserInfo()
    this.loadEmail()
    this.fetchInterestList()
    let temp_loudness_target = localStorage.getItem('loudness_target')

    if(temp_loudness_target!=undefined && temp_loudness_target!=null) {
      temp_loudness_target = parseInt(temp_loudness_target)

      if(!isNaN(temp_loudness_target)) {
        this.loudness_target = temp_loudness_target
      }
    }
  },
  async mounted() {
    this.matchAgainstUserInterestList()
  },
  watch: {
    diseaseType(newv, oldv) {
      if (newv.length > 0) {
        let uid = localStorage.getItem('uid');
        let ref_path = 'Users/' + uid + '/Data/'
        updateDataDirectory(ref_path, {'Qs_diseaseType': this.diseaseType})

        this.issue_flag = [false,false,false,false];

        for(let i=0;i<this.diseaseType.length;i++){
          let issue = this.diseaseType[i];
          let issue_id = this.issue_lib.indexOf(issue);
          this.issue_flag[issue_id] = true;
        }

        //console.debug('patient setting= ' + this.issue_flag)
        localStorage.setItem('issue_flag', this.issue_flag);
        this.$emit('changedSetting');
      }
    },
    next_payment_due(new_expiry) {
      this.expiry_date = new_expiry
    }
  },
  methods: {
    update_auto_tts() 
    {
      let uid = localStorage.getItem('uid');
      let ref_path = 'Users/' + uid + '/Settings'
      updateDataDirectory(ref_path, {'auto_tts': this.tts_enabled})
    },
    test_voice() {
      this.bv_tts.say("Hello! What a nice day to practice speech!")
    },
    goToSubscriptionPage() {
      this.$router.push({name: "subscribe"})
    },
    goToCustomerPortal() {
      window.location.href = import.meta.env.VITE_CUSTOMER_PORT_LINK + this.email
    },
    loadEmail() {
      let _this = this;
      getUserDataDir('Data/' , function (err, result) {
        _this.email = result.val()['Email'];
      });
    },
    async loadUserInfo() {
      let _this = this;

      await new Promise(resolve => {

        getUserDataDir('Data/' , async function (err, result) {
          _this.diseaseType = [];
          _this.diseaseType = result.child('Qs_diseaseType').val()

          if(_this.diseaseType == undefined || _this.diseaseType == null) {
            _this.diseaseType = []
          }
          
          let userName = result.child('userName').val()

          if(userName == undefined || userName == null) {
            _this.userName = result.child('Firstname').val()
          } else {
            _this.userName = userName;
          }

          _this.name =  result.child('Firstname').val().charAt(0).toUpperCase() + result.child('Firstname').val().slice(1) + ' ' +
                        result.child('Surname').val().charAt(0).toUpperCase() + result.child('Surname').val().slice(1)
          

          let notification_frequency = result.child('notification_frequency').val()

          if(notification_frequency == undefined || notification_frequency == null) {
            _this.freq = "3";
          } else {
            _this.freq = notification_frequency;
          }

          let user_interests = result.child('interests').val()
          localStorage.setItem("interests_array", JSON.stringify(user_interests))

          _this.prefered_voice = await _this.bv_tts.prefered_voice
          _this.voices = _this.bv_tts.language_voices //depends on the above promise()
          _this.voices.unshift({"lang": "GB", "name": "BV AI"})
          _this.voices.unshift({"lang": "US", "name": "BV AI"})
          
          resolve()
        });
      }) 

      await new Promise(resolve => {
        getUserDataDir('Settings/', async function (err, result) {      
          let region = result.child('region').val();

          if(region == undefined || region == null) {
            region = 'UK' 
          }
          localStorage.setItem('region', region); 

          let language = result.child('language').val();
          if(language == undefined || language == null) {
            language = 'UK' 
          }
          localStorage.setItem('language', language); 

          let value = result.child('auto_tts').val()
          if (value != null) {
            _this.tts_enabled = value
          } else {
            _this.tts_enabled = true
            _this.update_auto_tts()
          }

          resolve()
        })
      })

    },
    updateUserInfo(inp)
    {
      let _this = this;

      let uid = localStorage.getItem('uid');
      let ref_path = 'Users/' + uid + '/Data/'
      
      let info_updated = false
      
      if(inp == 'userName') {
        updateDataDirectory(ref_path, {'notification_frequency': _this.freq, 'userName': _this.userName});

        info_updated = true
      } else if(inp == 'loudness_target') {
        updateDataDirectory(ref_path, {'loudness_target':_this.loudness_target})
        
        info_updated = true
      } else if(inp == 'prefered_voice') {
        updateDataDirectory(ref_path, {'prefered_voice': _this.prefered_voice})
        this.bv_tts.set_voice()

        info_updated = true
      } else if(inp == 'region') {
        updateDataDirectory('Users/' + uid + '/Settings/', {'region': _this.region})
        localStorage.setItem('region', _this.region)
        
        info_updated = true
      } else if(inp == 'language') {
        updateDataDirectory('Users/' + uid + '/Settings/', {'language': _this.region})
        localStorage.setItem('language', _this.language)
        
        info_updated = true
      } else if(inp == 'interests') {
        let interests_array = [];
        for(let k=0;k<_this.allInterests.length;k++) {

          for(let j=0; j<_this.allInterests[k].length; j++) {
            if(_this.allInterests[k][j].checked == true) {
              let interest_name = _this.allInterests[k][j].name;
              interests_array.push(interest_name);
            }
          }
        }

        localStorage.setItem('interests_array', JSON.stringify(interests_array))
        //console.debug('set interest array = ' + interests_array)

        updateDataDirectory(ref_path, {'interests':interests_array});

        info_updated = true
      }

      if (info_updated) {
        const toast = useToast()
        toast.info("Your information has been updated.")
      }

      _this.$emit('changedSetting')
    },
    checkbutton(i, j)
    {
      if(this.allInterests[i][j].checked == true) {
        this.allInterests[i][j].checked = false;
      } else {
        this.allInterests[i][j].checked = true;
      }

      this.updateUserInfo('interests')
    },
    fetchInterestList()
    {
      let _this = this;
      let user_ref = _this.$firebase.database().ref('Exercises').child('General').child('News').child('UK').child('Standard').child('Short');

      var do_once = true;
      _this.allInterests = [];
      getData(user_ref , function (err, result) {

        if (do_once) {

          do_once = false;

          result.forEach(function(childSnapshot) {
            let interest_temp = {name:childSnapshot.key, bg:'', checked:''};
            let image_file = childSnapshot.key.replaceAll(' ','')
            let bg_img = new URL(`../../../assets/images/interests/${image_file}.png`, import.meta.url)

            interest_temp.bg = bg_img

            _this.allInterests.push(interest_temp);
          });

          _this.matchAgainstUserInterestList();
        }
      });

    },
    matchAgainstUserInterestList()
    {
      const _this = this

      let cached_interests_array = localStorage.getItem('interests_array');

      if(cached_interests_array!=undefined && cached_interests_array!=null) {
        cached_interests_array = JSON.parse(cached_interests_array)
      } else {
        cached_interests_array = []
      }

      //console.debug('interests localstorage = ' + cached_interests_array)

      for(let n in cached_interests_array) {
        for(let i in _this.allInterests) {
          if(cached_interests_array[n]==_this.allInterests[i].name) {
            //console.debug('found interest')
            _this.allInterests[i].checked = true
            break;
          }
        }
      }

      //convert to 2d
      let tmp = []
      let index = 0
      for(let i=0; i<_this.allInterests.length; i=i+this.colNum) {


        tmp.push([])

        for(let j=0; j<this.colNum; j++) {
          let defined = this.allInterests[i+j]
          if (defined) { //null check
            tmp[index].push(defined)
          }
        }

        index++;
      }

      this.allInterests = tmp;
    },
    goToCalibrate()
    {
      this.$router.push({name: "microphone_calibration"})
    }

  }
}
</script>
<style scoped>

.interestThumbnail {
  width: 120px;
  height: 120px;
  position: relative;
}

.text-overlay {
  /* margin-top:5%; */

  color:rgb(0, 0, 0);

  font-size: 16px;
}

.interestButtonChecked {
  width: 120px;
  height: 120px;
  overflow: hidden;

  cursor:pointer;

  border: 5px solid rgb(7, 241, 77);

  background-color: #555555;
}

.interestButtonUnChecked {
  width: 120px;
  height: 120px;
  overflow: hidden;

  cursor:pointer;
  
  border: 5px solid rgb(128, 127, 127);
}


</style>