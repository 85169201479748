<template>
  <div class="d-flex flex-column min-vh-100">

    <Navbar  :enableBack=true></Navbar>
    <div class="container">
      <h1 class="display-5">Practise voice and speech sounds </h1>
      <div class="card text-center">
        <!-- <div class="card-body">
          <h5> Follow the videos to practise your pronunciation. </h5>
          <p> Pay attention and stretch the movement of your mouth/tongue/lips for each pronunciation. </p>
          <button class="btn btn-primary w-50 btn-lg" type="button" @click="randomPhoneme()"> Start the exercise</button>
          
          <p class="lead mt-2">To practise a specific sound/words, click <strong style="color:#f56c6c;cursor:pointer" @click="gotoIPA()"> HERE </strong></p>
        </div> -->
        <div class="row">
          <div v-if="issue_flag_dic.voice" class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Sound loudness</p>
                <img :src="loudnessImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="generalNewsDashboard()"> Start the exercise</button>
                <br/> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="gotoPhonation()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('loudness')"> Exercise overview</button>
                <!-- <p>To practise words with specific sounds, <br>click <b style="color:#f56c6c;cursor:pointer" @click="articulationDashboard()"> HERE </b>.</p> -->
              </div>
            </div>
          </div>
          <div v-if="issue_flag_dic.voice" class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong>2</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Pitch variation</p>
                <img :src="pitchImg" width="120" height="120" style="object-fit: cover">
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="fun_facts()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button"  @click="gotoPitch()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('pitch')"> Exercise overview</button>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body">
                <div class="special-badge">
                </div>
                <div class="mask-t">
                  <strong v-if="issue_flag_dic.voice">3</strong>
                  <strong v-else>1</strong>
                </div>
                <p class="lead mt-4" style="font-size: x-large;"> Sound articulation</p>
                <img :src="phonemeImg" width="120" height="120" style="object-fit: cover">
                <!-- <span class="badge rounded-pill bg-danger position-absolute top-0 start-0">NEW</span> -->
                <br/>
                <!-- <button class="btn btn-primary btn-lg mt-2" type="button"  @click="goToQuiz()"> Start the exercise</button> -->
                <button class="btn btn-primary btn-lg mt-2" type="button" @click="randomPhoneme()"> Start the exercise</button>
                <br/>
                <button class="btn btn-outline-dark mt-2" type="button"  @click="gotoBenefit('phoneme')"> Exercise overview</button>
                <p>To practise specific sounds, click <strong style="color:#f56c6c;cursor:pointer" @click="gotoIPA()"> HERE </strong></p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="start_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ module_title }}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body text-center">
                <div class="row justify-content-around">
                  <div class="card card-body">
                    <h5>{{ benfite_statement }}</h5>
                  </div>
                  
                </div>
                <div class="row justify-content-center">
                  <Posture_instructions/>
                </div>
                <div class="row justify-content-center mt-4">
                  <div class="col-3">
                    <button class="btn btn-success w-100" @click="gotoModule()" data-bs-dismiss="modal" >Let's go!</button>
                  </div>
                  <div class="col-3">
                    <button class="btn btn-secondary w-100"  data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <Posture_instructions/> -->
    </div>

    <Footer class="mt-auto"></Footer>
  </div>
</template>
<script>

import Posture_instructions from '../components/posture_instructions.vue'
import Footer from '@/views/Footer.vue'
import Navbar from '@/views/Navbar.vue'
import loudnessImg from '@/assets/images/instructions/loudness.png'
import pitchImg from '@/assets/images/instructions/pitch.png'
import phonemeImg from '@/assets/images/instructions/phoneme_demo.png'
import { Modal } from 'bootstrap'

export default {
  name: 'Practise_mouth_movement',
  components: {Posture_instructions, Footer, Navbar},
  props: 
  {
  },
  data(){
    return {
      userCategory:'',
      region:'',
      explanation:'',
      
      start_object: {
        module_path:'', 
        assessment_type: "USER_TRAINING", 
        module_name: '', 
        current_exercise_index: 0,
      },
      loudnessImg:loudnessImg,
      pitchImg:pitchImg,
      phonemeImg:phonemeImg,
      issue_flag:[false,false,false,false],//speeech,motor,voice,aphasia
      issue_flag_dic:{"speech":false,"motor":false,"voice":false,"aphasia":false},
      conditions:["speech","motor","voice","aphasia"],
      module_title:'',
      benfite_statement:'',
      module_cate:''
    }
  },
  created() {
    this.userCategory = localStorage.getItem('userCategory')
    this.region = localStorage.getItem('region')
    // this.explanation[0] = "Make sure you:\n - are about 40cm/16in away from the screen.\n - have your back upright.\n - have a glass water to sip throughout."
    // this.explanation[1] = "1. You will see a video on how to pronounce a certain sound, follow the video to pratice pronouncing the sound."
    // this.explanation[2] = "2. By clicking 'Next', you will then see a few videos showing word examples that contain such sound."
    // this.explanation[3] = "3. Listen to the video, press 'Start' to record your pronunciation."
    this.explanation = ""
    this.getIssue_flag();
  },
  methods: {
    gotoIPA()
    {
      const _this = this
      _this.$router.push({ name: 'IPA'});
    },
    randomPhoneme()
    {
      this.$router.push({ name: 'mouth_movement' });
    },
    gotoPhonation()
    {
      this.$router.push({ name: 'sound_loudness_loudness_patient' });
    },
    gotoPitch()
    {
      this.$router.push({ name: 'pitch_variation' });
    },
    getIssue_flag()
    {
      if(this.userCategory == 'slp')
      {
        for(let i in this.issue_flag)
        {
          this.issue_flag[i] = true
          this.issue_flag_dic[this.conditions[i]] = true
          console.debug('issue flag dic= '+this.issue_flag_dic)
        }
      }
      else
      {
        let temp_iss_flag = localStorage.getItem('issue_flag');

        if(temp_iss_flag!=undefined && temp_iss_flag!=null)
        {
          let issue_flag_arr = temp_iss_flag.split(',')
          this.issue_flag = [];
          for(let i=0;i<issue_flag_arr.length;i++)
          {
              let flag = (issue_flag_arr[i] == 'true')
              this.issue_flag.push(flag);
              console.debug('condition= '+this.conditions[i])
              this.issue_flag_dic[this.conditions[i]] = flag
              console.debug('issue flag dic= '+this.issue_flag_dic[this.conditions[i]])

          }
          console.debug('issue at word pop= '+this.issue_flag);
        }
      }
    },
    gotoBenefit(cate)
    {
      this.module_cate = cate;
      if(cate == 'loudness')
      {
        this.module_title = 'Sound loudness'
        this.benfite_statement = 'This exercise aims to help you increase the length of time that you can speak for between breaths, and improve your volume consistency.'
      }
      else if(cate == 'pitch')
      {
        this.module_title = 'Pitch variation'
        this.benfite_statement = 'This exercise helps you practise varying your pitch. It aims to improve your pitch control and range.'
      }
      else if(cate == 'phoneme')
      {
        this.module_title = 'Speech sound articulation'
        this.benfite_statement = 'This exercise helps you practise articulating speech sounds and specific words containing the sounds. It aims to improve muscle control to make sounds correctly, and improve intelligibility.'
      }
      else
      {
        console.log('unknown category = '+cate)
      }
      let start_pop = new Modal(document.getElementById('start_pop'));
      start_pop.show()
    },
    gotoModule()
    {
      if(this.module_cate == 'loudness')
      {
        this.gotoPhonation()
      }
      else if(this.module_cate == 'pitch')
      {
        this.gotoPitch()
      }
      else if(this.module_cate == 'phoneme')
      {
        this.randomPhoneme()
      }
      else
      {
        console.log('unknown category = '+this.module_title)
      }
    }
  }

}
</script>
<style scoped>
.special-badge {
    position: absolute;
    right: 0;
    top: 0;
    border-top: 70px solid lightblue;
    border-left: 70px solid transparent;
}
.mask-t {
    color: #fff;
    font-size: x-large;
    position: absolute;
    width: 100px;
    height: 100px;
    right: 0px;
    top: 0px;

}
.mask-t strong {
    display: block;
    width:100%;
    height:100%;
    line-height: 100px;
    text-align: center;
    /* -webkit-transform:  translate(0, -25%); */
    /* -moz-transform: translate(0, -25%); */
    /* -ms-transform: translate(0, -25%); */
    /* -o-transform:  translate(0, -25%); */
    transform: translate(27%, -27%);
}
</style>