export function LevenshteinDistance(str1,str2)
{

    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()

    return LevenshteinDistanceNoLower(str1,str2)

    
}

export function LevenshteinDistanceNoLower(str1,str2)
{
    
    
    const track = Array(str2.length + 1).fill(null).map(() =>
    Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator, // substitution
            );
        }
    }
    return track[str2.length][str1.length];
}


import {getData} from '@/firebase/index'


export async function LevenshteinDistanceWord_Phonemes(str1,phonetic_1,str2)
{


    
    str2 = str2.toLowerCase()
    const promiseArray = []


    promiseArray.push(get_phonetic_transcription(str2))


    const all_results = await Promise.all(promiseArray)

    let phonetic_2 = all_results[0]


    if(phonetic_1==undefined || phonetic_1==null || phonetic_2==undefined || phonetic_2==null)
    {

        let value_written = LevenshteinDistance(str1.toLowerCase(),str2.toLowerCase())
        console.debug(str1.toLowerCase() + '/' + str2.toLowerCase() + ' = ' + value_written)
        return {'distance':value_written,'phonetic':phonetic_2}
    }
    else
    {

        let value_phonetic = LevenshteinDistanceNoLower(phonetic_1,phonetic_2)

        value_phonetic = value_phonetic/Math.max(phonetic_1.length,1)


        let value_written = LevenshteinDistance(str1.toLowerCase(),str2.toLowerCase())

        value_written = value_written/Math.max(str1.length,1)

        let value =  Math.min(value_phonetic,value_written)

        return {'normalised_distance':value,'phonetic':phonetic_2}


    }
}


export async function LevenshteinDistancePhoneme(str1,str2)
{


    
    str1 = str1.toLowerCase()
    str2 = str2.toLowerCase()
    const promiseArray = []


    promiseArray.push(get_phonetic_transcription(str1))
    promiseArray.push(get_phonetic_transcription(str2))


    const all_results = await Promise.all(promiseArray)

    let phonetic_1 = all_results[0]
    let phonetic_2 = all_results[1]

    if(phonetic_1==null || phonetic_2==null)
    {
        return LevenshteinDistance(str1.toLowerCase(),str2.toLowerCase())
    }
    else
    {

        let value_phonetic = LevenshteinDistanceNoLower(phonetic_1,phonetic_2)

        let value_written = LevenshteinDistance(str1.toLowerCase(),str2.toLowerCase())

        let value =  Math.min(value_phonetic,value_written)

        return value


    }
}

export function get_phonetic_transcription(str1)
{

    let address = 'Exercises/Resources/Dictionary/'+str1

    let phonetic = new Promise(function(resolve)
    {
        getData(address , function (err, result) {

            let word = result.val()

            if(word==undefined || word==null)
            {
                resolve(null)
            }
            else
            {
                let pronunciation = word['pronunciation']

                for(let k in pronunciation)
                {
                    pronunciation[k] = pronunciation[k].replace(/[0-9]/g, '');

                }


                resolve(word['pronunciation'])
            }




        })
    })

    return phonetic


}



  


