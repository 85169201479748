<template>
  <div class="row mt-2" v-if="pitch_range && pitch_range.length > 0">
    <div class="card card-body">
      <div>
        <p class="h4 text-start">Pitch range results</p>
      </div>
      <table class="table mt-4" >
        <thead>
          <tr>
            <th scope="col">Exercise Name</th>
            <th scope="col">Loudness (dB)</th>
            <th scope="col">Pitch Range (Hz)</th>
            <th scope="col">Maximum Pitch (Hz)</th>
            <th scope="col">Minimum Pitch (Hz)</th>
            <th scope="col">Average Pitch (Hz)</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row, index in pitch_range" v-bind:key="index">
            <td>{{ row.Name }}</td>
            <td>{{ row.loudness<50?'<50':row.loudness }}</td>

            <td>{{ row.freq_range }}</td>
            <td>{{ row.max_freq }}</td>
            <td>{{ row.min_freq }}</td>
            <td>{{ row.mean_freq }}</td>
            <td>
              <Audio_Playback :audio_address="row['audio_address']"></Audio_Playback>
            </td>
          </tr>
          <tr class="table-group-divider" v-show="pitch_range.length > 1">
            <th scope="col">Average value</th>
            <th scope="col">{{ (pitch_range.reduce((sum, value) => sum + value.loudness, 0) / pitch_range.length).toFixed(0) }}</th>
            <th scope="col">{{ (pitch_range.reduce((sum, value) => sum + value.freq_range, 0) / pitch_range.length).toFixed(0) }}</th>
            <th scope="col">{{ (pitch_range.reduce((sum, value) => sum + value.max_freq, 0) / pitch_range.length).toFixed(0) }}</th>
            <th scope="col">{{ (pitch_range.reduce((sum, value) => sum + value.min_freq, 0) / pitch_range.length).toFixed(0) }}</th>
            <th scope="col">{{ (pitch_range.reduce((sum, value) => sum + value.mean_freq, 0) / pitch_range.length).toFixed(0) }}</th>
          </tr>
        </tbody>
      </table> 
    </div>
  </div>
</template>
  
<script>

  import {ref, onUpdated, onMounted} from 'vue'
  import firebase from 'firebase/app'
  import Audio_Playback from '@/views/common/playback/Audio_Playback.vue';

  export default {
    name: 'pitch_range',
    components: {
      Audio_Playback
    },
    props: {
      pitch_range: {
        type: Object,
        default: null
      },
    },
    setup(props) {

      
      onMounted( () => {

      });

      return {

      };

    }
  }
</script>