
export function processCookieTheftInfo(all_words)
{
  
  let word_types = {'characters':'#ff00ff','objects':'#FF9D5C','actions':'#47A7F4'}

  let processed_info = {}
  for(let k in word_types)
  {
    processed_info[k] = {}
  }

  for(let n in all_words)
  {
    let identified_word_type = all_words[n]['identified_word_type']

    if(identified_word_type in word_types)
    {
      
      all_words[n]['colour'] = word_types[identified_word_type]



      let word = all_words[n]['word']

      console.debug(word + ' ' + identified_word_type)
      if(word in processed_info[identified_word_type])
      {
        processed_info[identified_word_type][word]['count'] = processed_info[identified_word_type][word]['count']+1


      }
      else
      {
        processed_info[identified_word_type][word] = {'count':1}

      }

      
    }
    else
    {
      all_words[n]['colour'] = '#000000'
    }
  }

  return [processed_info,all_words]

}
