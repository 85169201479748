<template>
  <div>
    <h1 class="display-3 mt-4"><strong>Select Reminder Frequency</strong></h1>

    <div class="row mt-2">
      <p>How frequently we should remind you to do exercises?</p>
    </div>

    <div class="row mt-4">
      <div class="col-md-3" style="cursor: pointer;" @click="select_frequency('3')">
        <div class="card card-body text-center" 
        :style="{'background-color': selected_frequency == '3' ? 'rgba(102, 51, 153, 0.4)' : '', 
           border: '2px solid #ccc'}">
          <p>Every 3 days</p>
        </div>
      </div>
      <div class="col-md-3" style="cursor: pointer;" @click="select_frequency('5')">
        <div class="card card-body text-center" 
        :style="{'background-color': selected_frequency == '5' ? 'rgba(102, 51, 153, 0.4)' : '', 
           border: '2px solid #ccc'}">
          <p>Every 5 days</p>
        </div>
      </div>
      <div class="col-md-3" style="cursor: pointer;" @click="select_frequency('7')">
        <div class="card card-body text-center" 
        :style="{'background-color': selected_frequency == '7' ? 'rgba(102, 51, 153, 0.4)' : '', 
           border: '2px solid #ccc'}">
          <p>Every 7 days</p>
        </div>
      </div>
      <div class="col-md-3" style="cursor: pointer;" @click="select_frequency('0')">
        <div class="card card-body text-center" 
        :style="{'background-color': selected_frequency == '0' ? 'rgba(102, 51, 153, 0.4)' : '', 
           border: '2px solid #ccc'}">
          <p>Don't nudge me</p>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div class="row mt-4 justify-content-center">
      <div class="col-12 text-center">
        <button class="btn btn-primary rounded-pill px-4" @click="next()">Continue</button>
      </div>
    </div>

  </div>
</template>

<script>
import {ref, watch} from 'vue'
import {updateUserData} from '@/firebase/index'

export default {
  name: 'Reminder_Frequency',
  setup(props, { emit }) {

    const selected_frequency = ref("3")

    if (sessionStorage.getItem('selected_notification_frequency')) {
      selected_frequency.value = sessionStorage.getItem('selected_notification_frequency')
    }
    
    const next = () => {

      sessionStorage.setItem('selected_notification_frequency', selected_frequency.value)
      updateUserData('Data', {'notification_frequency' : selected_frequency.value})

      updateUserData('Data/onboarding', {'current_stage_index': 6, 'finished': true})
      emit('next')
    }

    const select_frequency = (days) => {
      selected_frequency.value = days

      updateUserData('Data', {'notification_frequency' : days})
    }

    return {
      selected_frequency,

      next,
      select_frequency
     
    }
  }
}
</script>

<style scoped>

</style>
