<template>
  <div>
    <div class="row mt-2">
      <div class="card card-body" v-show="results['suggestionData'] && results['suggestionData'].length > 0">
        <div>
          <p class="h4 text-start">Training suggestions</p>
        </div>
        <table class="table mt-4" >
          <thead>
            <tr>
              <th scope="col" v-if="enable_accuracy">Accuracy</th>
              <th scope="col">Suggestions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row, index in results['suggestionData']" v-bind:key="index">
              <td v-if="enable_accuracy">{{ row.score }}</td>
              <td>{{ row.suggestion }}</td>
              <td><button class="btn btn-primary" v-if="results['funcFinished'][index]!=='none'" @click="practice(index)"> Practise it </button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Training_popup
      :showTraining="training_flag"
      :phoneme_exercise_list="phoneme_exercise_list"
      :target_phoneme="target_phoneme"
      @closeTraining="training_flag=false"
      >
    </Training_popup>
</div>
</template>
<script>


import {searchExamplesWithPhoneme} from '@/utils/results/parsers/intelligibility'
import Training_popup from './training_popup.vue'
import { useTTS } from '@/stores/tts'

export default {
  components: { Training_popup},

  name: 'Practise',
  props: {
    results: {},
    enable_accuracy: false
  },
  data() {
    return {
      phoneme_exercise_list: [],
      target_phoneme: '',


      training_flag:false,

      bv_tts: useTTS().tts_engine,
    }
  },
  created(){
  },
  mounted() {
  },
  methods: {
    practice(index)
    { 
      if(this.results['funcFinished'][index] == 'word')
      {
        // XXX to be programmed: find corresponding words (videos not existing)  
      }
      else if(this.results['funcFinished'][index] == 'pho')
      {
        let phoneme = this.results['suggestionList'][index]

        this.target_phoneme = phoneme;

        let promise = searchExamplesWithPhoneme(phoneme)

        promise.then((value) => {
          this.phoneme_exercise_list = value

          if(this.phoneme_exercise_list.length > 0)
          {
            this.training_flag = true
          } else {
            alert("No exercises available for phoneme /" + phoneme + "/ yet. The developers will be adding it as soon as possible.")
          }

          //XXX to be programmed: find the corresponding phoneme in articulation_consonants + find the corresponding phoneme in articulation_words
          //XXX create a base exercise plan
          console.debug('training_flag= '+this.training_flag)
        })
      } else {
        this.training_flag = false;
        alert('Error_post_suggestions. Contact the developers.');
      }
    },
  },
}
</script>
<style scoped>
  .custom-text{
    font-size: 22px;
  }
  .custom-text20{
    font-size: 20px;
  }
  .title_txt {
    float: left;
    line-height: 13mm;
    margin-left: 2%;
    font-size: 30px;
  }
</style>