<template>
  <div class="d-flex flex-column min-vh-100">
    <nav class="navbar navbar-expand-md navbar-dark align-items-end" style="background-color: #0D302A;">
      <div class="container-fluid align-items-end">
        <a class="navbar-brand v-step-0" @click="$router.push({name: 'home'})" style="cursor:pointer">
          <img src="../../assets/images/logo/logo.svg" alt="" width="225" class="d-inline-block align-text-top">
        </a>
      </div>
    </nav>
    <div class="container">
      <h1 class="display-4 mt-4">Company Information</h1>
      <div class="row ms-4 mt-4 mb-4">
        <h4 class="mt-2">Beautiful Voice Limited is a company registered in England and Wales.</h4>
        <h5 class="mt-4"><strong>Registered number:</strong> 13863314</h5>
        <h5><strong>Address:</strong></h5>
        <h5>9a Springfield Road</h5>
        <h5>Oxford</h5>
        <h5>England, OX2 9HJ</h5>
        <h5 class="mt-4"><strong>Email:</strong> contact@beautifulvoice.co.uk</h5>
      </div>
    </div>
    <Footer class="mt-auto"></Footer>
  </div>
</template>

<script>

import Footer from '@/views/Footer.vue'
export default {
  props: {

  },
  components: {Footer},
  name: "Company",
  data() {
    return {
    }
  },
  created () {
  },
  destroyed () {
  },
  mounted () {
  },
 
}
</script>

<style scoped>

#footer {
  position: fixed;
  height: 50px;
  background-color: red;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

</style>