<template>
    <div>
      <div class="row">
        <div v-for="pindex in Array.from({ length: total_stages }, (_, i) => i)" class="col">
          <div class="progress" role="progressbar" style="height: 20px">
            <div v-if="pindex == current_stage_index" class="progress-bar" :style="{'width': current_stage_progress + '%'}"></div>            
            <div v-else-if="pindex <= current_stage_index" class="progress-bar" style="width: 100%"></div>
            <div v-else class="progress-bar" style="width: 0%"></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {ref, toRefs} from 'vue'
  
  export default {
    name: 'ProgressIndicator',
    components: {

    },
    props: {
      total_stages: {
        type: Number,
        required: true
      },
      current_stage_index: {
        type: Number,
        required: true
      },
      current_stage_progress: {
        type: Number,
        required: true
      }
    },
    setup(props, { emit }) {

      const { total_stages, current_stage_index, current_stage_progress } = toRefs(props)


      return {
        total_stages,
        current_stage_index,
        current_stage_progress
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  