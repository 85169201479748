<template>
  <div>
      <Goals></Goals>
  </div>
</template>

<script>

import Goals from '../../../components/goals.vue'

export default {
    name: 'Goals_page',
    
    props: {
    },
    components: {Goals},
    data() {
      return {

      }
    },
    created() {
      
    },
    methods: 
    {

    }
}
</script>

<style scoped>

</style>