<template>
  <div>
    <loudness_indicator_section :results="results"></loudness_indicator_section>
    <correct_incorrect_section :results="results"></correct_incorrect_section>
    <pitch_section :results="results"></pitch_section>
    <intellligibility_section :results="results"></intellligibility_section>
    <speech_section :results="results"></speech_section>
    <questionnaire_section :results="results"></questionnaire_section>
    <cookie_theft_section :results="results"></cookie_theft_section>
    <cats_section :results="results"></cats_section>


    <misc_section :results="results"></misc_section>

    <Practise :results="results" :enable_accuracy="false"/>
  </div>
</template>
<script>

import loudness_indicator_section from '@/views/session/Result/sections/loudness_indicator_section.vue'
import correct_incorrect_section from '@/views/session/Result/sections/correct_incorrect_section.vue'
import pitch_section from '@/views/session/Result/sections/pitch_section.vue'
import intellligibility_section from '@/views/session/Result/sections/intelligibility_section.vue'
import speech_section from '@/views/session/Result/sections/speech_section.vue'
import cats_section from '@/views/session/Result/sections/cats_section.vue'

import questionnaire_section from '@/views/session/Result/sections/questionnaire_section.vue'
import misc_section from '@/views/session/Result/sections/misc_section.vue'
import cookie_theft_section from '@/views/session/Result/sections/cookie_theft_section.vue'


import Practise from '@/views/session/Result/practise_it/practise.vue'


export default {
  components: { 
    loudness_indicator_section,
    correct_incorrect_section,
    pitch_section,
    intellligibility_section,
    speech_section,
    cats_section,
    questionnaire_section,
    misc_section,
    cookie_theft_section,
    Practise
  },
  name: 'result_sections',
  props: ['results'],
  setup(props, {emit}) {

    return {
    }
  }
}
</script>
<style scoped>
</style>