<template>
    <div class="row">
      <div class="col-md-10">
        <div class="card card-body h-100"> 
          <div class="row">
            <p class="text-30"><strong>Find the hidden words</strong>
                <img :src="audioImg" style="width:35px;cursor: pointer;" @click="bv_tts.say('Find the hidden words')">
            </p>

          </div>
          <div class="row">
            <div class="col-7">

                <div v-for="dummy, row in letter_matrix" class="row mt-2  mb-2 justify-content-center">
                    <button v-for="letter, index in letter_matrix[row]" type="text" @click=select_letter(letter,row,index) :class="option_buttons_class[row][index]" >
                    {{letter_matrix[row][index]}}
                    </button>
                </div>
            
            </div>
            <div class="col-5">

                <div v-for="word in word_list" class="row mt-2  mb-2 justify-content-center">
                    <h1 class="display-5">{{word}}
                        <img :src="audioImg" style="width:35px;cursor: pointer;" @click="bv_tts.say(word)"></h1>

                </div>
                <div v-for="word in found_word_list" class="row mt-2  mb-2 justify-content-center">
                    <h1 class="display-5"><s>{{word}}</s>
                        <img :src="audioImg" style="width:35px;cursor: pointer;" @click="bv_tts.say(word)"></h1>

                </div>


  
                
            </div>
          </div>
        </div>
      </div>

      
      <div class="modal fade" id="result_pop" tabindex="-1">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{level_up_statement}}</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="row m-2">
                  <div class="row">
                    <h4 >{{level_up_detail}}</h4>

                  </div>
                  <div class="row mt-2">
                    <button class="btn btn-success w-100"    @click="nextExercise()" data-bs-dismiss="modal" >Next exercise</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <div class="col-md-2" style="padding-left: 0px">
        <div class="card card-body h-100">
          <p class="lead">Exercise {{current_exercise_index}} of {{total_exercises}}</p>
          <button class="btn btn-info btn-lg w-100 mt-2" @click="nextExercise()">Next</button>
          <button v-if="!first_exercise" class='btn btn-outline-warning btn-lg w-100 mt-4' @click="goToResults()">See your results</button>
        </div>
      </div>
    </div>
  </template>
  

<script>
    
    import { Modal } from 'bootstrap'
    import {logActivity,getTodayString, getWeekNumber} from '@/media_input/utils'
    import {getUserDataDir,getData,updateDataDirectory} from '@/firebase/index'
    import { useTTS } from '@/stores/tts'
    import {startConfetti} from '@/common_js/confetti'
    import audioImg from '@/assets/images/audio_logo.png'

    import {save_pre_computed_results} from '@/utils/results/data_save_utils'

    export default {
      props: { 
  
        exForm: {
          type: Object,
          },
  
      },
      name: 'Word_guessing',
      data() {
        return {
            level_type:'user_level',
            audioImg:audioImg,
            level_up_statement:'You are doing really well! We will increase the difficulty a little for you.',
            level_up_detail:'',
            first_exercise:true,
            current_exercise_index:1,
            total_exercises:1,
            option_buttons_class:[[]],
            letter_matrix:[[]],
            selected_letters:[],
            selected_positions:[],
            results:{correct:0,incorrect:0,total:0},
            level_properties:{
            1:{'level':1,'difficulty':'easy','n_words':1,'word_length':3,'height_width':3},
            2:{'level':2,'difficulty':'easy','n_words':2,'word_length':3,'height_width':3},
            3:{'level':3,'difficulty':'easy','n_words':2,'word_length':3,'height_width':4},
            4:{'level':4,'difficulty':'easy','n_words':3,'word_length':3,'height_width':4},
            5:{'level':5,'difficulty':'medium','n_words':2,'word_length':3,'height_width':5},
            6:{'level':6,'difficulty':'medium','n_words':3,'word_length':3,'height_width':6},
            7:{'level':7,'difficulty':'hard','n_words':3,'word_length':3,'height_width':6}},
            base_level_address: '/Levels/Word_Search',    
            level_address:'',
            level_up_counter:0,
            level_up_target:3,
            letters: 'abcdefghijklmnoprstuvwy',
            difficulty:'easy',
            allOrientations: ['horizontal','horizontalBack','vertical','verticalUp',
                           'diagonal','diagonalUp','diagonalBack','diagonalUpBack'],
            word_list:[],
            found_word_list:[],
            three_word_list:['dog','cat','sea','tea','bee','pig','bat','pot','fog','ant',
            'sad','mad','bag','bet','cut','cry','lip','get','set','rye',
            'pie','tie','hot','not','try','bun','pea','met','mat','put',
            'fur','flu','bet','vet','hit','fig','rot','nod','hat','mud',
            'bug','hut','gut','day','say','lay','pay','bay','cop',
            'jug','mug','new','old','was','may','ore'],
            n_words:2,
            orientations: 
            {
                horizontal:     function(x,y,i) { return {x: x+i, y: y  }; },
                horizontalBack: function(x,y,i) { return {x: x-i, y: y  }; },
                vertical:       function(x,y,i) { return {x: x,   y: y+i}; },
                verticalUp:     function(x,y,i) { return {x: x,   y: y-i}; },
                diagonal:       function(x,y,i) { return {x: x+i, y: y+i}; },
                diagonalBack:   function(x,y,i) { return {x: x-i, y: y+i}; },
                diagonalUp:     function(x,y,i) { return {x: x+i, y: y-i}; },
                diagonalUpBack: function(x,y,i) { return {x: x-i, y: y-i}; }
            },
            checkOrientations: 
            {
                horizontal:     function(x,y,h,w,l) { return w >= x + l; },
                horizontalBack: function(x,y,h,w,l) { return x + 1 >= l; },
                vertical:       function(x,y,h,w,l) { return h >= y + l; },
                verticalUp:     function(x,y,h,w,l) { return y + 1 >= l; },
                diagonal:       function(x,y,h,w,l) { return (w >= x + l) && (h >= y + l); },
                diagonalBack:   function(x,y,h,w,l) { return (x + 1 >= l) && (h >= y + l); },
                diagonalUp:     function(x,y,h,w,l) { return (w >= x + l) && (y + 1 >= l); },
                diagonalUpBack: function(x,y,h,w,l) { return (x + 1 >= l) && (y + 1 >= l); }
            },
            skipOrientations: 
            {
                horizontal:     function(x,y,l) { return {x: 0,   y: y+1  }; },
                horizontalBack: function(x,y,l) { return {x: l-1, y: y    }; },
                vertical:       function(x,y,l) { return {x: 0,   y: y+100}; },
                verticalUp:     function(x,y,l) { return {x: 0,   y: l-1  }; },
                diagonal:       function(x,y,l) { return {x: 0,   y: y+1  }; },
                diagonalBack:   function(x,y,l) { return {x: l-1, y: x>=l-1?y+1:y    }; },
                diagonalUp:     function(x,y,l) { return {x: 0,   y: y<l-1?l-1:y+1  }; },
                diagonalUpBack: function(x,y,l) { return {x: l-1, y: x>=l-1?y+1:y  }; }
            },
            options: 
            { 
                height: 4, 
                width:4, 
                fillBlanks: true
            },
            bv_tts: useTTS().tts_engine,


        }
      },
      created() 
      {
        const _this = this
        this.userCategory = localStorage.getItem('userCategory');


        _this.bv_tts.say_sentences(['Find the hidden words'])

        // this.bv_tts.say('')


        _this.show_results = (_this.exForm.current_exercise_index-_this.exForm.current_exercise_index)>=3

        _this.current_exercise_index = _this.exForm.current_exercise_index+1

        _this.total_exercises = _this.exForm.total_all_type_exercises

        _this.first_exercise = !_this.exForm.has_results


        this.getUserLevel()
  
      },
      methods: 
      {
        setUserLevel()
        {


        },
        getUserLevel()
        { 
            const _this = this

            let do_once = true

            let userType = localStorage.getItem('userCategory');


            let uid = localStorage.getItem('uid');

            this.level_address = '/Users/' + uid + this.base_level_address


            if(userType!=undefined || userType==='slp')
            {
                let client_number = localStorage.getItem('client_uid');

                if(client_number!='undefined' && client_number!=null && client_number!='')
                {

                    this.level_address = '/Users/'+ client_number + this.base_level_address

                }
            }


            getData(_this.level_address, function (err, result) {

                if(do_once)
                {
                    do_once = false
                    
                    let current_level = result.child('current_level').val()
                    let lvl_up_counter = result.child('level_up_counter').val()


                    if(current_level==undefined || lvl_up_counter==undefined)
                    {
                        current_level = 1
                        lvl_up_counter = 0
                        updateDataDirectory(_this.level_address,{current_level:current_level,level_up_counter:lvl_up_counter})
                    }

                    _this.level_up_counter = lvl_up_counter

                    if(current_level<1 || current_level>_this.level_properties.length)
                    {
                        current_level = 1
                    
                    }
                    
            
                    _this.current_level = current_level


                    _this.curr_level_properties = _this.level_properties[_this.current_level]

                    _this.difficulty = _this.curr_level_properties['difficulty']
                    _this.n_words = _this.curr_level_properties['n_words']
                    _this.options.height = _this.curr_level_properties['height_width']
                    _this.options.width = _this.curr_level_properties['height_width']

                    


                    _this.create_word_list()

                    _this.setDirections()

                    let puzzle = _this.newPuzzle(_this.word_list,_this.options)
                    _this.print(puzzle)

                    _this.letter_matrix = puzzle
                }            
            })


        },
        updateLevel(correct)
        {

            let new_level = false


            if(correct)
            {
                this.level_up_counter++
                if(this.level_up_counter == this.level_up_target)
                {
                    this.level_up_counter = 0
                    this.current_level++

                    if(this.current_level>this.level_properties.length)
                    {
                        this.current_level = this.level_properties.length
                    }
                    else
                    {
                        new_level = true
                        // this.level_up_message = 'You have been doing really well! We will increase the difficulty level a little for you.'
                        // let level_up_pop = new Modal(document.getElementById('level_up_pop'));
                        // level_up_pop.show()

                    }

            }
            }
            else
            {
                this.level_up_counter--

                if(this.level_up_counter<0)
                {
                    this.level_up_counter = 0
                }
            }

            updateDataDirectory(this.level_address,{current_level:this.current_level,level_up_counter:this.level_up_counter})

            return new_level

        },

        create_word_list()
        {
            let mother_word_list = this.three_word_list

            this.word_list = []

            let arr = []

            while(arr.length < this.n_words){
                var r = Math.floor(Math.random() * mother_word_list.length) ;
                if(arr.indexOf(r) === -1) 
                {
                    arr.push(r)
                    this.word_list.push(mother_word_list[r])
                };
            }

            console.debug('this.word_list = ' + this.word_list)


        },
        setDirections()
        {

            if(this.difficulty==='very hard')
            {
                this.allOrientations = ['horizontal','horizontalBack','vertical','verticalUp',
                           'diagonal','diagonalUp','diagonalBack','diagonalUpBack']
                
            }
            else if(this.difficulty==='hard')
            {
                this.allOrientations = ['horizontal','horizontalBack','vertical','verticalUp',
                           'diagonal','diagonalUp']


            }
            else if(this.difficulty==='medium')
            {
                this.allOrientations = ['horizontal','horizontalBack','vertical','verticalUp']
            }
            else
            {
                this.allOrientations = ['horizontal','vertical']
            }

        },
        fillPuzzle(words, options) 
        {

            var puzzle = [], i, j, len;
            console.log('options = ', options);

            this.option_buttons_class = []

            // initialize the puzzle with blanks
            for (i = 0; i < options.height; i++) {
                puzzle.push([]);
                this.option_buttons_class.push([]);

                for (j = 0; j < options.width; j++) {
                    puzzle[i].push('');
                    this.option_buttons_class[i].push('keyboard-button');

                }
            }

            // add each word into the puzzle one at a time
            for (i = 0, len = words.length; i < len; i++) {
                if (!this.placeWordInPuzzle(puzzle, options, words[i])) {
                    // if a word didn't fit in the puzzle, give up
                    return null;
                }
            }

            // return the puzzle
            return puzzle;
        },
        placeWordInPuzzle(puzzle, options, word) 
        {

            // find all of the best locations where this word would fit
            var locations = this.findBestLocations(puzzle, options, word);

            if (locations.length === 0) {
                return false;
            }

            // select a location at random and place the word there
            var sel = locations[Math.floor(Math.random() * locations.length)];
            this.placeWord(puzzle, word, sel.x, sel.y, this.orientations[sel.orientation]);

            return true;
        },
        select_letter(letter,row,index)
        {

            let found_anything = false
            for(let k in this.selected_positions)
            {
                if(this.selected_positions[k].row==row && this.selected_positions[k].index==index)
                {
                    console.log('repeat')

                    found_anything = true

                    this.option_buttons_class[row][index] = 'keyboard-button'

                    this.selected_positions.splice(k,1)
                    this.selected_letters.splice(k,1)
                }
            }
            
            if(!found_anything)
            {
                this.selected_positions.push({'row':row,'index':index})
                this.selected_letters.push(letter)

                this.option_buttons_class[row][index] = 'keyboard-button-selected'
            }

            this.$forceUpdate()

            let found_word = false
            let right_word_size = false

            for(let n in this.word_list)
            {
                let word = this.word_list[n]
                if(word.length==this.selected_letters.length)
                {
                    right_word_size = true
                    let wrong_letter = false
                    for(let k in word)
                    {
                        let letter = word[k]
                        if(!this.selected_letters.includes(letter))
                        {
                            wrong_letter = true
                        }
                    }

                    if(!wrong_letter)
                    {

                        found_word = true

                        this.found_word_list.push(word)

                        this.word_list.splice(n,1)

                        for(let k in this.selected_positions)
                        {
                            let row = this.selected_positions[k].row
                            let index = this.selected_positions[k].index
                            this.option_buttons_class[row][index] = 'keyboard-button-correct'
                        }

                        console.log('word_list = ' + this.word_list)

                        break
                    }
                }
            }

            if(right_word_size)
            {
                for(let k in this.selected_positions)
                {
                    row = this.selected_positions[k].row
                    index = this.selected_positions[k].index


                    if(found_word)
                    {
                        this.option_buttons_class[row][index] = 'keyboard-button-correct'

                    }
                    else
                    {
                        if(this.option_buttons_class[row][index] !== 'keyboard-button-correct')
                        {

                            this.option_buttons_class[row][index] = 'keyboard-button'
                        }

                    }
                }
                this.selected_positions = []
                this.selected_letters = []
                

                if(!found_word)
                {
                    this.foundWrongWordMessage()
                    
                }
                else
                {
                
                    this.foundWordMessage()
                }
            }

            if(this.word_list.length==0)
            {
                this.foundAllWords()

            }

        },
        foundWordMessage()
        {
            this.results['correct'] = this.results['correct'] + 1
            this.results['total'] = this.results['total'] + 1 



            let correct_text = 'Correct. ' + this.word_list.length + ' words left.'

            if(this.word_list.length==1)
            {
                correct_text = 'Correct. ' + this.word_list.length + ' word left.'

            }

            if(this.word_list.length>0)
            {
                this.bv_tts.say_sentences([correct_text])

            }




        },
        foundWrongWordMessage()
        {

            this.results['incorrect'] = this.results['incorrect'] + 1
            this.results['total'] = this.results['total'] + 1 

            this.bv_tts.say_sentences(['Not quite right. Try again.'])


        },
        foundAllWords()
        {

            
            let new_level = false

            if(this.results['incorrect']==0)
            {
                new_level = this.updateLevel(true)

            }
            else
            {
                new_level = this.updateLevel(false)
            }

            if(new_level)
            {
                this.bv_tts.say_sentences([this.level_up_statement])

                let result_pop = new Modal(document.getElementById('result_pop'));
                result_pop.show()


            }
            else
            {

                this.bv_tts.say_sentences(['Congrats, you found all the words!'])
                startConfetti(this)
                setTimeout(() => {
                    this.nextExercise()
                }, 3500);


            }

    
        },
        nextExercise()
        {
            const _this = this;

            _this.saveResults()


            
            _this.$emit('nextExercise');



        },
        saveResults()
        {
            const _this = this;

            if(this.results['total']>0)
            {
                let all_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect'],Total:_this.results['total']}

                let plot_results = {Correct:_this.results['correct'],Incorrect:_this.results['incorrect']}
                let results = {all_results:all_results, plot_results:plot_results}

                var wordSearchData = {resultsTablePre: {exercise_type: 'Word Search', exercise: 'Word Search', results: results, audio_address:''}}

                save_pre_computed_results(_this.exForm, _this.$firebase, wordSearchData)

                _this.$emit('logExerciseResults', wordSearchData);

            }


        },
        goToResults()
        {
            const _this = this

            _this.saveResults()
            var goToResultsData = {curr_ex_done:_this.curr_ex_done}
            
            _this.$emit('goToResults', goToResultsData);


        },


        findBestLocations(puzzle, options, word) 
        {

            var locations = [],
                height = options.height,
                width = options.width,
                wordLength = word.length,
                maxOverlap = 0; // we'll start looking at overlap = 0

            // loop through all of the possible orientations at this position
            for (var k = 0, len = options.orientations.length; k < len; k++) {
            
            var orientation = options.orientations[k],
                check = this.checkOrientations[orientation],
                next = this.orientations[orientation],
                skipTo = this.skipOrientations[orientation],
                x = 0, y = 0;

            // loop through every position on the board
                while( y < height ) 
                {

                    // see if this orientation is even possible at this location
                    if (check(x, y, height, width, wordLength)) {

                    // determine if the word fits at the current position
                    var overlap = this.calcOverlap(word, puzzle, x, y, next);

                    // if the overlap was bigger than previous overlaps that we've seen
                    if (overlap >= maxOverlap || (!options.preferOverlap && overlap > -1)) 
                    {
                        maxOverlap = overlap;
                        locations.push({x: x, y: y, orientation: orientation, overlap: overlap});
                    }

                    x++;
                    if (x >= width) 
                    {
                        x = 0;
                        y++;
                    }
                    }
                    else 
                    {
                        // if current cell is invalid, then skip to the next cell where
                        // this orientation is possible. this greatly reduces the number
                        // of checks that we have to do overall
                        var nextPossible = skipTo(x,y,wordLength);
                        x = nextPossible.x;
                        y = nextPossible.y;
                    }
                }
            }

            // finally prune down all of the possible locations we found by
            // only using the ones with the maximum overlap that we calculated
            return options.preferOverlap ?
                this.pruneLocations(locations, maxOverlap) :
                locations;
        },
        calcOverlap(word, puzzle, x, y, fnGetSquare) 
        {
            var overlap = 0;

            // traverse the squares to determine if the word fits
            for (var i = 0, len = word.length; i < len; i++) {

                var next = fnGetSquare(x, y, i),
                    square = puzzle[next.y][next.x];
                
                // if the puzzle square already contains the letter we
                // are looking for, then count it as an overlap square
                if (square === word[i]) 
                {
                    overlap++;
                }
                // if it contains a different letter, than our word doesn't fit
                // here, return -1
                else if (square !== '' ) 
                {
                    return -1;
                }
            }

            // if the entire word is overlapping, skip it to ensure words aren't
            // hidden in other words
            return overlap;
        },
        pruneLocations(locations, overlap) {

            var pruned = [];
            for(var i = 0, len = locations.length; i < len; i++) 
            {
                if (locations[i].overlap >= overlap) 
                {
                    pruned.push(locations[i]);
                }
            }

            return pruned;
        },
        placeWord(puzzle, word, x, y, fnGetSquare) 
        {
            for (var i = 0, len = word.length; i < len; i++) 
            {
                var next = fnGetSquare(x, y, i);
                puzzle[next.y][next.x] = word[i];
            }
        },
        newPuzzle(words, settings) 
        {
            var wordList, puzzle, attempts = 0, opts = settings || {};

            console.log('newPuzzle() :: settings = ', settings);
            
            // copy and sort the words by length, inserting words into the puzzle
            // from longest to shortest works out the best
            wordList = words.slice(0).sort( function (a,b) 
            {
                return (a.length < b.length) ? 1 : 0;
            });
            
            // initialize the options
            var options = {
                height:       opts.height || wordList[0].length,
                width:        opts.width || wordList[0].length,
                orientations: opts.orientations || this.allOrientations,
                fillBlanks:   opts.fillBlanks !== undefined ? opts.fillBlanks : true,
                maxAttempts:  opts.maxAttempts || 3,
                preferOverlap: false
                //opts.preferOverlap !== undefined ? opts.preferOverlap : true
            };

            // add the words to the puzzle
            // since puzzles are random, attempt to create a valid one up to
            // maxAttempts and then increase the puzzle size and try again
            while (!puzzle) 
            {
                while (!puzzle && attempts++ < options.maxAttempts) 
                {
                    puzzle = this.fillPuzzle(wordList, options);
                }

                if (!puzzle) 
                {
                    options.height++;
                    options.width++;
                    attempts = 0;
                }
            }

            // fill in empty spaces with random letters
            if (options.fillBlanks) 
            {
                this.fillBlanks(puzzle, options);
            }

            return puzzle;
        },
        fillBlanks(puzzle) 
        {
            for (var i = 0, height = puzzle.length; i < height; i++) 
            {
                var row = puzzle[i];
                for (var j = 0, width = row.length; j < width; j++) 
                {

                    if (!puzzle[i][j]) 
                    {
                        var randomLetter = Math.floor(Math.random() * this.letters.length);
                        puzzle[i][j] = this.letters[randomLetter];
                    }
                }
            }
        },
        solve(puzzle, words) 
        {
            var options = {
                            height:       puzzle.length,
                            width:        puzzle[0].length,
                            orientations: this.allOrientations,
                            preferOverlap: false
                        },
                found = [],
                notFound = [];

            for(var i = 0, len = words.length; i < len; i++) 
            {
                var word = words[i],
                    locations = this.findBestLocations(puzzle, options, word);

                if (locations.length > 0 && locations[0].overlap === word.length) 
                {
                    locations[0].word = word;
                    found.push(locations[0]);
                }
                else {
                    notFound.push(word);
                }
            }

            return { found: found, notFound: notFound };
        },
        print(puzzle) 
        {
            var puzzleString = '';
            for (var i = 0, height = puzzle.length; i < height; i++) 
            {
                var row = puzzle[i];
                for (var j = 0, width = row.length; j < width; j++) 
                {
                    puzzleString += (row[j] === '' ? ' ' : row[j]) + ' ';
                }
                puzzleString += '\n';
            }

            console.log(puzzleString);
            return puzzleString;
        },  
        loadDailyPuzzle()
        {
          let _this = this;
  
          let today_string = getTodayString()
  

        },
      }
    }
    </script>
    <style scoped>

    .keyboard-button
    {
    width: 20mm;
    height: 20mm;
    font-size: xx-large;
    margin-left: 5mm;
    margin-bottom: 5mm;
    background-color: white;
    border-color: blue;

    }

    .keyboard-button-selected
    {
    width: 20mm;
    height: 20mm;
    font-size: xx-large;
    margin-left: 5mm;
    margin-bottom: 5mm;
    color: white;
    background-color: blue;
    border-color: blue;

    }

    
    .keyboard-button-correct
    {
    width: 20mm;
    height: 20mm;
    font-size: xx-large;
    margin-left: 5mm;
    margin-bottom: 5mm;
    color: white;
    background-color: green;
    border-color: green;

    }
    .custom-button-BV
    {
        width: 130px;
        height: 130px;
        border-radius: 100px;
    }
    li:hover {
      background-color: #e0e0e2;
      color: white;
    }
    .keyboard-button
    {
      width: 20mm;
      height: 20mm;
      font-size: xx-large;
      margin-left: 5mm;
    }
    .display-button
    {
      width: 20mm;
      height: 20mm;
      font-size: xx-large;
      margin-left: 1mm;
      background-color: white;
      border-color: blueviolet;
    }
    .details
    {
      font-size: 24px;
    }
    </style>