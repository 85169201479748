<template>
  <div>
    <div class="row">        
      <div class="col-md-9">
        <div class="card card-body">
          <div class="row">
            <span class="read-text">{{latest_unvoted}}</span> 
          </div>
          <div class="row">
            <div class="card card-body">
              <div class="row" style="margin:auto;margin-top:1%;margin-left:5%" v-for="i in allImgs.length" :key="i">
                <div class="col" :span="5" v-for="j in allImgs[i-1].length" :key="j"> 
                  <a @click="checkbutton(i-1, j-1)" > 
                    <div v-if="allImgs[i-1][j-1].checked" class="interestButtonChecked" style="position:relative">
                      <img class="interestThumbnail" width="200" height="200" v-bind:src="allImgs[i-1][j-1].img"  style="opacity: 80%"/>
                    </div>
                    <div v-else class="interestButtonUnChecked" style="position:relative">
                        <img class="interestThumbnail" width="200" height="200" v-bind:src="allImgs[i-1][j-1].img" style="opacity: 40%"/>
                        <div class="bi bi-plus-lg" style="height:100%; margin-left: auto; margin-right: auto; left: 0; right: 0; top:25px; text-align: center; font-size: 60px; color:gray; font-weight: 1000; position:absolute;"/>

                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-body">
            <p>Only click here if you already selected the good/bad images</p>
            <button class='btn btn-warning' v-bind:style="styleBtn2" @click="save_selection()"> Next set</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  
  
  import homeplanImg from '@/assets/images/homeplan.png'
  import audioImg from '@/assets/images/audio_logo.png'
  
  import crossImg from '@/assets/images/cross.png'
  import doneIcon from '@/assets/images/icon_green_check.png'
  import {getData} from '@/firebase/index'
  
  
  export default {
      name: 'image_selection_tool',
      
      props: {
          exForm: {
          type: Object,
          },
      },
      data() {
          return {
          // variable
            word:'dog',
            url2image:'',
            uid:'',
            n_images:12,
            url2images:[],
            styleBtn2: {
                margin: 'auto',

            },
            development_data_address:'Development/Picture_Naming/',

            frameColour: ['#FFFFFF','#FFFFFF','#FFFFFF','#FFFFFF'],
            imgType_options:['IMG_TYPE_UNDEFINED','CLIPART','LINEART','STOCK','PHOTO'],
            imgType:'STOCK',
            ruleForm: {
                imgType:'STOCK'
            },
            allImgs: [],
            all_data:{},
            latest_unvoted:null,
          }
      },
      created() {
        const _this = this

        _this.uid = localStorage.getItem('uid');

        console.debug('UID = ' + _this.uid)


        _this.read_development_data()

        // path -> development/image_search_selection_tool

          
      },
      methods: 
      {

        read_development_data()
        {

          const _this = this





          getData(_this.development_data_address , function (err, handle) {
            console.debug('here 0')

            handle.forEach(function(childSnapshot)
            {
              _this.all_data[childSnapshot.key] = childSnapshot.val()
            })

            _this.find_latest_unvoted_data()


          });

        },
        find_latest_unvoted_data()
        {
          const _this = this
          _this.latest_unvoted = null
          for(let i in _this.all_data)
          {

            if(!('who_voted' in _this.all_data[i]))
            {
              console.debug('No one voted for ' + i)

              _this.latest_unvoted = i

              break

            }
            else if(_this.all_data[i]['who_voted'].includes(_this.uid))
            {
              console.debug('Already voted for ' + i)
            }
            else
            {
              _this.latest_unvoted = i
              
              console.debug('Not voted for ' + i)

              break

            }
          }

          if(_this.latest_unvoted==null)
          {
            alert('You finished everything. Go do something else')
          }
          else
          {
            _this.load_imgs()
          }

        },
        load_imgs()
        {
          const _this = this

          let curr_word_data = _this.all_data[_this.latest_unvoted]

          let thumbails = curr_word_data['thumbnails']

          let column_index = 0
          let row_index = 0

          let n_columns = 3

          let curr_row = []

          _this.allImgs = []
          for(let n in thumbails)
          {

            let img_address = thumbails[n]['Image']['storage_address']

            console.debug('img_address = ' + img_address)
            // storageRef.child(img_address).getDownloadURL().then(function(url) {

              let curr_data = {storage_img:img_address,img:'',checked:false,img_key:n}

              column_index = column_index+1

              curr_row.push(curr_data)


            // }).catch(function(error) {

            //   console.debug('error = ' + error)

            // });


            if(column_index==3)
            {

              _this.allImgs.push(curr_row)

              column_index = 0

              curr_row = []



            }

          }


          for(let n in _this.allImgs)
          {
            let row = _this.allImgs[n]
            for(let k in row)
            {

              let col = row[k]

              var storageRef = _this.$firebase.storage().ref();

              let storage_address = col['storage_img']

              console.debug('storage_address = ' + storage_address)

              storageRef.child(storage_address).getDownloadURL().then(function(url) {

                col['img']=url

              })
            }

          }

        },
        save_selection()
        {

          const _this = this
          
          let curr_word_data_address = _this.development_data_address+_this.latest_unvoted
          let curr_word_data_ref = _this.$firebase.database().ref(curr_word_data_address);

          let do_once = true

          getData(curr_word_data_address, function (err, handle) {

            if(do_once)
            {
              do_once = false
              console.debug('here 1')

              let data = handle.val()

              console.debug('data = ' + data)

              for(let k in data)
              {
                console.debug('data['+k+'] = ' + data[k])
              }

              let who_voted = [_this.uid]

              if('who_voted' in data)
              {
                who_voted = data['who_voted']

                who_voted.push(_this.uid)

              }

              curr_word_data_ref.child('who_voted').set(who_voted);

              for(let i in _this.allImgs)
              {
                let curr_row = _this.allImgs[i]

                for(let j in curr_row)
                {

                  let curr_img = curr_row[j]

                  let checked = curr_img['checked']

                  let img_key = curr_img['img_key']

                  if(checked)
                  {
                    console.debug(Object.keys(data))
                    console.debug(Object.keys(data['thumbnails']))

                    console.debug(Object.keys(data['thumbnails'][img_key]))

                    console.debug(Object.keys(data['thumbnails'][img_key]['Votes']))


                    let curr_pro = data['thumbnails'][img_key]['Votes']['pro']



                    curr_pro.push(_this.uid)


                    curr_word_data_ref.child('thumbnails').child(img_key).child('Votes').child('pro').set(curr_pro);


                  }
                  else
                  {
                    let curr_con = data['thumbnails'][img_key]['Votes']['con']

                    curr_con.push(_this.uid)

                    curr_word_data_ref.child('thumbnails').child(img_key).child('Votes').child('con').set(curr_con);

                  }

                  _this.read_development_data()
                  
                }
              
            }

            }



          });




        },
        checkbutton(i, j)
        {
          if(this.allImgs[i][j].checked == true)
          {
            this.allImgs[i][j].checked = false;
          }
          else
          {
            this.allImgs[i][j].checked = true;
          }

        },

     
      }
  }
  </script>
  
  <style scoped>
    .text {
      font-size: 14px;
    }
  
    .text_item {
      /* margin-bottom: 18px; */
      font-size: 30px;
      /* vertical-align: middle; */
      padding: 40px 0;
      /* height:80px; */
    }

    .clearfix:before,
    .clearfix:after {
      display: table;
      content: "";
    }
    .clearfix:after {
      clear: both
    }
  
    .box-card {
      width: 60%;
      margin:auto;
    }
 
  
    .demo-input-label {
      display: inline-block;
      width: 130px;
    }

    .legend-custom-style {
      font-size: 20px;
    }
  </style>