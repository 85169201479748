

import popSfx from '@/assets/sounds/pop_fx.mp3'

export function startConfetti(thisObj) {

    new Audio(popSfx).play()

    thisObj.$confetti.start()

    setTimeout(() => { thisObj.$confetti.stop()}, 2000);

}